import { Photo } from "editor/models/types";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import produce from "immer";
import { Reducer } from "redux";
import { ApiResponse } from "unsplash-js/dist/helpers/response";
import { Photos } from "unsplash-js/dist/methods/search/types/response";
import {
  startRequest,
  finishRequestWithErrors,
  finishRequest,
} from "api/apiActions";
import { fetchPhotosAsync } from "../../editor/api/photo";
import { getResponseJsonAsync } from "editor/api/common";

export type State = {
  photos: Photo[];
  requestState: "success" | "in-progress" | "failure" | null;
};

export const RESET_PHOTOS = "RESET_PHOTOS";

type ResetPhotosAction = {
  type: typeof RESET_PHOTOS;
};

export const resetPhotos = (): ResetPhotosAction => {
  return {
    type: RESET_PHOTOS,
  };
};

export const GET_PHOTOS = "GET_PHOTOS";

type GetPhotosAction = {
  type: typeof GET_PHOTOS;
  queryString: string;
  key: string;
};

export const getPhotos = (
  queryString: string,
  key: string
): GetPhotosAction => {
  return {
    type: GET_PHOTOS,
    queryString,
    key,
  };
};

export const GET_PHOTOS_SUCCEEDED = "GET_PHOTOS_SUCCEEDED";

type GetPhotosSucceededAction = {
  type: typeof GET_PHOTOS_SUCCEEDED;
  photos: Photo[];
};

export const getPhotosSucceeded = (photos: Photo[]): Action => {
  return {
    type: GET_PHOTOS_SUCCEEDED,
    photos,
  };
};

export const GET_PHOTOS_FAILED = "GET_PHOTOS_FAILED";

type GetPhotosFailedAction = {
  type: typeof GET_PHOTOS_FAILED;
};

export const getPhotosFailed = (): Action => {
  return {
    type: GET_PHOTOS_FAILED,
  };
};

type Action =
  | GetPhotosAction
  | GetPhotosSucceededAction
  | GetPhotosFailedAction
  | ResetPhotosAction;

const initialState: State = {
  photos: [],
  requestState: null,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case GET_PHOTOS:
      return handleGetPhotos(state, action);

    case GET_PHOTOS_SUCCEEDED:
      return handleGetPhotosSucceeded(state, action);

    case GET_PHOTOS_FAILED:
      return handleGetPhotosFailed(state, action);

    case RESET_PHOTOS:
      return handleResetPhotos(state, action);

    default:
      return state;
  }
};

const handleGetPhotos = (state: State, action: GetPhotosAction): State =>
  produce(state, (draft) => {
    draft.photos = state.photos;
    draft.requestState = "in-progress";
  });

const handleGetPhotosSucceeded = (
  state: State,
  action: GetPhotosSucceededAction
): State =>
  produce(state, (draft) => {
    draft.requestState = "success";
    draft.photos = action.photos;
  });

const handleGetPhotosFailed = (
  state: State,
  action: GetPhotosFailedAction
): State =>
  produce(state, (draft) => {
    draft.requestState = "failure";
  });

const handleResetPhotos = (state: State, action: ResetPhotosAction): State =>
  produce(state, (draft) => {
    draft.requestState = null;
    draft.photos = [];
  });

function* getPhotosFromApi(action: GetPhotosAction) {
  yield put(startRequest());
  try {
    const unsplashResponse: ApiResponse<Photos> = yield call(
      fetchPhotosAsync,
      action.queryString,
      action.key
    );
    if (unsplashResponse && unsplashResponse.type === "success") {
      yield put(getPhotosSucceeded(mapToPhoto(unsplashResponse)));
    } else {
      yield put(getPhotosFailed());
    }
    yield put(finishRequest());
  } catch (e) {
    yield put(getPhotosFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* resetPhotosInState(action: ResetPhotosAction) {}

const mapToPhoto = (photosResponse: ApiResponse<Photos>): Photo[] => {
  let photos: Photo[] = new Array(photosResponse.response?.results.length);
  photosResponse.response?.results.forEach((rawPhoto) => {
    return photos.push({
      id: rawPhoto.id,
      width: rawPhoto.width,
      height: rawPhoto.height,
      urls: {
        large: rawPhoto.urls.full,
        regular: rawPhoto.urls.regular,
        raw: rawPhoto.urls.raw,
        small: rawPhoto.urls.small,
      },
      color: rawPhoto.color,
      user: {
        username: rawPhoto.user.username,
        name: rawPhoto.user.name,
      },
    });
  });

  return photos;
};

export function* rootSaga() {
  yield takeLatest(GET_PHOTOS, getPhotosFromApi);
  yield takeLatest(RESET_PHOTOS, resetPhotosInState);
}
