import { MoreHorizOutlined } from "@material-ui/icons";
import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import { LinkCollectionProperty, LinkProperty } from "editor/properties";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { NavProperties } from "common/elements/components/Nav";
import { NavStyleProperty } from "editor/properties/NavStyleProperty/NavStyleProperty";
import styles from "./FooterNav.module.scss";
import { useTranslation } from "react-i18next";

export class FooterNavElement {
  static readonly elementKey = "footer-nav";
  static readonly label = "element_footernav_label";
  static readonly icon = MoreHorizOutlined;
  static readonly allowedToolbarPositions = [];
  static readonly allowedChildren = ["footer-social-nav"];
  static readonly elementDescription = "element_footernav_description";
  static readonly dialogPositionStatic = false;
  static readonly isDeletable = true;

  static readonly properties: PropertiesOf<NavProperties> = {
    links: LinkCollectionProperty,
    style: NavStyleProperty,
    isProduction: Boolean,
  };

  static renderProperties(element: ElementType) {
    return <NavPropertiesComponent element={element} />;
  }

  static create(properties: { links: LinkProperty[]; style: string }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const NavPropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const renderer = new PropertyRenderer<typeof FooterNavElement.properties>(
    FooterNavElement.properties,
    element
  );

  const { t } = useTranslation();
  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="links">
        <Tab eventKey="links" title={t("dialog_21")}>
          {renderer.render("links")}
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(FooterNavElement);
