import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type Heading1Properties = {
  heading1Style: { style: string };
  heading1Text: { text: string };
};

export function Heading1({
  properties: { heading1Style, heading1Text },
  className,
  ...props
}: ElementComponentProps<Heading1Properties>): JSX.Element {
  return (
    <h1
      {...props}
      className={className + "h1 heading-1-style-" + heading1Style.style}
    >
      {heading1Text.text}
    </h1>
  );
}

export const heading1ElementKey = "heading-1";

elementComponentRegistry.register(heading1ElementKey, Heading1);
