import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { ColorLensRounded, SaveOutlined } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { getUsername, getUserWebsiteSlug } from "../../../../auth/selectors";
import { Dialog } from "../../components/Dialog/Dialog";
import { Row, Form } from "react-bootstrap";
import {
  getDescription,
  getPageId,
  getPageIndexState,
  getPrimaryColor,
  getPrimaryFont,
  getPrimaryRadius,
  getPrimaryShadow,
  getSecondaryColor,
  getSecondaryFont,
  getSecondaryRadius,
  getTitle,
  getTernaryColor,
  getColorType,
} from "editor/selectors";
import { pageDetailsUpdated } from "editor/states/pageList";
import { ChromePicker, ColorResult } from "react-color";
import styles from "./ColorPropsDialog.module.scss";
import { editorPage } from "../Editor/Editor";
import { logActivity } from "auth/authActions";
import { getRequestIsInProgress } from "api/selectors";
import { updateWebsiteDetails } from "admin/websiteActions";
import { getWebsite } from "editor/states/selectors";
import { useTranslation } from "react-i18next";
type Props = {
  isShowFontsPropsDialog: boolean;
  toolBarButtonRect?: DOMRect;
  handleShowPropDialog: () => void;
  cssPos?: React.CSSProperties;
};

export default function ColorPropsDialog({
  isShowFontsPropsDialog,
  toolBarButtonRect,
  handleShowPropDialog,
  cssPos,
}: Props): JSX.Element {
  const { t } = useTranslation();

  var tinycolor = require("tinycolor2");

  const requestInProgress = useSelector(getRequestIsInProgress);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const statePrimaryColor = useSelector(getPrimaryColor);
  const stateSecondaryColor = useSelector(getSecondaryColor);
  const stateTernaryColor = useSelector(getTernaryColor);
  const stateColorType = useSelector(getColorType);
  const statePrimaryFont = useSelector(getPrimaryFont);
  const stateSecondaryFont = useSelector(getSecondaryFont);
  const statePrimaryRadius = useSelector(getPrimaryRadius);
  const stateSecondaryRadius = useSelector(getSecondaryRadius);
  const statePrimaryShadow = useSelector(getPrimaryShadow);
  const pageId = useSelector(getPageId);
  const pageTitle = useSelector(getTitle);
  const pageDescription = useSelector(getDescription);
  const isPageIndex = useSelector(getPageIndexState);
  const username = useSelector(getUsername);

  const [selectedPrimaryColor, setSelectedPrimaryColor] = useState("#124E78");
  const [selectedSecondaryColor, setSelectedSecondaryColor] = useState(
    "#C47500"
  );
  const [selectedTernaryColor, setSelectedTernaryColor] = useState("#003F7A");
  const [selectedColorType, setSelectedColorType] = useState("comp");

  const [triad1, setTriad1] = useState("#124E78");
  const [triad2, setTriad2] = useState("#BB4012");
  const [triad3, setTriad3] = useState("#BB9012");

  const [mono1, setmono1] = useState("#124E78");
  const [mono2, setmono2] = useState("#256490");
  const [mono3, setmono3] = useState("#063C62");

  const [like1, setlike1] = useState("#124E78");
  const [like2, setlike2] = useState("#0C814C");
  const [like3, setlike3] = useState("#1E2182");

  const [split1, setsplit1] = useState("#124E78");
  const [split2, setsplit2] = useState("#DE381F");
  const [split3, setsplit3] = useState("#6FDE14");

  const [tetra1, settetra1] = useState("#124E78");
  const [tetra2, settetra2] = useState("#BB9012");
  const [tetra3, settetra3] = useState("#1E2182");

  const [comp1, setcomp1] = useState("#124E78");
  const [comp2, setcomp2] = useState("#C47500");
  const [comp3, setcomp3] = useState("#003F7A");

  const dispatch = useDispatch();

  const setPalettes = (hex: string, colorType: string) => {
    let triadColors = tinycolor(hex).triad();
    let triadMap = triadColors.map(function (t: any) {
      return t.toHexString();
    });
    setTriad1(triadMap[0]);
    setTriad2(triadMap[1]);
    setTriad3(triadMap[2]);

    let monoColors = tinycolor(hex).monochromatic();
    let monoMap = monoColors.map(function (t: any) {
      return t.toHexString();
    });
    setmono1(monoMap[0]);
    setmono2(monoMap[1]);
    setmono3(monoMap[2]);

    let splitColors = tinycolor(hex).splitcomplement();
    let splitMap = splitColors.map(function (t: any) {
      return t.toHexString();
    });
    setsplit1(splitMap[0]);
    setsplit2(splitMap[1]);
    setsplit3(splitMap[2]);

    let analogousColors = tinycolor(hex).analogous();
    let likeMap = analogousColors.map(function (t: any) {
      return t.toHexString();
    });
    setlike1(likeMap[0]);
    setlike2(likeMap[1]);
    setlike3(likeMap[2]);

    let tetradColors = tinycolor(hex).tetrad();
    let tetraMap = tetradColors.map(function (t: any) {
      return t.toHexString();
    });
    settetra1(tetraMap[0]);
    settetra2(tetraMap[1]);
    settetra3(tetraMap[2]);

    let compColor = tinycolor(hex).complement().toHexString();
    let compMap = [hex, compColor, tinycolor(compColor).greyscale().toString()];
    setcomp1(compMap[0]);
    setcomp2(compMap[1]);
    setcomp3(compMap[2]);

    let colorsMap: string[];
    if (colorType === "triad") {
      colorsMap = triadMap;
    } else if (colorType === "mono") {
      colorsMap = monoMap;
    } else if (colorType === "split") {
      colorsMap = splitMap;
    } else if (colorType === "like") {
      colorsMap = likeMap;
    } else if (colorType === "tetra") {
      colorsMap = tetraMap;
    } else {
      colorsMap = compMap;
    }

    return colorsMap;
  };
  useEffect(() => {
    if (statePrimaryColor && statePrimaryColor !== "") {
      setSelectedPrimaryColor(statePrimaryColor);
    }
    if (stateSecondaryColor && stateSecondaryColor !== "") {
      setSelectedSecondaryColor(stateSecondaryColor);
    }
    if (stateTernaryColor && stateTernaryColor !== "") {
      setSelectedTernaryColor(stateTernaryColor);
    }
    if (stateColorType && stateColorType !== "") {
      setSelectedColorType(stateColorType);
    }
    if (stateColorType && statePrimaryColor) {
      let triadColors = tinycolor(statePrimaryColor).triad();
      let triadMap = triadColors.map(function (t: any) {
        return t.toHexString();
      });
      setTriad1(triadMap[0]);
      setTriad2(triadMap[1]);
      setTriad3(triadMap[2]);

      let monoColors = tinycolor(statePrimaryColor).monochromatic();
      let monoMap = monoColors.map(function (t: any) {
        return t.toHexString();
      });
      setmono1(monoMap[0]);
      setmono2(monoMap[1]);
      setmono3(monoMap[2]);

      let splitColors = tinycolor(statePrimaryColor).splitcomplement();
      let splitMap = splitColors.map(function (t: any) {
        return t.toHexString();
      });
      setsplit1(splitMap[0]);
      setsplit2(splitMap[1]);
      setsplit3(splitMap[2]);

      let analogousColors = tinycolor(statePrimaryColor).analogous();
      let likeMap = analogousColors.map(function (t: any) {
        return t.toHexString();
      });
      setlike1(likeMap[0]);
      setlike2(likeMap[1]);
      setlike3(likeMap[2]);

      let tetradColors = tinycolor(statePrimaryColor).tetrad();
      let tetraMap = tetradColors.map(function (t: any) {
        return t.toHexString();
      });
      settetra1(tetraMap[0]);
      settetra2(tetraMap[1]);
      settetra3(tetraMap[2]);

      let compColor = tinycolor(statePrimaryColor).complement().toHexString();
      let compMap = [
        statePrimaryColor,
        compColor,
        tinycolor(compColor).greyscale().toString(),
      ];
      setcomp1(compMap[0]);
      setcomp2(compMap[1]);
      setcomp3(compMap[2]);
    }
  }, [
    statePrimaryColor,
    stateSecondaryColor,
    stateTernaryColor,
    stateColorType,
    tinycolor,
  ]);

  const handleTypeClick = (type: string) => {
    let color1;
    let color2;
    let color3;
    if (type === "triad") {
      color1 = triad1;
      color2 = triad2;
      color3 = triad3;
    } else if (type === "mono") {
      color1 = mono1;
      color2 = mono2;
      color3 = mono3;
    } else if (type === "split") {
      color1 = split1;
      color2 = split2;
      color3 = split3;
    } else if (type === "like") {
      color1 = like1;
      color2 = like2;
      color3 = like3;
    } else if (type === "tetra") {
      color1 = tetra1;
      color2 = tetra2;
      color3 = tetra3;
    } else {
      color1 = comp1;
      color2 = comp2;
      color3 = comp3;
    }
    setSelectedPrimaryColor(color1);
    setSelectedSecondaryColor(color2);
    setSelectedTernaryColor(color3);
    setSelectedColorType(type);

    if (pageId && pageTitle && isPageIndex !== null) {
      dispatch(
        logActivity(
          "SET COLOR",
          editorPage,
          username,
          selectedPrimaryColor +
            " / " +
            selectedSecondaryColor +
            " / " +
            selectedTernaryColor +
            " / " +
            type
        )
      );
      dispatch(
        pageDetailsUpdated(
          pageId,
          pageTitle,
          pageDescription ? pageDescription : "",
          isPageIndex,
          statePrimaryFont ? statePrimaryFont : `"Roboto", sans-serif`,
          stateSecondaryFont ? stateSecondaryFont : `"Lora", serif`,
          selectedPrimaryColor ? selectedPrimaryColor : "#124E78",
          selectedSecondaryColor ? selectedSecondaryColor : "#C47500",
          selectedTernaryColor ? selectedTernaryColor : "#003F7A",
          type ? type : "comp",
          statePrimaryRadius ? statePrimaryRadius : "5px",
          stateSecondaryRadius ? stateSecondaryRadius : "2.5px",
          statePrimaryShadow ? statePrimaryShadow : "0.5"
        )
      );
      if (websiteSlug) {
        dispatch(
          updateWebsiteDetails(
            websiteSlug,
            website.primaryFont ? website.primaryFont : `"Roboto", sans-serif`,
            website.secondaryFont ? website.secondaryFont : `"Lora", serif`,
            website.primaryColor ? website.primaryColor : "#124E78",
            website.secondaryColor ? website.secondaryColor : "#C47500",
            website.ternaryColor ? website.ternaryColor : "#003F7A",
            type ? type : "comp",
            website.primaryRadius ? website.primaryRadius : "5px",
            website.secondaryRadius ? website.secondaryRadius : "2.5px",
            website.primaryShadow ? website.primaryShadow : "0.5",
            website.logoCreated ? website.logoCreated : false
          )
        );
      }
    }
  };

  const handleOnClick = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let colorsMap = setPalettes(color.hex, selectedColorType);

    setSelectedPrimaryColor(colorsMap[0]);
    setSelectedSecondaryColor(colorsMap[1]);
    setSelectedTernaryColor(colorsMap[2]);
    setSelectedColorType(selectedColorType);

    if (pageId && pageTitle && isPageIndex !== null) {
      dispatch(
        pageDetailsUpdated(
          pageId,
          pageTitle,
          pageDescription ? pageDescription : "",
          isPageIndex,
          statePrimaryFont ? statePrimaryFont : `"Roboto", sans-serif`,
          stateSecondaryFont ? stateSecondaryFont : `"Lora", serif`,
          selectedPrimaryColor ? colorsMap[0] : "#124E78",
          selectedSecondaryColor ? colorsMap[1] : "#C47500",
          selectedTernaryColor ? colorsMap[2] : "#003F7A",
          selectedColorType ? selectedColorType : "comp",
          statePrimaryRadius ? statePrimaryRadius : "5px",
          stateSecondaryRadius ? stateSecondaryRadius : "2.5px",
          statePrimaryShadow ? statePrimaryShadow : "0.5"
        )
      );
      if (websiteSlug) {
        dispatch(
          updateWebsiteDetails(
            websiteSlug,
            website.primaryFont ? website.primaryFont : `"Roboto", sans-serif`,
            website.secondaryFont ? website.secondaryFont : `"Lora", serif`,
            selectedPrimaryColor ? colorsMap[0] : "#124E78",
            selectedSecondaryColor ? colorsMap[1] : "#C47500",
            selectedTernaryColor ? colorsMap[2] : "#003F7A",
            selectedColorType ? selectedColorType : "comp",
            website.primaryRadius ? website.primaryRadius : "5px",
            website.secondaryRadius ? website.secondaryRadius : "2.5px",
            website.primaryShadow ? website.primaryShadow : "0.5",
            website.logoCreated ? website.logoCreated : false
          )
        );
      }
      dispatch(
        logActivity(
          "SET COLOR",
          editorPage,
          username,
          colorsMap[0] +
            " / " +
            colorsMap[1] +
            " / " +
            colorsMap[2] +
            " / " +
            selectedColorType
        )
      );
    }
  };

  if (!isShowFontsPropsDialog || !websiteSlug) {
    return <></>;
  }

  function hexToRGB(hex: string, alpha: number) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  const ColorPropsDialogBody = (
    <div className={`${styles["palette-container"]}`}>
      <Container
        className={`${styles["swatch-container"]}`}
        style={{ paddingBottom: 20, paddingTop: 20 }}
      >
        <Form.Group as={Row}>
          <Form.Group as={Row}>
            <div className="picker-container">
              <ChromePicker
                disableAlpha={true}
                color={selectedPrimaryColor}
                onChange={(
                  color: ColorResult,
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  handleOnClick(color, event);
                }}
              />
              <div className="harmony-rule-container">
                <div
                  className={`harmony-type ${
                    selectedColorType === "triad" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTypeClick("triad");
                  }}
                >
                  <span>Triad</span>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(triad1, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(triad2, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(triad3, 0.0)}` }}
                  ></div>
                </div>
                <div
                  className={`harmony-type ${
                    selectedColorType === "mono" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTypeClick("mono");
                  }}
                >
                  <span>Mono</span>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(mono1, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(mono2, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(mono3, 0.0)}` }}
                  ></div>
                </div>
              </div>
              <div className="harmony-rule-container">
                <div
                  className={`harmony-type ${
                    selectedColorType === "like" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTypeClick("like");
                  }}
                >
                  <span>Like</span>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(like1, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(like2, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(like3, 0.0)}` }}
                  ></div>
                </div>
                <div
                  className={`harmony-type ${
                    selectedColorType === "split" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTypeClick("split");
                  }}
                >
                  <span>Split</span>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(split1, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(split2, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(split3, 0.0)}` }}
                  ></div>
                </div>
              </div>
              <div className="harmony-rule-container">
                <div
                  className={`harmony-type ${
                    selectedColorType === "tetra" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTypeClick("tetra");
                  }}
                >
                  <span>Tetra</span>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(tetra1, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(tetra2, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(tetra3, 0.0)}` }}
                  ></div>
                </div>
                <div
                  className={`harmony-type ${
                    selectedColorType === "comp" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTypeClick("comp");
                  }}
                >
                  <span>Comp</span>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(comp1, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(comp2, 0.0)}` }}
                  ></div>
                  <div
                    className="harmony-swatch"
                    style={{ backgroundColor: `${hexToRGB(comp3, 0.0)}` }}
                  ></div>
                </div>
              </div>
            </div>
          </Form.Group>
        </Form.Group>
      </Container>
      <div className={styles["dialog-action-notification"]}>
        {requestInProgress ? (
          <>
            <Spinner
              size="sm"
              animation="border"
              variant="secondary"
              className={styles["saving-icon"]}
            />{" "}
            {t("Saving")}
          </>
        ) : (
          <>
            <SaveOutlined />
            {t("Changes_Saved")}
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Dialog
        dialogBody={ColorPropsDialogBody}
        icon={ColorLensRounded}
        headerLabel={t("Colors")}
        handleShowDialog={handleShowPropDialog}
        isShowDialog={isShowFontsPropsDialog}
        isGrabbable={false}
        elementPos={toolBarButtonRect}
        size="medium"
        isShowBg={false}
        cssPosition={cssPos}
      />
    </>
  );
}
