import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { getUserFirstName } from "../auth/selectors";
import { IStepProps } from "./Setup";

import { useTranslation } from "react-i18next";

export const SetupStep0Form: React.FunctionComponent<IStepProps> = ({
  handleSaveAndContinue,
  isPaid,
}) => {
  const { t } = useTranslation();
  const firstName = useSelector(getUserFirstName);

  useEffect(() => {
    if (isPaid) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?success=1";
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }, [isPaid]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {t("setup_page_1")} {firstName}!{" "}
          {isPaid ? (
            <span role="img" aria-label="cheers" id="ga-conversion">
              🥳
            </span>
          ) : (
            <span role="img" aria-label="cheers">
              🥂
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>{t("setup_page_2")}</b>
        </p>
        <p>
          {t("setup_page_3")}{" "}
          <a
            href="https://tawk.to/chat/63b56d68c2f1ac1e202ba11d/1glub6oqr"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("setup_page_4")}
          </a>{" "}
          {t("setup_page_5")}{" "}
          <a
            href="https://goodweb.tawk.help/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("setup_page_6")}
          </a>{" "}
          {t("setup_page_7")}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ borderRadius: 32 }}
          variant="primary"
          onClick={() => handleSaveAndContinue({})}
        >
          {t("setup_page_8")}
        </Button>
      </Modal.Footer>
    </>
  );
};
