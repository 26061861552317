import React from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import TextFieldGroup from "../components/components/TextFieldGroup";
import { IProperty, PropertyComponent } from "./types";
import { propertySet } from "editor/states/layout";
import { DimensionProperty } from "./DimensionProperty/DimensionProperty";

export class YoutubeEmbedUrlProperty implements IProperty {
  constructor(readonly url: string, readonly dimension?: DimensionProperty) {}

  render(elementId: string, propertyKey: string) {
    return (
      <YoutubeEmbedUrlPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
        url={this.url}
      />
    );
  }

  static parse(value: any): YoutubeEmbedUrlProperty {
    return new YoutubeEmbedUrlProperty(value);
  }
}

const YoutubeEmbedUrlPropertyComponent: PropertyComponent<YoutubeEmbedUrlProperty> = ({
  elementId,
  propertyKey,
  url,
}) => {
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let re = /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
    let url = e.target.value;
    if (url && re && url.match(re)) {
      let matched = url.match(re);
      if (matched) {
        let id = matched[7];
        dispatch(propertySet(elementId, propertyKey, id));
      }
    }
  };

  return (
    <div className="medium-padded-container">
      <div className="form__group">
        <Form className="form">
          <TextFieldGroup
            name="text"
            label="Youtube Link / URL / Share Embed"
            value={url}
            onChange={handleChange}
          />
        </Form>
      </div>
    </div>
  );
};
