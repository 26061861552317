import { Treatment } from "editor/elements/types";
import React from "react";
import { IProperty, PropertyComponent } from "../types";

export class TreatmentProperty implements IProperty {
  constructor(
    readonly isTreatment: boolean,
    readonly treatments?: Treatment[]
  ) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <TreatmentPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): TreatmentProperty {
    return new TreatmentProperty(value.text, value.url);
  }
}

const TreatmentPropertyComponent: PropertyComponent<TreatmentProperty> = ({
  elementId,
  propertyKey,
}) => {
  return <></>;
};
