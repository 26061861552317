import React from "react";

import { IProperty } from "./types";

export class RTEAlignmentProperty implements IProperty {
  constructor(readonly style: any) {}
  render() {
    return <></>;
  }

  static parse(value: any) {
    return new RTEAlignmentProperty(value);
  }
}
