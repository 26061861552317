import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type InputLabelProperties = {
  inputLabelStyle: { style: string };
  inputLabelText: { text: string };
};

export function InputLabel({
  properties: { inputLabelStyle, inputLabelText },
  className,
  ...props
}: ElementComponentProps<InputLabelProperties>): JSX.Element {
  return (
    <label
      {...props}
      className={className + " input-label-style-" + inputLabelStyle.style}
    >
      {inputLabelText.text}
    </label>
  );
}

export const inputLabelElementKey = "Label";

elementComponentRegistry.register(inputLabelElementKey, InputLabel);
