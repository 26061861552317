import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import { Form } from "react-bootstrap";

export type FormGroupProperties = {
  formGroupStyle: string;
  fieldGroupConfig: {
    label: string;
    type: string;
    placeholder?: string;
    isRequired?: boolean;
  };
};

export function FormGroup({
  properties: { formGroupStyle, fieldGroupConfig },
  className,
  innerRef,
  children,
  ...props
}: ElementComponentProps<FormGroupProperties>): JSX.Element {
  return (
    <div
      {...props}
      ref={innerRef}
      className={
        className + "form-group fp cg-df-dg form-group-style-" + formGroupStyle
      }
    >
      {fieldGroupConfig.type === "input" ? (
        <Form.Group controlId="input201f">
          <Form.Label>{fieldGroupConfig.label}</Form.Label>
          <Form.Control
            type="input"
            placeholder={fieldGroupConfig.placeholder}
            className="p-s p-c-bo-d90-a5"
            required={fieldGroupConfig.isRequired ? true : false}
          />
        </Form.Group>
      ) : (
        <Form.Group controlId="tarea201e">
          <Form.Label>{fieldGroupConfig.label}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={fieldGroupConfig.placeholder}
            className="p-s p-c-bo-d90-a5"
            required={fieldGroupConfig.isRequired ? true : false}
          />
        </Form.Group>
      )}
    </div>
  );
}

export const formGroupElementKey = "form-group";

elementComponentRegistry.register(formGroupElementKey, FormGroup);
