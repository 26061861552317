import { DeleteForeverRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getElementConfiguration } from "../../../elements/registry";
import { ElementsDialog } from "../../components/Dialog/ElementsDialog";
import { getPageSlug, getSelectedElementId } from "../../../selectors";
import { getElementDefinition } from "../../../elements/registry";
import {
  elementRemoved,
  elementShared,
  elementUnshared,
} from "editor/states/layout";
import {
  getCompositeLayout,
  getSharedElementConfiguration,
  isElementShared,
} from "editor/states/selectors";
import {
  cancelElementEdition,
  setHoveredElement,
} from "../Editor/editorActions";
import { State } from "store";
import { logActivity } from "auth/authActions";
import { editorPage } from "../Editor/Editor";
import { getUsername } from "auth/selectors";

type Props = {
  selectedRef: React.MutableRefObject<any>;
  showElementsPropDialog: boolean;
};

export const ElementsPropertiesDialog: React.FunctionComponent<Props> = ({
  selectedRef,
  showElementsPropDialog,
}) => {
  const layout = useSelector(getCompositeLayout);
  const pageSlug = useSelector(getPageSlug);
  const selectedElementId = useSelector(getSelectedElementId);
  const username = useSelector(getUsername);
  const isSelectedElementShared = useSelector((state: State) =>
    isElementShared(state, selectedElementId!)
  );
  const sharedElementConfiguration = useSelector((state: State) =>
    getSharedElementConfiguration(state, selectedElementId!)
  );
  const [sharedElementLabel, setSharedElementLabel] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setSharedElementLabel(sharedElementConfiguration?.label);
  }, [sharedElementConfiguration]);

  if (!selectedElementId) {
    return <></>;
  }

  const handleDeleteClick = () => {
    if (!selectedElementId) {
      return;
    }
    dispatch(
      logActivity("DELETE ELEMENT", editorPage, username, configuration.label)
    );
    dispatch(cancelElementEdition(selectedElementId, selectedRef));
    dispatch(elementRemoved(selectedElementId, pageSlug!));
    dispatch(setHoveredElement(null, null));
  };

  const handleCancelClick = () => {
    if (!selectedElementId) {
      return;
    }
    dispatch(cancelElementEdition(selectedElementId, selectedRef));
  };

  const handleElementSharedChange = () => {
    if (!selectedElementId) {
      return;
    }

    if (isSelectedElementShared) {
      dispatch(elementUnshared(selectedElementId));
    } else {
      dispatch(elementShared(selectedElementId, sharedElementLabel));
    }
  };

  if (!showElementsPropDialog) {
    return <></>;
  }

  const element = layout[selectedElementId];
  const configuration = getElementConfiguration(element?.elementKey);

  if (!configuration.isDialogDisplay) {
    return <></>;
  }

  const elementDefinition = getElementDefinition(element?.elementKey);
  const rect = selectedRef.current?.getBoundingClientRect();
  const ElementsPropertiesDialogBody = (
    <div>
      {elementDefinition?.renderProperties ? (
        elementDefinition.renderProperties(element)
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <>
      <ElementsDialog
        dialogBody={ElementsPropertiesDialogBody}
        icon={configuration.icon}
        headerLabel={configuration.label}
        handleShowDialog={handleCancelClick}
        isShowDialog={showElementsPropDialog}
        elementPos={rect}
        isShowBg={false}
        handleParentAction={
          configuration.isDeletable ? handleDeleteClick : undefined
        }
        parentActionIcon={
          configuration.isDeletable ? DeleteForeverRounded : undefined
        }
        parentActionButtonVariant="outline-danger"
        isDialogPositionStatic={configuration.dialogPositionStatic}
        isGrabbable={true}
        size={configuration.dialogSize}
        handleShare={handleElementSharedChange}
        isElementReusable={isSelectedElementShared}
        displayReuseButton={configuration.isReusable}
        cssPosition={
          configuration.cssPosition ? configuration.cssPosition : null
        }
      />
    </>
  );
};
