import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type Heading5Properties = {
  heading5Style: { style: string };
  heading5Text: { text: string };
};

export function Heading5({
  properties: { heading5Style, heading5Text },
  className,
  ...props
}: ElementComponentProps<Heading5Properties>): JSX.Element {
  return (
    <h5
      {...props}
      className={className + "h5 heading-5-style-" + heading5Style.style}
    >
      {heading5Text.text}
    </h5>
  );
}

export const heading5ElementKey = "heading-5";

elementComponentRegistry.register(heading5ElementKey, Heading5);
