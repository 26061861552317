import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Container, Form, Tabs, Tab } from "react-bootstrap";
import { ChromePicker, ColorResult, CompactPicker } from "react-color";
import * as yup from "yup";

import { TCreateCategory } from "./api";
import { IStepProps } from "./Setup";

import styles from "./Admin.module.scss";

import { useTranslation } from "react-i18next";

const schema = yup.object({
  category: yup.mixed<TCreateCategory>().required(/* TODO: Add a message */),
});

export type SetupStep4cFormValues = yup.InferType<typeof schema>;

export const SetupStep4cForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleSkip,
}) => {
  const { t } = useTranslation();
  var tinycolor = require("tinycolor2");
  const [statePrimaryColor, setStatePrimaryColor] = useState<
    undefined | string
  >(values.colorPrimary ? values.colorPrimary : undefined);
  const [stateSecondaryColor, setStateSecondaryColor] = useState<
    undefined | string
  >(values.colorSecondary ? values.colorSecondary : undefined);
  const [stateTernaryColor, setStateTernaryColor] = useState<
    undefined | string
  >(values.colorTernary ? values.colorTernary : undefined);
  const [stateColorType, setStateColorType] = useState<undefined | string>(
    values.colorType ? values.colorType : undefined
  );
  const [defaultTab, setDefaultTab] = useState<string | undefined>(
    values.colorPrimary ? "chrome" : "github"
  );

  const [triad1, setTriad1] = useState("#124E78");
  const [triad2, setTriad2] = useState("#BB4012");
  const [triad3, setTriad3] = useState("#BB9012");

  const [mono1, setmono1] = useState("#124E78");
  const [mono2, setmono2] = useState("#256490");
  const [mono3, setmono3] = useState("#063C62");

  const [like1, setlike1] = useState("#124E78");
  const [like2, setlike2] = useState("#0C814C");
  const [like3, setlike3] = useState("#1E2182");

  const [split1, setsplit1] = useState("#124E78");
  const [split2, setsplit2] = useState("#DE381F");
  const [split3, setsplit3] = useState("#6FDE14");

  const [tetra1, settetra1] = useState("#124E78");
  const [tetra2, settetra2] = useState("#BB9012");
  const [tetra3, settetra3] = useState("#1E2182");

  const [comp1, setcomp1] = useState("#124E78");
  const [comp2, setcomp2] = useState("#C47500");
  const [comp3, setcomp3] = useState("#003F7A");

  useEffect(() => {
    if (
      values &&
      (values.isDesignColors === undefined || values.isDesignColors === false)
    ) {
      handleSaveAndContinue(values);
    } else {
      if (values.colorPrimary && values.colorType) {
        setDefaultTab("chrome");
        let triadColors = tinycolor(values.colorPrimary).triad();
        let triadMap = triadColors.map(function (t: any) {
          return t.toHexString();
        });
        setTriad1(triadMap[0]);
        setTriad2(triadMap[1]);
        setTriad3(triadMap[2]);

        let monoColors = tinycolor(values.colorPrimary).monochromatic();
        let monoMap = monoColors.map(function (t: any) {
          return t.toHexString();
        });
        setmono1(monoMap[0]);
        setmono2(monoMap[1]);
        setmono3(monoMap[2]);

        let splitColors = tinycolor(values.colorPrimary).splitcomplement();
        let splitMap = splitColors.map(function (t: any) {
          return t.toHexString();
        });
        setsplit1(splitMap[0]);
        setsplit2(splitMap[1]);
        setsplit3(splitMap[2]);

        let analogousColors = tinycolor(values.colorPrimary).analogous();
        let likeMap = analogousColors.map(function (t: any) {
          return t.toHexString();
        });
        setlike1(likeMap[0]);
        setlike2(likeMap[1]);
        setlike3(likeMap[2]);

        let tetradColors = tinycolor(values.colorPrimary).tetrad();
        let tetraMap = tetradColors.map(function (t: any) {
          return t.toHexString();
        });
        settetra1(tetraMap[0]);
        settetra2(tetraMap[1]);
        settetra3(tetraMap[2]);

        let compColor = tinycolor(values.colorPrimary)
          .complement()
          .toHexString();
        let compMap = [
          values.colorPrimary,
          compColor,
          tinycolor(compColor).greyscale().toString(),
        ];
        setcomp1(compMap[0]);
        setcomp2(compMap[1]);
        setcomp3(compMap[2]);
      }
    }
  }, [values, handleSaveAndContinue, tinycolor]);

  const setPalettes = (hex: string, colorType: string) => {
    let triadColors = tinycolor(hex).triad();
    let triadMap = triadColors.map(function (t: any) {
      return t.toHexString();
    });
    setTriad1(triadMap[0]);
    setTriad2(triadMap[1]);
    setTriad3(triadMap[2]);

    let monoColors = tinycolor(hex).monochromatic();
    let monoMap = monoColors.map(function (t: any) {
      return t.toHexString();
    });
    setmono1(monoMap[0]);
    setmono2(monoMap[1]);
    setmono3(monoMap[2]);

    let splitColors = tinycolor(hex).splitcomplement();
    let splitMap = splitColors.map(function (t: any) {
      return t.toHexString();
    });
    setsplit1(splitMap[0]);
    setsplit2(splitMap[1]);
    setsplit3(splitMap[2]);

    let analogousColors = tinycolor(hex).analogous();
    let likeMap = analogousColors.map(function (t: any) {
      return t.toHexString();
    });
    setlike1(likeMap[0]);
    setlike2(likeMap[1]);
    setlike3(likeMap[2]);

    let tetradColors = tinycolor(hex).tetrad();
    let tetraMap = tetradColors.map(function (t: any) {
      return t.toHexString();
    });
    settetra1(tetraMap[0]);
    settetra2(tetraMap[1]);
    settetra3(tetraMap[2]);

    let compColor = tinycolor(hex).complement().toHexString();
    let compMap = [hex, compColor, tinycolor(compColor).greyscale().toString()];
    setcomp1(compMap[0]);
    setcomp2(compMap[1]);
    setcomp3(compMap[2]);

    let colorsMap: string[];
    if (colorType === "triad") {
      colorsMap = triadMap;
    } else if (colorType === "mono") {
      colorsMap = monoMap;
    } else if (colorType === "split") {
      colorsMap = splitMap;
    } else if (colorType === "like") {
      colorsMap = likeMap;
    } else if (colorType === "tetra") {
      colorsMap = tetraMap;
    } else {
      colorsMap = compMap;
    }

    return colorsMap;
  };

  const onSubmit = (values: SetupStep4cFormValues) => {
    handleSaveAndContinue(values);
  };

  function hexToRGB(hex: string, alpha: number) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  const handleOnClick = (color: ColorResult) => {
    setStatePrimaryColor(color.hex);
    values.colorPrimary = color.hex;
    let colorType = stateColorType ? stateColorType : "comp";
    let colorsMap = setPalettes(color.hex, colorType);

    setStatePrimaryColor(colorsMap[0]);
    setStateSecondaryColor(colorsMap[1]);
    setStateTernaryColor(colorsMap[2]);
    setStateColorType(colorType);

    values.colorPrimary = colorsMap[0];
    values.colorSecondary = colorsMap[1];
    values.colorTernary = colorsMap[2];
    values.colorType = colorType;
  };

  const handleSelectCombination = (colorType: string) => {
    let colorType2 = colorType ? colorType : "comp";
    if (colorType2 && statePrimaryColor) {
      let colorsMap = setPalettes(statePrimaryColor, colorType2);

      setStatePrimaryColor(colorsMap[0]);
      setStateSecondaryColor(colorsMap[1]);
      setStateTernaryColor(colorsMap[2]);
      setStateColorType(colorType2);

      values.colorPrimary = colorsMap[0];
      values.colorSecondary = colorsMap[1];
      values.colorTernary = colorsMap[2];
      values.colorType = colorType2;

      if (!stateSecondaryColor && !stateTernaryColor) {
        setStateSecondaryColor(colorsMap[1]);
        setStateTernaryColor(colorsMap[2]);
      }
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title style={{ width: "100%" }}>
              <h6>{t("setup_wizard_7")}</h6>
              <div className={styles["setup-subheader"]}>
                <div className={styles["setup-subheader-main"]}>
                  {t("setup_wizard_137")}
                </div>
                <div
                  className={styles["setup-subheader-skip"]}
                  onClick={() => {
                    handleSkip(values);
                  }}
                >
                  {t("setup_wizard_4")}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                className={`${styles["setup-purpose__container"]} ${styles["setup-design__container"]}`}
              >
                <div
                  className={`picker-container ${styles["picker-container"]} ${
                    statePrimaryColor !== undefined
                      ? styles["picker-container-selected"]
                      : ``
                  }`}
                >
                  <div className={styles["setup-label"]}>
                    {t("setup_wizard_139")}
                  </div>
                  <Tabs id="color-picker" defaultActiveKey={defaultTab}>
                    <Tab title={t("editor_setup_wizard13")} eventKey="github">
                      <CompactPicker
                        color={statePrimaryColor}
                        onChange={(
                          color: ColorResult,
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleOnClick(color);
                        }}
                      />
                    </Tab>
                    <Tab title={t("editor_setup_wizard14")} eventKey="chrome">
                      <ChromePicker
                        disableAlpha={true}
                        color={statePrimaryColor}
                        onChange={(
                          color: ColorResult,
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleOnClick(color);
                        }}
                      />
                    </Tab>
                  </Tabs>
                </div>
                <div
                  className={`${styles["swatch-container"]} ${
                    stateColorType !== undefined
                      ? styles["swatch-container-selected"]
                      : ``
                  }`}
                >
                  <div
                    className={styles["setup-label"]}
                    style={{ marginBottom: "24px" }}
                  >
                    {t("setup_wizard_140")}
                  </div>
                  <div className={styles["harmony-rule-container"]}>
                    <div
                      className={`${styles["harmony-type"]} ${
                        stateColorType === "triad"
                          ? styles["harmony-type-active"]
                          : ``
                      }`}
                      onClick={() => {
                        handleSelectCombination("triad");
                      }}
                    >
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(triad1, 0.0)}`,
                        }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(triad2, 0.0)}`,
                        }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(triad3, 0.0)}`,
                        }}
                      ></div>
                    </div>
                    <div
                      className={`${styles["harmony-type"]} ${
                        stateColorType === "mono"
                          ? styles["harmony-type-active"]
                          : ``
                      }`}
                      onClick={() => {
                        handleSelectCombination("mono");
                      }}
                    >
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(mono1, 0.0)}` }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(mono2, 0.0)}` }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(mono3, 0.0)}` }}
                      ></div>
                    </div>
                  </div>
                  <div className={styles["harmony-rule-container"]}>
                    <div
                      className={`${styles["harmony-type"]} ${
                        stateColorType === "like"
                          ? styles["harmony-type-active"]
                          : ``
                      }`}
                      onClick={() => {
                        handleSelectCombination("like");
                      }}
                    >
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(like1, 0.0)}` }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(like2, 0.0)}` }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(like3, 0.0)}` }}
                      ></div>
                    </div>
                    <div
                      className={`${styles["harmony-type"]} ${
                        stateColorType === "split"
                          ? styles["harmony-type-active"]
                          : ``
                      }`}
                      onClick={() => {
                        handleSelectCombination("split");
                      }}
                    >
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(split1, 0.0)}`,
                        }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(split2, 0.0)}`,
                        }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(split3, 0.0)}`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className={styles["harmony-rule-container"]}>
                    <div
                      className={`${styles["harmony-type"]} ${
                        stateColorType === "tetra"
                          ? styles["harmony-type-active"]
                          : ``
                      }`}
                      onClick={() => {
                        handleSelectCombination("tetra");
                      }}
                    >
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(tetra1, 0.0)}`,
                        }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(tetra2, 0.0)}`,
                        }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{
                          backgroundColor: `${hexToRGB(tetra3, 0.0)}`,
                        }}
                      ></div>
                    </div>
                    <div
                      className={`${styles["harmony-type"]} ${
                        stateColorType === "comp"
                          ? styles["harmony-type-active"]
                          : ``
                      }`}
                      onClick={() => {
                        handleSelectCombination("comp");
                      }}
                    >
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(comp1, 0.0)}` }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(comp2, 0.0)}` }}
                      ></div>
                      <div
                        className={styles["harmony-swatch"]}
                        style={{ backgroundColor: `${hexToRGB(comp3, 0.0)}` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Button
              type="button"
              onClick={handleStepBack}
              style={{
                borderRadius: 32,
                color: "#007bff",
                backgroundColor: "white",
                borderColor: "#007bff",
              }}
            >
              {t("login_page_back")}
            </Button>
            <Button
              style={{ borderRadius: 32 }}
              type="submit"
              disabled={
                !values.colorPrimary ||
                !values.colorSecondary ||
                !values.colorTernary ||
                !values.colorType
              }
            >
              {t("setup_page_15")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
