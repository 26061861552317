import {
  FindLatestPageVersionListByWebsiteSlugUsingGETRequest,
  PageVersionResource,
  CreatePageVersionUsingPOSTRequest,
} from "@urux/arm-of-dorne-api";
import { pageVersionListApi, pageVersionApi } from "api";
import { PageVersion } from "editor/models/types";

export const createPageVersionAsync = async (
  websiteSlug: string,
  pageSlug: string,
  title: string,
  description: string,
  isIndex: boolean,
  content: string,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  isMaster: boolean
): Promise<PageVersion> => {
  const request: CreatePageVersionUsingPOSTRequest = {
    websiteSlug,
    pageSlug,
    request: {
      title,
      description,
      isIndex,
      content,
      primaryFont,
      secondaryFont,
      primaryColor,
      secondaryColor,
      ternaryColor,
      colorType,
      primaryRadius,
      secondaryRadius,
      primaryShadow,
      isMaster,
    },
  };

  return mapToPageVersion(
    await pageVersionApi.createPageVersionUsingPOST(request)
  );
};

export const findLatestPageVersionListByWebsiteSlugAsync = async (
  websiteSlug: string
): Promise<PageVersion[]> => {
  const request: FindLatestPageVersionListByWebsiteSlugUsingGETRequest = {
    websiteSlug,
  };

  return (
    await pageVersionListApi.findLatestPageVersionListByWebsiteSlugUsingGET(
      request
    )
  ).map(mapToPageVersion);
};

const mapToPageVersion = (resource: PageVersionResource): PageVersion => {
  return {
    pageId: resource.pageId!,
    version: resource.version!,
    title: resource.title!,
    description: resource.description!,
    content: resource.content!,
    created: resource.created!,
    isIndex: resource.isIndex!,
    isMaster: resource.isMaster!,
    slug: resource.slug!,
    primaryFont: resource.primaryFont!,
    secondaryFont: resource.secondaryFont!,
    primaryColor: resource.primaryColor!,
    ternaryColor: resource.ternaryColor!,
    colorType: resource.colorType!,
    secondaryColor: resource.secondaryColor!,
    primaryRadius: resource.primaryRadius!,
    secondaryRadius: resource.secondaryRadius!,
    primaryShadow: resource.primaryShadow!,
    pageUniqueId: resource.pageUniqueId!,
  };
};
