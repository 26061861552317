import { RichTextProperty, RTEAlignmentProperty } from "../properties";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { HeadingElement } from "editor/elements/base/Heading";
import { CardsElement } from "editor/elements/sections/Cards/Cards";
import { CardsContainerElement } from "editor/elements/components/CardsContainer/CardsContainer";
import { TemplatesFactory } from "./TemplatesFactory";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const vinylChild1Template: ElementTemplates = {};

vinylChild1Template["cards"] = LayoutEditor.createEmpty()
  .add(
    CardsElement.create({
      cardsStyle: "0",
      backgroundColorProperty: "#FFFFFF",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "parent",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(68, 0, 0);">Catalog</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("center"),
            })
          )
          .build()
      )
      .add(
        CardsContainerElement.create({
          cardsStyle: "1",
          cardsOrientation: "1",
          cardsAlignment: "2",
        })
      )
      .addChildrenArray([
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Terror Terror</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>The Brooms</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1599689019338-50deb475f380?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWxidW0lMjBjb3ZlcnxlbnwwfDJ8MHxibGFja3w%3D&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>yes, robbed.</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Qesha</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1454789548928-9efd52dc4031?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YWxidW0lMjBjb3ZlcnxlbnwwfDJ8MHxibGFja3w%3D&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Now in Cleveland</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Jim Orton</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1531777992189-ad52457fbe93?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWxidW0lMjBjb3ZlcnxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Here's a Problem</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Hiding McClusky</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1582561879313-ea72c2743e68?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGFydHxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Good Morning</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Thea</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1645919268997-e8f6d5ee81e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGFsYnVtJTIwY292ZXJ8ZW58MHwyfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>The Abyss</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>F93</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1619535541830-af170e005ef5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fHJhcHxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Cuttin' Grass</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Sturgill Simpson</div>"),
          new RTEAlignmentProperty("center"),
          "https://media.pitchfork.com/photos/5f8da936e6fc05c7f21c73ca/1:1/w_600/cuttin%20grass%20vol%201_sturgill%20simpson.jpg",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Yo!</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Francis M.</div>"),
          new RTEAlignmentProperty("center"),
          "https://i.discogs.com/HNYzyghm5c_uds7biio6XmptzqkSjyaGvIuko0_y8Ro/rs:fit/g:sm/q:40/h:300/w:300/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTYzNzU4/MDItMTQxNzcwODU4/Ni04NzYwLmpwZWc.jpeg",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>fome</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Los Tres</div>"),
          new RTEAlignmentProperty("center"),
          "https://sonarfm.cl/sonarfm/site/artic/20161128/imag/foto_0000001620161128113640.jpg",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Gracias a La Vida</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Mercedes Sosa</div>"),
          new RTEAlignmentProperty("center"),
          "https://m.media-amazon.com/images/I/51cVhLyO4oL._SY355_.jpg",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Lovely Hula Hands</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Cocounut Willie</div>"),
          new RTEAlignmentProperty("center"),
          "https://c.saavncdn.com/025/Lovely-Hula-Hands-English-2007-500x500.jpg",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Himig ng Pag-ibig</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Asin</div>"),
          new RTEAlignmentProperty("center"),
          "https://i.scdn.co/image/ab67616d0000b27334c0b652019b33224fc27d08",
          "2"
        ),
      ])
      .build()
  )
  .build();
