import React, { useState, HTMLAttributes } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as addToolbarActions from "../../components/impl/AddElementToolbar/addToolbarActions";
import * as addToolbarGuidesActions from "../../components/impl/AddElementToolbar/addToolbarGuidesActions";
import * as editorActions from "../../components/impl/Editor/editorActions";
import { getSelectedElementId } from "../../selectors";
import { ElementType } from "../types";
import { ElementComponentProps, AnyProperties } from "common/elements/types";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";
import { logActivity } from "auth/authActions";
import { editorPage } from "editor/components/impl/Editor/Editor";
// import { EditRounded } from "@material-ui/icons";

// import styles from "./Element.module.scss";

export type Props = {
  as: React.FunctionComponent<ElementComponentProps<AnyProperties>>;
  elementId: string;
  elementParentId?: string;
  elementKey: string;
  elementParentKey: string | null;
  properties: { [propertyKey: string]: any };
  index: number;
  selectedRef: React.MutableRefObject<any>;
  width?: number | null;
  height?: number | null;
};

export const Element: React.FunctionComponent<
  Props & HTMLAttributes<any> & Partial<ElementType>
> = ({
  as,
  elementId,
  elementParentId,
  elementKey,
  elementParentKey,
  index,
  selectedRef,
  children,
  wdith,
  height,
  ...props
}) => {
  const selectedElementId = useSelector(getSelectedElementId);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const username = useSelector(getUsername);
  // const hoveredElementId = useSelector(getHoveredElementId);
  const [isHover, setIsHover] = useState(false);
  // const [onHoverEditPosLeft, setOnHoverEditPosLeft] = useState(0);
  // const [onHoverEditPosBottom, setOnHoverEditPosBottom] = useState(0);
  // const [elementWidth, setElementWidth] = useState(0);
  const dispatch = useDispatch();
  const isSelected = selectedElementId === elementId;
  const As = as;

  const handleMouseOver = (event: React.MouseEvent, id: string) => {
    setIsHover(true);
    dispatch(editorActions.setHoveredElement(id, index));
    event.stopPropagation();

    // const clientRect = event.currentTarget.getBoundingClientRect();
    // if (clientRect.width > 300) {
    //   setOnHoverEditPosLeft(clientRect.left - 110 + clientRect.width / 2);
    // } else {
    //   setOnHoverEditPosLeft(clientRect.left - 10 + clientRect.width / 2);
    // }
    // setOnHoverEditPosBottom(clientRect.top + clientRect.height - 12);
    // setElementWidth(clientRect.width);

    // if(selectedElementId === hoveredElementId){

    // }
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    setIsHover(false);
    event.stopPropagation();
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!elementParentId || !elementParentKey) {
      return;
    }

    const clientRect = event.currentTarget.getBoundingClientRect();
    const rect = {
      x: clientRect.left,
      y: clientRect.top,
      width: clientRect.width,
      height: clientRect.height,
    };
    const scroll = {
      x: window.scrollX,
      y: window.scrollY,
    };
    dispatch(
      addToolbarActions.setHoveredElementWithMousePosition(
        elementId,
        elementKey,
        elementParentId,
        elementParentKey,
        index,
        rect,
        scroll,
        event.clientX,
        event.clientY,
        () => {
          event.stopPropagation();
        },
        true
      )
    );
    dispatch(
      addToolbarGuidesActions.setHoveredElementWithMousePosition(
        elementId,
        elementKey,
        elementParentId,
        elementParentKey,
        index,
        rect,
        scroll,
        event.clientX,
        event.clientY,
        () => {
          event.stopPropagation();
        }
      )
    );
  };

  const handleClick = (event: React.MouseEvent) => {
    dispatch(editorActions.setSelectedElement(elementId, index));
    dispatch(
      logActivity(
        "SELECT ELEMENT / OPEN DIALOG",
        editorPage,
        username,
        elementKey
      )
    );
    dispatch(addToolbarActions.disable());
    dispatch(addToolbarGuidesActions.disable());
    event.stopPropagation();
  };

  return (
    <>
      {/* {isHover && !isSelected ? (
        <div
          className={styles["edit-hover"]}
          style={{
            position: "absolute",
            left: onHoverEditPosLeft,
            top: onHoverEditPosBottom,
          }}
        >
          {elementWidth > 300 ? (
            <label>Click inside the box to Edit</label>
          ) : (
            <EditRounded />
          )}
        </div>
      ) : (
        <></>
      )} */}
      <As
        children={children}
        id={elementId}
        elementKey={elementKey}
        properties={props}
        innerRef={isSelected ? selectedRef : undefined}
        className={
          "element " +
          (isHover ? "hover " : "") +
          (isSelected ? "selected " : "")
        }
        websiteSlug={websiteSlug ? websiteSlug : ""}
        onMouseOver={(event: React.MouseEvent) => {
          handleMouseOver(event, elementId);
        }}
        onMouseOut={handleMouseOut}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        style={
          isSelected
            ? {
                boxShadow: "rgba(158, 202, 237, 0.75) 2px 2px 2px 2px",
              }
            : {}
        }
      />
    </>
  );
};
