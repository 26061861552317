import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from "@redux-saga/core/effects";
import produce from "immer";
import { Reducer } from "redux";

import {
  startRequest,
  finishRequest,
  finishRequestWithErrors,
} from "api/apiActions";
import { Page, PageVersion } from "editor/models/types";
import { findPageListByWebsiteSlugAsync } from "editor/api/page";
import { getResponseJsonAsync } from "editor/api/common";
import { createPageVersionAsync } from "editor/api/pageVersion";
import { getPageWithLatestVersion, getCurrentPageSlug } from "./selectors";
import { getUserWebsiteSlug } from "auth/selectors";
import { latestPageVersionCreated } from "./latestPageVersionList";
import { currentPageDetailsUpdateSucceeded } from "editor/components/impl/Editor/editorActions";
import { Layout } from "common/elements/types";
import { getLayout } from "../states/selectors";

export type State = {
  pageList: { [pageId: number]: Page };
  state: "success" | "in-progress" | "failure" | "modified" | null;
};

export const EDITOR_PAGE_LIST_REQUESTED = "EDITOR_PAGE_LIST_REQUESTED";

type PageListRequestedAction = {
  type: typeof EDITOR_PAGE_LIST_REQUESTED;
  websiteSlug: string;
};

export const pageListRequested = (
  websiteSlug: string
): PageListRequestedAction => {
  return {
    type: EDITOR_PAGE_LIST_REQUESTED,
    websiteSlug,
  };
};

export const EDITOR_PAGE_LIST_REQUEST_SUCCEEDED =
  "EDITOR_PAGE_LIST_REQUEST_SUCCEEDED";

type PageListRequestSucceededAction = {
  type: typeof EDITOR_PAGE_LIST_REQUEST_SUCCEEDED;
  pageList: Page[];
};

const pageListRequestSucceeded = (
  pageList: Page[]
): PageListRequestSucceededAction => {
  return {
    type: EDITOR_PAGE_LIST_REQUEST_SUCCEEDED,
    pageList,
  };
};

export const EDITOR_PAGE_LIST_REQUEST_FAILED =
  "EDITOR_PAGE_LIST_REQUEST_FAILED";

type PageListRequestFailedAction = {
  type: typeof EDITOR_PAGE_LIST_REQUEST_FAILED;
};

const pageListRequestFailed = (): PageListRequestFailedAction => {
  return {
    type: EDITOR_PAGE_LIST_REQUEST_FAILED,
  };
};

export const EDITOR_PAGE_DETAILS_UPDATED = "EDITOR_PAGE_DETAILS_UPDATED";

type PageDetailsUpdatedAction = {
  type: typeof EDITOR_PAGE_DETAILS_UPDATED;
  pageId: number;
  title: string;
  description: string;
  isIndex: boolean;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
};

export const pageDetailsUpdated = (
  pageId: number,
  title: string,
  description: string,
  isIndex: boolean,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string
): PageDetailsUpdatedAction => {
  return {
    type: EDITOR_PAGE_DETAILS_UPDATED,
    pageId,
    title,
    description,
    isIndex,
    primaryFont,
    secondaryFont,
    primaryColor,
    secondaryColor,
    ternaryColor,
    colorType,
    primaryRadius,
    secondaryRadius,
    primaryShadow,
  };
};

export const EDITOR_PAGE_DETAILS_UPDATE_SUCCEEDED =
  "EDITOR_PAGE_DETAILS_UPDATE_SUCCEEDED";

type PageDetailsUpdateSucceededAction = {
  type: typeof EDITOR_PAGE_DETAILS_UPDATE_SUCCEEDED;
};

const pageDetailsUpdateSucceeded = (): PageDetailsUpdateSucceededAction => {
  return {
    type: EDITOR_PAGE_DETAILS_UPDATE_SUCCEEDED,
  };
};

export const EDITOR_PAGE_DETAILS_UPDATE_FAILED =
  "EDITOR_PAGE_DETAILS_UPDATE_FAILED";

type PageDetailsUpdateFailedAction = {
  type: typeof EDITOR_PAGE_DETAILS_UPDATE_FAILED;
};

const pageDetailsUpdateFailed = (): PageDetailsUpdateFailedAction => {
  return {
    type: EDITOR_PAGE_DETAILS_UPDATE_FAILED,
  };
};

export const EDITOR_PAGE_DETAILS_UPDATE_RESET =
  "EDITOR_PAGE_DETAILS_UPDATE_RESET";

type PageDetailsUpdateResetAction = {
  type: typeof EDITOR_PAGE_DETAILS_UPDATE_RESET;
};

export const pageDetailsUpdateReset = (): PageDetailsUpdateResetAction => {
  return {
    type: EDITOR_PAGE_DETAILS_UPDATE_RESET,
  };
};

export const EDITOR_PAGE_DETAILS_UPDATE_MODIFIED =
  "EDITOR_PAGE_DETAILS_UPDATE_MODIFIED";

type PageDetailsUpdateModifiedAction = {
  type: typeof EDITOR_PAGE_DETAILS_UPDATE_MODIFIED;
};

export const pageDetailsUpdateModified = (): PageDetailsUpdateModifiedAction => {
  return {
    type: EDITOR_PAGE_DETAILS_UPDATE_MODIFIED,
  };
};

type Action =
  | PageListRequestedAction
  | PageListRequestSucceededAction
  | PageListRequestFailedAction
  | PageDetailsUpdatedAction
  | PageDetailsUpdateSucceededAction
  | PageDetailsUpdateFailedAction
  | PageDetailsUpdateResetAction
  | PageDetailsUpdateModifiedAction;

const initialState: State = {
  pageList: {},
  state: null,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case EDITOR_PAGE_LIST_REQUEST_SUCCEEDED:
      return handlePageListRequestSucceeded(state, action);
    case EDITOR_PAGE_DETAILS_UPDATED:
      return handlePageDetailsUpdated(state, action);
    case EDITOR_PAGE_DETAILS_UPDATE_SUCCEEDED:
      return handlePageDetailsUpdateSucceeded(state, action);
    case EDITOR_PAGE_DETAILS_UPDATE_FAILED:
      return handlePageDetailsUpdateFailed(state, action);
    case EDITOR_PAGE_DETAILS_UPDATE_RESET:
      return handlePageDetailsUpdateReset(state, action);
    case EDITOR_PAGE_DETAILS_UPDATE_MODIFIED:
      return handlePageDetailsUpdateModified(state, action);
    default:
      return state;
  }
};

const handlePageDetailsUpdated = (
  state: State,
  action: PageDetailsUpdatedAction
): State =>
  produce(state, (draft) => {
    draft.state = "in-progress";
  });

const handlePageListRequestSucceeded = (
  state: State,
  action: PageListRequestSucceededAction
): State =>
  produce(state, (draft) => {
    draft.pageList = action.pageList.reduce(
      (acc, page) => ({ ...acc, [page.id]: page }),
      {}
    );
  });

const handlePageDetailsUpdateSucceeded = (
  state: State,
  action: PageDetailsUpdateSucceededAction
): State =>
  produce(state, (draft) => {
    draft.state = "success";
  });

const handlePageDetailsUpdateFailed = (
  state: State,
  action: PageDetailsUpdateFailedAction
): State =>
  produce(state, (draft) => {
    draft.state = "failure";
  });

const handlePageDetailsUpdateReset = (
  state: State,
  action: PageDetailsUpdateResetAction
): State =>
  produce(state, (draft) => {
    draft.state = null;
  });

const handlePageDetailsUpdateModified = (
  state: State,
  action: PageDetailsUpdateModifiedAction
): State =>
  produce(state, (draft) => {
    draft.state = "modified";
  });

function* loadPageList(action: PageListRequestedAction) {
  yield put(startRequest());
  try {
    const pageList: Page[] = yield call(
      findPageListByWebsiteSlugAsync,
      action.websiteSlug
    );
    yield put(pageListRequestSucceeded(pageList));
    yield put(finishRequest());
  } catch (e) {
    yield put(pageListRequestFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* updatePageDetails(action: PageDetailsUpdatedAction) {
  yield put(startRequest());
  try {
    const websiteSlug: string = yield select(getUserWebsiteSlug);
    const page: Page = yield select(getPageWithLatestVersion, action.pageId);
    const layout: Layout = yield select(getLayout);
    const currentPageSlug: string = yield select(getCurrentPageSlug);
    const content = JSON.stringify(layout);
    const latestVersion: PageVersion = yield call(
      createPageVersionAsync,
      websiteSlug,
      page.slug,
      action.title,
      action.description,
      action.isIndex,
      currentPageSlug === page.slug ? content : page.content,
      action.primaryFont,
      action.secondaryFont,
      action.primaryColor,
      action.secondaryColor,
      action.ternaryColor,
      action.colorType,
      action.primaryRadius,
      action.secondaryRadius,
      action.primaryShadow,
      page.isMaster
    );

    yield put(pageDetailsUpdateSucceeded());
    yield put(latestPageVersionCreated(latestVersion));

    if (currentPageSlug === page.slug) {
      yield put(
        currentPageDetailsUpdateSucceeded(
          latestVersion.pageId,
          latestVersion.title,
          latestVersion.description,
          latestVersion.version,
          latestVersion.isIndex,
          action.primaryFont,
          action.secondaryFont,
          action.primaryColor,
          action.secondaryColor,
          action.ternaryColor,
          action.colorType,
          action.primaryRadius,
          action.secondaryRadius,
          action.primaryShadow
        )
      );
    }
    yield put(finishRequest());
  } catch (e) {
    console.log("Error " + e);
    yield put(pageDetailsUpdateFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

export function* rootSaga() {
  yield takeLatest(EDITOR_PAGE_LIST_REQUESTED, loadPageList);
  yield takeEvery(EDITOR_PAGE_DETAILS_UPDATED, updatePageDetails);
}
