import { MapRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { GoogleMapsEmbedUrlProperty } from "editor/properties/GoogleMapsEmbedUrlProperty";
import { DimensionProperty } from "editor/properties";
import styles from "./GoogleMapsEmbed.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import {
  googleMapsEmbedElementKey,
  GoogleMapsEmbedProperties,
} from "common/elements/components/GoogleMapsEmbed";
import { useTranslation } from "react-i18next";

export class GoogleMapsEmbedElement {
  static readonly elementKey = googleMapsEmbedElementKey;
  static readonly label = "element_googlemapsembed_label";
  static readonly icon = MapRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly allowedChildren = [];
  static readonly elementPreviewUrl =
    "/img/add-GoogleMapsEmbed-element-preview-default.svg";
  static readonly elementDescription = "element_googlemapsembed_description";
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<GoogleMapsEmbedProperties> = {
    googleMapsUrl: GoogleMapsEmbedUrlProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <GoogleMapsEmbedPropertiesComponent element={element} />;
  }

  static create(properties: {
    googleMapsUrl: GoogleMapsEmbedUrlProperty;
    dimension?: DimensionProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const GoogleMapsEmbedPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<
    typeof GoogleMapsEmbedElement.properties
  >(GoogleMapsEmbedElement.properties, element);

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="googleMaps">
        <Tab eventKey="googleMaps" title={t("dialog_42")}>
          {renderer.render("googleMapsUrl")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(GoogleMapsEmbedElement);
