import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Facebook, Twitter } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { transportChild1Template } from "./transportChild1";
import { transportChild2Template } from "./transportChild2";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { HeadingElement } from "editor/elements/base/Heading";
import { LeadElement } from "editor/elements/base/Lead";
import { ArticleElement } from "editor/elements/components/Article";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { ImageElement } from "editor/elements/base/Image/Image";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const transportTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "transport";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 4,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 5,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultNavbar(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/transport/main.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        "1",
        new BackgroundColorProperty("#ffffff"),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "2",
        "2"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/transport/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright DRV-U " + new Date().getFullYear()
      );

    default:
      return transportTemplates[layoutKey];
  }
};

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page Transport",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: transportChild1Template,
  },
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "transportChild1Template",
    details: {
      title: "About Us",
      slug: randomSlug(6),
      description: "About Us",
    },
    template: {
      template: transportChild1Template,
    },
  },
  {
    templateName: "transportChild2Template",
    details: {
      title: "Contact Us",
      slug: randomSlug(6),
      description: "Contact Us",
    },
    template: {
      template: transportChild2Template,
    },
  },
];

transportTemplates["navbar"] = TemplatesFactory.createDefaultNavbar(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/transport/main.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "1",
  new BackgroundColorProperty("#ffffff"),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "2",
  "2"
);

transportTemplates["cover"] = TemplatesFactory.createDefaultCover(
  "6",
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Book an Appointment", "", false, false, false),
  new RichTextProperty(
    `<h1><span style="color: rgb(0, 0, 0);">We'll pick you up and drop you off. <br>Anywhere, anytime.</span></h1>`
  ),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://images.unsplash.com/photo-1576793048000-494aaa93d160?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNTExNzJ8MHwxfHNlYXJjaHwzfHx2YW58ZW58MHwwfHx8MTY1MjcxMjEyMw&ixlib=rb-1.2.1&q=85",
    "no-repeat",
    "50%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(""),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 80)
);

transportTemplates[
  "cards"
] = TemplatesFactory.createDefaultCards(
  new LeadStyleProperty("1"),
  new RichTextProperty("<h2>Our Services</h2>"),
  new RTEAlignmentProperty("center"),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit"
  ),
  new RTEAlignmentProperty("center"),
  "0",
  [
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Airport Transfer</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Book", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam dolorum at praesentium impedit accusantium alias quo</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1613750590555-5ad35bd95a99?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80",
      "2"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Moving Services</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Schedule", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Rerferendis odit molestiae velit numquam ad sapiente hic fuga aliquam recusandae facere, aperiam explicabo</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1520532240459-395dc4a823b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fHZhbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      "2"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Rental Services</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Rent", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Accusantium, velit placeat sunt, minus excepturi expedita magnam numquam laborum asperiores quod nisi, fugit iusto reiciendis.</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1573872762441-e4faba0cead7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjJ8fHZhbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      "2"
    ),
  ]
);

transportTemplates["articles"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#ECF7FF",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                "<h2>Safety and Comfort Guaranteed</h2>"
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                "<div>Or your money back in 24hrs</div><br /><div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore esse culpa sunt debitis laborum sit voluptate animi ex iusto accusantium.</div>"
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1597685204565-110abf469a1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

transportTemplates["articles2"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#ffffff",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1535953472862-9cc610a70f8a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty("<h2>Testimonials</h2>"),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><span style="font-style: italic;"><br></span></div><div><span style="font-style: italic;">"This was an exceptional service. Booking was simple, service was efficient with timely and informative reminders about pick up etc and the drivers - Leillands and especially Pavels - were exceptionally professional. A wonderful service that I will recommend highly."</span></div></div><div><span style="font-style: italic;"><b>- Janice Cumia</b></span></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .build()
  )
  .build();

transportTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("1"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Book an Appointment</h2>"),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Fill in your details below and we'll be in contact with you soon."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

transportTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/transport/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright DRV-U " + new Date().getFullYear()
);
