import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";

import { Formik, Form } from "formik";
import styles from "./ArticlesSectionStyleProperty.module.scss";
import { ReactComponent as CardsTiles } from "../../../img/CardsTiles.svg";
import { ReactComponent as CardsRows } from "../../../img/CardsRows.svg";

import { useTranslation } from "react-i18next";

export class ArticlesSectionStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <ArticlesSectionStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new ArticlesSectionStyleProperty(value);
  }
}

const ArticlesSectionStylePropertyComponent: PropertyComponent<ArticlesSectionStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();

  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };
  const { t } = useTranslation();
  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <Form className="form">
        <div className="form__group">
          <div className="medium-padded-container">
            <div className={styles["buttons-container"]}>
              <div
                className={`${styles["button"]} ${
                  style === "1" ? styles["active"] : ""
                }`}
                onClick={() => handleStyleChange("1")}
              >
                <div>
                  <CardsTiles className={styles["icon"]} />
                  {t("dialog_97")}
                </div>
              </div>
              <div
                className={`${styles["button"]} ${
                  style === "2" ? styles["active"] : ""
                }`}
                onClick={() => handleStyleChange("2")}
              >
                <div>
                  <CardsRows className={styles["icon"]} />
                  {t("dialog_97")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
