import produce from "immer";
import { Reducer } from "redux";

import {
  Action,
  SetSelectedElementAction,
  SetDraggableElementAction,
  SetHoveredElementAction,
  ResizeAction,
  CancelElementEditionAction,
  PageCreatedFromTemplateAction,
  PageCreationFromTemplateSucceededAction,
  SavePageSuccessAction,
  PageLatestVersionLoadSucceededAction,
  CurrentPageDetailsUpdateSucceededAction,
  HandleRTTempTextAction,
  SaveRTTempTextAction,
  ResetRTTempAction,
  GetPageTemplateSuccessAction,
  GetPageTemplateFailureAction,
  EDITOR_PAGE_CREATED_FROM_TEMPLATE,
  EDITOR_PAGE_CREATION_FROM_TEMPLATE_SUCCEEDED,
  EDITOR_PAGE_LATEST_VERSION_LOAD_SUCCEEDED,
  EDITOR_SAVE_PAGE_SUCCESS,
  EDITOR_CURRENT_PAGE_DETAILS_UPDATE_SUCCEEDED,
  EDITOR_SET_SELECTED_ELEMENT,
  EDITOR_SET_HOVERED_ELEMENT,
  EDITOR_CANCEL_ELEMENT_EDITION,
  EDITOR_RESIZE,
  EDITOR_HANDLE_RT_TEMP_TEXT_ACTION,
  EDITOR_SAVE_RT_TEMP_TEXT_ACTION,
  EDITOR_RESET_RT_TEMP_ACTION,
  ADMIN_GET_PAGE_TEMPLATE_SUCCESS,
  ADMIN_GET_PAGE_TEMPLATE_FAILURE,
  EDITOR_PAGE_CREATION_FROM_TEMPLATE_TREATMENT_SUCCEEDED,
  PageCreationFromTemplateTreatmentSucceededAction,
  EDITOR_TREATMENT_PAGE_LATEST_VERSION_LOAD_SUCCEEDED,
  TreatmentPageLatestVersionLoadSucceededAction,
  GetTreatmentPageSlugSuccessAction,
  EDITOR_GET_TREATMENT_PAGE_SLUG_SUCCESS,
  EDITOR_SET_DRAGGABLE_ELEMENT,
  EDITOR_CANCEL_DRAGGABLE_ELEMENT,
  CancelDraggableElementAction,
  EDITOR_SET_RESIZEABLE_ELEMENT,
  EDITOR_CANCEL_RESIZEABLE_ELEMENT,
  SetResizeableElementAction,
  CancelResizeableElementAction,
} from "./editorActions";
import { TemplateKey } from "../../../templates/types";
import {
  ADMIN_DELETE_PAGE_SUCCESS,
  DeletePageSuccessAction,
} from "admin/pageActions";
import { LogoutSuccessAction } from "auth/authActions";

export type State = {
  id: number | undefined;
  treatmentId: number | undefined;
  templateKey: TemplateKey | undefined;
  pageSlug: string | null;
  pageUniqueId: string | null;
  treatmentPageSlug: string | null;
  treatmentPageSlugs: { [masterSlug: string]: string };
  title: string | null;
  description: string | null;
  isIndex: boolean;
  version: number | null;
  treatmentVersion: number | null;
  selectedElementId: string | null;
  selectedElementIndex: number | null;
  draggableElementId: string | null;
  hoveredElementId: string | null;
  hoveredElementIndex: number | null;
  resizeableElementId: string | null;
  rtTempText: string | undefined;
  rtTempPropId: string | undefined;
  isRtTempTextSaved: boolean;
  primaryFont: string | null;
  secondaryFont: string | null;
  primaryColor: string | null;
  secondaryColor: string | null;
  ternaryColor: string | null;
  colorType: string | null;
  primaryRadius: string | null;
  secondaryRadius: string | null;
  primaryShadow: string | null;
};

const initialState: State = {
  id: undefined,
  treatmentId: undefined,
  templateKey: undefined,
  pageSlug: null,
  pageUniqueId: null,
  treatmentPageSlug: null,
  treatmentPageSlugs: {},
  title: null,
  description: null,
  isIndex: false,
  version: null,
  treatmentVersion: null,
  selectedElementId: null,
  selectedElementIndex: null,
  draggableElementId: null,
  hoveredElementId: null,
  hoveredElementIndex: null,
  resizeableElementId: null,
  rtTempText: undefined,
  rtTempPropId: undefined,
  isRtTempTextSaved: false,
  primaryFont: null,
  secondaryFont: null,
  primaryColor: null,
  secondaryColor: null,
  ternaryColor: null,
  colorType: null,
  primaryRadius: null,
  secondaryRadius: null,
  primaryShadow: null,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case EDITOR_PAGE_CREATED_FROM_TEMPLATE:
      return handlePageCreatedFromTemplate(state, action);

    case EDITOR_PAGE_CREATION_FROM_TEMPLATE_SUCCEEDED:
      return handlePageCreationFromTemplateSucceeded(state, action);

    case EDITOR_PAGE_CREATION_FROM_TEMPLATE_TREATMENT_SUCCEEDED:
      return handlePageCreationFromTemplateTreatmentSucceeded(state, action);

    case EDITOR_PAGE_LATEST_VERSION_LOAD_SUCCEEDED:
      return handlePageLatestVersionLoadSucceeded(state, action);

    case EDITOR_TREATMENT_PAGE_LATEST_VERSION_LOAD_SUCCEEDED:
      return handleTreatmentPageLatestVersionLoadSucceeded(state, action);

    case EDITOR_SAVE_PAGE_SUCCESS:
      return handleSavePageSuccess(state, action);

    case EDITOR_CURRENT_PAGE_DETAILS_UPDATE_SUCCEEDED:
      return handleCurrentPageDetailsUpdateSucceeded(state, action);

    case EDITOR_SET_SELECTED_ELEMENT:
      return handleSetSelectedElement(state, action);

    case EDITOR_SET_HOVERED_ELEMENT:
      return handleSetHoveredElement(state, action);

    case EDITOR_SET_DRAGGABLE_ELEMENT:
      return handleSetDraggableElement(state, action);

    case EDITOR_CANCEL_ELEMENT_EDITION:
      return handleCancelElementEdition(state, action);

    case EDITOR_CANCEL_DRAGGABLE_ELEMENT:
      return handleCancelDragElementEdition(state, action);

    case EDITOR_SET_RESIZEABLE_ELEMENT:
      return handleSetResizeableElement(state, action);

    case EDITOR_CANCEL_RESIZEABLE_ELEMENT:
      return handleCancelResizeableElementEdition(state, action);

    case EDITOR_RESIZE:
      return handleResize(state, action);

    case EDITOR_HANDLE_RT_TEMP_TEXT_ACTION:
      return handleRtTempText(state, action);

    case EDITOR_SAVE_RT_TEMP_TEXT_ACTION:
      return handleSaveRtTempText(state, action);

    case EDITOR_RESET_RT_TEMP_ACTION:
      return handleResetRtTemp(state, action);

    case ADMIN_GET_PAGE_TEMPLATE_SUCCESS:
      return handleGetPageTemplateSuccess(state, action);

    case ADMIN_GET_PAGE_TEMPLATE_FAILURE:
      return handleGetPageTemplateFailure(state, action);

    case EDITOR_GET_TREATMENT_PAGE_SLUG_SUCCESS:
      return handleGetTreatmentPageSlugSucceeded(state, action);

    case ADMIN_DELETE_PAGE_SUCCESS:
      return handleDeletePageSuccess(state, action);

    case "AUTH_LOGOUT_SUCCESS":
      return handleLogoutSuccess(state, action);

    default:
      return state;
  }
};

const handleLogoutSuccess = (state: State, _: LogoutSuccessAction): State => {
  return {
    ...state,
    id: undefined,
    treatmentId: undefined,
    templateKey: undefined,
    pageSlug: null,
    pageUniqueId: null,
    treatmentPageSlug: null,
    treatmentPageSlugs: {},
    title: null,
    description: null,
    isIndex: false,
    version: null,
    treatmentVersion: null,
    selectedElementId: null,
    selectedElementIndex: null,
    draggableElementId: null,
    hoveredElementId: null,
    hoveredElementIndex: null,
    resizeableElementId: null,
    rtTempText: undefined,
    rtTempPropId: undefined,
    isRtTempTextSaved: false,
    primaryFont: null,
    secondaryFont: null,
    primaryColor: null,
    secondaryColor: null,
    ternaryColor: null,
    colorType: null,
    primaryRadius: null,
    secondaryRadius: null,
    primaryShadow: null,
  };
};

const handleDeletePageSuccess = (
  state: State,
  action: DeletePageSuccessAction
): State =>
  produce(state, (draft) => {
    draft.id = undefined;
    draft.treatmentId = undefined;
    draft.templateKey = undefined;
    draft.pageSlug = null;
    draft.pageUniqueId = null;
    draft.treatmentPageSlug = null;
    draft.treatmentPageSlugs = {};
    draft.title = null;
    draft.description = null;
    draft.isIndex = false;
    draft.version = null;
    draft.treatmentVersion = null;
    draft.selectedElementId = null;
    draft.selectedElementIndex = null;
    draft.draggableElementId = null;
    draft.hoveredElementId = null;
    draft.hoveredElementIndex = null;
    draft.resizeableElementId = null;
    draft.rtTempText = undefined;
    draft.rtTempPropId = undefined;
    draft.isRtTempTextSaved = false;
    draft.primaryFont = null;
    draft.secondaryFont = null;
    draft.primaryColor = null;
    draft.secondaryColor = null;
    draft.ternaryColor = null;
    draft.colorType = null;
    draft.primaryRadius = null;
    draft.secondaryRadius = null;
    draft.primaryShadow = null;
  });

const handleGetTreatmentPageSlugSucceeded = (
  state: State,
  action: GetTreatmentPageSlugSuccessAction
): State =>
  produce(state, (draft) => {
    draft.treatmentPageSlug = action.treatmentSlug;
  });

const handleGetPageTemplateSuccess = (
  state: State,
  action: GetPageTemplateSuccessAction
): State =>
  produce(state, (draft) => {
    draft.templateKey = action.template;
    draft.primaryFont = action.primaryFont;
    draft.secondaryFont = action.secondaryFont;
    draft.primaryColor = action.primaryColor;
    draft.secondaryColor = action.secondaryColor;
    draft.ternaryColor = action.ternaryColor;
    draft.colorType = action.colorType;
    draft.primaryRadius = action.primaryRadius;
    draft.secondaryRadius = action.secondaryRadius;
    draft.primaryShadow = action.primaryShadow;
  });

const handleGetPageTemplateFailure = (
  state: State,
  action: GetPageTemplateFailureAction
): State =>
  produce(state, (draft) => {
    draft.templateKey = undefined;
    draft.primaryFont = null;
    draft.secondaryFont = null;
    draft.primaryColor = null;
    draft.secondaryColor = null;
    draft.ternaryColor = null;
    draft.colorType = null;
    draft.primaryRadius = null;
    draft.secondaryRadius = null;
    draft.primaryShadow = null;
  });

const handlePageCreatedFromTemplate = (
  state: State,
  action: PageCreatedFromTemplateAction
): State =>
  produce(state, (draft) => {
    // draft.id = action.id;
    draft.templateKey = action.templateKey;
    draft.title = action.title;
    draft.description = action.description;
    draft.isIndex = action.isIndex;
    draft.primaryFont = action.primaryFont;
    draft.secondaryFont = action.secondaryFont;
    draft.primaryColor = action.primaryColor;
    draft.secondaryColor = action.secondaryColor;
    draft.ternaryColor = action.ternaryColor;
    draft.colorType = action.colorType;
    draft.primaryRadius = action.primaryRadius;
    draft.secondaryRadius = action.secondaryRadius;
    draft.primaryShadow = action.primaryShadow;
  });

const handlePageCreationFromTemplateSucceeded = (
  state: State,
  action: PageCreationFromTemplateSucceededAction
): State =>
  produce(state, (draft) => {
    draft.id = action.id;
    draft.pageSlug = action.pageSlug;
    draft.pageUniqueId = action.pageUniqueId;
    draft.version = action.version;
  });

const handlePageCreationFromTemplateTreatmentSucceeded = (
  state: State,
  action: PageCreationFromTemplateTreatmentSucceededAction
): State =>
  produce(state, (draft) => {
    draft.treatmentId = action.id;
    draft.treatmentPageSlug = action.pageSlug;
    draft.treatmentVersion = action.version;
    draft.treatmentPageSlugs[action.masterSlug] = action.masterSlug;
  });

const handlePageLatestVersionLoadSucceeded = (
  state: State,
  action: PageLatestVersionLoadSucceededAction
): State =>
  produce(state, (draft) => {
    draft.id = action.pageId;
    draft.pageSlug = action.pageSlug;
    draft.pageUniqueId = action.pageUniqueId;
    draft.templateKey = action.templateKey;
    draft.title = action.title;
    draft.description = action.description;
    draft.isIndex = action.isIndex;
    draft.version = action.version;
    draft.primaryFont = action.primaryFont;
    draft.secondaryFont = action.secondaryFont;
    draft.primaryColor = action.primaryColor;
    draft.secondaryColor = action.secondaryColor;
    draft.ternaryColor = action.ternaryColor;
    draft.colorType = action.colorType;
    draft.primaryRadius = action.primaryRadius;
    draft.secondaryRadius = action.secondaryRadius;
    draft.primaryShadow = action.primaryShadow;
    if (action.treatmentSlug) draft.treatmentPageSlug = action.treatmentSlug;
  });

const handleTreatmentPageLatestVersionLoadSucceeded = (
  state: State,
  action: TreatmentPageLatestVersionLoadSucceededAction
): State =>
  produce(state, (draft) => {
    draft.treatmentPageSlug = action.slug;
  });

const handleSavePageSuccess = (
  state: State,
  action: SavePageSuccessAction
): State =>
  produce(state, (draft) => {
    draft.version = action.version;
  });

const handleCurrentPageDetailsUpdateSucceeded = (
  state: State,
  action: CurrentPageDetailsUpdateSucceededAction
): State =>
  produce(state, (draft) => {
    draft.id = action.pageId;
    draft.title = action.title;
    draft.description = action.description;
    draft.version = action.version;
    draft.isIndex = action.isIndex;
    draft.primaryFont = action.primaryFont;
    draft.secondaryFont = action.secondaryFont;
    draft.primaryColor = action.primaryColor;
    draft.secondaryColor = action.secondaryColor;
    draft.ternaryColor = action.ternaryColor;
    draft.colorType = action.colorType;
    draft.primaryRadius = action.primaryRadius;
    draft.secondaryRadius = action.secondaryRadius;
    draft.primaryShadow = action.primaryShadow;
  });

const handleSetSelectedElement = (
  state: State,
  action: SetSelectedElementAction
): State =>
  produce(state, (draft) => {
    draft.selectedElementId = action.elementId;
    draft.selectedElementIndex = action.elementIndex;
  });

const handleSetDraggableElement = (
  state: State,
  action: SetDraggableElementAction
): State =>
  produce(state, (draft) => {
    draft.draggableElementId = action.elementId;
  });

const handleSetResizeableElement = (
  state: State,
  action: SetResizeableElementAction
): State =>
  produce(state, (draft) => {
    draft.resizeableElementId = action.elementId;
  });

const handleSetHoveredElement = (
  state: State,
  action: SetHoveredElementAction
): State =>
  produce(state, (draft) => {
    draft.hoveredElementId = action.elementId;
    draft.hoveredElementIndex = action.elementIndex;
  });

const handleCancelElementEdition = (
  state: State,
  _: CancelElementEditionAction
): State => {
  return { ...state, selectedElementId: null };
};

const handleCancelDragElementEdition = (
  state: State,
  _: CancelDraggableElementAction
): State => {
  return { ...state, draggableElementId: null };
};

const handleCancelResizeableElementEdition = (
  state: State,
  _: CancelResizeableElementAction
): State => {
  return { ...state, resizeableElementId: null };
};

const handleRtTempText = (
  state: State,
  action: HandleRTTempTextAction
): State =>
  produce(state, (draft) => {
    draft.rtTempText = action.text;
    draft.rtTempPropId = action.propId;
  });

const handleSaveRtTempText = (
  state: State,
  action: SaveRTTempTextAction
): State =>
  produce(state, (draft) => {
    draft.isRtTempTextSaved = true;
  });

const handleResetRtTemp = (state: State, action: ResetRTTempAction): State =>
  produce(state, (draft) => {
    draft.isRtTempTextSaved = false;
    draft.rtTempText = undefined;
    draft.rtTempPropId = undefined;
  });
// TODO: Remove
const handleResize = (state: State, _: ResizeAction): State => {
  /* const element = layout[action.elementId]; */
  /* const parentElement = layout[element.elementParentId!]; */
  /* const index = parentElement.elementChildrenId!.indexOf(action.elementId); */
  /* const nextElementId = parentElement.elementChildrenId![index + 1]; */
  /* const nextElement = layout[nextElementId]; */

  /* TODO: Fix this
  if (action.position === "left") {
    element.offset! += action.delta;
    element.width! -= action.delta;
  } else if (action.position === "right") {
    element.width! += action.delta;
  }

  if (nextElement) {
    if (action.position === "right") {
      nextElement.offset! -= action.delta;
    }
  }
  */

  return state;
};
