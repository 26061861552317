import {
  CloseRounded,
  SaveOutlined,
  SvgIconComponent,
} from "@material-ui/icons";
import React, { useState, useEffect, ReactNode } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUserWebsiteSlug } from "../../../../auth/selectors";
import { fetchPages } from "../../../../admin/pageActions";
import { Shade } from "../../base/Shade/Shade";
import Moveable from "react-moveable";
import { Button } from "../../base/Button/Button";

import "./Dialog.scss";
import styles from "./Dialog.module.scss";
import shadeStyles from "../../base/Shade/Shade.module.scss";
import { Spinner } from "react-bootstrap";
import { getRequestIsInProgress } from "api/selectors";
import { getSelectedElementId } from "editor/selectors";

import { useTranslation } from "react-i18next";

type Props = {
  isShowDialog: boolean;
  isGrabbable?: boolean;
  isDisabled?: boolean;
  isShowBg?: boolean;
  isDialogPositionStatic?: boolean;
  size: "medium" | "large";
  dialogBody?: ReactNode;
  headerLabel: string;
  elementPos?: DOMRect;
  cssPosition?: React.CSSProperties | null;
  parentActionButtonVariant?: string;
  parentActionTo?: string;
  isElementReusable?: boolean;
  displayReuseButton?: boolean;
  icon: SvgIconComponent;
  parentActionIcon?: SvgIconComponent;
  handleShowDialog: () => void;
  handleParentAction?: () => void;
  handleShare?: () => void;
};

export const ElementsDialog: React.FunctionComponent<Props> = ({
  isShowDialog: showDialog,
  isGrabbable,
  isDisabled,
  isShowBg: showBg,
  isDialogPositionStatic: dialogPositionStatic,
  size,
  dialogBody,
  headerLabel,
  elementPos,
  cssPosition,
  parentActionButtonVariant,
  parentActionTo,
  icon,
  parentActionIcon,
  handleShowDialog,
  handleParentAction,
}) => {
  const requestInProgress = useSelector(getRequestIsInProgress);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const selectedElementId = useSelector(getSelectedElementId);
  const dispatch = useDispatch();
  // const frame = { translate: [0, 0] };
  const [frame, setFrame] = useState<any>({ translate: [0, 0] });
  const [isHeaderClicked, setIsHeaderClicked] = useState(false);

  const { t } = useTranslation();

  const handleHeaderClick = () => {
    setIsHeaderClicked(isHeaderClicked ? false : true);
  };

  useEffect(() => {
    if (websiteSlug) {
      dispatch(fetchPages(websiteSlug));
    }
  }, [dispatch, websiteSlug]);

  if (!showDialog) {
    return <></>;
  }

  if (!websiteSlug) {
    return <></>;
  }
  var Icon = null;
  if (icon) {
    Icon = icon;
  }
  const dialogWidth = size === "medium" ? 400 : 600;
  const dialogHeight = 630;

  let grabbableClassname = isGrabbable ? "grabbable" : "";

  if (cssPosition === undefined || cssPosition === null) {
    let elementPosition = elementPos;
    if (!elementPos && !dialogPositionStatic && selectedElementId) {
      // Element selected is a button or link, which
      // the Editor purposely halts the "selection" part (selectedRef)
      // so that the link does not forward to the href value
      const selectedDisabledElem = document.getElementById(selectedElementId);
      if (
        selectedDisabledElem &&
        (selectedDisabledElem.getAttribute("elementkey") === "button" ||
          selectedDisabledElem.getAttribute("elementkey") === "image")
      ) {
        elementPosition = selectedDisabledElem.getBoundingClientRect();
      }
    }

    if (dialogPositionStatic) {
      cssPosition = {
        left:
          window.innerWidth - dialogWidth - 24 < 140
            ? 140
            : window.innerWidth - dialogWidth - 24,
        top: window.innerHeight / 8,
      };
    } else if (elementPosition) {
      // posTRX - position top-right x
      // posTRY - position top-right y
      const posTRX = elementPosition.x + elementPosition.width;
      const posTRY = elementPosition.y;
      const isPosTRXOverX =
        posTRX + dialogWidth > window.innerWidth ? true : false;
      const isPosTRYOverY =
        dialogHeight + posTRY > window.innerHeight ? true : false;

      // posBRX - position bottom-right x
      // posBRY - position bottom-right y
      const posBRX = elementPosition.x + elementPosition.width;
      const posBRY = elementPosition.y + elementPosition.height - dialogHeight;
      const isPosBRXOverX =
        posBRX + dialogWidth > window.innerWidth ? true : false;
      const isPosBRYOverY =
        posBRY > window.innerHeight || posBRY < 60 ? true : false;

      // posTLX - position top-left x
      // posTLY - position top-left y
      const posTLX = elementPosition.x - dialogWidth;
      const posTLY = elementPosition.y;
      const isPosTLXoverX =
        posTLX > window.innerWidth || posTLX < 0 ? true : false;
      const isPosTLYoverY =
        dialogHeight + posTLY > window.innerHeight ? true : false;

      // posBLX - position bottom-left x
      // posBLY - position bottom-left y
      const posBLX = elementPosition.x - dialogWidth;
      const posBLY = elementPosition.bottom - dialogHeight;
      const isPosBLXOverX =
        posBLX > window.innerWidth || posBLX < 0 ? true : false;
      const isPosBLYOverY =
        posBLY < 0 || posBLY > window.innerHeight ? true : false;

      // posULX - position under-left x
      // posULY - position under-left y
      const posULX = elementPosition.x;
      const posULY = elementPosition.y + elementPosition.height;
      const isPosULXOverX = posULX + dialogWidth > window.innerWidth;
      const isPosULYOverY = posULY + dialogHeight > window.innerHeight;

      // posURX - position under-right x
      // posURY - position under-right y
      const posURX = elementPosition.x + elementPosition.width - dialogWidth;
      const posURY = elementPosition.y + elementPosition.height;
      const isPosURXOverX =
        posURX > window.innerWidth || posURX < 0 ? true : false;
      const isPosURYOverY = posURY + dialogHeight > window.innerHeight;

      // posMRX - position mid-right x
      // posMRY - position mid-right y
      const posMRX = elementPosition.right;
      const posMRY = elementPosition.x + elementPosition.width;
      const isPosMRXOverX =
        posMRX + dialogWidth > window.innerWidth ? true : false;
      const isPosMRYOverY = posMRY > window.innerHeight ? true : false;

      // posMLX - position mid-left x
      // posMLY - position mid-left y
      const posMLX = elementPosition.left - dialogWidth;
      const posMLY = (dialogHeight - elementPosition.height) / 2;
      const isPosMLXOverX =
        posMLX > window.innerWidth || posMLX < 0 ? true : false;
      const isPosMLYOverY = posMLY > window.innerHeight ? true : false;

      if (!isPosULXOverX && !isPosULYOverY) {
        cssPosition = {
          left: posULX < 140 ? 140 : posULX,
          top: posULY,
          marginTop: 16,
        };
      } else if (!isPosURXOverX && !isPosURYOverY) {
        cssPosition = {
          left: posURX < 140 ? 140 : posURX,
          top: posURY,
          marginTop: 16,
        };
      } else if (!isPosTRXOverX && !isPosTRYOverY) {
        cssPosition = {
          left: posTRX < 140 ? 140 : posTRX,
          top: posTRY,
          marginLeft: 12,
        };
      } else if (!isPosBRXOverX && !isPosBRYOverY) {
        cssPosition = {
          left: posBRX < 140 ? 140 : posBRX,
          top: posBRY,
          marginLeft: 12,
        };
      } else if (!isPosTLXoverX && !isPosTLYoverY) {
        cssPosition = {
          left: posTLX < 140 ? 140 : posTLX,
          top: posTLY,
          marginRight: 12,
        };
      } else if (!isPosBLXOverX && !isPosBLYOverY) {
        cssPosition = {
          left: posBLX < 140 ? 140 : posBLX,
          top: posBLY,
          marginRight: 12,
        };
      } else if (!isPosMLXOverX && !isPosMLYOverY) {
        cssPosition = {
          left: posMLX < 140 ? 140 : posMLX,
          top: posMLY,
          marginRight: 12,
        };
      } else if (!isPosMRXOverX && !isPosMRYOverY) {
        cssPosition = {
          left: posMRX < 140 ? 140 : posMRX,
          top: posMRY,
          marginLeft: 12,
        };
      } else {
        cssPosition = {
          left:
            window.innerWidth - dialogWidth - 24 < 140
              ? 140
              : window.innerWidth - dialogWidth - 2,
          top: window.innerHeight / 8,
        };
      }
    }
  }

  const TransparentShade = () => {
    return (
      <div
        className={shadeStyles["transparent-shade"]}
        onMouseDown={() => {
          handleShowDialog();
        }}
      ></div>
    );
  };

  return (
    <>
      {showBg ? <Shade /> : ""}
      <TransparentShade />
      <Moveable
        className={styles["draggable-dialog"]}
        target={
          isGrabbable && isHeaderClicked
            ? document.querySelector('div[class^="Dialog_property-dialog__"]')
            : ""
        }
        draggable={true}
        throttleDrag={0}
        onDragStart={({ set }) => {
          set(frame.translate);
        }}
        onDrag={({ target, beforeTranslate }) => {
          frame.translate = beforeTranslate;
          target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
        }}
        onDragEnd={({ target, isDrag, clientX, clientY }) => {
          setFrame(frame);
        }}
      />
      <div
        className={`${styles["property-dialog"]} ${
          styles["property-dialog--" + size]
        }`}
        style={cssPosition ? cssPosition : {}}
      >
        <div onMouseOver={handleHeaderClick} onMouseOut={handleHeaderClick}>
          <div
            className={`${styles["property-dialog__header"]} ${grabbableClassname}`}
          >
            <div className={styles["property-dialog__details-container"]}>
              <div className={styles["header-label"]}>
                {Icon ? <Icon className={styles["icon"]} /> : <></>}
                <h4>{headerLabel ? t(headerLabel) : "Element"}</h4>
              </div>
              <div className={styles["header-buttons-container"]}>
                {/* {isGrabbable ? (
                    <>
                    <button disabled className={styles['drag-button']}>
                        <ZoomOutMapRounded />
                    </button>
                    </>
                    ) : (
                    <></>
                    )} */}
                <Button
                  icon={CloseRounded}
                  onClick={handleShowDialog}
                  variant="outline-primary-white"
                  size="circle"
                  isFlat={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles["property-dialog__body"]}>{dialogBody}</div>
        {handleParentAction || parentActionTo ? (
          <div className={styles["property-dialog__footer"]}>
            <div className={styles["parent-action-container"]}>
              <div className={styles["parent-action-left"]}>
                <Button
                  variant={parentActionButtonVariant}
                  icon={parentActionIcon}
                  onClick={handleParentAction}
                  isDisabled={isDisabled}
                  to={parentActionTo}
                  // size="circle"
                  isFlat={true}
                  label={t("delete")}
                />
              </div>
              <div className={styles["parent-action-right"]}>
                {requestInProgress ? (
                  <>
                    <Spinner
                      size="sm"
                      animation="border"
                      variant="secondary"
                      className={styles["saving-icon"]}
                    />{" "}
                    {t("Saving")}...
                  </>
                ) : (
                  <div className="hideSaveStatus">
                    <SaveOutlined />
                    {t("Changes_Saved")}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
