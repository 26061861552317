import {
  BackgroundColorProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const weddingChild2Template: ElementTemplates = {};

weddingChild2Template[
  "cards"
] = TemplatesFactory.createDefaultCards(
  new LeadStyleProperty("0"),
  new RichTextProperty("<h2>Our Services</h2>"),
  new RTEAlignmentProperty("parent"),
  new BackgroundColorProperty("#F4EACC"),
  new RichTextProperty(
    `<div><span style="color: rgb(115, 94, 25);">Enjoy your Day. <br />We got everything covered.</span></div>`
  ),
  new RTEAlignmentProperty("parent"),
  "0",
  [
    TemplatesFactory.createDefaultCardNoTitleNoLink(
      new ImageStyleProperty("1"),
      new RichTextProperty(
        '<div style="color: rgb(115, 94, 25);">Photography</div><div></div><div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://images.unsplash.com/photo-1600164913117-2125c1f60b01?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzEyfHx3ZWRkaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      "parent"
    ),
    TemplatesFactory.createDefaultCardNoTitleNoLink(
      new ImageStyleProperty("1"),
      new RichTextProperty(
        '<div style="color: rgb(115, 94, 25);">Catering</div><div></div><div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://images.unsplash.com/photo-1625036809268-07375fdbaeca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
      "parent"
    ),
    TemplatesFactory.createDefaultCardNoTitleNoLink(
      new ImageStyleProperty("1"),
      new RichTextProperty(
        '<div style="color: rgb(115, 94, 25);">Planning</div><div></div><div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://images.unsplash.com/photo-1505366055494-2268a382e017?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzc0fHx3ZWRkaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      "parent"
    ),
    TemplatesFactory.createDefaultCardNoTitleNoLink(
      new ImageStyleProperty("1"),
      new RichTextProperty(
        '<div style="color: rgb(115, 94, 25);">Location</div><div></div><div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://images.unsplash.com/photo-1612599542650-3b98fd99f96a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80",
      "parent"
    ),
    TemplatesFactory.createDefaultCardNoTitleNoLink(
      new ImageStyleProperty("1"),
      new RichTextProperty(
        '<div style="color: rgb(115, 94, 25);">Organization</div><div></div><div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://images.unsplash.com/photo-1521129866021-4313ccf20e9e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDkzfHx3ZWRkaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      "parent"
    ),
    TemplatesFactory.createDefaultCardNoTitleNoLink(
      new ImageStyleProperty("1"),
      new RichTextProperty(
        '<div style="color: rgb(115, 94, 25);">Make-up</div><div></div><div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://images.unsplash.com/photo-1523264058183-49ea5572cdf4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDA4fHx3ZWRkaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      "parent"
    ),
  ]
);
