import { Reducer } from "redux";

import {
  Action,
  EnableAction,
  DisableAction,
  SetHoveredElementWithMousePositionAction,
  SetHoveredComponentWithMousePositionAction,
  HideAction,
} from "./addToolbarGuidesActions";
import { Rect, PositionString, Scroll, Direction } from "../../../types";
import { getElementConfiguration } from "../../../elements/registry";

export type State = {
  enabled: boolean;
  state: "hidden" | "shown";
  isShown: boolean;
  elementId: string;
  elementParentId: string;
  index: number;
  rect: Rect;
  drop: Direction;
  rects: {
    left: Rect | undefined;
    right: Rect | undefined;
    top: Rect | undefined;
    bottom: Rect | undefined;
  };
  elementKeys: string[];
};

const initialState: State = {
  enabled: true,
  state: "hidden",
  isShown: false,
  elementId: "",
  elementParentId: "",
  index: 0,
  rect: { x: 0, y: 0, width: 0, height: 0 },
  drop: "down",
  elementKeys: [],
  rects: {
    left: undefined,
    right: undefined,
    top: undefined,
    bottom: undefined,
  },
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case "ADD_TOOLBAR_GUIDES_ENABLE":
      return handleEnable(state, action);

    case "ADD_TOOLBAR_GUIDES_DISABLE":
      return handleDisable(state, action);

    case "ADD_TOOLBAR_GUIDES_HIDE":
      return handleHide(state, action);

    case "ADD_TOOLBAR_GUIDES_SET_HOVERED_ELEMENT_WITH_MOUSE_POSITION":
      return handleSetHoveredElementWithMousePosition(state, action);

    case "ADD_TOOLBAR_GUIDES_SET_HOVERED_COMPONENT_WITH_MOUSE_POSITION":
      return handleSetHoveredComponentWithMousePosition(state, action);

    default:
      return state;
  }
};

const handleEnable = (state: State, action: EnableAction): State => {
  return {
    ...state,
    isShown: true,
    enabled: true,
  };
};

const handleDisable = (state: State, action: DisableAction): State => {
  return {
    ...state,
    isShown: false,
    enabled: false,
    state: "hidden",
  };
};

const handleHide = (state: State, action: HideAction): State => {
  return {
    ...state,
    isShown: false,
    state: "hidden",
  };
};

const handleSetHoveredElementWithMousePosition = (
  state: State,
  action: SetHoveredElementWithMousePositionAction
): State => {
  const elementConfiguration = getElementConfiguration(action.elementKey);
  return handleSetHoveredElementOrComponentWithMousePosition(
    state,
    action.elementId,
    elementConfiguration.allowedToolbarPositions,
    action.elementParentId,
    action.elementParentKey,
    action.index,
    action.elementRect,
    action.scroll,
    action.x,
    action.y,
    action.stopEventPropagation
  );
};

const handleSetHoveredComponentWithMousePosition = (
  state: State,
  action: SetHoveredComponentWithMousePositionAction
): State => {
  if (!state.enabled) {
    return state;
  }

  return handleSetHoveredElementOrComponentWithMousePosition(
    state,
    "",
    ["top", "bottom", "left", "right"],
    action.elementParentId,
    action.elementParentKey,
    action.index,
    action.elementRect,
    action.scroll,
    action.x,
    action.y,
    action.stopEventPropagation
  );
};

const handleSetHoveredElementOrComponentWithMousePosition = (
  state: State,
  elementId: string,
  allowedToolbarPositions: PositionString[],
  elementParentId: string,
  elementParentKey: string,
  index: number,
  elementRect: Rect,
  scroll: Scroll,
  x: number,
  y: number,
  stopEventPropagation: () => void
): State => {
  const position = calculatePosition(
    elementRect,
    x,
    y,
    allowedToolbarPositions
  );

  if (!position) {
    return { ...state, state: "hidden" };
  }
  stopEventPropagation();

  const elementParentConfiguration = getElementConfiguration(elementParentKey);

  let rects = new Map<string, Rect>();
  rects.set("left", { x: 0, y: 0, width: 0, height: 0 });
  rects.set("right", { x: 0, y: 0, width: 0, height: 0 });
  rects.set("top", { x: 0, y: 0, width: 0, height: 0 });
  rects.set("bottom", { x: 0, y: 0, width: 0, height: 0 });
  allowedToolbarPositions.forEach((position) => {
    rects.set(position, calculateRect(position, elementRect, scroll));
  });

  return {
    ...state,
    state: "shown",
    elementId: elementId,
    elementParentId: elementParentId,
    index: calculateIndex(position, index),
    rect: calculateRect(position, elementRect, scroll),
    drop: dropByPosition[position],
    elementKeys: elementParentConfiguration.allowedChildren,
    rects: {
      left: rects.get("left"),
      right: rects.get("right"),
      top: rects.get("top"),
      bottom: rects.get("bottom"),
    },
    isShown: true,
  };
};

const dropByPosition: {
  [_: string]: Direction;
} = {
  top: "down",
  bottom: "up",
  left: "right",
  right: "left",
};

const calculatePosition = (
  elementRect: Rect,
  x: number,
  y: number,
  allowedToolbarPositions: PositionString[]
): PositionString | null => {
  const dx = x - elementRect.x;
  const dy = y - elementRect.y;
  if (dy < 15 && allowedToolbarPositions.includes("top")) {
    return "top";
  } else if (
    dy > elementRect.height - 15 &&
    allowedToolbarPositions.includes("bottom")
  ) {
    return "bottom";
  } else if (dx < 15 && allowedToolbarPositions.includes("left")) {
    return "left";
  } else if (
    dx > elementRect.width - 15 &&
    allowedToolbarPositions.includes("right")
  ) {
    return "right";
  }
  return null;
};

const calculateIndex = (position: PositionString, index: number): number => {
  return index + (position === "bottom" || position === "right" ? 1 : 0);
};

const calculateRect = (
  position: PositionString,
  elementRect: Rect,
  scroll: Scroll
): Rect => {
  const defaultX = scroll.x + elementRect.x;
  const defaultY = scroll.y + elementRect.y;

  if (position === "top") {
    return {
      x: defaultX + 10,
      y: scroll.y + elementRect.y - 4,
      width: elementRect.width - 20,
      height: 4,
    };
  } else if (position === "bottom") {
    return {
      x: defaultX + 10,
      y: scroll.y + elementRect.y + elementRect.height - 2,
      width: elementRect.width - 20,
      height: 4,
    };
  } else if (position === "left") {
    return {
      x: scroll.x + elementRect.x - 4,
      y: defaultY + 10,
      width: 4,
      height: elementRect.height - 20,
    };
  } else {
    return {
      x: scroll.x + elementRect.x + elementRect.width - 2,
      y: defaultY + 10,
      width: 4,
      height: elementRect.height - 20,
    };
  }
};
