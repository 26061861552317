import {
  BackgroundColorProperty,
  DimensionProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const weddingChild3Template: ElementTemplates = {};

weddingChild3Template["cover"] = TemplatesFactory.createDefaultCoverNoText(
  "1",
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://images.unsplash.com/photo-1505765052322-75804bb2e5f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    "no-repeat",
    "50%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(
    `<div><span style="color: rgb(115, 94, 25);">Read Our Testimonials</span></div>`
  ),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 60)
);

weddingChild3Template[
  "articles1"
] = TemplatesFactory.createDefaultArticlesNoTitle(
  new LeadStyleProperty("1"),
  new ImageStyleProperty("1"),
  new BackgroundColorProperty("#FFFFFF"),
  new RichTextProperty(
    '<div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25); font-style: italic;">"You are the love that came without warning; you had my heart before I could say no."</span><br></div>— Lauren Bacall</div>'
  ),
  new RTEAlignmentProperty("center"),
  "2",
  "1",
  "https://images.unsplash.com/photo-1589544421314-2d8297a767a5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHdlZGRpbmd8ZW58MHwyfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  "2"
);

weddingChild3Template[
  "articles2"
] = TemplatesFactory.createDefaultArticlesNoTitleImageFirst(
  new LeadStyleProperty("1"),
  new ImageStyleProperty("1"),
  new BackgroundColorProperty("#F4EACC"),
  new RichTextProperty(
    '<div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25); font-style: italic;">"Love seems the swiftest but it is the slowest of all growths. No man or woman really knows what perfect love is until they have been married a quarter of a century."</span><br></div>— Judy Garland</div>'
  ),
  new RTEAlignmentProperty("center"),
  "2",
  "1",
  "https://images.unsplash.com/photo-1648729415002-ae77bbb9d90d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1208&q=80",
  "2"
);

weddingChild3Template[
  "articles3"
] = TemplatesFactory.createDefaultArticlesNoTitle(
  new LeadStyleProperty("1"),
  new ImageStyleProperty("1"),
  new BackgroundColorProperty("#FFFFFF"),
  new RichTextProperty(
    '<div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25); font-style: italic;">"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla dolores modi quasi quia, impedit culpa nisi repellendus laborum ratione voluptate."</span><br></div>— Truth Devour, Unrequited</div>'
  ),
  new RTEAlignmentProperty("center"),
  "2",
  "1",
  "https://images.unsplash.com/photo-1628095419442-98429fc1c91a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODN8fHdlZGRpbmd8ZW58MHwyfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  "2"
);
