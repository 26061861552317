import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ColorResult, ChromePicker } from "react-color";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";

import { Formik, Form } from "formik";

import styles from "./LinkColorProperty.module.scss";
import "./LinkColorProperty.scss";

import { getPrimaryColor } from "editor/selectors";
import { Container } from "react-bootstrap";

export class LinkColorProperty implements IProperty {
  constructor(readonly color: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <LinkColorPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new LinkColorProperty(value);
  }
}

const LinkColorPropertyComponent: PropertyComponent<LinkColorProperty> = ({
  elementId,
  propertyKey,
  color,
}) => {
  const dispatch = useDispatch();
  const statePrimaryColor = useSelector(getPrimaryColor);
  const [selectedColor, setSelectedColor] = useState(color);

  useEffect(() => {
    if (!color && statePrimaryColor) {
      setSelectedColor(statePrimaryColor);
    }
  }, [color, statePrimaryColor]);

  const handleChangeComplete = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(propertySet(elementId, propertyKey, color.hex));
    setSelectedColor(color.hex);
  };

  return (
    <div className={`${styles["palette-container"]}`}>
      <Container
        className={`${styles["swatch-container"]}`}
        style={{ paddingBottom: 20, paddingTop: 20 }}
      >
        <Formik initialValues={{}} onSubmit={async (values) => {}}>
          <Form className="form">
            <div className="form__group">
              <div className="medium-padded-container">
                <div className="pickers-container">
                  <div className="picker-container">
                    <ChromePicker
                      disableAlpha={true}
                      color={selectedColor}
                      onChange={(
                        color: ColorResult,
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleChangeComplete(color, event);
                      }}
                    />
                    {/* <SwatchesPicker
                      className={styles["swatches-picker-override"]}
                      onChangeComplete={handleChangeComplete}
                      color={selectedColor}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Container>
    </div>
  );
};
