import { Layout } from "common/elements/types";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { HeadingElement } from "editor/elements/base/Heading";
import { ImageElement } from "editor/elements/base/Image/Image";
import { LeadElement } from "editor/elements/base/Lead";
import { ParagraphElement } from "editor/elements/base/Paragraph";
import { ArticleElement } from "editor/elements/components/Article";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { RichTextProperty, RTEAlignmentProperty } from "../properties";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const makeupChild1Template: ElementTemplates = {};

makeupChild1Template["articles"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#AE9F7F",
      acrticlesAlignment: "1",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        ArticleElement.create({
          articleStyle: "2",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1516029637308-3adce832dbec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1505&q=80",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h1><span style="color: rgb(38, 12, 25);"><br>My <br>Work</span></h1>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><span style="color: rgb(38, 12, 25);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt.<br><br></span></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .build()
  )
  .build();

makeupChild1Template["articles2"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#fff",
      acrticlesAlignment: "1",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h1><span style="color: rgb(38, 12, 25);">Since 2000</span></h1>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><div><div><div><span style="color: rgb(38, 12, 25);"><br></span></div><div><span style="color: rgb(38, 12, 25);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</span></div></div></div><div><span style="color: rgb(38, 12, 25);"><br></span></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            ImageElement.create({
              imageStyle: new ImageStyleProperty("1"),
              imgUrl:
                "https://images.unsplash.com/photo-1586376385817-c5adeff82cc7?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740",
              imageText: "",
            })
          )
          .add(
            ParagraphElement.create({
              richTextProperty: new RichTextProperty(
                `<div><br></div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<div><br></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            ImageElement.create({
              imageStyle: new ImageStyleProperty("1"),
              imgUrl:
                "https://images.unsplash.com/photo-1465426721606-27b1239015fd?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740",
              imageText: "",
            })
          )
          .add(
            ParagraphElement.create({
              richTextProperty: new RichTextProperty(
                `<div><br></div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<div><br></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "2",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1587494211817-da8f781fa951?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1858",
                  imageText: "",
                })
              )
              .add(
                ParagraphElement.create({
                  richTextProperty: new RichTextProperty(
                    `<div><br></div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<div><br></div>`
                  ),
                  rteAlignment: new RTEAlignmentProperty("left"),
                })
              )
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/flagged/photo-1556743591-ca7e9c56d18d?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1625",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();
