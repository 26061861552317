import { CropSquare, SvgIconComponent } from "@material-ui/icons";
import React, { useState, HTMLAttributes, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getElementConfiguration } from "editor/elements/registry";
import { ElementConfiguration } from "editor/elements/types";
import { sharedElementInserted, templateInserted } from "editor/states/layout";
import {
  getSharedElementIds,
  getSharedElementsConfiguration,
} from "editor/states/selectors";

import styles from "./AddElementToolbar.module.scss";
import { logActivity } from "auth/authActions";
import { editorPage } from "../Editor/Editor";
import { Website } from "editor/models/types";
import { useTranslation } from "react-i18next";
import { getUsername } from "auth/selectors";

type Props = {
  elementParentId: string;
  index: number;
  elementKeys: string[];
  close: () => void;
  width: number;
  height: number;
  drop: string;
  positionY: number | undefined;
  website: Website;
  websiteSlug?: string;
  pageSlug?: string | null;
  isFromMainAd?: boolean;
  isOpaque?: boolean | true;
} & HTMLAttributes<HTMLDivElement>;

export const AddElementToolbarDropdown = React.forwardRef<
  HTMLDivElement,
  Props
>(
  (
    {
      elementParentId,
      index,
      elementKeys,
      close,
      className,
      width,
      height,
      drop,
      positionY,
      website,
      websiteSlug,
      pageSlug,
      isFromMainAd,
      isOpaque,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const username = useSelector(getUsername);

    const DEFAULT_LABEL: string = t("element_preview");
    const DEFAULT_ELEMENT_DESC: string = t("element_preview_1");
    const DEFAULT_PREVIEW_URL: string = "/img/add-element-preview-default.svg";

    const [
      hoveredConfig,
      setHoveredElement,
    ] = useState<ElementConfiguration | null>({
      label: DEFAULT_LABEL,
      elementDescription: DEFAULT_ELEMENT_DESC,
      elementPreviewUrl: DEFAULT_PREVIEW_URL,
    });
    const sharedElementIds = useSelector(getSharedElementIds);
    const sharedElementsConfiguration = useSelector(
      getSharedElementsConfiguration
    );

    useEffect(() => {
      dispatch(
        logActivity("OPEN DIALOG", editorPage, username, "Add Element Dialog")
      );
    }, [dispatch, username]);

    let dialogWidth = Math.max(900, width);
    const dialogHeight = Math.max(371, height);

    if (dialogWidth > 860) {
      dialogWidth = 860;
    }

    let dialogTop;
    if (drop === "right" || drop === "left") {
      dialogTop = -Math.abs(dialogHeight / 2 - 20);
    }

    if (positionY) {
      if (positionY + dialogHeight > window.innerHeight) {
        dialogTop = -dialogHeight;
      } else {
        dialogTop = 18;
      }
    }

    let dialogLeft;
    let dialogRight;
    if (drop === "left") {
      dialogRight = 12;
    } else if (drop === "right") {
      dialogLeft = 12;
    } else {
      dialogLeft = -Math.abs(dialogWidth / 2 - 20) + 25;
    }

    if (isFromMainAd) {
      dialogLeft = 70;
    }
    const elements = elementKeys.map((elementKey: string) => {
      const configuration = getElementConfiguration(elementKey);
      const Icon = configuration.icon;
      return (
        <div
          key={elementKey}
          onClick={(event: React.MouseEvent) => {
            // TODO: For now it is assumed there is one template per element
            // we can add.
            dispatch(
              logActivity("ADD ELEMENT", editorPage, username, elementKey)
            );
            dispatch(
              templateInserted(
                elementKey,
                elementParentId,
                index,
                website.logoCreated ? website.logoCreated : false,
                websiteSlug,
                pageSlug
              )
            );
            close();
            event.preventDefault();
          }}
          onMouseOver={() => {
            handleMouseOver(configuration);
          }}
          onMouseOut={() => {
            handleMouseOut();
          }}
        >
          <Icon />
          {t(configuration.label)}
        </div>
      );
    });

    const sharedElements = sharedElementIds.map((elementId: string) => {
      const sharedConfiguration = sharedElementsConfiguration[elementId];

      if (!sharedConfiguration) return <></>;

      const label = sharedConfiguration?.label ?? "Untitled";
      const configuration: ElementConfiguration = sharedConfiguration
        ? getElementConfiguration(sharedConfiguration.elementKey)
        : { label, icon: CropSquare };
      const Icon: SvgIconComponent = configuration.icon!;

      return (
        <div
          key={elementId}
          onClick={(event: React.MouseEvent) => {
            dispatch(
              logActivity(
                "ADD SHARED ELEMENT",
                editorPage,
                username,
                sharedConfiguration.elementKey
              )
            );
            dispatch(sharedElementInserted(elementId, elementParentId, index));
            close();
            event.preventDefault();
          }}
          onMouseOver={() => {
            handleMouseOver(configuration);
          }}
          onMouseOut={() => {
            handleMouseOut();
          }}
        >
          <Icon />
          {t(label)}
        </div>
      );
    });

    const handleMouseOver = (configuration: ElementConfiguration) => {
      setHoveredElement(configuration);
    };

    const handleMouseOut = () => {
      setHoveredElement(null);
    };

    const parentClassName = className === undefined ? "" : className;

    return (
      <React.Fragment>
        <div
          ref={ref}
          className={`${parentClassName} ${
            styles["add-element-toolbar-dropdown"]
          } ${isFromMainAd ? styles["add-main"] : `normal-add`}`}
          style={{
            width: dialogWidth,
            minHeight: "auto",
            left: dialogLeft,
            top: dialogTop,
            right: dialogRight,
            maxWidth: 860,
          }}
        >
          <div
            className={`container ${styles["add-element-toolbar-container"]}`}
          >
            <div
              className={`container ${styles["add-element-toolbar-elements"]}`}
            >
              <h1
                className={`${styles["add-element-toolbar-dropdown-headers"]}`}
              >
                {t("element_add_dialog_1")}
              </h1>
              <div
                className={`${styles["add-element-toolbar-dropdown-group"]}`}
              >
                {elements}
                {sharedElements.length > 0 ? (
                  <>
                    <hr />
                    <h1
                      className={`${styles["add-element-toolbar-dropdown-headers"]}`}
                    >
                      {t("element_add_dialog_2")}
                    </h1>
                    {sharedElements}
                  </>
                ) : null}
              </div>
            </div>
            <div
              className={`container ${styles["add-element-toolbar-helper"]}`}
            >
              <div className={`${styles["add-element-preview"]}`}>
                <img
                  src={
                    hoveredConfig && hoveredConfig.elementPreviewUrl
                      ? hoveredConfig.elementPreviewUrl
                      : DEFAULT_PREVIEW_URL
                  }
                  alt={DEFAULT_LABEL}
                />
                <h4>
                  {hoveredConfig && hoveredConfig.label
                    ? t(hoveredConfig.label)
                    : DEFAULT_LABEL}
                </h4>
                <p>
                  {hoveredConfig && hoveredConfig.elementDescription
                    ? t(hoveredConfig.elementDescription)
                    : DEFAULT_ELEMENT_DESC}
                </p>
              </div>
            </div>
          </div>
        </div>
        {isOpaque ? (
          <div className={`${parentClassName} ${styles["opaque-cover"]}`}></div>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  }
);
