import React, { HTMLAttributes } from "react";
import { useSelector } from "react-redux";

import { getSelectedElementId } from "../../selectors";
import { ElementType } from "../types";
import { ElementComponentProps, AnyProperties } from "common/elements/types";
import { getUserWebsiteSlug } from "auth/selectors";

export type Props = {
  as: React.FunctionComponent<ElementComponentProps<AnyProperties>>;
  elementId: string;
  elementParentId?: string;
  elementKey: string;
  elementParentKey: string | null;
  properties: { [propertyKey: string]: any };
  index: number;
  selectedRef: React.MutableRefObject<any>;
  className?: string | null;
};

export const DumbElement: React.FunctionComponent<
  Props & HTMLAttributes<any> & Partial<ElementType>
> = ({
  as,
  elementId,
  elementParentId,
  elementKey,
  elementParentKey,
  index,
  selectedRef,
  children,
  className,
  ...props
}) => {
  const selectedElementId = useSelector(getSelectedElementId);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const isSelected = selectedElementId === elementId;
  const As = as;

  const handleMouseOver = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!elementParentId || !elementParentKey) {
      return;
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <As
        children={children}
        id={elementId}
        elementKey={elementKey}
        properties={props}
        innerRef={isSelected ? selectedRef : undefined}
        websiteSlug={websiteSlug ? websiteSlug : ""}
        onMouseOver={(event: React.MouseEvent) => {
          handleMouseOver(event, elementId);
        }}
        onMouseOut={handleMouseOut}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        className={`element ${className} `}
      />
    </>
  );
};
