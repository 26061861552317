import { ViewModuleRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import {
  formGroupElementKey,
  FormGroupProperties,
} from "common/elements/components/FormGroup";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { FormGroupStyleProperty } from "editor/properties/FormGroupStyleProperty";
import { FieldGroupConfigProperty } from "editor/properties/FieldGroupConfigProperty/FieldGroupConfigProperty";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./FormGroup.module.scss";
import { Formik, Form } from "formik";

import { useTranslation } from "react-i18next";

export class FrormGroupElement {
  static readonly elementKey = formGroupElementKey;
  static readonly label = "element_fieldconfig_label";
  static readonly icon = ViewModuleRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly allowedChildren = [
    "input-label",
    "input",
    "small-text",
    "textarea",
  ];
  static readonly elementPreviewUrl = "./img/default-image.svg";
  static readonly elementDescription = "element_fieldconfig_description";
  static readonly isDeletable = true;

  static readonly properties: PropertiesOf<FormGroupProperties> = {
    formGroupStyle: FormGroupStyleProperty,
    fieldGroupConfig: FieldGroupConfigProperty,
  };

  static renderProperties(element: ElementType) {
    return <FormGroupPropertiesComponent element={element} />;
  }

  static create(properties: {
    formGroupStyle: string;
    fieldGroupConfig: FieldGroupConfigProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const FormGroupPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof FrormGroupElement.properties>(
    FrormGroupElement.properties,
    element
  );
  const { t } = useTranslation();
  return (
    <div className={styles["properties__container"]}>
      <div className={styles["form-group"]}>
        <Tabs id="field-config" defaultActiveKey="config">
          <Tab eventKey="config" title={t("dialog_77")}>
            <Formik initialValues={{}} onSubmit={async (values) => {}}>
              <Form className="form">
                {renderer.render("fieldGroupConfig")}
              </Form>
            </Formik>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

elementDefinitionRegistry.register(FrormGroupElement);
