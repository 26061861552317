import { PanoramaHorizontalOutlined } from "@material-ui/icons";
import React from "react";
import { PropertyRenderer } from "../../PropertyRenderer";

import { ElementType, ElementPropertiesComponent } from "../../types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import {
  coverElementKey,
  CoverProperties,
} from "common/elements/sections/Cover";
import { PropertiesOf } from "editor/elements/types";
import { CoverStyleProperty } from "editor/properties/CoverStyleProperty/CoverStyleProperty";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { Tabs, Tab } from "react-bootstrap";
import { BackgroundColorProperty } from "editor/properties/BackgroundColorProperty/BackgroundColorProperty";
import styles from "./Cover.module.scss";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import { DimensionProperty } from "editor/properties";
import { BackgroundFilterProperty } from "editor/properties/BackgroundFilterProperty/BackgroundFilterProperty";
import { useTranslation } from "react-i18next";

export class CoverElement {
  static readonly elementKey = coverElementKey;
  static readonly label = "element_cover_label";
  static readonly icon = PanoramaHorizontalOutlined;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_cover_description";
  static readonly elementPreviewUrl =
    "/img/add-cover-element-preview-default.svg";
  static readonly allowedChildren = [
    "heading",
    "lead",
    "paragraph",
    "blockquote",
    "button",
    "image",
    "rt",
    "youtube",
  ];
  static readonly isResizeable = true;
  static readonly resizeDirections = ["s", "n"];
  static readonly resizeGuides = [1024, 1280, 1366, 1440, 1600, 1920, 0];
  static readonly dialogSize = "large";
  static readonly childDragDirection = "vertical";
  static readonly isReusable = true;
  // static readonly cssPosition = {
  //   // position: "absolute",
  //   // top: 0,
  //   // bottom: 0,
  //   // left: 0,
  //   // right: 0,
  //   // margin: "auto",
  //   // height: "fit-content",
  // };

  static readonly properties: PropertiesOf<CoverProperties> = {
    coverStyle: CoverStyleProperty,
    backgroundImageProperty: BackgroundImageProperty,
    backgroundColorProperty: BackgroundColorProperty,
    dimension: DimensionProperty,
    backgroundFilter: BackgroundFilterProperty,
  };

  static renderProperties(element: ElementType) {
    return <CoverPropertiesComponent element={element} />;
  }

  static create(properties: {
    coverStyle: string;
    backgroundImageProperty: BackgroundImageProperty;
    backgroundColorProperty: BackgroundColorProperty;
    dimension: { width?: number; height?: number };
    backgroundFilter?: BackgroundFilterProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const CoverPropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof CoverElement.properties>(
    CoverElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="background">
        <Tab eventKey="background" title={t("dialog_3")}>
          {renderer.render("backgroundImageProperty")}
        </Tab>
        <Tab eventKey="filter" title={t("dialog_45")}>
          {renderer.render("backgroundFilter")}
        </Tab>
        <Tab eventKey="background-color" title={t("dialog_46")}>
          {renderer.render("backgroundColorProperty")}
        </Tab>
        <Tab eventKey="position" title={t("dialog_47")}>
          {renderer.render("coverStyle")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(CoverElement);
