import { FileCopyRounded, NoteAddOutlined } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUsername, getUserWebsiteSlug } from "../../../../auth/selectors";
import { Dialog } from "../../components/Dialog/Dialog";
import { latestPageVersionListRequested } from "editor/states/latestPageVersionList";
import { getPageWithLatestDetailsList } from "editor/states/selectors";
import { pageListRequested } from "editor/states/pageList";

import styles from "./PageListPropertiesDialog.module.scss";
import PageListAccordion from "./PageListAccordion";
import { editorPage } from "../Editor/Editor";
import { logActivity } from "auth/authActions";

import { useTranslation } from "react-i18next";

type Props = {
  isShowPagesPropsDialog: boolean;
  toolBarButtonRect?: DOMRect;
  handleShowPagesPropDialog: () => void;
  cssPos?: React.CSSProperties;
};

export default function PageListPropertiesDialog({
  isShowPagesPropsDialog,
  toolBarButtonRect,
  handleShowPagesPropDialog,
  cssPos,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const pageList = useSelector(getPageWithLatestDetailsList);
  const username = useSelector(getUsername);
  const dispatch = useDispatch();

  useEffect(() => {});

  useEffect(() => {
    if (websiteSlug) {
      dispatch(pageListRequested(websiteSlug));
      dispatch(latestPageVersionListRequested(websiteSlug));
    }
  }, [dispatch, websiteSlug]);

  if (!isShowPagesPropsDialog || !websiteSlug) {
    return <></>;
  }

  if (!pageList || pageList.length === 0) {
    window.location.href = "/admin";
  }

  const PagesPropertiesDialogBody = (
    <>
      <div className={styles["pages-list"]}>
        <PageListAccordion
          pageList={pageList}
          handleShowPagesPropDialog={handleShowPagesPropDialog}
          pageContainer={editorPage}
        />
      </div>
    </>
  );

  return (
    <>
      <Dialog
        dialogBody={PagesPropertiesDialogBody}
        icon={FileCopyRounded}
        headerLabel={t("pages")}
        handleShowDialog={handleShowPagesPropDialog}
        isShowDialog={isShowPagesPropsDialog}
        isGrabbable={false}
        elementPos={toolBarButtonRect}
        size="large"
        isShowBg
        parentActionTo={
          !pageList || pageList.length === 0 ? undefined : "/admin/pages/new"
        }
        parentActionIcon={
          !pageList || pageList.length === 0 ? undefined : NoteAddOutlined
        }
        parentActionLabel={
          !pageList || pageList.length === 0
            ? undefined
            : t("properties_dialog_1")
        }
        parentActionButtonVariant="primary"
        handleParentAction={() => {
          dispatch(logActivity("CREATE NEW PAGE", editorPage, username));
        }}
        cssPosition={cssPos}
      />
    </>
  );
}
