import React, { useRef, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Link } from "react-router-dom";
import * as editorActions from "../Editor/editorActions";
import { AddElementToolbar } from "../AddElementToolbar/AddElementToolbar";
import { EditorContent } from "../Editor/EditorContent";
import { EditorWizard } from "../EditorWizard/EditorWizard";
import ResizeToolbar from "../ResizeToolbar/ResizeToolbar";
import { UndoRedoToolbar } from "../UndoRedoToolbar/UndoRedoToolbar";
import { ElementsPropertiesDialog } from "../ElementsPropertiesDialog/ElementsPropertiesDialog";
import { getRequestIsInProgress } from "../../../../api/selectors";
import {
  getAddToolbar,
  getDraggableElementId,
  getPageSlug,
  getPageVersion,
  getPrimaryColor,
  getPrimaryFont,
  getPrimaryRadius,
  getPrimaryShadow,
  getResizeableElementId,
  getResizeToolbar,
  getSecondaryColor,
  getSecondaryFont,
  getSecondaryRadius,
  getTernaryColor,
  getTreatmentPageSlug,
} from "../../../selectors";
import {
  AddRounded,
  ArrowBackRounded,
  AssignmentTurnedInRounded,
  ColorLensRounded,
  FileCopyOutlined,
  FormatShapesRounded,
  HomeRounded,
  PublicOutlined,
  RoundedCornerRounded,
  SaveRounded,
} from "@material-ui/icons";
import { Dropdown } from "react-bootstrap";
import "editor/elements/registrar";

import "./../../../../css/style.scss";
import "./../../../../css/global-styles.scss";
import styles from "./EditorArea.module.scss";
import "./EditorArea.scss";
import {
  getChallenge,
  getCompositeLayout,
  getFinishedChallenges,
  getInsertedKey,
  getPageTests,
  getS3config,
  getSubscription,
  getWebsite,
} from "editor/states/selectors";

import useUndo from "use-undo";
import {
  clearInsertedLayoutFromScroll,
  propertySet,
  saveLayoutFromUndoRedo,
} from "editor/states/layout";

import { savePage } from "../Editor/editorActions";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";

import { useBeforeunload } from "react-beforeunload";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import LinkHelper from "../RichTextHelper/LinkHelper";
import {
  getS3Configuration,
  websitePublished,
  websitePublishStateRequested,
} from "editor/states/website";
import { Styles } from "common/elements/imports/Styles";
import { editorPage } from "../Editor/Editor";
import { logActivity } from "auth/authActions";
import { RichTextProperty } from "editor/properties";
import { AddElementToolbarDropdown } from "../AddElementToolbar/AddElementToolbarDropdown";
import { setHoveredComponentWithMousePosition } from "../AddElementToolbar/addToolbarActions";
import PublishWebsiteDialog from "../PublishWebsiteDialog/PublishWebsiteDialog";
import FontsPropDialog from "../FontsPropsDialog/FontsPropsDialog";
import ColorPropsDialog from "../ColorPropsDialog/ColorPropsDialog";
import RoundessPropsDialog from "../RoundessPropsDialog/RoundessPropsDialog";
import TestsPropertiesDialog from "../TestsPropertiesDialog/TestsPropertiesDialog";
import PageListPropertiesDialog from "../PageListPropertiesDialog/PageListPropertiesDialog";
import { ElementPropertyQuickGuide } from "../ElementPropertyQuickGuide/ElementPropertyQuickGuide";
import { CardsPropDialog } from "../CardsPropDialog/CardsPropDialog";
import { State } from "store";

import { useTranslation } from "react-i18next";

require("./../../../../css/jaquen-main.scss");
require("./../../../../css/style.scss");
require("./../../../../css/global-styles.scss");

type Props = {
  selectedToolBarButtonRect?: DOMRect;
  cssPosition?: React.CSSProperties;
};

export const EditorArea: React.FunctionComponent<Props> = ({
  selectedToolBarButtonRect,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const draggableElementId = useSelector(getDraggableElementId);
  const resizeableElementId = useSelector(getResizeableElementId);
  const stateAddToolbar = useSelector(getAddToolbar);
  const requestInProgress = useSelector(getRequestIsInProgress);
  const pageVersion = useSelector(getPageVersion);
  const s3ConfigState = useSelector(getS3config);
  const state = useSelector(getResizeToolbar);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const pageSlug = useSelector(getPageSlug);
  const treatmentPageSlug = useSelector(getTreatmentPageSlug);
  const layout = useSelector(getCompositeLayout);
  const primaryFont = useSelector(getPrimaryFont);
  const secondaryFont = useSelector(getSecondaryFont);
  const primaryColor = useSelector(getPrimaryColor);
  const secondaryColor = useSelector(getSecondaryColor);
  const ternaryColor = useSelector(getTernaryColor);
  const primaryRadius = useSelector(getPrimaryRadius);
  const secondaryRadius = useSelector(getSecondaryRadius);
  const primaryShadow = useSelector(getPrimaryShadow);
  const insertedKey = useSelector(getInsertedKey);
  const website = useSelector(getWebsite);
  const username = useSelector(getUsername);
  const statePageChallenges = useSelector((state: State) =>
    getChallenge(state, pageSlug!)
  );
  const stateFinishedPageChallenges = useSelector((state: State) =>
    getFinishedChallenges(state, pageSlug!)
  );
  const statePageTests = useSelector((state: State) =>
    getPageTests(state, pageSlug!)
  );
  const subscription = useSelector(getSubscription);
  const selectedRef = useRef<any>(null);
  const layoutElementsSize = layout["root"].elementChildrenId!.length;
  const [positionY, setPositionY] = useState<number>();
  const [tempLinkAttr, setTempAttr] = useState<{
    tempId: string;
    top: number;
    left: number;
    selectedId: string;
    rtTempText: string;
  } | null>();
  const [savedPageVersion, setSavedPageVersion] = useState(pageVersion);
  const [
    layoutState,
    { set: setLayout, undo: undoLayout, redo: redoLayout, canUndo, canRedo },
  ] = useUndo({ pageVersion, layout });
  const { present: presentLayout } = layoutState;
  const [isS3ConfigReady, setIsS3ConfigReady] = useState(
    s3ConfigState.accessKeyId === null || s3ConfigState.accessKeyId === ""
      ? false
      : true
  );
  const [showPagesPropDialog, setShowPagesPropDialog] = useState(false);
  const [showPublishWebsiteDialog, setPublishWebsiteDialog] = useState(false);
  const [showFontsPropDialog, setShowFontsPropDialog] = useState(false);
  const [showColorsPropDialog, setShowColorsPropDialog] = useState(false);
  const [showRoundessPropDialog, setShowRoundessPropDialog] = useState(false);
  const [showTestsPropDialog, setShowTestsPropDialog] = useState(false);
  const [dialogCSSPos, setDialogCSSPos] = useState<React.CSSProperties>();
  const [hideButtons, setHideButtons] = useState(false);
  const [toolBarButtonRect, setToolBarButtonRect] = useState(
    selectedToolBarButtonRect
  );
  const [mousePosX, setMousePosX] = useState<number>(0);
  const [mousePosY, setMousePosY] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageChallenges, setPageChallenges] = useState(statePageChallenges);
  const [finishedChallenges, setFinishedChallenges] = useState(
    stateFinishedPageChallenges
  );
  const [pageTest, setPageTest] = useState(statePageTests);

  const [isPremiumUser, setIsPremiumUser] = useState(false);

  useEffect(() => {
    if (subscription && subscription.isActive) {
      setIsPremiumUser(subscription.isActive);
    }
  }, [subscription]);

  useEffect(() => {
    if (
      savedPageVersion !== pageVersion ||
      (savedPageVersion === 0 && pageVersion === 0)
    ) {
      setLayout({ pageVersion, layout });
      setSavedPageVersion(pageVersion);
    }
  }, [dispatch, setLayout, layout, pageVersion, savedPageVersion]);

  useEffect(() => {
    if (pageVersion !== 0) {
      dispatch(
        saveLayoutFromUndoRedo(presentLayout.layout, presentLayout.pageVersion)
      );
    }
  }, [dispatch, presentLayout, pageVersion]);

  useEffect(() => {
    if (insertedKey) {
      const elemInserted = document.getElementById(insertedKey);
      if (elemInserted) {
        window.scrollTo({ top: elemInserted.offsetTop, behavior: "smooth" });
        dispatch(clearInsertedLayoutFromScroll());
      }
    }
  }, [dispatch, insertedKey]);

  useEffect(() => {
    if (pageSlug) {
      dispatch(editorActions.loadOtherPages(pageSlug));
    }
  }, [dispatch, pageSlug]);

  useEffect(() => {
    if (treatmentPageSlug && pageSlug && websiteSlug) {
      dispatch(editorActions.getPageChallenges(pageSlug, websiteSlug));
    }
  }, [dispatch, treatmentPageSlug, pageSlug, websiteSlug]);

  useEffect(() => {
    if (
      treatmentPageSlug &&
      treatmentPageSlug === statePageChallenges?.treatmentSlug
    ) {
      if (pageChallenges?.variants !== statePageChallenges?.variants) {
        setPageChallenges(statePageChallenges);
      }
    }

    if (stateFinishedPageChallenges) {
      setFinishedChallenges(stateFinishedPageChallenges);
    }

    if (statePageTests) {
      setPageTest(statePageTests);
    }
  }, [
    statePageChallenges,
    treatmentPageSlug,
    pageChallenges,
    stateFinishedPageChallenges,
    statePageTests,
  ]);

  useEffect(() => {
    if (!isS3ConfigReady) {
      if (
        s3ConfigState.accessKeyId === null ||
        s3ConfigState.accessKeyId === ""
      ) {
        dispatch(getS3Configuration());
        setIsS3ConfigReady(false);
      } else {
        setIsS3ConfigReady(true);
      }
    }
  }, [dispatch, s3ConfigState, isS3ConfigReady]);

  const handleUndo = () => {
    dispatch(logActivity("UNDO", editorPage, username));
    undoLayout();
  };

  const handleRedo = () => {
    dispatch(logActivity("REDO", editorPage, username));
    redoLayout();
  };

  const displayLoading = () => {
    return (
      <>
        <Spinner
          size="sm"
          animation="border"
          variant="light"
          className={styles["saving-icon"]}
        />{" "}
        {t("Saving")}
      </>
    );
  };

  const handleExitDragMode = () => {
    dispatch(logActivity("EXIT DRAG MODE", editorPage, username));
    dispatch(editorActions.cancelDraggableElement());
  };

  const handleExitResizeMode = () => {
    dispatch(logActivity("EXIT RESIZE MODE", editorPage, username));
    dispatch(editorActions.cancelResizeableElement());
  };

  const handleSavePageClick = () => {
    if (!websiteSlug || !pageSlug) {
      return;
    }
    dispatch(logActivity("SAVE PAGE", editorPage, username));
    dispatch(editorActions.savePage(websiteSlug, pageSlug, null, false));
  };

  // Save page when page is refreshed or closed
  useBeforeunload(() => {
    if (!websiteSlug || !pageSlug) {
      return;
    }
    dispatch(savePage(websiteSlug, pageSlug, null, false));
  });

  const handleTemplLink = (
    attr: {
      tempId: string;
      top: number;
      left: number;
      selectedId: string;
      rtTempText: string;
    } | null
  ) => {
    setTempAttr(attr);
  };

  const handleTempColor = (
    attr: {
      tempId: string;
      top: number;
      left: number;
      selectedId: string;
      rtTempText: string;
    } | null
  ) => {
    setTempAttr(attr);
    if (attr) {
      var template = document.createElement("div");
      var html = attr.rtTempText;
      template.innerHTML = html;
      dispatch(
        propertySet(
          attr.selectedId,
          "richTextProperty",
          new RichTextProperty(template.innerHTML)
        )
      );
      dispatch(
        editorActions.setRtTempText(attr.selectedId, template.innerHTML)
      );
    }
  };

  const handleDisplayLinkHelper = () => {
    setTempAttr(null);
  };

  const handleMouseOver = (event: React.MouseEvent) => {
    const clientRect = event.currentTarget.getBoundingClientRect();
    const rect = {
      x: clientRect.left,
      y: clientRect.top,
      width: clientRect.width,
      height: clientRect.height,
    };
    const scroll = {
      x: window.scrollX,
      y: window.scrollY,
    };
    dispatch(
      setHoveredComponentWithMousePosition(
        "root",
        "root",
        layoutElementsSize,
        rect,
        scroll,
        event.clientX,
        event.clientY,
        () => {
          event.stopPropagation();
        },
        false
      )
    );
  };

  const handleMouseDown = (event: React.MouseEvent, isBottom?: boolean) => {
    resetMenuDialogs();
    const clientRect = event.currentTarget.getBoundingClientRect();
    setPositionY(clientRect.y);
    const overridenRect = new DOMRect(
      clientRect.x,
      clientRect.y,
      clientRect.width,
      clientRect.y
    );
    setToolBarButtonRect(overridenRect);

    if (isBottom) {
      setDialogCSSPos({
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        height: "fit-content",
        zIndex: 1000,
      });
    } else {
      setDialogCSSPos({
        left: 110,
        top: clientRect.y,
        marginLeft: "12px",
        marginTop: "-16px",
        zIndex: 1000,
      });
    }
  };

  const resetMenuDialogs = () => {
    setPublishWebsiteDialog(false);
    setShowPagesPropDialog(false);
    setShowFontsPropDialog(false);
    setShowColorsPropDialog(false);
    setShowRoundessPropDialog(false);
    setShowTestsPropDialog(false);
  };

  const handleShowPagesPropDialog = () => {
    showPagesPropDialog
      ? setShowPagesPropDialog(false)
      : setShowPagesPropDialog(true);
    if (showPagesPropDialog) {
      dispatch(
        logActivity("CLOSE DIALOG", editorPage, username, "Pages Dialog")
      );
    } else {
      dispatch(
        logActivity("OPEN DIALOG", editorPage, username, "Pages Dialog")
      );
    }
  };

  const handleShowTestsDialog = () => {
    showTestsPropDialog
      ? setShowTestsPropDialog(false)
      : setShowTestsPropDialog(true);
    if (showTestsPropDialog) {
      dispatch(
        logActivity("CLOSE DIALOG", editorPage, username, "Tests Dialog")
      );
    } else {
      dispatch(
        logActivity("OPEN DIALOG", editorPage, username, "Tests Dialog")
      );
    }
  };

  const handleShowFontsPropDialog = () => {
    showFontsPropDialog
      ? setShowFontsPropDialog(false)
      : setShowFontsPropDialog(true);
    if (showFontsPropDialog) {
      dispatch(
        logActivity("CLOSE DIALOG", editorPage, username, "Fonts Dialog")
      );
    } else {
      dispatch(
        logActivity("OPEN DIALOG", editorPage, username, "Fonts Dialog")
      );
    }
  };

  const handleShowColorsPropDialog = () => {
    showColorsPropDialog
      ? setShowColorsPropDialog(false)
      : setShowColorsPropDialog(true);
    if (showColorsPropDialog) {
      dispatch(
        logActivity("CLOSE DIALOG", editorPage, username, "Colors Dialog")
      );
    } else {
      dispatch(
        logActivity("OPEN DIALOG", editorPage, username, "Colors Dialog")
      );
    }
  };

  const handleShowRoundessPropDialog = () => {
    showRoundessPropDialog
      ? setShowRoundessPropDialog(false)
      : setShowRoundessPropDialog(true);
    if (showRoundessPropDialog) {
      dispatch(
        logActivity("CLOSE DIALOG", editorPage, username, "Radius Dialog")
      );
    } else {
      dispatch(
        logActivity("OPEN DIALOG", editorPage, username, "Radius Dialog")
      );
    }
  };

  const handlePagesNavClick = (event: React.MouseEvent) => {
    resetMenuDialogs();
    handleMouseDown(event, true);
    handleShowPagesPropDialog();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleTestsNavClick = (event: React.MouseEvent) => {
    resetMenuDialogs();
    handleMouseDown(event, true);
    handleShowTestsDialog();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleShowWebsitePublishPropDialog = () => {
    showPublishWebsiteDialog
      ? setPublishWebsiteDialog(false)
      : setPublishWebsiteDialog(true);

    if (showPublishWebsiteDialog === false) {
      dispatch(
        logActivity("OPEN DIALOG", editorPage, username, "Publish Dialog")
      );
      dispatch(websitePublishStateRequested(websiteSlug!));
    } else {
      dispatch(
        logActivity("CLOSE DIALOG", editorPage, username, "Publish Dialog")
      );
    }
  };

  const handlePublishNavClick = (event: React.MouseEvent) => {
    resetMenuDialogs();
    handleMouseDown(event, true);
    handleSavePageClick();
    handleShowWebsitePublishPropDialog();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleFontsNavClick = (event: React.MouseEvent) => {
    resetMenuDialogs();
    handleMouseDown(event);
    handleShowFontsPropDialog();
  };

  const handleColorsNavClick = (event: React.MouseEvent) => {
    resetMenuDialogs();
    handleMouseDown(event);
    handleShowColorsPropDialog();
  };

  const handleRoundessNavClick = (event: React.MouseEvent) => {
    resetMenuDialogs();
    handleMouseDown(event);
    handleShowRoundessPropDialog();
  };

  const handlePublishClick = () => {
    if (!website.slug) {
      return;
    }
    dispatch(
      logActivity("PUBLISH WEBSITE", editorPage, username, website.slug)
    );
    dispatch(websitePublished(website.slug));
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    // setMouseMoved(true)
    setHideButtons(false);
    setMousePosX(event.clientX);
    setMousePosY(event.clientY);
  };

  return (
    <div className="editor-area">
      {draggableElementId || resizeableElementId ? (
        <></>
      ) : (
        <div
          className={`${styles["buttons-container"]} ${
            hideButtons ? styles["hide"] : styles["show"]
          }`}
        >
          <div
            className={`${styles["add-component-button"]} ${
              layoutElementsSize < 1 ? "blink" : ""
            }`}
          >
            <Dropdown
              onMouseDown={handleMouseDown}
              onMouseOver={handleMouseOver}
            >
              <Dropdown.Toggle id="toggle">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="add-tooltip">{t("editor_area_add")}</Tooltip>
                  }
                >
                  <AddRounded className="rotate-add" />
                </OverlayTrigger>
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={AddElementToolbarDropdown}
                elementParentId={stateAddToolbar.elementParentId}
                index={stateAddToolbar.index}
                elementKeys={stateAddToolbar.elementKeys}
                close={() => {}}
                height={stateAddToolbar.rect.height}
                width={stateAddToolbar.rect.width}
                drop={"bottom"}
                positionY={positionY}
                websiteSlug={websiteSlug}
                pageSlug={pageSlug}
                isFromMainAd={true}
                website={website}
              ></Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={styles["top-buttons"]}>
            <div
              className={styles["secondary-button"]}
              onMouseDown={handleFontsNavClick}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="font-tooltip">{t("editor_area_fonts")}</Tooltip>
                }
              >
                <FormatShapesRounded />
              </OverlayTrigger>
            </div>
            <div
              className={styles["secondary-button"]}
              onMouseDown={handleColorsNavClick}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="colors-tooltip">
                    {t("editor_area_colors")}
                  </Tooltip>
                }
              >
                <ColorLensRounded />
              </OverlayTrigger>
            </div>
            <div
              className={styles["secondary-button"]}
              onMouseDown={handleRoundessNavClick}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="curves-tooltip">
                    {t("editor_area_curves")}
                  </Tooltip>
                }
              >
                <RoundedCornerRounded />
              </OverlayTrigger>
            </div>
          </div>
          <div className={styles["bottom-buttons"]}>
            <div
              className={styles["secondary-button"]}
              onMouseDown={handlePublishNavClick}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="publish-tooltip">
                    {t("editor_area_publish")}
                  </Tooltip>
                }
              >
                <PublicOutlined />
              </OverlayTrigger>
            </div>
            <div
              className={styles["secondary-button"]}
              onMouseDown={handlePagesNavClick}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="pages-tooltip">{t("editor_area_pages")}</Tooltip>
                }
              >
                <FileCopyOutlined />
              </OverlayTrigger>
            </div>
            <div
              className={styles["secondary-button"]}
              onMouseDown={handleTestsNavClick}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tests-tooltip">{t("editor_area_tests")}</Tooltip>
                }
              >
                <AssignmentTurnedInRounded />
              </OverlayTrigger>
            </div>
            <div className={styles["secondary-button"]}>
              <Link
                className={styles["editor-toolbar-button"]}
                to="/admin"
                onClick={handleSavePageClick}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="admin-tooltip">{t("back_to_admin")}</Tooltip>
                  }
                >
                  <HomeRounded />
                </OverlayTrigger>
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className={styles["undo-redo-container"]}>
        {draggableElementId ? (
          <>
            <div onClick={handleExitDragMode} className={styles["save-link"]}>
              <ArrowBackRounded className={styles["save-icon"]} />
              {t("editor_area_drag")}
            </div>
            <div
              style={{
                borderRadius: 4,
                border: "1px solid white",
                height: 32,
                marginRight: 8,
              }}
            ></div>
            <div onClick={handleSavePageClick} className={styles["save-link"]}>
              {requestInProgress ? (
                displayLoading()
              ) : (
                <>
                  <SaveRounded className={styles["save-icon"]} />
                  {t("Changes_Saved")}
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {resizeableElementId ? (
          <>
            <div onClick={handleExitResizeMode} className={styles["save-link"]}>
              <ArrowBackRounded className={styles["save-icon"]} />
              {t("editor_area_exit_resize")}
            </div>
            <div
              style={{
                borderRadius: 4,
                border: "1px solid white",
                height: 32,
                marginRight: 8,
              }}
            ></div>
            <div onClick={handleSavePageClick} className={styles["save-link"]}>
              {requestInProgress ? (
                displayLoading()
              ) : (
                <>
                  <SaveRounded className={styles["save-icon"]} />
                  {t("Changes_Saved")}
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {!draggableElementId && !resizeableElementId ? (
          <>
            <UndoRedoToolbar
              undoLayout={handleUndo}
              redoLayout={handleRedo}
              canUndo={canUndo}
              canRedo={canRedo}
            />
            <div
              style={{
                borderRadius: 4,
                border: "1px solid white",
                height: 32,
                marginRight: 8,
              }}
            ></div>
            <div onClick={handleSavePageClick} className={styles["save-link"]}>
              {requestInProgress ? (
                displayLoading()
              ) : (
                <>
                  <SaveRounded className={styles["save-icon"]} />
                  {t("Changes_Saved")}
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={styles["editor-container"]} onMouseMove={handleMouseMove}>
        <div className="main">
          <Switch>
            <Route path={`/admin/pages/:pageSlug/edit`}>
              <Styles
                primaryFont={primaryFont ? primaryFont : ""}
                secondaryFont={secondaryFont ? secondaryFont : ""}
                primaryColor={primaryColor ? primaryColor : ""}
                secondaryColor={secondaryColor ? secondaryColor : ""}
                ternaryColor={ternaryColor ? ternaryColor : ""}
                primaryRadius={primaryRadius ? primaryRadius : ""}
                secondaryRadius={secondaryRadius ? secondaryRadius : ""}
                primaryShadow={primaryShadow ? primaryShadow : ""}
              />
              <EditorContent selectedRef={selectedRef} layout={layout} />
            </Route>
            <Route path={`/admin/pages/new`}>
              <EditorWizard />
            </Route>
          </Switch>
        </div>
        {layoutElementsSize > 0 ? (
          <>
            <CardsPropDialog />
            <ElementPropertyQuickGuide
              selectedRef={selectedRef}
              mousePosX={mousePosX}
              mousePosY={mousePosY}
            />
            {/* <AddElementToolbarGuides /> */}
            <AddElementToolbar id="add-element-toolbar" />
            <ResizeToolbar selectedRef={selectedRef} />
            <RichTextEditor
              selectedRef={selectedRef}
              handleTemplLink={handleTemplLink}
              handleTempColor={handleTempColor}
            />
            <LinkHelper
              tempLinkAttr={tempLinkAttr}
              handleDisplayLinkHelper={handleDisplayLinkHelper}
            />
          </>
        ) : (
          <></>
        )}
        {state.state === "resizing" ? (
          <></>
        ) : (
          <ElementsPropertiesDialog
            selectedRef={selectedRef}
            showElementsPropDialog={true}
          />
        )}
      </div>
      <PageListPropertiesDialog
        isShowPagesPropsDialog={showPagesPropDialog}
        toolBarButtonRect={toolBarButtonRect}
        handleShowPagesPropDialog={handleShowPagesPropDialog}
        cssPos={dialogCSSPos}
      />
      <PublishWebsiteDialog
        showWebsitePublishDialog={showPublishWebsiteDialog}
        handleShowWebsitePublishPropDialog={handleShowWebsitePublishPropDialog}
        toolBarButtonRect={toolBarButtonRect}
        handlePublish={handlePublishClick}
        cssPos={dialogCSSPos}
      />
      <FontsPropDialog
        isShowFontsPropsDialog={showFontsPropDialog}
        handleShowPropDialog={handleShowFontsPropDialog}
        toolBarButtonRect={toolBarButtonRect}
        cssPos={dialogCSSPos}
      />
      <ColorPropsDialog
        isShowFontsPropsDialog={showColorsPropDialog}
        handleShowPropDialog={handleShowColorsPropDialog}
        toolBarButtonRect={toolBarButtonRect}
        cssPos={dialogCSSPos}
      />
      <RoundessPropsDialog
        isShowFontsPropsDialog={showRoundessPropDialog}
        handleShowPropDialog={handleShowRoundessPropDialog}
        toolBarButtonRect={toolBarButtonRect}
        cssPos={dialogCSSPos}
      />
      <TestsPropertiesDialog
        isShowTestsPropsDialog={showTestsPropDialog}
        handleShowTestsPropDialog={handleShowTestsDialog}
        toolBarButtonRect={toolBarButtonRect}
        pageContainer={editorPage}
        cssPos={dialogCSSPos}
        challenges={pageChallenges}
        finishedChallenges={finishedChallenges}
        pageTest={pageTest}
        isPremiumUser={isPremiumUser}
      />
    </div>
  );
};
