/* AWS S3 config options */
/* Highly recommended to declare the config object in an external file import it when needed */

/* s3Config.ts */

export const s3Config = {
  bucketName: "img.goodweb.app",
  dirName: "sites" /* Optional */,
  region: "us-east-1",
  accessKeyId: "",
  secretAccessKey: "",
  s3Url: "https://s3.amazonaws.com/img.goodweb.app" /* Optional */,
  signatureVersion: "v4",
};
