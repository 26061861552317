import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Facebook, Twitter } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { realestateChild1Template } from "./realestateChild1";
import { realestateChild2Template } from "./realestateChild2";
import { realestateChild3Template } from "./realestateChild3";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { HeadingElement } from "editor/elements/base/Heading";
import { LeadElement } from "editor/elements/base/Lead";
import { ArticleElement } from "editor/elements/components/Article";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { ImageElement } from "editor/elements/base/Image/Image";
import { ButtonElement } from "editor/elements/components/Button/Button";
import { CardsElement } from "editor/elements/sections/Cards/Cards";
import { CardsContainerElement } from "editor/elements/components/CardsContainer/CardsContainer";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const realestateTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "realestate";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 4,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 5,
    templateTitle: templateKey,
  },
  {
    elementKey: "messenger",
    position: 6,
    templateTitle: templateKey,
  },
  {
    elementKey: "whatsapp",
    position: 7,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultNavbar(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/realestate/main.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        "1",
        new BackgroundColorProperty("#ffffff"),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "2",
        "2"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/realestate/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright real.estate " + new Date().getFullYear()
      );

    default:
      return realestateTemplates[layoutKey];
  }
};

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page Real Estate",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: realestateChild1Template,
  },
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "realestateChild1Template",
    details: {
      title: "Properties",
      slug: randomSlug(6),
      description: "Available Properties",
    },
    template: {
      template: realestateChild1Template,
    },
  },
  {
    templateName: "realestateChild2Template",
    details: {
      title: "About Us",
      slug: randomSlug(6),
      description: "About Us",
    },
    template: {
      template: realestateChild2Template,
    },
  },
  {
    templateName: "realestateChild3Template",
    details: {
      title: "Contact Us",
      slug: randomSlug(6),
      description: "Contact Us",
    },
    template: {
      template: realestateChild3Template,
    },
  },
];

realestateTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "Real Estate",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

realestateTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "Makeup",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);

realestateTemplates["navbar"] = TemplatesFactory.createDefaultNavbar(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/realestate/main.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "1",
  new BackgroundColorProperty("#ffffff"),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "2",
  "2"
);

realestateTemplates["articles"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#fff",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(0, 41, 133);">Find your next piece of property</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div id="5a9zjw46e" elementkey="lead" websiteslug="yelswebsite" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><div><div><div><div><br></div><div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt.</div><div><br></div></div></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            ButtonElement.create({
              buttonStyle: new ButtonStyleProperty("hobaw-btn-secondary"),
              buttonLink: new LinkProperty(
                "See Properties",
                "",
                false,
                false,
                false
              ),
              treatmentProperty: new TreatmentProperty(false),
              isProduction: false,
              isButton: false,
              isTestable: false,
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1524026986132-000404263b59?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

realestateTemplates["cards"] = LayoutEditor.createEmpty()
  .add(
    CardsElement.create({
      cardsStyle: "0",
      backgroundColorProperty: "#F6F6F6",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "parent",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(0, 41, 133);">Properties</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        CardsContainerElement.create({
          cardsStyle: "1",
          cardsOrientation: "1",
          cardsAlignment: "2",
        })
      )
      .addChildrenArray([
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Greens</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1515263487990-61b07816b324?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBhcnRtZW50JTIwYnVpbGRpbmd8ZW58MHx8MHxibHVlfA%3D%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Once Centro</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1572798177370-303020c3e6e2?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YXBhcnRtZW50JTIwYnVpbGRpbmd8ZW58MHx8MHxibHVlfA%3D%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Alveo</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1528290526716-9c58aa233fb7?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8YXBhcnRtZW50JTIwYnVpbGRpbmd8ZW58MHx8MHxibHVlfA%3D%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>El Patio</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1567320743368-9db24e12ebf0?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHVyYmFufGVufDB8fDB8Ymx1ZXw%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Parkford Suites</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1511452885600-a3d2c9148a31?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fGFyY2hpdGVjdHVyZXxlbnwwfHwwfGJsdWV8&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Viento</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1536557869186-5a2f30e1ba9c?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTM1fHxhcmNoaXRlY3R1cmV8ZW58MHx8MHxibHVlfA%3D%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
      ])
      .build()
  )
  .build();

realestateTemplates["articles2"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#FFF3F3",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(0, 41, 133);">Helping thousands find their place</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                "<div>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Inventore officia omnis aperiam aliquid provident cum quos quo sint nulla excepturi.</div>"
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1616587896595-51352538155b?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

realestateTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("1"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Book an Appointment</h2>"),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Fill in your details below and we'll be in contact with you soon."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

realestateTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/realestate/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright real.estate " + new Date().getFullYear()
);
