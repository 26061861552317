import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Container, Row, Col, Form } from "react-bootstrap";
import * as yup from "yup";

import { CheckRounded, RemoveRounded } from "@material-ui/icons";

import styles from "./Admin.module.scss";
import selectStyles from "./Select.module.scss";
import { ISelectProps } from "./Select";
import { useDispatch, useSelector } from "react-redux";
import {
  getDLocalAnnualPrice,
  getDLocalCountryIsoLanguageCode,
  getDLocalCurrencyCode,
  getDLocalPrice,
  getPaymentOptions,
  getPaypalAnnualPrice,
  getPaypalCountryIsoLanguageCode,
  getPaypalCurrencyCode,
  getPaypalPrice,
  getPreferredPaymentOption,
  getUsername,
} from "auth/selectors";
import { logActivity } from "auth/authActions";
import { isDesktopView } from "../admin/utils-country";

import { useTranslation } from "react-i18next";
import { setupPage } from "./Container";

const containerClassName = "radio-container";
const containerClassNameSelected = containerClassName + "-selected";

const schema = yup.object({
  category: yup.mixed<PlanCategoryEnum>().required(/* TODO: Add a message */),
});

export enum PlanCategoryEnum {
  NONE = "NONE",
  FREE = "FREE",
  PAID = "PAID",
  PAID_ANNUAL = "PAID_ANNUAL",
}

export type SelectStep0FormValues = yup.InferType<typeof schema>;

export const SelectStep0Form: React.FunctionComponent<ISelectProps> = ({
  handleSaveAndContinue,
  values,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const username = useSelector(getUsername);
  const paymentOptions = useSelector(getPaymentOptions);
  const preferredPaymentOption = useSelector(getPreferredPaymentOption);

  const paypalAnnualPrice = useSelector(getPaypalAnnualPrice);
  const paypalCountryIsoLanguageCode = useSelector(
    getPaypalCountryIsoLanguageCode
  );
  const paypalCurrencyCode = useSelector(getPaypalCurrencyCode);
  const paypalPrice = useSelector(getPaypalPrice);

  const dlocalPrice = useSelector(getDLocalPrice);
  const dlocalAnnualPrice = useSelector(getDLocalAnnualPrice);
  const dlocalCurrency = useSelector(getDLocalCurrencyCode);
  const dLocalCountryIsoLanguageCode = useSelector(
    getDLocalCountryIsoLanguageCode
  );
  const [priceFormatted, setPriceFormatted] = useState<string | undefined>();
  const [annualPriceFormatted, setAnnualPriceFormatted] = useState<
    string | undefined
  >();
  const [priceFormattedFontSize, setPriceFormattedFontSize] = useState<
    number | undefined
  >();
  const [
    annualPriceFormattedFontSize,
    setAnnualPriceFormattedFontSize,
  ] = useState<number | undefined>();
  const [currencyCode, setCurrencyCode] = useState<string | undefined>();
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    if (paymentOptions && preferredPaymentOption) {
      if (
        preferredPaymentOption === "dlocal" &&
        dLocalCountryIsoLanguageCode &&
        dlocalCurrency &&
        dlocalAnnualPrice &&
        dlocalPrice
      ) {
        const annualPriceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalAnnualPrice);
        const priceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalPrice);
        setAnnualPriceFormatted(annualPriceFormatted);
        setPriceFormatted(priceFormatted);
        setCurrencyCode(dlocalCurrency);
        setAnnualPriceFormattedFontSize(12 / annualPriceFormatted.length);
        setPriceFormattedFontSize(12 / priceFormatted.length);
      } else {
        if (
          paypalCountryIsoLanguageCode &&
          paypalCurrencyCode &&
          paypalAnnualPrice &&
          paypalPrice
        ) {
          const annualPriceFormatted = new Intl.NumberFormat(
            paypalCountryIsoLanguageCode,
            {
              currency: paypalCurrencyCode,
              style: "currency",
            }
          ).format(paypalAnnualPrice);
          const priceFormatted = new Intl.NumberFormat(
            paypalCountryIsoLanguageCode,
            {
              currency: paypalCurrencyCode,
              style: "currency",
            }
          ).format(paypalPrice);
          setAnnualPriceFormatted(annualPriceFormatted);
          setPriceFormatted(priceFormatted);
          setCurrencyCode(paypalCurrencyCode);
          setAnnualPriceFormattedFontSize(12 / annualPriceFormatted.length);
          setPriceFormattedFontSize(12 / priceFormatted.length);
        }
      }

      if (
        preferredPaymentOption === "dlocal" &&
        dLocalCountryIsoLanguageCode &&
        dlocalCurrency &&
        dlocalAnnualPrice &&
        dlocalPrice
      ) {
        const annualPriceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalAnnualPrice);
        const priceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalPrice);
        setAnnualPriceFormatted(annualPriceFormatted);
        setPriceFormatted(priceFormatted);
        setCurrencyCode(dlocalCurrency);
        setAnnualPriceFormattedFontSize(24 / annualPriceFormatted.length);
        setPriceFormattedFontSize(24 / priceFormatted.length);
      }
    }
  }, [
    paymentOptions,
    preferredPaymentOption,
    dLocalCountryIsoLanguageCode,
    dlocalCurrency,
    dlocalAnnualPrice,
    dlocalPrice,
    paypalCountryIsoLanguageCode,
    paypalCurrencyCode,
    paypalAnnualPrice,
    paypalPrice,
  ]);

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, []);

  const onSubmit = (values: SelectStep0FormValues) => {
    handleSaveAndContinue(values);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <h5>{t("setup_page_wizard1")}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: isDesktop ? "1rem" : "0" }}>
            <Container>
              <Form.Group
                as={isDesktop ? Row : Col}
                style={{
                  paddingRight: isDesktop ? "15px" : "0",
                  paddingLeft: isDesktop ? "15px" : "0",
                }}
              >
                <Col style={{ marginBottom: isDesktop ? "0" : "12px" }}>
                  <Form.Check
                    type="radio"
                    id="category-free"
                    className={`${
                      values.category === PlanCategoryEnum.FREE
                        ? styles[containerClassNameSelected]
                        : styles[containerClassName]
                    }
                        ${styles["theme-selector"]} ${
                      styles["theme-selector-free"]
                    }`}
                  >
                    <Form.Check.Input
                      type="radio"
                      name="category"
                      value={PlanCategoryEnum.FREE}
                      checked={values.category === PlanCategoryEnum.FREE}
                      onChange={handleChange}
                      onClick={() => {
                        dispatch(
                          logActivity("CHOSE FREEMIUM", setupPage, username)
                        );
                      }}
                      isValid
                      className={styles["radio"]}
                    />
                    <Form.Check.Label className={`${styles["label"]}`}>
                      <span className={`${selectStyles["price-muted"]}`}>
                        {t("setup_page_wizard2")}
                      </span>
                      <div className={`${selectStyles["select-dialog"]}`}>
                        <div
                          className={`${selectStyles["price"]} ${selectStyles["price-muted"]}`}
                        >
                          <span className={`${selectStyles["currency-sign"]}`}>
                            $
                          </span>
                          0
                        </div>
                        <span className={selectStyles["price-desc"]}>
                          {t("setup_page_wizard3")} <br />
                          {t("setup_page_wizard4")}
                        </span>
                        <ul className={selectStyles["list"]}>
                          <li>
                            <RemoveRounded className={selectStyles["muted"]} />{" "}
                            {t("setup_page_wizard_feature1")}
                          </li>
                          <li>
                            <RemoveRounded className={selectStyles["muted"]} />{" "}
                            {t("setup_page_wizard_feature2")}
                          </li>
                          <li>
                            <RemoveRounded className={selectStyles["muted"]} />{" "}
                            {t("setup_page_wizard_feature3")}
                          </li>
                          <li>
                            <RemoveRounded className={selectStyles["muted"]} />{" "}
                            {t("setup_page_wizard_feature4")}
                          </li>
                          <li>
                            <RemoveRounded className={selectStyles["muted"]} />{" "}
                            {t("setup_page_wizard_feature5")}
                          </li>
                        </ul>
                      </div>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col style={{ marginBottom: isDesktop ? "0" : "12px" }}>
                  <Form.Check
                    type="radio"
                    id="category-annual"
                    className={`${
                      values.category === PlanCategoryEnum.PAID_ANNUAL
                        ? styles[containerClassNameSelected]
                        : styles[containerClassName]
                    }
                    ${styles["theme-selector"]} ${
                      styles["theme-selector-annual"]
                    }`}
                  >
                    <Form.Check.Input
                      type="radio"
                      name="category"
                      value={PlanCategoryEnum.PAID_ANNUAL}
                      checked={values.category === PlanCategoryEnum.PAID_ANNUAL}
                      onClick={() => {
                        dispatch(
                          logActivity("CHOSE PAID ANNUAL", setupPage, username)
                        );
                      }}
                      onChange={handleChange}
                      isValid
                      className={styles["radio"]}
                    />
                    <Form.Check.Label className={styles["label"]}>
                      {t("setup_page_wizard_premium1")}
                      <div className={`${selectStyles["select-dialog"]}`}>
                        <div
                          className={`${selectStyles["price"]}`}
                          style={{
                            fontSize: `${annualPriceFormattedFontSize}rem`,
                          }}
                        >
                          {annualPriceFormatted}
                          <span className={`${selectStyles["currency-code"]}`}>
                            {currencyCode}
                          </span>
                        </div>
                        <span className={selectStyles["price-desc"]}>
                          {t("upgrade_page_per_annual")} <br />
                          {t("upgrade_cancel")}
                        </span>
                        <ul className={selectStyles["list"]}>
                          <li style={{ fontWeight: 800 }}>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature0")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature1")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature2")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature3")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature4")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature5")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature6")}
                          </li>
                        </ul>
                      </div>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col style={{ marginBottom: isDesktop ? "0" : "12px" }}>
                  <Form.Check
                    type="radio"
                    id="category-monthly"
                    className={`${
                      values.category === PlanCategoryEnum.PAID
                        ? styles[containerClassNameSelected]
                        : styles[containerClassName]
                    }
                    ${styles["theme-selector"]} ${
                      styles["theme-selector-monthly"]
                    }`}
                  >
                    <Form.Check.Input
                      type="radio"
                      name="category"
                      value={PlanCategoryEnum.PAID}
                      checked={values.category === PlanCategoryEnum.PAID}
                      onChange={handleChange}
                      onClick={() => {
                        dispatch(
                          logActivity("CHOSE PAID MONTHLY", setupPage, username)
                        );
                      }}
                      isValid
                      className={styles["radio"]}
                    />
                    <Form.Check.Label className={styles["label"]}>
                      {t("upgrade_page_premium_monthly")}
                      <div className={`${selectStyles["select-dialog"]}`}>
                        <div
                          className={`${selectStyles["price"]}`}
                          style={{ fontSize: `${priceFormattedFontSize}rem` }}
                        >
                          {priceFormatted}
                          <span className={`${selectStyles["currency-code"]}`}>
                            {currencyCode}
                          </span>
                        </div>
                        <span className={selectStyles["price-desc"]}>
                          {t("upgrade_page_summary")} <br />
                          {t("upgrade_cancel")}
                        </span>
                        <ul className={selectStyles["list"]}>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature1")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature2")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature3")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature4")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature5")}
                          </li>
                          <li>
                            <CheckRounded />{" "}
                            {t("upgrade_page_subscription_feature6")}
                          </li>
                        </ul>
                      </div>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Group>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ borderRadius: 32 }}
              type="submit"
              disabled={values.category === PlanCategoryEnum.NONE}
            >
              {values.category === PlanCategoryEnum.NONE
                ? t("setup_page_wizard1")
                : t("next")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
