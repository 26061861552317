import React, { useCallback, useState, useEffect } from "react";
import { IProperty, PropertyComponent } from "../types";
import { useDropzone } from "react-dropzone";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import { SaveRounded } from "@material-ui/icons";
import { ArrowBackRounded, BackupRounded } from "@material-ui/icons";
import styles from "./AlternateLogoImageProperty.module.scss";
import { Button } from "editor/components/base/Button/Button";
import { uploadFile } from "admin/S3Client";
import { useDispatch, useSelector } from "react-redux";
import { getUserWebsiteSlug } from "auth/selectors";
import { getS3config, getWebsite } from "editor/states/selectors";
import { propertySet } from "editor/states/layout";
import { getS3Configuration } from "editor/states/website";
import html2canvas from "html2canvas";

import { urltoFile } from "../../../admin/image-utils";
import { useTranslation } from "react-i18next";

export class AlternateLogoImageProperty implements IProperty {
  constructor(
    readonly src: string | undefined,
    readonly lastUpdateDate: string
  ) {}

  render(elementId: string, propertyKey: string) {
    return (
      <AlternateLogoImagePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
        src={this.src}
      />
    );
  }

  static parse(value: any) {
    return new AlternateLogoImageProperty(value.src, value.lastUpdateDate);
  }
}

const AlternateLogoImagePropertyComponent: PropertyComponent<AlternateLogoImageProperty> = ({
  elementId,
  propertyKey,
  src,
}) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 50,
    height: 50,
  });
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const s3ConfigState = useSelector(getS3config);
  const [savedImgSrc, setSavedImgSrc] = useState<string | undefined>(src);
  const [imgStrUrl, setImgStrUrl] = useState<string | undefined>(undefined);
  const [imgOriginal, setImgOriginal] = useState<HTMLImageElement>();
  const [cropImgUrl, setCropImgUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [footerLoc, setFooterLoc] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getS3Configuration());
  }, [dispatch]);

  useEffect(() => {
    // Check if alternate logo container exists, update that too
    const altLogo = document.querySelector(
      "[elementkey='alternate-logo-container']"
    );
    if (altLogo && footerLoc) {
      const altLogoId = altLogo.getAttribute("id");
      if (altLogoId && footerLoc) {
        dispatch(
          propertySet(
            altLogoId,
            "logoImage",
            new AlternateLogoImageProperty(footerLoc, new Date().toISOString())
          )
        );
      }
    }
  }, [dispatch, footerLoc]);

  function calculateAspectRatioFit(
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
  ) {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      setFileType(file.type);
      const reader = new FileReader();
      reader.onabort = () => console.log("File reading was aborted");
      reader.onerror = () => console.log("File reading has failed");
      reader.onload = () => {
        const imgStr = reader.result as string;
        setImgStrUrl(imgStr);

        let img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.src = objectUrl;
        img.onload = function () {
          var maxWidth = 556; // Max width for the image
          var maxHeight = 260; // Max height for the image

          if (img.width > maxWidth || img.height > maxHeight) {
            const newDimensions = calculateAspectRatioFit(
              img.width,
              img.height,
              maxWidth,
              maxHeight
            );
            img.width = newDimensions.width;
            img.height = newDimensions.height;
          }
          setImgOriginal(img);
          URL.revokeObjectURL(objectUrl);
        };
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    minSize: 0,
    maxSize: 2097152, // 2MB max
    onDrop,
  });

  if (websiteSlug === undefined && website === undefined) {
    return <></>;
  }

  const onCropChange = (changedCrop: Crop) => {
    setCrop(changedCrop);
  };

  const onComplete = (changedCrop: Crop) => {
    makeClientCrop(changedCrop);
  };

  const makeClientCrop = (newCrop: Crop) => {
    if (imgOriginal && newCrop.width && newCrop.height) {
      const croppedImageUrl = getCroppedImg(imgOriginal, newCrop);
      setCropImgUrl(croppedImageUrl!);
    }
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div className={styles["drop-zone-label-errors"]}>
        {errors.map((e) => (
          <span key={e.code}>{e.message}</span>
        ))}
      </div>
    );
  });

  const getCroppedImg = (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width! * pixelRatio * scaleX;
    canvas.height = crop.height! * pixelRatio * scaleY;

    if (ctx && imgStrUrl) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        crop.x! * scaleX,
        crop.y! * scaleY,
        crop.width! * scaleX,
        crop.height! * scaleY,
        0,
        0,
        crop.width! * scaleX,
        crop.height! * scaleY
      );
      let mimeType = imgStrUrl.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0];

      return canvas.toDataURL(mimeType);
    }
  };

  const handleReuploadTrigger = () => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new AlternateLogoImageProperty(undefined, new Date().toISOString())
      )
    );
    setImgStrUrl(undefined);
    setSavedImgSrc(undefined);
    open();
  };

  const handleUploadCroppedImage = () => {
    let filename = "main";
    if (website.title) filename = "main";
    urltoFile(cropImgUrl, filename, "png", fileType).then(function (file) {
      if (websiteSlug !== undefined) {
        uploadFile(file, websiteSlug, filename, s3ConfigState).then(function (
          loc
        ) {
          if (loc !== undefined) {
            dispatch(
              propertySet(
                elementId,
                propertyKey,
                new AlternateLogoImageProperty(loc, new Date().toISOString())
              )
            );
          }
        });
      }
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const uploadOtherAssets = (cropImgUrl: string, filename: string) => {
    urltoFile(cropImgUrl, filename, "png", fileType).then(function (file) {
      if (websiteSlug !== undefined) {
        let promise = uploadFile(file, websiteSlug, filename, s3ConfigState);
        if (filename === "mono") {
          promise.then((location) => {
            setFooterLoc(location);
          });
        }
      }
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function resizeImage(
    url: string,
    width: number,
    height: number,
    file: File,
    filename: string,
    callback: any
  ) {
    var sourceImage = new Image();

    sourceImage.onload = (function (f) {
      return function (evt: any) {
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        if (sourceImage.width === sourceImage.height) {
          if (ctx) {
            ctx.drawImage(sourceImage, 0, 0, width, height);
          }
        } else {
          const minVal = Math.min(sourceImage.width, sourceImage.height);
          if (sourceImage.width > sourceImage.height) {
            if (ctx) {
              ctx.drawImage(
                sourceImage,
                (sourceImage.width - minVal) / 2,
                0,
                minVal,
                minVal,
                0,
                0,
                width,
                height
              );
            }
          } else {
            if (ctx) {
              ctx.drawImage(
                sourceImage,
                0,
                (sourceImage.height - minVal) / 2,
                minVal,
                minVal,
                0,
                0,
                width,
                height
              );
            }
          }
        }
        if (canvas.toDataURL()) callback(canvas.toDataURL(), filename);
      };
    })(file);

    sourceImage.src = url;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createOgImage = (id: string, idProp: string) => {
    if (id && idProp) {
      const toCapture = document.getElementById(id);
      if (toCapture) {
        html2canvas(toCapture, {
          backgroundColor: "rgba(0, 0, 0, 0)",
          removeContainer: true,
        }).then(function (canvas: HTMLCanvasElement) {
          let filename = idProp;
          urltoFile(canvas.toDataURL(), filename, "png", "image/png").then(
            function (file) {
              if (websiteSlug !== undefined) {
                uploadFile(file, websiteSlug, filename, s3ConfigState);
              }
            }
          );
        });
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const invertToWhite = (
    url: string,
    width: number,
    height: number,
    file: File,
    filename: string,
    callback: any
  ) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(image, 0, 0);
        const imageData = ctx.getImageData(0, 0, width, height);
        let data32 = new Uint32Array(imageData.data.buffer);
        let len = data32.length;
        for (let i = 0; i < len; i++) {
          let px = data32[i]; // pixel

          // is white? then knock it out
          if (px === 0xffffffff) data32[i] = px = 0;

          // extract alpha channel from a pixel
          px = px & 0xff000000; // little-endian: ABGR

          // any non-transparency? ie. alpha > 0
          if (px) {
            data32[i] = px | 0xffffff; // set this pixel to white, keep alpha level
          }
        }

        ctx.putImageData(imageData, 0, 0);
      }
      // output url
      if (canvas.toDataURL()) callback(canvas.toDataURL(), filename);
    };
    image.src = url;
  };

  const render = () => {
    if (savedImgSrc && savedImgSrc !== "") {
      return (
        <div className={styles["image-cropper"]}>
          <img
            src={
              savedImgSrc
                ? savedImgSrc
                : `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            }
            alt="Your Logo"
          />
        </div>
      );
    } else if (imgStrUrl && imgStrUrl !== "") {
      return (
        <div className={styles["image-cropper"]}>
          <ReactCrop
            src={imgStrUrl}
            crop={crop}
            onChange={onCropChange}
            onComplete={onComplete}
          />
        </div>
      );
    } else {
      return (
        <div {...getRootProps()} className={styles["dropzone"]}>
          <input {...getInputProps()} />
          <div className={styles["drop-zone-label-container"]}>
            <BackupRounded />
            <p>{t("upload_logo_1")}</p>
            <small>
              {t("upload_logo_2")} <br />
              {t("upload_logo_3")}
            </small>
            {fileRejectionItems.length > 0 ? fileRejectionItems : <></>}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles["logo-upload-editor"]}>
        {render()}
        <div className={styles["buttons-container"]}>
          {imgStrUrl || src || (website.logoCreated && websiteSlug) ? (
            <>
              <Button
                variant={"none-outline"}
                onClick={handleReuploadTrigger}
                label={t("upload_logo_5")}
                isFlat={true}
                // isDisabled={imgStrUrl || src ? false : true}
                icon={ArrowBackRounded}
              />
              <Button
                label={t("save")}
                variant={imgStrUrl ? "primary" : "disabled"}
                icon={SaveRounded}
                onClick={handleUploadCroppedImage}
                isDisabled={imgStrUrl ? false : true}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="preview">
        <div className="preview-container">
          <div className={styles["og1200x627"]} id="og1200x627">
            <img
              src={imgStrUrl}
              className={styles["img-scaled"]}
              alt="Logo Preview"
            />
          </div>
        </div>
      </div>
    </>
  );
};
