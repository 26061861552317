import React from "react";
import { useDispatch } from "react-redux";
import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { Carousel } from "react-bootstrap";

import styles from "./AlternateLogoStyleProperty.module.scss";
import { useTranslation } from "react-i18next";

export class AlternateLogoStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <AlternateLogoStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new AlternateLogoStyleProperty(value);
  }
}

const AlternateLogoStylePropertyComponent: PropertyComponent<AlternateLogoStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  return (
    <div className="form__group">
      <Carousel interval={null} defaultActiveIndex={parseInt(style)}>
        <Carousel.Item onClick={(e: any) => handleStyleChange("0")}>
          <div className={styles["logo-display-container"]}>
            <div className={styles["logo-display"]}>
              <div className="logo-style-0 alt">logo</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item onClick={(e: any) => handleStyleChange("1")}>
          <div className={styles["logo-display-container"]}>
            <div className={styles["logo-display"]}>
              <div className="logo-style-1 alt">LOGO</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item onClick={(e: any) => handleStyleChange("2")}>
          <div className={styles["logo-display-container"]}>
            <div className={styles["logo-display"]}>
              <div className="logo-style-2 alt">logo</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item onClick={(e: any) => handleStyleChange("3")}>
          <div className={styles["logo-display-container"]}>
            <div className={styles["logo-display"]}>
              <div className="logo-style-3 alt">LG</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item onClick={(e: any) => handleStyleChange("4")}>
          <div className={styles["logo-display-container"]}>
            <div className={styles["logo-display"]}>
              <div className="logo-style-4 alt">logo</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item onClick={(e: any) => handleStyleChange("5")}>
          <div className={styles["logo-display-container"]}>
            <div className={styles["logo-display"]}>
              <div className="logo-style-5 alt">logo</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item onClick={(e: any) => handleStyleChange("6")}>
          <div className={styles["logo-display-container"]}>
            <div className={styles["logo-display"]}>
              <div className="logo-style-6 alt">{t("create_logo_1")}</div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item onClick={(e: any) => handleStyleChange("7")}>
          <div className={styles["logo-display-container"]}>
            <div className={styles["logo-display"]}>
              <div className="logo-style-7 alt">{t("create_logo_1")}</div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
