import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const weddingChild1Template: ElementTemplates = {};

weddingChild1Template["cover"] = TemplatesFactory.createDefaultCover(
  "7",
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Collaborate With Us", "", false, false, false),
  new RichTextProperty("<h1>Who We Are</h1>"),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://images.unsplash.com/photo-1605985687770-2e2e82c9b5f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    "no-repeat",
    "0%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(
    '<div><span style="color: rgb(115, 94, 25);">Learn about our team, who we are, <br>and how we can help</span></div>'
  ),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 70)
);

weddingChild1Template["articles"] = TemplatesFactory.createDefaultArticles(
  new LeadStyleProperty("2"),
  new RichTextProperty("<h2>Our Team, Our Style</h2>"),
  new RTEAlignmentProperty("parent"),
  new ImageStyleProperty("1"),
  new ButtonStyleProperty("hobaw-btn-outline-primary"),
  new LinkProperty("Chat with Us", "", false, false, false),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "<div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor. Etiam sit amet fringilla lorem, vitae dapibus velit. Donec vitae enim gravida, sodales massa ut, dictum ante. Morbi risus ligula, venenatis eget porttitor eu, facilisis at sem. Phasellus et gravida ipsum, non tincidunt ante. Nunc rhoncus libero vitae feugiat condimentum. Maecenas at metus id mauris ultrices hendrerit vitae at elit. Duis laoreet, mi et convallis fermentum, purus sem volutpat nulla, id vulputate risus dui a augue.<div /><div></div>div>Donec maximus commodo maximus. In orci nisi, accumsan non tempus feugiat, imperdiet at tortor. Cras tempus suscipit quam, vitae mattis urna. Praesent non tristique turpis. Mauris in odio vulputate, pulvinar dui eu, pretium augue. Quisque viverra tempor commodo. Maecenas faucibus, leo sit amet pharetra consectetur, velit urna elementum ante, sed tincidunt tellus sem id metus. Etiam vitae diam turpis. Suspendisse eu diam in nisl interdum luctus. Sed quis convallis augue.</div>"
  ),
  new RTEAlignmentProperty("parent"),
  new RichTextProperty(
    `<div><span style="color: rgb(115, 94, 25);">Experts, Passionate, and Always Ready</span></div>`
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "1",
  "https://images.unsplash.com/photo-1503315082045-a2bfb5e7f56e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=776&q=80",
  "2"
);
