import {
  AddCircleRounded,
  DeleteForeverRounded,
  EditRounded,
  OpenInNewRounded,
  OpenWithRounded,
} from "@material-ui/icons";
import * as addToolbarActions from "../../../components/impl/AddElementToolbar/addToolbarActions";
import * as addToolbarGuidesActions from "../../../components/impl/AddElementToolbar/addToolbarGuidesActions";
import {
  getAddToolbar,
  getDraggableElementId,
  getHoveredElementId,
  getHoveredElementIndex,
  getPageSlug,
  getSelectedElementId,
} from "editor/selectors";
import React, { HTMLAttributes, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelElementEdition,
  setDraggableElement,
  setHoveredElement,
  setResizeableElement,
  setSelectedElement,
} from "../Editor/editorActions";

import styles from "./ElementPropertyQuickGuide.module.scss";
import { logActivity } from "auth/authActions";
import { editorPage } from "../Editor/Editor";
import { getCompositeLayout, getWebsite } from "editor/states/selectors";
import { getElementConfiguration } from "editor/elements/registry";
import { elementRemoved, templateInserted } from "editor/states/layout";
import { ElementConfiguration } from "editor/elements/types";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";
import { Dropdown } from "react-bootstrap";
import { AddElementToolbarDropdown } from "../AddElementToolbar/AddElementToolbarDropdown";
import "./ElementPropertyQuickGuide.scss";
import { useTranslation } from "react-i18next";

type Props = {
  selectedRef: React.MutableRefObject<any>;
  mousePosX: number;
  mousePosY: number;
};

export const ElementPropertyQuickGuide: React.FunctionComponent<
  Props & HTMLAttributes<HTMLDivElement>
> = ({ selectedRef, mousePosX, mousePosY }) => {
  const xlDialogSize = 585.89;
  const bigDialogSize = 464.8;
  const smallDialogSize = 230;
  const dialogHeight = 69;
  const dialogMinus = 24;

  const dispatch = useDispatch();

  const hoveredElementId = useSelector(getHoveredElementId);
  const hoveredElementIndex = useSelector(getHoveredElementIndex);
  const selectedElementId = useSelector(getSelectedElementId);
  const draggableElementId = useSelector(getDraggableElementId);
  const layout = useSelector(getCompositeLayout);
  const pageSlug = useSelector(getPageSlug);
  const website = useSelector(getWebsite);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const username = useSelector(getUsername);
  const [onHoverEditPosLeft, setOnHoverEditPosLeft] = useState(0);
  const [onHoverEditPosBottom, setOnHoverEditPosBottom] = useState(0);
  const [elementConfiguration, setElementConfiguration] = useState<
    ElementConfiguration
  >();
  const [elementKey, setElementKey] = useState<string>();
  const [elementTitle, setElementTitle] = useState<string>();
  const [isButtonSmall, setIsButtonSmall] = useState<boolean>(false);
  const [isElemPresent, setElemPresent] = useState(true);
  const [currentHoveredElementId, setcurrentHoveredElementId] = useState<
    string
  >();
  const [isResizeable, setIsResizeable] = useState(false);
  const stateAddToolbar = useSelector(getAddToolbar);
  const [elementParentId, setElementParentId] = useState<string | undefined>();
  const [elementSiblings, setElementSiblings] = useState<string[]>([]);
  const [isDialogTop, setIsDialogPosTop] = useState(false);
  const [elemPosIndex, setElemPosIndex] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (
      currentHoveredElementId !== hoveredElementId &&
      currentHoveredElementId
    ) {
      const elem = document.getElementById(currentHoveredElementId);
      if (elem) {
        elem.style.outline = "unset";
        elem.style.outlineOffset = "unset";
        elem.style.transition = "outline-color 0.4s ease-out";
        elem.style.boxShadow = "unset";
        elem.style.transition = "box-shadow 0.4s ease-out";
      }
    }

    if (hoveredElementId) {
      setcurrentHoveredElementId(hoveredElementId);
      const elem = document.getElementById(hoveredElementId);

      if (elem) {
        elem.style.outline = "2px solid #fff";
        elem.style.outlineOffset = "-4px";
        elem.style.transition = "outline-color 0.4s ease-out";
        elem.style.boxShadow = "inset 0px 0px 0px 6px rgba(49,133,252,1)";
        elem.style.transition = "box-shadow 0.4s ease-out";
      }

      const elementLayout = layout[hoveredElementId];

      if (!elementLayout) {
        return;
      }

      const elemConfiguration = getElementConfiguration(
        elementLayout.elementKey
      );
      setIsResizeable(elemConfiguration.isResizeableSmall);
      setElementParentId(elementLayout.elementParentId);
      if (
        elementLayout.elementParentId &&
        layout[elementLayout.elementParentId]
      ) {
        const elementParentConfiguration = getElementConfiguration(
          layout[elementLayout.elementParentId].elementKey
        );

        setElementSiblings(elementParentConfiguration.allowedChildren);
      }

      if (elem) {
        const clientRect = elem.getBoundingClientRect();

        let dialogSize = 0;
        if (clientRect.width < bigDialogSize) {
          dialogSize = smallDialogSize;
          setIsButtonSmall(true);
        } else {
          setIsButtonSmall(false);
          if (elementLayout.elementKey === "cards-container") {
            dialogSize = xlDialogSize;
          } else {
            dialogSize = bigDialogSize;
          }
        }

        const bottomDiff = clientRect.height + clientRect.top - mousePosY;
        const topDiff = clientRect.top - mousePosY;
        const numbers = [bottomDiff, topDiff];
        let closest = 0;

        for (let i = 0; i < numbers.length; i++) {
          if (closest === 0) {
            closest = numbers[i];
          } else if (numbers[i] > 0 && numbers[i] <= Math.abs(closest)) {
            closest = numbers[i];
          } else if (numbers[i] < 0 && -numbers[i] < Math.abs(closest)) {
            closest = numbers[i];
          }
        }

        let isTop = true;
        if (closest === bottomDiff || clientRect.top - dialogHeight < 0) {
          isTop = false;
        }

        let isOut = false;
        if (
          elementLayout.elementKey === "logo-container" ||
          elementLayout.elementKey === "button" ||
          elementLayout.elementKey === "social-nav" ||
          elementLayout.elementKey === "nav" ||
          dialogHeight > clientRect.height
        ) {
          isOut = true;
        }

        if (isTop) {
          setIsDialogPosTop(true);
          if (clientRect.top + window.pageYOffset - 37 < 0) {
            if (isOut) {
              setOnHoverEditPosBottom(
                clientRect.top +
                  window.pageYOffset +
                  clientRect.height -
                  37 +
                  dialogMinus
              );
            } else {
              // It's over the screen
              setOnHoverEditPosBottom(
                clientRect.top + window.pageYOffset + clientRect.height - 37
              );
            }
          } else if (
            clientRect.top + window.pageYOffset + clientRect.height - 37 >
              window.innerHeight &&
            clientRect.y > 0
          ) {
            setOnHoverEditPosBottom(clientRect.top + window.pageYOffset - 37);
          } else {
            if (isOut) {
              setOnHoverEditPosBottom(
                clientRect.top + window.pageYOffset - dialogMinus
              );
            } else {
              setOnHoverEditPosBottom(clientRect.top + window.pageYOffset - 37);
            }
          }
        } else {
          setIsDialogPosTop(false);
          if (
            clientRect.top + window.pageYOffset + clientRect.height + 7 >
              window.innerHeight &&
            clientRect.y > 0
          ) {
            // setOnHoverEditPosBottom(clientRect.top + window.pageYOffset + 7);
            setOnHoverEditPosBottom(
              clientRect.top + window.pageYOffset + clientRect.height + 7
            );
          } else {
            if (isOut) {
              setOnHoverEditPosBottom(
                clientRect.top +
                  window.pageYOffset +
                  clientRect.height +
                  7 +
                  dialogMinus
              );
            } else {
              setOnHoverEditPosBottom(
                clientRect.top + window.pageYOffset + clientRect.height + 7
              );
            }
          }
        }

        const widthElement = clientRect.width;
        const deductWidth = (widthElement - dialogSize) / 2;
        setOnHoverEditPosLeft(
          clientRect.left + window.pageXOffset + deductWidth - 12
        );
      } else {
        setElemPresent(false);
      }
      setElementTitle(getElementConfiguration(elementLayout.elementKey).label);
      setElementKey(elementLayout.elementKey);
      setElementConfiguration(
        getElementConfiguration(elementLayout.elementKey)
      );
    }
  }, [hoveredElementId, layout, currentHoveredElementId, mousePosY]);

  useEffect(() => {
    if (hoveredElementIndex !== null) {
      setElemPosIndex(hoveredElementIndex);
    }
  }, [hoveredElementIndex]);

  if (
    selectedElementId ||
    draggableElementId ||
    !hoveredElementId ||
    !isElemPresent
  ) {
    return <></>;
  }

  const handleDragClick = (event: React.MouseEvent) => {
    dispatch(setHoveredElement(null, null));
    dispatch(setDraggableElement(hoveredElementId));
    dispatch(addToolbarActions.disable());
    dispatch(addToolbarGuidesActions.disable());
    event.stopPropagation();
  };

  const handleResizeClick = (event: React.MouseEvent) => {
    dispatch(setHoveredElement(null, null));
    dispatch(setResizeableElement(hoveredElementId));
    dispatch(addToolbarActions.disable());
    dispatch(addToolbarGuidesActions.disable());
    event.stopPropagation();
  };

  const handleClick = (event: React.MouseEvent) => {
    dispatch(setSelectedElement(hoveredElementId, hoveredElementIndex));
    dispatch(logActivity("QUICK MENU / OPEN DIALOG", editorPage, username));
    dispatch(addToolbarActions.disable());
    dispatch(addToolbarGuidesActions.disable());
    event.stopPropagation();
  };

  const handleDeleteClick = () => {
    if (!hoveredElementId) {
      return;
    }

    if (elementConfiguration)
      dispatch(
        logActivity(
          "QUICK DELETE ELEMENT",
          editorPage,
          elementConfiguration.label
        )
      );
    dispatch(cancelElementEdition(hoveredElementId, selectedRef));
    dispatch(elementRemoved(hoveredElementId, pageSlug!));
    dispatch(setHoveredElement(null, null));
  };

  const handleAddClick = () => {
    const element = layout[hoveredElementId];

    if (elementKey === "cards-container") {
      dispatch(logActivity("QUICK ADD ELEMENT", editorPage, username, "card"));
      dispatch(
        templateInserted(
          "card",
          hoveredElementId,
          element.elementChildrenId ? element.elementChildrenId.length : 0,
          website.logoCreated ? website.logoCreated : false,
          websiteSlug,
          pageSlug
        )
      );
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        left: onHoverEditPosLeft,
        top: onHoverEditPosBottom,
        width: "auto",
      }}
      className={styles["property-quick-guide"]}
      id={"prop-quick-guide"}
    >
      <div
        style={{
          paddingTop: "12px",
          paddingLeft: "12px",
          paddingRight: "12px",
          marginBottom: "-32px",
        }}
      >
        <div className={styles["element-title-container"]}>
          {elementTitle ? t(elementTitle) : "Element"}
        </div>
      </div>
      <div
        style={{
          paddingTop: "32px",
          paddingLeft: "12px",
          paddingRight: "12px",
          paddingBottom: "12px",
          backgroundColor: "transparent",
        }}
      >
        <div className={`${styles["actions-container"]} quick-guide-actions`}>
          <div
            className={`${styles["button"]} ${styles["button-normal"]} ${
              isButtonSmall ? styles["small"] : ""
            }`}
            onClick={handleClick}
          >
            <EditRounded className={isButtonSmall ? styles["small"] : ""} />{" "}
            {isButtonSmall ? "" : "Edit"}
          </div>
          <Dropdown>
            <Dropdown.Toggle id="toggle">
              <AddCircleRounded className={isButtonSmall ? "small" : ""} />{" "}
              {isButtonSmall ? "" : "Add"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={AddElementToolbarDropdown}
              elementParentId={elementParentId ? elementParentId : "root"}
              index={
                elementParentId === "root" && elemPosIndex === 0
                  ? 1
                  : isDialogTop
                  ? elemPosIndex
                  : elemPosIndex + 1
              }
              elementKeys={elementSiblings}
              close={() => {}}
              height={stateAddToolbar.rect.height}
              width={stateAddToolbar.rect.width}
              drop={"bottom"}
              positionY={onHoverEditPosBottom}
              websiteSlug={websiteSlug}
              pageSlug={pageSlug}
              isFromMainAd={true}
              website={website}
              isOpaque={false}
            ></Dropdown.Menu>
          </Dropdown>
          <div
            className={`${styles["button"]} ${styles["button-normal"]} ${styles["small"]}`}
            onClick={handleDragClick}
          >
            <OpenWithRounded className={isButtonSmall ? styles["small"] : ""} />{" "}
            {isButtonSmall ? "" : "Reorder"}
          </div>
          <div
            className={`${styles["button"]} ${styles["button-normal"]} ${
              styles["small"]
            } ${isResizeable ? "" : styles["disabled"]}`}
            onClick={handleResizeClick}
          >
            <OpenInNewRounded
              className={isButtonSmall ? styles["small"] : ""}
            />{" "}
            {isButtonSmall ? "" : "Resize"}
          </div>
          <div
            className={`${styles["button"]} ${styles["button-delete"]} ${styles["small"]}`}
            onClick={handleDeleteClick}
          >
            <DeleteForeverRounded
              className={isButtonSmall ? styles["small"] : ""}
            />{" "}
            {isButtonSmall ? "" : "Delete"}
          </div>
          {elementKey === "cards-container" ? (
            <div
              className={`${styles["button"]} ${styles["button-normal"]}`}
              onClick={handleAddClick}
            >
              <AddCircleRounded
                className={isButtonSmall ? styles["small"] : ""}
              />{" "}
              {t("elem_prop_quick_dialog1")}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
