import { LocalOfferRounded } from "@material-ui/icons";
import { Formik, Form } from "formik";
import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { LogoTextProperty } from "editor/properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "editor/properties/LogoStyleProperty/LogoStyleProperty";
import { LogoDecorationProperty } from "editor/properties/LogoDecorationProperty";
import { LogoImageProperty } from "editor/properties/LogoImageProperty/LogoImageProperty";
import {
  LogoContainerProperties,
  logoContainerElementKey,
} from "common/elements/components/LogoContainer";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";

import styles from "./LogoContainer.module.scss";
import { DimensionProperty } from "editor/properties";
import { getUserWebsiteSlug } from "auth/selectors";
import { useSelector } from "react-redux";
import { getWebsite } from "editor/states/selectors";
import { useTranslation } from "react-i18next";

export class LogoContainerElement {
  static readonly elementKey = logoContainerElementKey;
  static readonly label = "element_logo_label";
  static readonly icon = LocalOfferRounded;
  static readonly allowedToolbarPositions = [];
  static readonly elementDescription = "element_logo_description";
  static readonly dialogPositionStatic = false;
  static readonly isDeletable = true;
  static readonly dialogSize = "large";

  static readonly properties: PropertiesOf<LogoContainerProperties> = {
    logoText: LogoTextProperty,
    logoStyle: LogoStyleProperty,
    logoDecoration: LogoDecorationProperty,
    logoImage: LogoImageProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <LogoContainerPropertiesComponent element={element} />;
  }

  static create(properties: {
    label: string;
    logoText: LogoTextProperty;
    logoStyle: LogoStyleProperty;
    logoDecoration: string;
    logoImage: LogoImageProperty;
    dimension?: DimensionProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const LogoContainerPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const { t } = useTranslation();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);

  const renderer = new PropertyRenderer<typeof LogoContainerElement.properties>(
    LogoContainerElement.properties,
    element
  );

  const defaultActiveKey =
    element.logoImage.src || (website.logoCreated && websiteSlug)
      ? "upload"
      : "make";

  return (
    <div className={styles["logo-properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey={defaultActiveKey}>
        <Tab eventKey="upload" title={t("upload_logo")}>
          <Formik initialValues={{}} onSubmit={async (values) => {}}>
            <Form className="form">{renderer.render("logoImage")}</Form>
          </Formik>
        </Tab>
        <Tab eventKey="make" title={t("create_logo")}>
          <Formik initialValues={{}} onSubmit={async (values) => {}}>
            <Form className="form">
              {renderer.render("logoText")}
              {renderer.render("logoStyle")}
            </Form>
          </Formik>
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(LogoContainerElement);
