import React from "react";

import { IProperty } from "./types";

export default class WidthProperty implements IProperty {
  constructor(readonly width: number) {}

  // This property is changed through the resizer, so for now there is no need
  // to provide a way to render it on the properties dialog.
  render(elementId: string, propertyKey: string): JSX.Element {
    return <></>;
  }
}
