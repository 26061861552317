import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type AlternateLogoContainerProperties = {
  logoText: { label: string };
  logoStyle: { style: string; url: string; lastUpdateDate: string };
  logoDecoration: string;
  logoImage: { src: string; lastUpdateDate: string };
};

export function AlternateLogoContainer({
  properties: { logoText, logoStyle, logoDecoration, logoImage },
  className,
  innerRef,
  websiteSlug,
  ...props
}: ElementComponentProps<AlternateLogoContainerProperties>): JSX.Element {
  return (
    <div className={"alt-logo-container"}>
      <a
        {...props}
        ref={innerRef}
        className={className + " link-block alternate-logo-container"}
      >
        {logoImage && logoImage.src !== undefined ? (
          <img
            className="logo-container__logo"
            alt=""
            src={`${logoImage.src}?tm=${logoImage.lastUpdateDate}`}
          />
        ) : (
          <img
            className="logo-container__logo"
            alt=""
            src={`${logoStyle.url}?tm=${logoStyle.lastUpdateDate}`}
          />
        )}
      </a>
    </div>
  );
}

export const alternatelogoContainerElementKey = "alternate-logo-container";

elementComponentRegistry.register(
  alternatelogoContainerElementKey,
  AlternateLogoContainer
);
