import React, { ChangeEvent, useEffect, useState } from "react";
import {
  BuildRounded,
  SaveRounded,
  CheckRounded,
  PriorityHighRounded,
} from "@material-ui/icons";
import { Dialog } from "editor/components/components/Dialog/Dialog";
import * as yup from "yup";
import produce from "immer";
import { Formik, Form } from "formik";
import { FormControl, InputGroup } from "react-bootstrap";
import TextFieldGroup from "editor/components/components/TextFieldGroup/TextFieldGroup";
import { ItemProps } from "editor/components/base/Dropdown/Dropdown";
import { Website } from "editor/models/types";
import { useDispatch, useSelector } from "react-redux";
import { TCategory } from "api";
import DropdownGroup from "editor/components/components/DropdownGroup/DropdownGroup";
import * as websiteActions from "editor/states/website";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core";
import { getS3config, getWebsiteStatus } from "editor/states/selectors";
import { websiteUpdateModified } from "editor/states/website";
// import ReservedSubdomains from "reserved-subdomains";
import { fetchCurrentUser, logActivity } from "auth/authActions";
import { settingsPage } from "admin/Container";
import { updateDirWithNewDomain } from "admin/S3Client";
import { getUsername } from "auth/selectors";

type Props = {
  showDialog: boolean;
  handleShowWebsiteDetailsDialog: () => void;
  website: Website;
};

const schema = yup.object({
  title: yup.string().required("Title is required"),
});

export const WebsiteSettingsDialog: React.FunctionComponent<Props> = ({
  showDialog,
  handleShowWebsiteDetailsDialog,
  website,
}) => {
  const dispatch = useDispatch();
  const [websiteTitle, setWebsiteTitle] = useState(website.title);
  const [websiteTitleError, setWebsiteTitleError] = useState<String>();
  const [websiteCategory, setWebsiteCategory] = useState(website.category);
  const [websiteUrl, setWebsiteUrl] = useState(website.slug);
  const [websiteUrlError, setWebsiteUrlError] = useState<String>();
  const websiteUpdateStatus = useSelector(getWebsiteStatus);
  const s3ConfigState = useSelector(getS3config);
  const username = useSelector(getUsername);

  useEffect(() => {
    if (website) {
      setWebsiteTitle(website.title);
      setWebsiteCategory(website.category);
      setWebsiteUrl(website.slug);
    }
  }, [website]);

  let buttonLabel: string | undefined;
  let buttonIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | undefined;
  let buttonVariant = "primary";
  let isDisabled = false;

  if (websiteUpdateStatus === "success") {
    buttonLabel = "Saved Details";
    buttonIcon = CheckRounded;
    buttonVariant = "success";
    isDisabled = true;
  } else if (websiteUpdateStatus === "fail") {
    buttonLabel = "Save Failed";
    buttonVariant = "danger";
    buttonIcon = PriorityHighRounded;
    isDisabled = false;
  } else if (websiteUpdateStatus === "changed") {
    buttonLabel = "Update Details";
    isDisabled = false;
    buttonVariant = "primary";
    buttonIcon = SaveRounded;
  } else {
    buttonLabel = "Update Details";
    isDisabled = true;
    buttonVariant = "disabled";
    buttonIcon = SaveRounded;
  }

  const handleUpdate = () => {
    let isNotValid = false;
    if (!websiteTitle || !websiteTitle.replace(/\s/g, "").length) {
      setWebsiteTitleError("Website Title can not be empty");
      isNotValid = true;
    }
    if (!websiteUrl || !websiteUrl.replace(/\s/g, "").length) {
      setWebsiteUrlError("Website Url can not be empty");
      isNotValid = true;
    }
    // if (ReservedSubdomains.isNotValid(websiteUrl)) {
    //   setWebsiteUrlError("Your chosen subdomain is either reserved or invalid");
    //   isNotValid = true;
    // }
    if (!isNotValid) {
      dispatch(
        websiteActions.websiteDetailsUpdated(
          website.slug,
          produce(website, (draft) => {
            draft.category = websiteCategory;
            draft.title = websiteTitle;
            draft.slug = websiteUrl;
            draft.widthGuide = website.widthGuide;
          })
        )
      );
      dispatch(fetchCurrentUser());
      dispatch(logActivity("UPDATE WEBSITE DETAILS", settingsPage, username));
      updateDirWithNewDomain(website.slug, websiteUrl, s3ConfigState);
    }
  };

  const handleTitleUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setWebsiteTitle(event.target.value);
    setWebsiteTitleError("");
    setWebsiteState();
  };

  const handleUrlUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setWebsiteUrl(event.target.value);
    setWebsiteUrlError("");
    setWebsiteState();
  };

  const handleCategoryUpdate = (category: string): void => {
    if (TCategory.BUSINESS === toUppercase(category)) {
      setWebsiteCategory(TCategory.BUSINESS);
    } else if (TCategory.PERSONAL === toUppercase(category)) {
      setWebsiteCategory(TCategory.PERSONAL);
    } else {
      setWebsiteCategory(TCategory.NONE);
    }
    setWebsiteState();
  };

  const setWebsiteState = (): void => {
    dispatch(websiteUpdateModified(website));
  };

  const capitalize = (s: string): string => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  const toUppercase = (s: string): string => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toUpperCase();
  };

  const websiteCategories = [
    capitalize(TCategory.BUSINESS),
    capitalize(TCategory.PERSONAL),
  ];

  const dropdownPageItems = websiteCategories.map(
    (category): ItemProps => ({
      key: category,
      eventKey: category,
      itemLabel: category,
      isActive: website.category === category ? true : false,
      onClickOfItem: () => handleCategoryUpdate(category),
    })
  );

  const tldjs = require("tldjs");
  const { getDomain } = tldjs;
  const domain =
    getDomain(document.domain) +
    (window.location.port ? ":" + window.location.port : "");

  const SettingsDialogBody = (
    <Formik
      validationSchema={schema}
      onSubmit={handleUpdate}
      initialValues={{
        title: websiteTitle,
        url: website.slug,
      }}
    >
      <Form>
        <TextFieldGroup
          value={websiteTitle}
          name="title"
          label="Website Title"
          onChange={handleTitleUpdate}
        />
        <p className="text-danger">{websiteTitleError}</p>
        <DropdownGroup
          label={"Type"}
          items={dropdownPageItems}
          name={"type"}
          placeholder={capitalize(websiteCategory)}
        />
        <label htmlFor="basic-url">URL</label>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="URL"
            aria-label="URL"
            aria-describedby="basic-addon2"
            value={websiteUrl}
            onChange={handleUrlUpdate}
          />
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon2">{domain}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <p className="text-danger">{websiteUrlError}</p>
      </Form>
    </Formik>
  );

  return (
    <Dialog
      dialogBody={SettingsDialogBody}
      icon={BuildRounded}
      headerLabel={"Edit Website Details"}
      handleShowDialog={handleShowWebsiteDetailsDialog}
      isShowDialog={showDialog}
      isShowBg={true}
      parentActionLabel={buttonLabel}
      parentActionButtonVariant={buttonVariant}
      handleParentAction={handleUpdate}
      parentActionIcon={buttonIcon}
      isDisabled={isDisabled}
      isDialogPositionStatic={true}
      cssPosition={{
        left: (window.innerWidth - 400) / 2,
        top: window.innerHeight / 8,
      }}
      isGrabbable={false}
      size={"medium"}
    />
  );
};
