import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Twitter, Facebook } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { authorChild1Template } from "./authorChild1";
import { authorChild2Template } from "./authorChild2";
import { authorChild3Template } from "./authorChild3";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { CoverElement } from "editor/elements/sections/Cover/Cover";
import { HeadingElement } from "editor/elements/base/Heading";
import { LeadElement } from "editor/elements/base/Lead";
import { ButtonElement } from "editor/elements/components/Button/Button";
import { CardsElement } from "editor/elements/sections/Cards/Cards";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { CardsContainerElement } from "editor/elements/components/CardsContainer/CardsContainer";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { ArticleElement } from "editor/elements/components/Article";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { ImageElement } from "editor/elements/base/Image/Image";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const authorTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "author";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 5,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 6,
    templateTitle: templateKey,
  },
  {
    elementKey: "messenger",
    position: 7,
    templateTitle: templateKey,
  },
  {
    elementKey: "whatsapp",
    position: 8,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultNavbar(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/author/main.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        "0",
        new BackgroundColorProperty("#000000"),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "2",
        "2",
        "#FFFFFF"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        [
          new LinkProperty("Google", "https://google.com", false, true, false),
          new LinkProperty("Yahoo", "https://yahoo.com", false, true, false),
        ],
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/author/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright YourCompany " + new Date().getFullYear()
      );

    default:
      return authorTemplates[layoutKey];
  }
};

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page of James Jones",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: authorChild1Template,
  },
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "authorChild1Template",
    details: {
      title: "Books",
      slug: randomSlug(6),
      description: "Books by James Jones",
    },
    template: {
      template: authorChild1Template,
    },
  },
  {
    templateName: "authorChild2Template",
    details: {
      title: "About",
      slug: randomSlug(6),
      description: "About James Jones",
    },
    template: {
      template: authorChild2Template,
    },
  },
  {
    templateName: "authorChild3Template",
    details: {
      title: "Contact",
      slug: randomSlug(6),
      description: "Contact James Jones",
    },
    template: {
      template: authorChild3Template,
    },
  },
];

authorTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "Fashion",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

authorTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "Author",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);

authorTemplates["navbar"] = TemplatesFactory.createDefaultNavbar(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/author/main.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "0",
  new BackgroundColorProperty("#000000"),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "2",
  "2",
  "#FFFFFF"
);

authorTemplates["cover"] = LayoutEditor.createEmpty()
  .add(
    CoverElement.create({
      coverStyle: "5",
      backgroundImageProperty: new BackgroundImageProperty(
        "https://images.unsplash.com/photo-1508086124809-e4a7c34a36f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1688&q=80",
        "no-repeat",
        "50%",
        "50%",
        "100%",
        0,
        0,
        1
      ),
      dimension: new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 40),
      backgroundColorProperty: new BackgroundColorProperty("#f4f4f4"),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        HeadingElement.create({
          richTextProperty: new RichTextProperty(
            '<h2><span style="color: rgb(255, 255, 255);">Fantasy Books by J.J.</span></h2>'
          ),
          rteAlignment: new RTEAlignmentProperty("parent"),
        })
      )
      .add(
        ButtonElement.create({
          buttonStyle: new ButtonStyleProperty("hobaw-btn-primary"),
          buttonLink: new LinkProperty(
            "Book an Appointment",
            "",
            false,
            false,
            false
          ),
          treatmentProperty: new TreatmentProperty(false),
          isProduction: false,
          isButton: false,
          isTestable: false,
        })
      )
      .build()
  )
  .build();

authorTemplates["cards"] = LayoutEditor.createEmpty()
  .add(
    CardsElement.create({
      cardsStyle: "0",
      backgroundColorProperty: "#000000",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "parent",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(255, 255, 255);">The Opal Trilogy</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("center"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><div><span style="color: rgb(255, 255, 255);">Lorem ipsum dolor sit amet consectetur adipisicing elit.&nbsp;</span></div><div><span style="color: rgb(255, 255, 255);">Quibusdam, voluptatibus commodi alias deserunt.</span></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("center"),
            })
          )
          .build()
      )
      .add(
        CardsContainerElement.create({
          cardsStyle: "0",
          cardsOrientation: "1",
          cardsAlignment: "2",
        })
      )
      .addChildrenArray([
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(
            `<h3><span style="color: rgb(255, 255, 255);">A Diamond Crest</span></h3>`
          ),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty(
            '<div><span style="color: rgb(255, 255, 255);">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae veniam nihil perspiciatis quisquam.</span></div>'
          ),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1594396552580-4ef979578398?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(
            `<h3><span style="color: rgb(255, 255, 255);">An Opal Key</span></h3>`
          ),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty(
            '<div><span style="color: rgb(255, 255, 255);">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae veniam nihil perspiciatis quisquam.</span></div>'
          ),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1433979933652-5e766d98ebb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(
            `<h3><span style="color: rgb(255, 255, 255);">A Silver Knife</span></h3>`
          ),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty(
            '<div><span style="color: rgb(255, 255, 255);">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae veniam nihil perspiciatis quisquam.</span></div>'
          ),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1594399432819-af8cb403864d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
          "2"
        ),
      ])
      .build()
  )
  .build();

authorTemplates["articles"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#ffffff",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty("<h2>Catacombs</h2>"),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                "<div>Spring 2024</div><br /><div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore esse culpa sunt debitis laborum sit voluptate animi ex iusto accusantium.</div><br /><div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis odit molestiae velit numquam ad sapiente hic fuga aliquam recusandae facere, aperiam explicabo accusamus minima ipsa necessitatibus. Accusantium, velit placeat sunt, minus excepturi expedita magnam numquam laborum asperiores quod nisi, fugit iusto reiciendis illum repellat beatae eos enim aliquid quae praesentium.</div>"
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1519074069444-1ba4fff66d16?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
                  imageText: "",
                })
              )
              // .add(
              //   ParagraphElement.create({
              //     richTextProperty: richTextParagraphProperty,
              //     rteAlignment: rteParagraphAlignment,
              //   })
              // )
              // .add(
              //   ButtonElement.create({
              //     buttonStyle: buttonStyle,
              //     buttonLink: linkProperty,
              //     treatmentProperty: new TreatmentProperty(false),
              //     isProduction: false,
              //     isButton: false,
              //   })
              // )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

authorTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("2"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Contact J.J.</h2>"),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(""),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

authorTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/author/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright James Jones " + new Date().getFullYear()
);
