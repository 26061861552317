import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { AssignmentTurnedInRounded } from "@material-ui/icons";
import styles from "./Admin.module.scss";
import { ChallengesDisplay, getAllChallenges } from "editor/states/layout";
import TestPropertiesAccordion from "editor/components/impl/TestsPropertiesDialog/TestPropertiesAccordion";
import {
  getUserFirstName,
  getUserLastName,
  getUsername,
  getUserWebsiteSlug,
} from "auth/selectors";
import { logActivity } from "auth/authActions";
import { testsPage } from "./Container";
import {
  getAllChallenges as getAllChallengesState,
  getSubscription,
} from "editor/states/selectors";
import { useTranslation } from "react-i18next";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { setTawkAttributes, tawkWidgetToggle } from "./Tawk";

type PagesAndElementsAndChallenges = {
  [pageSlug: string]: {
    [elementId: string]: {
      challengeDisplay: ChallengesDisplay;
    };
  };
};

export const Tests: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const challenges = useSelector(getAllChallengesState);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const subscription = useSelector(getSubscription);

  const [isPremiumUser, setIsPremiumUser] = useState(false);

  const { t } = useTranslation();
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const username = useSelector(getUsername);
  const tldjs = require("tldjs");
  const { getDomain } = tldjs;

  useEffect(() => {
    document.title = "GoodWeb | Tests";
  }, []);

  useEffect(() => {
    tawkWidgetToggle(true);
  }, []);

  useEffect(() => {
    if (firstName && lastName && websiteSlug && username) {
      const publishedDomain =
        websiteSlug +
        "." +
        getDomain(document.domain) +
        (window.location.port ? ":" + window.location.port : "");
      setTawkAttributes(firstName + " " + lastName, username, publishedDomain);
    }
  }, [firstName, lastName, username, websiteSlug, getDomain]);

  useEffect(() => {
    if (!challenges) {
      dispatch(getAllChallenges());
    }
  }, [dispatch, challenges]);

  useEffect(() => {
    if (subscription && subscription.isActive) {
      setIsPremiumUser(subscription.isActive);
    }
  }, [subscription]);

  useEffect(() => {
    dispatch(logActivity("VIEW", testsPage, username));
  }, [dispatch, username]);

  const renderPagesAndChallenges = (): JSX.Element[] => {
    const pageAndChallenges: JSX.Element[] = [];
    const pagesAndElementsAndChallenges: PagesAndElementsAndChallenges = {};

    if (challenges && challenges.length > 0) {
      challenges.forEach((challenge) => {
        let existingChallengesByPage =
          pagesAndElementsAndChallenges[challenge.controlSlug!];
        if (existingChallengesByPage) {
          let existingChallengesByElement =
            existingChallengesByPage[challenge.elementId!];
          if (existingChallengesByElement) {
            if (challenge.testActualEndDate) {
              existingChallengesByElement.challengeDisplay.finishedChallenges?.push(
                challenge
              );
            } else {
              existingChallengesByElement.challengeDisplay.currentChallenge = challenge;
            }
          } else {
            const challengeToAdd: ChallengesDisplay = {
              elementId: challenge.elementId!,
              currentChallenge: challenge.testActualEndDate
                ? undefined
                : challenge,
              finishedChallenges: challenge.testActualEndDate
                ? [challenge]
                : [],
            };
            existingChallengesByPage[challenge.elementId!] = {
              challengeDisplay: challengeToAdd,
            };
          }
        } else {
          const challengeToAdd: ChallengesDisplay = {
            elementId: challenge.elementId!,
            currentChallenge: challenge.testActualEndDate
              ? undefined
              : challenge,
            finishedChallenges: challenge.testActualEndDate ? [challenge] : [],
          };
          pagesAndElementsAndChallenges[challenge.controlSlug!] = {
            [challengeToAdd.elementId]: {
              challengeDisplay: challengeToAdd,
            },
          };
        }
      });

      Object.keys(pagesAndElementsAndChallenges).forEach((key) => {
        const elementsAndChallenges = pagesAndElementsAndChallenges[key];
        Object.values(elementsAndChallenges).forEach((challengeDisplay) => {
          if (challengeDisplay && challengeDisplay.challengeDisplay) {
            const currentChallenge =
              challengeDisplay.challengeDisplay.currentChallenge;
            const finishedChallenges =
              challengeDisplay.challengeDisplay.finishedChallenges;
            let controlSlug;
            let elementId;
            if (currentChallenge && currentChallenge.controlSlug) {
              controlSlug = currentChallenge.controlSlug;
              elementId = currentChallenge.elementId;
            } else {
              if (finishedChallenges && finishedChallenges.length > 0) {
                controlSlug = finishedChallenges[0].controlSlug;
                elementId = finishedChallenges[0].elementId;
              }
            }
            if (controlSlug && elementId) {
              pageAndChallenges.push(
                <div className={styles["admin__test-challenge-container"]}>
                  <span className={styles["admin__test-page-header"]}>
                    {controlSlug}
                  </span>
                  <span>
                    <TestPropertiesAccordion
                      key={controlSlug}
                      websiteSlug={websiteSlug!}
                      pageSlug={controlSlug}
                      pageContainer={testsPage}
                      challenge={currentChallenge}
                      finishedChallenges={finishedChallenges}
                      disableApply={true}
                      isPremiumUser={isPremiumUser}
                    />
                  </span>
                </div>
              );
            }
          }
        });
      });
    }

    return pageAndChallenges;
  };

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <AssignmentTurnedInRounded />
          <h3>{t("tests")}</h3>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card--big"]}>
          <div className={styles["admin__card-header"]}>
            <h4>{t("pages_tests")}</h4>
          </div>
          <div>
            {challenges && challenges.length > 0 ? (
              <>{renderPagesAndChallenges()}</>
            ) : (
              <>{t("no_pages_tests")}</>
            )}
          </div>
        </div>
      </div>
      <TawkMessengerReact
        propertyId="63b56d68c2f1ac1e202ba11d"
        widgetId="1glub6oqr"
      />
    </>
  );
};
