import { Layout } from "common/elements/types";
import { DumbElement } from "editor/elements/DumbElement/DumbElement";
import { getElementComponent } from "editor/elements/registry";
import { DimensionProperty } from "editor/properties";
import { propertySet } from "editor/states/layout";
import { getCompositeLayout } from "editor/states/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as editorActions from "../Editor/editorActions";

import { ResizableBox, ResizeCallbackData } from "react-resizable";

import styles from "./DragWindow.module.scss";
import "./DragWindow.scss";
import { logActivity } from "auth/authActions";
import { editorPage } from "../Editor/Editor";

type Props = {
  selectedRef: React.MutableRefObject<any>;
  resizeId: string;
  width: number;
  height: number;
};

export const ResizeWindow: React.FunctionComponent<Props> = ({
  selectedRef,
  resizeId,
  width,
  height,
}) => {
  const dispatch = useDispatch();

  const layout = useSelector(getCompositeLayout);
  const [stateWidth, setStateWidth] = useState(width);
  const [stateHeight, setStateHeight] = useState(height);

  useEffect(() => {
    if (resizeId) {
    }
  }, [resizeId, layout]);

  const onResizeEnd = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    setStateWidth(data.size.width);
    setStateHeight(data.size.height);
    dispatch(
      propertySet(
        resizeId,
        "dimension",
        new DimensionProperty(data.size.width, data.size.height)
      )
    );
  };

  const handleCloseResize = () => {
    dispatch(logActivity("EXIT RESIZE MODE", editorPage));
    dispatch(editorActions.cancelResizeableElement());
  };

  const onResize = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    setStateWidth(data.size.width);
    setStateHeight(data.size.height);
  };

  const mapDraggableElementToComponent = (
    elementId: string,
    elementParentId: string | undefined,
    index: number,
    layoutComp: Layout,
    selRef: React.MutableRefObject<any>
  ) => {
    const elem = layoutComp[elementId];
    const elemParent = elementParentId ? layoutComp[elementParentId] : null;
    const component = getElementComponent(elem.elementKey);

    if (resizeId === elementId) {
      return (
        <div
          className={elementId === "root" ? styles["root-context"] : ""}
          style={{
            display: "inherit",
            zIndex: 1,
          }}
        >
          <div className={"close-tab"} onClick={handleCloseResize}>
            Close
          </div>
          <ResizableBox
            className="box"
            width={stateWidth}
            height={stateHeight}
            lockAspectRatio={
              elem.elementKey === "image" ||
              elem.elementKey === "logo-container"
                ? true
                : false
            }
            onResizeStop={onResizeEnd}
            onResize={onResize}
            resizeHandles={["sw", "ne", "nw", "se"]}
            axis={
              elem.elementKey === "navbar" ||
              elem.elementKey === "footer" ||
              elem.elementKey === "card"
                ? "y"
                : "both"
            }
          >
            <DumbElement
              as={component}
              key={elem.elementId}
              elementParentId={elem.elementParentId}
              elementParentKey={elemParent ? elemParent.elementKey : null}
              properties={elem}
              index={index}
              selectedRef={selRef}
              width={stateWidth}
              height={stateHeight}
              {...elem}
            >
              {elem.elementChildrenId ? (
                elem.elementChildrenId.map((elementChildId: any, idx: number) =>
                  mapDraggableElementToComponent(
                    elementChildId,
                    elem.elementId,
                    idx,
                    layoutComp,
                    selRef
                  )
                )
              ) : (
                <></>
              )}
            </DumbElement>
          </ResizableBox>
        </div>
      );
    }

    return (
      <>
        <DumbElement
          as={component}
          key={elem.elementId}
          elementParentId={elem.elementParentId}
          elementParentKey={elemParent ? elemParent.elementKey : null}
          properties={elem}
          index={index}
          selectedRef={selRef}
          {...elem}
        >
          {elem.elementChildrenId ? (
            elem.elementChildrenId.map((elementChildId: any, idx: number) =>
              mapDraggableElementToComponent(
                elementChildId,
                elem.elementId,
                idx,
                layoutComp,
                selRef
              )
            )
          ) : (
            <></>
          )}
        </DumbElement>
      </>
    );
  };

  return mapDraggableElementToComponent(
    "root",
    undefined,
    0,
    layout,
    selectedRef
  );
};
