import { CallToActionOutlined } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { FooterStyleProperty } from "editor/properties/FooterStyleProperty";
import {
  footerElementKey,
  FooterProperties,
} from "common/elements/sections/Footer";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import WidthProperty from "editor/properties/WidthProperty";
import { BackgroundColorProperty } from "editor/properties/BackgroundColorProperty/BackgroundColorProperty";
import { Tabs, Tab } from "react-bootstrap";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import styles from "./Footer.module.scss";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import { BorderProperty, DimensionProperty } from "editor/properties";
import { useTranslation } from "react-i18next";

export class FooterElement {
  static readonly elementKey = footerElementKey;
  static readonly label = "element_footer_label";
  static readonly icon = CallToActionOutlined;
  static readonly allowedToolbarPositions = ["top"];
  static readonly elementDescription = "element_footer_description";
  static readonly elementPreviewUrl =
    "/img/add-footer-element-preview-default.svg";
  static readonly allowedChildren = ["alternate-logo-container", "footer-nav"];
  static readonly isResizeable = false;
  static readonly resizeDirections = [];
  static readonly resizeGuides = [1024, 1280, 1366, 1440, 1600, 0];
  static readonly dialogSize = "large";
  static readonly childDragDirection = "horizontal";
  static readonly isReusable = true;
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<FooterProperties> = {
    footerStyle: FooterStyleProperty,
    backgroundColorProperty: BackgroundColorProperty,
    width: WidthProperty,
    backgroundImageProperty: BackgroundImageProperty,
    dimension: DimensionProperty,
    borderProperty: BorderProperty,
  };

  static renderProperties(element: ElementType) {
    return <FooterPropertiesComponent element={element} />;
  }

  static create(properties: {
    footerStyle: string;
    backgroundColorProperty: string;
    backgroundImageProperty: BackgroundImageProperty;
    dimension?: DimensionProperty;
    borderProperty?: BorderProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const FooterPropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof FooterElement.properties>(
    FooterElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="alignment">
        <Tab eventKey="alignment" title={t("dialog_44")}>
          {renderer.render("footerStyle")}
        </Tab>
        <Tab eventKey="background-color" title={t("dialog_2")}>
          {renderer.render("backgroundColorProperty")}
        </Tab>
        <Tab eventKey="background-image" title={t("dialog_3")}>
          {renderer.render("backgroundImageProperty")}
        </Tab>
        <Tab eventKey="navbar-border" title={t("dialog_4")}>
          {renderer.render("borderProperty")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(FooterElement);
