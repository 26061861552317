import FavoriteOutlined from "@material-ui/icons/FavoriteOutlined";
import React from "react";

import {
  ElementType,
  ElementConfiguration,
  ElementDefinition,
} from "editor/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";

type Entry<T extends ElementType> = {
  definition?: ElementDefinition;
  component: React.FunctionComponent<T>;
  configuration: Required<ElementConfiguration>;
};

// TODO: Find a way to strongly type this.
const registry: { [key: string]: Entry<any> } = {};

const defaultConfiguration: Required<ElementConfiguration> = {
  label: "",
  icon: FavoriteOutlined,
  allowedChildren: [],
  allowedToolbarPositions: [],
  elementPreviewUrl: "./img/default-image.svg",
  elementDescription: "No description available",
  isResizeable: false,
  resizeDirections: [],
  resizeGuides: [],
  dialogPositionStatic: true,
  isDeletable: true,
  dialogSize: "medium",
  isDialogDisplay: true,
  childDragDirection: "vertical",
  cssPosition: null,
  isReusable: false,
  isResizeableSmall: false,
};

export function registerElement<T extends ElementType>(
  key: string,
  component: React.FunctionComponent<T>,
  configuration: ElementConfiguration
) {
  registry[key] = {
    component,
    configuration: { ...defaultConfiguration, ...configuration },
  };
}

export const getElementDefinition = (elementKey: string): ElementDefinition => {
  if (elementKey in registry) {
    return registry[elementKey].definition!;
  }
  return elementDefinitionRegistry.get(elementKey);
};

// TODO: Find a way to strongly type this.
export const getElementComponent = (
  elementKey: string
): React.FunctionComponent<any> => {
  if (elementKey in registry) {
    return registry[elementKey].component;
  }
  return elementComponentRegistry.get(elementKey);
};

export const getElementConfiguration = (
  elementKey: string
): Required<ElementConfiguration> => {
  if (elementKey in registry) {
    return registry[elementKey].configuration;
  }
  return {
    ...defaultConfiguration,
    ...elementDefinitionRegistry.get(elementKey),
  };
};
