import React, { useEffect, useState } from "react";
import { SidebarNav } from "./SidebarNav";
import { Link } from "react-router-dom";

import styles from "../Admin.module.scss";
import { Subscription } from "editor/models/types";

import { isDesktopView } from "../utils-country";

type Props = {
  url: string;
  currentPage: string;
  onLogoutClick: () => void;
  subscription: Subscription | null;
};

export const Sidebar: React.FunctionComponent<Props> = ({
  url,
  currentPage,
  onLogoutClick,
  subscription,
}) => {
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView())
    }
  }, [isDesktopView])
  return (
    isDesktop ?
      <div className={styles["admin__sidebar"]}>
        <Link to={url} className={styles["admin__sidebar-logo-container"]}>
          <img
            alt="GoodWeb"
            src="/img/goodweb-fullmarkup-1-line-white.svg"
            className={styles["admin__sidebar-logo"]}
          />
        </Link>
        <SidebarNav
          url={url}
          onLogoutClick={onLogoutClick}
          currentPage={currentPage}
          subscription={subscription}
          isDesktop={isDesktop}
        />
      </div>
      :
      <SidebarNav
        url={url}
        onLogoutClick={onLogoutClick}
        currentPage={currentPage}
        subscription={subscription}
        isDesktop={isDesktop}
      />
  );
};
