import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { RoundedCornerRounded, SaveOutlined } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { getUsername, getUserWebsiteSlug } from "../../../../auth/selectors";
import { Dialog } from "../../components/Dialog/Dialog";
import { Row, Form } from "react-bootstrap";
import {
  getColorType,
  getDescription,
  getPageId,
  getPageIndexState,
  getPrimaryColor,
  getPrimaryFont,
  getPrimaryRadius,
  getPrimaryShadow,
  getSecondaryColor,
  getSecondaryFont,
  getSecondaryRadius,
  getTernaryColor,
  getTitle,
} from "editor/selectors";
import { Range } from "react-range";
import styles from "./RoundessPropsDialog.module.scss";
import { pageDetailsUpdated } from "editor/states/pageList";
import { logActivity } from "auth/authActions";
import { editorPage } from "../Editor/Editor";
import { getRequestIsInProgress } from "api/selectors";
import { updateWebsiteDetails } from "admin/websiteActions";
import { getWebsite } from "editor/states/selectors";
import { useTranslation } from "react-i18next";

type Props = {
  isShowFontsPropsDialog: boolean;
  toolBarButtonRect?: DOMRect;
  handleShowPropDialog: () => void;
  cssPos?: React.CSSProperties;
};

export default function RoundessPropsDialog({
  isShowFontsPropsDialog,
  toolBarButtonRect,
  handleShowPropDialog,
  cssPos,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const requestInProgress = useSelector(getRequestIsInProgress);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const statePrimaryColor = useSelector(getPrimaryColor);
  const stateSecondaryColor = useSelector(getSecondaryColor);
  const stateTernaryColor = useSelector(getTernaryColor);
  const stateColorType = useSelector(getColorType);
  const statePrimaryFont = useSelector(getPrimaryFont);
  const stateSecondaryFont = useSelector(getSecondaryFont);
  const statePrimaryRadius = useSelector(getPrimaryRadius);
  const stateSecondaryRadius = useSelector(getSecondaryRadius);
  const statePrimaryShadow = useSelector(getPrimaryShadow);
  const pageId = useSelector(getPageId);
  const pageTitle = useSelector(getTitle);
  const pageDescription = useSelector(getDescription);
  const isPageIndex = useSelector(getPageIndexState);
  const username = useSelector(getUsername);

  const [selectedPrimaryRadius, setSelectedPrimaryRadius] = useState("5px");
  const [selectedSecondaryRadius, setSelectedSecondaryRadius] = useState(
    "2.5px"
  );
  const [selectedPrimaryShadow, setSelectedPrimaryShadow] = useState("0.5");

  const [roundnessRangeValue, setRoundnessRangeValue] = useState([5]);
  const [shadowRangeValue, setShadowRangeValue] = useState([0.5]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (statePrimaryRadius && statePrimaryRadius !== "") {
      setSelectedPrimaryRadius(statePrimaryRadius);
      setRoundnessRangeValue([Number(statePrimaryRadius.replace("px", ""))]);
    }
    if (stateSecondaryRadius && stateSecondaryRadius !== "") {
      setSelectedSecondaryRadius(stateSecondaryRadius);
    }
    if (statePrimaryShadow && statePrimaryShadow !== "") {
      setSelectedPrimaryShadow(statePrimaryShadow);
      setShadowRangeValue([Number(statePrimaryShadow)]);
    }
  }, [statePrimaryRadius, stateSecondaryRadius, statePrimaryShadow]);

  if (!isShowFontsPropsDialog || !websiteSlug) {
    return <></>;
  }

  const updateTemplate = () => {
    if (pageId && pageTitle && isPageIndex !== null) {
      dispatch(
        pageDetailsUpdated(
          pageId,
          pageTitle,
          pageDescription ? pageDescription : "",
          isPageIndex,
          statePrimaryFont ? statePrimaryFont : `"Roboto", sans-serif`,
          stateSecondaryFont ? stateSecondaryFont : `"Lora", serif`,
          statePrimaryColor ? statePrimaryColor : "#124E78",
          stateSecondaryColor ? stateSecondaryColor : "#C47500",
          stateTernaryColor ? stateTernaryColor : "#003F7A",
          stateColorType ? stateColorType : "comp",
          selectedPrimaryRadius ? selectedPrimaryRadius : "5px",
          selectedSecondaryRadius ? selectedSecondaryRadius : "2.5px",
          selectedPrimaryShadow ? selectedPrimaryShadow : "0.5"
        )
      );
      if (websiteSlug) {
        dispatch(
          updateWebsiteDetails(
            websiteSlug,
            website.primaryFont ? website.primaryFont : `"Roboto", sans-serif`,
            website.secondaryFont ? website.secondaryFont : `"Lora", serif`,
            website.primaryColor ? website.primaryColor : "#124E78",
            website.secondaryColor ? website.secondaryColor : "#C47500",
            website.ternaryColor ? website.ternaryColor : "#003F7A",
            website.colorType ? website.colorType : "comp",
            selectedPrimaryRadius ? selectedPrimaryRadius : "5px",
            selectedSecondaryRadius ? selectedSecondaryRadius : "2.5px",
            selectedPrimaryShadow ? selectedPrimaryShadow : "0.5",
            website.logoCreated ? website.logoCreated : false
          )
        );
      }
    }
  };

  const handleRoundedRangeChange = (values: number[]) => {
    setRoundnessRangeValue(values);
    setSelectedPrimaryRadius(values[0] + "px");
    setSelectedSecondaryRadius(values[0] / 2 + "px");
  };

  const handleFinalRoundedRangeChange = (values: number[]) => {
    handleRoundedRangeChange(values);
    dispatch(
      logActivity(
        "SET RADIUS",
        editorPage,
        username,
        selectedPrimaryRadius + " / " + selectedSecondaryRadius
      )
    );
    updateTemplate();
  };

  const handleShadowRangeChange = (values: number[]) => {
    const shadowVal = values[0].toString();
    setShadowRangeValue(values);
    setSelectedPrimaryShadow(shadowVal);
  };

  const handleFinalShadowRangeChange = (values: number[]) => {
    handleShadowRangeChange(values);
    dispatch(
      logActivity("SET SHADOW", editorPage, username, selectedPrimaryShadow)
    );
    updateTemplate();
  };

  const RoundnessPropsDialogBody = (
    <div className={`${styles["palette-container"]}`}>
      <Container>
        <Form.Group as={Row}>
          <div className={`${styles["range-container"]}`}>
            <p>{t("editor_setup_wizard17")}</p>
            <Range
              step={1}
              min={0}
              max={50}
              values={roundnessRangeValue}
              onChange={(values) => handleRoundedRangeChange(values)}
              onFinalChange={(values) => handleFinalRoundedRangeChange(values)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  className={`${styles["range-tracker"]}`}
                  style={{
                    ...props.style,
                    height: "4px",
                    width: "100%",
                    backgroundColor: "#cfcfcf",
                    borderRadius: "4px",
                    boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  className={`${styles["range-thumb"]}`}
                  style={{
                    ...props.style,
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#319560",
                  }}
                />
              )}
            />
          </div>
          <div
            className={`${styles["range-container"]}`}
            style={{
              marginTop: "60px",
              marginBottom: "40px",
            }}
          >
            <p>{t("editor_setup_wizard18")}</p>
            <Range
              step={0.1}
              min={0}
              max={1}
              values={shadowRangeValue}
              onChange={(values) => handleShadowRangeChange(values)}
              onFinalChange={(values) => handleFinalShadowRangeChange(values)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  className={`${styles["range-tracker"]}`}
                  style={{
                    ...props.style,
                    height: "4px",
                    width: "100%",
                    backgroundColor: "#cfcfcf",
                    borderRadius: "4px",
                    boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  className={`${styles["range-thumb"]}`}
                  style={{
                    ...props.style,
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#319560",
                  }}
                />
              )}
            />
          </div>
        </Form.Group>

        <div className={styles["dialog-action-notification"]}>
          {requestInProgress ? (
            <>
              <Spinner
                size="sm"
                animation="border"
                variant="secondary"
                className={styles["saving-icon"]}
              />{" "}
              {t("Saving")}
            </>
          ) : (
            <>
              <SaveOutlined />
              {t("Changes_Saved")}
            </>
          )}
        </div>
      </Container>
    </div>
  );

  return (
    <>
      <Dialog
        dialogBody={RoundnessPropsDialogBody}
        icon={RoundedCornerRounded}
        headerLabel={t("editor_area_curves")}
        handleShowDialog={handleShowPropDialog}
        isShowDialog={isShowFontsPropsDialog}
        isGrabbable={false}
        elementPos={toolBarButtonRect}
        size="medium"
        isShowBg={false}
        cssPosition={cssPos}
      />
    </>
  );
}
