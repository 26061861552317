import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import * as yup from "yup";

import { TCreateCategory } from "./api";
import { IStepProps } from "./Setup";

import styles from "./Admin.module.scss";

import { useTranslation } from "react-i18next";
import { templates } from "editor/templates/types";
import { templateTitles } from "editor/templates/types";
import { VisibilityRounded } from "@material-ui/icons";
import { TemplateKey } from "editor/templates/types";

const schema = yup.object({
  category: yup.mixed<TCreateCategory>().required(/* TODO: Add a message */),
});

export type SetupStep4dFormValues = yup.InferType<typeof schema>;

export const SetupStep4dForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  setIsSelectTemplateLogo,
  handleSkip,
}) => {
  const { t } = useTranslation();

  const [templateKeys, setTemplateKeys] = useState<TemplateKey[]>();
  const [stateTemplateKey, setStateTemplateKey] = useState(values.templateKey);

  useEffect(() => {
    if (
      values &&
      (values.isDesignTemplates === undefined ||
        values.isDesignTemplates === false)
    ) {
      handleSaveAndContinue(values);
    } else {
      setIsSelectTemplateLogo(true);
      let tempTemplateKeys: any = [];
      if (templates) {
        Object.keys(templates).forEach(function eachKey(key) {
          tempTemplateKeys.push(key);
        });
      }
      setTemplateKeys(tempTemplateKeys);
    }
  }, [handleSaveAndContinue, setIsSelectTemplateLogo, values]);

  const onSubmit = (values: SetupStep4dFormValues) => {
    handleSaveAndContinue(values);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title style={{ width: "100%" }}>
              <h6>{t("setup_wizard_7")}</h6>
              <div className={styles["setup-subheader"]}>
                <div className={styles["setup-subheader-main"]}>
                  {t("setup_wizard_138")}
                </div>
                <div
                  className={styles["setup-subheader-skip"]}
                  onClick={() => {
                    handleSkip(values);
                  }}
                >
                  {t("setup_wizard_4")}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className={styles["setup-purpose__container"]}>
                {templateKeys ? (
                  templateKeys.map((templateKey) => (
                    <div
                      className={`${styles["setup-purpose__card"]} ${
                        stateTemplateKey === templateKey
                          ? styles["setup-purpose__card-selected"]
                          : ``
                      } ${styles["setup-purpose__card-template"]}`}
                      style={{ padding: "unset" }}
                      onClick={() => {
                        setStateTemplateKey(templateKey);
                        values.templateKey = templateKey;
                        if (
                          values.colorPrimary === undefined ||
                          values.colorPrimary === ""
                        ) {
                          values.colorPrimary =
                            templateTitles[templateKey].primaryColor;
                          values.colorSecondary =
                            templateTitles[templateKey].secondaryColor;
                          values.colorTernary =
                            templateTitles[templateKey].ternaryColor;
                          values.colorType =
                            templateTitles[templateKey].colorType;
                        }
                        values.fontPrimary =
                          templateTitles[templateKey].primaryFont;
                        values.fontSecondary =
                          templateTitles[templateKey].secondaryFont;
                        values.radiusPrimary =
                          templateTitles[templateKey].primaryRadius;
                        values.radiusSecondary =
                          templateTitles[templateKey].secondaryRadius;
                        values.shadowPrimary =
                          templateTitles[templateKey].primaryShadow;
                      }}
                    >
                      <div
                        key={templateKey}
                        style={{
                          backgroundImage: `url(${templateTitles[templateKey].screenshotUrl})`,
                          minHeight: "175px",
                          backgroundSize: "cover",
                          marginBottom: "8px",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginLeft: "16px",
                          marginRight: "16px",
                          marginBottom: "8px",
                          marginTop: "12px",
                        }}
                      >
                        <span className={styles["label"]}>
                          {t(templateTitles[templateKey].title)}
                        </span>
                        <span className={styles["label-view"]}>
                          {templateKey === TemplateKey.Blank ? (
                            <>&nbsp;</>
                          ) : (
                            <a
                              href={templateTitles[templateKey].sampleUrl}
                              target={"_blank"}
                              rel="noopener noreferrer"
                            >
                              <VisibilityRounded /> {t("view_sample")}
                            </a>
                          )}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <>No Templates available</>
                )}
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Button
              type="button"
              onClick={handleStepBack}
              style={{
                borderRadius: 32,
                color: "#007bff",
                backgroundColor: "white",
                borderColor: "#007bff",
              }}
            >
              {t("login_page_back")}
            </Button>
            <Button
              style={{ borderRadius: 32 }}
              type="submit"
              disabled={!values.templateKey}
            >
              {t("setup_page_15")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
