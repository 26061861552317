import React from "react";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { IProperty, PropertyComponent } from "./types";
import { propertySet } from "editor/states/layout";
import TextFieldGroup from "./../components/components/TextFieldGroup/TextFieldGroup";

import styles from "./CopyrightTextProperty.module.scss";
import { useTranslation } from "react-i18next";

export class CopyrightTextProperty implements IProperty {
  constructor(readonly label: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <CopyrightTextPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
        label={this.label}
      />
    );
  }

  static parse(value: any) {
    return new CopyrightTextProperty(value);
  }
}

const CopyrightTextPropertyComponent: PropertyComponent<CopyrightTextProperty> = ({
  elementId,
  propertyKey,
  label,
}) => {
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(propertySet(elementId, propertyKey, e.target.value));
  };
  const { t } = useTranslation();
  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <div className={styles["container"]}>
        <div className={styles["textField-container"]}>
          <Form className="form">
            <TextFieldGroup
              name="label"
              label={t("dialog_105")}
              value={label}
              onChange={handleChange}
              size={"m"}
              id={"copyright-text"}
              placeholder={"Set your copyright text here"}
            />
          </Form>
        </div>
      </div>
    </Formik>
  );
};
