import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import TextFieldGroup from "../../components/components/TextFieldGroup/TextFieldGroup";
import { Formik } from "formik";

import styles from "./MessengerButtonUsernameProperty.module.scss";

export default class MessengerButtonUsernameProperty implements IProperty {
  constructor(readonly text: any) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <MessengerButtonUsernamePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
        text={this.text}
      />
    );
  }

  static parse(value: any): MessengerButtonUsernameProperty {
    return new MessengerButtonUsernameProperty(value);
  }
}

const MessengerButtonUsernamePropertyComponent: PropertyComponent<MessengerButtonUsernameProperty> = ({
  elementId,
  propertyKey,
  text,
}) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState(text.text);
  const [timer, setTimer] = useState<any>();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUsername(e.target.value);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      dispatch(
        propertySet(
          elementId,
          propertyKey,
          new MessengerButtonUsernameProperty(e.target.value)
        )
      );
    }, 500);

    setTimer(newTimer);
  };

  return (
    <div>
      <div className={styles["button-prop-container"]}>
        <Formik initialValues={{}} onSubmit={async (username) => {}}>
          <TextFieldGroup
            name="username"
            label="Username"
            value={username ? username : ""}
            onChange={handleChange}
            size={"m"}
          />
        </Formik>
      </div>
    </div>
  );
};
