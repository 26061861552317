import React, { useEffect, useState } from "react";

import { RichTextProperty } from "editor/properties";
import {
  getPageSlug,
  getRtTempPropId,
  getRtTempText,
  getRtTempTextStatus,
  getSelectedElementId,
} from "editor/selectors";
import { elementRemoved, propertySet } from "editor/states/layout";

import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import {
  cancelElementEdition,
  resetRtTempText,
  saveRtTempText,
  setRtTempText,
} from "../Editor/editorActions";
import RichTextHelper from "../RichTextHelper/RichTextHelper";
import { getCompositeLayout } from "editor/states/selectors";
import "./RichTextEditor.scss";

export const sanitizeConf = {
  allowedTags: [
    "b",
    "i",
    "em",
    "strong",
    "u",
    "a",
    "p",
    "br",
    "div",
    "h1",
    "h2",
    "h3",
    "blockquote",
    "span",
    "font",
  ],
  allowedAttributes: {
    a: ["href"],
    span: ["style"],
    font: ["color", "face", "size"],
  },
};
//<span id="col-b35a6i" style="color: rgb(255, 255, 255);">Lorem</span>
export type RichTextProperties = {
  selectedRef: React.MutableRefObject<any>;
  handleTemplLink: (
    attr: {
      tempId: string;
      top: number;
      left: number;
      selectedId: string;
      rtTempText: string;
    } | null
  ) => void;
  handleTempColor: (
    attr: {
      tempId: string;
      top: number;
      left: number;
      selectedId: string;
      rtTempText: string;
    } | null
  ) => void;
};

export default function RichTextEditor({
  selectedRef,
  handleTemplLink,
  handleTempColor,
}: RichTextProperties): JSX.Element {
  const dispatch = useDispatch();

  const [html, setHtml] = React.useState<string | undefined>(
    "selectedRef.current"
  );
  const [showHelper, setShowHelper] = useState(false);
  const [textRange, setTextRange] = useState<Selection | null>();
  const rtTempText = useSelector(getRtTempText);
  const rtPropId = useSelector(getRtTempPropId);
  const isRtTempTextSaved = useSelector(getRtTempTextStatus);
  const selectedElementId = useSelector(getSelectedElementId);
  const layout = useSelector(getCompositeLayout);
  const pageSlug = useSelector(getPageSlug);

  useEffect(() => {
    if (selectedElementId !== null) {
      setHtml(document.getElementById(selectedElementId)?.innerHTML);
    }
  }, [dispatch, selectedElementId]);

  useEffect(() => {
    if (isRtTempTextSaved) {
      if (rtTempText !== undefined && rtTempText) {
        if (rtPropId !== undefined && rtPropId === selectedElementId) {
          dispatch(
            propertySet(
              selectedElementId,
              "richTextProperty",
              new RichTextProperty(sanitizeHtml(rtTempText, sanitizeConf))
            )
          );
          dispatch(resetRtTempText());
        }
      }
    }
  }, [dispatch, isRtTempTextSaved, selectedElementId, rtPropId, rtTempText]);
  if (
    selectedElementId === null ||
    selectedRef === null ||
    selectedRef.current === null
  ) {
    return <></>;
  }

  const element = layout[selectedElementId];
  if (
    !["rt", "heading", "paragraph", "lead", "blockquote"].includes(
      element.elementKey
    )
  ) {
    return <></>;
  }
  let selectedDomElement = document.getElementById(selectedElementId);
  if (selectedDomElement === null || selectedDomElement === undefined) {
    return <></>;
  }

  const handleChange = (e: any) => {
    const input = document.getElementById("edit-" + selectedElementId);
    if (input) {
      setHtml(e.target.value);
      dispatch(setRtTempText(selectedElementId, e.target.value));
      selectedDomElement?.style.setProperty(
        "height",
        window.getComputedStyle(input).height
      );
    }
  };

  const handleBlur = () => {
    setTimeout(function () {
      dispatch(saveRtTempText());
      dispatch(cancelElementEdition(selectedElementId, selectedRef));
      setShowHelper(false);
    }, 20);

    const input = document.getElementById("edit-" + selectedElementId);
    if (input) {
      selectedDomElement?.style.setProperty("height", "");
    }

    setTimeout(function () {
      selectedDomElement?.style.setProperty("visibility", "visible");
      selectedDomElement?.style.setProperty("opacity", "1");
    }, 60);
  };

  const handleDoubleClick = (e: any) => {
    selectedDomElement?.style.setProperty("visibility", "hidden");
    selectedDomElement?.style.setProperty("opacity", "0");
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    selectedDomElement?.style.setProperty("visibility", "hidden");
    selectedDomElement?.style.setProperty("opacity", "0");
    const t2 = document.getSelection();
    if (t2 && t2.getRangeAt(0).toString() !== "") {
      setShowHelper(true);
      setTextRange(document.getSelection());
    } else {
      setShowHelper(false);
      setTextRange(undefined);
    }
  };

  const handleClickHelper = () => {
    const input = document.getElementById("edit-" + selectedElementId);
    if (input) {
      setHtml(input.innerHTML);
      dispatch(setRtTempText(selectedElementId, input.innerHTML));
    }
  };

  const handleDeleteClick = () => {
    if (!selectedElementId) {
      return;
    }
    dispatch(cancelElementEdition(selectedElementId, selectedRef));
    dispatch(elementRemoved(selectedElementId, pageSlug!));
  };

  const rect = selectedDomElement.getBoundingClientRect();
  const computedStyle = window.getComputedStyle(selectedDomElement);

  const handleTempLink = (
    temp: {
      tempId: string;
      top: number;
      left: number;
      selectedId: string;
    } | null
  ) => {
    const input = document.getElementById("edit-" + selectedElementId);
    if (temp && input) {
      handleTemplLink({
        tempId: temp.tempId,
        top: temp.top,
        left: temp.left,
        selectedId: temp.selectedId,
        rtTempText: input.innerHTML,
      });
    }
  };

  const handleTemplColor = (
    temp: {
      tempId: string;
      top: number;
      left: number;
      selectedId: string;
    } | null
  ) => {
    const input = document.getElementById("edit-" + selectedElementId);
    if (temp && input) {
      handleTempColor({
        tempId: temp.tempId,
        top: temp.top,
        left: temp.left,
        selectedId: temp.selectedId,
        rtTempText: input.innerHTML,
      });
    }
  };

  return (
    <>
      <RichTextHelper
        showHelper={showHelper}
        clickHandler={handleClickHelper}
        textRangeParent={textRange}
        handleTemplLink={handleTempLink}
        handleTempColor={handleTemplColor}
        handleDelete={handleDeleteClick}
        handleBlur={handleBlur}
      />
      <div id={"editable-container"}>
        {/* <div
          className={style["rte-container-button"]}
          style={{
            position: "absolute",
            top: rect.top + window.pageYOffset - 20,
            left: rect.left + window.pageXOffset + rect.width - 16,
            zIndex: 1000,
          }}
        >
          <Button
            icon={DeleteForeverRounded}
            variant={"danger"}
            size={"extra-small"}
            onClick={handleDeleteClick}
          />
        </div> */}
        <ContentEditable
          id={"edit-" + selectedElementId}
          className="editable headline"
          tagName="div"
          html={html === undefined ? "Enter text" : html} // innerHTML of the editable div
          onChange={handleChange} // handle innerHTML change
          onBlur={handleBlur}
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
          style={{
            position: "absolute",
            top: rect.top + window.pageYOffset,
            left: rect.left + window.pageXOffset,
            maxWidth: computedStyle.getPropertyValue("max-width"),
            minHeight: computedStyle.getPropertyValue("min-height"),
            width: computedStyle.getPropertyValue("width"),
            height: "auto",
            fontSize: computedStyle.getPropertyValue("font-size"),
            fontFamily: computedStyle.getPropertyValue("font-family"),
            fontWeight: computedStyle.getPropertyValue("font-weight"),
            display: "inlineBlock",
            flexDirection: computedStyle.getPropertyValue("flex-direction"),
            alignItems: computedStyle.getPropertyValue("align-items"),
            textAlign: computedStyle.getPropertyValue("text-align"),
            lineHeight: computedStyle.getPropertyValue("line-height"),
            padding: computedStyle.getPropertyValue("padding"),
            justifyContent: computedStyle.getPropertyValue("justify-content"),
            // margin: computedStyle.getPropertyValue("margin"),
            outline: "2px solid #2176ff",
          }}
        />
      </div>
    </>
  );
}
