import React, { useEffect, useState } from "react";

import { FormatShapesRounded, SaveOutlined } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { getUsername, getUserWebsiteSlug } from "../../../../auth/selectors";
import { Dialog } from "../../components/Dialog/Dialog";
import { Row, Form, ListGroup, Spinner } from "react-bootstrap";
import {
  getColorType,
  getDescription,
  getPageId,
  getPageIndexState,
  // getPageSlug,
  getPrimaryColor,
  getPrimaryFont,
  getPrimaryRadius,
  getPrimaryShadow,
  getSecondaryColor,
  getSecondaryFont,
  getSecondaryRadius,
  getTernaryColor,
  getTitle,
} from "editor/selectors";

import styles from "./FontsPropDialog.module.scss";
import { pageDetailsUpdated } from "editor/states/pageList";
// import { trackFakeDoor } from "../Editor/editorActions";
import { editorPage } from "../Editor/Editor";
import { logActivity } from "auth/authActions";
import { getRequestIsInProgress } from "api/selectors";
import { updateWebsiteDetails } from "admin/websiteActions";
import { getWebsite } from "editor/states/selectors";
import { useTranslation } from "react-i18next";
type Props = {
  isShowFontsPropsDialog: boolean;
  toolBarButtonRect?: DOMRect;
  handleShowPropDialog: () => void;
  cssPos?: React.CSSProperties;
};

export default function FontsPropDialog({
  isShowFontsPropsDialog,
  toolBarButtonRect,
  handleShowPropDialog,
  cssPos,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const requestInProgress = useSelector(getRequestIsInProgress);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const statePrimaryFont = useSelector(getPrimaryFont);
  const stateSecondaryFont = useSelector(getSecondaryFont);
  const pageId = useSelector(getPageId);
  const pageTitle = useSelector(getTitle);
  // const pageSlug = useSelector(getPageSlug);
  const pageDescription = useSelector(getDescription);
  const isPageIndex = useSelector(getPageIndexState);
  const primaryColor = useSelector(getPrimaryColor);
  const secondaryColor = useSelector(getSecondaryColor);
  const ternaryColor = useSelector(getTernaryColor);
  const colorType = useSelector(getColorType);
  const statePrimaryRadius = useSelector(getPrimaryRadius);
  const stateSecondaryRadius = useSelector(getSecondaryRadius);
  const statePrimaryShadow = useSelector(getPrimaryShadow);
  const username = useSelector(getUsername);
  const [selectedPrimaryFont, setSelectedPrimaryFont] = useState(
    `"Roboto", sans-serif`
  );
  const [selectedSecondaryFont, setSelectedSecondaryFont] = useState(
    `"Noto Sans JP", sans-serif`
  );
  const [isDefAActive, setIsDefAActive] = useState(true);
  const [isDefBActive, setIsDefBActive] = useState(false);
  const [isDefCActive, setIsDefCActive] = useState(false);
  const [isDefDActive, setIsDefDActive] = useState(false);
  const [isDefEActive, setIsDefEActive] = useState(false);
  const [isDefFActive, setIsDefFActive] = useState(false);
  const [isDefGActive, setIsDefGActive] = useState(false);
  const [isDefHActive, setIsDefHActive] = useState(false);
  const [isDefIActive, setIsDefIActive] = useState(false);

  const [isDef2AActive, setIsDef2AActive] = useState(true);
  const [isDef2BActive, setIsDef2BActive] = useState(false);
  const [isDef2CActive, setIsDef2CActive] = useState(false);
  const [isDef2DActive, setIsDef2DActive] = useState(false);
  const [isDef2EActive, setIsDef2EActive] = useState(false);
  const [isDef2FActive, setIsDef2FActive] = useState(false);
  const [isDef2GActive, setIsDef2GActive] = useState(false);
  const [isDef2HActive, setIsDef2HActive] = useState(false);

  useEffect(() => {
    if (statePrimaryFont) {
      if (statePrimaryFont === `"Roboto", sans-serif`) {
        setIsDefAActive(true);
        setIsDefBActive(false);
        setIsDefCActive(false);
        setIsDefDActive(false);
        setIsDefEActive(false);
        setIsDefFActive(false);
        setIsDefGActive(false);
        setIsDefHActive(false);
        setIsDefIActive(false);
      } else if (statePrimaryFont === `"Lora", serif`) {
        setIsDefAActive(false);
        setIsDefBActive(true);
        setIsDefCActive(false);
        setIsDefDActive(false);
        setIsDefEActive(false);
        setIsDefFActive(false);
        setIsDefGActive(false);
        setIsDefHActive(false);
        setIsDefIActive(false);
      } else if (statePrimaryFont === `"Spartan", sans-serif`) {
        setIsDefAActive(false);
        setIsDefBActive(false);
        setIsDefCActive(true);
        setIsDefDActive(false);
        setIsDefEActive(false);
        setIsDefFActive(false);
        setIsDefGActive(false);
        setIsDefHActive(false);
        setIsDefIActive(false);
      } else if (statePrimaryFont === `"Raleway", sans-serif`) {
        setIsDefAActive(false);
        setIsDefBActive(false);
        setIsDefCActive(false);
        setIsDefDActive(true);
        setIsDefEActive(false);
        setIsDefFActive(false);
        setIsDefGActive(false);
        setIsDefHActive(false);
        setIsDefIActive(false);
      } else if (statePrimaryFont === `"Overpass", sans-serif`) {
        setIsDefAActive(false);
        setIsDefBActive(false);
        setIsDefCActive(false);
        setIsDefDActive(false);
        setIsDefEActive(true);
        setIsDefFActive(false);
        setIsDefGActive(false);
        setIsDefHActive(false);
        setIsDefIActive(false);
      } else if (statePrimaryFont === `"Rozha One", serif`) {
        setIsDefAActive(false);
        setIsDefBActive(false);
        setIsDefCActive(false);
        setIsDefDActive(false);
        setIsDefEActive(false);
        setIsDefFActive(true);
        setIsDefGActive(false);
        setIsDefHActive(false);
      } else if (statePrimaryFont === `"Comfortaa", sans-serif`) {
        setIsDefAActive(false);
        setIsDefBActive(false);
        setIsDefCActive(false);
        setIsDefDActive(false);
        setIsDefEActive(false);
        setIsDefFActive(false);
        setIsDefGActive(true);
        setIsDefHActive(false);
      } else if (statePrimaryFont === `"Copse", sans-serif`) {
        setIsDefAActive(false);
        setIsDefBActive(false);
        setIsDefCActive(false);
        setIsDefDActive(false);
        setIsDefEActive(false);
        setIsDefFActive(false);
        setIsDefGActive(false);
        setIsDefHActive(true);
        setIsDefIActive(false);
      } else if (statePrimaryFont === `"Space Mono", monospace`) {
        setIsDefAActive(false);
        setIsDefBActive(false);
        setIsDefCActive(false);
        setIsDefDActive(false);
        setIsDefEActive(false);
        setIsDefFActive(false);
        setIsDefGActive(false);
        setIsDefHActive(false);
        setIsDefIActive(true);
      }
    }

    if (stateSecondaryFont) {
      if (stateSecondaryFont === `"Noto Sans JP", sans-serif`) {
        setIsDef2AActive(true);
        setIsDef2BActive(false);
        setIsDef2CActive(false);
        setIsDef2DActive(false);
        setIsDef2EActive(false);
      } else if (stateSecondaryFont === `"Poppins", sans-serif`) {
        setIsDef2AActive(false);
        setIsDef2BActive(true);
        setIsDef2CActive(false);
        setIsDef2DActive(false);
        setIsDef2EActive(false);
      } else if (stateSecondaryFont === `"Montserrat", sans-serif`) {
        setIsDef2AActive(false);
        setIsDef2BActive(false);
        setIsDef2CActive(true);
        setIsDef2DActive(false);
        setIsDef2EActive(false);
      } else if (stateSecondaryFont === `"Libre Baskerville", serif`) {
        setIsDef2AActive(false);
        setIsDef2BActive(false);
        setIsDef2CActive(false);
        setIsDef2DActive(true);
        setIsDef2EActive(false);
      } else if (stateSecondaryFont === `"Kumbh Sans", sans-serif`) {
        setIsDef2AActive(false);
        setIsDef2BActive(false);
        setIsDef2CActive(false);
        setIsDef2DActive(false);
        setIsDef2EActive(true);
      } else if (stateSecondaryFont === `"Arvo", sans-serif`) {
        setIsDef2AActive(false);
        setIsDef2BActive(false);
        setIsDef2CActive(false);
        setIsDef2DActive(false);
        setIsDef2EActive(false);
        setIsDef2FActive(true);
        setIsDef2GActive(false);
        setIsDef2HActive(false);
      } else if (stateSecondaryFont === `"Jost", sans-serif`) {
        setIsDef2AActive(false);
        setIsDef2BActive(false);
        setIsDef2CActive(false);
        setIsDef2DActive(false);
        setIsDef2EActive(false);
        setIsDef2FActive(false);
        setIsDef2GActive(true);
        setIsDef2HActive(false);
      } else if (stateSecondaryFont === `"Nixie One", sans-serif`) {
        setIsDef2AActive(false);
        setIsDef2BActive(false);
        setIsDef2CActive(false);
        setIsDef2DActive(false);
        setIsDef2EActive(false);
        setIsDef2FActive(false);
        setIsDef2GActive(false);
        setIsDef2HActive(true);
      }
    }

    if (statePrimaryFont) setSelectedPrimaryFont(statePrimaryFont);

    if (stateSecondaryFont) setSelectedSecondaryFont(stateSecondaryFont);
  }, [statePrimaryFont, stateSecondaryFont]);

  const handleOnClick = (primaryFont: string, secondaryFont: string) => {
    if (pageId && pageTitle && isPageIndex !== null) {
      dispatch(
        logActivity(
          "SET FONT",
          editorPage,
          username,
          primaryFont + " / " + secondaryFont
        )
      );
      dispatch(
        pageDetailsUpdated(
          pageId,
          pageTitle,
          pageDescription ? pageDescription : "",
          isPageIndex,
          primaryFont ? primaryFont : `"Roboto", sans-serif`,
          secondaryFont ? secondaryFont : `"Lora", serif`,
          primaryColor ? primaryColor : "#124E78",
          secondaryColor ? secondaryColor : "#C47500",
          ternaryColor ? ternaryColor : "#003F7A",
          colorType ? colorType : "comp",
          statePrimaryRadius ? statePrimaryRadius : "5px",
          stateSecondaryRadius ? stateSecondaryRadius : "2.5px",
          statePrimaryShadow ? statePrimaryShadow : "0.5"
        )
      );
      if (websiteSlug) {
        dispatch(
          updateWebsiteDetails(
            websiteSlug,
            primaryFont ? primaryFont : `"Roboto", sans-serif`,
            secondaryFont ? secondaryFont : `"Lora", serif`,
            website.primaryColor ? website.primaryColor : "#124E78",
            website.secondaryColor ? website.secondaryColor : "#C47500",
            website.ternaryColor ? website.ternaryColor : "#003F7A",
            website.colorType ? website.colorType : "comp",
            website.primaryRadius ? website.primaryRadius : "5px",
            website.secondaryRadius ? website.secondaryRadius : "2.5px",
            website.primaryShadow ? website.primaryShadow : "0.5",
            website.logoCreated ? website.logoCreated : false
          )
        );
      }
    }
  };

  const handleSelectPair = (
    primaryFont: string,
    secondaryFont: string,
    isDefA: boolean,
    isDefB: boolean,
    isDefC: boolean,
    isDefD: boolean,
    isDefE: boolean,
    isDefF: boolean,
    isDefG: boolean,
    isDefH: boolean,
    isDefI: boolean,
    isPrimary: boolean
  ) => {
    setSelectedPrimaryFont(primaryFont);
    setSelectedSecondaryFont(secondaryFont);
    if (isPrimary) {
      setIsDefAActive(isDefA);
      setIsDefBActive(isDefB);
      setIsDefCActive(isDefC);
      setIsDefDActive(isDefD);
      setIsDefEActive(isDefE);
      setIsDefFActive(isDefF);
      setIsDefGActive(isDefG);
      setIsDefHActive(isDefH);
      setIsDefIActive(isDefI);
    } else {
      setIsDef2AActive(isDefA);
      setIsDef2BActive(isDefB);
      setIsDef2CActive(isDefC);
      setIsDef2DActive(isDefD);
      setIsDef2EActive(isDefE);
      setIsDef2FActive(isDefF);
      setIsDef2GActive(isDefG);
      setIsDef2HActive(isDefH);
    }

    handleOnClick(primaryFont, secondaryFont);
  };

  const dispatch = useDispatch();
  // const [isOwnFontDisplayed, setIsOwnFontDisplayed] = useState(false);

  // const handleDisplayTabs = (display: boolean) => {
  //   setIsOwnFontDisplayed(display);
  //   if (display) {
  //     if (websiteSlug) {
  //       dispatch(
  //         trackFakeDoor(
  //           websiteSlug,
  //           pageSlug ? pageSlug : undefined,
  //           "Custom Fonts"
  //         )
  //       );
  //     }
  //   }
  // };

  if (!isShowFontsPropsDialog || !websiteSlug) {
    return <></>;
  }

  const FontsPropDialogBody = (
    <>
      {/* <div className={`${styles["tab"]}`}>
        <div onClick={() => handleDisplayTabs(false)}>Default Pairings</div>
        <div onClick={() => handleDisplayTabs(true)}>Choose your own fonts</div>
      </div> */}
      {/* {!isOwnFontDisplayed ? (*/}
      <Form>
        <div className={styles["font-col"]}>
          <Form.Group as={Row}>
            <span>{t("editor_setup_wizard2")}</span>
            <ListGroup as="ul" style={{ width: "100%", padding: "0.75rem" }}>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefAActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Roboto", sans-serif`,
                    selectedSecondaryFont,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  );
                }}
              >
                Roboto
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefBActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Lora", serif`,
                    selectedSecondaryFont,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  );
                }}
              >
                Lora
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefCActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Spartan", sans-serif`,
                    selectedSecondaryFont,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  );
                }}
              >
                Spartan
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefDActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Raleway", sans-serif`,
                    selectedSecondaryFont,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  );
                }}
              >
                Raleway
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefEActive ? styles["active"] : styles[""]
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Overpass", sans-serif`,
                    selectedSecondaryFont,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    true
                  );
                }}
              >
                Overpass
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefFActive ? styles["active"] : styles[""]
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Rozha One", serif`,
                    selectedSecondaryFont,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    true
                  );
                }}
              >
                Rozha One
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefGActive ? styles["active"] : styles[""]
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Comfortaa", sans-serif`,
                    selectedSecondaryFont,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    true
                  );
                }}
              >
                Comfortaa
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefHActive ? styles["active"] : styles[""]
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Copse", sans-serif`,
                    selectedSecondaryFont,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true
                  );
                }}
              >
                Copse
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDefIActive ? styles["active"] : styles[""]
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    `"Space Mono", monospace`,
                    selectedSecondaryFont,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    true
                  );
                }}
              >
                Space Mono
              </ListGroup.Item>
            </ListGroup>
          </Form.Group>
          <Form.Group as={Row}>
            <span>{t("editor_setup_wizard3")}</span>
            <ListGroup as="ul" style={{ width: "100%", padding: "0.75rem" }}>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDef2AActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    selectedPrimaryFont,
                    `"Noto Sans JP", sans-serif`,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Noto Sans JP
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDef2BActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    selectedPrimaryFont,
                    `"Poppins", sans-serif`,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Poppins
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDef2CActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    selectedPrimaryFont,
                    `"Montserrat", sans-serif`,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Montserrat
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDef2DActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    selectedPrimaryFont,
                    `"Libre Baskerville", serif`,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Libre Baskerville
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDef2EActive ? styles["active"] : ""
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    selectedPrimaryFont,
                    `"Kumbh Sans", sans-serif`,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Kumbh Sans
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDef2FActive ? styles["active"] : styles[""]
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    selectedPrimaryFont,
                    `"Arvo", sans-serif`,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Arvo
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDef2GActive ? styles["active"] : styles[""]
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    selectedPrimaryFont,
                    `"Jost", sans-serif`,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false
                  );
                }}
              >
                Jost
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className={`${styles["list"]} ${
                  isDef2HActive ? styles["active"] : styles[""]
                }`}
                onMouseDown={() => {
                  handleSelectPair(
                    selectedPrimaryFont,
                    `"Nixie One", sans-serif`,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false
                  );
                }}
              >
                Nixie One
              </ListGroup.Item>
            </ListGroup>
          </Form.Group>
        </div>
      </Form>
      {/*) : (
        <div
          className={`${styles["message"]}`}
          style={{ marginTop: "4px", fontSize: ".9rem" }}
        >
          <h3>Thanks for your interest!</h3>
          At the moment it's not yet possible to use more fonts aside from the
          Default Pairs but we are evaluating the interest. We'll let you know
          if we will roll this feature out.
        </div>
      )}*/}
      <div className={styles["dialog-action-notification"]}>
        {requestInProgress ? (
          <>
            <Spinner
              size="sm"
              animation="border"
              variant="secondary"
              className={styles["saving-icon"]}
            />{" "}
            {t("Saving")}
          </>
        ) : (
          <>
            <SaveOutlined />
            {t("Changes_Saved")}
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      <Dialog
        dialogBody={FontsPropDialogBody}
        icon={FormatShapesRounded}
        headerLabel={t("Fonts")}
        handleShowDialog={handleShowPropDialog}
        isShowDialog={isShowFontsPropsDialog}
        isGrabbable={false}
        elementPos={toolBarButtonRect}
        size="medium"
        isShowBg={false}
        cssPosition={cssPos}
      />
    </>
  );
}
