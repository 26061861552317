import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "common/elements";
import * as Admin from "admin";
import * as Auth from "auth";
import { PrivateRoute } from "auth";
import * as Editor from "editor";
import * as Public from "public";
import * as serviceWorker from "serviceWorker";
import { store } from "store";
import { OAuth2RedirectHandler } from "auth/OAuth2RedirectHandler";
import "./i18n";
import { ErrorBoundary } from "react-error-boundary";
import { Loader } from "admin/Loader/Loader";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const Segment = require("analytics-node");
export const SegmentClient = new Segment("rYnYVf38pJJ9kJctl0JogqOgww4Za55p");

const isAdminUrl = (subdomain: string): boolean => {
  return (
    subdomain === "www" ||
    subdomain === "goodweb" ||
    subdomain === "localhost:3000"
  );
};

const subdomain = window.location.host.split(".")[0];

ReactDOM.render(
  <ErrorBoundary FallbackComponent={Loader}>
    <PayPalScriptProvider
      options={{
        "client-id":
          // "AVPW5XoZAJvyguJdJrcwsS4oouL_caKQhxq5e5oKuJ5UrEs_fq1DtrKw40Nf-nR_IN_Y_oNP5u4ssEA5" // Production
          "AdwWZ6IN3_WWLFkPhm6hAbNeN1aGFFPYghzkYGAipBTjfSUV6ZNEldJxm50xwv3Wl5XoYoTwvNZtk7EG", // Sandbox
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}
    >
      <Provider store={store}>
        {isAdminUrl(subdomain) ? (
          <Router>
            <Switch>
              <Redirect exact strict from="/" to="/login" />
              <Route exact strict path="/fbad1">
                <Public.TestFacebookAd1 />
              </Route>
              <Route exact strict path="/login">
                <Auth.Login />
              </Route>
              <Route exact strict path="/sign-up">
                <Auth.SignUp />
              </Route>
              <Route exact strict path="/admin/pages/new">
                <Editor.Editor />
              </Route>
              <Route
                path="/oauth2/redirect"
                component={OAuth2RedirectHandler}
              ></Route>
              <PrivateRoute exact strict path="/admin/pages/:postSlug/edit">
                <Editor.Editor />
              </PrivateRoute>
              <PrivateRoute path="/admin">
                <Admin.Container />
              </PrivateRoute>
            </Switch>
          </Router>
        ) : (
          <Router>
            <Switch>
              <Route exact strict path="/">
                <Public.Website websiteSlug={subdomain} />
              </Route>
              <Route exact strict path="/:pageSlug">
                <Public.Page websiteSlug={subdomain} />
              </Route>
            </Switch>
          </Router>
        )}
      </Provider>
    </PayPalScriptProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
