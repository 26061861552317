import {
  DeleteForeverRounded,
  EditRounded,
  AddRounded,
  SwapVertRounded,
  ArrowBackRounded,
} from "@material-ui/icons";
import produce from "immer";
import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { LinkProperty } from "..";
import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { ReactSortable } from "react-sortablejs";
import { Button } from "editor/components/base/Button/Button";
import { LinkPropertyEditor } from "editor/components/impl/LinkPropertyEditor/LinkPropertyEditor";

import "./LinkCollectionProperty.scss";
import styles from "./LinkCollectionProperty.module.scss";

import validator from "validator";
import { useTranslation } from "react-i18next";

export class LinkCollectionProperty implements IProperty {
  constructor(readonly links: LinkProperty[]) {}

  render(elementId: string, propertyKey: string) {
    return (
      <LinkCollectionPropertyComponent
        {...this}
        key={elementId}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    const links: LinkProperty[] = value.map((v: any) => LinkProperty.parse(v));
    return new LinkCollectionProperty(links);
  }
}

interface ItemType {
  id: number;
  link: LinkProperty;
}

const LinkCollectionPropertyComponent: PropertyComponent<LinkCollectionProperty> = ({
  elementId,
  propertyKey,
  links,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLinkEditDisplayed, setIsLinkEditDisplayed] = useState(false);
  const [indexToDisplay, setIndexToDisplay] = useState(0);
  const [isAdding, setIsAdding] = useState(false);
  const [linksUnsorted, setLinksUnsorted] = useState(links);
  const [linksSorted, setLinksSorted] = useState<ItemType[]>(
    links.map((link, index) => ({ id: index, link: link }))
  );
  const [selectedUrl, setSelectedUrl] = useState("");
  const [unsavedText, setUnsavedText] = useState("");
  const [unsavedUrl, setUnsavedUrl] = useState("");
  const [unsavedAnchor, setUnsavedAnchor] = useState(false);
  const [unsavedExternalLink, setUnsavedExternalLink] = useState(false);
  const [unsavedOpenNewTab, setUnsavedOpenNewTab] = useState(false);

  const [urlInvalid, setUrlInvalid] = useState(true);

  const [buttonVariant, setButtonVariant] = useState(
    unsavedText || unsavedText !== "" ? "primary" : "disabled"
  );

  const handleLinkLabelChange = (index: number, text: string) => {
    const nextLinks = produce(linksUnsorted, (draft) => {
      draft[index].text = text;
    });
    dispatch(propertySet(elementId, propertyKey, nextLinks));
  };

  const handleOpenNewTabChange = (index: number, openNewTab: boolean) => {
    const nextLinks = produce(linksUnsorted, (draft) => {
      draft[index].openNewTab = openNewTab;
    });
    dispatch(propertySet(elementId, propertyKey, nextLinks));
  };

  const handleAdd = () => {
    const nextLinks = produce(linksUnsorted, (draft) => {
      draft.push(
        new LinkProperty(
          unsavedText,
          unsavedUrl,
          unsavedAnchor,
          unsavedExternalLink,
          unsavedOpenNewTab
        )
      );
    });
    dispatch(propertySet(elementId, propertyKey, nextLinks));
    setIsAdding(false);
    setIsLinkEditDisplayed(false);

    linksSorted.push({
      id: linksSorted.slice(-1)[0].id + 1,
      link: nextLinks[nextLinks.length - 1],
    });
    setLinksSorted(linksSorted);
    setLinksUnsorted(nextLinks);
  };

  const handleAdding = () => {
    setIsLinkEditDisplayed(true);
    setIsAdding(true);
  };

  const handleRemove = (index: number) => {
    const nextLinks = produce(linksUnsorted, (draft) => {
      draft.splice(index, 1);
    });
    dispatch(propertySet(elementId, propertyKey, nextLinks));
    setIsLinkEditDisplayed(false);
    linksSorted.splice(index, 1);
    setLinksSorted(linksSorted);
  };

  const handleLinkClick = (index?: number) => {
    setIsLinkEditDisplayed(isLinkEditDisplayed ? false : true);
    if (index !== undefined) {
      setIndexToDisplay(index);
    }
  };

  const handleSort = () => {
    // links.map((link, index) => ({ id: index, link: link }))
    const nextLinks = linksSorted.map((linkSort: ItemType) => linkSort.link);
    dispatch(propertySet(elementId, propertyKey, nextLinks));
    setLinksUnsorted(nextLinks);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let linkLabel = e.target.value;
    if (linkLabel || linkLabel !== "") {
      setButtonVariant("primary");
    } else {
      setButtonVariant("disabled");
    }
    if (isAdding) {
      setUnsavedText(linkLabel);
    } else {
      handleLinkLabelChange(indexToDisplay, linkLabel);
    }
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedUrl(e.target.value);
    if (isAdding) {
      setUnsavedUrl(e.target.value);
      setUnsavedExternalLink(true);
      setUnsavedAnchor(false);
    } else {
      const nextLinks = produce(linksUnsorted, (draft) => {
        draft[indexToDisplay].url = e.target.value;
        draft[indexToDisplay].isExternalLink = true;
        draft[indexToDisplay].isAnchor = false;
      });
      dispatch(propertySet(elementId, propertyKey, nextLinks));
    }
  };

  const handleUrlChangeSave = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (validator.isURL(e.target.value)) {
      setUrlInvalid(true);
    } else {
      setUrlInvalid(false);
    }
  };

  const handlePageSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (isAdding) {
      setUnsavedUrl("/" + e);
      setUnsavedExternalLink(false);
      setUnsavedAnchor(false);
    } else {
      const nextLinks = produce(linksUnsorted, (draft) => {
        draft[indexToDisplay].url = "/" + e;
        draft[indexToDisplay].isExternalLink = false;
        draft[indexToDisplay].isAnchor = false;
      });
      dispatch(propertySet(elementId, propertyKey, nextLinks));
    }
    setSelectedUrl(String(e));
  };

  const handleSectionSelect = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (isAdding) {
      setUnsavedUrl("#" + e);
      setUnsavedExternalLink(false);
      setUnsavedAnchor(true);
    } else {
      const nextLinks = produce(linksUnsorted, (draft) => {
        draft[indexToDisplay].url = "#" + e;
        draft[indexToDisplay].isExternalLink = false;
        draft[indexToDisplay].isAnchor = true;
      });
      dispatch(propertySet(elementId, propertyKey, nextLinks));
    }
    setSelectedUrl(String(e));
  };

  const handleOpenNewTabSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isAdding) {
      setUnsavedOpenNewTab(e.target.checked);
    } else {
      handleOpenNewTabChange(indexToDisplay, e.target.checked);
    }
  };

  return (
    <div className="medium-padded-container">
      {isLinkEditDisplayed ? (
        isAdding ? (
          <div className="link-collection-display-container">
            <LinkPropertyEditor
              text={unsavedText}
              url={unsavedUrl}
              isAnchor={unsavedAnchor}
              isExternalLink={unsavedExternalLink}
              isAdding={true}
              openNewTab={unsavedOpenNewTab}
              handleTextChange={handleTextChange}
              handlePageSelect={handlePageSelect}
              handleSectionSelect={handleSectionSelect}
              handleUrlChange={handleUrlChange}
              handleOpenNewTabChange={handleOpenNewTabSelect}
              selectedUrl={selectedUrl}
              elementId={elementId}
              urlInvalid={urlInvalid}
              handleUrlChangeSave={handleUrlChangeSave}
            />
            <div className={styles["actions"]}>
              {isAdding ? (
                <>
                  <span style={{ width: "15%" }}></span>
                  <span
                    style={{ paddingRight: "16px" }}
                    onClick={() => {
                      setIsAdding(false);
                      setIsLinkEditDisplayed(false);
                    }}
                    className={styles["back-link"]}
                  >
                    <ArrowBackRounded /> {t("cancel")}
                  </span>
                  <Button
                    variant={buttonVariant}
                    label={t("dialog_49")}
                    icon={AddRounded}
                    onClick={() => handleAdd()}
                  />
                </>
              ) : (
                <>
                  <Button
                    variant="outline-danger"
                    size="circle"
                    icon={DeleteForeverRounded}
                    onClick={() => handleRemove(indexToDisplay)}
                  />
                  <span
                    onClick={() => {
                      setIsLinkEditDisplayed(false);
                    }}
                    className={styles["back-link"]}
                  >
                    <ArrowBackRounded /> {t("dialog_35")}
                  </span>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="link-collection-display-container">
            {linksUnsorted.map((link, index) =>
              indexToDisplay === index ? (
                <>
                  <LinkPropertyEditor
                    text={link.text}
                    url={link.url}
                    isAnchor={link.isAnchor}
                    isExternalLink={link.isExternalLink}
                    openNewTab={link.openNewTab}
                    isAdding={false}
                    handleTextChange={handleTextChange}
                    handlePageSelect={handlePageSelect}
                    handleSectionSelect={handleSectionSelect}
                    handleUrlChange={handleUrlChange}
                    handleOpenNewTabChange={handleOpenNewTabSelect}
                    selectedUrl={selectedUrl}
                    elementId={elementId}
                    urlInvalid={urlInvalid}
                    handleUrlChangeSave={handleUrlChangeSave}
                  />
                  <div className={styles["actions"]}>
                    <Button
                      variant="outline-danger"
                      size="circle"
                      icon={DeleteForeverRounded}
                      onClick={() => handleRemove(indexToDisplay)}
                    />
                    <span
                      onClick={() => setIsLinkEditDisplayed(false)}
                      className={styles["back-link"]}
                    >
                      <ArrowBackRounded /> {t("dialog_35")}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )
            )}
          </div>
        )
      ) : (
        <div className="links-body">
          <div className="link-collection">
            <div className="link-collection-label">
              <SwapVertRounded /> {t("dialog_23")}
            </div>
            <ListGroup className="list-group">
              <ReactSortable
                list={linksSorted}
                setList={setLinksSorted}
                onEnd={handleSort}
              >
                {linksSorted.map((item, index) => (
                  <ListGroup.Item
                    onClick={() => {
                      handleLinkClick(index);
                    }}
                    key={index}
                    className="list-group-item"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className={"actions left grabbable"}>
                        <SwapVertRounded />
                      </div>
                      <h5>{item.link.text}</h5>
                    </div>
                    <div className={"actions right"}>
                      <EditRounded />
                    </div>
                  </ListGroup.Item>
                ))}
              </ReactSortable>
            </ListGroup>
          </div>
          <Button
            variant="outline-primary"
            icon={AddRounded}
            label={t("dialog_24")}
            onClick={handleAdding}
            size="medium"
          />
        </div>
      )}
    </div>
  );
};
