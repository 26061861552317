import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Modal, Container, Row, Form } from "react-bootstrap";
import * as yup from "yup";

import { StepProps } from "./EditorWizard";
import { TemplateKey } from "../../../templates/types";

import styles from "./EditorWizard.module.scss";
import { Button } from "editor/components/base/Button/Button";
import { ArrowBackRounded, ArrowForwardRounded } from "@material-ui/icons";
import { Range } from "react-range";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  templateKey: yup.mixed<TemplateKey>().required(),
  primaryRadius: yup.string(),
  secondaryRadius: yup.string(),
  primaryShadow: yup.string(),
});

type FormValues = yup.InferType<typeof schema>;

export const EditorWizardStep0c: React.FunctionComponent<StepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleUpdateRadiusShadow,
  parentPrimaryRadius,
  parentSecondaryRadius,
  parentPrimaryShadow,
  parentPrimaryColor,
  parentSecondaryColor,
  parentTernaryColor,
  parentPrimaryFont,
  parentSecondaryFont,
}) => {
  const { t } = useTranslation();
  const [selectedPrimaryRadius, setSelectedPrimaryRadius] = useState("5px");
  const [selectedSecondaryRadius, setSelectedSecondaryRadius] = useState(
    "2.5px"
  );
  const [selectedPrimaryShadow, setSelectedPrimaryShadow] = useState("0.5");
  const [roundnessRangeValue, setRoundnessRangeValue] = useState([5]);
  const [shadowRangeValue, setShadowRangeValue] = useState([0.5]);

  useEffect(() => {
    if (parentPrimaryRadius && parentPrimaryRadius !== "") {
      setSelectedPrimaryRadius(parentPrimaryRadius);
      setRoundnessRangeValue([Number(parentPrimaryRadius.replace("px", ""))]);
    }
    if (parentSecondaryRadius && parentSecondaryRadius !== "") {
      setSelectedSecondaryRadius(parentSecondaryRadius);
    }
    if (parentPrimaryShadow && parentPrimaryShadow !== "") {
      setSelectedPrimaryShadow(parentPrimaryShadow);
      setShadowRangeValue([Number(parentPrimaryShadow)]);
    }
  }, [parentPrimaryRadius, parentSecondaryRadius, parentPrimaryShadow]);

  const onSubmit = (values: FormValues) => {
    handleSaveAndContinue(values);
  };

  const handleRoundedRangeChange = (
    values: number[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setRoundnessRangeValue(values);
    setFieldValue("primaryRadius", values[0] + "px");
    setFieldValue("secondaryRadius", values[0] / 2 + "px");
    handleUpdateRadiusShadow(
      values[0] + "px",
      values[0] / 2 + "px",
      selectedPrimaryShadow
    );
  };

  const handleShadowRangeChange = (
    values: number[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setShadowRangeValue(values);
    setFieldValue("primaryShadow", values[0] + "");
    handleUpdateRadiusShadow(
      selectedPrimaryRadius,
      selectedSecondaryRadius,
      values[0] + ""
    );
  };
  function hexToRGB(hex: string, alpha: number) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
  function isHexLight(color: string) {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, setFieldValue, handleSubmit, handleChange }) => (
        <div className={`${styles["wizard__split-container"]}`}>
          <Form noValidate onSubmit={handleSubmit} style={{ width: "50%" }}>
            <Modal.Header className={`${styles["wizard__header"]}`}>
              <Modal.Title style={{ lineHeight: 1 }}>
                {t("editor_setup_wizard16")}
              </Modal.Title>
              {/* <small style={{marginTop: 12}}>
                Select how round the corners of some of the elements (Buttons,
                Dialogs, Text Areas) and its shadows in your page. You can
                change this later in the Editor.
              </small> */}
            </Modal.Header>
            <Modal.Body className={`${styles["wizard__body"]}`}>
              <Container>
                <Form.Group as={Row}>
                  <Form.Control
                    type="hidden"
                    name="primaryColor"
                    value={values.primaryColor}
                  ></Form.Control>
                  <Form.Control
                    type="hidden"
                    name="secondaryColor"
                    value={values.secondaryColor}
                  ></Form.Control>
                  <div className={`${styles["range-container"]}`}>
                    <p>{t("editor_setup_wizard17")}</p>
                    <Range
                      step={1}
                      min={0}
                      max={50}
                      values={roundnessRangeValue}
                      onChange={(values) =>
                        handleRoundedRangeChange(values, setFieldValue)
                      }
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          className={`${styles["range-tracker"]}`}
                          style={{
                            ...props.style,
                            height: "4px",
                            width: "100%",
                            backgroundColor: "#cfcfcf",
                            borderRadius: "4px",
                            boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          className={`${styles["range-thumb"]}`}
                          style={{
                            ...props.style,
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#319560",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    className={`${styles["range-container"]}`}
                    style={{
                      marginTop: "80px",
                      marginBottom: "80px",
                    }}
                  >
                    <p>{t("editor_setup_wizard18")}</p>
                    <Range
                      step={0.1}
                      min={0}
                      max={1}
                      values={shadowRangeValue}
                      onChange={(values) =>
                        handleShadowRangeChange(values, setFieldValue)
                      }
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          className={`${styles["range-tracker"]}`}
                          style={{
                            ...props.style,
                            height: "4px",
                            width: "100%",
                            backgroundColor: "#cfcfcf",
                            borderRadius: "4px",
                            boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          className={`${styles["range-thumb"]}`}
                          style={{
                            ...props.style,
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#319560",
                          }}
                        />
                      )}
                    />
                  </div>
                </Form.Group>
              </Container>
            </Modal.Body>
            <Modal.Footer className={styles["wizard__footer"]}>
              <Button
                label="Next"
                variant="outline-primary"
                size="medium"
                icon={ArrowForwardRounded}
                onClick={handleSubmit}
              />
              <Button
                icon={ArrowBackRounded}
                variant="outline-primary"
                size="circle"
                onClick={() => handleStepBack(1)}
              />
            </Modal.Footer>
          </Form>
          <div className={`${styles["wizard__preview"]}`}>
            <div className={`${styles["label"]}`}>
              {t("editor_setup_wizard4")}
            </div>
            <div
              className={`${styles["container"]}`}
              style={{
                backgroundColor: `${hexToRGB(parentTernaryColor, 0.1)}`,
                paddingBottom: 20,
              }}
            >
              {/* <div
                className={`${styles["card-1"]}`}
                style={{
                  alignSelf: "self-start",
                  borderRadius: roundnessRangeValue[0] + "px",
                  boxShadow: `5px 5px 5px -5px rgba(0,0,0,${shadowRangeValue})`,
                }}
              >
                <div>
                  <img
                    src="/img/Tomasz_Jedruszek_Kings_Landing-300px.png"
                    alt="https://awoiaf.westeros.org/images/6/64/Tomasz_Jedruszek_Kings_Landing.jpg"
                  />
                </div>
                <div className={`${styles["card-1-content-container"]}`}>
                  <p>King's Landing</p>
                  <p>
                    The capital of the Seven Kingdoms with a population of half
                    a million. The largest city of Westeros, founded by Aegon
                    the Conqueror on the site where he first set foot on the
                    continent.
                  </p>
                  <button
                    style={{
                      border: "2px solid rgb(255, 163, 163)",
                      backgroundColor: "#fff",
                      color: "rgb(51, 51, 51)",
                      padding: "12px",
                      borderRadius: roundnessRangeValue[0] / 2 + "px",
                    }}
                  >
                    Read More About This
                  </button>
                </div>
              </div> */}
              <div>
                <img
                  className={`${styles["shadow-image"]}`}
                  src="/img/300px-Marc_Simonetti_bran_climbing.jpg"
                  alt="https://awoiaf.westeros.org/images/thumb/e/ed/Marc_Simonetti_bran_climbing.jpg/300px-Marc_Simonetti_bran_climbing.jpg"
                  style={{
                    borderRadius: roundnessRangeValue[0] + "px",
                    boxShadow: `5px 5px 5px -5px rgba(0,0,0,${shadowRangeValue})`,
                  }}
                />
                <div
                  className={`${styles["shadow-button"]}`}
                  style={{
                    borderRadius: roundnessRangeValue[0] / 2 + "px",
                    boxShadow: `5px 5px 5px -5px rgba(0,0,0,${shadowRangeValue})`,
                    borderColor: `${parentPrimaryColor}`,
                    color: `${parentPrimaryColor}`,
                    fontFamily: `${parentPrimaryFont}`,
                  }}
                >
                  {t("editor_setup_wizard19")}
                </div>
              </div>
              <div
                className={`${styles["card-1"]}`}
                style={{
                  borderRadius: roundnessRangeValue[0] + "px",
                  boxShadow: `5px 5px 5px -5px rgba(0,0,0,${shadowRangeValue})`,
                }}
              >
                <div>
                  <img
                    src="/img/Mariusz_Gandzell_Gates_of_Winterfell-300px.png"
                    alt="https://awoiaf.westeros.org/images/c/cb/Mariusz_Gandzell_Gates_of_Winterfell.png"
                  />
                </div>
                <div className={`${styles["card-1-content-container"]}`}>
                  <p
                    style={{
                      fontFamily: `${parentPrimaryFont}`,
                    }}
                  >
                    {t("editor_setup_wizard20")}
                  </p>
                  <p
                    style={{
                      fontFamily: `${parentSecondaryFont}`,
                    }}
                  >
                    {t("editor_setup_wizard21")}{" "}
                  </p>
                  <button
                    style={{
                      border: "none",
                      padding: "12px",
                      borderRadius: roundnessRangeValue[0] / 2 + "px",
                      fontFamily: `${parentPrimaryFont}`,
                      backgroundColor: `${parentPrimaryColor}`,
                      color: `${
                        isHexLight(parentPrimaryColor)
                          ? parentSecondaryColor
                          : `#ffffff`
                      }`,
                    }}
                  >
                    {t("editor_setup_wizard22")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
