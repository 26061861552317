import React, { useEffect, useState } from "react";
import { IProperty, PropertyComponent } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import "./PhoneNumberProperty.scss";
import styles from "./PhoneNumberProperty.module.scss";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { getUserCountryCode } from "auth/selectors";
import { propertySet } from "editor/states/layout";

export class PhoneNumberProperty implements IProperty {
  constructor(
    readonly number: string,
    readonly countryCode?: string | undefined
  ) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <PhoneNumberPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): PhoneNumberProperty {
    return new PhoneNumberProperty(value.number, value.countryCode);
  }
}

const PhoneNumberPropertyComponent: PropertyComponent<PhoneNumberProperty> = ({
  elementId,
  propertyKey,
  number,
  countryCode,
}) => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState(number);
  const [code, setCode] = useState(countryCode);
  const stateCountryCode = useSelector(getUserCountryCode);
  const [timer, setTimer] = useState<any>();

  useEffect(() => {
    if (countryCode === undefined) {
      setCode(stateCountryCode?.toLowerCase());
    }
  }, [countryCode, stateCountryCode]);

  const handlePhoneChange = (e: string): void => {
    setPhoneNumber(e);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      dispatch(
        propertySet(elementId, propertyKey, new PhoneNumberProperty(e, code))
      );
    }, 500);

    setTimer(newTimer);
  };

  return (
    <div className={styles["form__group"]}>
      <Formik initialValues={{ name: "" }} onSubmit={() => {}}>
        <Form className="form">
          <div className={styles["phone-number-container"]}>
            <span>Whatsapp Number</span>
            <PhoneInput
              country={code}
              enableSearch={true}
              value={phoneNumber}
              onChange={(e) => {
                handlePhoneChange(e);
              }}
              placeholder={""}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};
