import {
  HomeRounded,
  DeleteForeverRounded,
  SaveRounded,
  CheckRounded,
  PriorityHighRounded,
  RefreshRounded,
  WarningRounded,
} from "@material-ui/icons";
import { Formik, Form } from "formik";
import { FormCheck } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { deletePage } from "admin/pageActions";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";
import TextFieldGroup from "../../components/TextFieldGroup/TextFieldGroup";
import TextAreaGroup from "../../components/TextAreaGroup/TextAreaGroup";
import { PageWithLatestDetails } from "editor/models/types";
import {
  pageDetailsUpdated,
  pageDetailsUpdateModified,
  pageListRequested,
} from "editor/states/pageList";
import { Button } from "../../base/Button/Button";
import { getPageUpdateStatus } from "editor/states/selectors";
import styles from "./PageListPropertiesDialog.module.scss";
import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { ChangeEvent } from "react";
import {
  getColorType,
  getPrimaryColor,
  getPrimaryFont,
  getPrimaryRadius,
  getPrimaryShadow,
  getSecondaryColor,
  getSecondaryFont,
  getSecondaryRadius,
  getTernaryColor,
} from "editor/selectors";
import { logActivity } from "auth/authActions";
import { useTranslation } from "react-i18next";

type Props = {
  page: PageWithLatestDetails;
  indexPage: PageWithLatestDetails;
  websiteSlug: string;
  pageContainer: string;
};

const schema = yup.object({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
});

export default function PagePropertiesForm({
  page,
  indexPage,
  pageContainer,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const pageUpdateStatus = useSelector(getPageUpdateStatus);
  const [isDeleteDisplayed, setDeleteDisplayed] = useState(false);
  const [pageToDelete, setPageToDelete] = useState("");
  const [pageTitle, setPageTitle] = useState<string>(page.title);
  const [pageDescription, setPageDescription] = useState<string>(
    page.description
  );
  const [pageTitleError, setPageTitleError] = useState<string>();
  const [pageDescriptionError, setPageDescriptionError] = useState<string>();
  const primaryFont = useSelector(getPrimaryFont);
  const secondaryFont = useSelector(getSecondaryFont);
  const primaryColor = useSelector(getPrimaryColor);
  const secondaryColor = useSelector(getSecondaryColor);
  const ternaryColor = useSelector(getTernaryColor);
  const colorType = useSelector(getColorType);
  const statePrimaryRadius = useSelector(getPrimaryRadius);
  const stateSecondaryRadius = useSelector(getSecondaryRadius);
  const statePrimaryShadow = useSelector(getPrimaryShadow);
  const username = useSelector(getUsername);

  let buttonLabel: string | undefined;
  let buttonIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | undefined;
  let buttonVariant = "primary";
  let isDisabled = false;

  if (pageUpdateStatus === "in-progress") {
    buttonLabel = t("Saving");
    isDisabled = true;
    buttonIcon = RefreshRounded;
    buttonVariant = "disabled";
  } else if (pageUpdateStatus === "success") {
    buttonLabel = t("saved");
    buttonIcon = CheckRounded;
    buttonVariant = "success";
    isDisabled = true;
  } else if (pageUpdateStatus === "failure") {
    buttonLabel = t("saved_failed");
    buttonVariant = "danger";
    buttonIcon = PriorityHighRounded;
    isDisabled = false;
  } else if (pageUpdateStatus === "modified") {
    buttonLabel = t("save");
    isDisabled = false;
    buttonVariant = "primary";
    buttonIcon = SaveRounded;
  } else {
    buttonLabel = t("save");
    isDisabled = true;
    buttonVariant = "disabled";
    buttonIcon = SaveRounded;
  }

  if (!websiteSlug) {
    return <></>;
  }

  const handleSave = (): void => {
    let isValid = true;
    if (!pageTitle || !pageTitle.replace(/\s/g, "").length) {
      setPageTitleError(t("page_properties_1"));
      isValid = false;
    }
    if (!pageDescription || !pageDescription.replace(/\s/g, "").length) {
      setPageDescriptionError(t("page_properties_2"));
      isValid = false;
    }

    if (isValid) {
      dispatch(
        logActivity("SAVE PAGE DETAIL", pageContainer, username, page.slug)
      );
      dispatch(
        pageDetailsUpdated(
          page.id,
          pageTitle,
          pageDescription,
          page.isIndex,
          primaryFont ? primaryFont : `"Roboto", sans-serif`,
          secondaryFont ? secondaryFont : `"Lora", serif`,
          primaryColor ? primaryColor : "#124E78",
          secondaryColor ? secondaryColor : "#C47500",
          ternaryColor ? ternaryColor : "#003F7A",
          colorType ? colorType : "comp",
          statePrimaryRadius ? statePrimaryRadius : "5px",
          stateSecondaryRadius ? stateSecondaryRadius : "2.5px",
          statePrimaryShadow ? statePrimaryShadow : "0.5"
        )
      );
    }
  };

  const handleTitleUpdate = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(pageDetailsUpdateModified());
    setPageTitle(event.target.value);
    setPageTitleError("");
  };

  const handleDescriptionUpdate = (
    event: ChangeEvent<HTMLTextAreaElement>
  ): void => {
    dispatch(pageDetailsUpdateModified());
    setPageDescription(event.target.value);
    setPageDescriptionError("");
  };

  const handleDelete = (pageToDelete: string): void => {
    dispatch(logActivity("DELETE PAGE", pageContainer, username, page.slug));
    dispatch(deletePage(websiteSlug, page.slug));
    dispatch(pageListRequested(websiteSlug));
    isDeleteDisplayed ? setDeleteDisplayed(false) : setDeleteDisplayed(true);
    isDeleteDisplayed ? setDeleteDisplayed(false) : setDeleteDisplayed(true);
  };

  const handleDeleteDialog = (pageToDelete: string) => {
    setPageToDelete(pageToDelete);
    isDeleteDisplayed ? setDeleteDisplayed(false) : setDeleteDisplayed(true);
    isDeleteDisplayed ? setDeleteDisplayed(false) : setDeleteDisplayed(true);
  };

  const handleSetIndex = () => {
    if (indexPage) {
      dispatch(
        pageDetailsUpdated(
          indexPage.id,
          indexPage.title,
          indexPage.description,
          false,
          primaryFont ? primaryFont : `"Roboto", sans-serif`,
          secondaryFont ? secondaryFont : `"Lora", serif`,
          primaryColor ? primaryColor : "#124E78",
          secondaryColor ? secondaryColor : "#C47500",
          ternaryColor ? ternaryColor : "#003F7A",
          colorType ? colorType : "comp",
          statePrimaryRadius ? statePrimaryRadius : "5px",
          stateSecondaryRadius ? stateSecondaryRadius : "2.5px",
          statePrimaryShadow ? statePrimaryShadow : "0.5"
        )
      );
    }
    dispatch(
      pageDetailsUpdated(
        page.id,
        page.title,
        page.description,
        true,
        primaryFont ? primaryFont : `"Roboto", sans-serif`,
        secondaryFont ? secondaryFont : `"Lora", serif`,
        primaryColor ? primaryColor : "#124E78",
        secondaryColor ? secondaryColor : "#C47500",
        ternaryColor ? ternaryColor : "#003F7A",
        colorType ? colorType : "comp",
        statePrimaryRadius ? statePrimaryRadius : "5px",
        stateSecondaryRadius ? stateSecondaryRadius : "2.5px",
        statePrimaryShadow ? statePrimaryShadow : "0.5"
      )
    );
  };

  return (
    <>
      {!isDeleteDisplayed ? (
        <Formik
          validationSchema={schema}
          onSubmit={handleSave}
          initialValues={{
            title: page.title,
            description: page.description,
          }}
        >
          <Form className={styles["form"]}>
            <TextFieldGroup
              name="title"
              label={t("page_properties_4")}
              onChange={handleTitleUpdate}
              value={pageTitle}
            />
            <p className="text-danger">{pageTitleError}</p>
            <TextAreaGroup
              name="description"
              label={t("page_properties_3")}
              value={pageDescription}
              onChange={handleDescriptionUpdate}
            />
            <p className="text-danger">{pageDescriptionError}</p>
            <FormCheck className={styles["home-checkbox"]}>
              <FormCheck.Label>
                {page.isIndex ? (
                  <></>
                ) : (
                  <FormCheck.Input
                    type="checkbox"
                    checked={page.isIndex}
                    onChange={handleSetIndex}
                  />
                )}
                <div>
                  {page.isIndex ? (
                    <>
                      <HomeRounded />
                      <span>{t("page_properties_5")}</span>
                    </>
                  ) : (
                    <>
                      <span>{t("page_properties_6")}</span>
                    </>
                  )}
                </div>
              </FormCheck.Label>
            </FormCheck>
            <div className={styles["actions"]}>
              <Button
                variant="outline-danger"
                size="circle"
                icon={DeleteForeverRounded}
                onClick={() => handleDeleteDialog(page.title)}
                style={{ padding: "12px" }}
                key={page.slug}
              />
              <Button
                variant={buttonVariant}
                icon={buttonIcon}
                onClick={handleSave}
                label={buttonLabel}
                isDisabled={isDisabled}
                type={"submit"}
              />
            </div>
          </Form>
        </Formik>
      ) : (
        <div className={styles["delete_confirmation"]}>
          <div className={styles["warning-message"]}>
            <WarningRounded />
            {t("page_properties_7")}
            <br />
            {t("page_properties_8")} <span>{pageToDelete}</span>
          </div>
          <div className={styles["delete_actions"]}>
            <Button
              label={t("cancel")}
              variant={"outline-primary"}
              size="small"
              onClick={() => setDeleteDisplayed(false)}
            />
            <Button
              label={t("delete_page")}
              variant={"danger"}
              icon={DeleteForeverRounded}
              size="small"
              onClick={() => handleDelete(pageToDelete)}
            />
          </div>
        </div>
      )}
    </>
  );
}
