import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import { ReactComponent as NavLinksRegular } from "../../../img/NavLinksRegular.svg";
import { ReactComponent as NavLinkswithEmphasis } from "../../../img/NavLinkswithEmphasis.svg";

import styles from "./NavStyleProperty.module.scss";
import { useTranslation } from "react-i18next";

export class NavStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <NavStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    return new NavStyleProperty(value);
  }
}

const NavStylePropertyComponent: PropertyComponent<NavStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const radios = [
    { name: t("dialog_37"), value: "1", icon: <NavLinksRegular /> },
    { name: t("dialog_38"), value: "2", icon: <NavLinkswithEmphasis /> },
  ];

  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  return (
    <div className={styles["medium-padded-container"]}>
      <div className="form__group">
        <div className={styles["instructions-container"]}>{t("dialog_36")}</div>
        <ButtonGroup className={`mb-2 ${styles["row-select-btn-grp"]}`}>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              type="radio"
              variant="primary"
              name="style"
              value={radio.value}
              checked={style === radio.value}
              className={`button-radio-hidden ${styles["row-select-btn"]}`}
              onClick={(e: any) => handleStyleChange(e.target.value)}
            >
              <label>{radio.name}</label>
              {radio.icon}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
};
