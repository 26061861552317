import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { ColorResult, ChromePicker } from "react-color";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";

import styles from "./BackgroundFilterProperty.module.scss";
import "./BackgroundFilterProperty.scss";

import { Container, Form } from "react-bootstrap";
import { Range } from "react-range";
import Toggle from "react-toggle";
import { useTranslation } from "react-i18next";

export class BackgroundFilterProperty implements IProperty {
  constructor(
    readonly colorResult?: ColorResult | null,
    readonly color?: string | null,
    readonly opacity?: number | null,
    readonly colorResult2?: ColorResult | null,
    readonly color2?: string | null,
    readonly opacity2?: number | null,
    readonly isUp?: boolean | null
  ) {}

  render(elementId: string, propertyKey: string) {
    return (
      <BackgroundFilterPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): BackgroundFilterProperty {
    if (value)
      return new BackgroundFilterProperty(
        value.colorResult,
        value.color,
        value.opacity,
        value.colorResult2,
        value.color2,
        value.opacity2,
        value.isUp
      );
    return new BackgroundFilterProperty(null, null, 0, null, null, 0, null);
  }
}

const BackgroundFilterPropertyComponent: PropertyComponent<BackgroundFilterProperty> = ({
  elementId,
  propertyKey,
  colorResult,
  color,
  opacity,
  colorResult2,
  color2,
  opacity2,
  isUp,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [opacityRangeValue, setOpacityRangeValue] = useState([
    opacity ? opacity : 0.0,
  ]);
  const [opacity2RangeValue, setOpacity2RangeValue] = useState([
    opacity2 ? opacity2 : 0.0,
  ]);

  const [stateColorResult, setStateColorResult] = useState(colorResult);
  const [stateColor, setStateColor] = useState(color);
  const [stateOpacity, setStateOpacity] = useState(opacity);
  const [stateColorResult2, setStateColorResult2] = useState(colorResult2);
  const [stateColor2, setStateColor2] = useState(color2);
  const [stateOpacity2, setStateOpacity2] = useState(opacity2);
  const [stateIsUp, setStateIsUp] = useState(isUp);

  const handleChangeComplete = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStateColorResult(color);
    setStateColor(generateRGBA(color, stateOpacity));
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BackgroundFilterProperty(
          color,
          generateRGBA(color, stateOpacity),
          stateOpacity,
          stateColorResult2,
          stateColor2,
          stateOpacity2,
          stateIsUp
        )
      )
    );
  };

  const handleChangeComplete2 = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStateColorResult2(color);
    setStateColor2(generateRGBA(color, stateOpacity2));
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BackgroundFilterProperty(
          stateColorResult,
          stateColor,
          stateOpacity,
          color,
          generateRGBA(color, stateOpacity2),
          stateOpacity2,
          stateIsUp
        )
      )
    );
  };

  const handleOpacityRangeChange = (values: number[]) => {
    const shadowVal = values[0].toString();
    setStateColor(generateRGBA(stateColorResult, parseFloat(shadowVal)));
    setStateOpacity(parseFloat(shadowVal));
    setOpacityRangeValue(values);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BackgroundFilterProperty(
          stateColorResult,
          generateRGBA(stateColorResult, parseFloat(shadowVal)),
          parseFloat(shadowVal),
          stateColorResult2,
          stateColor2,
          stateOpacity2,
          stateIsUp
        )
      )
    );
  };

  const handleFinalOpacity2RangeChange = (values: number[]) => {
    handleOpacity2RangeChange(values);
  };

  const handleOpacity2RangeChange = (values: number[]) => {
    const shadowVal = values[0].toString();
    setStateColor2(generateRGBA(stateColorResult2, parseFloat(shadowVal)));
    setStateOpacity2(parseFloat(shadowVal));
    setOpacity2RangeValue(values);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BackgroundFilterProperty(
          stateColorResult,
          stateColor,
          stateOpacity,
          stateColorResult2,
          generateRGBA(stateColorResult2, parseFloat(shadowVal)),
          parseFloat(shadowVal),
          stateIsUp
        )
      )
    );
  };

  const handleFinalOpacityRangeChange = (values: number[]) => {
    handleOpacityRangeChange(values);
  };

  const handleApplyFilter = () => {
    const currApplyFilter = color ? false : true;
    if (currApplyFilter) {
      const defaultColorResult: ColorResult = {
        hex: "#000000",
        hsl: {
          a: 1,
          h: 249.99999999999994,
          l: 0,
          s: 0,
        },
        rgb: {
          a: 1,
          b: 0,
          g: 0,
          r: 0,
        },
      };

      setStateColorResult(defaultColorResult);
      setStateColor(generateRGBA(defaultColorResult, 0.4));
      setStateOpacity(0.4);
      setStateColorResult2(null);
      setStateColor2(null);
      setStateOpacity2(null);
      setOpacityRangeValue([0.4]);
      setStateIsUp(true);
      dispatch(
        propertySet(
          elementId,
          propertyKey,
          new BackgroundFilterProperty(
            defaultColorResult,
            generateRGBA(defaultColorResult, 0.4),
            0.4,
            null,
            null,
            null,
            true
          )
        )
      );
    } else {
      setStateColorResult(null);
      setStateColor(null);
      setStateOpacity(null);
      setStateColorResult2(null);
      setStateColor2(null);
      setStateOpacity2(null);
      dispatch(
        propertySet(
          elementId,
          propertyKey,
          new BackgroundFilterProperty(null, null, null, null, null, null, null)
        )
      );
    }
  };

  const handleApplyTwoTone = () => {
    const currApplyFilter = color2 ? false : true;
    if (currApplyFilter) {
      const defaultColorResult: ColorResult = {
        hex: "#000000",
        hsl: {
          a: 1,
          h: 249.99999999999994,
          l: 0,
          s: 0,
        },
        rgb: {
          a: 1,
          b: 0,
          g: 0,
          r: 0,
        },
      };
      setStateColorResult2(defaultColorResult);
      setStateColor2(generateRGBA(defaultColorResult, 0.4));
      setStateOpacity2(0.4);
      dispatch(
        propertySet(
          elementId,
          propertyKey,
          new BackgroundFilterProperty(
            stateColorResult,
            generateRGBA(stateColorResult, stateOpacity),
            stateOpacity,
            defaultColorResult,
            generateRGBA(defaultColorResult, 0.4),
            0.4,
            true
          )
        )
      );
    } else {
      setStateColorResult2(null);
      setStateColor2(null);
      setStateOpacity2(null);
      dispatch(
        propertySet(
          elementId,
          propertyKey,
          new BackgroundFilterProperty(
            stateColorResult,
            generateRGBA(stateColorResult, stateOpacity),
            stateOpacity,
            null,
            null,
            null,
            null
          )
        )
      );
    }
  };

  const handleDirection = (isUp: boolean) => {
    setStateIsUp(isUp);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BackgroundFilterProperty(
          stateColorResult,
          generateRGBA(stateColorResult, stateOpacity),
          stateOpacity,
          stateColorResult2,
          generateRGBA(stateColorResult2, stateOpacity2),
          stateOpacity2,
          isUp
        )
      )
    );
  };

  const generateRGBA = (
    colorResult?: ColorResult | null,
    opacity?: number | null
  ): string => {
    if (colorResult && opacity) {
      return `rgba(${colorResult.rgb.r}, ${colorResult.rgb.g}, ${colorResult.rgb.b}, ${opacity})`;
    } else {
      return "rgba(0, 0, 0, 0.4)";
    }
  };

  return (
    <div className={`${styles["palette-container"]}`}>
      <Container
        className={`${styles["swatch-container"]}`}
        style={{ paddingBottom: 20, paddingTop: 20 }}
      >
        <div className="form__group">
          <div className="medium-padded-container">
            <div className="pickers-container">
              <div className="picker-container">
                <div style={{ width: "50%", padding: "12px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "12px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        marginRight: "18px",
                      }}
                    >
                      {t("dialog_60")}
                    </label>
                    <Toggle
                      id="cheese-status"
                      defaultChecked={stateColor ? true : false}
                      onClick={handleApplyFilter}
                    />
                  </div>
                  <div
                    style={{
                      display: stateColor ? "flex" : "none",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "12px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        marginRight: "18px",
                      }}
                    >
                      {t("dialog_61")}
                    </label>
                    <Range
                      disabled={color ? false : true}
                      step={0.1}
                      min={0}
                      max={1}
                      values={opacityRangeValue}
                      onChange={
                        (values) => handleOpacityRangeChange(values)
                        // () => { }
                      }
                      onFinalChange={(values) =>
                        handleFinalOpacityRangeChange(values)
                      }
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          className={`${styles["range-tracker"]}`}
                          style={{
                            ...props.style,
                            height: "4px",
                            width: "100%",
                            backgroundColor: "#cfcfcf",
                            borderRadius: "4px",
                            boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          className={`${styles["range-thumb"]}`}
                          style={{
                            ...props.style,
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#319560",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div style={{ display: stateColor ? "flex" : "none" }}>
                    <ChromePicker
                      disableAlpha={true}
                      color={stateColorResult?.hex}
                      onChange={(
                        color: ColorResult,
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleChangeComplete(color, event);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    padding: "12px",
                    display: stateColor ? "flex" : "none",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "12px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        marginRight: "18px",
                      }}
                    >
                      {t("dialog_62")}
                    </label>
                    <Toggle
                      id="cheese-status"
                      defaultChecked={stateColor2 ? true : false}
                      onClick={handleApplyTwoTone}
                    />
                  </div>
                  <div
                    style={{
                      display: stateColor2 ? "flex" : "none",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "12px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        marginRight: "18px",
                      }}
                    >
                      {t("dialog_61")}
                    </label>
                    <Range
                      step={0.1}
                      min={0}
                      max={1}
                      values={opacity2RangeValue}
                      onChange={(values) => handleOpacity2RangeChange(values)}
                      onFinalChange={(values) =>
                        handleFinalOpacity2RangeChange(values)
                      }
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          className={`${styles["range-tracker"]}`}
                          style={{
                            ...props.style,
                            height: "4px",
                            width: "100%",
                            backgroundColor: "#cfcfcf",
                            borderRadius: "4px",
                            boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          {children}
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          className={`${styles["range-thumb"]}`}
                          style={{
                            ...props.style,
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#319560",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div style={{ display: stateColor2 ? "flex" : "none" }}>
                    <ChromePicker
                      disableAlpha={true}
                      color={stateColorResult2?.hex}
                      onChange={(
                        color: ColorResult,
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleChangeComplete2(color, event);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: stateColor2 ? "flex" : "none",
                      width: "100%",
                    }}
                  >
                    <Form style={{ width: "100%" }}>
                      <div key={`inline-radio`}>
                        <label
                          style={{
                            fontSize: "14px",
                            color: "gray",
                            marginRight: "18px",
                          }}
                        >
                          {t("dialog_63")}{" "}
                        </label>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Form.Check
                            inline
                            label={t("dialog_64")}
                            name="group1"
                            type={"radio"}
                            id={`inline-radio-1`}
                            checked={
                              stateIsUp && stateIsUp === true ? true : false
                            }
                            onChange={(e: any) => {
                              // e.target.checked will return true or false if checkbox is checked
                              e.target.checked
                                ? handleDirection(true)
                                : handleDirection(false);
                            }}
                          />
                          <Form.Check
                            inline
                            label={t("dialog_65")}
                            name="group1"
                            type={"radio"}
                            id={`inline-radio-2`}
                            checked={
                              stateIsUp && stateIsUp === true ? false : true
                            }
                            onChange={(e: any) => {
                              // e.target.checked will return true or false if checkbox is checked
                              e.target.checked
                                ? handleDirection(false)
                                : handleDirection(true);
                            }}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
