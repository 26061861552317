import { ShortTextRounded } from "@material-ui/icons";
import { leadElementKey, LeadProperties } from "common/elements/base/Lead";
import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import React from "react";
import { elementDefinitionRegistry } from "../ElementDefinitionRegistry";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import {
  DimensionProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "editor/properties";

export class LeadElement {
  static readonly elementKey = leadElementKey;
  static readonly label = "element_lead_label";
  static readonly icon = ShortTextRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_lead_description";
  static readonly elementPreviewUrl =
    "/img/add-lead-element-preview-default.svg";
  static readonly dialogPositionStatic = false;
  static readonly isDialogDisplay = false;
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<LeadProperties> = {
    leadStyle: LeadStyleProperty,
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <LeadPropertiesComponent element={element} />;
  }

  static create(properties: {
    leadStyle: LeadStyleProperty;
    richTextProperty: RichTextProperty;
    rteAlignment: RTEAlignmentProperty;
    dimension?: DimensionProperty;
  }) {
    return {
      ...properties,
      elementKey: this.elementKey,
    };
  }
}

const LeadPropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const renderer = new PropertyRenderer<typeof LeadElement.properties>(
    LeadElement.properties,
    element
  );
  return <>{renderer.render("richTextProperty")}</>;
};

elementDefinitionRegistry.register(LeadElement);
