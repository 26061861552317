import React, { useEffect, useState } from "react";
import { Container, Col, Row, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getCountry, getCountryCode } from "../admin/utils-country";
import { getRequestIsInProgress } from "../api/selectors";
import {
  fetchCurrentUser,
  signUp,
  fetchUser,
  logActivity,
} from "./authActions";
import { SignUpForm, SignUpFormValues } from "./SignUpForm";
import {
  getUserId,
  getUserWebsiteSlug,
  getUsernameValidity,
} from "./selectors";

import { Button } from "editor/components/base/Button/Button";

import styles from "./Auth.module.scss";
import Footer from "./Footer";
import { loginPage } from "./Login";

import { tawkWidgetToggle } from "../admin/Tawk";

import { useTranslation } from "react-i18next";

export const signupPage = "Sign-up";

export const SignUp: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const userId = useSelector(getUserId);
  const isUsernameValid = useSelector(getUsernameValidity);
  const userWebsiteSlug = useSelector(getUserWebsiteSlug);
  const isRequestInProgress = useSelector(getRequestIsInProgress);
  const [country, setCountry] = useState();
  const [countryCode, setCountryCode] = useState();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    tawkWidgetToggle(false);
  }, []);

  useEffect(() => {
    dispatch(logActivity("VIEW", signupPage, undefined));
    const wauidDate = localStorage.getItem("wauid");
    if (wauidDate) {
      if (new Date(wauidDate) > new Date()) {
        dispatch(fetchCurrentUser());
      } else {
        localStorage.removeItem("wauid");
      }
    } else {
      localStorage.removeItem("wauid");
    }
  }, [dispatch]);

  useEffect(() => {
    const countryCode = getCountryCode();
    const country = getCountry(countryCode);

    setCountryCode(countryCode);
    setCountry(country);
  }, []);

  const onSubmit = (values: SignUpFormValues) => {
    if (
      values.username &&
      values.password &&
      values.firstName &&
      values.lastName &&
      !isSubmitClicked
    ) {
      dispatch(
        signUp(
          values.username,
          values.password,
          values.firstName,
          values.lastName,
          countryCode,
          country
        )
      );
      dispatch(logActivity("SIGN-UP", signupPage, values.username));
      setIsSubmitClicked(true);
    }
  };

  const validateUsername = (username: string) => {
    dispatch(fetchUser(username));
  };

  if (userId && !userWebsiteSlug) {
    return <Redirect to="/admin/select" />;
  } else if (userId && userWebsiteSlug) {
    return <Redirect to={`/admin`} />;
  } else {
    return (
      <Container className={styles["auth__container-signup"]}>
        <div className={styles["auth__navbar-container"]}>
          <Navbar className={styles["auth__navbar"]}>
            <Navbar.Brand href="#">
              <img
                alt="goodweb logo"
                src="/img/goodweb-fullmarkup-b-white.svg"
              />
            </Navbar.Brand>
            <Button
              label={t("login_page_status2")}
              isFlat={true}
              to="/login"
              variant="white-outline"
              onClick={() => {
                dispatch(
                  logActivity("GO TO", signupPage, undefined, loginPage)
                );
              }}
            />
          </Navbar>
        </div>
        <Row className={styles["auth__content-row"]}>
          <Col className={styles["auth__content-col"]}>
            <img
              alt="goodweb logo"
              src="/img/goodweb-fullmarkup-b-white-2.svg"
            />
            <SignUpForm
              onSubmit={onSubmit}
              isSubmitting={isRequestInProgress}
              validateUsername={validateUsername}
              isUsernameValid={
                !isUsernameValid ? "false" : isUsernameValid.toString()
              }
              username={userId}
              websiteSlug={userWebsiteSlug}
            />
            <Footer />
          </Col>
        </Row>
      </Container>
    );
  }
};
