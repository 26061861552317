import { UndoOutlined, RedoOutlined } from "@material-ui/icons";
import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";

import styles from "./UndoRedoToolbar.module.scss";

type Props = {
  undoLayout?: () => void;
  redoLayout?: () => void;
  handleSavePage?: () => void;
  canUndo?: boolean;
  canRedo?: boolean;
};
export const UndoRedoToolbar: React.FunctionComponent<Props> = ({
  undoLayout,
  redoLayout,
  handleSavePage,
  canUndo,
  canRedo,
}) => {
  return (
    <Navbar className={styles["editor-undo-redo-toolbar"]}>
      <Container>
        <div className={styles["editor-undo-redo-toolbar-button-container"]}>
          <Nav.Link
            className={
              canUndo
                ? `${styles["editor-toolbar-button"]}`
                : `${styles["editor-toolbar-button--disabled"]}`
            }
            onClick={undoLayout}
            onMouseOut={handleSavePage}
          >
            <UndoOutlined className={styles["editor-toolbar-icon"]} />
          </Nav.Link>
          <Nav.Link
            className={
              canRedo
                ? `${styles["editor-toolbar-button"]}`
                : `${styles["editor-toolbar-button--disabled"]}`
            }
            onClick={redoLayout}
            onMouseOut={handleSavePage}
          >
            <RedoOutlined className={styles["editor-toolbar-icon"]} />
          </Nav.Link>
        </div>
      </Container>
    </Navbar>
  );
};
