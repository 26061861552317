import produce from "immer";
import { State } from "store";

import {
  PageWithLatestDetails,
  Page,
  Website,
  Photo,
  GAProperty,
  S3Config,
  ReportAcquisition,
  ReportPageViews,
  Billings,
  Subscription,
  ElementStyle,
  PageVersion,
  ABMockResource,
  DailyReportWeb,
} from "editor/models/types";
import { Layout } from "common/elements/types";
import {
  SharedElementConfiguration,
  SharedElementConfigurationById,
} from "editor/elements/types";
import { Challenge, Test } from "./layout";

export const getWebsitePublishState = (state: State): string =>
  state.editor.website.publishState;

export const getWebsite = (state: State): Website =>
  state.editor.website.website;

export const getWebsiteStatus = (state: State): string =>
  state.editor.website.websiteUpdated;

export const getPhotos = (state: State): Photo[] => state.editor.photos.photos;

export const getPhotosStatus = (state: State): string | null =>
  state.editor.photos.requestState;

export const getCurrentPageSlug = (state: State): string | null =>
  state.editor.editor.pageSlug;

export const getPageUniqueId = (state: State): string | null =>
  state.editor.editor.pageUniqueId;

export const getPageUpdateStatus = (state: State): string | null =>
  state.editor.pageList.state;

export const getPageWithLatestVersion = (
  state: State,
  pageId: number
): Page => {
  const page = state.editor.pageList.pageList[pageId];
  const version = state.editor.latestPageVersionList.pageVersionList[pageId];
  return produce(page, (draft) => {
    draft.version = version.version;
    draft.title = version.title;
    draft.description = version.description;
    draft.isIndex = version.isIndex;
    draft.content = version.content;
    draft.updated = version.created;
    draft.isMaster = version.isMaster;
  });
};

export const getPageWithLatestDetailsList = (
  state: State
): PageWithLatestDetails[] => {
  return Object.values(state.editor.latestPageVersionList.pageVersionList)
    .map((version) => {
      const page = state.editor.pageList.pageList[version.pageId];
      if (!page || !page.isMaster) {
        return null;
      }
      return {
        id: page.id,
        slug: page.slug,
        title: version.title,
        description: version.description,
        isIndex: version.isIndex,
        isMaster: version.isMaster,
      };
    })
    .filter((page): page is PageWithLatestDetails => !!page);
};

export const getInsertedKey = (state: State): string | null =>
  state.editor.layout.insertedKey;

export const getLayout = (state: State): Layout => state.editor.layout.body;

export const getChildrenLayout = (state: State, templateKey: string): Layout =>
  state.editor.layout.childrenBody[templateKey];

export const getAllChildrenLayout = (
  state: State
): { [templateKey: string]: Layout } => state.editor.layout.childrenBody;

export const getTreatmentLayout = (state: State): Layout =>
  state.editor.layout.treamentBody;

export const getTreatmentBodiesForCreate = (
  state: State,
  masterSlug: string
): Layout | null => state.editor.layout.treatmentBodiesForCreate[masterSlug];

export const getCompositeLayout = (state: State): Layout => {
  return produce(
    {
      ...state.editor.layout.body,
      ...state.editor.layout.shared,
    },
    (draft) => {
      // TODO
    }
  );
};

export const getLoadedVersion = (state: State): number | null =>
  state.editor.layout.loadedVersion;

export const getLoadedLayout = (state: State): Layout | null =>
  state.editor.layout.loadedBody;

export const getSharedElementsLayout = (state: State): Layout =>
  state.editor.layout.shared;

export const getSharedElementsConfiguration = (
  state: State
): SharedElementConfigurationById =>
  state.editor.layout.sharedElementConfiguration;

export const getSharedElementConfiguration = (
  state: State,
  elementId: string
): SharedElementConfiguration =>
  state.editor.layout.sharedElementConfiguration[elementId];

export const getSharedElementIds = (state: State): string[] => {
  let sharedElementIds = [];
  for (const elementId in state.editor.layout.shared) {
    if (state.editor.layout.shared[elementId].elementParentId === "root") {
      sharedElementIds.push(elementId);
    }
  }

  return sharedElementIds;
};

export const isElementShared = (state: State, elementId: string): boolean =>
  elementId in state.editor.layout.shared;

export const getAvailablePropertyIds = (state: State): GAProperty[] =>
  state.editor.website.availablePropertyIds;

export const getAllAvailableElementStyles = (
  state: State
): ElementStyle[] | null => state.editor.website.elementStyles;

export const getAllChallengesCollecting = (
  state: State
): ABMockResource[] | null => state.editor.website.challengesCollecting;

export const getUsedPropertyIds = (state: State): GAProperty[] =>
  state.editor.website.usedPropertyIds;

export const getAllUnlinkedWebsites = (state: State): Website[] =>
  state.editor.website.unlinkedWebsites;

export const getAcquisitionReport = (state: State): ReportAcquisition =>
  state.editor.reports.acquisitionReport;

export const getDailyReports = (state: State): DailyReportWeb[] | null =>
  state.editor.reports.dailyReports;

export const getWeeklyReports = (state: State): DailyReportWeb[] | null =>
  state.editor.reports.weeklyReports;

export const getMonthlyReports = (state: State): DailyReportWeb[] | null =>
  state.editor.reports.monthlyReports;

export const getPageViewReport = (state: State): ReportPageViews =>
  state.editor.reports.pageViewReport;

export const getS3config = (state: State): S3Config =>
  state.editor.website.s3Config;

export const getChallenge = (
  state: State,
  pageSlug: string
): Challenge | undefined => state.editor.layout.challenges[pageSlug];

export const getPageTests = (
  state: State,
  pageSlug: string
): Test | undefined => state.editor.layout.tests[pageSlug];

export const getFinishedChallenges = (
  state: State,
  pageSlug: string
): Challenge[] | undefined => state.editor.layout.finishedChallenges[pageSlug];

export const getAllChallenges = (state: State): Challenge[] | null =>
  state.editor.layout.allChallenges;

export const getBillings = (state: State): Billings =>
  state.editor.reports.billings;

export const getSubscription = (state: State): Subscription | null =>
  state.editor.reports.subscription;

export const getPageSiblings = (
  state: State
): { [slug: string]: PageVersion } | null =>
  state.editor.layout.otherPagesVersions;

export const getPageSiblingsSlugs = (state: State): string[] | null =>
  state.editor.layout.otherPageSlugs;
