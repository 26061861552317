import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type YoutubeEmbedProperties = {
  youtubeUrl: string;
  dimension: { width?: number; height?: number };
};

export function YoutubeEmbed({
  properties: { youtubeUrl, dimension },
  className,
  ...props
}: ElementComponentProps<YoutubeEmbedProperties>): JSX.Element {
  return (
    <div
      {...props}
      style={{
        width: dimension && dimension.width ? dimension.width : "100%",
        height: dimension && dimension.height ? dimension.height : "450px",
      }}
      className={className + " rs sp"}
    >
      <iframe
        src={`https://www.youtube.com/embed/${youtubeUrl}`}
        width="100%"
        height="100%"
        data-loading="lazy"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write;
                    encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export const youtubeEmbedElementKey = "youtube";

elementComponentRegistry.register(youtubeEmbedElementKey, YoutubeEmbed);
