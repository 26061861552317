import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Container, Row, Col, Form } from "react-bootstrap";
import * as yup from "yup";

import { IStepProps } from "./Setup";

import styles from "./Admin.module.scss";

import { isDesktopView } from "../admin/utils-country";

import { useTranslation } from "react-i18next";

export type ForkType = "facebook" | "custom" | undefined;

const containerClassName = "radio-container";
const containerClassNameSelected = containerClassName + "-selected";

const schema = yup.object({
  //   siteCreationType: yup.mixed<ForkType>().required(/* TODO: Add a message */),
});

export type SetupStepForkValues = yup.InferType<typeof schema>;

export const SetupStepFork: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
}) => {
  const { t } = useTranslation();
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    handleSaveAndContinue(values); // TODO: Remove this in the future when social integration is to be worked on
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, [handleSaveAndContinue, values]);

  const onSubmit = (values: SetupStepForkValues) => {
    handleSaveAndContinue(values);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <h6>{t("setup_wizard_1")}</h6>
              {t("setup_wizard_2")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form.Group as={isDesktop ? Row : Col}>
                <Col>
                  <Form.Check
                    type="radio"
                    id="category-facebook"
                    className={`${
                      values.siteCreationType === "facebook"
                        ? styles[containerClassNameSelected]
                        : styles[containerClassName]
                    }
                                        ${styles["theme-selector"]}`}
                  >
                    <Form.Check.Input
                      type="radio"
                      name="siteCreationType"
                      value={"facebook"}
                      checked={values.siteCreationType === "facebook"}
                      onChange={handleChange}
                      isValid
                      className={styles["radio"]}
                    />
                    <Form.Check.Label
                      className={`${styles["label-fork"]} ${styles["label-fb"]}`}
                    >
                      <img
                        alt="Facebook"
                        src="/img/facebook-logo.svg"
                        className={styles["radio-image"]}
                      />
                      <span className={styles["radio-desc"]}>
                        {t("setup_wizard_3")}
                      </span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check
                    type="radio"
                    id="category-custom"
                    className={`${
                      values.siteCreationType === "custom"
                        ? styles[containerClassNameSelected]
                        : styles[containerClassName]
                    }
                                        ${styles["theme-selector"]}`}
                  >
                    <Form.Check.Input
                      type="radio"
                      name="siteCreationType"
                      value={"custom"}
                      checked={values.siteCreationType === "custom"}
                      onChange={handleChange}
                      isValid
                      className={styles["radio"]}
                    />
                    <Form.Check.Label
                      className={`${styles["label-fork"]} ${styles["label-skip"]}`}
                    >
                      {t("setup_wizard_4")}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Group>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ borderRadius: 32 }}
              type="submit"
              disabled={values.siteCreationType === undefined}
            >
              {t("setup_page_15")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
