import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type Heading3Properties = {
  heading3Style: { style: string };
  heading3Text: { text: string };
};

export function Heading3({
  properties: { heading3Style, heading3Text },
  className,
  ...props
}: ElementComponentProps<Heading3Properties>): JSX.Element {
  return (
    <h3
      {...props}
      className={className + "h3 heading-3-style-" + heading3Style.style}
    >
      {heading3Text.text}
    </h3>
  );
}

export const heading3ElementKey = "heading-3";

elementComponentRegistry.register(heading3ElementKey, Heading3);
