import React from "react";

export class TextProperty {
  constructor(readonly text: string) {}

  render() {
    return <></>;
  }

  static parse(value: any) {
    return new TextProperty(value);
  }
}
