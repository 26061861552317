import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { ToggleButton, Carousel } from "react-bootstrap";

import { Formik, Form } from "formik";

import styles from "./NavbarStyleProperty.module.scss";
import "./NavbarStyleProperty.scss";
import "./../../../css/jaquen-main.scss";
import { Facebook, Twitter } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

export class NavbarStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <NavbarStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new NavbarStyleProperty(value);
  }
}

const NavbarStylePropertyComponent: PropertyComponent<NavbarStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [imgUrl, setImgUrl] = useState<string>();
  const [isLogoAvailable, setLogoAvailble] = useState(false);

  useEffect(() => {
    const imgElements = document.getElementsByClassName("logo-container__logo");
    if (imgElements && imgElements.length > 0) {
      const imgElement = imgElements.item(0) as HTMLImageElement;
      if (imgElement && imgElement.src) {
        setLogoAvailble(true);
        setImgUrl(imgElement.src);
      }
    }
  }, []);

  const radios = [
    {
      value: "0",
      icon: (
        <div className="navbar-style-option-container">
          <div className="browser">
            <div className="browser-options">
              <div className="browser-option option1"></div>
              <div className="browser-option option2"></div>
              <div className="browser-option option3"></div>
            </div>
            <div className="browser-address"></div>
          </div>
          <div className="jq-navbar mini-navbar navbarstyle-0">
            <div className="navs-container">Menu A &nbsp;&nbsp; Menu B</div>
            <div className="logo-container">
              <img
                className="logo-container__logo"
                alt=""
                src={isLogoAvailable ? imgUrl : "/img/logo-default.png"}
              />
            </div>
            <div className="social-nav style-1">
              <Facebook />
              <Twitter />
            </div>
          </div>
          <div className="fader"></div>
        </div>
      ),
    },
    {
      value: "1",
      icon: (
        <div className="navbar-style-option-container">
          <div className="browser">
            <div className="browser-options">
              <div className="browser-option option1"></div>
              <div className="browser-option option2"></div>
              <div className="browser-option option3"></div>
            </div>
            <div className="browser-address"></div>
          </div>
          <div className="jq-navbar mini-navbar navbarstyle-1">
            <div className="logo-container">
              <img
                className="logo-container__logo"
                alt=""
                src={isLogoAvailable ? imgUrl : "/img/logo-default.png"}
              />
            </div>
            <div className="navs-container">Menu A &nbsp;&nbsp; Menu B</div>
            <div className="social-nav style-1">
              <Facebook />
              <Twitter />
            </div>
          </div>
          <div className="fader"></div>
        </div>
      ),
    },
    {
      value: "2",
      icon: (
        <div className="navbar-style-option-container">
          <div className="browser">
            <div className="browser-options">
              <div className="browser-option option1"></div>
              <div className="browser-option option2"></div>
              <div className="browser-option option3"></div>
            </div>
            <div className="browser-address"></div>
          </div>
          <div className="jq-navbar mini-navbar navbarstyle-2">
            <div className="logo-container">
              <img
                className="logo-container__logo"
                alt=""
                src={isLogoAvailable ? imgUrl : "/img/logo-default.png"}
              />
            </div>
            <div className="navs-container">Menu A &nbsp;&nbsp; Menu B</div>
            <div className="social-nav style-1">
              <Facebook />
              <Twitter />
            </div>
          </div>
          <div className="fader"></div>
        </div>
      ),
    },
    {
      value: "3",
      icon: (
        <div className="navbar-style-option-container">
          <div className="browser">
            <div className="browser-options">
              <div className="browser-option option1"></div>
              <div className="browser-option option2"></div>
              <div className="browser-option option3"></div>
            </div>
            <div className="browser-address"></div>
          </div>
          <div className="jq-navbar mini-navbar navbarstyle-3">
            <div className="logo-container">
              <img
                className="logo-container__logo"
                alt=""
                src={isLogoAvailable ? imgUrl : "/img/logo-default.png"}
              />
            </div>
            <div className="navs-container">Menu A &nbsp;&nbsp; Menu B</div>
            <div className="social-nav style-1">
              <Facebook />
              <Twitter />
            </div>
          </div>
          <div className="fader"></div>
        </div>
      ),
    },
    {
      value: "4",
      icon: (
        <div className="navbar-style-option-container">
          <div className="browser">
            <div className="browser-options">
              <div className="browser-option option1"></div>
              <div className="browser-option option2"></div>
              <div className="browser-option option3"></div>
            </div>
            <div className="browser-address"></div>
          </div>
          <div className="jq-navbar mini-navbar navbarstyle-4">
            <div className="social-nav style-1">
              <Facebook />
              <Twitter />
            </div>
            <div className="navs-container">Menu A &nbsp;&nbsp; Menu B</div>
            <div className="logo-container">
              <img
                className="logo-container__logo"
                alt=""
                src={isLogoAvailable ? imgUrl : "/img/logo-default.png"}
              />
            </div>
          </div>
          <div className="fader"></div>
        </div>
      ),
    },
  ];

  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <Form className="form">
        <div className="form__group">
          <div className={styles["instructions-container"]}>
            {t("dialog_6")}
          </div>
          <Carousel
            interval={null}
            defaultActiveIndex={parseInt(style)}
            onSlide={(e: any) => {
              handleStyleChange(e);
            }}
          >
            {radios.map((radio, idx) => (
              <Carousel.Item
                onClick={(e: any) => handleStyleChange(e.target.value)}
                key={idx}
              >
                <div className={styles["navbar-display-container"]}>
                  <div className={styles["navbar-display"]}>
                    <ToggleButton
                      key={idx}
                      type="radio"
                      variant="primary"
                      name="style"
                      value={radio.value}
                      checked={style === radio.value}
                      className={`button-radio-hidden row-select-btn mid ${styles["carousel-button"]}`}
                      onClick={(e: any) => handleStyleChange(e.target.value)}
                    >
                      {radio.icon}
                    </ToggleButton>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Form>
    </Formik>
  );
};
