import React from "react";
import { useDispatch } from "react-redux";
import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { Carousel } from "react-bootstrap";
import { ReactComponent as SocialNavDefault } from "../../../img/SocialNavDefault.svg";
import { ReactComponent as SocialNavDefaultMono } from "../../../img/SocialNavDefaultMono.svg";
import { ReactComponent as SocialNavBlocks } from "../../../img/SocialNavBlocks.svg";
import { ReactComponent as SocialNavEncircled } from "../../../img/SocialNavEncircled.svg";
import { ReactComponent as SocialNavText } from "../../../img/SocialNavText.svg";
import styles from "./SocialLinkStyleProperty.module.scss";
import { useTranslation } from "react-i18next";

export class SocialLinkStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <SocialLinkStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new SocialLinkStyleProperty(value);
  }
}

const SocialLinkStylePropertyComponent: PropertyComponent<SocialLinkStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const radios = [
    {
      value: "0",
      icon: (
        <div className="logo-display-container">
          <SocialNavDefault />
        </div>
      ),
    },
    {
      value: "1",
      icon: (
        <div className="logo-display-container">
          <SocialNavDefaultMono />
        </div>
      ),
    },
    {
      value: "2",
      icon: (
        <div className="logo-display-container">
          <SocialNavBlocks />
        </div>
      ),
    },
    {
      value: "3",
      icon: (
        <div className="logo-display-container">
          <SocialNavEncircled />
        </div>
      ),
    },
    {
      value: "4",
      icon: (
        <div className="logo-display-container">
          <SocialNavText />
        </div>
      ),
    },
  ];
  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  return (
    <div className="form__group">
      <div className={styles["instructions-container"]}>{t("dialog_41")}</div>
      <Carousel
        interval={null}
        defaultActiveIndex={parseInt(style)}
        onSlide={(e: any) => {
          handleStyleChange(e + "");
        }}
      >
        {radios.map((radio, idx) => (
          <Carousel.Item
            onClick={(e: any) => handleStyleChange(radio.value)}
            key={idx}
          >
            {radio.icon}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
