import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getPrimaryColor,
  getSecondaryColor,
  getTernaryColor,
} from "editor/selectors";
import "./ColorSwatch.scss";
import { useTranslation } from "react-i18next";

type Props = {
  handleColorClick: (color: string, e: any) => void;
  isShadowed: boolean;
};

export default function ColorSwatch({
  handleColorClick,
  isShadowed,
}: Props): JSX.Element {
  const { t } = useTranslation();
  var tinycolor = require("tinycolor2");
  const statePrimaryColor = useSelector(getPrimaryColor);
  const stateSecondaryColor = useSelector(getSecondaryColor);
  const stateTernaryColor = useSelector(getTernaryColor);
  const [primaryColorsRange, setPrimaryColorsRange] = useState<string[]>([
    "#fff",
  ]);
  const [secondaryColorsRange, setSecondaryColorsRange] = useState<string[]>([
    "#fff",
  ]);
  const [ternaryColorsRange, setTernaryColorsRange] = useState<string[]>([
    "#fff",
  ]);
  const [splitComplementsRange, setSplitComplementsRange] = useState<string[]>([
    "#fff",
  ]);
  const defaultColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
    "#35822D",
    "#824F2D",
    "#3E2D82",
    "#822D71",
    "#F1D778",
    "#F17880",
  ];

  useEffect(() => {
    if (statePrimaryColor) {
      setPrimaryColorsRange([
        tinycolor(statePrimaryColor).darken(25).toString(),
        tinycolor(statePrimaryColor).darken(10).toString(),
        statePrimaryColor,
        tinycolor(statePrimaryColor).lighten(10).toString(),
        tinycolor(statePrimaryColor).lighten(25).toString(),
      ]);
      let splitColors = tinycolor(statePrimaryColor).splitcomplement();
      let splitMap = splitColors.map(function (t: any) {
        return t.toHexString();
      });
      splitMap.shift();
      let tetradColors = tinycolor(statePrimaryColor).tetrad();
      let tetradMap = tetradColors.map(function (t: any) {
        return t.toHexString();
      });
      tetradMap.shift();
      setSplitComplementsRange([
        ...splitMap,
        ...tetradMap,
        tinycolor(statePrimaryColor).greyscale().toString(),
        "#fff",
        "#000",
      ]);
    }

    if (stateSecondaryColor) {
      setSecondaryColorsRange([
        tinycolor(stateSecondaryColor).darken(25).toString(),
        tinycolor(stateSecondaryColor).darken(10).toString(),
        stateSecondaryColor,
        tinycolor(stateSecondaryColor).lighten(10).toString(),
        tinycolor(stateSecondaryColor).lighten(25).toString(),
      ]);
    }

    if (stateTernaryColor) {
      setTernaryColorsRange([
        tinycolor(stateTernaryColor).darken(25).toString(),
        tinycolor(stateTernaryColor).darken(10).toString(),
        stateTernaryColor,
        tinycolor(stateTernaryColor).lighten(10).toString(),
        tinycolor(stateTernaryColor).lighten(25).toString(),
      ]);
    }
  }, [statePrimaryColor, stateSecondaryColor, stateTernaryColor, tinycolor]);

  return (
    <div className={isShadowed ? "picker-swatch shadow" : "picker-swatch"}>
      <div
        className="picker-swatch-container"
        style={{ display: "flex", flexWrap: "inherit", width: "173px" }}
      >
        <span className="helper">{t("dialog_7")}</span>
        {[
          ...primaryColorsRange,
          ...secondaryColorsRange,
          ...ternaryColorsRange,
          ...splitComplementsRange,
        ].map((color) =>
          color ? (
            <div
              className="color-pick"
              key={color + Math.random().toString(36).slice(-6)}
              style={{
                backgroundColor: color,
              }}
              onMouseDown={(e) => {
                handleColorClick(color, e);
              }}
            ></div>
          ) : (
            <></>
          )
        )}
      </div>
      <div
        className="picker-swatch-container"
        style={{ display: "flex", flexWrap: "inherit", width: "153px" }}
      >
        <span className="helper">Default</span>
        {[...defaultColors].map((color) =>
          color ? (
            <div
              className="color-pick"
              key={color + Math.random().toString(36).slice(-6)}
              style={{
                backgroundColor: color,
              }}
              onMouseDown={(e) => {
                handleColorClick(color, e);
              }}
            ></div>
          ) : (
            <></>
          )
        )}
      </div>
    </div>
  );
}
