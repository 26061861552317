import { Reducer } from "redux";

import { WebsiteResource } from "@urux/arm-of-dorne-api";
import { IWebsite } from "./models";
import { IWebsiteState, initialWebsiteState } from "./state";
import { Action } from "./websiteActions";

export const websiteReducer: Reducer<IWebsiteState> = (
  state = initialWebsiteState,
  action: Action
) => {
  switch (action.type) {
    case "RESET_WEBSITE_STATE":
      return initialWebsiteState;

    case "CREATE_WEBSITE_SUCCESS":
      return {
        ...state,
        ...mapWebsiteResourceToWebsite(action.website),
      };

    default:
      return state;
  }
};

const mapWebsiteResourceToWebsite = (resource: WebsiteResource): IWebsite => {
  return {
    slug: resource.slug,
    title: resource.title,
    description: resource.description,
  };
};
