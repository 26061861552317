import { AssignmentTurnedInRounded, SaveOutlined } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { getUserWebsiteSlug } from "../../../../auth/selectors";
import { Dialog } from "../../components/Dialog/Dialog";

import styles from "./TestsPropertiesDialog.module.scss";
import TestPropertiesAccordion from "./TestPropertiesAccordion";
import { getPageSlug, getTitle } from "editor/selectors";
import { Challenge, ChallengesDisplay, Test } from "editor/states/layout";
import { Spinner } from "react-bootstrap";
import { getRequestIsInProgress } from "api/selectors";

import { State } from "store";
import { getChallenge, getFinishedChallenges } from "editor/states/selectors";

import { useTranslation } from "react-i18next";

type Props = {
  pageContainer: string;
  isShowTestsPropsDialog: boolean;
  toolBarButtonRect?: DOMRect;
  handleShowTestsPropDialog: () => void;
  cssPos?: React.CSSProperties;
  challenges?: Challenge;
  finishedChallenges?: Challenge[];
  pageTest?: Test;
  isPremiumUser: boolean;
};

export default function TestsPropertiesDialog({
  pageContainer,
  isShowTestsPropsDialog,
  toolBarButtonRect,
  handleShowTestsPropDialog,
  cssPos,
  pageTest,
  isPremiumUser,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const requestInProgress = useSelector(getRequestIsInProgress);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const pageSlug = useSelector(getPageSlug);
  const pageTitle = useSelector(getTitle);
  const challenge = useSelector((state: State) =>
    getChallenge(state, pageSlug!)
  );
  const finishedChallenges = useSelector((state: State) =>
    getFinishedChallenges(state, pageSlug!)
  );

  // Construct Challenges based on element id
  useEffect(() => {
    let challengesToDisplayMap: Map<string, ChallengesDisplay> = new Map();
    let finishedChallengesGroupedByElementId: Map<
      string,
      Challenge[]
    > = new Map();

    if (finishedChallenges) {
      finishedChallenges.forEach((finishedChallenge) => {
        let elementId = finishedChallenge.elementId;
        if (elementId) {
          let challenges = finishedChallengesGroupedByElementId.get(elementId);
          if (challenges && challenges?.length > 0) {
            challenges.push(finishedChallenge);
          } else {
            let challenges: Challenge[] = [];
            challenges.push(finishedChallenge);
            finishedChallengesGroupedByElementId.set(elementId, challenges);
          }
        }
      });

      finishedChallengesGroupedByElementId.forEach(
        (finishedChallengesArray, elementId) => {
          finishedChallengesArray.sort((a, b) => b.challengeId - a.challengeId);
          let challengesDisplay: ChallengesDisplay = {
            elementId: elementId,
            finishedChallenges: finishedChallengesArray,
          };
          challengesToDisplayMap.set(elementId, challengesDisplay);
        }
      );
    }

    if (challenge) {
      let elementId = challenge.elementId;
      if (elementId) {
        let challengeDisplay: ChallengesDisplay;
        if (challengesToDisplayMap.get(elementId)) {
          challengeDisplay = {
            elementId: elementId,
            finishedChallenges: challengesToDisplayMap.get(elementId)
              ?.finishedChallenges,
            currentChallenge: challenge,
          };
        } else {
          challengeDisplay = {
            elementId: elementId,
            currentChallenge: challenge,
          };
        }
        challengesToDisplayMap.set(elementId, challengeDisplay);
      }
    }
  }, [challenge, finishedChallenges]);

  if (!isShowTestsPropsDialog || !websiteSlug) {
    return <></>;
  }

  const TestsPropertiesDialogBody = (
    <>
      <div className={styles["pages-list"]}>
        <TestPropertiesAccordion
          handleShowTestsPropDialog={handleShowTestsPropDialog}
          websiteSlug={websiteSlug}
          pageSlug={pageSlug}
          pageContainer={pageContainer}
          challenge={challenge}
          finishedChallenges={finishedChallenges}
          disableApply={false}
          pageTest={pageTest}
          isPremiumUser={isPremiumUser}
        />
      </div>
      <div className={styles["dialog-action-notification"]}>
        {requestInProgress ? (
          <>
            <Spinner
              size="sm"
              animation="border"
              variant="secondary"
              className={styles["saving-icon"]}
            />{" "}
            {t("Saving")}...
          </>
        ) : (
          <>
            <SaveOutlined />
            {t("Changes_Saved")}
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      <Dialog
        dialogBody={TestsPropertiesDialogBody}
        icon={AssignmentTurnedInRounded}
        headerLabel={t("tests_for") + pageTitle}
        handleShowDialog={handleShowTestsPropDialog}
        isShowDialog={isShowTestsPropsDialog}
        isGrabbable={false}
        elementPos={toolBarButtonRect}
        size="large"
        isShowBg
        cssPosition={cssPos}
      />
    </>
  );
}
