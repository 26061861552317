import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type SectionHeaderProperties = {
  sectionHeaderStyle: string;
  dimension: { width?: number; height?: number };
};

export function SectionHeader({
  properties: { sectionHeaderStyle, dimension },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<SectionHeaderProperties>): JSX.Element {
  return (
    <div
      {...props}
      ref={innerRef}
      className={`
        ${className} section-header section-header-style-${sectionHeaderStyle} fp cd-df-dg`}
      style={{
        width: dimension && dimension.width ? dimension.width : "",
        height: dimension && dimension.height ? dimension.height : "",
      }}
    >
      {children}
    </div>
  );
}

export const sectionHeaderKey = "section-header";

elementComponentRegistry.register(sectionHeaderKey, SectionHeader);
