import React from "react";
import { useDispatch } from "react-redux";
import { IProperty, PropertyComponent } from "./types";
import { propertySet } from "editor/states/layout";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

export class LogoDecorationProperty implements IProperty {
  constructor(readonly decoration: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <LogoDecorationPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new LogoDecorationProperty(value);
  }
}

const LogoDecorationPropertyComponent: PropertyComponent<LogoDecorationProperty> = ({
  elementId,
  propertyKey,
  decoration,
}) => {
  const dispatch = useDispatch();

  const radios = [
    { name: "Underlined", value: "1" },
    { name: "Italicized", value: "2" },
    { name: "Crossed", value: "3" },
    { name: "None", value: "0" },
  ];

  const handledecorationChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  return (
    <div className="form__group">
      <label htmlFor="decoration">Logo Decoration</label>
      <ButtonGroup className="mb-2 logo-decoration-group">
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="primary"
            name="decoration"
            value={radio.value}
            checked={decoration === radio.value}
            className="button-radio-hidden"
            onClick={(e: any) => handledecorationChange(e.target.value)}
          >
            <span className={"logo-prop logo-deco-" + radio.value}>sample</span>
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
};
