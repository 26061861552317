import { createApi } from "unsplash-js";

export const fetchPhotosAsync = async (
  queryString: string,
  key: string
): Promise<any> => {
  const api = createApi({
    // Don't forget to set your access token here!
    // See https://unsplash.com/developers
    accessKey: key,
  });
  return await api.search.getPhotos({
    query: queryString,
    orientation: "landscape",
  });
};
