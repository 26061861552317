import React, { useState } from "react";
import { Form, FormCheck, Tab, Tabs } from "react-bootstrap";

import TextFieldGroup from "../../../components/components/TextFieldGroup/TextFieldGroup";
import { ItemProps, Dropdown } from "editor/components/base/Dropdown/Dropdown";
import { useSelector } from "react-redux";
import {
  getCompositeLayout,
  getPageWithLatestDetailsList,
} from "editor/states/selectors";

import "./LinkPropertyEditor.scss";
import styles from "./LinkPropertyEditor.module.scss";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

type Props = {
  text: string;
  url: string;
  isAnchor: boolean;
  isExternalLink: boolean;
  openNewTab?: boolean;
  handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePageSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSectionSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUrlChangeSave: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenNewTabChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedUrl: string;
  isAdding?: boolean;
  elementId: string;
  urlInvalid: boolean;
};

export const LinkPropertyEditor: React.FunctionComponent<Props> = ({
  text,
  url,
  openNewTab,
  isAnchor,
  isExternalLink,
  isAdding,
  handleTextChange,
  handlePageSelect,
  handleSectionSelect,
  handleUrlChange,
  handleUrlChangeSave,
  handleOpenNewTabChange,
  selectedUrl,
  elementId,
  urlInvalid,
}) => {
  const { t } = useTranslation();

  const sectionsElementKey = [
    "navbar",
    "cover",
    "cards",
    "articles",
    "contact",
    "footer",
  ];

  const [selectedPage, setSelectedPage] = useState(text);

  const pageList = useSelector(getPageWithLatestDetailsList);

  const dropdownPageItems = pageList.map(
    (page): ItemProps => ({
      key: page.id,
      eventKey: page.slug,
      itemLabel: page.title,
      isActive: url === "/" + page.slug ? true : false,
      onClickOfItem: () => handleChangeDropdownTitle(page.title),
    })
  );

  let elementsIdKey: ItemProps[] = [];
  const layout = useSelector(getCompositeLayout);
  for (const layoutElementId in layout) {
    const element = layout[layoutElementId];
    if (sectionsElementKey.includes(element.elementKey)) {
      elementsIdKey.push({
        key: layoutElementId,
        itemLabel: element.elementKey,
        eventKey: layoutElementId,
        isActive: selectedUrl === "#" + layoutElementId ? true : false,
        onClickOfItem: () => handleChangeDropdownTitle(element.elementKey),
      });
    }
  }

  const handleChangeDropdownTitle = (title: string) => {
    setSelectedPage(title);
  };

  let tempPageName = selectedPage;
  if (isAdding) {
    if (isAnchor) {
      elementsIdKey.forEach((page) => {
        if ("#" + page.key === url) {
          tempPageName = page.itemLabel;
        }
      });
    } else if (!isExternalLink) {
      tempPageName = selectedPage;
    } else {
      tempPageName = url;
    }
  } else if (isAnchor) {
    elementsIdKey.forEach((page) => {
      if ("#" + page.key === url) {
        tempPageName = page.itemLabel;
      }
    });
  } else if (!isExternalLink) {
    let isMapped = false;
    pageList.forEach((page) => {
      if ("/" + page.slug === url) {
        tempPageName = page.title;
        isMapped = true;
      }
    });
    if (!isMapped) {
      tempPageName = "";
    }
  } else {
    tempPageName = url;
  }

  let defaultKey;
  if (isAnchor) {
    defaultKey = "section";
  } else if (isExternalLink) {
    defaultKey = "url";
  } else {
    defaultKey = "page";
  }

  const currentElement = layout[elementId];
  const parentElement =
    layout[
      currentElement.elementParentId === undefined
        ? ""
        : currentElement.elementParentId
    ];

  return parentElement.elementKey === "form-container" ? (
    <div className={styles["form__group"]}>
      <Formik initialValues={{ name: "" }} onSubmit={() => {}}>
        <Form className="form">
          <TextFieldGroup
            name="text"
            label={t("dialog_25")}
            value={text}
            onChange={handleTextChange}
            placeholder="Ex. Contact us"
          />
        </Form>
      </Formik>
    </div>
  ) : (
    <div className={styles["form__group"]}>
      <Formik initialValues={{ name: "" }} onSubmit={() => {}}>
        <Form className="form">
          <TextFieldGroup
            name="text"
            label={t("dialog_25")}
            value={text}
            onChange={handleTextChange}
            placeholder={t("dialog_53")}
          />
          <div
            style={{
              marginBottom: "4px",
              marginTop: "8px",
              fontSize: "1rem",
              fontFamily: "Nunito",
              color: "#808a93",
            }}
          >
            {t("dialog_26")}
          </div>
          <Tabs
            id="link-property"
            defaultActiveKey={defaultKey}
            className={`link-property-tabs ${styles["link-property-tabs"]}`}
          >
            <Tab title={t("dialog_27")} eventKey="page">
              <Dropdown
                items={dropdownPageItems}
                title={
                  isExternalLink || isAnchor || tempPageName === ""
                    ? t("dialog_28")
                    : tempPageName
                }
                onSelect={handlePageSelect}
              />
            </Tab>
            <Tab title={t("dialog_29")} eventKey="section">
              <Dropdown
                items={elementsIdKey}
                title={!isAnchor ? t("dialog_30") : tempPageName}
                onSelect={handleSectionSelect}
              />
            </Tab>
            <Tab title="URL" eventKey="url">
              <TextFieldGroup
                name="url"
                label={t("dialog_32")}
                value={isExternalLink ? tempPageName : ""}
                onBlur={handleUrlChangeSave}
                onChange={handleUrlChange}
                placeholder={t("dialog_33")}
              />
              {urlInvalid === false ? (
                <span style={{ color: "red" }}>{t("dialog_54")}</span>
              ) : (
                <></>
              )}
            </Tab>
          </Tabs>
          <FormCheck className={styles["open-new-tab"]}>
            <FormCheck.Label>
              <FormCheck.Input
                type="checkbox"
                onChange={handleOpenNewTabChange}
                checked={openNewTab}
              />
              {t("dialog_34")}
            </FormCheck.Label>
          </FormCheck>
        </Form>
      </Formik>
    </div>
  );
};
