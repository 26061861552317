export type StartRequestAction = {
  type: "API_START_REQUEST";
};

export const startRequest = (): Action => {
  return {
    type: "API_START_REQUEST",
  };
};

export type ResetAuthErrorRequestAction = {
  type: "API_AUTH_RESET_ERROR_REQUEST";
};

export const resetAuthErrorRequest = (): Action => {
  return {
    type: "API_AUTH_RESET_ERROR_REQUEST",
  };
};

export type FinishRequestAction = {
  type: "API_FINISH_REQUEST";
};

export const finishRequest = (): Action => {
  return {
    type: "API_FINISH_REQUEST",
  };
};

export type FinishRequestWithErrorsAction = {
  type: "API_FINISH_REQUEST_WITH_ERRORS";
  error: string;
};

export const finishRequestWithErrors = (error: string): Action => {
  return {
    type: "API_FINISH_REQUEST_WITH_ERRORS",
    error,
  };
};

export type AuthStartRequestAction = {
  type: "API_AUTH_START_REQUEST";
};

export const authStartRequest = (): Action => {
  return {
    type: "API_AUTH_START_REQUEST",
  };
};

export type AuthFinishRequestAction = {
  type: "API_AUTH_FINISH_REQUEST";
};

export const authFinishRequest = (): Action => {
  return {
    type: "API_AUTH_FINISH_REQUEST",
  };
};

export type AuthFinishRequestWithErrorsAction = {
  type: "API_AUTH_FINISH_REQUEST_WITH_ERRORS";
  error: string;
};

export const authFinishRequestWithErrors = (error: string): Action => {
  return {
    type: "API_AUTH_FINISH_REQUEST_WITH_ERRORS",
    error,
  };
};

export type Action =
  // This is only needed for Typescript type check to pass. There has to be an
  // action with just the type property, and nothing else.
  | { type: "_" }
  | StartRequestAction
  | FinishRequestAction
  | FinishRequestWithErrorsAction
  | AuthStartRequestAction
  | AuthFinishRequestAction
  | AuthFinishRequestWithErrorsAction
  | ResetAuthErrorRequestAction;
