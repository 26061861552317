import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IProperty, PropertyComponent } from "../types";
import TextFieldGroup from "editor/components/components/TextFieldGroup/TextFieldGroup";
import { Formik } from "formik";
import { propertySet } from "editor/states/layout";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UnsplashLogo } from "../../../img/UnsplashWordmarkLogo.svg";
import { Button } from "editor/components/base/Button/Button";
import {
  ArrowBackRounded,
  RefreshRounded,
  SearchRounded,
} from "@material-ui/icons";
import styles from "./ImageSourceProperty.module.scss";
import {
  getPhotos,
  getPhotosStatus,
  getS3config,
  getWebsite,
} from "../../../editor/states/selectors";
import {
  resetPhotos,
  getPhotos as getPhotosFromApi,
} from "../../../editor/states/photo";
import { Carousel, Form } from "react-bootstrap";
import { useEffect } from "react";
import { getUnsplashKey, getUserWebsiteSlug } from "auth/selectors";
import { getUnplashKey } from "auth/authActions";
import { uploadFile } from "admin/S3Client";

import { urltoFile } from "../../../admin/image-utils";
import { useTranslation } from "react-i18next";

var mime = require("mime-types");

export class ImageSourceProperty implements IProperty {
  constructor(readonly imageUrl: string | undefined) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <ImageSourcePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): ImageSourceProperty {
    return new ImageSourceProperty(value);
  }
}

const ImageSourcePropertyComponent: PropertyComponent<ImageSourceProperty> = ({
  elementId,
  propertyKey,
  imageUrl,
}) => {
  const { t } = useTranslation();
  const [imgStrUrl, setImgStrUrl] = useState<string | undefined>(imageUrl);
  const [queryString, setQueryString] = useState("");
  const photos = useSelector(getPhotos);
  const photoQueryState = useSelector(getPhotosStatus);
  const unsplashKey = useSelector(getUnsplashKey);
  const s3ConfigState = useSelector(getS3config);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const dispatch = useDispatch();

  useEffect(() => {
    if (unsplashKey === undefined) {
      dispatch(getUnplashKey());
    }
  }, [dispatch, unsplashKey]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("File reading was aborted");
        reader.onerror = () => console.log("File reading has failed");
        reader.onload = () => {
          const imgStr = reader.result as string;
          let filename = "IMG";
          if (website.title)
            filename =
              filename +
              "-" +
              website.title.replace(/\s+/g, "-") +
              "-" +
              Math.random().toString(36).slice(-6);

          let fileType = mime.extension(file.type);
          if (!fileType) {
            fileType = "png";
          }

          if (imgStr)
            urltoFile(imgStr, filename, fileType, file.type).then(function (
              file
            ) {
              if (websiteSlug !== undefined) {
                uploadFile(file, websiteSlug, filename, s3ConfigState).then(
                  function (loc) {
                    setImgStrUrl(loc);
                    if (loc !== undefined) {
                      dispatch(propertySet(elementId, propertyKey, loc));
                    }
                  }
                );
              }
            });
        };
        reader.readAsDataURL(file);
        let img = new Image();
        if (imgStrUrl) img.src = imgStrUrl;
      });
    },
    [
      imgStrUrl,
      website.title,
      dispatch,
      elementId,
      propertyKey,
      s3ConfigState,
      websiteSlug,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    onDrop,
  });

  let buttonLabel = "Search";
  let buttonIcon = SearchRounded;
  let isDisabled = false;
  if (photoQueryState === "in-progress") {
    buttonLabel = "Searching";
    buttonIcon = RefreshRounded;
    isDisabled = true;
  }

  const handleReselectClick = () => {
    setQueryString("");
    setImgStrUrl("");
    dispatch(propertySet(elementId, propertyKey, ""));
  };

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryString(e.target.value);
  };

  const handleGetPhotosFromUnsplash = () => {
    const key = unsplashKey === undefined ? "replace-me" : unsplashKey;
    dispatch(getPhotosFromApi(queryString, key));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && queryString !== "") {
      handleGetPhotosFromUnsplash();
    }
  };

  const handleSelectPhotoFromUnsplash = (url: string) => {
    setImgStrUrl(url);
    dispatch(propertySet(elementId, propertyKey, url));
  };

  const handleReselectFromUnsplash = () => {
    dispatch(resetPhotos());
    setQueryString("");
  };

  return (
    <div className="medium-padded-container">
      {imgStrUrl && imgStrUrl !== "" ? (
        <div className={styles["background-preview-container"]}>
          <Button
            label={t("dialog_95")}
            isFlat={true}
            variant="danger"
            size="medium"
            onClick={handleReselectClick}
            style={{ marginBottom: "8px" }}
            icon={ArrowBackRounded}
          />
          <div className={styles["background"]}>
            <img
              src={imgStrUrl}
              alt="Preview"
              className={styles["image-preview"]}
            />
          </div>
          <div className={styles["actions"]}>
            <div className={styles["top-actions"]}></div>
          </div>
        </div>
      ) : photos && photos.length > 0 && photoQueryState === "success" ? (
        <div>
          <Carousel interval={null} defaultActiveIndex={0}>
            {photos.map((photo) => (
              <Carousel.Item key={photo.id}>
                <div
                  className={styles["background-preview-container"]}
                  onClick={() =>
                    handleSelectPhotoFromUnsplash(photo.urls.large)
                  }
                >
                  <div
                    className={styles["background"]}
                    style={{
                      backgroundImage: `url(${photo.urls.small})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPositionX: "center",
                      backgroundPositionY: "center",
                    }}
                  ></div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className={styles["photos-slide-actions"]}>
            Click on image above to use
            <br />
            or
            <br />
            <span onClick={handleReselectFromUnsplash}>
              <ArrowBackRounded /> Search new image
            </span>
          </div>
        </div>
      ) : (
        <Formik initialValues={{}} onSubmit={async (values) => {}}>
          <div className={styles["choice-container"]}>
            <div {...getRootProps()} className={styles["dropzone"]}>
              <input {...getInputProps()} />
              <div className={styles["drop-zone-label-container"]}>
                <p>{t("upload_logo_1")}</p>
                <small>
                  {t("upload_logo_2")} <br />
                  {t("upload_logo_3")}
                </small>
              </div>
            </div>
            <div className={styles["unsplash-container"]}>
              <span className={styles["info-text"]}>{t("upload_logo_4")}</span>
              <UnsplashLogo />
              <div className={styles["action-container"]}>
                <Form>
                  <TextFieldGroup
                    name="searchkey"
                    label={t("dialog_55")}
                    value={queryString}
                    size="m"
                    placeholder={t("dialog_10")}
                    onChange={handleQueryChange}
                    onKeyPress={handleKeyPress}
                  />
                  <Button
                    label={buttonLabel}
                    icon={buttonIcon}
                    variant="outline-primary"
                    size="medium"
                    onClick={() => handleGetPhotosFromUnsplash()}
                    onKeyUp={(e: React.KeyboardEvent) => {
                      if (e.keyCode) {
                        e.preventDefault();
                        handleGetPhotosFromUnsplash();
                      }
                    }}
                    isDisabled={isDisabled}
                  />
                </Form>
              </div>
            </div>
          </div>
        </Formik>
      )}
    </div>
  );
};
