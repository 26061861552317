import { FormatSizeRounded } from "@material-ui/icons";
import { RichTextProperties } from "common/elements/base/RichText";
import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import React from "react";
import { elementDefinitionRegistry } from "../ElementDefinitionRegistry";
import { RichTextProperty } from "editor/properties/RichTextProperty";
import { DimensionProperty, RTEAlignmentProperty } from "editor/properties";
import { PropertyRenderer } from "../PropertyRenderer";

export class HeadingElement {
  static readonly elementKey = "heading";
  static readonly label = "element_heading_label";
  static readonly icon = FormatSizeRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_heading_description";
  static readonly elementPreviewUrl =
    "/img/add-header-element-preview-default.svg";
  static readonly dialogPositionStatic = false;
  static readonly isDialogDisplay = false;
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<RichTextProperties> = {
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <HeadingPropertiesComponent element={element} />;
  }

  static create(properties: {
    richTextProperty: RichTextProperty;
    rteAlignment: RTEAlignmentProperty;
    dimension?: DimensionProperty;
  }) {
    return {
      ...properties,
      elementKey: this.elementKey,
    };
  }
}

const HeadingPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof HeadingElement.properties>(
    HeadingElement.properties,
    element
  );
  return <>{renderer.render("richTextProperty")}</>;
};

elementDefinitionRegistry.register(HeadingElement);
