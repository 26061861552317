import React from "react";
import { useField } from "formik";

import styles from "./TextFieldGroup.module.scss";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocusOut?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "xs" | "s" | "m" | "l";
  id?: string;
};

// Uses Formik by default unless value OR onChange are provided. Using
// Formik means it doesn't apply the changes automatically, but rather
// the whole form has to be submitted.
export default function TextFieldGroup({
  name,
  label,
  placeholder,
  value,
  onChange,
  onKeyPress,
  onFocusOut,
  onBlur,
  size,
  id,
}: Props): JSX.Element {
  return (
    <FormikTextFieldGroup
      name={name}
      label={label}
      placeholder={placeholder}
      size={size}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onFocusOut={onFocusOut}
      onBlur={onBlur}
      id={id}
    />
  );
}

function createClassName(isInvalid?: string, size?: string) {
  let className = isInvalid ? "is-invalid" : "";
  className = size ? " size-" + size : "";

  return className;
}

function FormikTextFieldGroup({
  name,
  label,
  placeholder,
  size,
  value,
  id,
  onKeyPress,
  onChange,
  onFocusOut,
  onBlur,
}: Props): JSX.Element {
  const [field, meta] = useField(name);
  field.value = value;
  const isInvalid = meta.touched && meta.error;
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={styles["form__group"]}>
      <label htmlFor={name}>{label}</label>
      <input
        type="text"
        className={createClassName("", size)}
        placeholder={placeholder}
        name={field.name}
        value={field.value}
        checked={field.checked}
        multiple={field.multiple}
        onBlur={onBlur}
        onChange={handleOnChange}
        onKeyPress={onKeyPress}
        onBlurCapture={onFocusOut}
        id={id}
      />
      {isInvalid ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
}
