import {
  Accessibility,
  AccountCircleRounded,
  AddShoppingCartOutlined,
  AirlineSeatFlatAngledSharp,
  AlarmAddSharp,
  AppsSharp,
  ArrowBack,
  ArrowForwardRounded,
  Battery50Sharp,
  BookOutlined,
  Brightness1,
  Brush,
  BugReport,
  CakeRounded,
  CalendarTodayRounded,
  Camera,
  CardGiftcard,
  CardTravel,
  CellWifi,
  ChatBubbleRounded,
  Cloud,
  ComputerRounded,
  ConfirmationNumberSharp,
  Create,
  CreditCardOutlined,
  CreditCardRounded,
  DesktopMac,
  DesktopWindows,
  DevicesOutlined,
  DirectionsRun,
  DirectionsWalkOutlined,
  DonutLargeRounded,
  DriveEta,
  EmailRounded,
  EmojiPeople,
  EmojiTransportation,
  ExpandLessSharp,
  ExpandMoreSharp,
  ExploreSharp,
  Face,
  FastfoodRounded,
  FilterVintageOutlined,
  Fireplace,
  FitnessCenterSharp,
  FlightOutlined,
  FlightTakeoffSharp,
  FormatPaintRounded,
  FormatQuoteRounded,
  Gesture,
  GolfCourse,
  GroupWork,
  Headset,
  HeadsetMic,
  HotelSharp,
  HourglassFullOutlined,
  InsertEmoticonRounded,
  InvertColorsSharp,
  KitchenRounded,
  LaptopChromebook,
  LocalBarSharp,
  LocalMallSharp,
  LocalPizzaSharp,
  LocalPlayOutlined,
  LocalShippingRounded,
  LocationOn,
  Looks,
  LoyaltyRounded,
  MapOutlined,
  MenuBook,
  MicSharp,
  MoneySharp,
  MouseRounded,
  MovieSharp,
  MusicNote,
  NatureOutlined,
  NavigateNext,
  Navigation,
  OutlinedFlag,
  PaletteOutlined,
  Person,
  Pets,
  PhoneEnabledSharp,
  PhoneIphone,
  PhotoCameraSharp,
  PlayCircleFilled,
  PolicySharp,
  Pool,
  PortraitOutlined,
  PregnantWomanOutlined,
  Public,
  RestaurantMenuOutlined,
  RestaurantRounded,
  RowingRounded,
  SecurityRounded,
  Send,
  Settings,
  ShoppingBasket,
  ShoppingCartRounded,
  ShowChartOutlined,
  ShuffleSharp,
  SignalCellular0Bar,
  SpeakerRounded,
  SportsBasketball,
  SportsEsports,
  SportsFootball,
  SportsHandball,
  SportsMma,
  SportsMotorsportsRounded,
  SportsSoccerRounded,
  SportsTennisOutlined,
  Star,
  Stars,
  StorefrontRounded,
  Style,
  TagFaces,
  Theaters,
  ThumbsUpDown,
  TimeToLeaveRounded,
  TransformOutlined,
  TrendingUpOutlined,
  VideoCallOutlined,
  Videocam,
  Vignette,
  Voicemail,
  VpnKeySharp,
  Watch,
  WeekendOutlined,
  WifiLockSharp,
  Work,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCat } from "@fortawesome/free-solid-svg-icons/faCat";
import { faDog } from "@fortawesome/free-solid-svg-icons/faDog";
import { faCrow } from "@fortawesome/free-solid-svg-icons/faCrow";
import { faDove } from "@fortawesome/free-solid-svg-icons/faDove";
import { faFish } from "@fortawesome/free-solid-svg-icons/faFish";
import { faFrog } from "@fortawesome/free-solid-svg-icons/faFrog";
import { faHippo } from "@fortawesome/free-solid-svg-icons/faHippo";
import { faHorse } from "@fortawesome/free-solid-svg-icons/faHorse";
import { faHorseHead } from "@fortawesome/free-solid-svg-icons/faHorseHead";
import { faKiwiBird } from "@fortawesome/free-solid-svg-icons/faKiwiBird";
import { faOtter } from "@fortawesome/free-solid-svg-icons/faOtter";
import { faSpider } from "@fortawesome/free-solid-svg-icons/faSpider";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons/faBriefcase";
import { faChartPie } from "@fortawesome/free-solid-svg-icons/faChartPie";
import { faCity } from "@fortawesome/free-solid-svg-icons/faCity";
import { faCopyright } from "@fortawesome/free-solid-svg-icons/faCopyright";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faGlasses } from "@fortawesome/free-solid-svg-icons/faGlasses";
import { faIndustry } from "@fortawesome/free-solid-svg-icons/faIndustry";
import { faMarker } from "@fortawesome/free-solid-svg-icons/faMarker";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons/faPaperclip";
import { faRegistered } from "@fortawesome/free-solid-svg-icons/faRegistered";
import { faPercent } from "@fortawesome/free-solid-svg-icons/faPercent";
import { faBrush } from "@fortawesome/free-solid-svg-icons/faBrush";
import { faEraser } from "@fortawesome/free-solid-svg-icons/faEraser";
import { faIcons } from "@fortawesome/free-solid-svg-icons/faIcons";
import { faPaintRoller } from "@fortawesome/free-solid-svg-icons/faPaintRoller";
import { faStamp } from "@fortawesome/free-solid-svg-icons/faStamp";
import { faBaseballBall } from "@fortawesome/free-solid-svg-icons/faBaseballBall";
import { faHockeyPuck } from "@fortawesome/free-solid-svg-icons/faHockeyPuck";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faVolleyballBall } from "@fortawesome/free-solid-svg-icons/faVolleyballBall";
import { faBasketballBall } from "@fortawesome/free-solid-svg-icons/faBasketballBall";
import { faBicycle } from "@fortawesome/free-solid-svg-icons/faBicycle";
import { faBowlingBall } from "@fortawesome/free-solid-svg-icons/faBowlingBall";
import { faFootballBall } from "@fortawesome/free-solid-svg-icons/faFootballBall";
import { faVest } from "@fortawesome/free-solid-svg-icons/faVest";
import { faHatCowboy } from "@fortawesome/free-solid-svg-icons/faHatCowboy";
import { faHatCowboySide } from "@fortawesome/free-solid-svg-icons/faHatCowboySide";
import { faMitten } from "@fortawesome/free-solid-svg-icons/faMitten";
import { faShoePrints } from "@fortawesome/free-solid-svg-icons/faShoePrints";
import { faSocks } from "@fortawesome/free-solid-svg-icons/faSocks";
import { faCamera } from "@fortawesome/free-solid-svg-icons/faCamera";
import { faCompactDisc } from "@fortawesome/free-solid-svg-icons/faCompactDisc";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faPlug } from "@fortawesome/free-solid-svg-icons/faPlug";
import { faSimCard } from "@fortawesome/free-solid-svg-icons/faSimCard";
import { faSatellite } from "@fortawesome/free-solid-svg-icons/faSatellite";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons/faPowerOff";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons/faHeadphones";
import { faBus } from "@fortawesome/free-solid-svg-icons/faBus";
import { faCar } from "@fortawesome/free-solid-svg-icons/faCar";
import { faCarSide } from "@fortawesome/free-solid-svg-icons/faCarSide";
import { faCaravan } from "@fortawesome/free-solid-svg-icons/faCaravan";
import { faHotel } from "@fortawesome/free-solid-svg-icons/faHotel";
import { faMapPin } from "@fortawesome/free-solid-svg-icons/faMapPin";
import { faMountain } from "@fortawesome/free-solid-svg-icons/faMountain";
import { faPassport } from "@fortawesome/free-solid-svg-icons/faPassport";
import { faPlane } from "@fortawesome/free-solid-svg-icons/faPlane";
import { faRoad } from "@fortawesome/free-solid-svg-icons/faRoad";
import { faRoute } from "@fortawesome/free-solid-svg-icons/faRoute";
import { faDice } from "@fortawesome/free-solid-svg-icons/faDice";
import { faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons/faUmbrellaBeach";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons/faDoorOpen";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons/faSnowflake";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faTaxi } from "@fortawesome/free-solid-svg-icons/faTaxi";
import { faSuitcaseRolling } from "@fortawesome/free-solid-svg-icons/faSuitcaseRolling";
import { faBacon } from "@fortawesome/free-solid-svg-icons/faBacon";
import { faCarrot } from "@fortawesome/free-solid-svg-icons/faCarrot";
import { faEgg } from "@fortawesome/free-solid-svg-icons/faEgg";
import { faBone } from "@fortawesome/free-solid-svg-icons/faBone";
import { faBreadSlice } from "@fortawesome/free-solid-svg-icons/faBreadSlice";
import { faCandyCane } from "@fortawesome/free-solid-svg-icons/faCandyCane";
import { faCheese } from "@fortawesome/free-solid-svg-icons/faCheese";
import { faIceCream } from "@fortawesome/free-solid-svg-icons/faIceCream";
import { faHotdog } from "@fortawesome/free-solid-svg-icons/faHotdog";
import { faLemon } from "@fortawesome/free-solid-svg-icons/faLemon";
import { faMugHot } from "@fortawesome/free-solid-svg-icons/faMugHot";
import { faPepperHot } from "@fortawesome/free-solid-svg-icons/faPepperHot";
import { faPizzaSlice } from "@fortawesome/free-solid-svg-icons/faPizzaSlice";
import { faWineBottle } from "@fortawesome/free-solid-svg-icons/faWineBottle";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faPersonBooth } from "@fortawesome/free-solid-svg-icons/faPersonBooth";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons/faUserAlt";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons/faUserSecret";
import { faUserTag } from "@fortawesome/free-solid-svg-icons/faUserTag";
import { faUserAstronaut } from "@fortawesome/free-solid-svg-icons/faUserAstronaut";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";
import { faUserNinja } from "@fortawesome/free-solid-svg-icons/faUserNinja";
import { faUserNurse } from "@fortawesome/free-solid-svg-icons/faUserNurse";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faCloudMoon } from "@fortawesome/free-solid-svg-icons/faCloudMoon";
import { faCloudShowersHeavy } from "@fortawesome/free-solid-svg-icons/faCloudShowersHeavy";
import { faMeteor } from "@fortawesome/free-solid-svg-icons/faMeteor";
import { faMoon } from "@fortawesome/free-solid-svg-icons/faMoon";
import { faRainbow } from "@fortawesome/free-solid-svg-icons/faRainbow";
import { faSun } from "@fortawesome/free-solid-svg-icons/faSun";
import { faUmbrella } from "@fortawesome/free-solid-svg-icons/faUmbrella";
import { faWater } from "@fortawesome/free-solid-svg-icons/faWater";
import { faGuitar } from "@fortawesome/free-solid-svg-icons/faGuitar";
import { faRecordVinyl } from "@fortawesome/free-solid-svg-icons/faRecordVinyl";
import { faSeedling } from "@fortawesome/free-solid-svg-icons/faSeedling";
import { faRibbon } from "@fortawesome/free-solid-svg-icons/faRibbon";
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faLeaf } from "@fortawesome/free-solid-svg-icons/faLeaf";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons/faPiggyBank";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faRobot } from "@fortawesome/free-solid-svg-icons/faRobot";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faCertificate } from "@fortawesome/free-solid-svg-icons/faCertificate";
import { faCrown } from "@fortawesome/free-solid-svg-icons/faCrown";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faDrum } from "@fortawesome/free-solid-svg-icons/faDrum";
import { faMusic } from "@fortawesome/free-solid-svg-icons/faMusic";

import { GiDress } from "react-icons/gi";

import React from "react";

library.add(faCat as any);
library.add(faDog as any);
library.add(faCrow as any);
library.add(faDove as any);
library.add(faFish as any);
library.add(faFrog as any);
library.add(faHippo as any);
library.add(faHorse as any);
library.add(faHorseHead as any);
library.add(faKiwiBird as any);
library.add(faOtter as any);
library.add(faSpider as any);
library.add(faBriefcase as any);
library.add(faChartPie as any);
library.add(faCity as any);
library.add(faCopyright as any);
library.add(faEnvelope as any);
library.add(faGlasses as any);
library.add(faIndustry as any);
library.add(faMarker as any);
library.add(faPaperclip as any);
library.add(faRegistered as any);
library.add(faPercent as any);
library.add(faBuilding as any);
library.add(faBrush as any);
library.add(faEraser as any);
library.add(faIcons as any);
library.add(faPaintRoller as any);
library.add(faStamp as any);
library.add(faBaseballBall as any);
library.add(faHockeyPuck as any);
library.add(faMedal as any);
library.add(faVolleyballBall as any);
library.add(faBasketballBall as any);
library.add(faBicycle as any);
library.add(faBowlingBall as any);
library.add(faFootballBall as any);
library.add(faFootballBall as any);
library.add(faVest as any);
library.add(faHatCowboy as any);
library.add(faHatCowboySide as any);
library.add(faMitten as any);
library.add(faShoePrints as any);
library.add(faSocks as any);
library.add(faCamera as any);
library.add(faCompactDisc as any);
library.add(faDatabase as any);
library.add(faPlug as any);
library.add(faSimCard as any);
library.add(faSatellite as any);
library.add(faPowerOff as any);
library.add(faHeadphones as any);
library.add(faBus as any);
library.add(faCar as any);
library.add(faCarSide as any);
library.add(faCaravan as any);
library.add(faHotel as any);
library.add(faMapPin as any);
library.add(faMountain as any);
library.add(faPassport as any);
library.add(faPlane as any);
library.add(faRoad as any);
library.add(faRoute as any);
library.add(faDice as any);
library.add(faUmbrellaBeach as any);
library.add(faDoorOpen as any);
library.add(faSnowflake as any);
library.add(faKey as any);
library.add(faTaxi as any);
library.add(faSuitcaseRolling as any);
library.add(faBacon as any);
library.add(faCarrot as any);
library.add(faEgg as any);
library.add(faBone as any);
library.add(faBreadSlice as any);
library.add(faCandyCane as any);
library.add(faCheese as any);
library.add(faIceCream as any);
library.add(faHotdog as any);
library.add(faLemon as any);
library.add(faMugHot as any);
library.add(faPepperHot as any);
library.add(faPizzaSlice as any);
library.add(faWineBottle as any);
library.add(faUser as any);
library.add(faPersonBooth as any);
library.add(faUserAlt as any);
library.add(faUserSecret as any);
library.add(faUserTag as any);
library.add(faUserAstronaut as any);
library.add(faUserCircle as any);
library.add(faUserNinja as any);
library.add(faUserNurse as any);
library.add(faUserTie as any);
library.add(faBolt as any);
library.add(faCloudMoon as any);
library.add(faCloudShowersHeavy as any);
library.add(faMeteor as any);
library.add(faMoon as any);
library.add(faRainbow as any);
library.add(faSun as any);
library.add(faUmbrella as any);
library.add(faWater as any);
library.add(faGuitar as any);
library.add(faRecordVinyl as any);
library.add(faSeedling as any);
library.add(faRibbon as any);
library.add(faHeart as any);
library.add(faLeaf as any);
library.add(faPiggyBank as any);
library.add(faEye as any);
library.add(faRobot as any);
library.add(faRocket as any);
library.add(faCertificate as any);
library.add(faCrown as any);
library.add(faPlay as any);
library.add(faDrum as any);
library.add(faMusic as any);

export const businessIcons = [
  { id: "cat-1-icon-1", icon: <ShoppingBasket /> },
  { id: "cat-1-icon-2", icon: <ShoppingCartRounded /> },
  { id: "cat-1-icon-3", icon: <CreditCardOutlined /> },
  { id: "cat-1-icon-4", icon: <Work /> },
  { id: "cat-1-icon-5", icon: <StorefrontRounded /> },
  { id: "cat-1-icon-6", icon: <AddShoppingCartOutlined /> },
  { id: "cat-1-icon-7", icon: <CardGiftcard /> },
  { id: "cat-1-icon-8", icon: <AlarmAddSharp /> },
  { id: "cat-1-icon-9", icon: <Stars /> },
  { id: "cat-1-icon-10", icon: <LoyaltyRounded /> },
  { id: "cat-1-icon-11", icon: <PolicySharp /> },
  { id: "cat-1-icon-12", icon: <OutlinedFlag /> },
  { id: "cat-1-icon-13", icon: <LocalShippingRounded /> },
  { id: "cat-1-icon-14", icon: <HotelSharp /> },
  { id: "cat-1-icon-15", icon: <HeadsetMic /> },
  {
    id: "cat-1-icon-16",
    icon: <FontAwesomeIcon icon={["fas", "briefcase"]} />,
  },
  {
    id: "cat-1-icon-17",
    icon: <FontAwesomeIcon icon={["fas", "chart-pie"]} />,
  },
  { id: "cat-1-icon-18", icon: <FontAwesomeIcon icon={["fas", "city"]} /> },
  {
    id: "cat-1-icon-19",
    icon: <FontAwesomeIcon icon={["fas", "copyright"]} />,
  },
  { id: "cat-1-icon-20", icon: <FontAwesomeIcon icon={["fas", "envelope"]} /> },
  { id: "cat-1-icon-21", icon: <FontAwesomeIcon icon={["fas", "glasses"]} /> },
  { id: "cat-1-icon-22", icon: <FontAwesomeIcon icon={["fas", "industry"]} /> },
  { id: "cat-1-icon-23", icon: <FontAwesomeIcon icon={["fas", "marker"]} /> },
  {
    id: "cat-1-icon-24",
    icon: <FontAwesomeIcon icon={["fas", "paperclip"]} />,
  },
  {
    id: "cat-1-icon-25",
    icon: <FontAwesomeIcon icon={["fas", "registered"]} />,
  },
  { id: "cat-1-icon-26", icon: <FontAwesomeIcon icon={["fas", "percent"]} /> },
  { id: "cat-1-icon-27", icon: <FontAwesomeIcon icon={["fas", "building"]} /> },
];

export const artIcons = [
  { id: "cat-2-icon-1", icon: <BookOutlined /> },
  { id: "cat-2-icon-2", icon: <Create /> },
  { id: "cat-2-icon-3", icon: <Gesture /> },
  { id: "cat-2-icon-4", icon: <PhotoCameraSharp /> },
  { id: "cat-2-icon-5", icon: <PaletteOutlined /> },
  { id: "cat-2-icon-6", icon: <InvertColorsSharp /> },
  { id: "cat-2-icon-7", icon: <MusicNote /> },
  { id: "cat-2-icon-8", icon: <Style /> },
  { id: "cat-2-icon-9", icon: <Camera /> },
  { id: "cat-2-icon-10", icon: <Vignette /> },
  { id: "cat-2-icon-11", icon: <MenuBook /> },
  { id: "cat-2-icon-12", icon: <LocalPlayOutlined /> },
  { id: "cat-2-icon-13", icon: <FormatQuoteRounded /> },
  { id: "cat-2-icon-14", icon: <ConfirmationNumberSharp /> },
  { id: "cat-2-icon-15", icon: <Brush /> },
  { id: "cat-2-icon-16", icon: <FormatPaintRounded /> },
  { id: "cat-2-icon-17", icon: <MovieSharp /> },
  { id: "cat-2-icon-18", icon: <FontAwesomeIcon icon={["fas", "brush"]} /> },
  { id: "cat-2-icon-19", icon: <FontAwesomeIcon icon={["fas", "eraser"]} /> },
  { id: "cat-2-icon-20", icon: <FontAwesomeIcon icon={["fas", "icons"]} /> },
  {
    id: "cat-2-icon-21",
    icon: <FontAwesomeIcon icon={["fas", "paint-roller"]} />,
  },
  { id: "cat-2-icon-22", icon: <FontAwesomeIcon icon={["fas", "stamp"]} /> },
];

export const sportIcons = [
  { id: "cat-3-icon-1", icon: <SportsEsports /> },
  { id: "cat-3-icon-2", icon: <FitnessCenterSharp /> },
  { id: "cat-3-icon-3", icon: <SportsSoccerRounded /> },
  { id: "cat-3-icon-4", icon: <Pool /> },
  { id: "cat-3-icon-5", icon: <SportsBasketball /> },
  { id: "cat-3-icon-6", icon: <RowingRounded /> },
  { id: "cat-3-icon-7", icon: <SportsTennisOutlined /> },
  { id: "cat-3-icon-8", icon: <SportsMotorsportsRounded /> },
  { id: "cat-3-icon-9", icon: <GolfCourse /> },
  { id: "cat-3-icon-10", icon: <SportsMma /> },
  { id: "cat-3-icon-11", icon: <SportsHandball /> },
  { id: "cat-3-icon-12", icon: <SportsFootball /> },
  { id: "cat-3-icon-13", icon: <FontAwesomeIcon icon={["fas", "stamp"]} /> },
  {
    id: "cat-3-icon-14",
    icon: <FontAwesomeIcon icon={["fas", "baseball-ball"]} />,
  },
  {
    id: "cat-3-icon-15",
    icon: <FontAwesomeIcon icon={["fas", "hockey-puck"]} />,
  },
  { id: "cat-3-icon-16", icon: <FontAwesomeIcon icon={["fas", "medal"]} /> },
  {
    id: "cat-3-icon-17",
    icon: <FontAwesomeIcon icon={["fas", "volleyball-ball"]} />,
  },
  {
    id: "cat-3-icon-18",
    icon: <FontAwesomeIcon icon={["fas", "basketball-ball"]} />,
  },
  { id: "cat-3-icon-19", icon: <FontAwesomeIcon icon={["fas", "bicycle"]} /> },
  {
    id: "cat-3-icon-20",
    icon: <FontAwesomeIcon icon={["fas", "bowling-ball"]} />,
  },
  {
    id: "cat-3-icon-21",
    icon: <FontAwesomeIcon icon={["fas", "football-ball"]} />,
  },
];

export const fashionIcons = [
  { id: "cat-4-icon-1", icon: <LoyaltyRounded /> },
  { id: "cat-4-icon-2", icon: <FilterVintageOutlined /> },
  { id: "cat-4-icon-3", icon: <TagFaces /> },
  { id: "cat-4-icon-4", icon: <CreditCardRounded /> },
  { id: "cat-4-icon-5", icon: <LocalMallSharp /> },
  { id: "cat-4-icon-6", icon: <Watch /> },
  { id: "cat-4-icon-7", icon: <FontAwesomeIcon icon={["fas", "glasses"]} /> },
  { id: "cat-4-icon-8", icon: <FontAwesomeIcon icon={["fas", "vest"]} /> },
  {
    id: "cat-4-icon-9",
    icon: <FontAwesomeIcon icon={["fas", "hat-cowboy"]} />,
  },
  {
    id: "cat-4-icon-10",
    icon: <FontAwesomeIcon icon={["fas", "hat-cowboy-side"]} />,
  },
  { id: "cat-4-icon-11", icon: <FontAwesomeIcon icon={["fas", "mitten"]} /> },
  {
    id: "cat-4-icon-12",
    icon: <FontAwesomeIcon icon={["fas", "shoe-prints"]} />,
  },
  { id: "cat-4-icon-13", icon: <FontAwesomeIcon icon={["fas", "socks"]} /> },
  { id: "cat-4-icon-14", icon: <GiDress /> },
];

export const animalsIcons = [
  { id: "cat-5-icon-1", icon: <Pets /> },
  { id: "cat-5-icon-2", icon: <BugReport /> },
  { id: "cat-5-icon-3", icon: <NatureOutlined /> },
  { id: "cat-5-icon-4", icon: <FontAwesomeIcon icon={["fas", "cat"]} /> },
  { id: "cat-5-icon-5", icon: <FontAwesomeIcon icon={["fas", "dog"]} /> },
  { id: "cat-5-icon-6", icon: <FontAwesomeIcon icon={["fas", "crow"]} /> },
  { id: "cat-5-icon-7", icon: <FontAwesomeIcon icon={["fas", "dove"]} /> },
  { id: "cat-5-icon-8", icon: <FontAwesomeIcon icon={["fas", "fish"]} /> },
  { id: "cat-5-icon-9", icon: <FontAwesomeIcon icon={["fas", "frog"]} /> },
  { id: "cat-5-icon-10", icon: <FontAwesomeIcon icon={["fas", "hippo"]} /> },
  { id: "cat-5-icon-11", icon: <FontAwesomeIcon icon={["fas", "horse"]} /> },
  {
    id: "cat-5-icon-12",
    icon: <FontAwesomeIcon icon={["fas", "horse-head"]} />,
  },
  {
    id: "cat-5-icon-13",
    icon: <FontAwesomeIcon icon={["fas", "kiwi-bird"]} />,
  },
  { id: "cat-5-icon-14", icon: <FontAwesomeIcon icon={["fas", "otter"]} /> },
  { id: "cat-5-icon-15", icon: <FontAwesomeIcon icon={["fas", "spider"]} /> },
];

export const techIcons = [
  { id: "cat-6-icon-1", icon: <Settings /> },
  { id: "cat-6-icon-2", icon: <Headset /> },
  { id: "cat-6-icon-3", icon: <ComputerRounded /> },
  { id: "cat-6-icon-4", icon: <PhoneEnabledSharp /> },
  { id: "cat-6-icon-5", icon: <Cloud /> },
  { id: "cat-6-icon-6", icon: <VideoCallOutlined /> },
  { id: "cat-6-icon-7", icon: <Videocam /> },
  { id: "cat-6-icon-9", icon: <AppsSharp /> },
  { id: "cat-6-icon-10", icon: <EmailRounded /> },
  { id: "cat-6-icon-11", icon: <SecurityRounded /> },
  { id: "cat-6-icon-12", icon: <WifiLockSharp /> },
  { id: "cat-6-icon-13", icon: <Voicemail /> },
  { id: "cat-6-icon-14", icon: <ChatBubbleRounded /> },
  { id: "cat-6-icon-15", icon: <EmojiTransportation /> },
  { id: "cat-6-icon-16", icon: <CellWifi /> },
  { id: "cat-6-icon-17", icon: <WifiLockSharp /> },
  { id: "cat-6-icon-18", icon: <DesktopMac /> },
  { id: "cat-6-icon-19", icon: <DesktopWindows /> },
  { id: "cat-6-icon-20", icon: <MouseRounded /> },
  { id: "cat-6-icon-21", icon: <MicSharp /> },
  { id: "cat-6-icon-22", icon: <PhoneIphone /> },
  { id: "cat-6-icon-23", icon: <LaptopChromebook /> },
  { id: "cat-6-icon-24", icon: <SpeakerRounded /> },
  { id: "cat-6-icon-25", icon: <DevicesOutlined /> },
  { id: "cat-6-icon-26", icon: <Battery50Sharp /> },
  { id: "cat-6-icon-27", icon: <FontAwesomeIcon icon={["fas", "spider"]} /> },
  { id: "cat-6-icon-28", icon: <FontAwesomeIcon icon={["fas", "camera"]} /> },
  {
    id: "cat-6-icon-29",
    icon: <FontAwesomeIcon icon={["fas", "compact-disc"]} />,
  },
  { id: "cat-6-icon-30", icon: <FontAwesomeIcon icon={["fas", "database"]} /> },
  { id: "cat-6-icon-31", icon: <FontAwesomeIcon icon={["fas", "plug"]} /> },
  { id: "cat-6-icon-32", icon: <FontAwesomeIcon icon={["fas", "sim-card"]} /> },
  {
    id: "cat-6-icon-33",
    icon: <FontAwesomeIcon icon={["fas", "satellite"]} />,
  },
  {
    id: "cat-6-icon-34",
    icon: <FontAwesomeIcon icon={["fas", "power-off"]} />,
  },
  {
    id: "cat-6-icon-35",
    icon: <FontAwesomeIcon icon={["fas", "headphones"]} />,
  },
];

export const travelIcons = [
  { id: "cat-7-icon-1", icon: <MapOutlined /> },
  { id: "cat-7-icon-2", icon: <ExploreSharp /> },
  { id: "cat-7-icon-3", icon: <CardTravel /> },
  { id: "cat-7-icon-4", icon: <FlightTakeoffSharp /> },
  { id: "cat-7-icon-5", icon: <FlightOutlined /> },
  { id: "cat-7-icon-6", icon: <DriveEta /> },
  { id: "cat-7-icon-7", icon: <NavigateNext /> },
  { id: "cat-7-icon-8", icon: <AirlineSeatFlatAngledSharp /> },
  { id: "cat-7-icon-9", icon: <TimeToLeaveRounded /> },
  { id: "cat-7-icon-10", icon: <Navigation /> },
  { id: "cat-7-icon-11", icon: <LocationOn /> },
  { id: "cat-7-icon-12", icon: <FontAwesomeIcon icon={["fas", "bus"]} /> },
  { id: "cat-7-icon-13", icon: <FontAwesomeIcon icon={["fas", "car"]} /> },
  { id: "cat-7-icon-14", icon: <FontAwesomeIcon icon={["fas", "car-side"]} /> },
  { id: "cat-7-icon-15", icon: <FontAwesomeIcon icon={["fas", "caravan"]} /> },
  { id: "cat-7-icon-16", icon: <FontAwesomeIcon icon={["fas", "hotel"]} /> },
  { id: "cat-7-icon-17", icon: <FontAwesomeIcon icon={["fas", "map-pin"]} /> },
  { id: "cat-7-icon-18", icon: <FontAwesomeIcon icon={["fas", "mountain"]} /> },
  { id: "cat-7-icon-19", icon: <FontAwesomeIcon icon={["fas", "passport"]} /> },
  { id: "cat-7-icon-20", icon: <FontAwesomeIcon icon={["fas", "plane"]} /> },
  { id: "cat-7-icon-21", icon: <FontAwesomeIcon icon={["fas", "road"]} /> },
  { id: "cat-7-icon-22", icon: <FontAwesomeIcon icon={["fas", "route"]} /> },
  { id: "cat-7-icon-23", icon: <FontAwesomeIcon icon={["fas", "dice"]} /> },
  {
    id: "cat-7-icon-24",
    icon: <FontAwesomeIcon icon={["fas", "umbrella-beach"]} />,
  },
  {
    id: "cat-7-icon-25",
    icon: <FontAwesomeIcon icon={["fas", "door-open"]} />,
  },
  {
    id: "cat-7-icon-26",
    icon: <FontAwesomeIcon icon={["fas", "snowflake"]} />,
  },
  { id: "cat-7-icon-27", icon: <FontAwesomeIcon icon={["fas", "key"]} /> },
  { id: "cat-7-icon-28", icon: <FontAwesomeIcon icon={["fas", "taxi"]} /> },
  {
    id: "cat-7-icon-29",
    icon: <FontAwesomeIcon icon={["fas", "suitcase-rolling"]} />,
  },
];

export const foodIcons = [
  { id: "cat-8-icon-1", icon: <RestaurantRounded /> },
  { id: "cat-8-icon-2", icon: <RestaurantMenuOutlined /> },
  { id: "cat-8-icon-3", icon: <CakeRounded /> },
  { id: "cat-8-icon-4", icon: <FastfoodRounded /> },
  { id: "cat-8-icon-5", icon: <LocalBarSharp /> },
  { id: "cat-8-icon-6", icon: <KitchenRounded /> },
  { id: "cat-8-icon-9", icon: <LocalPizzaSharp /> },
  { id: "cat-8-icon-10", icon: <FontAwesomeIcon icon={["fas", "bacon"]} /> },
  { id: "cat-8-icon-11", icon: <FontAwesomeIcon icon={["fas", "carrot"]} /> },
  { id: "cat-8-icon-12", icon: <FontAwesomeIcon icon={["fas", "egg"]} /> },
  { id: "cat-8-icon-13", icon: <FontAwesomeIcon icon={["fas", "bone"]} /> },
  {
    id: "cat-8-icon-14",
    icon: <FontAwesomeIcon icon={["fas", "bread-slice"]} />,
  },
  {
    id: "cat-8-icon-15",
    icon: <FontAwesomeIcon icon={["fas", "candy-cane"]} />,
  },
  { id: "cat-8-icon-16", icon: <FontAwesomeIcon icon={["fas", "cheese"]} /> },
  {
    id: "cat-8-icon-17",
    icon: <FontAwesomeIcon icon={["fas", "ice-cream"]} />,
  },
  { id: "cat-8-icon-18", icon: <FontAwesomeIcon icon={["fas", "hotdog"]} /> },
  { id: "cat-8-icon-20", icon: <FontAwesomeIcon icon={["fas", "lemon"]} /> },
  { id: "cat-8-icon-21", icon: <FontAwesomeIcon icon={["fas", "mug-hot"]} /> },
  {
    id: "cat-8-icon-22",
    icon: <FontAwesomeIcon icon={["fas", "pepper-hot"]} />,
  },
  {
    id: "cat-8-icon-23",
    icon: <FontAwesomeIcon icon={["fas", "pizza-slice"]} />,
  },
  {
    id: "cat-8-icon-24",
    icon: <FontAwesomeIcon icon={["fas", "wine-bottle"]} />,
  },
];

export const peopleIcons = [
  { id: "cat-9-icon-1", icon: <Accessibility /> },
  { id: "cat-9-icon-2", icon: <PortraitOutlined /> },
  { id: "cat-9-icon-3", icon: <Person /> },
  { id: "cat-9-icon-4", icon: <PregnantWomanOutlined /> },
  { id: "cat-9-icon-5", icon: <AccountCircleRounded /> },
  { id: "cat-9-icon-6", icon: <GroupWork /> },
  { id: "cat-9-icon-8", icon: <DirectionsWalkOutlined /> },
  { id: "cat-9-icon-9", icon: <Face /> },
  { id: "cat-9-icon-10", icon: <DirectionsRun /> },
  { id: "cat-9-icon-11", icon: <InsertEmoticonRounded /> },
  { id: "cat-9-icon-12", icon: <EmojiPeople /> },
  { id: "cat-9-icon-13", icon: <FontAwesomeIcon icon={["fas", "user"]} /> },
  {
    id: "cat-9-icon-14",
    icon: <FontAwesomeIcon icon={["fas", "person-booth"]} />,
  },
  { id: "cat-9-icon-15", icon: <FontAwesomeIcon icon={["fas", "user-alt"]} /> },
  {
    id: "cat-9-icon-16",
    icon: <FontAwesomeIcon icon={["fas", "user-secret"]} />,
  },
  { id: "cat-9-icon-17", icon: <FontAwesomeIcon icon={["fas", "user-tag"]} /> },
  {
    id: "cat-9-icon-18",
    icon: <FontAwesomeIcon icon={["fas", "user-astronaut"]} />,
  },
  {
    id: "cat-9-icon-19",
    icon: <FontAwesomeIcon icon={["fas", "user-circle"]} />,
  },
  {
    id: "cat-9-icon-20",
    icon: <FontAwesomeIcon icon={["fas", "user-ninja"]} />,
  },
  {
    id: "cat-9-icon-21",
    icon: <FontAwesomeIcon icon={["fas", "user-nurse"]} />,
  },
  { id: "cat-9-icon-22", icon: <FontAwesomeIcon icon={["fas", "user-tie"]} /> },
];

export const othersIcons = [
  { id: "cat-0-icon-1", icon: <Public /> },
  { id: "cat-0-icon-2", icon: <CalendarTodayRounded /> },
  { id: "cat-0-icon-3", icon: <Send /> },
  { id: "cat-0-icon-4", icon: <Fireplace /> },
  { id: "cat-0-icon-5", icon: <WeekendOutlined /> },
  { id: "cat-0-icon-6", icon: <MicSharp /> },
  { id: "cat-0-icon-7", icon: <TransformOutlined /> },
  { id: "cat-0-icon-8", icon: <Looks /> },
  { id: "cat-0-icon-9", icon: <Brightness1 /> },
  { id: "cat-0-icon-10", icon: <VpnKeySharp /> },
  { id: "cat-0-icon-11", icon: <Theaters /> },
  { id: "cat-0-icon-12", icon: <Star /> },
  { id: "cat-0-icon-13", icon: <MoneySharp /> },
  { id: "cat-0-icon-14", icon: <Star /> },
  { id: "cat-0-icon-15", icon: <SignalCellular0Bar /> },
  { id: "cat-0-icon-16", icon: <DonutLargeRounded /> },
  { id: "cat-0-icon-17", icon: <ExpandLessSharp /> },
  { id: "cat-0-icon-18", icon: <ExpandMoreSharp /> },
  { id: "cat-0-icon-19", icon: <ArrowForwardRounded /> },
  { id: "cat-0-icon-20", icon: <ArrowBack /> },
  { id: "cat-0-icon-21", icon: <TrendingUpOutlined /> },
  { id: "cat-0-icon-22", icon: <PlayCircleFilled /> },
  { id: "cat-0-icon-23", icon: <NavigateNext /> },
  { id: "cat-0-icon-24", icon: <ShowChartOutlined /> },
  { id: "cat-0-icon-25", icon: <ShuffleSharp /> },
  { id: "cat-0-icon-26", icon: <ThumbsUpDown /> },
  { id: "cat-0-icon-27", icon: <HourglassFullOutlined /> },
  { id: "cat-0-icon-28", icon: <FontAwesomeIcon icon={["fas", "bolt"]} /> },
  {
    id: "cat-0-icon-29",
    icon: <FontAwesomeIcon icon={["fas", "cloud-moon"]} />,
  },
  {
    id: "cat-0-icon-30",
    icon: <FontAwesomeIcon icon={["fas", "cloud-showers-heavy"]} />,
  },
  { id: "cat-0-icon-31", icon: <FontAwesomeIcon icon={["fas", "meteor"]} /> },
  { id: "cat-0-icon-32", icon: <FontAwesomeIcon icon={["fas", "moon"]} /> },
  { id: "cat-0-icon-33", icon: <FontAwesomeIcon icon={["fas", "rainbow"]} /> },
  { id: "cat-0-icon-34", icon: <FontAwesomeIcon icon={["fas", "sun"]} /> },
  { id: "cat-0-icon-35", icon: <FontAwesomeIcon icon={["fas", "umbrella"]} /> },
  { id: "cat-0-icon-36", icon: <FontAwesomeIcon icon={["fas", "water"]} /> },
  { id: "cat-0-icon-37", icon: <FontAwesomeIcon icon={["fas", "guitar"]} /> },
  {
    id: "cat-0-icon-38",
    icon: <FontAwesomeIcon icon={["fas", "record-vinyl"]} />,
  },
  { id: "cat-0-icon-39", icon: <FontAwesomeIcon icon={["fas", "seedling"]} /> },
  { id: "cat-0-icon-40", icon: <FontAwesomeIcon icon={["fas", "ribbon"]} /> },
  { id: "cat-0-icon-41", icon: <FontAwesomeIcon icon={["fas", "heart"]} /> },
  { id: "cat-0-icon-42", icon: <FontAwesomeIcon icon={["fas", "leaf"]} /> },
  {
    id: "cat-0-icon-43",
    icon: <FontAwesomeIcon icon={["fas", "piggy-bank"]} />,
  },
  { id: "cat-0-icon-44", icon: <FontAwesomeIcon icon={["fas", "eye"]} /> },
  { id: "cat-0-icon-45", icon: <FontAwesomeIcon icon={["fas", "robot"]} /> },
  { id: "cat-0-icon-46", icon: <FontAwesomeIcon icon={["fas", "rocket"]} /> },
  {
    id: "cat-0-icon-47",
    icon: <FontAwesomeIcon icon={["fas", "certificate"]} />,
  },
  { id: "cat-0-icon-48", icon: <FontAwesomeIcon icon={["fas", "crown"]} /> },
  { id: "cat-0-icon-49", icon: <FontAwesomeIcon icon={["fas", "play"]} /> },
  { id: "cat-0-icon-50", icon: <FontAwesomeIcon icon={["fas", "drum"]} /> },
  { id: "cat-0-icon-51", icon: <FontAwesomeIcon icon={["fas", "music"]} /> },
];
