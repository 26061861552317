import { RichTextProperty, RTEAlignmentProperty } from "../properties";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { ImageElement } from "editor/elements/base/Image/Image";
import { LeadElement } from "editor/elements/base/Lead";
import { ArticleElement } from "editor/elements/components/Article";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { HeadingElement } from "editor/elements/base/Heading";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const cateringChild1Template: ElementTemplates = {};

cateringChild1Template["articles"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#FFEAE0",
      acrticlesAlignment: "1",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(0, 26, 84);">Experienced chefs, managers, and crew</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><div><div><div><br></div><div><span style="color: rgb(0, 41, 135);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor.</span></div></div><div><br></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            ImageElement.create({
              imageStyle: new ImageStyleProperty("1"),
              imgUrl:
                "https://images.unsplash.com/photo-1470784790053-6c2f15489967?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1718",
              imageText: "",
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><div><div><div><div><span style="color: rgb(0, 26, 84);"><br></span></div><div><span style="color: rgb(0, 26, 84);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus.<br><br>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor.&nbsp;</span><br></div></div></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "2",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1532635245-05a2903ffd56?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();
