import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getIsPasswordUpdated,
  getIsUsernameUpdated,
  getUserCountry,
  getUserCountryCode,
  getUserFirstName,
  getUserId,
  getUserLastName,
  getUsername,
  getUsernameValidity,
  getUserProvider,
  getUserWebsiteSlug,
} from "../auth/selectors";
import { Form } from "react-bootstrap";
import { FolderSpecialRounded, SaveRounded } from "@material-ui/icons";
import { Button } from "editor/components/base/Button/Button";
import styles from "./Admin.module.scss";
import * as yup from "yup";
import { Formik, FormikErrors } from "formik";
import { getRequestIsInProgress } from "api/selectors";
import {
  getUserProvider as getProvider,
  logActivity,
  updateDetails,
  updatePassword,
  updatePasswordStateAction,
  updateUsername,
  updateUsernameStateAction,
} from "auth/authActions";
import { logout } from "auth/authActions";
import { useHistory } from "react-router-dom";
import { accountPage } from "./Container";
import { getCountry, getCountryCode } from "../admin/utils-country";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { setTawkAttributes, tawkWidgetToggle } from "./Tawk";
import { useTranslation } from "react-i18next";

export type UsernameFormValues = yup.InferType<typeof usernameSchema>;

export type PasswordFormValues = yup.InferType<typeof passwordSchema>;

export type DetailsFormValues = yup.InferType<typeof detailsSchema>;

let usernameSchema = yup.object({
  username: yup.string(),
});

let passwordSchema = yup.object({
  password: yup.string(),
  passwordConfirmation: yup.string(),
});

let detailsSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
});

export const AccountDetails: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const username = useSelector(getUsername);
  const userId = useSelector(getUserId);
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const isRequestInProgress = useSelector(getRequestIsInProgress);
  const provider = useSelector(getUserProvider);
  const isUsernameValid = useSelector(getUsernameValidity);
  const isPasswordUpdated = useSelector(getIsPasswordUpdated);
  const isUsernameUpdated = useSelector(getIsUsernameUpdated);
  const [isUsernameChanged, setIsUsernameChanged] = useState(false);
  const [isNamesChanged, setIsNamesChanged] = useState(false);
  const stateCountryCode = useSelector(getUserCountryCode);
  const stateCountry = useSelector(getUserCountry);
  const [countryCode, setCountryCode] = useState(stateCountryCode);
  const [country, setCountry] = useState(stateCountry);

  let providerName;
  if (provider !== undefined && provider)
    providerName =
      provider.charAt(0).toUpperCase() + provider.slice(1).toLowerCase();

  const tldjs = require("tldjs");
  const { getDomain } = tldjs;

  useEffect(() => {
    document.title = "GoodWeb | Account Details";
  }, []);

  useEffect(() => {
    tawkWidgetToggle(true);
  }, []);

  useEffect(() => {
    if (firstName && lastName && websiteSlug && username) {
      const publishedDomain =
        websiteSlug +
        "." +
        getDomain(document.domain) +
        (window.location.port ? ":" + window.location.port : "");
      setTawkAttributes(firstName + " " + lastName, username, publishedDomain);
    }
  }, [firstName, lastName, username, websiteSlug, getDomain]);

  useEffect(() => {
    if (!stateCountryCode) {
      const countryCode = getCountryCode();
      const country = getCountry(countryCode);

      setCountryCode(countryCode);
      setCountry(country);

      if (countryCode && country && userId && firstName && lastName)
        dispatch(
          updateDetails(userId, firstName, lastName, countryCode, country)
        );
    }
  }, [stateCountryCode, userId, firstName, lastName, dispatch]);

  useEffect(() => {
    if (provider === undefined) {
      dispatch(getProvider());
    }

    if (username && isUsernameUpdated) {
      dispatch(updateUsernameStateAction());
      localStorage.removeItem("wauid");
      localStorage.removeItem("accessToken");
      history.push("/login");
      dispatch(logout());
    }
    if (isPasswordUpdated) {
      dispatch(updatePasswordStateAction());
      localStorage.removeItem("wauid");
      localStorage.removeItem("accessToken");
      history.push("/login");
      dispatch(logout());
    }
  }, [
    dispatch,
    history,
    username,
    isPasswordUpdated,
    isUsernameUpdated,
    provider,
  ]);

  useEffect(() => {
    dispatch(logActivity("VIEW", accountPage, username));
  }, [dispatch, username]);

  const handleUsernameChange = (e: any) => {
    if (e.target.value !== username) {
      setIsUsernameChanged(true);
    } else {
      setIsUsernameChanged(false);
    }
  };

  const handleNamesChange = (e: any) => {
    if (e.target.value) {
      setIsNamesChanged(true);
    } else {
      setIsNamesChanged(false);
    }
  };

  const handleUsernameSubmit = (values: UsernameFormValues) => {
    if (values.username) {
      if (userId !== undefined) {
        dispatch(logActivity("UPDATE USERNAME", accountPage, username));
        dispatch(updateUsername(userId, values.username));
      }
    }
  };

  const handleNameSubmit = (values: DetailsFormValues) => {
    if (values.firstName && values.lastName) {
      if (userId !== undefined) {
        dispatch(logActivity("UPDATE NAMES", accountPage, username));
        if (countryCode && country)
          dispatch(
            updateDetails(
              userId,
              values.firstName,
              values.lastName,
              countryCode,
              country
            )
          );
      }
    }
  };

  const handlePasswordSubmit = (values: PasswordFormValues) => {
    if (values.password) {
      if (userId !== undefined) {
        dispatch(logActivity("UPDATE PASSWORD", accountPage));
        dispatch(updatePassword(userId, values.password));
      }
    }
  };

  usernameSchema = yup.object({
    username: yup
      .string()
      .required("A valid email is required")
      .email("Invalid email")
      .test("is-valid", "The email is already registered", function (
        this: yup.TestContext,
        value?: any
      ) {
        if (isUsernameValid) {
          return false;
        } else {
          return true;
        }
      }),
  });

  passwordSchema = yup.object({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password is too short (minimum of 8 characters)")
      .matches(/[a-z]/, "A lower case character (a-z) is required")
      .matches(/[A-Z]/, "An upper case character (A-Z) is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password")], "Both passwords must match")
      .required("Password confirmation is required"),
  });

  detailsSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
  });

  const validateFormForButton = (
    errors: FormikErrors<{
      username: string;
      password: string;
      passwordConfirmation: string;
      firstName: string;
      lastName: string;
    }>,
    values: {
      username: string;
      password: string;
      passwordConfirmation: string;
      firstName: string;
      lastName: string;
    }
  ): boolean => {
    let isValid = true;
    if (errors.password || errors.passwordConfirmation) {
      isValid = false;
    } else if (!values.password || !values.passwordConfirmation) {
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <FolderSpecialRounded />
          <h3>{t("Account_Details")}</h3>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card"]}>
          <div className={styles["admin__card-header"]}>
            <h4>{t("login_credentials")}</h4>
          </div>
          {provider === "local" ? (
            <div>
              <Formik
                validationSchema={usernameSchema}
                onSubmit={handleUsernameSubmit}
                initialValues={{
                  username: username,
                }}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleSubmit,
                  handleChange,
                  setErrors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className={styles["admin__content-forms_container"]}>
                      <Form.Group
                        className={styles["admin__content-form-group"]}
                        controlId="username"
                      >
                        <Form.Label className={styles["admin__content-lead"]}>
                          {t("username_email")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="username"
                          placeholder="Username"
                          value={values.username}
                          isInvalid={touched.username && !!errors.username}
                          autoComplete="username"
                          onChange={(e) => {
                            handleUsernameChange(e);
                            handleChange(e);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        label={
                          isRequestInProgress
                            ? t("loading")
                            : t("save_username")
                        }
                        variant={isUsernameChanged ? "primary" : "disabled"}
                        size="large"
                        isDisabled={isRequestInProgress}
                        icon={SaveRounded}
                        style={{
                          marginTop: "8px",
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
              <hr style={{ marginBottom: 0 }} />
              <Formik
                validationSchema={passwordSchema}
                onSubmit={handlePasswordSubmit}
                initialValues={{
                  username: "",
                  password: "",
                  passwordConfirmation: "",
                  firstName: "",
                  lastName: "",
                }}
                validateOnChange={true}
                validateOnBlur={true}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setErrors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className={styles["admin__content-forms_container"]}>
                      <Form.Group
                        className={styles["admin__content-form-group"]}
                        controlId="password"
                      >
                        <Form.Label className={styles["admin__content-lead"]}>
                          {t("password")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.password && !!errors.password}
                          autoComplete="new-password"
                        />
                        <Form.Text className="text-muted">
                          <ul>
                            <li>{t("password_rule_1")}</li>
                            <li>{t("password_rule_2")}</li>
                            <li>{t("password_rule_3")}</li>
                          </ul>
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className={styles["admin__content-form-group"]}
                        controlId="password-confirmation"
                      >
                        <Form.Label className={styles["admin__content-lead"]}>
                          {t("repeat_password")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="passwordConfirmation"
                          placeholder="Repeat password"
                          value={values.passwordConfirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.passwordConfirmation &&
                            !!errors.passwordConfirmation
                          }
                          autoComplete="new-password"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.passwordConfirmation}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        label={
                          isRequestInProgress
                            ? t("loading")
                            : t("save_password")
                        }
                        variant={
                          validateFormForButton(errors, values)
                            ? "primary"
                            : "disabled"
                        }
                        size="large"
                        isDisabled={
                          validateFormForButton(errors, values) ? false : true
                        }
                        icon={SaveRounded}
                        style={{
                          marginTop: "8px",
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <div className={styles["home-support"]}>
              <div
                className="text-muted"
                style={{ marginTop: "4px", fontSize: ".9rem" }}
              >
                {t("account_details_paragraph_1")} {providerName}.{" "}
                {t("account_details_paragraph_2")} {providerName}.
              </div>
            </div>
          )}
        </div>
        <div className={styles["admin__content-card"]}>
          <div className={styles["admin__card-header"]}>
            <h4>{t("Account_Details")}</h4>
          </div>
          <div>
            <Formik
              validationSchema={usernameSchema}
              onSubmit={handleNameSubmit}
              initialValues={{
                username: username,
                password: "",
                passwordConfirmation: "",
                firstName: firstName,
                lastName: lastName,
              }}
            >
              {({
                values,
                touched,
                errors,
                handleSubmit,
                handleChange,
                setErrors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className={styles["admin__content-forms_container"]}>
                    <Form.Group
                      className={styles["admin__content-form-group"]}
                      controlId="first-name"
                    >
                      <Form.Label className={styles["admin__content-lead"]}>
                        {t("first_name")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        value={values.firstName}
                        onChange={(e) => {
                          handleNamesChange(e);
                          handleChange(e);
                        }}
                        isInvalid={touched.firstName && !!errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className={styles["admin__content-form-group"]}
                      controlId="last-name"
                    >
                      <Form.Label className={styles["admin__content-lead"]}>
                        {t("last_name")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                        value={values.lastName}
                        onChange={(e) => {
                          handleNamesChange(e);
                          handleChange(e);
                        }}
                        isInvalid={touched.lastName && !!errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button
                      label={
                        isRequestInProgress ? t("loading") : t("save_details")
                      }
                      variant={isNamesChanged ? "primary" : "disabled"}
                      size="large"
                      isDisabled={isRequestInProgress}
                      icon={SaveRounded}
                      style={{
                        marginTop: "8px",
                        textAlign: "center",
                        justifyContent: "center",
                        float: "right",
                      }}
                      type="submit"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {provider === "local" ? (
        <div className={styles["admin__content-container"]}>
          <div
            className={`${styles["admin__content-card"]} ${styles["admin__content-card--warning"]}`}
          >
            <div>
              <div className={styles["home-support"]}>
                <div className={styles["admin__card-header--small"]}>
                  <h5>
                    <span
                      role="img"
                      aria-label="Help Notice"
                      style={{ marginRight: "6px" }}
                    >
                      ⚠️{" "}
                    </span>{" "}
                    {t("account_details_paragraph_3")}
                  </h5>
                </div>
                <div
                  className="text-muted"
                  style={{ marginTop: "4px", fontSize: ".9rem" }}
                >
                  {t("account_details_paragraph_4")}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <TawkMessengerReact
        propertyId="63b56d68c2f1ac1e202ba11d"
        widgetId="1glub6oqr"
      />
    </>
  );
};
