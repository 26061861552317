import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
  Form,
  ProgressBar,
  Tabs,
  Tab,
  ListGroup,
} from "react-bootstrap";
import * as yup from "yup";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import { TCreateCategory } from "./api";
import { IStepProps } from "./Setup";
import { useDropzone } from "react-dropzone";
import styles from "./Admin.module.scss";
import TextFieldGroup from "../editor/components/components/TextFieldGroup/TextFieldGroup";
import html2canvas from "html2canvas";
import { ChromePicker, ColorResult, CompactPicker } from "react-color";

import "./AdminLogoStyles.scss";
import {
  ArrowForwardRounded,
  BrushRounded,
  CloudUploadRounded,
} from "@material-ui/icons";
import {
  businessIcons,
  artIcons,
  sportIcons,
  fashionIcons,
  animalsIcons,
  techIcons,
  travelIcons,
  foodIcons,
  peopleIcons,
  othersIcons,
} from "common/elements/logoicons";
import { LogoDisplaySection } from "common/elements/LogoDisplaySection";
import { isDesktopView } from "./utils-country";
import { urltoFile } from "./image-utils";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  category: yup.mixed<TCreateCategory>().required(/* TODO: Add a message */),
});

export type SetupStep4bFormValues = yup.InferType<typeof schema>;

export const SetupStep4bForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  setIsCreateLogo,
  setImagesToUpload,
  imagesToUpload,
  setPrimaryColor,
  setSecondaryColor,
  setTernaryColor,
  setColorType,
  setPrimaryRadius,
  setSecondaryRadius,
  setPrimaryShadow,
  setPrimaryFont,
  setSecondaryFont,
  handleSkip,
}) => {
  var tinycolor = require("tinycolor2");
  const { t } = useTranslation();
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, []);

  useEffect(() => {
    if (
      values &&
      (values.isDesignLogo === undefined || values.isDesignLogo === false)
    ) {
      handleSaveAndContinue(values);
    }
  }, [values, handleSaveAndContinue]);

  const colorsGitHubDefault = [
    "#4D4D4D",
    "#999999",
    "#AB149E",
    "#F44E3B",
    "#FE9200",
    "#FCDC00",
    "#DBDF00",
    "#A4DD00",
    "#68CCCA",
    "#73D8FF",
    "#AEA1FF",
    "#FDA1FF",
    "#333333",
    "#808080",
    "#009CE0",
    "#7B64FF",
    "#FA28FF",
    "#000000",
    "#666666",
    "#B3B3B3",
    "#9F0500",
    "#C45100",
    "#FB9E00",
    "#808900",
    "#194D33",
    "#0C797D",
    "#0062B1",
    "#653294",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];
  var randomColor =
    colorsGitHubDefault[(Math.random() * colorsGitHubDefault.length) | 0];

  const [imgStrUrl, setImgStrUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [imgOriginal, setImgOriginal] = useState<HTMLImageElement>();
  const [cropImgUrl, setCropImgUrl] = useState("");
  const [modalTitle, setModalTitle] = useState<any>(t("setup_page_19"));
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [progressLabel, setProgressLabel] = useState<any>(t("setup_page_20"));
  const [logoMethod, setLogoMethod] = useState<"upload" | "create" | null>();
  const [logoText, setLogoText] = useState(values.title);
  const [isColorSelected, setIsColorSelected] = useState(true);
  const [iconSelected, setIconSelected] = useState<any>();
  const [iconIdSelected, setIconIdSelected] = useState<string>();
  const [selectedPrimaryColor, setSelectedPrimaryColor] = useState("#124E78");
  const [selectedSecondaryColor, setSelectedSecondaryColor] = useState(
    "#C47500"
  );
  const [selectedTernaryColor, setSelectedTernaryColor] = useState("#003F7A");
  const [selectedColorType, setSelectedColorType] = useState("comp");
  const [isSelectLogo, setIsSelectLogo] = useState(false);
  const [isLogoSelected, setIsLogoSelected] = useState(false);
  const [logoIdSelected, setLogoIdSelected] = useState<string>();
  const [isLogoPreviewDragged, setIsLogoPreviewDragged] = useState(false);
  const [useWhiteBg, setUseWhiteBg] = useState(false);
  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    width: 50,
    height: 50,
    x: 0,
    y: 0,
  });
  const [triad1, setTriad1] = useState("#124E78");
  const [triad2, setTriad2] = useState("#BB4012");
  const [triad3, setTriad3] = useState("#BB9012");

  const [mono1, setmono1] = useState("#124E78");
  const [mono2, setmono2] = useState("#256490");
  const [mono3, setmono3] = useState("#063C62");

  const [like1, setlike1] = useState("#124E78");
  const [like2, setlike2] = useState("#0C814C");
  const [like3, setlike3] = useState("#1E2182");

  const [split1, setsplit1] = useState("#124E78");
  const [split2, setsplit2] = useState("#DE381F");
  const [split3, setsplit3] = useState("#6FDE14");

  const [tetra1, settetra1] = useState("#124E78");
  const [tetra2, settetra2] = useState("#BB9012");
  const [tetra3, settetra3] = useState("#1E2182");

  const [comp1, setcomp1] = useState("#124E78");
  const [comp2, setcomp2] = useState("#C47500");
  const [comp3, setcomp3] = useState("#003F7A");

  const setPalettes = (hex: string, colorType: string) => {
    let triadColors = tinycolor(hex).triad();
    let triadMap = triadColors.map(function (t: any) {
      return t.toHexString();
    });
    setTriad1(triadMap[0]);
    setTriad2(triadMap[1]);
    setTriad3(triadMap[2]);

    let monoColors = tinycolor(hex).monochromatic();
    let monoMap = monoColors.map(function (t: any) {
      return t.toHexString();
    });
    setmono1(monoMap[0]);
    setmono2(monoMap[1]);
    setmono3(monoMap[2]);

    let splitColors = tinycolor(hex).splitcomplement();
    let splitMap = splitColors.map(function (t: any) {
      return t.toHexString();
    });
    setsplit1(splitMap[0]);
    setsplit2(splitMap[1]);
    setsplit3(splitMap[2]);

    let analogousColors = tinycolor(hex).analogous();
    let likeMap = analogousColors.map(function (t: any) {
      return t.toHexString();
    });
    setlike1(likeMap[0]);
    setlike2(likeMap[1]);
    setlike3(likeMap[2]);

    let tetradColors = tinycolor(hex).tetrad();
    let tetraMap = tetradColors.map(function (t: any) {
      return t.toHexString();
    });
    settetra1(tetraMap[0]);
    settetra2(tetraMap[1]);
    settetra3(tetraMap[2]);

    let compColor = tinycolor(hex).complement().toHexString();
    let compMap = [hex, compColor, tinycolor(compColor).greyscale().toString()];
    setcomp1(compMap[0]);
    setcomp2(compMap[1]);
    setcomp3(compMap[2]);

    let colorsMap: string[];
    if (colorType === "triad") {
      colorsMap = triadMap;
    } else if (colorType === "mono") {
      colorsMap = monoMap;
    } else if (colorType === "split") {
      colorsMap = splitMap;
    } else if (colorType === "like") {
      colorsMap = likeMap;
    } else if (colorType === "tetra") {
      colorsMap = tetraMap;
    } else {
      colorsMap = compMap;
    }

    return colorsMap;
  };

  const onSubmit = () => {
    setDisplayOverlay(false);
    handleSaveAndContinue(values);
  };

  const generateLogos = () => {
    if (logoIdSelected) {
      // setIsLoadingImage(true);
      const toCapture = document.getElementById(logoIdSelected + "-main");
      if (toCapture) {
        html2canvas(toCapture, {
          backgroundColor: useWhiteBg
            ? "rgba(255, 255, 255)"
            : "rgba(0, 0, 0, 0)",
          removeContainer: true,
        }).then(function (canvas: HTMLCanvasElement) {
          let filename = "main";
          urltoFile(canvas.toDataURL(), filename, "png", "image/png").then(
            function (file) {
              setProgressLabel(t("setup_page_21"));
              setImagesToUpload((imagesToUpload: any) => [
                ...imagesToUpload,
                {
                  filename,
                  file,
                },
              ]);
              handleSaveAndContinue(values);
            }
          );
        });
        generateOtherAssets(logoIdSelected, "mono", 85, t("setup_page_22"));
        generateOtherAssets(logoIdSelected, "favicon", 90, t("setup_page_23"));
        generateOtherAssets(
          logoIdSelected,
          "favicon32",
          25,
          t("setup_page_24")
        );
        generateOtherAssets(
          logoIdSelected,
          "favicon180",
          35,
          t("setup_page_25")
        );
        generateOtherAssets(
          logoIdSelected,
          "favicon192",
          50,
          t("setup_page_26")
        );
        generateOtherAssets(
          logoIdSelected,
          "favicon512",
          65,
          t("setup_page_27")
        );
        generateOtherAssets(
          logoIdSelected,
          "og1200x627",
          75,
          t("setup_page_28")
        );
      }
    }
  };

  const generateOtherAssets = (
    id: string,
    idProp: string,
    progressCount: number,
    progressLabel: string
  ) => {
    if (id && idProp) {
      const toCapture = document.getElementById(id + "-" + idProp);
      if (toCapture) {
        html2canvas(toCapture, {
          backgroundColor:
            useWhiteBg && idProp !== "mono"
              ? "rgba(255, 255, 255)"
              : "rgba(0, 0, 0, 0)",
          removeContainer: true,
        }).then(function (canvas: HTMLCanvasElement) {
          let filename = idProp;
          urltoFile(canvas.toDataURL(), filename, "png", "image/png").then(
            function (file) {
              setProgressLabel(progressLabel);
              setImagesToUpload((imagesToUpload: any) => [
                ...imagesToUpload,
                {
                  filename,
                  file,
                },
              ]);
            }
          );
        });
      }
    }
  };

  const generateUploadLogos = () => {
    createOgImage("og1200x627", "og1200x627");
  };

  const createOgImage = (id: string, idProp: string) => {
    if (id && idProp) {
      const toCapture = document.getElementById(id);
      if (toCapture) {
        html2canvas(toCapture, {
          backgroundColor: "rgba(0, 0, 0, 0)",
          removeContainer: true,
        }).then(function (canvas: HTMLCanvasElement) {
          let filename = idProp;
          urltoFile(canvas.toDataURL(), filename, "png", "image/png").then(
            function (file) {
              setProgressLabel(t("setup_page_21"));
              setImagesToUpload((imagesToUpload: any) => [
                ...imagesToUpload,
                {
                  filename,
                  file,
                },
              ]);
              handleSaveAndContinue(values);
            }
          );
        });
      }
    }
  };

  useEffect(
    () => {
      let colorsMap = setPalettes(randomColor, selectedColorType);
      setSelectedPrimaryColor(colorsMap[0]);
      setSelectedSecondaryColor(colorsMap[1]);
      setSelectedTernaryColor(colorsMap[2]);
      setSelectedColorType(selectedColorType);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (displayOverlay) {
        if (logoMethod === "create") {
          setTimeout(() => {
            generateLogos();
          }, 500);
        } else if (logoMethod === "upload") {
          setTimeout(() => {
            generateUploadLogos();
          }, 500);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayOverlay, cropImgUrl, logoMethod]
  );

  function calculateAspectRatioFit(
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
  ) {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  function hexToRGB(hex: string, alpha: number) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      setFileType(file.type);
      const reader = new FileReader();
      reader.onabort = () => console.log("File reading was aborted");
      reader.onerror = () => console.log("File reading has failed");
      reader.onload = () => {
        const imgStr = reader.result as string;
        setImgStrUrl(imgStr);
        setLogoMethod("upload");

        let img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.src = objectUrl;

        img.onload = function () {
          var maxWidth = 556; // Max width for the image
          var maxHeight = 260; // Max height for the image

          if (img.width > maxWidth || img.height > maxHeight) {
            const newDimensions = calculateAspectRatioFit(
              img.width,
              img.height,
              maxWidth,
              maxHeight
            );
            img.width = newDimensions.width;
            img.height = newDimensions.height;
          }
          setImgOriginal(img);
          URL.revokeObjectURL(objectUrl);
        };
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    minSize: 0,
    maxSize: 2097152, // 2MB max
    onDrop,
  });

  const buildRgb = (imageData: Uint8ClampedArray) => {
    const rgbValues = [];
    for (let i = 0; i < imageData.length; i += 4) {
      const rgb = {
        r: imageData[i],
        g: imageData[i + 1],
        b: imageData[i + 2],
      };
      var luma = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b; // per ITU-R BT.709
      if (luma > 40 && luma < 220) {
        rgbValues.push(rgb);
      }
    }
    return rgbValues;
  };

  const findBiggestColorRange = (rgbValues: any) => {
    /**
     * Min is initialized to the maximum value posible
     * from there we procced to find the minimum value for that color channel
     *
     * Max is initialized to the minimum value posible
     * from there we procced to fin the maximum value for that color channel
     */
    let rMin = Number.MAX_VALUE;
    let gMin = Number.MAX_VALUE;
    let bMin = Number.MAX_VALUE;

    let rMax = Number.MIN_VALUE;
    let gMax = Number.MIN_VALUE;
    let bMax = Number.MIN_VALUE;

    rgbValues.forEach((pixel: any) => {
      rMin = Math.min(rMin, pixel.r);
      gMin = Math.min(gMin, pixel.g);
      bMin = Math.min(bMin, pixel.b);

      rMax = Math.max(rMax, pixel.r);
      gMax = Math.max(gMax, pixel.g);
      bMax = Math.max(bMax, pixel.b);
    });

    const rRange = rMax - rMin;
    const gRange = gMax - gMin;
    const bRange = bMax - bMin;

    // determine which color has the biggest difference
    const biggestRange = Math.max(rRange, gRange, bRange);
    if (biggestRange === rRange) {
      return "r";
    } else if (biggestRange === gRange) {
      return "g";
    } else {
      return "b";
    }
  };

  const quantization = (rgbValues: any, depth: number): any => {
    const MAX_DEPTH = 4;

    // Base case
    if (depth === MAX_DEPTH || rgbValues.length === 0) {
      const color = rgbValues.reduce(
        (prev: any, curr: any) => {
          prev.r += curr.r;
          prev.g += curr.g;
          prev.b += curr.b;

          return prev;
        },
        {
          r: 0,
          g: 0,
          b: 0,
        }
      );

      color.r = Math.round(color.r / rgbValues.length);
      color.g = Math.round(color.g / rgbValues.length);
      color.b = Math.round(color.b / rgbValues.length);

      return [color];
    }

    const componentToSortBy = findBiggestColorRange(rgbValues);
    rgbValues.sort((p1: any, p2: any) => {
      return p1[componentToSortBy] - p2[componentToSortBy];
    });

    const mid = rgbValues.length / 2;
    return [
      ...quantization(rgbValues.slice(0, mid), depth + 1),
      ...quantization(rgbValues.slice(mid + 1), depth + 1),
    ];
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width! * pixelRatio * scaleX;
    canvas.height = crop.height! * pixelRatio * scaleY;

    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(
        image,
        crop.x! * scaleX,
        crop.y! * scaleY,
        crop.width! * scaleX,
        crop.height! * scaleY,
        0,
        0,
        crop.width! * scaleX,
        crop.height! * scaleY
      );
      let mimeType = imgStrUrl.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0];
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      if (imageData && imageData.data) {
        const rgbArray = buildRgb(imageData.data);
        const quantColors = quantization(rgbArray, 0);
        if (quantColors && quantColors[0]) {
          let dominant = tinycolor(
            "rgb (" +
              quantColors[0].r +
              ", " +
              quantColors[0].g +
              ", " +
              quantColors[0].b +
              ")"
          );
          let colorsMap = setPalettes(
            dominant.toHexString(),
            selectedColorType
          );
          setSelectedPrimaryColor(colorsMap[0]);
          setSelectedSecondaryColor(colorsMap[1]);
          setSelectedTernaryColor(colorsMap[2]);
          setSelectedColorType(selectedColorType);
        }
      }
      return canvas.toDataURL(mimeType);
    }
  };

  const onCropChange = (changedCrop: Crop) => {
    setCrop(changedCrop);
  };

  const onComplete = (changedCrop: Crop) => {
    setImagesToUpload([]);
    makeClientCrop(changedCrop);
  };

  const onDrag = () => {
    setIsLogoPreviewDragged(true);
  };

  const makeClientCrop = (newCrop: Crop) => {
    if (imgOriginal && newCrop.width && newCrop.height) {
      const croppedImageUrl = getCroppedImg(imgOriginal, newCrop);
      setCropImgUrl(croppedImageUrl!);
      // setCropImgWidth(newCrop.width);
      // setCropImgHeight(newCrop.height);
      setModalTitle(t("setup_page_29"));
      let filename = "main";
      if (croppedImageUrl) {
        const dataUrl = croppedImageUrl;
        const mainFile = new File([croppedImageUrl], filename, {
          type: fileType,
        });
        const imgSrc = URL.createObjectURL(mainFile);
        let img = new Image();
        img.src = imgSrc;
        urltoFile(dataUrl, filename, "png", "image/png").then(function (file) {
          setImagesToUpload((imagesToUpload: any) => [
            ...imagesToUpload,
            {
              filename,
              file,
              dataUrl,
            },
          ]);
        });
        resizeImage(croppedImageUrl, 16, 16, mainFile, "favicon");
        resizeImage(croppedImageUrl, 32, 32, mainFile, "favicon32");
        resizeImage(croppedImageUrl, 180, 180, mainFile, "favicon180");
        resizeImage(croppedImageUrl, 192, 192, mainFile, "favicon192");
        resizeImage(croppedImageUrl, 512, 512, mainFile, "favicon512");
        invertToWhite(
          croppedImageUrl,
          newCrop.width,
          newCrop.height,
          mainFile,
          "mono"
        );
      }
    }
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div className={styles["drop-zone-label-errors"]}>
        {errors.map((e) => (
          <span key={e.code}>{e.message}</span>
        ))}
      </div>
    );
  });

  const render = () => {
    if (imgStrUrl && imgStrUrl !== "") {
      return (
        <div className={styles["image-cropper"]}>
          <ReactCrop
            src={imgStrUrl}
            crop={crop}
            onChange={onCropChange}
            onComplete={onComplete}
            onImageLoaded={onComplete}
            onDragEnd={onDrag}
          />
        </div>
      );
    } else {
      return (
        <div
          {...getRootProps()}
          className={`${styles["dropzone"]}  ${styles["create-option"]}`}
          style={{ padding: "32px" }}
        >
          <input {...getInputProps()} />
          <div className={styles["drop-zone-label-container"]}>
            <CloudUploadRounded />
            <p style={{ lineHeight: "1.2rem" }}>
              {t("setup_page_30")}
              <br />
              {t("setup_page_31")}
            </p>
            <p style={{ fontSize: "12px", fontWeight: 300 }}>
              {t("setup_page_32")} {t("setup_page_33")} {t("setup_page_34")}
            </p>
            {fileRejectionItems.length > 0 ? fileRejectionItems : <></>}
          </div>
        </div>
      );
    }
  };

  const handleLogoTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogoText(e.target.value);
  };

  const handleTypeClick = (type: string) => {
    let color1;
    let color2;
    let color3;
    if (type === "triad") {
      color1 = triad1;
      color2 = triad2;
      color3 = triad3;
    } else if (type === "mono") {
      color1 = mono1;
      color2 = mono2;
      color3 = mono3;
    } else if (type === "split") {
      color1 = split1;
      color2 = split2;
      color3 = split3;
    } else if (type === "like") {
      color1 = like1;
      color2 = like2;
      color3 = like3;
    } else if (type === "tetra") {
      color1 = tetra1;
      color2 = tetra2;
      color3 = tetra3;
    } else {
      color1 = comp1;
      color2 = comp2;
      color3 = comp3;
    }
    setSelectedPrimaryColor(color1);
    setSelectedSecondaryColor(color2);
    setSelectedTernaryColor(color3);
    setSelectedColorType(type);
  };

  const handleOnClick = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsColorSelected(true);

    let colorsMap = setPalettes(color.hex, selectedColorType);

    setSelectedPrimaryColor(colorsMap[0]);
    setSelectedSecondaryColor(colorsMap[1]);
    setSelectedTernaryColor(colorsMap[2]);
    setSelectedColorType(selectedColorType);
  };

  const handleIconClick = (icon: any, id: string) => {
    setIconSelected(icon);
    setIconIdSelected(id);
  };

  const handleGenerateLogos = () => {
    // Change Title
    setModalTitle(t("setup_page_21"));

    if (logoMethod === "create") {
      setPrimaryColor(selectedPrimaryColor);
      setSecondaryColor(selectedSecondaryColor);
      setTernaryColor(selectedTernaryColor);
      setColorType(selectedColorType);
      values.colorPrimary = selectedPrimaryColor;
      values.colorSecondary = selectedSecondaryColor;
      values.colorTernary = selectedTernaryColor;
      values.colorType = selectedColorType;
    } else if (logoMethod === "upload") {
      setPrimaryColor(selectedPrimaryColor);
      setSecondaryColor(selectedSecondaryColor);
      setTernaryColor(selectedTernaryColor);
      setColorType(selectedColorType);
      values.colorPrimary = selectedPrimaryColor;
      values.colorSecondary = selectedSecondaryColor;
      values.colorTernary = selectedTernaryColor;
      values.colorType = selectedColorType;
    }
    handleSaveAndContinue(values);
    // Display Overlay
    // setDisplayOverlay(true);
    // setIsCreateLogo(true);
  };

  const invertToWhite = (
    url: string,
    width: number,
    height: number,
    file: File,
    filename: string
  ) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.filter = "grayscale()";
        ctx.drawImage(image, 0, 0, width, height);
      }
      // output url
      if (canvas.toDataURL()) {
        const dataUrl = canvas.toDataURL();
        urltoFile(dataUrl, filename, "png", "image/png").then(function (file) {
          setImagesToUpload((imagesToUpload: any) => [
            ...imagesToUpload,
            {
              filename,
              file,
              dataUrl,
            },
          ]);
        });
      }
    };
    image.src = url;
  };

  function resizeImage(
    url: string,
    width: number,
    height: number,
    file: File,
    filename: string
  ) {
    var sourceImage = new Image();

    sourceImage.onload = (function (file) {
      return function (evt: any) {
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        if (sourceImage.width === sourceImage.height) {
          if (ctx) {
            ctx.drawImage(sourceImage, 0, 0, width, height);
          }
        } else {
          const minVal = Math.min(sourceImage.width, sourceImage.height);
          if (sourceImage.width > sourceImage.height) {
            if (ctx) {
              ctx.drawImage(
                sourceImage,
                (sourceImage.width - minVal) / 2,
                0,
                minVal,
                minVal,
                0,
                0,
                width,
                height
              );
            }
          } else {
            if (ctx) {
              ctx.drawImage(
                sourceImage,
                0,
                (sourceImage.height - minVal) / 2,
                minVal,
                minVal,
                0,
                0,
                width,
                height
              );
            }
          }
        }
        if (canvas.toDataURL()) {
          const dataUrl = canvas.toDataURL();
          urltoFile(dataUrl, filename, "png", "image/png").then(function (
            file
          ) {
            setImagesToUpload((imagesToUpload: any) => [
              ...imagesToUpload,
              {
                filename,
                file,
                dataUrl,
              },
            ]);
          });
        }
      };
    })(file);

    sourceImage.src = url;
  }

  return (
    <Form noValidate>
      {displayOverlay ? (
        <></>
      ) : (
        <Modal.Header>
          <Modal.Title style={{ width: "100%" }}>
            <h6>{t("setup_wizard_7")}</h6>
            <div className={styles["setup-subheader"]}>
              <div className={styles["setup-subheader-main"]}>{modalTitle}</div>
              <div
                className={styles["setup-subheader-skip"]}
                onClick={() => {
                  handleSkip(values);
                }}
              >
                {t("setup_wizard_4")}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <Container>
          {displayOverlay ? (
            <div
              className={`${styles["logo-select-container"]} ${styles["progress-overlay"]}`}
            >
              <div
                className={`bounce bounce-7`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="goodweb loader 1"
                  src="/img/goodweb-bouncing-logo-1.svg"
                />
              </div>
              {progressLabel}
              <span
                style={{
                  color: "#7a8794",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                {t("setup_page_35")}
              </span>
              <ProgressBar
                animated
                now={60}
                style={{
                  width: isDesktop ? "400px" : "auto",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {logoMethod === "create" ? (
            isSelectLogo ? (
              <LogoDisplaySection
                styles={styles}
                logoIdSelected={logoIdSelected}
                selectedPrimaryColor={selectedPrimaryColor}
                selectedSecondaryColor={selectedSecondaryColor}
                selectedTernaryColor={selectedTernaryColor}
                iconSelected={iconSelected}
                logoText={logoText}
                websiteTitle={values.title}
                setLogoIdSelected={setLogoIdSelected}
                setIsLogoSelected={setIsLogoSelected}
                setUseWhiteBg={setUseWhiteBg}
                setPrimaryRadius={setPrimaryRadius}
                setSecondaryRadius={setSecondaryRadius}
                setPrimaryShadow={setPrimaryShadow}
                setPrimaryFont={setPrimaryFont}
                setSecondaryFont={setSecondaryFont}
              />
            ) : (
              <div className={styles["logo-create-container"]}>
                <div className={styles["logo-create-left"]}>
                  <div className={styles["textField-container"]}>
                    <span
                      className={`${styles["list-label"]}  
                        ${
                          logoText
                            ? styles["list-label-valid"]
                            : styles["list-label-invalid"]
                        }`}
                    >
                      {t("setup_page_36")}
                    </span>
                    <TextFieldGroup
                      name="logo-text"
                      value={logoText}
                      onChange={handleLogoTextChange}
                      size={"m"}
                      placeholder={t("create_logo_2")}
                    />
                  </div>
                  <div
                    className={`picker-container-setup ${styles["picker-container"]}`}
                  >
                    {/* <span className={`${styles["list-label"]}  
                        ${isColorSelected ?
                            styles["list-label-valid"]
                            :
                            styles["list-label-invalid"]
                          }`}>Choose a color palette</span> */}
                    <Tabs id="color-picker" defaultActiveKey={"github"}>
                      <Tab title="Simple" eventKey="github">
                        <CompactPicker
                          color={selectedPrimaryColor}
                          onChange={(
                            color: ColorResult,
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleOnClick(color, event);
                          }}
                        />
                      </Tab>
                      <Tab title="Advanced" eventKey="chrome">
                        <ChromePicker
                          disableAlpha={true}
                          color={selectedPrimaryColor}
                          onChange={(
                            color: ColorResult,
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleOnClick(color, event);
                          }}
                        />
                      </Tab>
                    </Tabs>
                    <div className="harmony-rule-container">
                      <div
                        className={`harmony-type ${
                          selectedColorType === "triad" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("triad");
                        }}
                      >
                        <span>Triad</span>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(triad1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(triad2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(triad3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                      <div
                        className={`harmony-type ${
                          selectedColorType === "mono" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("mono");
                        }}
                      >
                        <span>Mono</span>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(mono1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(mono2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(mono3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="harmony-rule-container">
                      <div
                        className={`harmony-type ${
                          selectedColorType === "like" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("like");
                        }}
                      >
                        <span>Like</span>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(like1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(like2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(like3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                      <div
                        className={`harmony-type ${
                          selectedColorType === "split" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("split");
                        }}
                      >
                        <span>Split</span>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(split1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(split2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(split3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="harmony-rule-container">
                      <div
                        className={`harmony-type ${
                          selectedColorType === "tetra" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("tetra");
                        }}
                      >
                        <span>Tetra</span>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(tetra1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(tetra2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(tetra3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                      <div
                        className={`harmony-type ${
                          selectedColorType === "comp" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("comp");
                        }}
                      >
                        <span>Comp</span>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(comp1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(comp2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(comp3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles["logo-create-right"]}>
                  <span
                    className={`${styles["list-label"]}  
                        ${
                          iconIdSelected
                            ? styles["list-label-valid"]
                            : styles["list-label-invalid"]
                        }`}
                  >
                    {t("setup_page_37")}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isDesktop ? "row" : "column",
                    }}
                  >
                    <div style={{ width: isDesktop ? "240px" : "100%" }}>
                      <span style={{ color: "#808a93" }}></span>
                      {t("setup_page_38")}
                      <ListGroup
                        as="ul"
                        style={{
                          width: isDesktop ? "200px" : "100%",
                          padding: "0.75rem",
                        }}
                        className={styles["logo-select-category"]}
                      >
                        <a href="#business">
                          <ListGroup.Item>{t("setup_page_39")}</ListGroup.Item>
                        </a>
                        <a href="#sports">
                          <ListGroup.Item>{t("setup_page_40")}</ListGroup.Item>
                        </a>
                        <a href="#fashion">
                          <ListGroup.Item>{t("setup_page_41")}</ListGroup.Item>
                        </a>
                        <a href="#animals">
                          <ListGroup.Item>{t("setup_page_42")}</ListGroup.Item>
                        </a>
                        <a href="#tech">
                          <ListGroup.Item>{t("setup_page_43")}</ListGroup.Item>
                        </a>
                        <a href="#travel">
                          <ListGroup.Item>{t("setup_page_44")}</ListGroup.Item>
                        </a>
                        <a href="#food">
                          <ListGroup.Item>{t("setup_page_45")}</ListGroup.Item>
                        </a>
                        <a href="#arts">
                          <ListGroup.Item>{t("setup_page_46")}</ListGroup.Item>
                        </a>
                        <a href="#people">
                          <ListGroup.Item>{t("setup_page_47")}</ListGroup.Item>
                        </a>
                        <a href="#others">
                          <ListGroup.Item>{t("setup_page_48")}</ListGroup.Item>
                        </a>
                      </ListGroup>
                    </div>
                    <div className={styles["logo-icon-select-container"]}>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="business"
                      >
                        {t("setup_page_39")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {businessIcons.map((businessIcon, idx) => (
                          <div
                            key={businessIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === businessIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(
                                businessIcon.icon,
                                businessIcon.id
                              );
                            }}
                          >
                            {businessIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="arts"
                      >
                        {t("setup_page_46")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {artIcons.map((artIcon, idx) => (
                          <div
                            key={artIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === artIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(artIcon.icon, artIcon.id);
                            }}
                          >
                            {artIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="sports"
                      >
                        {t("setup_page_40")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {sportIcons.map((sportIcon, idx) => (
                          <div
                            key={sportIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === sportIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(sportIcon.icon, sportIcon.id);
                            }}
                          >
                            {sportIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="fashion"
                      >
                        {t("setup_page_41")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {fashionIcons.map((fashionIcon, idx) => (
                          <div
                            key={fashionIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === fashionIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(fashionIcon.icon, fashionIcon.id);
                            }}
                          >
                            {fashionIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="animals"
                      >
                        {t("setup_page_42")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {animalsIcons.map((animalsIcon, idx) => (
                          <div
                            key={animalsIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === animalsIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(animalsIcon.icon, animalsIcon.id);
                            }}
                          >
                            {animalsIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="tech"
                      >
                        {t("setup_page_43")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {techIcons.map((techIcon, idx) => (
                          <div
                            key={techIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === techIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(techIcon.icon, techIcon.id);
                            }}
                          >
                            {techIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="travel"
                      >
                        {t("setup_page_44")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {travelIcons.map((travelIcon, idx) => (
                          <div
                            key={travelIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === travelIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(travelIcon.icon, travelIcon.id);
                            }}
                          >
                            {travelIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="food"
                      >
                        {t("setup_page_45")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {foodIcons.map((foodIcon, idx) => (
                          <div
                            key={foodIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === foodIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(foodIcon.icon, foodIcon.id);
                            }}
                          >
                            {foodIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="people"
                      >
                        {t("setup_page_47")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {peopleIcons.map((peopleIcon, idx) => (
                          <div
                            key={peopleIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === peopleIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(peopleIcon.icon, peopleIcon.id);
                            }}
                          >
                            {peopleIcon.icon}
                          </div>
                        ))}
                      </div>
                      <span
                        className={styles["logo-icon-select-title"]}
                        id="others"
                      >
                        {t("setup_page_48")}
                      </span>
                      <div
                        className={styles["logo-icon-select-icons-container"]}
                      >
                        {othersIcons.map((othersIcon, idx) => (
                          <div
                            key={othersIcon.id}
                            className={`${styles["logo-icon-container"]} ${
                              iconIdSelected === othersIcon.id
                                ? styles["icon-selected"]
                                : ""
                            }`}
                            onMouseDown={() => {
                              handleIconClick(othersIcon.icon, othersIcon.id);
                            }}
                          >
                            {othersIcon.icon}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <Form.Group
              as={isDesktop ? Row : Col}
              style={{
                paddingRight: isDesktop ? "15px" : "0",
                paddingLeft: isDesktop ? "15px" : "0",
              }}
              className={styles["create-options-container"]}
            >
              <Col>{render()}</Col>
              <Col>
                {imgStrUrl ? (
                  <div className={styles["logo-upload-detail-container"]}>
                    <div className={styles["logo-upload-preview"]}>
                      {cropImgUrl ? (
                        <div className={styles["logo-upload-logos"]}>
                          <div className={styles["logo-upload-logos-main"]}>
                            <img src={cropImgUrl} alt="Uploaded logo" />
                          </div>
                          <div
                            className={styles["logo-upload-logos-icons"]}
                          ></div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{
                        color: "#808a93",
                        fontSize: "1rem",
                      }}
                    >
                      {t("setup_page_49")}
                    </div>
                    <div style={{ zIndex: -1, position: "absolute" }}>
                      {imagesToUpload.map((element, idx) => (
                        <>
                          <img src={element.dataUrl} alt="Preview" />
                        </>
                      ))}
                      <div
                        id="og1200x627"
                        style={{
                          width: "1200px",
                          height: "627px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          backgroundColor: "#fff",
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            alignSelf: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              alignSelf: "center",
                              paddingRight: "20px",
                              borderRight: "5px solid black",
                            }}
                          >
                            <img
                              src={cropImgUrl}
                              style={{
                                maxHeight: "300px",
                                minHeight: "200px",
                              }}
                              alt="Preview"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            color: selectedPrimaryColor,
                            fontFamily: "Lexend",
                            fontWeight: 600,
                            fontSize: "600px",
                            width: "600px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <p
                            style={{
                              lineHeight: "1",
                              fontSize: "18%",
                            }}
                          >
                            {values.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${styles["logo-forward"]} ${styles["create-option"]}`}
                    onClick={() => {
                      setLogoMethod("create");
                      setModalTitle(t("setup_page_53"));
                      setIsCreateLogo(true);
                    }}
                    style={{ height: isDesktop ? "100%" : "200px" }}
                  >
                    <BrushRounded />
                    {t("setup_page_50")}
                  </div>
                )}
              </Col>
            </Form.Group>
          )}
        </Container>
      </Modal.Body>
      {displayOverlay ? (
        <></>
      ) : logoMethod === "create" ? (
        isSelectLogo ? (
          <Modal.Footer>
            {isDesktop ? (
              <>
                <Button
                  type="button"
                  style={{
                    borderRadius: 32,
                    color: "#007bff",
                    backgroundColor: "white",
                    borderColor: "#007bff",
                  }}
                  onClick={() => {
                    setIsSelectLogo(false);
                    setImagesToUpload([]);
                  }}
                >
                  {t("login_page_back")}
                </Button>
                <Button
                  style={{ borderRadius: 32 }}
                  disabled={isLogoSelected ? false : true}
                  onClick={() => {
                    handleGenerateLogos();
                  }}
                >
                  {t("setup_page_15")}
                  <ArrowForwardRounded />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{ borderRadius: 32 }}
                  disabled={isLogoSelected ? false : true}
                  onClick={() => {
                    handleGenerateLogos();
                  }}
                >
                  {t("setup_page_15")}
                  <ArrowForwardRounded />
                </Button>
                <Button
                  type="button"
                  style={{
                    border: "none",
                    color: "#007bff",
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    setIsSelectLogo(false);
                    setImagesToUpload([]);
                  }}
                >
                  {t("login_page_back")}
                </Button>
              </>
            )}
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              type="button"
              style={{
                borderRadius: 32,
                color: "#007bff",
                backgroundColor: "white",
                borderColor: "#007bff",
              }}
              onClick={() => {
                setLogoMethod(null);
                setIsCreateLogo(false);
                setModalTitle(t("setup_page_53"));
              }}
            >
              {t("login_page_back")}
            </Button>
            <Button
              style={{ borderRadius: 32 }}
              disabled={
                iconSelected && logoText && isColorSelected ? false : true
              }
              onClick={() => {
                setIsSelectLogo(true);
                setModalTitle(t("setup_page_52"));
              }}
            >
              {t("setup_page_15")}
            </Button>
          </Modal.Footer>
        )
      ) : logoMethod === "upload" ? (
        <Modal.Footer>
          <Button
            type="button"
            style={{
              borderRadius: 32,
              color: "#007bff",
              backgroundColor: "white",
              borderColor: "#007bff",
            }}
            onClick={() => {
              setImgStrUrl("");
              setImagesToUpload([]);
              setLogoMethod(null);
            }}
          >
            {t("login_page_back")}
          </Button>
          <Button
            style={{ borderRadius: 32 }}
            disabled={isLogoPreviewDragged ? false : true}
            onClick={() => {
              handleGenerateLogos();
            }}
          >
            {t("setup_page_15")}
            <ArrowForwardRounded />
          </Button>
        </Modal.Footer>
      ) : isDesktop ? (
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button
            type="button"
            onClick={handleStepBack}
            style={{
              borderRadius: 32,
              color: "#007bff",
              backgroundColor: "white",
              borderColor: "#007bff",
            }}
          >
            {t("login_page_back")}
          </Button>
          <Button
            type="button"
            onClick={onSubmit}
            disabled={values.category === TCreateCategory.NONE}
            style={{ borderRadius: 32 }}
          >
            {t("setup_page_15")}
            <ArrowForwardRounded />
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button
            type="button"
            onClick={onSubmit}
            disabled={values.category === TCreateCategory.NONE}
            style={{ borderRadius: 32 }}
          >
            {t("setup_page_15")}
            <ArrowForwardRounded />
          </Button>
          <Button
            type="button"
            style={{
              border: "none",
              color: "#007bff",
              backgroundColor: "white",
            }}
            onClick={handleStepBack}
          >
            {t("login_page_back")}
          </Button>
        </Modal.Footer>
      )}
    </Form>
  );
};
