import {
  FindPageListByWebsiteSlugUsingGETRequest,
  PageResource,
} from "@urux/arm-of-dorne-api";
import { pageApi } from "api";
import { Page } from "editor/models/types";

export const findPageListByWebsiteSlugAsync = async (
  websiteSlug: string
): Promise<Page[]> => {
  const request: FindPageListByWebsiteSlugUsingGETRequest = {
    websiteSlug,
  };

  return (await pageApi.findPageListByWebsiteSlugUsingGET(request)).map(
    mapToPage
  );
};

const mapToPage = (resource: PageResource): Page => {
  return {
    id: resource.pageId!,
    version: resource.version!,
    slug: resource.slug!,
    template: resource.template!,
    title: resource.title!,
    description: resource.description!,
    content: resource.content!,
    updated: resource.updated!,
    isIndex: resource.isIndex!,
    isMaster: resource.isMaster!,
  };
};
