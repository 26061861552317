import {
  BackgroundColorProperty,
  DimensionProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { Layout } from "common/elements/types";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const transportChild2Template: ElementTemplates = {};

transportChild2Template["cover"] = TemplatesFactory.createDefaultCoverNoText(
  "1",
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://images.unsplash.com/photo-1561820790-f9d0f859abaf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    "no-repeat",
    "50%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(``),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 60)
);
