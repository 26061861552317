import { put, select, takeLatest } from "@redux-saga/core/effects";
import produce from "immer";
import { Reducer } from "redux";

import { ElementType, Layout } from "common/elements/types";
import {
  ClearChallengesFromDeletePageSuccessAction,
  CreateChallengeSuccessAction,
  CreateChildPagesFromTemplateAction,
  CreateOtherPagesUpdateReusables,
  EDITOR_CLEAR_CHALLENGE_FROM_PAGE_DELETE,
  EDITOR_CREATE_CHILD_PAGES_FROM_TEMPLATE,
  EDITOR_GET_PAGE_CHALLENGES_SUCCESS,
  EDITOR_LOAD_OTHER_PAGES_SUCCESS,
  EDITOR_PAGE_CREATED_FROM_TEMPLATE,
  EDITOR_PAGE_LATEST_VERSION_LOAD_SUCCEEDED,
  EDITOR_SAVE_OTHER_PAGES,
  EDITOR_TREATMENT_PAGE_LATEST_VERSION_LOAD_SUCCEEDED,
  EDITOR_UPDATE_CHALLENGE_VARIANTS_SUCCESS,
  GetPageChallengesActionSuccess,
  LoadOtherPagesSuccessAction,
  PageCreatedFromTemplateAction,
  PageCreationFromTemplateSucceededAction,
  PageLatestVersionLoadSucceededAction,
  PageSaveSucceededAction,
  SaveChallengeAction,
  SaveOtherPagesAction,
  setSelectedElement,
  TreatmentPageLatestVersionLoadSucceededAction,
  UpdateChallengeVariantsStatusActionSuccess,
} from "editor/components/impl/Editor/editorActions";
import {
  EDITOR_WEBSITE_LATEST_VERSION_REQUEST_SUCCEEDED,
  WebsiteLatestVersionRequestSucceededAction,
} from "editor/states/website";
import { returnTemplate } from "editor/templates/default";
import { returnTemplate as returnCompactTemplate } from "editor/templates/compact";
import { returnTemplate as returnLooseTemplate } from "editor/templates/loose";
import { returnTemplate as returnFoodTemplate } from "editor/templates/food";
import { returnTemplate as returnAuthorTemplate } from "editor/templates/author";
import { returnTemplate as returnWeddingTemplate } from "editor/templates/wedding";
import { returnTemplate as returnTransportTemplate } from "editor/templates/transport";
import { returnTemplate as returnMakeupTemplate } from "editor/templates/makeup";
import { returnTemplate as returnRealEstateTemplate } from "editor/templates/realestate";
import { returnTemplate as returnResumeTemplate } from "editor/templates/resume";
import { returnTemplate as returnCateringTemplate } from "editor/templates/catering";
import { returnTemplate as returnVinylTemplate } from "editor/templates/vinyl";
import { returnTemplate as returnLodgeTemplate } from "editor/templates/lodge";
import {
  childTemplates,
  sharedElements,
  TemplateKey,
  templates,
} from "editor/templates/types";
import {
  removeElementParentReference,
  renameElementId,
  renameElementIdParentReference,
} from "editor/elements/operations";
import {
  getSelectedElementId,
  getSelectedElementIndex,
} from "editor/selectors";
import { SharedElementConfigurationById } from "editor/elements/types";
import { logActivity, LogoutSuccessAction } from "auth/authActions";
import { editorPage } from "editor/components/impl/Editor/Editor";
import { PageVersion } from "editor/models/types";

export type State = {
  body: Layout;
  treamentBody: Layout;
  childrenBody: { [templateKey: string]: Layout };
  treatmentBodiesForCreate: { [masterSlug: string]: Layout };
  shared: Layout;
  sharedElementConfiguration: SharedElementConfigurationById;
  treatmentShared: Layout;
  treatmentSharedElementConfiguration: SharedElementConfigurationById;
  loadedBody: Layout | null;
  treatmentLoadedBody: Layout | null;
  loadedVersion: number | null;
  challenges: { [pageSlug: string]: Challenge | undefined };
  finishedChallenges: { [pageSlug: string]: Challenge[] | undefined };
  allChallenges: Challenge[] | null;
  insertedKey: string | null;
  otherPagesVersions: { [slug: string]: PageVersion };
  otherPageSlugs: string[] | null;
  sharedElementIdsToRemove: string[] | null;
  tests: { [pageSlug: string]: Test };
};

export type Test = {
  [elementId: string]: {
    currentChallenge: Challenge | undefined;
    finishedChallenges: Challenge[] | undefined;
  };
};

export type Challenge = {
  challengeId: number;
  elementId?: string;
  controlSlug?: string;
  treatmentSlug?: string;
  variants?: Variant[];
  result?: Result;
  isActive?: boolean;
  isAutoApply?: boolean;
  isTesting?: boolean;
  isCollecting?: boolean;
  isTreatmentInserted?: boolean;
  isFinished?: boolean;
  targetCollectionEndDate?: string;
  targetTestingEndDate?: string;
  testActualEndDate?: string;
};

export type Variant = {
  id?: number;
  elementName?: string;
  elementId?: string;
  property?: string;
  propertyDesc?: string;
  isCurrent?: boolean;
  isTested?: boolean;
  isControl?: boolean;
  isChampion?: boolean;
  wasWinner?: boolean;
  wasApplied?: boolean;
  componentProperty?: string;
  isMaster?: boolean;
};

export type ChallengesDisplay = {
  elementId: string;
  currentChallenge?: Challenge;
  finishedChallenges?: Challenge[];
};

export type Result = {};

export const EDITOR_TEMPLATE_INSERTED = "EDITOR_TEMPLATE_INSERTED";

export type TemplateInsertedAction = {
  type: typeof EDITOR_TEMPLATE_INSERTED;
  templateKey: string;
  parentElementId: string;
  index: number;
  isLogoCreated: boolean;
  websiteSlug?: string;
  pageSlug?: string | null;
};

export const templateInserted = (
  templateKey: string,
  parentElementId: string,
  index: number,
  isLogoCreated: boolean,
  websiteSlug?: string,
  pageSlug?: string | null
): TemplateInsertedAction => {
  return {
    type: EDITOR_TEMPLATE_INSERTED,
    templateKey,
    parentElementId,
    index,
    isLogoCreated,
    websiteSlug,
    pageSlug,
  };
};

export const EDITOR_SHARED_ELEMENT_INSERTED = "EDITOR_SHARED_ELEMENT_INSERTED";

export type SharedElementInsertedAction = {
  type: typeof EDITOR_SHARED_ELEMENT_INSERTED;
  sharedElementId: string;
  parentElementId: string;
  index: number;
};

export const sharedElementInserted = (
  sharedElementId: string,
  parentElementId: string,
  index: number
): SharedElementInsertedAction => {
  return {
    type: EDITOR_SHARED_ELEMENT_INSERTED,
    sharedElementId,
    parentElementId,
    index,
  };
};

export const EDITOR_ELEMENT_SHARED = "EDITOR_ELEMENT_SHARED";

export type ElementSharedAction = {
  type: typeof EDITOR_ELEMENT_SHARED;
  elementId: string;
  label: string;
};

export const elementShared = (
  elementId: string,
  label: string
): ElementSharedAction => {
  return {
    type: EDITOR_ELEMENT_SHARED,
    elementId,
    label,
  };
};

export const EDITOR_ELEMENT_UNSHARED = "EDITOR_ELEMENT_UNSHARED";

export type ElementUnsharedAction = {
  type: typeof EDITOR_ELEMENT_UNSHARED;
  elementId: string;
};

export const elementUnshared = (elementId: string): ElementUnsharedAction => {
  return {
    type: EDITOR_ELEMENT_UNSHARED,
    elementId,
  };
};

export const EDITOR_ELEMENT_REMOVED = "EDITOR_ELEMENT_REMOVED";

export type ElementRemovedAction = {
  type: typeof EDITOR_ELEMENT_REMOVED;
  elementId: string;
  pageSlug: string;
};

export const elementRemoved = (
  elementId: string,
  pageSlug: string
): ElementRemovedAction => {
  return {
    type: EDITOR_ELEMENT_REMOVED,
    elementId,
    pageSlug,
  };
};

export const EDITOR_ELEMENT_REARRANGE = "EDITOR_ELEMENT_REARRANGE";

type ElementRearrangeAction = {
  type: typeof EDITOR_ELEMENT_REARRANGE;
  parentElementId: string;
  newChildrenOrder: string[];
};

export const elementRearrange = (
  parentElementId: string,
  newChildrenOrder: string[]
): ElementRearrangeAction => {
  return {
    type: EDITOR_ELEMENT_REARRANGE,
    parentElementId,
    newChildrenOrder,
  };
};

export const EDITOR_PROPERTY_SET = "EDITOR_PROPERTY_SET";

export type PropertySetAction = {
  type: typeof EDITOR_PROPERTY_SET;
  elementId: string;
  propertyKey: string;
  value: any;
};

export const propertySet = (
  elementId: string,
  propertyKey: string,
  value: any
): PropertySetAction => {
  return {
    type: EDITOR_PROPERTY_SET,
    elementId,
    propertyKey,
    value,
  };
};

export const EDITOR_SAVE_LAYOUT_FROM_UNDOREDO =
  "EDITOR_SAVE_LAYOUT_FROM_UNDOREDO";

export type SaveLayoutFromUndoRedoAction = {
  type: typeof EDITOR_SAVE_LAYOUT_FROM_UNDOREDO;
  layout: Layout | null;
  version: number | null;
};

export const saveLayoutFromUndoRedo = (
  layout: Layout | null,
  version: number | null
): Action => {
  return {
    type: EDITOR_SAVE_LAYOUT_FROM_UNDOREDO,
    layout,
    version,
  };
};

export const EDITOR_CLEAR_INSERTED_ELEMENT_ID_AFTER_SCROLL =
  "EDITOR_CLEAR_INSERTED_ELEMENT_ID_AFTER_SCROLL";

export type ClearInsertedLayoutFromScrollAction = {
  type: typeof EDITOR_CLEAR_INSERTED_ELEMENT_ID_AFTER_SCROLL;
};

export const clearInsertedLayoutFromScroll = (): Action => {
  return {
    type: EDITOR_CLEAR_INSERTED_ELEMENT_ID_AFTER_SCROLL,
  };
};

export const EDITOR_UPDATE_CHALLENGE_STATUS = "EDITOR_UPDATE_CHALLENGE_STATUS";

export type UpdateChallengeStatusAction = {
  type: typeof EDITOR_UPDATE_CHALLENGE_STATUS;
  challengeId: number;
  elementId: string;
  isActive: boolean;
  callback: () => void;
};

export const updateChallengeStatus = (
  challengeId: number,
  elementId: string,
  isActive: boolean,
  callback: () => void
): Action => {
  return {
    type: EDITOR_UPDATE_CHALLENGE_STATUS,
    challengeId,
    elementId,
    isActive,
    callback,
  };
};

export const EDITOR_UPDATE_CHALLENGE_AUTO_APPLY =
  "EDITOR_UPDATE_CHALLENGE_AUTO_APPLY";

export type UpdateChallengeAutoApplyAction = {
  type: typeof EDITOR_UPDATE_CHALLENGE_AUTO_APPLY;
  challengeId: number;
  elementId: string;
  isAutoApply: boolean;
  callback: () => void;
};

export const updateChallengeAutoApply = (
  challengeId: number,
  elementId: string,
  isAutoApply: boolean,
  callback: () => void
): Action => {
  return {
    type: EDITOR_UPDATE_CHALLENGE_AUTO_APPLY,
    challengeId,
    elementId,
    isAutoApply,
    callback,
  };
};

export const EDITOR_UPDATE_CHALLENGE_CONTROL =
  "EDITOR_UPDATE_CHALLENGE_CONTROL";

export type UpdateChallengeControlAction = {
  type: typeof EDITOR_UPDATE_CHALLENGE_CONTROL;
  elementId: string;
  pageSlug: string;
  callback: () => void;
};

export const updateChallengeControl = (
  elementId: string,
  pageSlug: string,
  callback: () => void
): Action => {
  return {
    type: EDITOR_UPDATE_CHALLENGE_CONTROL,
    elementId,
    pageSlug,
    callback,
  };
};

export const EDITOR_UPDATE_CHALLENGE_VARIANTS =
  "EDITOR_UPDATE_CHALLENGE_VARIANTS";

export type UpdateChallengeVariantsStatusAction = {
  type: typeof EDITOR_UPDATE_CHALLENGE_VARIANTS;
  pageSlug: string;
};

export const updateChallengeVariants = (pageSlug: string): Action => {
  return {
    type: EDITOR_UPDATE_CHALLENGE_VARIANTS,
    pageSlug,
  };
};

export const ADMIN_ALL_CHALLENGES = "ADMIN_ALL_CHALLENGES";

export type GetAllChallengesAction = {
  type: typeof ADMIN_ALL_CHALLENGES;
};

export const getAllChallenges = (): Action => {
  return {
    type: ADMIN_ALL_CHALLENGES,
  };
};

export const ADMIN_PAGES_CHALLENGES = "ADMIN_PAGES_CHALLENGES";

export type GetPagesChallengesAction = {
  type: typeof ADMIN_PAGES_CHALLENGES;
  pageSlug: string;
};

export const getPagesChallenges = (pageSlug: string): Action => {
  return {
    type: ADMIN_PAGES_CHALLENGES,
    pageSlug,
  };
};

export const ADMIN_PAGES_CHALLENGES_SUCCESS = "ADMIN_PAGES_CHALLENGES_SUCCESS";

export type GetPagesChallengesSuccessAction = {
  type: typeof ADMIN_PAGES_CHALLENGES_SUCCESS;
  pageSlug: string;
  challenges: Challenge[];
};

export const getPagesChallengesSuccessAction = (
  pageSlug: string,
  challenges: Challenge[]
): Action => {
  return {
    type: ADMIN_PAGES_CHALLENGES_SUCCESS,
    pageSlug,
    challenges,
  };
};

export const ADMIN_ALL_CHALLENGES_SUCCESS = "ADMIN_ALL_CHALLENGES_SUCCESS";

export type GetAllChallengesSuccessAction = {
  type: typeof ADMIN_ALL_CHALLENGES_SUCCESS;
  challenges: Challenge[];
};

export const getAllChallengesSuccessAction = (
  challenges: Challenge[]
): Action => {
  return {
    type: ADMIN_ALL_CHALLENGES_SUCCESS,
    challenges,
  };
};

type Action =
  | { type: "_" }
  | TemplateInsertedAction
  | SharedElementInsertedAction
  | ElementSharedAction
  | ElementUnsharedAction
  | ElementRemovedAction
  | ElementRearrangeAction
  | PropertySetAction
  | PageCreatedFromTemplateAction
  | PageLatestVersionLoadSucceededAction
  | WebsiteLatestVersionRequestSucceededAction
  | SaveLayoutFromUndoRedoAction
  | TreatmentPageLatestVersionLoadSucceededAction
  | PageCreationFromTemplateSucceededAction
  | SaveChallengeAction
  | PageSaveSucceededAction
  | GetPageChallengesActionSuccess
  | UpdateChallengeStatusAction
  | UpdateChallengeVariantsStatusAction
  | UpdateChallengeVariantsStatusActionSuccess
  | GetPagesChallengesAction
  | GetAllChallengesAction
  | GetPagesChallengesSuccessAction
  | GetAllChallengesSuccessAction
  | UpdateChallengeControlAction
  | ClearInsertedLayoutFromScrollAction
  | CreateOtherPagesUpdateReusables
  | CreateChildPagesFromTemplateAction
  | ClearChallengesFromDeletePageSuccessAction
  | CreateChallengeSuccessAction
  | LoadOtherPagesSuccessAction
  | SaveOtherPagesAction
  | UpdateChallengeAutoApplyAction
  | LogoutSuccessAction;

const initialState: State = {
  body: {
    root: {
      elementKey: "root",
      elementId: "root",
      templateKey: "default",
      elementChildrenId: [],
    },
  },
  treamentBody: {
    root: {
      elementKey: "root",
      elementId: "root",
      templateKey: "default",
      elementChildrenId: [],
    },
  },
  treatmentBodiesForCreate: {},
  childrenBody: {},
  shared: {},
  sharedElementConfiguration: {},
  treatmentShared: {},
  treatmentSharedElementConfiguration: {},
  loadedBody: null,
  treatmentLoadedBody: null,
  loadedVersion: null,
  challenges: {},
  finishedChallenges: {},
  allChallenges: null,
  insertedKey: null,
  otherPagesVersions: {},
  otherPageSlugs: null,
  sharedElementIdsToRemove: null,
  tests: {},
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case EDITOR_PAGE_CREATED_FROM_TEMPLATE:
      return handlePageCreatedFromTemplate(state, action);

    case EDITOR_PAGE_LATEST_VERSION_LOAD_SUCCEEDED:
      return handlePageLatestVersionLoadSucceeded(state, action);

    case EDITOR_TREATMENT_PAGE_LATEST_VERSION_LOAD_SUCCEEDED:
      return handleTreatmentPageLatestVersionLoadSucceeded(state, action);

    case EDITOR_WEBSITE_LATEST_VERSION_REQUEST_SUCCEEDED:
      return handleWebsiteLatestVersionRequestSucceeded(state, action);

    case EDITOR_TEMPLATE_INSERTED:
      return handleTemplateInserted(state, action);

    case EDITOR_SHARED_ELEMENT_INSERTED:
      return handleSharedElementInserted(state, action);

    case EDITOR_ELEMENT_SHARED:
      return handleElementShared(state, action);

    case EDITOR_ELEMENT_UNSHARED:
      let stateDraft: State = handleElementUnshared(state, action);
      return handleElementSharedForSiblingPages(stateDraft);

    case EDITOR_ELEMENT_REMOVED:
      return handleElementRemoved(state, action);

    case EDITOR_ELEMENT_REARRANGE:
      return handleRearrangeLayout(state, action);

    case EDITOR_PROPERTY_SET:
      return handlePropertySet(state, action);

    case EDITOR_SAVE_LAYOUT_FROM_UNDOREDO:
      return handleSaveLayout(state, action);

    case EDITOR_UPDATE_CHALLENGE_VARIANTS_SUCCESS:
      return handleUpdateChallengeVariantsSuccess(state, action);

    case EDITOR_UPDATE_CHALLENGE_VARIANTS:
      return handleUpdateChallengeVariants(state, action);

    case ADMIN_PAGES_CHALLENGES_SUCCESS:
      return handleGetAllPagesChallenges(state, action);

    case EDITOR_CLEAR_INSERTED_ELEMENT_ID_AFTER_SCROLL:
      return handleClearInsertedElementIdAfterSCroll(state, action);

    case EDITOR_CREATE_CHILD_PAGES_FROM_TEMPLATE:
      return handleCreateChildPagesFromTemplate(state, action);

    case EDITOR_CLEAR_CHALLENGE_FROM_PAGE_DELETE:
      return handleClearChallengesFromDeletePageSuccess(state, action);

    case EDITOR_LOAD_OTHER_PAGES_SUCCESS:
      return handleLoadOtherPages(state, action);

    case EDITOR_SAVE_OTHER_PAGES:
      return handleSaveOtherPages(state, action);

    case EDITOR_GET_PAGE_CHALLENGES_SUCCESS:
      return handleGetPageChallengesSuccess(state, action);

    case ADMIN_ALL_CHALLENGES_SUCCESS:
      return handleGetAllChallenges(state, action);

    case "AUTH_LOGOUT_SUCCESS":
      return handleLogoutSuccess(state, action);

    default:
      return state;
  }
};

const handleLogoutSuccess = (state: State, _: LogoutSuccessAction): State => {
  return {
    ...state,
    body: {
      root: {
        elementKey: "root",
        elementId: "root",
        templateKey: "default",
        elementChildrenId: [],
      },
    },
    treamentBody: {
      root: {
        elementKey: "root",
        elementId: "root",
        templateKey: "default",
        elementChildrenId: [],
      },
    },
    treatmentBodiesForCreate: {},
    childrenBody: {},
    shared: {},
    sharedElementConfiguration: {},
    treatmentShared: {},
    treatmentSharedElementConfiguration: {},
    loadedBody: null,
    treatmentLoadedBody: null,
    loadedVersion: null,
    challenges: {},
    finishedChallenges: {},
    allChallenges: null,
    insertedKey: null,
    otherPagesVersions: {},
    otherPageSlugs: null,
    sharedElementIdsToRemove: null,
    tests: {},
  };
};

const handleSaveOtherPages = (
  state: State,
  action: SaveOtherPagesAction
): State =>
  produce(state, (draft) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [pageKey, pageValue] of Object.entries(
      state.otherPagesVersions
    )) {
      let pageVersion: PageVersion = state.otherPagesVersions[pageKey];
      let content: Layout = JSON.parse(pageVersion.content);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(action.sharedLayout)) {
        if (content[key]) {
          content[key] = action.sharedLayout[key];
          draft.otherPagesVersions[pageKey].content = JSON.stringify(content);
        }
      }
    }
  });

const handleLoadOtherPages = (
  state: State,
  action: LoadOtherPagesSuccessAction
): State =>
  produce(state, (draft) => {
    draft.otherPagesVersions = {};
    draft.otherPageSlugs = null;
    let otherPageSlugs: string[] = [];

    action.otherPages.forEach((otherPage) => {
      draft.otherPagesVersions[otherPage.slug] = otherPage;
      otherPageSlugs.push(otherPage.slug);
    });
    draft.otherPageSlugs = otherPageSlugs;
  });

const handleCreateChildPagesFromTemplate = (
  state: State,
  action: CreateChildPagesFromTemplateAction
): State =>
  produce(state, (draft) => {
    const sharedElementsFromTemplate = sharedElements[action.templateKey];
    const childrenTemplate = childTemplates[action.templateKey];
    childrenTemplate.forEach((childTemplate) => {
      let childLayout: Layout = {
        root: {
          elementKey: "root",
          elementId: "root",
          templateKey: action.templateKey,
          elementChildrenId: [],
        },
      };
      let index = 0;
      for (const elementTemplateKey in childTemplate.template.template) {
        insertTemplate(
          childLayout,
          childTemplate.template.template[elementTemplateKey],
          "root",
          index++,
          action.isLogoCreated,
          action.templateKey
        );
      }

      if (sharedElementsFromTemplate) {
        let sharedElementKeysAdded: string[] = [];
        sharedElementsFromTemplate.forEach((sharedElement) => {
          for (const rootChildInShared in state.shared) {
            if (
              sharedElement.elementKey &&
              sharedElement.elementKey ===
                state.shared[rootChildInShared].elementKey &&
              sharedElementKeysAdded.indexOf(sharedElement.elementKey) === -1
            ) {
              sharedElementKeysAdded.push(sharedElement.elementKey);
              childLayout[rootChildInShared] = state.shared[rootChildInShared];

              if (state.shared[rootChildInShared].elementChildrenId) {
                copyElementsShared(
                  state,
                  childLayout,
                  state.shared[rootChildInShared]
                );
              }

              if (childLayout["root"].elementChildrenId) {
                childLayout["root"].elementChildrenId.splice(
                  sharedElement.position,
                  0,
                  rootChildInShared
                );
              }
            }
          }
        });
      }

      draft.childrenBody[childTemplate.templateName] = childLayout;
    });
  });

const copyElements = (
  state: State,
  draftLayout: Layout,
  element: ElementType
) => {
  element.elementChildrenId?.forEach((elementId) => {
    draftLayout[elementId] = state.body[elementId];
    if (state.body[elementId].elementChildrenId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      copyElements(state, draftLayout, state.body[elementId]);
    }
  });
};

const copyElementsShared = (
  state: State,
  draftLayout: Layout,
  element: ElementType
) => {
  element.elementChildrenId?.forEach((elementId) => {
    draftLayout[elementId] = state.shared[elementId];
    if (state.shared[elementId].elementChildrenId) {
      copyElementsShared(state, draftLayout, state.shared[elementId]);
    }
  });
};

const handleClearInsertedElementIdAfterSCroll = (
  state: State,
  action: ClearInsertedLayoutFromScrollAction
): State =>
  produce(state, (draft) => {
    draft.insertedKey = null;
  });

const handleGetAllChallenges = (
  state: State,
  action: GetAllChallengesSuccessAction
): State =>
  produce(state, (draft) => {
    draft.allChallenges = action.challenges;
  });

const handleGetAllPagesChallenges = (
  state: State,
  action: GetPagesChallengesSuccessAction
): State =>
  produce(state, (draft) => {
    if (action.challenges && action.challenges.length > 0) {
      let finishedChallenges: Challenge[] = [];
      let tests: Test = {};
      action.challenges.forEach((challenge) => {
        if (!challenge.isActive && challenge.isFinished) {
          finishedChallenges.push(challenge);
        } else {
          draft.challenges[action.pageSlug] = challenge;
        }

        let elementId;
        if (elementId) {
          let currentTest = tests[elementId];
          if (!currentTest) {
            currentTest = {
              currentChallenge: undefined,
              finishedChallenges: [],
            };
          }
          if (!challenge.isActive && challenge.isFinished) {
            currentTest.finishedChallenges?.push(challenge);
          } else {
            currentTest.currentChallenge = challenge;
          }
          tests[elementId] = currentTest;
        }
      });
      draft.finishedChallenges[action.pageSlug] = finishedChallenges;
    }
  });

const handleGetPageChallengesSuccess = (
  state: State,
  action: GetPageChallengesActionSuccess
): State =>
  produce(state, (draft) => {
    if (action.challenges && action.challenges.length > 0) {
      let finishedChallenges: Challenge[] = [];
      let tests: Test = {};
      action.challenges.forEach((challenge) => {
        if (!challenge.isActive && challenge.isFinished) {
          finishedChallenges.push(challenge);
        } else {
          draft.challenges[action.pageSlug] = challenge;
        }

        let elementId = challenge.elementId;
        if (elementId) {
          let currentTest = tests[elementId];
          if (!currentTest) {
            currentTest = {
              currentChallenge: undefined,
              finishedChallenges: [],
            };
          }
          if (!challenge.isActive && challenge.isFinished) {
            currentTest.finishedChallenges?.push(challenge);
          } else {
            currentTest.currentChallenge = challenge;
          }
          tests[elementId] = currentTest;
        }
      });
      draft.tests[action.pageSlug] = tests;
      draft.finishedChallenges[action.pageSlug] = finishedChallenges;
    }
  });

const handleClearChallengesFromDeletePageSuccess = (
  state: State,
  action: ClearChallengesFromDeletePageSuccessAction
): State =>
  produce(state, (draft) => {
    draft.challenges[action.pageSlug] = undefined;
  });

const handleSaveLayout = (
  state: State,
  action: SaveLayoutFromUndoRedoAction
): State =>
  produce(state, (draft) => {
    if (action.layout !== null) {
      draft.body = action.layout;
    }
    draft.loadedBody = action.layout;
    draft.loadedVersion = action.version;
  });

const handlePageCreatedFromTemplate = (
  state: State,
  action: PageCreatedFromTemplateAction
): State =>
  produce(state, (draft) => {
    draft.body = {
      root: {
        elementKey: "root",
        elementId: "root",
        templateKey: action.templateKey,
        elementChildrenId: [],
      },
    };

    if (action.isIndex) {
      let index = 0;
      const template = templates[action.templateKey];
      switch (action.templateKey) {
        case TemplateKey.Compact:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnCompactTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Compact
            );
          }
          break;
        case TemplateKey.Loose:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnLooseTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Loose
            );
          }
          break;
        case TemplateKey.Food:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnFoodTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Food
            );
          }
          break;
        case TemplateKey.Author:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnAuthorTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Author
            );
          }
          break;
        case TemplateKey.Wedding:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnWeddingTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Wedding
            );
          }
          break;
        case TemplateKey.Transport:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnTransportTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Transport
            );
          }
          break;
        case TemplateKey.Makeup:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnMakeupTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Makeup
            );
          }
          break;
        case TemplateKey.Realestate:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnRealEstateTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Realestate
            );
          }
          break;
        case TemplateKey.Resume:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnResumeTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Resume
            );
          }
          break;
        case TemplateKey.Catering:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnCateringTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Catering
            );
          }
          break;
        case TemplateKey.Vinyl:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnVinylTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Vinyl
            );
          }
          break; //returnLodgeTemplate
        case TemplateKey.Lodge:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              returnLodgeTemplate(
                elementTemplateKey,
                action.isLogoCreated,
                action.websiteSlug
              ),
              "root",
              index++,
              action.isLogoCreated,
              TemplateKey.Lodge
            );
          }
          break;
        default:
          for (const elementTemplateKey in template) {
            insertTemplate(
              draft.body,
              template[elementTemplateKey],
              "root",
              index++,
              action.isLogoCreated,
              "default"
            );
          }
      }
    }

    if (action.isMultiPage) {
      const element = { ...draft.body["root"] };
      const sharedElementsFromTemplate = sharedElements[action.templateKey];

      let existingSharedElementKeysAdded: { key: string; elem: any }[] = [];
      const existingSharedElements = { ...state.body["root"] };
      for (const childElementId of existingSharedElements.elementChildrenId ||
        []) {
        sharedElementsFromTemplate.forEach((sharedElementDetails) => {
          if (
            sharedElementDetails.elementKey &&
            sharedElementDetails.elementKey ===
              state.body[childElementId]!.elementKey &&
            action.templateKey === state.body[childElementId]!.templateKey &&
            childElementId.startsWith(sharedElementPrefix)
          ) {
            existingSharedElementKeysAdded.push({
              key: sharedElementDetails.elementKey,
              elem: state.body[childElementId],
            });
          }
        });
      }

      for (const childElementId of element.elementChildrenId || []) {
        let elem = draft.body[childElementId] as any;
        sharedElementsFromTemplate.forEach((sharedElementDetails) => {
          if (sharedElementDetails.elementKey === elem.elementKey) {
            const pos = existingSharedElementKeysAdded
              .map(function (e: any) {
                return e.key;
              })
              .indexOf(sharedElementDetails.elementKey);
            if (pos === -1) {
              const sharedElementId = shareElementAndChildren(
                draft.body,
                draft.shared,
                elem.elementId
              );
              const sharedElement = draft.shared[sharedElementId];
              draft.sharedElementConfiguration[sharedElementId] = {
                elementKey: sharedElement.elementKey,
                label: capitalizeFirstLetter(sharedElement.elementKey),
              };
            } else {
              if (existingSharedElementKeysAdded[pos]) {
                let existingElem = existingSharedElementKeysAdded[pos].elem;
                if (existingElem && existingElem.elementId) {
                  draft.shared[existingElem.elementId] =
                    draft.body[existingElem.elementId];
                  draft.sharedElementConfiguration[existingElem.elementId] = {
                    elementKey: existingElem.elementKey,
                    label: capitalizeFirstLetter(existingElem.elementKey),
                  };
                  delete draft.body[elem.elementId];
                }
              }
            }
          }
        });
      }
    }
  });

const handleUpdateChallengeVariantsSuccess = (
  state: State,
  action: UpdateChallengeVariantsStatusActionSuccess
): State =>
  produce(state, (draft) => {
    draft.challenges[action.pageSlug]!.isTreatmentInserted = false;
    draft.challenges[action.pageSlug]!.elementId = action.elementId;
  });

const handleUpdateChallengeVariants = (
  state: State,
  action: UpdateChallengeVariantsStatusAction
): State =>
  produce(state, (draft) => {
    draft.challenges[action.pageSlug]!.isTreatmentInserted = true;
  });

const handlePageLatestVersionLoadSucceeded = (
  state: State,
  action: PageLatestVersionLoadSucceededAction
): State =>
  produce(state, (draft) => {
    draft.body = action.layout;
    if (!action.treatmentSlug) {
      for (const [key, value] of Object.entries(action.layout)) {
        if (key.startsWith(sharedElementPrefix)) {
          draft.shared[key] = value;
        }
      }
    }
  });

const handleTreatmentPageLatestVersionLoadSucceeded = (
  state: State,
  action: TreatmentPageLatestVersionLoadSucceededAction
): State =>
  produce(state, (draft) => {
    draft.treamentBody = action.layout;
  });

const handleWebsiteLatestVersionRequestSucceeded = (
  state: State,
  action: WebsiteLatestVersionRequestSucceededAction
): State =>
  produce(state, (draft) => {
    draft.shared = action.sharedContent;
    draft.sharedElementConfiguration = action.sharedContentMetadata;
  });

const handleTemplateInserted = (
  state: State,
  action: TemplateInsertedAction
): State => {
  return produce(state, (draft) => {
    draft.insertedKey = insertTemplate(
      draft.body,
      returnTemplate(
        action.templateKey,
        action.isLogoCreated,
        action.websiteSlug
      ),
      action.parentElementId,
      action.index,
      action.isLogoCreated,
      action.templateKey,
      action.pageSlug ? draft.challenges[action.pageSlug] : undefined
    );
  });
};

const handleSharedElementInserted = (
  state: State,
  action: SharedElementInsertedAction
): State =>
  produce(state, (draft) => {
    insertSharedElement(
      draft.body,
      action.sharedElementId,
      action.parentElementId,
      action.index
    );
  });

const sharedElementPrefix = "shared-";

const handleElementShared = (
  state: State,
  action: ElementSharedAction
): State =>
  produce(state, (draft) => {
    const sharedElementId = shareElementAndChildren(
      draft.body,
      draft.shared,
      action.elementId
    );
    const sharedElement = draft.shared[sharedElementId];
    draft.sharedElementConfiguration[sharedElementId] = {
      elementKey: sharedElement.elementKey,
      label: capitalizeFirstLetter(sharedElement.elementKey),
    };
  });

function capitalizeFirstLetter(toCapitalize: string) {
  return toCapitalize.charAt(0).toUpperCase() + toCapitalize.slice(1);
}

const shareElementAndChildren = (
  body: Layout,
  shared: Layout,
  elementId: string
): string => {
  const sharedElementId = sharedElementPrefix + elementId;
  renameElementId(body, elementId, sharedElementId);

  for (const childElementId of body[sharedElementId].elementChildrenId || []) {
    shareElementAndChildren(body, shared, childElementId);
  }

  shared[sharedElementId] = body[sharedElementId];
  delete body[sharedElementId];

  return sharedElementId;
};

const handleElementSharedForSiblingPages = (state: State): State =>
  produce(state, (draft) => {
    if (state.sharedElementIdsToRemove) {
      // Loop through sibling pages
      let sharedElementIdsToRemove: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [pageKey, pageValue] of Object.entries(
        state.otherPagesVersions
      )) {
        let pageVersion: PageVersion = state.otherPagesVersions[pageKey];
        // Make layout body
        let content: Layout = JSON.parse(pageVersion.content);
        let sharedContent: Layout = {};
        // Find layout using sharedElement
        state.sharedElementIdsToRemove.forEach((sharedElementIdToRemove) => {
          if (content[sharedElementIdToRemove]) {
            for (const [elementId, element] of Object.entries(content)) {
              if (
                content[elementId] &&
                elementId.startsWith(sharedElementPrefix)
              ) {
                sharedContent[elementId] = element;
                delete content[elementId];
              }
            }
            unshareElementAndChildren(
              content,
              sharedContent,
              sharedElementIdToRemove,
              sharedElementIdsToRemove
            );
          }
          draft.otherPagesVersions[pageKey].content = JSON.stringify(content);
        });
      }
    }
  });

const handleElementUnshared = (
  state: State,
  action: ElementUnsharedAction
): State =>
  produce(state, (draft) => {
    let sharedElementIdsToRemove: string[] = [];
    unshareElementAndChildren(
      draft.body,
      draft.shared,
      action.elementId,
      sharedElementIdsToRemove
    );
    delete draft.sharedElementConfiguration[action.elementId];
    draft.sharedElementIdsToRemove = sharedElementIdsToRemove;
  });

const unshareElementAndChildren = (
  body: Layout,
  shared: Layout,
  sharedElementId: string,
  sharedElementIdsToRemove: string[]
): void => {
  const elementId = sharedElementId.replace(
    new RegExp("^" + sharedElementPrefix),
    ""
  );

  sharedElementIdsToRemove.push(sharedElementId);
  renameElementId(shared, sharedElementId, elementId);
  renameElementIdParentReference(body, "root", sharedElementId, elementId);

  for (const childElementId of shared[elementId].elementChildrenId || []) {
    unshareElementAndChildren(
      body,
      shared,
      childElementId,
      sharedElementIdsToRemove
    );
  }

  body[elementId] = shared[elementId];
  delete shared[elementId];
};

const handleElementRemoved = (
  state: State,
  action: ElementRemovedAction
): State =>
  produce(state, (draft) => {
    draft.body = removeElementAndParentReference(
      draft.body,
      action.elementId,
      draft.challenges[action.pageSlug]!
    );
  });

const handlePropertySet = (state: State, action: PropertySetAction): State => {
  return produce(state, (draft) => {
    if (action.elementId in draft.shared) {
      draft.shared[action.elementId][action.propertyKey] = action.value;
    }

    if (action.elementId in draft.body) {
      draft.body[action.elementId][action.propertyKey] = action.value;
    }
  });
};

const insertSharedElement = (
  layout: Layout,
  sharedElementId: string,
  parentElementId: string,
  index: number
): void => {
  layout[parentElementId].elementChildrenId!.splice(index, 0, sharedElementId);
};

const generateId = () => Math.random().toString(36).substr(2, 9);

const handleRearrangeLayout = (
  state: State,
  action: ElementRearrangeAction
): State =>
  produce(state, (draft) => {
    draft.body = rearrangeLayout(
      draft.body,
      action.parentElementId,
      action.newChildrenOrder
    );
  });

const rearrangeLayout = (
  layout: Layout,
  parentElementId: string,
  newChildrenOrder: string[]
) => {
  layout[parentElementId].elementChildrenId = newChildrenOrder;
  return layout;
};

const insertTemplate = (
  layout: Layout,
  template: Layout,
  parentElementId: string,
  index: number,
  isLogoCreated: boolean,
  templateKey: string,
  draftChallenge?: Challenge
): string | null => {
  const templateIdToId: { [_: string]: string } = {};
  let insertedTreatment = false;
  let insertedKey = null;
  for (const templateId in template) {
    const id = generateId();

    templateIdToId[templateId] = id;
    if (insertedKey === null) {
      insertedKey = id;
    }

    const parentTemplateId =
      template[templateId].elementParentId || parentElementId;
    const parentId = templateIdToId[parentTemplateId]
      ? templateIdToId[parentTemplateId]
      : parentTemplateId;
    const element = {
      ...template[templateId],
      elementId: id,
      elementParentId: parentId,
      elementChildrenId: [],
      templateKey: templateKey,
    };

    layout[id] = element;

    if (parentId === parentElementId) {
      layout[parentId].elementChildrenId!.splice(index, 0, id);
    } else {
      layout[parentId].elementChildrenId!.push(id);
    }
    if (
      element.elementKey === "button" ||
      element.elementKey === "messenger" ||
      element.elementKey === "whatsapp"
    ) {
      let elem = element as any;
      if (elem.treatmentProperty.isTreatment) {
        insertedTreatment = true;
      }
    }
  }
  if (insertedTreatment && draftChallenge) {
    draftChallenge.isTreatmentInserted = true;
  }
  return insertedKey;
};

const removeElementAndParentReference = (
  layout: Layout,
  elementId: string,
  challenge: Challenge
): Layout => {
  const parentElementId = layout[elementId].elementParentId;
  let nextLayout = removeElement(layout, elementId, challenge);
  if (!parentElementId) {
    return nextLayout;
  }
  nextLayout = removeElementParentReference(
    nextLayout,
    parentElementId,
    elementId
  );
  return removeElementAndParentReferenceIfEmpty(
    nextLayout,
    parentElementId,
    challenge
  );
};

const removeElementAndParentReferenceIfEmpty = (
  layout: Layout,
  elementId: string,
  challenge: Challenge
): Layout => {
  const element = layout[elementId];
  if (
    elementId === "root" ||
    !element.elementChildrenId ||
    element.elementChildrenId.length
  ) {
    return layout;
  }
  return removeElementAndParentReference(layout, elementId, challenge);
};

const removeElement = (
  layout: Layout,
  elementId: string,
  challenge?: Challenge
): Layout => {
  const element = { ...layout[elementId] };
  for (const childElementId of element.elementChildrenId || []) {
    layout = removeElement(layout, childElementId, challenge);
  }
  delete layout[elementId];
  if (challenge) {
    if (elementId === challenge.elementId) {
      challenge.variants = undefined;
      challenge.isTreatmentInserted = true;
    }
  }
  return layout;
};

function* resetSelectedElementAfterShare(action: ElementSharedAction) {
  const selectedElementId: string = yield select(getSelectedElementId);
  const selectedElementIndex: number = yield select(getSelectedElementIndex);
  if (action.elementId === selectedElementId) {
    yield put(
      setSelectedElement(
        sharedElementPrefix + action.elementId,
        selectedElementIndex
      )
    );
  }
}

function* resetSelectedElementAfterUnshare(action: ElementUnsharedAction) {
  const selectedElementId: string = yield select(getSelectedElementId);
  const selectedElementIndex: number = yield select(getSelectedElementIndex);
  if (action.elementId === selectedElementId) {
    const elementId = action.elementId.replace(
      new RegExp("^" + sharedElementPrefix),
      ""
    );
    yield put(setSelectedElement(elementId, selectedElementIndex));
  }
}

function* logPropertySet(action: PropertySetAction) {
  if (action.value) {
    yield put(
      logActivity(
        "SET ELEMENT PROPERTY",
        editorPage,
        action.propertyKey + " / " + action.value
      )
    );
  }
}

export function* rootSaga() {
  yield takeLatest(EDITOR_ELEMENT_SHARED, resetSelectedElementAfterShare);
  yield takeLatest(EDITOR_ELEMENT_UNSHARED, resetSelectedElementAfterUnshare);
  yield takeLatest(EDITOR_PROPERTY_SET, logPropertySet);
}
