import { fork } from "@redux-saga/core/effects";
import { combineReducers } from "redux";

import {
  reducer as pageReducer,
  State as PageState,
  rootSaga as pageSaga,
} from "public/states/page";

export const reducer = combineReducers({
  page: pageReducer,
});

export type State = {
  page: PageState;
};

export function* saga() {
  yield fork(pageSaga);
}
