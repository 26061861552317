import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal, Form, ListGroup, Tabs, Tab } from "react-bootstrap";
import * as yup from "yup";

import { StepProps } from "./EditorWizard";
import { TemplateKey } from "../../../templates/types";

import styles from "./EditorWizard.module.scss";
import { Button } from "editor/components/base/Button/Button";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  CloudCircleRounded,
  Facebook,
  WhatsApp,
} from "@material-ui/icons";

import { useTranslation } from "react-i18next";

const schema = yup.object({
  templateKey: yup.mixed<TemplateKey>().required(),
  primaryFont: yup.string(),
  secondaryFont: yup.string(),
});

type FormValues = yup.InferType<typeof schema>;

export const EditorWizardStep0a: React.FunctionComponent<StepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleUpdateFonts,
  parentPrimaryFont,
  parentSecondaryFont,
  activeFont,
  website,
  handleJumpForward,
}) => {
  const { t } = useTranslation();
  const onSubmit = (values: FormValues) => {
    handleSaveAndContinue(values);
  };

  const [selectedPrimaryFont, setSelectedPrimaryFont] = useState(
    `"Roboto", sans-serif`
  );
  const [selectedSecondaryFont, setSelectedSecondaryFont] = useState(
    `"Noto Sans JP", sans-serif`
  );
  const [tempPrimaryFont, setTempPrimaryFont] = useState(
    `"Roboto", sans-serif`
  );
  const [tempSecondaryFont, setTempSecondaryFont] = useState(
    `"Noto Sans JP", sans-serif`
  );
  const [isDefAActive, setIsDefAActive] = useState(true);
  const [isDefBActive, setIsDefBActive] = useState(false);
  const [isDefCActive, setIsDefCActive] = useState(false);
  const [isDefDActive, setIsDefDActive] = useState(false);
  const [isDefEActive, setIsDefEActive] = useState(false);
  const [isDefFActive, setIsDefFActive] = useState(false);
  const [isDefGActive, setIsDefGActive] = useState(false);
  const [isDefHActive, setIsDefHActive] = useState(false);
  const [isDefIActive, setIsDefIActive] = useState(false);

  const [isDef2AActive, setIsDef2AActive] = useState(true);
  const [isDef2BActive, setIsDef2BActive] = useState(false);
  const [isDef2CActive, setIsDef2CActive] = useState(false);
  const [isDef2DActive, setIsDef2DActive] = useState(false);
  const [isDef2EActive, setIsDef2EActive] = useState(false);
  const [isDef2FActive, setIsDef2FActive] = useState(false);
  const [isDef2GActive, setIsDef2GActive] = useState(false);
  const [isDef2HActive, setIsDef2HActive] = useState(false);

  useEffect(() => {
    if (website.logoCreated) {
      handleJumpForward();
    }
  }, [handleJumpForward, website.logoCreated]);

  useEffect(() => {
    if (parentPrimaryFont && parentPrimaryFont !== "") {
      setSelectedPrimaryFont(parentPrimaryFont);
      setTempPrimaryFont(tempPrimaryFont);
    } else {
      setSelectedPrimaryFont(`"Roboto", sans-serif`);
      setTempPrimaryFont(`"Roboto", sans-serif`);
    }

    if (parentSecondaryFont && parentSecondaryFont !== "") {
      setSelectedSecondaryFont(parentSecondaryFont);
      setTempSecondaryFont(parentSecondaryFont);
    } else {
      setSelectedSecondaryFont(`"Noto Sans JP", sans-serif`);
      setTempSecondaryFont(`"Noto Sans JP", sans-serif`);
    }

    if (parentPrimaryFont === `"Roboto", sans-serif`) {
      setIsDefAActive(true);
      setIsDefBActive(false);
      setIsDefCActive(false);
      setIsDefDActive(false);
      setIsDefEActive(false);
      setIsDefFActive(false);
      setIsDefGActive(false);
      setIsDefHActive(false);
      setIsDefIActive(false);
    } else if (parentPrimaryFont === `"Lora", serif`) {
      setIsDefAActive(false);
      setIsDefBActive(true);
      setIsDefCActive(false);
      setIsDefDActive(false);
      setIsDefEActive(false);
      setIsDefFActive(false);
      setIsDefGActive(false);
      setIsDefHActive(false);
      setIsDefIActive(false);
    } else if (parentPrimaryFont === `"Spartan", sans-serif`) {
      setIsDefAActive(false);
      setIsDefBActive(false);
      setIsDefCActive(true);
      setIsDefDActive(false);
      setIsDefEActive(false);
      setIsDefFActive(false);
      setIsDefGActive(false);
      setIsDefHActive(false);
      setIsDefIActive(false);
    } else if (parentPrimaryFont === `"Raleway", sans-serif`) {
      setIsDefAActive(false);
      setIsDefBActive(false);
      setIsDefCActive(false);
      setIsDefDActive(true);
      setIsDefEActive(false);
      setIsDefFActive(false);
      setIsDefGActive(false);
      setIsDefHActive(false);
      setIsDefIActive(false);
    } else if (parentPrimaryFont === `"Overpass", sans-serif`) {
      setIsDefAActive(false);
      setIsDefBActive(false);
      setIsDefCActive(false);
      setIsDefDActive(false);
      setIsDefEActive(true);
      setIsDefFActive(false);
      setIsDefGActive(false);
      setIsDefHActive(false);
      setIsDefIActive(false);
    } else if (parentPrimaryFont === `"Rozha One", serif`) {
      setIsDefAActive(false);
      setIsDefBActive(false);
      setIsDefCActive(false);
      setIsDefDActive(false);
      setIsDefEActive(false);
      setIsDefFActive(true);
      setIsDefGActive(false);
      setIsDefHActive(false);
    } else if (parentPrimaryFont === `"Comfortaa", sans-serif`) {
      setIsDefAActive(false);
      setIsDefBActive(false);
      setIsDefCActive(false);
      setIsDefDActive(false);
      setIsDefEActive(false);
      setIsDefFActive(false);
      setIsDefGActive(true);
      setIsDefHActive(false);
      setIsDefIActive(false);
    } else if (parentPrimaryFont === `"Copse", sans-serif`) {
      setIsDefAActive(false);
      setIsDefBActive(false);
      setIsDefCActive(false);
      setIsDefDActive(false);
      setIsDefEActive(false);
      setIsDefFActive(false);
      setIsDefGActive(false);
      setIsDefHActive(true);
      setIsDefIActive(false);
    } else if (parentPrimaryFont === `"Space Mono", monospace`) {
      setIsDefAActive(false);
      setIsDefBActive(false);
      setIsDefCActive(false);
      setIsDefDActive(false);
      setIsDefEActive(false);
      setIsDefFActive(false);
      setIsDefGActive(false);
      setIsDefHActive(false);
      setIsDefIActive(true);
    }

    if (parentSecondaryFont === `"Noto Sans JP", sans-serif`) {
      setIsDef2AActive(true);
      setIsDef2BActive(false);
      setIsDef2CActive(false);
      setIsDef2DActive(false);
      setIsDef2EActive(false);
      setIsDef2FActive(false);
      setIsDef2GActive(false);
      setIsDef2HActive(false);
    } else if (parentSecondaryFont === `"Poppins", sans-serif`) {
      setIsDef2AActive(false);
      setIsDef2BActive(true);
      setIsDef2CActive(false);
      setIsDef2DActive(false);
      setIsDef2EActive(false);
      setIsDef2FActive(false);
      setIsDef2GActive(false);
      setIsDef2HActive(false);
    } else if (parentSecondaryFont === `"Montserrat", sans-serif`) {
      setIsDef2AActive(false);
      setIsDef2BActive(false);
      setIsDef2CActive(true);
      setIsDef2DActive(false);
      setIsDef2EActive(false);
      setIsDef2FActive(false);
      setIsDef2GActive(false);
      setIsDef2HActive(false);
    } else if (parentSecondaryFont === `"Libre Baskerville", serif`) {
      setIsDef2AActive(false);
      setIsDef2BActive(false);
      setIsDef2CActive(false);
      setIsDef2DActive(true);
      setIsDef2EActive(false);
      setIsDef2FActive(false);
      setIsDef2GActive(false);
      setIsDef2HActive(false);
    } else if (parentSecondaryFont === `"Kumbh Sans", sans-serif`) {
      setIsDef2AActive(false);
      setIsDef2BActive(false);
      setIsDef2CActive(false);
      setIsDef2DActive(false);
      setIsDef2EActive(true);
      setIsDef2FActive(false);
      setIsDef2GActive(false);
      setIsDef2HActive(false);
    } else if (parentSecondaryFont === `"Arvo", sans-serif`) {
      setIsDef2AActive(false);
      setIsDef2BActive(false);
      setIsDef2CActive(false);
      setIsDef2DActive(false);
      setIsDef2EActive(false);
      setIsDef2FActive(true);
      setIsDef2GActive(false);
      setIsDef2HActive(false);
    } else if (parentSecondaryFont === `"Jost", sans-serif`) {
      setIsDef2AActive(false);
      setIsDef2BActive(false);
      setIsDef2CActive(false);
      setIsDef2DActive(false);
      setIsDef2EActive(false);
      setIsDef2FActive(false);
      setIsDef2GActive(true);
      setIsDef2HActive(false);
    } else if (parentSecondaryFont === `"Nixie One", sans-serif`) {
      setIsDef2AActive(false);
      setIsDef2BActive(false);
      setIsDef2CActive(false);
      setIsDef2DActive(false);
      setIsDef2EActive(false);
      setIsDef2FActive(false);
      setIsDef2GActive(false);
      setIsDef2HActive(true);
    }
  }, [parentPrimaryFont, parentSecondaryFont, activeFont, tempPrimaryFont]);

  const handleOnHover = (primaryFont: string, secondaryFont: string) => {
    setTempPrimaryFont(selectedPrimaryFont);
    setTempSecondaryFont(selectedSecondaryFont);
    setSelectedPrimaryFont(primaryFont);
    setSelectedSecondaryFont(secondaryFont);
  };

  const handleOnHoverOut = () => {
    setSelectedPrimaryFont(tempPrimaryFont);
    setSelectedSecondaryFont(tempSecondaryFont);
  };

  const handleSelectPair = (
    primaryFont: string,
    secondaryFont: string,
    isDefA: boolean,
    isDefB: boolean,
    isDefC: boolean,
    isDefD: boolean,
    isDefE: boolean,
    isDefF: boolean,
    isDefG: boolean,
    isDefH: boolean,
    isDefI: boolean,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setSelectedPrimaryFont(primaryFont);
    setSelectedSecondaryFont(secondaryFont);
    setTempPrimaryFont(primaryFont);
    setTempSecondaryFont(secondaryFont);
    setIsDefAActive(isDefA);
    setIsDefBActive(isDefB);
    setIsDefCActive(isDefC);
    setIsDefDActive(isDefD);
    setIsDefEActive(isDefE);
    setIsDefFActive(isDefF);
    setIsDefGActive(isDefG);
    setIsDefHActive(isDefH);
    setIsDefIActive(isDefI);
    setFieldValue("primaryFont", primaryFont);
    setFieldValue("secondaryFont", secondaryFont);
    handleUpdateFonts(
      primaryFont,
      secondaryFont,
      determineFontSelected(primaryFont)
    );
  };

  const determineFontSelected = (font: string) => {
    if (font === `"Roboto", sans-serif`) {
      return "A";
    } else if (font === `"Lora", serif`) {
      return "B";
    } else if (font === `"Spartan", sans-serif`) {
      return "C";
    } else if (font === `"Raleway", sans-serif`) {
      return "D";
    } else if (font === `"Overpass", sans-serif`) {
      return "E";
    } else if (font === `"Rozha One", serif`) {
      return "F";
    } else if (font === `"Comfortaa", sans-serif`) {
      return "G";
    } else if (font === `"Copse", sans-serif`) {
      return "H";
    } else {
      return "I";
    }
  };

  // const [isOwnFontDisplayed, setIsOwnFontDisplayed] = useState(false);
  // const dispatch = useDispatch();
  // const handleDisplayTabs = (display: boolean) => {
  //   setIsOwnFontDisplayed(display);
  //   if (display) {
  //     if (websiteSlug) {
  //       dispatch(trackFakeDoor(websiteSlug, "wizard", "Custom Fonts"));
  //     }
  //   }
  // };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, setFieldValue, handleSubmit, handleChange }) => (
        <div className={`${styles["wizard__split-container"]}`}>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header className={`${styles["wizard__header"]}`}>
              <Modal.Title style={{ lineHeight: 1 }}>
                {t("editor_setup_wizard1")}
              </Modal.Title>
              {/* <small style={{marginTop: 12}}>
                The fonts you select here will be used for your headers, titles
                and paragraphs. You can change this later in the Editor.
              </small> */}
            </Modal.Header>
            <Modal.Body className={`${styles["wizard__body"]}`}>
              {/* <div className={`${styles["tab"]}`}>
                <div onClick={() => handleDisplayTabs(false)}>
                  Default Pairings
                </div>
                <div onClick={() => handleDisplayTabs(true)}>
                  Choose your own fonts
                </div>
              </div> */}
              {/* {!isOwnFontDisplayed ? ( */}
              <div className="picker-container">
                <Tabs id="color-picker" defaultActiveKey={"headers"}>
                  <Tab title={t("editor_setup_wizard2")} eventKey="headers">
                    <ListGroup
                      as="ul"
                      style={{ width: "100%", padding: "0.75rem" }}
                    >
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefAActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            `"Roboto", sans-serif`,
                            selectedSecondaryFont
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Roboto", sans-serif`,
                            selectedSecondaryFont,
                            true,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Roboto
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefBActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(`"Lora", serif`, selectedSecondaryFont)
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Lora", serif`,
                            selectedSecondaryFont,
                            false,
                            true,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Lora
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefCActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            `"Spartan", sans-serif`,
                            selectedSecondaryFont
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Spartan", sans-serif`,
                            selectedSecondaryFont,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Spartan
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefDActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            `"Raleway", sans-serif`,
                            selectedSecondaryFont
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Raleway", sans-serif`,
                            selectedSecondaryFont,
                            false,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Raleway
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefEActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            `"Overpass", sans-serif`,
                            selectedSecondaryFont
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Overpass", sans-serif`,
                            selectedSecondaryFont,
                            false,
                            false,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Overpass
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefFActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            `"Rozha One", serif`,
                            selectedSecondaryFont
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Rozha One", serif`,
                            selectedSecondaryFont,
                            false,
                            false,
                            false,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Rozha One
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefGActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            `"Comfortaa", sans-serif`,
                            selectedSecondaryFont
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Comfortaa", sans-serif`,
                            selectedSecondaryFont,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            true,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Comfortaa
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefHActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            `"Copse", sans-serif`,
                            selectedSecondaryFont
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Copse", sans-serif`,
                            selectedSecondaryFont,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            true,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Copse
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDefIActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            `"Space Mono", monospace`,
                            selectedSecondaryFont
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            `"Space Mono", monospace`,
                            selectedSecondaryFont,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            true,
                            setFieldValue
                          );
                        }}
                      >
                        Space Mono
                      </ListGroup.Item>
                    </ListGroup>
                  </Tab>
                  <Tab title={t("editor_setup_wizard3")} eventKey="smalls">
                    <ListGroup
                      as="ul"
                      style={{ width: "100%", padding: "0.75rem" }}
                    >
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDef2AActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            selectedPrimaryFont,
                            `"Noto Sans JP", sans-serif`
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            selectedPrimaryFont,
                            `"Noto Sans JP", sans-serif`,
                            true,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Noto Sans JP
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDef2BActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            selectedPrimaryFont,
                            `"Poppins", sans-serif`
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            selectedPrimaryFont,
                            `"Poppins", sans-serif`,
                            false,
                            true,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Poppins
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDef2CActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            selectedPrimaryFont,
                            `"Montserrat", sans-serif`
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            selectedPrimaryFont,
                            `"Montserrat", sans-serif`,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Montserrat
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDef2DActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            selectedPrimaryFont,
                            `"Libre Baskerville", serif`
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            selectedPrimaryFont,
                            `"Libre Baskerville", serif`,
                            false,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Libre Baskerville
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDef2EActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            selectedPrimaryFont,
                            `"Kumbh Sans", sans-serif`
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            selectedPrimaryFont,
                            `"Kumbh Sans", sans-serif`,
                            false,
                            false,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Kumbh Sans
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDef2FActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            selectedPrimaryFont,
                            `"Arvo", sans-serif`
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            selectedPrimaryFont,
                            `"Arvo", sans-serif`,
                            false,
                            false,
                            false,
                            false,
                            false,
                            true,
                            false,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Arvo
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDef2GActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            selectedPrimaryFont,
                            `"Jost", sans-serif`
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            selectedPrimaryFont,
                            `"Jost", sans-serif`,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            true,
                            false,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Jost
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className={`${styles["list"]} ${
                          isDef2HActive ? styles["active"] : styles[""]
                        }`}
                        onMouseOver={() =>
                          handleOnHover(
                            selectedPrimaryFont,
                            `"Nixie One", sans-serif`
                          )
                        }
                        onMouseOut={() => handleOnHoverOut()}
                        onMouseDown={() => {
                          handleSelectPair(
                            selectedPrimaryFont,
                            `"Nixie One", sans-serif`,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            false,
                            true,
                            false,
                            setFieldValue
                          );
                        }}
                      >
                        Nixie One
                      </ListGroup.Item>
                    </ListGroup>
                  </Tab>
                </Tabs>
              </div>
            </Modal.Body>
            <Modal.Footer className={styles["wizard__footer"]}>
              <Button
                label={t("next")}
                variant="outline-primary"
                size="medium"
                icon={ArrowForwardRounded}
                onClick={handleSubmit}
              />
              <Button
                icon={ArrowBackRounded}
                variant="outline-primary"
                size="circle"
                onClick={() => handleStepBack(1)}
              />
            </Modal.Footer>
          </Form>
          <div className={`${styles["wizard__preview"]}`}>
            <div className={`${styles["label"]}`}>
              {t("editor_setup_wizard4")}
            </div>
            <div
              className={`${styles["navbar"]}`}
              style={{
                fontFamily: `${selectedSecondaryFont}`,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {website && website.logoCreated ? (
                  <img
                    src={`https://s3.amazonaws.com/img.goodweb.app/sites/${website.slug}/main.png`}
                    alt="Main logo"
                    style={{ maxHeight: "50px", marginRight: "12px" }}
                  />
                ) : (
                  <div className={styles["logo"]}>
                    <CloudCircleRounded /> logo
                  </div>
                )}
                <div>{t("editor_setup_wizard5")}</div>
                <div>{t("editor_setup_wizard6")}</div>
              </div>
              <div>
                <Facebook />
                <WhatsApp />
              </div>
            </div>
            <div className={`${styles["cover"]}`}>
              <h1
                style={{
                  fontFamily: `${selectedPrimaryFont}`,
                }}
              >
                {t("editor_setup_wizard7")}
              </h1>
              <p
                style={{
                  fontFamily: `${selectedSecondaryFont}`,
                }}
              >
                {t("editor_setup_wizard8")}
              </p>
              <button
                style={{
                  fontFamily: `${selectedPrimaryFont}`,
                }}
              >
                {t("editor_setup_wizard9")}
              </button>
            </div>
            <div className={`${styles["body"]}`}>
              <article>
                <h3
                  style={{
                    fontFamily: `${selectedPrimaryFont}`,
                  }}
                >
                  {t("editor_setup_wizard10")}
                </h3>
                <p
                  style={{
                    fontFamily: `${selectedSecondaryFont}`,
                  }}
                >
                  {t("editor_setup_wizard11")}
                </p>
              </article>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
