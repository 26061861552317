import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import ReactHtmlParser from "react-html-parser";

export type BlockQuoteProperties = {
  richTextProperty: { text: string | undefined };
  rteAlignment: { style: string | undefined };
};

export function BlockQuote({
  properties: { richTextProperty, rteAlignment },
  className,
  innerRef,
  ...props
}: ElementComponentProps<BlockQuoteProperties>): JSX.Element {
  if (richTextProperty.text === undefined) return <></>;
  return (
    <div
      {...props}
      ref={innerRef}
      className={`${className} hobaw-rte rte-${rteAlignment.style} blockquote blockquote-style-1`}
    >
      {ReactHtmlParser(richTextProperty.text)}
    </div>
  );
}

export const blockquoteElementKey = "blockquote";

elementComponentRegistry.register(blockquoteElementKey, BlockQuote);
