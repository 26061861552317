import React from "react";

import styles from "./ResizeToolbar.module.scss";

type Props = {
  selectedDomElement: Element;
};

export const ResizeToolbarHorizontalGuide: React.FunctionComponent<Props> = ({
  selectedDomElement,
}) => {
  const guides = [4, 5, 6, 7, 8];

  // Get viewport diff
  const guideDiff = getWindowDimensions().height / 10;

  // Create guides measurements
  const guidesMeasurements: number[] = [];
  guides.forEach((guide) => {
    guidesMeasurements.push(
      selectedDomElement.getBoundingClientRect().top + guide * guideDiff
    );
  });

  const closestGuide = getClosestGuideHeight(
    selectedDomElement.getBoundingClientRect().top +
      selectedDomElement.getBoundingClientRect().height,
    guidesMeasurements
  );

  const guideElements: JSX.Element[] = [];
  guidesMeasurements.forEach((guide) => {
    const highlight = guide === closestGuide ? "highlight" : "";

    guideElements.push(
      <div
        className={`${styles["resize-guide"]} ${styles["horizontal"]} ${styles["resizing"]} ${styles[highlight]}`}
        style={{
          top: guide,
        }}
        key={guide}
      ></div>
    );
  });

  return <>{guideElements}</>;
};

export const getClosestGuide = (
  height: number,
  guidesMeasurements: number[]
) => {
  let tempGuides = guidesMeasurements.slice();
  const index = tempGuides.findIndex((x) => x === 0);
  tempGuides[index] = window.innerWidth;

  return tempGuides.reduce((a: number, b: number) => {
    const aDiff = Math.abs(a - height);
    const bDiff = Math.abs(b - height);

    if (aDiff === bDiff) {
      return a > b ? a : b;
    }
    return bDiff < aDiff ? b : a;
  });
};

export const getClosestGuideHeight = (
  height: number,
  guidesMeasurements: number[]
) => {
  let tempGuides = guidesMeasurements.slice();
  const index = tempGuides.findIndex((x) => x === 0);
  tempGuides[index] = window.innerHeight;

  return tempGuides.reduce((a: number, b: number) => {
    const aDiff = Math.abs(a - height);
    const bDiff = Math.abs(b - height);

    if (aDiff === bDiff) {
      return a > b ? a : b;
    }
    return bDiff < aDiff ? b : a;
  });
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
