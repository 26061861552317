import React from "react";

import { Element } from "./elements/Element/Element";
import { getElementComponent } from "./elements/registry";
import { Layout } from "common/elements/types";

export const mapElementToComponent = (
  elementId: string,
  elementParentId: string | undefined,
  index: number,
  layout: Layout,
  selectedRef: React.MutableRefObject<any>
) => {
  const element = layout[elementId];
  const elementParent = elementParentId ? layout[elementParentId] : null;
  if (element) {
    const component = getElementComponent(element.elementKey);

    return (
      <Element
        as={component}
        key={elementId}
        elementParentId={elementParentId}
        elementParentKey={elementParent ? elementParent.elementKey : null}
        properties={element}
        index={index}
        selectedRef={selectedRef}
        // text={element.text}
        // offset={element.offset}
        // width={element.width}
        {...element}
      >
        {element.elementChildrenId ? (
          element.elementChildrenId.map((elementChildId: any, index: number) =>
            mapElementToComponent(
              elementChildId,
              elementId,
              index,
              layout,
              selectedRef
            )
          )
        ) : (
          <></>
        )}
      </Element>
    );
  } else {
  }
};
