import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import * as yup from "yup";

import { TCreateCategory } from "./api";
import { IStepProps } from "./Setup";

import styles from "./Admin.module.scss";

import { useTranslation } from "react-i18next";

const schema = yup.object({
  category: yup.mixed<TCreateCategory>().required(/* TODO: Add a message */),
});

export type SetupStep2bFormValues = yup.InferType<typeof schema>;

export const SetupStep2bForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleSkip,
}) => {
  const { t } = useTranslation();

  const [statePurposeContact, setStatePurposeContact] = useState<
    boolean | undefined
  >(values.isPurposeContact);
  const [statePurposeWorkServices, setStatePurposeWorkServices] = useState<
    boolean | undefined
  >(values.isPurposeWorkServices);
  const [statePurposeProduct, setStatePurposeProduct] = useState<
    boolean | undefined
  >(values.isPurposeProduct);
  const [statePurposeLocation, setStatePurposeLocation] = useState<
    boolean | undefined
  >(values.isPurposeLocation);
  const [statePurposeReview, setStatePurposeReview] = useState<
    boolean | undefined
  >(values.isPurposeReview);
  const [statePurposeIncreaseSocial, setStatePurposeIncreaseSocial] = useState<
    boolean | undefined
  >(values.isPurposeIncreaseSocial);
  const [statePurposeIntroduceTeam, setStatePurposeIntroduceTeam] = useState<
    boolean | undefined
  >(values.isPurposeIntroduceTeam);

  const onSubmit = (values: SetupStep2bFormValues) => {
    handleSaveAndContinue(values);
  };

  const handleStatePurposeIntroduceTeam = () => {
    values.isPurposeIntroduceTeam = statePurposeIntroduceTeam ? false : true;
    setStatePurposeIntroduceTeam(statePurposeIntroduceTeam ? false : true);
  };

  const handleStatePurposeContact = () => {
    values.isPurposeContact = statePurposeContact ? false : true;
    setStatePurposeContact(statePurposeContact ? false : true);
  };

  const handleStatePurposeIncreaseSocial = () => {
    values.isPurposeIncreaseSocial = statePurposeIncreaseSocial ? false : true;
    setStatePurposeIncreaseSocial(statePurposeIncreaseSocial ? false : true);
  };

  const handleStatePurposeWorkServices = () => {
    values.isPurposeWorkServices = statePurposeWorkServices ? false : true;
    setStatePurposeWorkServices(statePurposeWorkServices ? false : true);
  };
  const handleStatePurposeProduct = () => {
    values.isPurposeProduct = statePurposeProduct ? false : true;
    setStatePurposeProduct(statePurposeProduct ? false : true);
  };
  const handleStatePurposeLocation = () => {
    values.isPurposeLocation = statePurposeLocation ? false : true;
    setStatePurposeLocation(statePurposeLocation ? false : true);
  };
  const handleStatePurposeReview = () => {
    values.isPurposeReview = statePurposeReview ? false : true;
    setStatePurposeReview(statePurposeReview ? false : true);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title style={{ width: "100%" }}>
              <h6>{t("setup_wizard_81")}</h6>
              <div className={styles["setup-subheader"]}>
                <div className={styles["setup-subheader-main"]}>
                  {t("setup_wizard_8")}
                </div>
                <div
                  className={styles["setup-subheader-skip"]}
                  onClick={() => {
                    handleSkip(values);
                  }}
                >
                  {t("setup_wizard_4")}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className={styles["setup-purpose__container"]}>
                <div
                  className={`${styles["setup-purpose__card"]} ${
                    statePurposeContact === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStatePurposeContact();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    <span role="img" aria-label="Contact Emoji">
                      💬
                    </span>{" "}
                    {t("setup_wizard_112")}
                  </div>
                  <div className={styles["setup-purpose__card-body"]}>
                    <span className={styles["setup-purpose__card-body__title"]}>
                      {t("setup_wizard_113")} {values.title}
                    </span>
                    <span
                      className={
                        styles["setup-purpose__card-body__description"]
                      }
                    >
                      {t("setup_wizard_114")}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles["setup-purpose__card"]} ${
                    statePurposeIncreaseSocial === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStatePurposeIncreaseSocial();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    <span role="img" aria-label="Like Emoji">
                      👍
                    </span>{" "}
                    {t("setup_wizard_115")}
                  </div>
                  <div className={styles["setup-purpose__card-body"]}>
                    <span className={styles["setup-purpose__card-body__title"]}>
                      {t("setup_wizard_116")}
                    </span>
                    <span
                      className={
                        styles["setup-purpose__card-body__description"]
                      }
                    >
                      {t("setup_wizard_117")}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles["setup-purpose__card"]} ${
                    statePurposeWorkServices === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStatePurposeWorkServices();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    <span role="img" aria-label="Team Emoji">
                      👩🏻‍🔧
                    </span>{" "}
                    {t("setup_wizard_118")}
                  </div>
                  <div className={styles["setup-purpose__card-body"]}>
                    <span className={styles["setup-purpose__card-body__title"]}>
                      {t("setup_wizard_119")}
                    </span>
                    <span
                      className={
                        styles["setup-purpose__card-body__description"]
                      }
                    >
                      {t("setup_wizard_120")}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles["setup-purpose__card"]} ${
                    statePurposeProduct === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStatePurposeProduct();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    <span role="img" aria-label="Product Emoji">
                      🍯
                    </span>{" "}
                    {t("setup_wizard_121")}
                  </div>
                  <div className={styles["setup-purpose__card-body"]}>
                    <span className={styles["setup-purpose__card-body__title"]}>
                      {t("setup_wizard_122")} {values.title}
                      {t("setup_wizard_122")}
                    </span>
                    <span
                      className={
                        styles["setup-purpose__card-body__description"]
                      }
                    >
                      {t("setup_wizard_124")}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles["setup-purpose__card"]} ${
                    statePurposeLocation === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStatePurposeLocation();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    <span role="img" aria-label="Location Emoji">
                      📍
                    </span>{" "}
                    {t("setup_wizard_125")}
                  </div>
                  <div className={styles["setup-purpose__card-body"]}>
                    <span className={styles["setup-purpose__card-body__title"]}>
                      {t("setup_wizard_126")} {values.title}
                    </span>
                    <span
                      className={
                        styles["setup-purpose__card-body__description"]
                      }
                    >
                      {t("setup_wizard_127")}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles["setup-purpose__card"]} ${
                    statePurposeIntroduceTeam === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStatePurposeIntroduceTeam();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    <span role="img" aria-label="Team Emoji">
                      👭
                    </span>{" "}
                    {t("setup_wizard_128")}
                  </div>
                  <div className={styles["setup-purpose__card-body"]}>
                    <span className={styles["setup-purpose__card-body__title"]}>
                      {t("setup_wizard_129")}
                    </span>
                    <span
                      className={
                        styles["setup-purpose__card-body__description"]
                      }
                    >
                      {t("setup_wizard_130")} {values.title}
                      {t("setup_wizard_131")}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles["setup-purpose__card"]} ${
                    statePurposeReview === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStatePurposeReview();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    <span role="img" aria-label="Star Emoji">
                      ⭐️
                    </span>{" "}
                    {t("setup_wizard_132")}
                  </div>
                  <div className={styles["setup-purpose__card-body"]}>
                    <span className={styles["setup-purpose__card-body__title"]}>
                      {t("setup_wizard_133")}
                    </span>
                    <span
                      className={
                        styles["setup-purpose__card-body__description"]
                      }
                    >
                      {t("setup_wizard_134")}
                    </span>
                  </div>
                </div>
              </div>
              {/* <Form.Group as={Col} style={{ maxWidth: "" }}>
                <Col>
                  <Form.Check
                    type="checkbox"
                    className={`${statePurposeContact === true
                      ? styles[containerClassNameSelected]
                      : styles[containerClassName]
                      }
                        ${styles["theme-selector"]}`}
                    name="purpose-contact"
                    checked={statePurposeContact}
                    isValid
                  >
                    <Form.Check.Label
                      id="purpose-contact"
                      className={`${styles["label"]} ${styles["label-check"]}`}
                      style={{ height: "100%", width: "100%" }}
                      onClick={() => {
                        handleStatePurposeContact()
                      }}>
                      {t("setup_wizard_9")}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    className={`${statePurposeIncreaseSocial === true
                      ? styles[containerClassNameSelected]
                      : styles[containerClassName]
                      }
                        ${styles["theme-selector"]}`}
                    name="purpose-social"
                    checked={statePurposeIncreaseSocial}
                    isValid
                  >
                    <Form.Check.Label
                      id="purpose-social"
                      className={`${styles["label"]} ${styles["label-check"]}`}
                      style={{ height: "100%", width: "100%" }}
                      onClick={() => {
                        handleStatePurposeIncreaseSocial()
                      }}>
                      {t("setup_wizard_14")}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    className={`${statePurposeWorkServices === true
                      ? styles[containerClassNameSelected]
                      : styles[containerClassName]
                      }
                        ${styles["theme-selector"]}`}
                    name="purpose-contact"
                    checked={statePurposeWorkServices}
                    isValid
                  >
                    <Form.Check.Label
                      id="purpose-work-services"
                      className={`${styles["label"]} ${styles["label-check"]}`}
                      style={{ height: "100%", width: "100%" }}
                      onClick={() => {
                        handleStatePurposeWorkServices()
                      }}>
                      {t("setup_wizard_10")}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    className={`${statePurposeProduct === true
                      ? styles[containerClassNameSelected]
                      : styles[containerClassName]
                      }
                        ${styles["theme-selector"]}`}
                    name="purpose-product"
                    checked={statePurposeProduct}
                    isValid
                  >
                    <Form.Check.Label
                      id="purpose-product"
                      className={`${styles["label"]} ${styles["label-check"]}`}
                      style={{ height: "100%", width: "100%" }}
                      onClick={() => {
                        handleStatePurposeProduct()
                      }}>
                      {t("setup_wizard_11")}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    className={`${statePurposeLocation === true
                      ? styles[containerClassNameSelected]
                      : styles[containerClassName]
                      }
                        ${styles["theme-selector"]}`}
                    name="purpose-location"
                    checked={statePurposeLocation}
                    isValid
                  >
                    <Form.Check.Label
                      id="purpose-location"
                      className={`${styles["label"]} ${styles["label-check"]}`}
                      style={{ height: "100%", width: "100%" }}
                      onClick={() => {
                        handleStatePurposeLocation()
                      }}>
                      {t("setup_wizard_12")}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    className={`${statePurposeIntroduceTeam === true
                      ? styles[containerClassNameSelected]
                      : styles[containerClassName]
                      }
                        ${styles["theme-selector"]}`}
                    name="purpose-team"
                    checked={statePurposeIntroduceTeam}
                    isValid
                  >
                    <Form.Check.Label
                      id="purpose-team"
                      className={`${styles["label"]} ${styles["label-check"]}`}
                      style={{ height: "100%", width: "100%" }}
                      onClick={() => {
                        handleStatePurposeIntroduceTeam()
                      }}>
                      {t("setup_wizard_61")}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    className={`${statePurposeOther === true
                      ? styles[containerClassNameSelected]
                      : styles[containerClassName]
                      }
                        ${styles["theme-selector"]}`}
                    name="purpose-other"
                    checked={statePurposeOther}
                    isValid
                  >
                    <Form.Check.Label
                      id="purpose-other"
                      className={`${styles["label"]} ${styles["label-check"]}`}
                      style={{ height: "100%", width: "100%" }}
                      onClick={() => {
                        handleStatePurposeOther()
                      }}>
                      {t("setup_wizard_13")}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Group> */}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              type="button"
              onClick={handleStepBack}
              style={{
                borderRadius: 32,
                color: "#007bff",
                backgroundColor: "white",
                borderColor: "#007bff",
              }}
            >
              {t("login_page_back")}
            </Button> */}
            <Button
              style={{ borderRadius: 32 }}
              type="submit"
              disabled={
                values.isPurposeContact !== true &&
                values.isPurposeIncreaseSocial !== true &&
                values.isPurposeWorkServices !== true &&
                values.isPurposeProduct !== true &&
                values.isPurposeLocation !== true &&
                values.isPurposeIntroduceTeam !== true &&
                values.isPurposeReview !== true
              }
            >
              {t("setup_page_15")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
