import { AddRounded } from "@material-ui/icons";
import React from "react";

import style from "./EditorEmptyHelper.module.scss";

export const EditorEmptyHelper: React.FunctionComponent = () => {
  return (
    <div className={`${style["empty-editor-dialog"]}`}>
      <div className={`${style["center-container"]}`}>
        <div className={`${style["content-container"]}`}>
          <h3>
            Start adding things to this page by clicking the blinking{" "}
            <AddRounded /> button.
          </h3>
          <p>
            You can also add elements to a page by placing your mouse pointer
            near the sides of an element once you've added them.
          </p>
        </div>
      </div>
    </div>
  );
};
