import { combineReducers } from "redux";

import { reducer as pageReducer, State as PageState } from "./pageReducer";
import { IWebsiteState } from "./state";
import { websiteReducer } from "./websiteReducer";

export const reducer = combineReducers({
  website: websiteReducer,
  page: pageReducer,
});

export type TAdminState = {
  website: IWebsiteState;
  page: PageState;
};

export { rootSaga as saga } from "./sagas";
