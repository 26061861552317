import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const compactChild1Template: ElementTemplates = {};

compactChild1Template["cover"] = TemplatesFactory.createDefaultCover(
  "7",
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Collaborate With Us", "", false, false, false),
  new RichTextProperty("<h1>Some of Irene's<br />Best Work</h1>"),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-banner2-bg.png",
    "no-repeat",
    "0%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(
    '6 samples of <a href="https://icons8.com/illustrations/author/603d1fd6123f9916a4db9ee6" target="_blank"> Irene M. Ray\'s</a> <br /> best work from her portfolio.'
  ),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 70)
);

compactChild1Template[
  "cards"
] = TemplatesFactory.createDefaultCards(
  new LeadStyleProperty("0"),
  new RichTextProperty("<h2>We've Got You Covered</h2>"),
  new RTEAlignmentProperty("parent"),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Illustrations, animations, story boards, etc. You name it, we got it."
  ),
  new RTEAlignmentProperty("parent"),
  "2",
  [
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Cherry</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Contact", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-cherry.png",
      "parent",
      true
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Mellow</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Contact", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-mellow.png",
      "parent",
      true
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Burgundy</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Contact", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-burgundy.png",
      "parent",
      true
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Woof</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Contact", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-woof.png",
      "parent",
      true
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Sammy</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Contact", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-sammy.png",
      "parent",
      true
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Jelly</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Contact", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-jelly.png",
      "parent",
      true
    ),
  ]
);
