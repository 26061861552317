import { AutorenewRounded, CheckRounded } from "@material-ui/icons";
import { elementShared, elementUnshared } from "editor/states/layout";
import {
  getSharedElementConfiguration,
  isElementShared,
} from "editor/states/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store";
import { Button } from "../../base/Button/Button";
import "./Dialog.scss";
import { useTranslation } from "react-i18next";

type Props = {
  elementId: string;
};

export const ReuseTab: React.FunctionComponent<Props> = ({ elementId }) => {
  const [sharedElementLabel, setSharedElementLabel] = useState("");
  const sharedElementConfiguration = useSelector((state: State) =>
    getSharedElementConfiguration(state, elementId)
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    setSharedElementLabel(sharedElementConfiguration?.label);
  }, [sharedElementConfiguration]);

  const isElementUsed = useSelector((state: State) =>
    isElementShared(state, elementId)
  );

  const handleElementSharedChange = () => {
    if (!elementId) {
      return;
    }

    if (isElementUsed) {
      dispatch(elementUnshared(elementId));
    } else {
      dispatch(elementShared(elementId, sharedElementLabel));
    }
  };

  return (
    <div className="medium-padded-container">
      <div className="reuse-section">
        <div className="instructions">{t("dialog_20")}</div>
        <div className="button-container">
          <Button
            label={isElementUsed ? t("dialog_48") : t("dialog_5")}
            variant={isElementUsed ? "outline-success" : "outline-primary"}
            icon={isElementUsed ? CheckRounded : AutorenewRounded}
            onClick={handleElementSharedChange}
            isFlat={true}
          />
        </div>
      </div>
    </div>
  );
};
