import { ViewModuleRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import {
  articleElementKey,
  ArticleProperties,
} from "common/elements/components/Article";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { ArticleStyleProperty } from "editor/properties/ArticleStyleProperty";

export class ArticleElement {
  static readonly elementKey = articleElementKey;
  static readonly label = "element_article_label";
  static readonly icon = ViewModuleRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly allowedChildren = [
    "image",
    "button",
    "paragraph",
    "youtube",
    "google-maps",
  ];
  static readonly elementPreviewUrl =
    "/img/add-article-element-preview-default.svg";
  static readonly elementDescription = "element_article_description";

  static readonly properties: PropertiesOf<ArticleProperties> = {
    articleStyle: ArticleStyleProperty,
  };

  static renderProperties(element: ElementType) {
    return <ArticlePropertiesComponent element={element} />;
  }

  static create(properties: { articleStyle: string }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const ArticlePropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof ArticleElement.properties>(
    ArticleElement.properties,
    element
  );

  return <>{renderer.render("articleStyle")}</>;
};

elementDefinitionRegistry.register(ArticleElement);
