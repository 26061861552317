import React from "react";
import styles from "./Auth.module.scss";

export default function Footer(): JSX.Element {
  return (
    <footer className={styles["auth__footer"]}>
      <a href="https://www.trygoodweb.com/company/privacy">Privacy Policy</a> ●{" "}
      <a href="https://www.trygoodweb.com/company/terms">
        Terms and Conditions
      </a>
      <div>© {new Date().getFullYear()} GoodWeb</div>
    </footer>
  );
}
