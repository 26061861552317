import React from "react";
import { useField } from "formik";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "xs" | "s" | "m" | "l";
};

// Uses Formik by default unless value OR onChange are provided. Using
// Formik means it doesn't apply the changes automatically, but rather
// the whole form has to be submitted.
export default function TextFieldGroup({
  name,
  label,
  placeholder,
  value,
  onChange,
  size,
}: Props): JSX.Element {
  if (value || onChange) {
    return (
      <SimpleTextFieldGroup
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        size={size}
      />
    );
  }
  return (
    <FormikTextFieldGroup
      name={name}
      label={label}
      placeholder={placeholder}
      size={size}
    />
  );
}

function createClassName(isInvalid?: string, size?: string) {
  let className = isInvalid ? "is-invalid" : "";
  className = size ? " size-" + size : "";

  return className;
}

function SimpleTextFieldGroup({
  name,
  label,
  placeholder,
  value,
  onChange,
  size,
}: Props): JSX.Element {
  return (
    <div className="form__group">
      <label htmlFor={name}>{label}</label>
      <input
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        className={createClassName("", size)}
      />
    </div>
  );
}

function FormikTextFieldGroup({
  name,
  label,
  placeholder,
  size,
}: Props): JSX.Element {
  const [field, meta] = useField(name);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className="form__group">
      <label htmlFor={name}>{label}</label>
      <input
        type="text"
        className={createClassName("", size)}
        placeholder={placeholder}
        name={field.name}
        value={field.value}
        checked={field.checked}
        multiple={field.multiple}
        onBlur={field.onBlur}
        onChange={field.onChange}
      />
      {isInvalid ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
}
