import React, { useEffect, useCallback, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import * as yup from "yup";
import { IStepProps, PurposeTeam, PurposeTeamMember, ToUpload } from "./Setup";

import { useTranslation } from "react-i18next";

import styles from "./Admin.module.scss";
import { AccountCircleRounded, EditRounded } from "@material-ui/icons";

import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { getS3config } from "editor/states/selectors";
import { uploadFile } from "./S3Client";

var mime = require("mime-types");

const schema = yup.object({
  purposeName: yup.string(),
  purposeDescription: yup.string(),
});

type Props = {
  i: number;
  index: number;
  memberName: string;
  memberPosition?: string;
  memberDescription?: string;
  memberPhotoUrl?: ToUpload;
  tempFilename?: string;
};

export type SetupStep3fFormValues = yup.InferType<typeof schema>;

export const SetupStep3fForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleSkip,
}) => {
  const { t } = useTranslation();
  const s3ConfigState = useSelector(getS3config);
  const [stateTeam, setStateTeam] = useState<PurposeTeam | undefined>(
    values.team
  );

  const [stateDescription, setStateDescription] = useState<string | undefined>(
    values.team.description
  );
  const [stateTeamMemberName, setStateTeamMemberName] = useState<
    string | undefined
  >();
  const [stateTeamMemberPosition, setStateTeamMemberPosition] = useState<
    string | undefined
  >();
  const [stateTeamMemberDescription, setStateTeamMemberDescription] = useState<
    string | undefined
  >();
  const [stateTeamPhotoToUpload, setStateTeamPhotoToUpload] = useState<
    ToUpload | undefined
  >();
  const [stateTeamMembers, setStateTeamMembers] = useState<PurposeTeamMember[]>(
    values.team?.member
  );

  const [stateIndex, setStateIndex] = useState<number | undefined>();
  const [stateShowTeamMemberForm, setStateShowTeamMemberForm] = useState<
    boolean
  >(false);
  // const [fileType, setFileType] = useState("");
  const [tempFilename, setTempFilename] = useState<string | undefined>();

  const [formDOM, setFormDOM] = useState<HTMLFormElement | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  // const [showAdd, setShowAdd] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imgStr = reader.result as string;

        let fileType = mime.extension(file.type);
        if (!fileType) {
          fileType = "png";
        }
        setTempFilename("team-member-" + stateTeam?.member.length);
        // setFileType(fileType);
        setStateTeamPhotoToUpload({
          filename: "team-member-" + stateTeam?.member.length,
          file: file,
          dataUrl: imgStr,
        });
      };
      reader.readAsDataURL(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    minSize: 0,
    maxSize: 2097152, // 2MB max
    onDrop,
  });

  useEffect(() => {
    if (
      values &&
      (values.isPurposeIntroduceTeam === undefined ||
        values.isPurposeIntroduceTeam === false)
    ) {
      handleSaveAndContinue(values);
    }
  }, [values, handleSaveAndContinue]);

  const onSubmit = () => {
    if (stateTeam) {
      values.team = stateTeam;
    }
    handleSaveAndContinue(values);
  };

  const handleShowTeamMemberForm = () => {
    setStateShowTeamMemberForm(!stateShowTeamMemberForm);
  };

  const pushTeamPurposes = (purposes: PurposeTeamMember[], loc: any) => {
    if (stateIndex !== undefined) {
      stateTeamMembers[stateIndex] = {
        id: stateIndex,
        name: stateTeamMemberName,
        desription: stateTeamMemberDescription,
        position: stateTeamMemberPosition,
        photoUrl: loc
          ? {
              file: stateTeamPhotoToUpload?.file!,
              filename: loc,
              dataUrl: stateTeamPhotoToUpload?.dataUrl!,
            }
          : stateTeamPhotoToUpload,
        oldFilename: tempFilename,
      };
    } else {
      stateTeamMembers?.push({
        id: stateTeamMembers.length,
        name: stateTeamMemberName,
        desription: stateTeamMemberDescription,
        position: stateTeamMemberPosition,
        photoUrl: loc
          ? {
              file: stateTeamPhotoToUpload?.file!,
              filename: loc,
              dataUrl: stateTeamPhotoToUpload?.dataUrl!,
            }
          : stateTeamPhotoToUpload,
        oldFilename: tempFilename,
      });

      setStateTeamMembers(stateTeamMembers);
      setStateIndex(undefined);
      setStateTeamMemberName(undefined);
      setStateTeamMemberPosition(undefined);
      setStateTeamMemberDescription(undefined);
      setStateTeamPhotoToUpload(undefined);
      setTempFilename(undefined);
      setStateShowTeamMemberForm(false);

      formDOM?.reset();
    }
  };

  const handleAddTeamMember = () => {
    if (stateTeamMemberName) {
      let purposes: PurposeTeamMember[] | undefined = stateTeam?.member;

      if (
        stateTeamPhotoToUpload !== undefined &&
        stateTeamPhotoToUpload.dataUrl &&
        stateTeamPhotoToUpload.file &&
        stateTeamPhotoToUpload.filename
      ) {
        uploadFile(
          stateTeamPhotoToUpload.file,
          values.url,
          stateTeamPhotoToUpload.filename,
          s3ConfigState
        ).then(function (loc) {
          if (purposes) {
            pushTeamPurposes(purposes, loc);
          }
          setStateTeam({
            description: stateDescription,
            member: stateTeamMembers,
          });
        });
      } else {
        if (purposes) {
          pushTeamPurposes(purposes, undefined);
        }
        setStateTeam({
          description: stateDescription,
          member: stateTeamMembers,
        });
      }
    }
  };

  const handleDelete = (index: number) => {
    let teamMembers: PurposeTeamMember[] = stateTeamMembers;
    var filteredTeamMembers = teamMembers.filter((e) => e.id !== index);

    setStateTeamMembers(filteredTeamMembers);
    setStateTeam({
      description: stateDescription,
      member: filteredTeamMembers,
    });
    values.team = {
      description: stateDescription,
      member: filteredTeamMembers,
    };
    setStateIndex(undefined);
    setStateTeamMemberName(undefined);
    setStateTeamMemberPosition(undefined);
    setStateTeamMemberDescription(undefined);
    setStateTeamPhotoToUpload(undefined);
    setTempFilename(undefined);
    setStateShowTeamMemberForm(false);
    setIsAdding(false);
    formDOM?.reset();
    return;
  };

  function EditButton({
    i,
    index,
    memberName,
    memberPosition,
    memberDescription,
    memberPhotoUrl,
    tempFilename,
  }: Props): JSX.Element {
    let shortTitle = memberName;

    if (memberName)
      shortTitle =
        memberName?.length > 20
          ? memberName?.substring(0, 20) + "..."
          : memberName;

    return (
      <Button
        type="button"
        key={i}
        style={{
          width: "100%",
          textAlign: "left",
          borderRadius: "32px",
          color: "#007bff",
          backgroundColor: "#fff",
          border: "1px solid #007bff",
          marginBottom: "12px",
        }}
        onClick={() => {
          setStateIndex(index);
          setStateTeamMemberName(memberName);
          setStateTeamMemberPosition(memberPosition);
          setStateTeamMemberDescription(memberDescription);
          setStateTeamPhotoToUpload(memberPhotoUrl);
          setTempFilename(tempFilename);
          setStateShowTeamMemberForm(true);
          setIsAdding(true);
        }}
      >
        <EditRounded /> {shortTitle}
      </Button>
    );
  }

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div className={styles["button-drop-zone-label-errors"]}>
        {errors.map((e) => (
          <span key={e.code}>{e.message}</span>
        ))}
      </div>
    );
  });

  return (
    <Form noValidate ref={(form: any) => setFormDOM(form)}>
      <Modal.Header>
        <Modal.Title style={{ width: "100%" }}>
          <h6>{t("setup_wizard_81")}</h6>
          <div className={styles["setup-subheader"]}>
            <div className={styles["setup-subheader-main"]}>
              {t("setup_wizard_60")}
            </div>
            <div
              className={styles["setup-subheader-skip"]}
              onClick={() => {
                handleSkip(values);
              }}
            >
              {t("setup_wizard_4")}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row}>
          <Col>
            {!isAdding ? (
              <>
                <div className={styles["setup-label"]}>
                  {t("setup_wizard_63")}
                  <span>*</span>
                </div>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="description"
                  value={stateDescription}
                  onChange={(e: React.ChangeEvent<any>) => {
                    setStateDescription(e.target.value);
                    setStateTeam({
                      description: e.target.value,
                      member: stateTeamMembers,
                    });
                  }}
                  placeholder={t("setup_wizard_88")}
                  size="lg"
                  style={{ minHeight: "160px" }}
                />
                <span className={styles["invalid"]}></span>
              </>
            ) : (
              <></>
            )}
            {values.team &&
            values.team.member?.length > 0 &&
            stateIndex === undefined &&
            !isAdding ? (
              <>
                <div
                  style={{
                    width: "100%",
                    borderTop: "1px solid rgba(0, 0, 0, 0.125)",
                    marginTop: "24px",
                    marginBottom: "24px",
                  }}
                />
                <div className={styles["setup-list-label"]}>
                  {t("setup_wizard_110")}
                </div>
                {values.team.member.map((member, i) =>
                  member.name ? (
                    <EditButton
                      i={i}
                      tempFilename={member.oldFilename}
                      index={member.id}
                      memberName={member.name}
                      memberDescription={member.desription}
                      memberPhotoUrl={member.photoUrl}
                      memberPosition={member.position}
                    />
                  ) : (
                    <></>
                  )
                )}
              </>
            ) : (
              <></>
            )}
            {stateShowTeamMemberForm ? (
              <>
                <div>
                  <div
                    className={styles["setup-label"]}
                    style={{ marginTop: "24px" }}
                  >
                    {t("setup_wizard_64")}
                    <span>*</span>
                  </div>
                  <Form.Control
                    type="text"
                    name="teamMemberName"
                    value={stateTeamMemberName}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setStateTeamMemberName(e.target.value);
                    }}
                    placeholder={t("setup_wizard_89")}
                    size="lg"
                    style={{}}
                  />
                  <div
                    className={styles["setup-label"]}
                    style={{ marginTop: "24px" }}
                  >
                    {t("setup_wizard_65")}
                  </div>
                  <Form.Control
                    type="text"
                    name="teamMemberPosition"
                    value={stateTeamMemberPosition}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setStateTeamMemberPosition(e.target.value);
                    }}
                    placeholder={t("setup_wizard_90")}
                    size="lg"
                    style={{}}
                  />
                  <div
                    className={styles["setup-label"]}
                    style={{ marginTop: "24px" }}
                  >
                    {t("setup_wizard_66")}
                  </div>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="stateTeamDescription"
                    value={stateTeamMemberDescription}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setStateTeamMemberDescription(e.target.value);
                    }}
                    placeholder={t("setup_wizard_91")}
                    size="lg"
                    style={{ minHeight: "160px" }}
                  />
                  <div
                    {...getRootProps()}
                    className={`${styles["button-dropzone"]}  ${styles["create-option"]}`}
                    style={{ marginTop: "24px" }}
                  >
                    <input {...getInputProps()} />
                    <div className={styles["button-drop-zone-label-container"]}>
                      <AccountCircleRounded />{" "}
                      {tempFilename === undefined
                        ? t("setup_wizard_71")
                        : t("setup_wizard_72")}
                      {fileRejectionItems.length > 0 ? (
                        fileRejectionItems
                      ) : (
                        <></>
                      )}
                    </div>
                    <div style={{ fontSize: "14px", fontWeight: "lighter" }}>
                      {tempFilename ? tempFilename : ""}
                    </div>
                  </div>
                </div>
                <Button
                  type="button"
                  style={{ borderRadius: 32, width: "100%", marginTop: "12px" }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleAddTeamMember();
                    setIsAdding(false);
                  }}
                  disabled={!stateTeamMemberName}
                >
                  {stateIndex !== undefined
                    ? t("setup_wizard_69")
                    : t("setup_wizard_153")}
                </Button>
                {/* <Button
              type="button"
              style={{
                width: "100%",
                textAlign: "center",
                borderRadius: "32px",
                color: "#007bff",
                backgroundColor: "#fff",
                border: "1px solid #007bff",
                marginTop: "12px",
              }}
              onClick={(e: any) => {
                e.preventDefault();
                setStateIndex(undefined);
                setStateTeamMemberName(undefined);
                setStateTeamMemberPosition(undefined);
                setStateTeamMemberDescription(undefined);
                setStateTeamPhotoToUpload(undefined);
                setTempFilename(undefined);
                setIsAdding(false);
                handleShowTeamMemberForm();
              }}
            >
              {stateIndex !== undefined
                ? t("setup_wizard_70")
                : t("setup_wizard_68")}
            </Button> */}
                {stateIndex !== undefined ? (
                  <Button
                    type="button"
                    disabled={stateIndex === undefined}
                    style={{
                      borderRadius: 32,
                      width: "100%",
                      marginTop: "12px",
                      borderColor: "",
                      border: "2px solid #df2935",
                      backgroundColor: "white",
                      color: "#df2935",
                    }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleDelete(stateIndex);
                    }}
                  >
                    {t("setup_wizard_75")}
                  </Button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="button"
                  style={{
                    borderRadius: 32,
                    color: "#007bff",
                    backgroundColor: "white",
                    borderColor: "#007bff",
                    marginTop: "28px",
                  }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    setIsAdding(true);
                    handleShowTeamMemberForm();
                  }}
                >
                  {t("setup_wizard_62")}
                </Button>
              </div>
            )}
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button
          type="button"
          onClick={
            isAdding
              ? () => {
                  setIsAdding(false);
                  setStateIndex(undefined);
                  setStateTeamMemberName(undefined);
                  setStateTeamMemberPosition(undefined);
                  setStateTeamMemberDescription(undefined);
                  setStateTeamPhotoToUpload(undefined);
                  setTempFilename(undefined);
                  setStateShowTeamMemberForm(false);
                }
              : handleStepBack
          }
          style={{
            borderRadius: 32,
            color: "#007bff",
            backgroundColor: "white",
            borderColor: "#007bff",
          }}
        >
          {isAdding ? t("setup_wizard_70") : t("login_page_back")}
        </Button>
        {isAdding ? (
          <Button
            type="button"
            disabled={!stateDescription || stateDescription === ""}
            style={{
              borderRadius: 32,
              opacity: 0,
            }}
            onClick={onSubmit}
          >
            {t("setup_page_15")}
          </Button>
        ) : (
          <Button
            type="button"
            disabled={!stateDescription || stateDescription === ""}
            style={{
              borderRadius: 32,
            }}
            onClick={onSubmit}
          >
            {t("setup_page_15")}
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
};
