import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type ArticleContentProperties = {
  articleContentStyle: string;
};

export function ArticleContent({
  properties: { articleContentStyle },
  className,
  innerRef,
  children,
  ...props
}: ElementComponentProps<ArticleContentProperties>): JSX.Element {
  return (
    <div
      {...props}
      ref={innerRef}
      className={`${className} article-content article-content-style-${articleContentStyle}`}
    >
      {children}
    </div>
  );
}

export const articleContentElementKey = "article-content";

elementComponentRegistry.register(articleContentElementKey, ArticleContent);
