import React from "react";
import { Helmet } from "react-helmet";

import { Layout } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import { Page, Website } from "./models/types";

type Props = {
  page: Page;
  website: Website;
};

export function StaticPage({ page, website }: Props): JSX.Element {
  if (!("root" in page.content)) {
    return <></>;
  }

  return (
    <div
      className="main"
      id="main-gw"
      style={page.isMaster ? { display: "none" } : {}}
    >
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={`${page.description}`} />
      </Helmet>
      <div
        className={`jaquen jq-rt--${website.widthGuide} hobaw-website-root--${website.widthGuide}`}
      >
        {mapElementToComponent("root", page.content, website.slug)}
      </div>
    </div>
  );
}

const mapElementToComponent = (
  elementId: string,
  layout: Layout,
  slug: string
) => {
  const element = layout[elementId];

  if (!element || !element.elementId || !element.elementKey) {
    return <></>;
  }

  if (
    element &&
    element.elementKey &&
    (element.elementKey === "button" ||
      element.elementKey === "social-nav" ||
      element.elementKey === "nav" ||
      element.elementKey === "footer-nav" ||
      element.elementKey === "footer-social-nav" ||
      element.elementKey === "messenger" ||
      element.elementKey === "whatsapp")
  ) {
    element.isProduction = true;
  }
  const Component =
    elementComponentRegistry.get(element.elementKey) ?? React.Fragment;

  return (
    <>
      {/* The empty className is just to make it easier to the components, so
          they can assume className is defined */}
      <Component
        key={elementId}
        className=""
        properties={element}
        id={element.elementId}
        elementKey={element.elementKey}
        websiteSlug={slug}
      >
        {element.elementChildrenId ? (
          element.elementChildrenId.map((elementChildId: any) =>
            mapElementToComponent(elementChildId, layout, slug)
          )
        ) : (
          <></>
        )}
      </Component>
    </>
  );
};
