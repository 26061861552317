import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ColorResult, ChromePicker } from "react-color";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";

import { Formik, Form } from "formik";

import styles from "./BackgroundColorProperty.module.scss";
import "./BackgroundColorProperty.scss";

import { getPrimaryColor } from "editor/selectors";
import { Container } from "react-bootstrap";
import ColorSwatch from "editor/components/impl/ColorSwatch/ColorSwatch";

export class BackgroundColorProperty implements IProperty {
  constructor(readonly color: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <BackgroundColorPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new BackgroundColorProperty(value);
  }
}

const BackgroundColorPropertyComponent: PropertyComponent<BackgroundColorProperty> = ({
  elementId,
  propertyKey,
  color,
}) => {
  const dispatch = useDispatch();
  const statePrimaryColor = useSelector(getPrimaryColor);
  const [selectedColor, setSelectedColor] = useState(color);

  useEffect(() => {
    if (!color && statePrimaryColor) {
      setSelectedColor(statePrimaryColor);
    }
  }, [color, statePrimaryColor]);

  const handleChangeComplete = (
    color: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(propertySet(elementId, propertyKey, color));
    setSelectedColor(color);
  };

  return (
    <div className={`${styles["palette-container"]}`}>
      <Container
        className={`${styles["swatch-container"]}`}
        style={{ paddingBottom: 20, paddingTop: 20 }}
      >
        <Formik initialValues={{}} onSubmit={async (values) => {}}>
          <Form className="form">
            <div className="form__group">
              <div className="medium-padded-container">
                <div className="pickers-container">
                  <div className="picker-container">
                    <div className="picker-container-swatch-container">
                      <ColorSwatch
                        handleColorClick={handleChangeComplete}
                        isShadowed={false}
                      />
                    </div>
                    <div
                      style={{
                        width: "92%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ChromePicker
                        disableAlpha={true}
                        color={selectedColor}
                        onChange={(
                          color: ColorResult,
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleChangeComplete(color.hex, event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Container>
    </div>
  );
};
