import React from "react";
import {
  BackgroundColorProperty,
  DimensionProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Twitter, Facebook } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { foodChild1Template } from "./foodChild1";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const foodTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "food";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "footer",
    position: 1,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/food/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright Greg's Diner " + new Date().getFullYear()
      );

    default:
      return foodTemplates[layoutKey];
  }
};

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page of Gregs",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: foodChild1Template,
  },
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "foodChild1Template",
    details: {
      title: "Locations",
      slug: randomSlug(6),
      description: "Locations of Greg's around the metro",
    },
    template: {
      template: foodChild1Template,
    },
  },
];

foodTemplates["cover"] = TemplatesFactory.createDefaultCoverWithLogo(
  "https://s3.amazonaws.com/img.goodweb.app/sites/default/food/main.png",
  "7",
  new RichTextProperty(
    "<h1><b>Great food,<br>Good times.<br>Always at<br>Greg's.</b></h1>"
  ),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://images.unsplash.com/photo-1515003197210-e0cd71810b5f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    "no-repeat",
    "0%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(
    "Check out our different locations and our <br>social media for all our updates"
  ),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 90)
);

foodTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/food/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright Greg's Diner " + new Date().getFullYear()
);
