import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";

import styles from "./CardsContainerOrientationProperty.module.scss";
import { ReactComponent as CardsTiles } from "../../../img/CardsTiles.svg";
import { ReactComponent as CardsRows } from "../../../img/CardsRows.svg";

import { useTranslation } from "react-i18next";

export class CardsContainerOrientationProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <CardsContainerOrientationPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new CardsContainerOrientationProperty(value);
  }
}

const CardsContainerOrientationPropertyComponent: PropertyComponent<CardsContainerOrientationProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  return (
    <div className="medium-padded-container">
      <div className={styles["buttons-container"]}>
        <div
          className={`${styles["button"]} ${
            style === "1" ? styles["active"] : ""
          }`}
          onClick={() => handleStyleChange("1")}
        >
          <div>
            <CardsTiles className={styles["icon"]} />
            {t("dialog_93")}
          </div>
        </div>
        <div
          className={`${styles["button"]} ${
            style === "2" ? styles["active"] : ""
          }`}
          onClick={() => handleStyleChange("2")}
        >
          <div>
            <CardsRows className={styles["icon"]} />
            {t("dialog_94")}
          </div>
        </div>
      </div>
    </div>
  );
};
