import React, { useEffect, useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";

type Props = {
  handleOnApprove: (data: any) => void;
  handleOnCancel: (data: any) => void;
  handleOnError: (err: any) => void;
  annualPlanId?: string;
  monthlyPlanId?: string;
};

export default function PaypalSection({
  handleOnApprove,
  handleOnCancel,
  handleOnError,
  annualPlanId,
  monthlyPlanId,
}: Props): JSX.Element {
  const [loadedAnnualPlanId, setLoadedAnnualPlanId] = useState<
    string | undefined
  >();
  const [loadedMonthlyPlanId, setLoadedMonthlyPlanId] = useState<
    string | undefined
  >();

  useEffect(() => {
    setLoadedAnnualPlanId(annualPlanId);
    setLoadedMonthlyPlanId(monthlyPlanId);
  }, [annualPlanId, monthlyPlanId]);

  return (
    <>
      {loadedAnnualPlanId ? (
        <div style={{ display: loadedAnnualPlanId ? "block" : "none" }}>
          <PayPalButtons
            createSubscription={(data, actions) => {
              return actions.subscription
                .create({
                  plan_id: loadedAnnualPlanId,
                })
                .then((orderId) => {
                  return orderId;
                });
            }}
            onCancel={(data, actions: any) => {
              handleOnCancel(data);
              return;
            }}
            onError={(err: any) => {
              handleOnError(err);
              return;
            }}
            onApprove={(data, actions: any) => {
              handleOnApprove(data);
              return new Promise((resolve) => resolve());
            }}
            style={{
              color: "blue",
              shape: "pill",
              label: "pay",
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {loadedMonthlyPlanId ? (
        <div style={{ display: loadedMonthlyPlanId ? "block" : "none" }}>
          <PayPalButtons
            createSubscription={(data, actions) => {
              return actions.subscription
                .create({
                  plan_id: loadedMonthlyPlanId,
                })
                .then((orderId) => {
                  return orderId;
                });
            }}
            onCancel={(data, actions: any) => {
              handleOnCancel(data);
              return;
            }}
            onError={(err: any) => {
              handleOnError(err);
              return;
            }}
            onApprove={(data, actions: any) => {
              handleOnApprove(data);
              return new Promise((resolve) => resolve());
            }}
            style={{
              color: "blue",
              shape: "pill",
              label: "pay",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
