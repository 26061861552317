import { Layout } from "common/elements/types";
import { DumbElement } from "editor/elements/DumbElement/DumbElement";
import {
  getElementComponent,
  getElementConfiguration,
} from "editor/elements/registry";
import { getCompositeLayout } from "editor/states/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  Direction,
} from "react-beautiful-dnd";
import { elementRearrange } from "editor/states/layout";

import styles from "./DragWindow.module.scss";
import "./DragWindow.scss";

type Props = {
  selectedRef: React.MutableRefObject<any>;
  draggableId: string;
};

export const DragWindow: React.FunctionComponent<Props> = ({
  selectedRef,
  draggableId,
}) => {
  const dispatch = useDispatch();

  const layout = useSelector(getCompositeLayout);
  const [draggableParentId, setDraggableParentId] = useState<string>();
  const [draggableSiblingIds, setDraggableSiblingIds] = useState<string[]>();

  useEffect(() => {
    if (draggableId) {
      const elem = layout[draggableId];
      setDraggableParentId(elem.elementParentId);
      if (elem.elementParentId) {
        const parentElem = layout[elem.elementParentId];
        if (parentElem) {
          setDraggableSiblingIds(parentElem.elementChildrenId);
        }
      }
    }
  }, [draggableId, layout]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    let children: any[] = [];
    if (draggableSiblingIds) {
      draggableSiblingIds.forEach((element, index) => {
        children[index] = element;
      });
    }

    const removed = children?.splice(result.source.index, 1);
    if (removed && draggableParentId) {
      children?.splice(result.destination.index, 0, ...removed);
      //
      dispatch(elementRearrange(draggableParentId, children));
    }
  };

  const mapDraggableElementToComponent = (
    elementId: string,
    elementParentId: string | undefined,
    index: number,
    layoutComp: Layout,
    selRef: React.MutableRefObject<any>
  ) => {
    const elem = layoutComp[elementId];
    const elemParent = elementParentId ? layoutComp[elementParentId] : null;
    const component = getElementComponent(elem.elementKey);
    const parentConfig = getElementConfiguration(elem.elementKey);
    let dragDir: Direction = parentConfig.childDragDirection
      ? parentConfig.childDragDirection
      : "vertical";

    if (draggableParentId === elementId) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction={dragDir}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={elementId === "root" ? styles["root-context"] : ""}
                style={{
                  display: "inherit",
                  zIndex: 1,
                  backgroundColor: elementId === "root" ? "#7f7f7f" : "#fff",
                }}
              >
                <DumbElement
                  as={component}
                  key={elem.elementId}
                  elementParentId={elem.elementParentId}
                  elementParentKey={elemParent ? elemParent.elementKey : null}
                  properties={elem}
                  index={index}
                  selectedRef={selRef}
                  {...elem}
                >
                  {elem.elementChildrenId ? (
                    elem.elementChildrenId.map(
                      (elementChildId: any, idx: number) =>
                        mapDraggableElementToComponent(
                          elementChildId,
                          elem.elementId,
                          idx,
                          layoutComp,
                          selRef
                        )
                    )
                  ) : (
                    <></>
                  )}
                </DumbElement>
                <div style={{ display: "none" }}>{provided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    } else if (draggableSiblingIds?.includes(elementId)) {
      return (
        <Draggable
          key={elementId}
          draggableId={elementId}
          index={draggableSiblingIds.indexOf(elementId)}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`${
                elem.elementParentId === "root" ? styles["root-drag"] : ""
              } ${snapshot.isDragging ? styles["grabbing"] : styles["grab"]}`}
            >
              <DumbElement
                as={component}
                key={elem.elementId}
                elementParentId={elem.elementParentId}
                elementParentKey={elemParent ? elemParent.elementKey : null}
                properties={elem}
                index={index}
                selectedRef={selRef}
                className={`${snapshot.isDragging ? "grabbing" : "grab"}`}
                {...elem}
              >
                {elem.elementChildrenId ? (
                  elem.elementChildrenId.map(
                    (elementChildId: any, idx: number) =>
                      mapDraggableElementToComponent(
                        elementChildId,
                        elem.elementId,
                        idx,
                        layoutComp,
                        selRef
                      )
                  )
                ) : (
                  <></>
                )}
              </DumbElement>
            </div>
          )}
        </Draggable>
      );
    }

    const domElem = document.getElementById(elem.elementId);
    let topPos = 0;
    let height = 100;
    if (domElem) {
      const clientRect = domElem.getBoundingClientRect();
      topPos = clientRect.top + window.pageYOffset;
      height = clientRect.height;
    }
    return (
      <>
        <DumbElement
          as={component}
          key={elem.elementId}
          elementParentId={elem.elementParentId}
          elementParentKey={elemParent ? elemParent.elementKey : null}
          properties={elem}
          index={index}
          selectedRef={selRef}
          {...elem}
        >
          {elem.elementChildrenId ? (
            elem.elementChildrenId.map((elementChildId: any, idx: number) =>
              mapDraggableElementToComponent(
                elementChildId,
                elem.elementId,
                idx,
                layoutComp,
                selRef
              )
            )
          ) : (
            <></>
          )}
        </DumbElement>
        {elem.elementParentId === "root" ? (
          <div
            style={{
              position: "absolute",
              top: topPos,
              left: 0,
              width: "100%",
              height: height,
              background: "rgba(0,0,0,0.5)",
            }}
          ></div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return mapDraggableElementToComponent(
    "root",
    undefined,
    0,
    layout,
    selectedRef
  );
};
