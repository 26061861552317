import React from "react";

import { PhotoLibraryRounded } from "@material-ui/icons";
import {
  galleryElementKey,
  GalleryProperties,
} from "common/elements/sections/Gallery";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import { BackgroundColorProperty } from "editor/properties";
import WidthProperty from "editor/properties/WidthProperty";

import styles from "./Gallery.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import { GalleryImagesProperty } from "editor/properties/GalleryImagesProperty/GalleryImagesProperty";
import { ImageProperty } from "editor/properties/ImageProperty/ImageProperty";
import { GalleryStyleProperty } from "editor/properties/GalleryStyleProperty";
import { useTranslation } from "react-i18next";
export class GalleryElement {
  static readonly elementKey = galleryElementKey;
  static readonly label = "element_gallery_label";
  static readonly icon = PhotoLibraryRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_gallery_description";
  static readonly elementPreviewUrl =
    "/img/add-navbar-element-preview-default.svg";
  static readonly allowedChildren = ["image"];
  static readonly isResizeable = true;
  static readonly resizeDirections = ["e", "w"];
  static readonly resizeGuides = [1024, 1280, 1366, 1440, 1600, 0];
  static readonly childDragDirection = "vertical";
  static readonly dialogSize = "large";
  static readonly isReusable = true;

  static readonly properties: PropertiesOf<GalleryProperties> = {
    galleryImages: GalleryImagesProperty,
    galleryStyle: GalleryStyleProperty,
    width: WidthProperty,
    backgroundColorProperty: BackgroundColorProperty,
  };

  static renderProperties(element: ElementType) {
    return <GalleryPropertiesComponent element={element} />;
  }

  static create(properties: {
    galleryImages: ImageProperty[];
    galleryStyle: string;
    width: number;
    backgroundColorProperty: string;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}
const GalleryPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof GalleryElement.properties>(
    GalleryElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="images">
        <Tab eventKey="images" title="Images">
          {renderer.render("galleryImages")}
        </Tab>
        <Tab eventKey="style" title={t("dialog_1")}>
          {renderer.render("galleryStyle")}
        </Tab>
        <Tab eventKey="background-color" title={t("dialog_2")}>
          {renderer.render("backgroundColorProperty")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(GalleryElement);
