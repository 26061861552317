import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type MessengerProperties = {
  websiteTitle: string;
  isButton: boolean;
  isProduction: boolean;
  messengerButtonStyle: { style: string };
  messengerUsername: { text: string };
  treatmentProperty: {
    isTreatment: boolean;
    treatments: [
      {
        isControl: boolean;
        isCurrentTreatment: boolean;
        hasTested: boolean;
        property: string;
      }
    ];
  };
  isTestable: boolean;
  testableElementStyle?: string;
  color?: {
    color: string;
    fontColor: string;
  };
};

export function Messenger({
  properties: {
    websiteTitle,
    messengerButtonStyle,
    messengerUsername,
    treatmentProperty,
    isProduction,
    isButton,
    color,
  },
  className,
  ...props
}: ElementComponentProps<MessengerProperties>): JSX.Element {
  let linkUrl;
  if (isProduction) {
    linkUrl = "http://m.me/" + messengerUsername.text;
  } else {
    linkUrl = "#";
  }

  let classStyle = "";

  switch (messengerButtonStyle.style) {
    case "hobaw-messenger-btn-default":
      classStyle = "pos-fx pos-fx-br messenger-btn messenger-df";
      break;
    case "hobaw-messenger-btn-primary":
      classStyle = "pos-fx pos-fx-br cp--bgc cs--bgc--hov messenger-btn";
      break;
    case "hobaw-messenger-btn-secondary":
      classStyle = "pos-fx pos-fx-br cs--bgc cp--bgc--hov messenger-btn";
      break;
    default:
      classStyle = messengerButtonStyle.style;
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
      ></link>
      {isProduction ? (
        <a
          {...props}
          className={classStyle}
          target={"_blank"}
          href={linkUrl}
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-messenger"></i>
        </a>
      ) : (
        <a
          {...props}
          className={classStyle}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-messenger"></i>
        </a>
      )}
    </>
  );
}

export const messengerElementKey = "messenger";

elementComponentRegistry.register(messengerElementKey, Messenger);
