import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StyleRounded } from "@material-ui/icons";
import styles from "./Admin.module.scss";
import { Table } from "react-bootstrap";
import { getAllElementStyles } from "editor/states/website";
import { getAllAvailableElementStyles } from "editor/states/selectors";

export const ElementStyles: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const elementStyles = useSelector(getAllAvailableElementStyles);

  useEffect(() => {
    dispatch(getAllElementStyles());
  }, [dispatch]);

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <StyleRounded />
          <h3>Element Styles</h3>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card--big"]}>
          <Table style={{ fontSize: ".8rem" }}>
            <thead>
              <tr>
                <td>Element</td>
                <td>Element Key</td>
                <td>Property</td>
                <td>Style</td>
                <td>Description</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {elementStyles !== undefined ? (
                elementStyles?.map((elementStyle) => (
                  <tr>
                    <td>{elementStyle.elementName}</td>
                    <td>{elementStyle.elementKey}</td>
                    <td>{elementStyle.property}</td>
                    <td>{elementStyle.style}</td>
                    <td>{elementStyle.description}</td>
                    <td></td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
