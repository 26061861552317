import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type ContentContainerProperties = {};

export function ContentContainer({
  className,
  innerRef,
  children,
  ...props
}: ElementComponentProps<ContentContainerProperties>): JSX.Element {
  return (
    <div {...props} className={className + "content-container"}>
      {children}
    </div>
  );
}

export const contentContainerElementKey = "content-container";

elementComponentRegistry.register(contentContainerElementKey, ContentContainer);
