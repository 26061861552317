import {
  FormatAlignCenterRounded,
  FormatAlignJustifyRounded,
  FormatAlignLeftRounded,
  FormatAlignRightRounded,
  FormatBoldRounded,
  FormatItalicRounded,
  FormatUnderlinedRounded,
  LinkOffRounded,
  LinkRounded,
  FormatColorFillRounded,
  DeleteForeverRounded,
  CloseRounded,
  FormatShapesRounded,
  FormatSizeRounded,
  ExpandMoreRounded,
} from "@material-ui/icons";
import { RTEAlignmentProperty } from "editor/properties/RTEAlignmentProperty";
import { getPrimaryColor, getSelectedElementId } from "editor/selectors";
import { propertySet } from "editor/states/layout";
import { getCompositeLayout } from "editor/states/selectors";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ColorSwatch from "../ColorSwatch/ColorSwatch";

import style from "./RichTextHelper.module.scss";
import "./RichTextHelper.scss";

import { useTranslation } from "react-i18next";

type Props = {
  showHelper: boolean;
  textRangeParent: Selection | null | undefined;
  clickHandler: () => void;
  handleTemplLink: (
    attr: {
      tempId: string;
      top: number;
      left: number;
      selectedId: string;
    } | null
  ) => void;
  handleTempColor: (
    attr: {
      tempId: string;
      top: number;
      left: number;
      selectedId: string;
    } | null
  ) => void;
  handleDelete: () => void;
  handleBlur: () => void;
};

export default function RichTextHelper({
  showHelper,
  textRangeParent,
  clickHandler,
  handleTemplLink,
  handleTempColor,
  handleDelete,
  handleBlur,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedElementId = useSelector(getSelectedElementId);
  const layout = useSelector(getCompositeLayout);
  const statePrimaryColor = useSelector(getPrimaryColor);

  const [isShowColors, setIsShowColors] = useState(false);
  const [isShowFonts, setIsShowFonts] = useState(false);
  const [isShowSize, setIsShowSize] = useState(false);
  const [isShowTextStyle, setIsShowTextStyle] = useState(false);
  const primaryFonts = [
    "Roboto",
    "Lora",
    "Spartan",
    "Raleway",
    "Overpass",
    "Rozha One",
    "Comfortaa",
    "Copse",
    "Space Mono",
  ];
  const secondaryFonts = [
    "Noto Sans JP",
    "Poppins",
    "Montserrat",
    "Libre Baskerville",
    "Kumbh Sans",
    "Arvo",
    "Jost",
    "Nixie One",
  ];

  if (!selectedElementId) {
    return <></>;
  }

  const selectedElement = layout[selectedElementId];

  if (!selectedElement) {
    return <></>;
  }

  const elem = document.getElementById(selectedElementId);
  if (!elem) {
    return <></>;
  }
  const clientRect = elem.getBoundingClientRect();
  const isSmall = clientRect.width < 666 ? true : false;
  const leftPos = isSmall
    ? clientRect.left + window.pageXOffset + clientRect.width - 204
    : clientRect.left + window.pageXOffset + clientRect.width - 967;
  const topPos = isSmall
    ? clientRect.top + window.pageYOffset - 240
    : clientRect.top + window.pageYOffset - 70;
  const width = isSmall ? 207 : 970;

  const isItemFormatted = (tag: string) => {
    var sel = window.getSelection();
    if (sel && sel.toString() !== "") {
      var selection = sel.getRangeAt(0);
      if (selection) {
        if (
          selection.startContainer?.parentElement?.tagName === tag ||
          selection.endContainer?.parentElement?.tagName === tag
        ) {
          return "richTextHelper-button--highlight";
        } else {
          if (
            sel.focusNode?.previousSibling?.firstChild?.textContent ===
            sel.toString()
          ) {
            if (sel.focusNode?.previousSibling?.nodeName === tag) {
              return "richTextHelper-button--highlight";
            }
          }
          return "richTextHelper-button--normal";
        }
      } else {
        return "richTextHelper-button--normal";
      }
    }
    return "richTextHelper-button--normal";
  };

  const isItemFont = (face: string) => {
    var sel = window.getSelection();
    if (sel && sel.toString() !== "") {
      var selection = sel.getRangeAt(0);
      if (selection) {
        if (
          (selection.startContainer?.parentElement?.tagName === "FONT" ||
            selection.endContainer?.parentElement?.tagName === "FONT") &&
          selection.startContainer?.parentElement?.getAttribute("FACE") === face
        ) {
          return "selected";
        } else {
          return "normal";
        }
      } else {
        return "normal";
      }
    }
    return "normal";
  };

  const isParentAligned = (tag: string) => {
    const editor = document.getElementById("edit-" + selectedElementId);
    if (editor) {
      if (window.getComputedStyle(editor).textAlign === tag) {
        return "richTextHelper-button--highlight";
      } else {
        return "richTextHelper-button--normal";
      }
    } else {
      return "richTextHelper-button--normal";
    }
  };

  const handleBasicFormatting = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: string,
    arg?: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    document.execCommand(action, false, arg);
    clickHandler();
    setIsShowTextStyle(false);
  };

  const handleRemoveFormatting = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    document.execCommand("formatBlock", false, "p");
    clickHandler();
    setIsShowTextStyle(false);
  };

  const handleClose = () => {
    handleBlur();
    clickHandler();
  };

  const handleUnlink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    document.execCommand("unlink", false);
    clickHandler();
  };

  const handleAlignment = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      propertySet(
        selectedElementId,
        "rteAlignment",
        new RTEAlignmentProperty(action)
      )
    );
    var editor = document.getElementById("edit-" + selectedElementId);
    if (editor) editor.style.setProperty("text-align", action);
  };

  const handleChangeFont = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    font: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    document.execCommand("fontName", false, font);
    setIsShowFonts(false);
    clickHandler();
  };

  const handleSwitchColor = (color: string, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    var sel = window.getSelection();
    var isColoredAlready = false;
    if (sel && sel.toString() !== "") {
      var selection = sel.getRangeAt(0);
      if (selection) {
        if (
          selection.startContainer?.parentElement?.tagName === "SPAN" ||
          selection.endContainer?.parentElement?.tagName === "SPAN"
        ) {
          document.execCommand("removeFormat", false, "foreColor");
        } else {
          if (
            sel.focusNode?.previousSibling?.firstChild?.textContent ===
            sel.toString()
          ) {
            if (sel.focusNode?.previousSibling?.nodeName === "SPAN") {
              document.execCommand("removeFormat", false, "foreColor");
            }
          }
        }
      }
    }

    var uuid = Math.random().toString(36).slice(-6);
    if (!isColoredAlready) {
      document.execCommand("stylewithCSS", false);
      document.execCommand("foreColor", false, color);
      if (sel) {
        sel.focusNode?.parentElement?.setAttribute("id", "col-" + uuid);
        handleTempColor({
          tempId: "col-" + uuid,
          top: topPos,
          left: leftPos,
          selectedId: selectedElementId,
        });
      }
    } else {
      document.execCommand("removeFormat", false, "foreColor");
    }
    clickHandler();
    setIsShowColors(false);
  };

  const handleLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    var sel = window.getSelection();
    var isLinkAlready = false;
    if (sel && sel.toString() !== "") {
      var selection = sel.getRangeAt(0);
      if (selection) {
        if (
          selection.startContainer?.parentElement?.tagName === "A" ||
          selection.endContainer?.parentElement?.tagName === "A"
        ) {
          isLinkAlready = true;
        } else {
          if (
            sel.focusNode?.previousSibling?.firstChild?.textContent ===
            sel.toString()
          ) {
            if (sel.focusNode?.previousSibling?.nodeName === "A") {
              isLinkAlready = true;
            }
          }
        }
      }
    }

    var uuid = Math.random().toString(36).slice(-6);
    if (!isLinkAlready) {
      document.execCommand("createLink", false, "#");
      if (sel) {
        sel.focusNode?.parentElement?.setAttribute("id", "link-" + uuid);
        handleTemplLink({
          tempId: "link-" + uuid,
          top: topPos,
          left: leftPos,
          selectedId: selectedElementId,
        });
      }
    } else {
      if (sel) {
        let id;
        try {
          id = ((sel?.focusNode
            ?.previousSibling as any) as Element).getAttribute("id"); //Wrong
        } catch {}

        if (id && id !== undefined) {
          handleTemplLink({
            tempId: id,
            top: topPos,
            left: leftPos,
            selectedId: selectedElementId,
          });
        } else {
          sel.focusNode?.parentElement?.setAttribute("id", "link-" + uuid);
          handleTemplLink({
            tempId: "link-" + uuid,
            top: topPos,
            left: leftPos,
            selectedId: selectedElementId,
          });
        }
      }
    }

    clickHandler();
  };

  const handleShowColors = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (isShowColors) {
      setIsShowColors(false);
    } else {
      setIsShowColors(true);
      setIsShowSize(false);
      setIsShowFonts(false);
      setIsShowTextStyle(false);
    }
  };

  const hanndleShowFonts = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (isShowFonts) {
      setIsShowFonts(false);
    } else {
      setIsShowFonts(true);
      setIsShowSize(false);
      setIsShowColors(false);
      setIsShowTextStyle(false);
    }
  };

  const handleShowTextStyle = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (isShowTextStyle) {
      setIsShowTextStyle(false);
    } else {
      setIsShowTextStyle(true);
      setIsShowFonts(false);
      setIsShowSize(false);
      setIsShowColors(false);
    }
  };

  const hanndleShowSizes = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (isShowSize) {
      setIsShowSize(false);
    } else {
      setIsShowSize(true);
      setIsShowColors(false);
      setIsShowFonts(false);
      setIsShowTextStyle(false);
    }
  };

  return (
    <>
      <div
        className={`${style["richTextHelper-cover"]}`}
        onMouseOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div
          className={`${style["richTextHelper-dialog"]}`}
          style={{
            left: leftPos + window.pageXOffset,
            top: topPos,
            width: width,
            height: isSmall ? 220 : 50,
            flexDirection: isSmall ? "column" : "row",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div
            className={`${style["richTextHelper-dialog-container"]}`}
            style={{
              alignItems: "center",
            }}
          >
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="font-size-tooltip">{t("rth_font_0")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isItemFormatted("FONT")]
                }`}
                onMouseDown={(e) => {
                  handleShowTextStyle(e);
                }}
                style={{ width: 200 }}
              >
                <ExpandMoreRounded
                  style={{ marginRight: 8 }}
                  className={`${style["normal"]}`}
                />
                {t("rth_font_1")}
              </div>
            </OverlayTrigger>
            {/* <div
              className={`${style["richTextHelper-button"]} ${style[isItemFormatted("p")]}`}
              onMouseDown={handleRemoveFormatting}
            >
              <FilterNoneRounded />
            </div>
            <div
              className={`${style["richTextHelper-button"]} ${style[isItemFormatted("H1")]
                }`}
              onMouseDown={(e) => {
                handleBasicFormatting(e, "formatBlock", "h1"); //
              }}
            >
              <Filter1Rounded />
            </div>
            <div
              className={`${style["richTextHelper-button"]} ${style[isItemFormatted("H2")]
                }`}
              onMouseDown={(e) => {
                handleBasicFormatting(e, "formatBlock", "h2");
              }}
            >
              <Filter2Rounded />
            </div>
            <div
              className={`${style["richTextHelper-button"]} ${style[isItemFormatted("H3")]
                }`}
              onMouseDown={(e) => {
                handleBasicFormatting(e, "formatBlock", "h3");
              }}
            >
              <Filter3Rounded />
            </div> */}
          </div>
          {isSmall ? (
            <></>
          ) : (
            <div className={style["richTextHelper-divider"]}></div>
          )}
          <div
            className={`${style["richTextHelper-dialog-container"]}`}
            style={{
              alignItems: "center",
            }}
          >
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="font-family-tooltip">{t("rth_font_2")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isItemFormatted("FONT")]
                }`}
                onMouseDown={(e) => {
                  hanndleShowFonts(e);
                }}
                style={{ width: 80 }}
              >
                <FormatShapesRounded
                  style={{ marginRight: 8 }}
                  className={`${style["normal"]}`}
                />{" "}
                {t("rth_font_3")}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="font-size-2-tooltip">{t("rth_font_4")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isItemFormatted("I")]
                }`}
                onMouseDown={(e) => {
                  hanndleShowSizes(e);
                }}
                style={{ width: 80 }}
              >
                <FormatSizeRounded
                  style={{ marginRight: 8 }}
                  className={`${style["normal"]}`}
                />{" "}
                {t("rth_font_5")}
              </div>
            </OverlayTrigger>
          </div>
          {isSmall ? (
            <></>
          ) : (
            <div className={style["richTextHelper-divider"]}></div>
          )}
          <div
            className={`${style["richTextHelper-dialog-container"]}`}
            style={{
              alignItems: "center",
            }}
          >
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={<Tooltip id="bold-tooltip">{t("rth_font_6")}</Tooltip>}
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isItemFormatted("B")]
                }`}
                onMouseDown={(e) => {
                  handleBasicFormatting(e, "bold");
                }}
              >
                <FormatBoldRounded className={`${style["normal"]}`} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="italize-tooltip">{t("rth_font_7")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isItemFormatted("I")]
                }`}
                onMouseDown={(e) => {
                  handleBasicFormatting(e, "italic");
                }}
              >
                <FormatItalicRounded className={`${style["normal"]}`} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="underline-tooltip">{t("rth_font_8")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isItemFormatted("U")]
                }`}
                onMouseDown={(e) => {
                  handleBasicFormatting(e, "underline");
                }}
              >
                <FormatUnderlinedRounded className={`${style["normal"]}`} />
              </div>
            </OverlayTrigger>
            {isItemFormatted("SPAN") !== "richTextHelper-button--normal" ? (
              <OverlayTrigger
                placement={isSmall ? "right" : "top"}
                overlay={
                  <Tooltip id="color-tooltip">{t("rth_font_9")}</Tooltip>
                }
              >
                <div
                  className={`${style["richTextHelper-button"]}`}
                  onMouseDown={(e) => {
                    handleShowColors(e);
                  }}
                >
                  {isShowColors ? (
                    <CloseRounded className={`${style["normal"]}`} />
                  ) : (
                    <FormatColorFillRounded
                      htmlColor={statePrimaryColor ? statePrimaryColor : "red"}
                    />
                  )}
                </div>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement={isSmall ? "right" : "top"}
                overlay={
                  <Tooltip id="color-tooltip">{t("rth_font_9")}</Tooltip>
                }
              >
                <div
                  className={`${style["richTextHelper-button"]}`}
                  onMouseDown={(e) => {
                    handleShowColors(e);
                  }}
                >
                  {isShowColors ? (
                    <CloseRounded className={`${style["normal"]}`} />
                  ) : (
                    <FormatColorFillRounded
                      htmlColor={statePrimaryColor ? statePrimaryColor : "red"}
                    />
                  )}
                </div>
              </OverlayTrigger>
            )}
          </div>
          {isSmall ? (
            <></>
          ) : (
            <div className={style["richTextHelper-divider"]}></div>
          )}
          <div
            className={`${style["richTextHelper-dialog-container"]}`}
            style={{
              alignItems: "center",
            }}
          >
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="align-left-tooltip">{t("rth_font_10")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isParentAligned("left")]
                }`}
                onMouseDown={(e) => {
                  handleAlignment(e, "left");
                }}
              >
                <FormatAlignLeftRounded className={`${style["normal"]}`} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="align-center-tooltip">{t("rth_font_11")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isParentAligned("center")]
                }`}
                onMouseDown={(e) => {
                  handleAlignment(e, "center");
                }}
              >
                <FormatAlignCenterRounded className={`${style["normal"]}`} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="align-right-tooltip">{t("rth_font_12")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isParentAligned("right")]
                }`}
                onMouseDown={(e) => {
                  handleAlignment(e, "right");
                }}
              >
                <FormatAlignRightRounded className={`${style["normal"]}`} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="align-justify-tooltip">{t("rth_font_13")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${
                  style[isParentAligned("justify")]
                }`}
                onMouseDown={(e) => {
                  handleAlignment(e, "justify");
                }}
              >
                <FormatAlignJustifyRounded className={`${style["normal"]}`} />
              </div>
            </OverlayTrigger>
          </div>
          <div
            className={`${style["richTextHelper-dialog-container"]}`}
            style={{
              alignItems: "center",
            }}
          >
            {isSmall ? (
              <></>
            ) : (
              <div className={style["richTextHelper-divider"]}></div>
            )}
            {isItemFormatted("A") !== "richTextHelper-button--normal" ? (
              <OverlayTrigger
                placement={isSmall ? "right" : "top"}
                overlay={
                  <Tooltip id="create-tooltip">{t("rth_font_14")}</Tooltip>
                }
              >
                <div
                  className={`${style["richTextHelper-button"]}`}
                  onMouseDown={(e) => {
                    handleUnlink(e);
                  }}
                >
                  <LinkOffRounded className={`${style["normal"]}`} />
                </div>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement={isSmall ? "right" : "top"}
                overlay={
                  <Tooltip id="create-link-tooltip">{t("rth_font_14")}</Tooltip>
                }
              >
                <div
                  className={`${style["richTextHelper-button"]} ${
                    style[isItemFormatted("A")]
                  }`}
                  onMouseDown={(e) => {
                    handleLink(e);
                  }}
                >
                  <LinkRounded className={`${style["normal"]}`} />
                </div>
              </OverlayTrigger>
            )}
            {isSmall ? (
              <></>
            ) : (
              <div className={style["richTextHelper-divider"]}></div>
            )}
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={
                <Tooltip id="delete-tooltip">{t("rth_font_15")}</Tooltip>
              }
            >
              <div
                className={`${style["richTextHelper-button"]} ${style["richTextHelper-button-delete"]}`}
                onMouseDown={handleDelete}
              >
                <DeleteForeverRounded />
              </div>
            </OverlayTrigger>
            {isSmall ? (
              <></>
            ) : (
              <div className={style["richTextHelper-divider"]}></div>
            )}
            <OverlayTrigger
              placement={isSmall ? "right" : "top"}
              overlay={<Tooltip id="add-tooltip">{t("close")}</Tooltip>}
            >
              <div
                className={`${style["richTextHelper-button"]}`}
                onMouseDown={handleClose}
                style={
                  isSmall
                    ? {
                        width: 85,
                        padding: 2,
                      }
                    : {}
                }
              >
                <CloseRounded className={`${style["normal"]}`} />
                {isSmall ? " Close" : <></>}
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      {isShowTextStyle ? (
        <div
          className={`${style["richTextHelper-color"]}`}
          style={
            isSmall
              ? {
                  position: "absolute",
                  zIndex: 1,
                  left: leftPos + window.pageXOffset - 245,
                  top: topPos + 16,
                }
              : {
                  position: "absolute",
                  zIndex: 1,
                  left: leftPos + window.pageXOffset + 2,
                  top: topPos + 72,
                }
          }
        >
          <div className="picker text-style-picker">
            <div
              className={`font-container  ${style[isItemFormatted("p")]}`}
              onMouseDown={handleRemoveFormatting}
            >
              {t("rth_font_16")}
            </div>
            <div
              className={`font-container  ${style[isItemFormatted("H1")]}`}
              onMouseDown={(e) => {
                handleBasicFormatting(e, "formatBlock", "h1"); //
              }}
            >
              <h1>{t("rth_font_17")}</h1>
            </div>
            <div
              className={`font-container  ${style[isItemFormatted("H2")]}`}
              onMouseDown={(e) => {
                handleBasicFormatting(e, "formatBlock", "h2"); //
              }}
            >
              <h2>{t("rth_font_18")}</h2>
            </div>
            <div
              className={`font-container  ${style[isItemFormatted("H3")]}`}
              onMouseDown={(e) => {
                handleBasicFormatting(e, "formatBlock", "h3"); //
              }}
            >
              <h3>{t("rth_font_19")}</h3>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isShowColors ? (
        <div
          className={`${style["richTextHelper-color"]}`}
          style={
            isSmall
              ? {
                  position: "absolute",
                  zIndex: 1,
                  left: leftPos + window.pageXOffset + 210,
                  top: topPos + 66,
                }
              : {
                  position: "absolute",
                  zIndex: 1,
                  left: leftPos + window.pageXOffset + 540,
                  top: topPos + 72,
                }
          }
        >
          <ColorSwatch handleColorClick={handleSwitchColor} isShadowed={true} />
        </div>
      ) : (
        <></>
      )}
      {isShowFonts ? (
        <div
          className={`${style["richTextHelper-color"]}`}
          style={
            isSmall
              ? {
                  position: "absolute",
                  zIndex: 1,
                  left: leftPos + window.pageXOffset - 295,
                  top: topPos + 16,
                }
              : {
                  position: "absolute",
                  zIndex: 1,
                  left: leftPos + window.pageXOffset + 202,
                  top: topPos + 72,
                }
          }
        >
          <div className="picker font-picker">
            <div className="primary">
              {[...primaryFonts].map((font) =>
                font ? (
                  <div
                    onMouseDown={(e) => {
                      handleChangeFont(e, font);
                    }}
                    className={`font-container ${isItemFont(font)}`}
                  >
                    {font}
                  </div>
                ) : (
                  <></>
                )
              )}
            </div>
            <div className="secondary">
              {[...secondaryFonts].map((font) =>
                font ? (
                  <div
                    onMouseDown={(e) => {
                      handleChangeFont(e, font);
                    }}
                    className={`font-container ${isItemFont(font)}`}
                  >
                    {font}
                  </div>
                ) : (
                  <></>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
