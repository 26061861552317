import { WbIncandescentRounded } from "@material-ui/icons";
import {
  headlineElementKey,
  HeadlineProperties,
} from "common/elements/base/Headline";
import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import React from "react";
import { elementDefinitionRegistry } from "../ElementDefinitionRegistry";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { HeadlineStyleProperty } from "editor/properties/HeadlineStyleProperty";
import {
  DimensionProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "editor/properties";

export class HeadlineElement {
  static readonly elementKey = headlineElementKey;
  static readonly label = "Headline";
  static readonly icon = WbIncandescentRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "A Headline";
  static readonly dialogPositionStatic = false;
  static readonly isDialogDisplay = false;

  static readonly properties: PropertiesOf<HeadlineProperties> = {
    headlineStyle: HeadlineStyleProperty,
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <HeadlinePropertiesComponent element={element} />;
  }

  static create(properties: {
    headlineStyle: HeadlineStyleProperty;
    richTextProperty: RichTextProperty;
    rteAlignment: RTEAlignmentProperty;
    dimension?: DimensionProperty;
  }) {
    return {
      ...properties,
      elementKey: this.elementKey,
    };
  }
}

const HeadlinePropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof HeadlineElement.properties>(
    HeadlineElement.properties,
    element
  );
  return <>{renderer.render("richTextProperty")}</>;
};

elementDefinitionRegistry.register(HeadlineElement);
