import { State } from "../store";

export const getRequestIsInProgress = (state: State): boolean =>
  state.api.requestInProgress;

export const getRequestError = (state: State): string | undefined =>
  state.api.error;

export const getAuthRequestState = (state: State): boolean =>
  state.api.authState;
