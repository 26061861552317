import {
  WebsiteResource,
  FindWebsiteBySlugUsingGETRequest,
  CreateWebsiteUsingPOSTRequest,
  UpdateWebsiteDetailsUsingPUTRequest,
  GenerateWebsiteUrlUsingPOSTRequest,
  UpdateWebsitePurposesUsingPOSTRequest,
  RestPurpose,
  RestPurposeContact,
  RestPurposeLocation,
  RestPurposeSocial,
  RestPurposeTeam,
  RestPurposeTeamMember,
  RestPurposeWorkOrServiceOrProduct,
  RestPurposeReview,
  UpdateWebsiteDetailsUsingPOSTRequest,
} from "@urux/arm-of-dorne-api";
import {
  ApiAction,
  dispatchStart,
  dispatchSuccess,
  dispatchFailure,
} from "./actions";
import { websiteApi } from "../api";
import { TCreateCategory } from "./api";
import { ISetupFormValues } from "./Setup";

export const resetWebsiteState = (): Action => {
  return {
    type: "RESET_WEBSITE_STATE",
  };
};

export const updateWebsiteDetails = (
  websiteSlug: string,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  logoCreated: boolean
): ApiAction<Action> => async (dispatch) => {
  dispatchStart(dispatch, { type: "UPDATE_WEBSITE_DETAILS_REQUEST" });
  try {
    const request: UpdateWebsiteDetailsUsingPUTRequest = {
      websiteSlug: websiteSlug,
      request: {
        colorType,
        logoCreated,
        primaryColor,
        primaryFont,
        primaryRadius,
        primaryShadow,
        secondaryColor,
        secondaryFont,
        secondaryRadius,
        ternaryColor,
      },
    };

    const response = await websiteApi.updateWebsiteDetailsUsingPUT(request);
    dispatchSuccess(dispatch, {
      type: "FETCH_WEBSITE_SUCCESS",
      website: response,
    });
  } catch (e) {
    dispatchFailure(dispatch, { type: "CREATE_WEBSITE_FAILURE" }, e);
  }
};

export const generateUrl = (
  title: string,
  callback: (slug?: string, isValid?: boolean, oldUrl?: string) => void
): ApiAction<Action> => async (dispatch) => {
  dispatchStart(dispatch, { type: "CREATE_WEBSITE_URL_REQUEST" });

  try {
    const request: GenerateWebsiteUrlUsingPOSTRequest = {
      request: {
        title,
      },
    };

    const response = await websiteApi.generateWebsiteUrlUsingPOST(request);

    callback(response.slug, response.valid, response.oldUrl);
  } catch (e) {
    dispatchFailure(dispatch, { type: "CREATE_WEBSITE_URL_REQUEST" }, e);
  }
};

export const validateUrl = (
  url: string,
  callback: (slug?: string, isValid?: boolean, oldUrl?: string) => void
): ApiAction<Action> => async (dispatch) => {
  dispatchStart(dispatch, { type: "VALIDATE_WEBSITE_URL_REQUEST" });

  try {
    const request: GenerateWebsiteUrlUsingPOSTRequest = {
      request: {
        url,
      },
    };

    const response = await websiteApi.validateWebsiteUrlUsingPOST(request);

    callback(response.slug, response.valid, response.oldUrl);
  } catch (e) {
    dispatchFailure(dispatch, { type: "VALIDATE_WEBSITE_URL_REQUEST" }, e);
  }
};

export const getWebsiteDetails = (
  callback: (websiteResource: WebsiteResource) => void
): ApiAction<Action> => async (dispatch) => {
  dispatchStart(dispatch, { type: "GET_WEBSITE_DETAILS_REQUEST" });

  try {
    const response = await websiteApi.getWebsiteDetailsUsingGET();
    callback(response);

    dispatchSuccess(dispatch, {
      type: "GET_WEBSITE_DETAILS_SUCCESS",
    });
  } catch (e) {
    dispatchFailure(dispatch, { type: "GET_WEBSITE_DETAILS_FAILURE" }, e);
  }
};

export const createWebsiteCore = (
  title: string,
  category: TCreateCategory,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  logoCreated: boolean,
  widthGuide: number,
  userTimeZone: string,
  description: string,
  url: string,
  callback: (isSuccess: boolean, slug: string) => void
): ApiAction<Action> => async (dispatch) => {
  dispatchStart(dispatch, { type: "CREATE_WEBSITE_CORE_REQUEST" });

  try {
    const request: CreateWebsiteUsingPOSTRequest = {
      request: {
        title,
        category,
        colorType,
        logoCreated,
        primaryColor,
        primaryFont,
        primaryRadius,
        primaryShadow,
        secondaryColor,
        secondaryFont,
        secondaryRadius,
        ternaryColor,
        widthGuide,
        userTimeZone,
        description,
        url,
      },
    };

    const response = await websiteApi.createWebsiteUsingPOST(request);

    callback(response.success!, response.slug!);

    dispatchSuccess(dispatch, {
      type: "CREATE_WEBSITE_CORE_SUCCESS",
      website: response,
    });
  } catch (e) {
    dispatchFailure(dispatch, { type: "CREATE_WEBSITE_CORE_FAILURE" }, e);
  }
};

export const updateWebsitePurposes = (
  updatedValues: Partial<ISetupFormValues>
): ApiAction<Action> => async (dispatch) => {
  dispatchStart(dispatch, { type: "UPDATE_WEBSITE_PURPOSES_REQUEST" });

  try {
    let slug = updatedValues.url;
    let isPurposeContact = updatedValues.isPurposeContact;
    let isPurposeWorkServices = updatedValues.isPurposeWorkServices;
    let isPurposeProduct = updatedValues.isPurposeProduct;
    let isPurposeLocation = updatedValues.isPurposeLocation;
    let isPurposeReview = updatedValues.isPurposeReview;
    let isPurposeIncreaseSocial = updatedValues.isPurposeIncreaseSocial;
    let isPurposeIntroduceTeam = updatedValues.isPurposeIntroduceTeam;
    let restContact: RestPurposeContact = {
      mobile1: updatedValues.contact?.mobile1!,
      mobile2: updatedValues.contact?.mobile2!,
      mobile3: updatedValues.contact?.mobile3!,
      instagram: updatedValues.contact?.instagram!,
      whatsapp: updatedValues.contact?.whatsapp!,
      discord: updatedValues.contact?.discord!,
      messenger: updatedValues.contact?.messenger!,
      telegram: updatedValues.contact?.telegram!,
    };
    let restLocation: RestPurposeLocation = {
      address1: updatedValues.location?.address1,
      address2: updatedValues.location?.address2,
      city: updatedValues.location?.city,
      country: updatedValues.location?.country,
    };
    let restSocial: RestPurposeSocial = {
      facebook: updatedValues.social?.facebook,
      twitter: updatedValues.social?.twitter,
      instagram: updatedValues.social?.instagram,
      linkedin: updatedValues.social?.linkedin,
      whatsapp: updatedValues.social?.whatsapp,
      twitch: updatedValues.social?.twitch,
      tiktok: updatedValues.social?.tiktok,
      youtube: updatedValues.social?.youtube,
      telegram: updatedValues.social?.telegram,
      discord: updatedValues.social?.discord,
    };

    let restTeam: RestPurposeTeam = {
      description: undefined,
      teamMembers: [],
    };
    let teamMembers: RestPurposeTeamMember[] = [];
    if (updatedValues.team) {
      updatedValues.team.member.forEach(function (mem, index) {
        teamMembers.push({
          name: mem.name,
          position: mem.position,
          desription: mem.desription,
          photoUrl: mem.photoUrl?.filename,
        });
      });
      restTeam = {
        description: updatedValues.team.description,
        teamMembers: teamMembers,
      };
    }
    let restProduct: RestPurposeWorkOrServiceOrProduct[] = [];
    if (updatedValues.products) {
      updatedValues.products.forEach(function (product, index) {
        restProduct.push({
          description: product.description,
          isProduct: true,
          isWorkOrService: false,
          photoUrl: product.photoUrl?.filename,
          title: product.title,
        });
      });
    }
    let restWorkOrService: RestPurposeWorkOrServiceOrProduct[] = [];
    if (updatedValues.services) {
      updatedValues.services.forEach(function (service, index) {
        restWorkOrService.push({
          description: service.description,
          isProduct: true,
          isWorkOrService: false,
          photoUrl: service.photoUrl?.filename,
          title: service.title,
        });
      });
    }
    let restRevies: RestPurposeReview[] = [];
    if (updatedValues.reviews) {
      updatedValues.reviews.forEach(function (review, index) {
        restRevies.push({
          comment: review.comment,
          rating: review.rating,
          reviewer: review.reviewer,
          reviewerPhoto: review.reviewerPhoto?.filename,
          title: review.title,
        });
      });
    }
    let purpose: RestPurpose = {
      links: undefined,
      contact: restContact,
      isPurposeContact: updatedValues.isPurposeContact,
      isPurposeWorkServices: updatedValues.isPurposeWorkServices,
      isPurposeProduct: updatedValues.isPurposeProduct,
      isPurposeLocation: updatedValues.isPurposeLocation,
      isPurposeReview: updatedValues.isPurposeReview,
      isPurposeIncreaseSocial: updatedValues.isPurposeIncreaseSocial,
      isPurposeIntroduceTeam: updatedValues.isPurposeIntroduceTeam,
      location: restLocation,
      products: restProduct,
      reviews: restRevies,
      social: restSocial,
      team: restTeam,
      teamMembers: teamMembers,
      workOrService: restWorkOrService,
    };

    const request: UpdateWebsitePurposesUsingPOSTRequest = {
      request: {
        slug,
        isPurposeContact,
        isPurposeWorkServices,
        isPurposeProduct,
        isPurposeLocation,
        isPurposeReview,
        isPurposeIncreaseSocial,
        isPurposeIntroduceTeam,
        purpose,
      },
    };

    await websiteApi.updateWebsitePurposesUsingPOST(request);

    dispatchSuccess(dispatch, {
      type: "UPDATE_WEBSITE_PURPOSES_REQUEST_SUCCESS",
    });
  } catch (e) {
    dispatchFailure(
      dispatch,
      { type: "UPDATE_WEBSITE_PURPOSES_REQUEST_FAILURE" },
      e
    );
  }
};

export const createWebsite = (
  title: string,
  category: TCreateCategory,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  logoCreated: boolean,
  widthGuide: number,
  userTimeZone: string,
  description: string,
  url: string,
  callback: (websiteSlug: string) => void
): ApiAction<Action> => async (dispatch) => {
  dispatchStart(dispatch, { type: "CREATE_WEBSITE_REQUEST" });

  try {
    const request: CreateWebsiteUsingPOSTRequest = {
      request: {
        title,
        category,
        colorType,
        logoCreated,
        primaryColor,
        primaryFont,
        primaryRadius,
        primaryShadow,
        secondaryColor,
        secondaryFont,
        secondaryRadius,
        ternaryColor,
        widthGuide,
        userTimeZone,
        description,
        url,
      },
    };

    const response = await websiteApi.createWebsiteUsingPOST(request);

    callback(response.slug!);

    dispatchSuccess(dispatch, {
      type: "CREATE_WEBSITE_SUCCESS",
      website: response,
    });

    dispatchStart(dispatch, {
      type: "AUTH_FETCH_CURRENT_USER_REQUEST",
    });
  } catch (e) {
    dispatchFailure(dispatch, { type: "CREATE_WEBSITE_FAILURE" }, e);
  }
};

export const updateWebsiteDetailsOnCreate = (
  url: string | undefined,
  widthGuide: number,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  logoCreated: boolean,
  callback: (websiteSlug: string) => void
): ApiAction<Action> => async (dispatch) => {
  dispatchStart(dispatch, { type: "CREATE_WEBSITE_REQUEST" });

  try {
    const request: UpdateWebsiteDetailsUsingPOSTRequest = {
      request: {
        url,
        widthGuide,
        primaryFont,
        secondaryFont,
        primaryColor,
        secondaryColor,
        ternaryColor,
        colorType,
        primaryRadius,
        secondaryRadius,
        primaryShadow,
        logoCreated,
      },
    };

    const response = await websiteApi.updateWebsiteDetailsUsingPOST(request);

    callback(response.slug!);

    dispatchSuccess(dispatch, {
      type: "CREATE_WEBSITE_SUCCESS",
      website: response,
    });

    dispatchStart(dispatch, {
      type: "AUTH_FETCH_CURRENT_USER_REQUEST",
    });
  } catch (e) {
    dispatchFailure(dispatch, { type: "CREATE_WEBSITE_FAILURE" }, e);
  }
};

export const fetchWebsite = (slug: string): ApiAction<Action> => async (
  dispatch
) => {
  dispatchStart(dispatch, { type: "FETCH_WEBSITE_REQUEST" });

  try {
    const request: FindWebsiteBySlugUsingGETRequest = {
      websiteSlug: slug,
    };
    const response = await websiteApi.findWebsiteBySlugUsingGET(request);
    dispatchSuccess(dispatch, {
      type: "FETCH_WEBSITE_SUCCESS",
      website: response,
    });
  } catch (e) {
    dispatchFailure(dispatch, { type: "FETCH_WEBSITE_FAILURE" }, e);
  }
};

export type Action =
  | { type: "RESET_WEBSITE_STATE" }
  // -
  | { type: "CREATE_WEBSITE_CORE_REQUEST" }
  | { type: "CREATE_WEBSITE_CORE_SUCCESS"; website: WebsiteResource }
  | { type: "CREATE_WEBSITE_CORE_FAILURE" }
  | { type: "CREATE_WEBSITE_REQUEST" }
  | { type: "CREATE_WEBSITE_SUCCESS"; website: WebsiteResource }
  | { type: "CREATE_WEBSITE_FAILURE" }
  // -
  | { type: "FETCH_WEBSITE_REQUEST" }
  | { type: "FETCH_WEBSITE_SUCCESS"; website: WebsiteResource }
  | { type: "FETCH_WEBSITE_FAILURE" }
  | { type: "UPDATE_WEBSITE_DETAILS_REQUEST" }
  | { type: "UPDATE_WEBSITE_DETAILS_SUCCESS"; website: WebsiteResource }
  | { type: "AUTH_FETCH_CURRENT_USER_REQUEST" }
  | { type: "CREATE_WEBSITE_URL_REQUEST" }
  | { type: "VALIDATE_WEBSITE_URL_REQUEST" }
  | { type: "GET_WEBSITE_DETAILS_REQUEST" }
  | { type: "GET_WEBSITE_DETAILS_SUCCESS" }
  | { type: "GET_WEBSITE_DETAILS_FAILURE" }
  | { type: "UPDATE_WEBSITE_PURPOSES_REQUEST" }
  | { type: "UPDATE_WEBSITE_PURPOSES_REQUEST_SUCCESS" }
  | { type: "UPDATE_WEBSITE_PURPOSES_REQUEST_FAILURE" };
