import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Facebook, Twitter } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { HeadingElement } from "editor/elements/base/Heading";
import { LeadElement } from "editor/elements/base/Lead";
import { ArticleElement } from "editor/elements/components/Article";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { ImageElement } from "editor/elements/base/Image/Image";
import { ButtonElement } from "editor/elements/components/Button/Button";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { ParagraphElement } from "editor/elements/base/Paragraph";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const resumeTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "resume";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 4,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 5,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultNavbar(
        [
          new LinkProperty("Bio", "/", false, false, false),
          new LinkProperty("Experiences", "/", false, false, false),
          new LinkProperty("Education", "/", false, false, false),
          new LinkProperty("Get in Touch", "/", false, false, false),
        ],
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/resume/main.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        "1",
        new BackgroundColorProperty("#F2F2F2"),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "2",
        "2"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/resume/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright Beth Cruz " + new Date().getFullYear()
      );

    default:
      return resumeTemplates[layoutKey];
  }
};

export const childPageTemplates: ChildPage[] = [];

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page Real Estate",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: resumeTemplates,
  },
};

resumeTemplates["navbar"] = TemplatesFactory.createDefaultNavbar(
  [
    new LinkProperty("Bio", "/", false, false, false),
    new LinkProperty("Experiences", "/", false, false, false),
    new LinkProperty("Education", "/", false, false, false),
    new LinkProperty("Get in Touch", "/", false, false, false),
  ],
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/resume/main.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "1",
  new BackgroundColorProperty("#F2F2F2"),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "2",
  "2"
);

resumeTemplates["articles"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#F2F2F2",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2>Sales and advertising professional in tech</h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div elementkey="lead" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><br><br>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt.<br><br></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div elementkey="lead" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><br></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            ButtonElement.create({
              buttonStyle: new ButtonStyleProperty("hobaw-btn-primary"),
              buttonLink: new LinkProperty(
                "Download Resume",
                "",
                false,
                false,
                false
              ),
              treatmentProperty: new TreatmentProperty(false),
              isProduction: false,
              isButton: false,
              isTestable: false,
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://s3.amazonaws.com/img.goodweb.app/sites/default/resume/main-resume.png",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

resumeTemplates["articles2"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#F2F2F2",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(`<h2>Biography</h2>`),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div elementkey="lead" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><div><div style=""><div><div><div><div><div><br></div><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div><div><br></div><div>Beth Cruz</div><div>beth.cruz@bethcruz.com</div><div>(+12) 345 67 892</div><div><br></div><div>Brooklyn, New York</div></div></div></div></div></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ParagraphElement.create({
                  richTextProperty: new RichTextProperty(
                    `<div id="kiy6wqodb" elementkey="paragraph" websiteslug="bcfoo" class="ce element  hobaw-rte paragraph rte-left cg-df-dg" style="visibility: visible; opacity: 1;"><div><br></div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<div><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br></div><div><br></div><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.<br></div><div><br></div><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br></div><div><br></div><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute.<br></div><div><br></div><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br></div></div>`
                  ),
                  rteAlignment: new RTEAlignmentProperty("left"),
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

resumeTemplates["articles3"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#F2F2F2",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(`<h2>Experience</h2>`),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div id="h5ur5qgcc" elementkey="lead" websiteslug="bcfoo" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><div><div style=""><div><div><div><div><div><div><div><div><div><div>Google, 2002-2004</div></div></div></div></div></div></div></div></div></div></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ParagraphElement.create({
                  richTextProperty: new RichTextProperty(
                    `<div id="ejgwluc1o" elementkey="paragraph" websiteslug="bcfoo" class="ce element  hobaw-rte paragraph rte-left cg-df-dg" style="visibility: visible; opacity: 1;"><div style=""><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.<br></div></div></div>`
                  ),
                  rteAlignment: new RTEAlignmentProperty("left"),
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

resumeTemplates["articles4"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#F2F2F2",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div id="h5ur5qgcc" elementkey="lead" websiteslug="bcfoo" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><div><div style=""><div><div><div><div><div><div><div><div><div><div>Airbnb, 2004-2008</div></div></div></div></div></div></div></div></div></div></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ParagraphElement.create({
                  richTextProperty: new RichTextProperty(
                    `<div id="kb49gwuir" elementkey="article-content" websiteslug="bcfoo" class="element  article-content article-content-style-2"><div id="x14sox7vg" elementkey="paragraph" websiteslug="bcfoo" class="ce element  hobaw-rte paragraph rte-left cg-df-dg"><div id="ejgwluc1o" elementkey="paragraph" websiteslug="bcfoo" class="ce element  hobaw-rte paragraph rte-left cg-df-dg" style="visibility: visible; opacity: 1;"><div><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.<br></div></div></div></div><div class="ce image-container"><picture id="kr68kx0rm" elementkey="image" websiteslug="bcfoo" class="1" style=""><img src="https://images.unsplash.com/photo-1573167582108-000d05b2faad?crop=entropy&amp;cs=tinysrgb&amp;fm=jpg&amp;ixlib=rb-1.2.1&amp;q=80&amp;raw_url=true&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1738" alt="Image" class="element  rs"></picture></div></div>`
                  ),
                  rteAlignment: new RTEAlignmentProperty("left"),
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

resumeTemplates["articles5"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#F2F2F2",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div id="h5ur5qgcc" elementkey="lead" websiteslug="bcfoo" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><div><div style=""><div><div><div><div><div><div><div><div><div><div>AOL, 2008-2021</div></div></div></div></div></div></div></div></div></div></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ParagraphElement.create({
                  richTextProperty: new RichTextProperty(
                    `<div id="ejgwluc1o" elementkey="paragraph" websiteslug="bcfoo" class="ce element  hobaw-rte paragraph rte-left cg-df-dg" style="visibility: visible; opacity: 1;"><div style=""><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.<br></div></div></div>`
                  ),
                  rteAlignment: new RTEAlignmentProperty("left"),
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

resumeTemplates["articles6"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#F2F2F2",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(`<h2>Education</h2>`),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div id="h5ur5qgcc" elementkey="lead" websiteslug="bcfoo" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><div><div style=""><div><div><div><div><div><div><div><div><div><div>MIT, 1998-2002</div></div></div></div></div></div></div></div></div></div></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ParagraphElement.create({
                  richTextProperty: new RichTextProperty(
                    `<div id="d6sxsjpmz" elementkey="article-content" websiteslug="bcfoo" class="element  article-content article-content-style-2"><div class="ce image-container"><picture id="wtbc59xaj" elementkey="image" websiteslug="bcfoo" class="1"><img src="https://images.unsplash.com/photo-1522008818026-240ebed561e5?crop=entropy&amp;cs=tinysrgb&amp;fm=jpg&amp;ixlib=rb-1.2.1&amp;q=80&amp;raw_url=true&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1740" alt="Image" class="element  rs"></picture></div><div id="pr6pu3gcx" elementkey="paragraph" websiteslug="bcfoo" class="ce element  hobaw-rte paragraph rte-left cg-df-dg"><div id="ejgwluc1o" elementkey="paragraph" websiteslug="bcfoo" class="ce element  hobaw-rte paragraph rte-left cg-df-dg" style="visibility: visible; opacity: 1;"><div><div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.<br></div></div></div></div></div>`
                  ),
                  rteAlignment: new RTEAlignmentProperty("left"),
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

resumeTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("1"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Get in Touch</h2>"),
  new RTEAlignmentProperty("left"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#F2F2F2"),
  new RichTextProperty(
    "Fill in your details below and we'll be in contact with you soon."
  ),
  new RTEAlignmentProperty("left"),
  "2",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

resumeTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/resume/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright Beth Cruz " + new Date().getFullYear()
);
