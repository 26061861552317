import React from "react";
import { IProperty } from "../types";

export class DimensionProperty implements IProperty {
  constructor(readonly width: number, readonly height: number) {}

  render(): JSX.Element {
    return <></>;
  }
}
