import { Formik } from "formik";
import React, { useState } from "react";
import { Form, Card } from "react-bootstrap";
import { Button } from "editor/components/base/Button/Button";
import * as yup from "yup";
import { Link } from "react-router-dom";

import styles from "./Auth.module.scss";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";
import { useDispatch } from "react-redux";
import { logActivity, resetPassword } from "./authActions";
import { loginPage } from "./Login";
import { signupPage } from "./SignUp";
import { ArrowBackRounded } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

const schema = yup.object({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

export type LoginFormValues = yup.InferType<typeof schema>;

interface IProps {
  onSubmit: (values: LoginFormValues) => void;
  isSubmitting: boolean;
  error?: string;
}

export const LoginForm: React.FunctionComponent<IProps> = ({
  onSubmit,
  isSubmitting,
  error,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isRequestResetPassword, setIsRequestResetPassword] = useState<boolean>(
    false
  );
  const [requestResetUsername, setRequestResetUsername] = useState<string>();
  const [isRequested, setIsRequested] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isDisplayError, setIsDisplayError] = useState<boolean>(false);

  let loginErrorMessage: string = "";
  if (error) {
    if (error === "Unauthorized") {
      loginErrorMessage = t("login_page_errormessage");
    } else {
      loginErrorMessage = error;
    }
  }

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleForgotPassword = () => {
    setIsRequestResetPassword(!isRequestResetPassword);
  };

  const handleUsernameChange = (e: any) => {
    setIsDisplayError(false);
    let val = e.target.value;
    if (val && val !== "" && validateEmail(val)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleUsernameBlur = (e: any) => {
    setRequestResetUsername(e.target.value);
  };

  const handleResetPassword = () => {
    if (isValid && requestResetUsername) {
      setIsDisplayError(false);
      dispatch(resetPassword(requestResetUsername, handleResponse));
    } else {
      setIsDisplayError(true);
    }
  };

  const handleResponse = () => {
    setIsRequested(true);
  };

  return (
    <>
      {isRequestResetPassword ? (
        <Card className={styles["auth__card"]}>
          <div className={styles["auth__login"]}>
            <div
              onClick={handleForgotPassword}
              style={{
                display: "flex",
                color: "#3185fc",
                cursor: "pointer",
                marginBottom: "24px",
              }}
            >
              <ArrowBackRounded /> {t("login_page_back")}
            </div>
            <h3>{t("login_page_reset")}</h3>
            {isRequested ? (
              <>
                <div
                  style={{
                    marginTop: "12px",
                    marginBottom: "12px",
                    backgroundColor: "#ffca7f4d",
                    padding: "18px",
                    borderRadius: "18px",
                  }}
                >
                  {t("login_page_emailsent")} {requestResetUsername}{" "}
                  {t("login_page_emailsent2")}
                </div>
              </>
            ) : (
              <>
                <div
                  className={styles["auth__forms_container"]}
                  style={{ marginBottom: "24px" }}
                >
                  <Form.Group controlId="username">
                    <Form.Label>{t("login_page_username2")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder={t("login_page_username3")}
                      onBlur={handleUsernameBlur}
                      onChange={handleUsernameChange}
                    />
                    {isDisplayError ? (
                      <div
                        style={{
                          display: "block",
                          width: "100%",
                          marginTop: "0.25rem",
                          fontSize: "80%",
                          color: "#dc3545",
                        }}
                      >
                        {t("login_page_invalid")}
                      </div>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                </div>
                <Button
                  label={
                    isSubmitting
                      ? t("login_page_requesting")
                      : t("login_page_requesting2")
                  }
                  variant="primary"
                  size="large"
                  style={{
                    alignSelf: "stretch",
                    width: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                    marginTop: 20,
                    padding: 12,
                  }}
                  onClick={handleResetPassword}
                />
              </>
            )}
          </div>
        </Card>
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            username: "",
            password: "",
          }}
          className={styles["auth__formik"]}
        >
          {({ values, touched, errors, handleSubmit, handleChange }) => (
            <Card className={styles["auth__card"]}>
              <div className={styles["auth__login"]}>
                <h2>{t("login_page_header")} </h2>
                <span>
                  {t("login_page_lead")}{" "}
                  <Link
                    to="/sign-up"
                    className="mx-auto"
                    onClick={() => {
                      dispatch(
                        logActivity("GO TO", loginPage, undefined, signupPage)
                      );
                    }}
                  >
                    {t("login_page_lead2")}
                  </Link>
                </span>
                <div
                  className={styles["auth__socmed-buttons"]}
                  style={{ paddingTop: "24px" }}
                >
                  <GoogleLoginButton
                    label={t("login_page_google")}
                    page={loginPage}
                  />
                  <FacebookLoginButton
                    label={t("login_page_facebook")}
                    page={loginPage}
                  />
                </div>

                <div
                  className={styles["auth__divider-container"]}
                  style={{ marginTop: "24px", marginBottom: "4px" }}
                >
                  <div className={styles["auth__divider-or"]}>{t("or")}</div>
                </div>

                <Form noValidate onSubmit={handleSubmit}>
                  <div
                    className={styles["auth__forms_container"]}
                    style={{ paddingTop: "0px" }}
                  >
                    <Form.Group controlId="username">
                      <Form.Label>{t("login_page_username")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder={t("login_page_username")}
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={touched.username && !!errors.username}
                        autoComplete="username"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Label>{t("login_page_password")}</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder={t("login_page_password")}
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={touched.password && !!errors.password}
                        autoComplete="current-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <p className="text-danger"> {`${loginErrorMessage}`}</p>
                  <Button
                    label={
                      isSubmitting
                        ? t("login_page_status")
                        : t("login_page_status2")
                    }
                    variant="primary"
                    size="large"
                    isDisabled={isSubmitting}
                    style={{
                      alignSelf: "stretch",
                      width: "100%",
                      textAlign: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      padding: 12,
                    }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </Form>

                <div className={styles["auth__divider-container"]}></div>
                <div
                  style={{
                    marginTop: "24px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 16,
                    color: "#3185fc",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <div onClick={handleForgotPassword}>
                    {t("login_page_forgot")}
                  </div>
                </div>
              </div>
            </Card>
          )}
        </Formik>
      )}
    </>
  );
};
