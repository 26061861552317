import { State } from "../store";

export const getUserId = (state: State): number | undefined =>
  state.auth.userId;

export const getUserFirstName = (state: State): string | undefined =>
  state.auth.firstName;

export const getUserLastName = (state: State): string | undefined =>
  state.auth.lastName;

export const getUserWebsiteSlug = (state: State): string | undefined =>
  state.auth.websiteSlug;

export const getWebsiteUniqueId = (state: State): string | undefined =>
  state.auth.websiteUniqueId;

export const getGoogleClientId = (state: State): string | undefined =>
  state.auth.googleClientId;

export const getRedirectUri = (state: State): string | undefined =>
  state.auth.redirectUri;

export const getBaseUrl = (state: State): string | undefined =>
  state.auth.baseUrl;

export const getUsername = (state: State): string | undefined =>
  state.auth.username;

export const getUsernameValidity = (state: State): boolean | undefined =>
  state.auth.isUsernameValid;

export const getUserRole = (state: State): boolean | undefined =>
  state.auth.isSuper;

export const getIsPasswordUpdated = (state: State): boolean | undefined =>
  state.auth.isPasswordUpdated;

export const getIsUsernameUpdated = (state: State): boolean | undefined =>
  state.auth.isUsernameUpdated;

export const getUnsplashKey = (state: State): string | undefined =>
  state.auth.unsplashKey;

export const getUserProvider = (state: State): string | undefined =>
  state.auth.provider;

export const getIsUserEmailVerified = (state: State): boolean | undefined =>
  state.auth.isUserEmailVerified;

export const getUserCountryCode = (state: State): string | undefined =>
  state.auth.countryCode;

export const getUserCountry = (state: State): string | undefined =>
  state.auth.country;

export const getDLocalCountry = (state: State): string | undefined =>
  state.auth.dLocalcountry;

export const getDLocalCountryIsoLanguageCode = (
  state: State
): string | undefined => state.auth.dLocalcountryIsoLanguageCode;

export const getDLocalCountryCode = (state: State): string | undefined =>
  state.auth.dLocalcountryCode;

export const getDLocalCurrencyCode = (state: State): string | undefined =>
  state.auth.dLocalcurrencyCode;

export const getDLocalDocumentName = (state: State): string | undefined =>
  state.auth.dLocaldocumentName;

export const getDLocalDocumentFormat = (state: State): string | undefined =>
  state.auth.dLocaldocumentFormat;

export const getDLocalDocumentFormatMin = (state: State): number | undefined =>
  state.auth.dLocaldocumentFormatMin;

export const getDLocalDocumentFormatMax = (state: State): number | undefined =>
  state.auth.dLocaldocumentFormatMax;

export const getDLocalIsRequired = (state: State): boolean | undefined =>
  state.auth.dLocalisRequired;

export const getDLocalPrice = (state: State): number | undefined =>
  state.auth.dLocalprice;

export const getDLocalAnnualPrice = (state: State): number | undefined =>
  state.auth.dLocalAnnualPrice;

export const getDLocalApiKey = (state: State): string | undefined =>
  state.auth.dLocalApiKey;

export const getDLocalPaymentStatus = (state: State): string | undefined =>
  state.auth.dLocalPaymentStatus;

export const getDLocalPaymentStateMessage = (
  state: State
): string | undefined => state.auth.dLocalPaymentStateMessage;

export const getPaypalCountry = (state: State): string | undefined =>
  state.auth.paypalCountry;

export const getPaypalCountryIsoLanguageCode = (
  state: State
): string | undefined => state.auth.paypalCountryIsoLanguageCode;

export const getPaypalCountryCode = (state: State): string | undefined =>
  state.auth.paypalCountryCode;

export const getPaypalCurrencyCode = (state: State): string | undefined =>
  state.auth.paypalCurrencyCode;

export const getPaypalPrice = (state: State): number | undefined =>
  state.auth.paypalPrice;

export const getPaypalAnnualPrice = (state: State): number | undefined =>
  state.auth.paypalAnnualPrice;

export const getPaypalAnnualPlanId = (state: State): string | undefined =>
  state.auth.paypalAnnualPlanId;

export const getPaypalMonthlyPlanId = (state: State): string | undefined =>
  state.auth.paypalMonthlyPlanId;

export const getPaymentOptions = (state: State): string[] | undefined =>
  state.auth.availablePaymentMethod;

export const getPreferredPaymentOption = (state: State): string | undefined =>
  state.auth.preferredPaymentMethod;
