import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  PageResource,
  DeletePageUsingDELETERequest,
  FindPageListByWebsiteSlugUsingGETRequest,
} from "@urux/arm-of-dorne-api";
import { pageApi } from "../api";
import {
  FetchPagesRequestAction,
  fetchPagesSuccess,
  fetchPagesFailure,
  DeletePageRequestAction,
  deletePageSuccess,
  deletePageFailure,
} from "./pageActions";
import {
  startRequest,
  finishRequest,
  finishRequestWithErrors,
} from "../api/apiActions";
import { latestPageVersionListRequested } from "editor/states/latestPageVersionList";
import { clearChallengesFromDeletePageSuccessAction } from "editor/components/impl/Editor/editorActions";

export function* fetchPages(action: FetchPagesRequestAction) {
  yield put(startRequest());
  try {
    const response = yield call(fetchPagesAsync, action.websiteSlug);
    yield put(fetchPagesSuccess(response));
    yield put(finishRequest());
  } catch (e) {
    yield put(fetchPagesFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

export function* deletePage(action: DeletePageRequestAction) {
  yield put(startRequest());
  try {
    yield call(deletePageAsync, action.websiteSlug, action.pageSlug);
    yield put(deletePageSuccess());
    yield put(clearChallengesFromDeletePageSuccessAction(action.pageSlug));
    yield put(finishRequest());
    yield put(latestPageVersionListRequested(action.websiteSlug));
  } catch (e) {
    yield put(deletePageFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

const fetchPagesAsync = async (
  websiteSlug: string
): Promise<PageResource[]> => {
  const request: FindPageListByWebsiteSlugUsingGETRequest = {
    websiteSlug,
  };
  return await pageApi.findPageListByWebsiteSlugUsingGET(request);
};

const deletePageAsync = async (
  websiteSlug: string,
  pageSlug: string
): Promise<object> => {
  const request: DeletePageUsingDELETERequest = {
    websiteSlug,
    pageSlug,
  };
  return await pageApi.deletePageUsingDELETE(request);
};

const getResponseJsonAsync = async (response: Response): Promise<any> => {
  return await response.json();
};

export function* rootSaga() {
  yield takeLatest("ADMIN_FETCH_PAGES_REQUEST", fetchPages);
  yield takeLatest("ADMIN_DELETE_PAGE_REQUEST", deletePage);
}
