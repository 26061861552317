import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Facebook, Twitter } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { vinylChild1Template } from "./vinylChild1";
import { vinylChild2Template } from "./vinylChild2";
import { vinylChild3Template } from "./vinylChild3";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { HeadingElement } from "editor/elements/base/Heading";
import { LeadElement } from "editor/elements/base/Lead";
import { ArticleElement } from "editor/elements/components/Article";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { ImageElement } from "editor/elements/base/Image/Image";
import { ButtonElement } from "editor/elements/components/Button/Button";
import { CardsElement } from "editor/elements/sections/Cards/Cards";
import { CardsContainerElement } from "editor/elements/components/CardsContainer/CardsContainer";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const vinylTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "vinyl";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 4,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 5,
    templateTitle: templateKey,
  },
  {
    elementKey: "messenger",
    position: 6,
    templateTitle: templateKey,
  },
  {
    elementKey: "whatsapp",
    position: 7,
    templateTitle: templateKey,
  },
];

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page Vinyl",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: vinylChild1Template,
  },
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "vinylChild1Template",
    details: {
      title: "Catalog",
      slug: randomSlug(6),
      description: "Available Vinyls",
    },
    template: {
      template: vinylChild1Template,
    },
  },
  {
    templateName: "vinylChild2Template",
    details: {
      title: "About Us",
      slug: randomSlug(6),
      description: "About Us",
    },
    template: {
      template: vinylChild2Template,
    },
  },
  {
    templateName: "vinylChild3Template",
    details: {
      title: "Contact Us",
      slug: randomSlug(6),
      description: "Contact Us",
    },
    template: {
      template: vinylChild3Template,
    },
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultNavbar(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/vinyl/main.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        "1",
        new BackgroundColorProperty("#000000"),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "2",
        "2",
        "#FFFFFF"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/vinyl/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright HITS " + new Date().getFullYear()
      );

    default:
      return vinylTemplates[layoutKey];
  }
};

vinylTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "Vinyl",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

vinylTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "Makeup",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);

vinylTemplates["navbar"] = TemplatesFactory.createDefaultNavbar(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/vinyl/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "1",
  new BackgroundColorProperty("#000000"),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "2",
  "2",
  "#FFFFFF"
);

vinylTemplates["articles"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#000",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h1><span style="color: rgb(255, 255, 255);">New record by Vitorio: 95 Jodeci available now</span></h1>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div elementkey="lead" websiteslug="yelswebsite" class="element  hobaw-rte rte-left lead lead-style-1 fs cg-df-dg" style="visibility: visible; opacity: 1;"><div><div><div><div><br></div><div><span style="color: rgb(255, 255, 255);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt.</span></div><div><br></div></div></div></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1619622599449-3cdafdce4776?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1562&q=80",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

vinylTemplates["cards"] = LayoutEditor.createEmpty()
  .add(
    CardsElement.create({
      cardsStyle: "0",
      backgroundColorProperty: "#FFFFFF",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "parent",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(68, 0, 0);">New Stuff</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        CardsContainerElement.create({
          cardsStyle: "1",
          cardsOrientation: "1",
          cardsAlignment: "2",
        })
      )
      .addChildrenArray([
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Terror Terror</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>The Brooms</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1599689019338-50deb475f380?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWxidW0lMjBjb3ZlcnxlbnwwfDJ8MHxibGFja3w%3D&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>yes, robbed.</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Qesha</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1454789548928-9efd52dc4031?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YWxidW0lMjBjb3ZlcnxlbnwwfDJ8MHxibGFja3w%3D&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Now in Cleveland</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Jim Orton</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1531777992189-ad52457fbe93?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWxidW0lMjBjb3ZlcnxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Here's a Problem</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Hiding McClusky</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1582561879313-ea72c2743e68?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGFydHxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>Good Morning</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>Thea</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1645919268997-e8f6d5ee81e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGFsYnVtJTIwY292ZXJ8ZW58MHwyfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(`<h3>The Abyss</h3>`),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty("<div>F93</div>"),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1619535541830-af170e005ef5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fHJhcHxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
          "2"
        ),
      ])
      .build()
  )
  .build();

vinylTemplates["articles2"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#000000",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(255, 255, 255);">Our entire catalog</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><br /><span style="color: rgb(255, 255, 255);">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Inventore officia omnis aperiam aliquid provident cum quos quo sint nulla excepturi.</span><br /></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            ButtonElement.create({
              buttonStyle: new ButtonStyleProperty("hobaw-btn-primary"),
              buttonLink: new LinkProperty(
                "View Catalog",
                "",
                false,
                false,
                false
              ),
              treatmentProperty: new TreatmentProperty(false),
              isProduction: false,
              isButton: false,
              isTestable: false,
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1650853775006-451d7b78287d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

vinylTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("1"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty(
    `<h2><span style="color: rgb(68, 0, 0);">Contact Us</span></h2>`
  ),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Fill in your details below and we'll be in contact with you soon."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

vinylTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/vinyl/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright HITS " + new Date().getFullYear()
);
