import React from "react";
import { ElementComponentProps, AnyProperties } from "./types";

class ElementComponentRegistry {
  private readonly _registry: {
    [elementKey: string]: React.FunctionComponent<
      ElementComponentProps<AnyProperties>
    >;
  } = {};

  register<T extends ElementComponentProps<AnyProperties>>(
    elementKey: string,
    component: React.FunctionComponent<T>
  ): void {
    this._registry[elementKey] = component as React.FunctionComponent<
      ElementComponentProps<AnyProperties>
    >;
  }

  get(
    elementKey: string
  ): React.FunctionComponent<ElementComponentProps<AnyProperties>> {
    return this._registry[elementKey];
  }
}

export const elementComponentRegistry = new ElementComponentRegistry();
