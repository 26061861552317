import { ProgressBar } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { getS3config, getWebsite } from "editor/states/selectors";
import { getUserWebsiteSlug } from "auth/selectors";
import { uploadFile } from "admin/S3Client";
import html2canvas from "html2canvas";
import styles from "./LogoStyleProperty.module.scss";
import "./LogoStyleProperty.scss";
import {
  businessIcons,
  artIcons,
  sportIcons,
  fashionIcons,
  animalsIcons,
  techIcons,
  travelIcons,
  foodIcons,
  peopleIcons,
  othersIcons,
} from "common/elements/logoicons";

import { LogoDisplaySection } from "common/elements/LogoDisplaySection";
import { LogoImageProperty } from "../LogoImageProperty/LogoImageProperty";
import { updateWebsiteDetails } from "admin/websiteActions";
import { Button } from "editor/components/base/Button/Button";
import { urltoFile } from "../../../admin/image-utils";
import { useTranslation } from "react-i18next";

interface ToUpload {
  filename: string;
  file: File;
  dataUrl: string;
  isMain: boolean;
  isFooter: boolean;
}

export class LogoStyleProperty implements IProperty {
  constructor(
    readonly style: string,
    readonly url: string,
    readonly lastUpdateDate: string
  ) {}

  render(elementId: string, propertyKey: string) {
    return (
      <LogoStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    return new LogoStyleProperty(value.style, value.url, value.lastUpdateDate);
  }
}

const LogoStylePropertyComponent: PropertyComponent<LogoStyleProperty> = ({
  elementId,
  propertyKey,
  style,
  url,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const website = useSelector(getWebsite);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const s3ConfigState = useSelector(getS3config);
  const [logoText, setLogoText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [iconIdSelected, setIconIdSelected] = useState<string>();
  const [iconSelected, setIconSelected] = useState<any>();
  const [logoIdSelected, setLogoIdSelected] = useState<string>();
  const [isSelectLogo, setIsSelectLogo] = useState(false);
  const [useWhiteBg, setUseWhiteBg] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState<ToUpload[]>([]);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [uploadImagesReady, setUploadImagesReady] = useState(false);
  const [uploadImagesDone, setUploadImagesDone] = useState(false);
  const [mainLoc, setMainLoc] = useState("");
  const [footerLoc, setFooterLoc] = useState("");
  useEffect(() => {
    let input = document.getElementById("logo-text") as HTMLInputElement;
    if (input) {
      input.addEventListener("keypress", (e) => {
        let x = (e as unknown) as React.ChangeEvent<HTMLInputElement>;
        setInputValue(
          x.target.value && x.target.value !== "" ? x.target.value : "logo"
        );
      });
      input.addEventListener("change", (e) => {
        let x = (e as unknown) as React.ChangeEvent<HTMLInputElement>;
        setInputValue(
          x.target.value && x.target.value !== "" ? x.target.value : "logo"
        );
      });
      setLogoText(input.value && input.value !== "" ? input.value : "logo");
    }
  }, [inputValue]);

  useEffect(
    () => {
      if (displayOverlay) {
        setTimeout(() => {
          generateLogos();
        }, 500);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayOverlay]
  );

  useEffect(
    () => {
      if (uploadImagesReady && websiteSlug) {
        imagesToUpload.forEach(function (imageToUpload, idx, array) {
          let promise = uploadFile(
            imageToUpload.file,
            websiteSlug,
            imageToUpload.filename,
            s3ConfigState
          );
          promise.then((location) => {
            if (imageToUpload.isMain && location) {
              setMainLoc(location);
            } else if (imageToUpload.isFooter && location) {
              setFooterLoc(location);
            }
            if (idx + 1 === array.length) {
              setUploadImagesDone(true);
            }
          });
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadImagesReady]
  );

  useEffect(
    () => {
      if (uploadImagesDone && iconIdSelected && mainLoc) {
        dispatch(
          propertySet(
            elementId,
            "logoImage",
            new LogoImageProperty(undefined, new Date().toISOString())
          )
        );
        dispatch(
          propertySet(
            elementId,
            propertyKey,
            new LogoStyleProperty(
              iconIdSelected,
              mainLoc,
              new Date().toISOString()
            )
          )
        );
        if (
          // !website.logoCreated &&
          websiteSlug &&
          website.primaryFont &&
          website.secondaryFont &&
          website.primaryColor &&
          website.secondaryColor &&
          website.ternaryColor &&
          website.colorType &&
          website.primaryRadius &&
          website.secondaryRadius &&
          website.primaryShadow
        ) {
          dispatch(
            updateWebsiteDetails(
              websiteSlug,
              website.primaryFont,
              website.secondaryFont,
              website.primaryColor,
              website.secondaryColor,
              website.ternaryColor,
              website.colorType,
              website.primaryRadius,
              website.secondaryRadius,
              website.primaryShadow,
              true
            )
          );
        }
        setIsSelectLogo(false);
        setDisplayOverlay(false);
        setIconIdSelected(undefined);
        setIconSelected(undefined);
      }

      // Check if alternate logo container exists, update that too
      const altLogo = document.querySelector(
        "[elementkey='alternate-logo-container']"
      );
      if (altLogo) {
        const altLogoId = altLogo.getAttribute("id");
        if (altLogoId && footerLoc) {
          dispatch(
            propertySet(
              altLogoId,
              "logoImage",
              new LogoImageProperty(footerLoc, new Date().toISOString())
            )
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadImagesDone, mainLoc, footerLoc]
  );

  const generateLogos = () => {
    if (logoIdSelected) {
      // setIsLoadingImage(true);
      const toCapture = document.getElementById(logoIdSelected + "-main");
      if (toCapture) {
        html2canvas(toCapture, {
          backgroundColor: useWhiteBg
            ? "rgba(255, 255, 255)"
            : "rgba(0, 0, 0, 0)",
          removeContainer: true,
        })
          .then(function (canvas: HTMLCanvasElement) {
            let isMain = true;
            let isFooter = false;
            let filename = "main";
            urltoFile(canvas.toDataURL(), filename, "png", "image/png").then(
              function (file) {
                setImagesToUpload((imagesToUpload: any) => [
                  ...imagesToUpload,
                  {
                    filename,
                    file,
                    isMain,
                    isFooter,
                  },
                ]);
              }
            );
          })
          .then(function () {
            generateOtherAssets(logoIdSelected, "mono", false, false, true);
          })
          .then(function () {
            generateOtherAssets(logoIdSelected, "favicon", false, false, false);
          })
          .then(function () {
            generateOtherAssets(
              logoIdSelected,
              "favicon32",
              false,
              false,
              false
            );
          })
          .then(function () {
            generateOtherAssets(
              logoIdSelected,
              "favicon180",
              false,
              false,
              false
            );
          })
          .then(function () {
            generateOtherAssets(
              logoIdSelected,
              "favicon192",
              false,
              false,
              false
            );
          })
          .then(function () {
            generateOtherAssets(
              logoIdSelected,
              "favicon512",
              false,
              false,
              false
            );
          })
          .then(function () {
            generateOtherAssets(
              logoIdSelected,
              "og1200x627",
              true,
              false,
              false
            );
          });
      }
    }
  };

  const generateOtherAssets = (
    id: string,
    idProp: string,
    isFinal: boolean,
    isMain: boolean,
    isFooter: boolean
  ) => {
    if (id && idProp) {
      const toCapture = document.getElementById(id + "-" + idProp);
      if (toCapture) {
        html2canvas(toCapture, {
          backgroundColor:
            useWhiteBg && idProp !== "mono"
              ? "rgba(255, 255, 255)"
              : "rgba(0, 0, 0, 0)",
          removeContainer: true,
        }).then(function (canvas: HTMLCanvasElement) {
          let filename = idProp;
          // if (isFooter) {
          //   const altLogo = document.querySelector("[elementkey='alternate-logo-container']");
          //   if (altLogo) {
          //     filename = idProp + "-" + Math.random().toString(36).slice(-6)
          //   }
          // }
          urltoFile(canvas.toDataURL(), filename, "png", "image/png").then(
            function (file) {
              setImagesToUpload((imagesToUpload: any) => [
                ...imagesToUpload,
                {
                  filename,
                  file,
                  isMain,
                  isFooter,
                },
              ]);
              if (isFinal) {
                setUploadImagesReady(true);
              }
            }
          );
        });
      }
    }
  };

  const handleIconClick = (icon: any, id: string) => {
    setIconSelected(icon);
    setIconIdSelected(id);
  };

  const handleGenerateLogos = () => {
    // Display Overlay
    setDisplayOverlay(true);
    // setIsCreateLogo(true);
    const toHide = document.getElementById("logoText-dialog-container");
    if (toHide) {
      toHide.style.display = "none";
    }
  };

  return uploadImagesDone && mainLoc ? (
    <div className={styles["loader"]} style={{ margin: "unset" }}>
      <img src={mainLoc} alt="New logo" />
      {t("create_logo_5")}
    </div>
  ) : (
    <div
      className="form__group"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: isSelectLogo ? "-100px" : "0",
      }}
    >
      {isSelectLogo ? (
        <>
          <LogoDisplaySection
            styles={styles}
            logoIdSelected={logoIdSelected}
            selectedPrimaryColor={website.primaryColor}
            selectedSecondaryColor={website.secondaryColor}
            selectedTernaryColor={website.ternaryColor}
            iconSelected={iconSelected}
            logoText={logoText}
            websiteTitle={logoText}
            setLogoIdSelected={setLogoIdSelected}
            // setIsLogoSelected={setIsLogoSelected}
            setUseWhiteBg={setUseWhiteBg}
            // setPrimaryRadius={setPrimaryRadius}
            // setSecondaryRadius={setSecondaryRadius}
            // setPrimaryShadow={setPrimaryShadow}
            // setPrimaryFont={setPrimaryFont}
            // setSecondaryFont={setSecondaryFont}
          />
          {displayOverlay ? (
            <div className={styles["loader"]}>
              <div
                className={`bounce bounce-7`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="goodweb loader 1"
                  src="/img/goodweb-bouncing-logo-1.svg"
                />
              </div>
              {t("create_logo_3")}
              <span
                style={{
                  color: "#7a8794",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                {t("create_logo_4")}
              </span>
              <ProgressBar
                animated
                now={60}
                style={{
                  width: "300px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className={styles["logo-icon-select-container"]}>
          <span className={styles["logo-icon-select-title"]} id="business">
            {t("setup_page_39")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {businessIcons.map((businessIcon, idx) => (
              <div
                key={businessIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === businessIcon.id
                    ? styles["icon-selected"]
                    : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(businessIcon.icon, businessIcon.id);
                }}
              >
                {businessIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="arts">
            {t("setup_page_46")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {artIcons.map((artIcon, idx) => (
              <div
                key={artIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === artIcon.id ? styles["icon-selected"] : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(artIcon.icon, artIcon.id);
                }}
              >
                {artIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="sports">
            {t("setup_page_40")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {sportIcons.map((sportIcon, idx) => (
              <div
                key={sportIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === sportIcon.id ? styles["icon-selected"] : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(sportIcon.icon, sportIcon.id);
                }}
              >
                {sportIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="fashion">
            {t("setup_page_41")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {fashionIcons.map((fashionIcon, idx) => (
              <div
                key={fashionIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === fashionIcon.id
                    ? styles["icon-selected"]
                    : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(fashionIcon.icon, fashionIcon.id);
                }}
              >
                {fashionIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="animals">
            {t("setup_page_42")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {animalsIcons.map((animalsIcon, idx) => (
              <div
                key={animalsIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === animalsIcon.id
                    ? styles["icon-selected"]
                    : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(animalsIcon.icon, animalsIcon.id);
                }}
              >
                {animalsIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="tech">
            {t("setup_page_43")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {techIcons.map((techIcon, idx) => (
              <div
                key={techIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === techIcon.id ? styles["icon-selected"] : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(techIcon.icon, techIcon.id);
                }}
              >
                {techIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="travel">
            {t("setup_page_44")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {travelIcons.map((travelIcon, idx) => (
              <div
                key={travelIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === travelIcon.id
                    ? styles["icon-selected"]
                    : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(travelIcon.icon, travelIcon.id);
                }}
              >
                {travelIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="food">
            {t("setup_page_45")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {foodIcons.map((foodIcon, idx) => (
              <div
                key={foodIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === foodIcon.id ? styles["icon-selected"] : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(foodIcon.icon, foodIcon.id);
                }}
              >
                {foodIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="people">
            {t("setup_page_47")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {peopleIcons.map((peopleIcon, idx) => (
              <div
                key={peopleIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === peopleIcon.id
                    ? styles["icon-selected"]
                    : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(peopleIcon.icon, peopleIcon.id);
                }}
              >
                {peopleIcon.icon}
              </div>
            ))}
          </div>
          <span className={styles["logo-icon-select-title"]} id="others">
            {t("setup_page_48")}
          </span>
          <div className={styles["logo-icon-select-icons-container"]}>
            {othersIcons.map((othersIcon, idx) => (
              <div
                key={othersIcon.id}
                className={`${styles["logo-icon-container"]} ${
                  iconIdSelected === othersIcon.id
                    ? styles["icon-selected"]
                    : ""
                }`}
                onMouseDown={() => {
                  handleIconClick(othersIcon.icon, othersIcon.id);
                }}
              >
                {othersIcon.icon}
              </div>
            ))}
          </div>
        </div>
      )}
      {isSelectLogo ? (
        <div
          style={{
            display: displayOverlay ? "none" : "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              setIsSelectLogo(false);
            }}
            variant={"outline-primary"}
            isFlat={true}
            label={t("back")}
            style={{ color: "#fff", marginBottom: "unset" }}
          >
            {t("back")}
          </Button>
          <Button
            onClick={() => {
              handleGenerateLogos();
            }}
            variant={logoIdSelected ? "primary" : "disabled"}
            isFlat={true}
            isDisabled={logoIdSelected ? false : true}
            label={logoIdSelected ? t("create_logo_6") : t("create_logo_7")}
            style={{ color: "#fff", marginBottom: "unset" }}
          />
        </div>
      ) : (
        // <Button
        //   style={{ borderRadius: 32, maxWidth: "240px", margin: 10 }}
        //   disabled={iconSelected ? false : true}
        //   onClick={() => {
        //     setIsSelectLogo(true);
        //   }}
        // >
        //   {iconSelected ? <>Next</> : <>Select Icon</>}
        // </Button>
        <Button
          onClick={() => {
            setIsSelectLogo(true);
          }}
          variant={iconSelected ? "primary" : "disabled"}
          isFlat={true}
          isDisabled={iconSelected ? false : true}
          label={iconSelected ? t("next") : t("select_icon")}
          style={{ color: "#fff", marginBottom: "unset" }}
        />
      )}
      <div className="logos-container"></div>
    </div>
  );
};
