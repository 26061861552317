import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

export type ApiAction<A extends Action> = ThunkAction<void, void, void, A>;

export const dispatchStart = <A extends Action>(
  dispatch: ThunkDispatch<void, void, A>,
  action: A
) => {
  dispatch(action);
};

export const dispatchSuccess = <A extends Action>(
  dispatch: ThunkDispatch<void, void, A>,
  action: A
) => {
  dispatch(action);
};

export const dispatchFailure = <A extends Action>(
  dispatch: ThunkDispatch<void, void, A>,
  action: A,
  response?: Response
) => {
  dispatch(action);
};
