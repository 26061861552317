import {
  PageResource,
  FindPageBySlugUsingGETRequest,
} from "@urux/arm-of-dorne-api";
import { pageApi } from "api";
import { Page } from "public/models/types";

export const findPageBySlugAsync = async (
  websiteSlug: string,
  pageSlug: string
): Promise<Page> => {
  const request: FindPageBySlugUsingGETRequest = {
    websiteSlug,
    pageSlug,
  };

  return mapToPage(await pageApi.findPageBySlugUsingGET(request));
};

const mapToPage = (resource: PageResource): Page => {
  return {
    id: resource.pageId!,
    slug: resource.slug!,
    websiteSlug: resource.websiteSlug!,
    title: resource.title!,
    description: resource.description!,
    content: JSON.parse(resource.content!),
    isIndex: resource.isIndex!,
    primaryFont: resource.primaryFont!,
    secondaryFont: resource.secondaryFont!,
    primaryColor: resource.primaryColor!,
    secondaryColor: resource.secondaryColor!,
    ternaryColor: resource.ternaryColor!,
    primaryRadius: resource.primaryRadius!,
    secondaryRadius: resource.secondaryRadius!,
    primaryShadow: resource.primaryShadow!,
    isMaster: resource.isMaster!,
    masterSlug: resource.masterSlug!,
    template: resource.template!,
    pageUniqueId: resource.pageUniqueId!,
  };
};
