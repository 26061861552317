import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type ArticleProperties = {
  articleStyle: string;
};

export function Article({
  properties: { articleStyle },
  className,
  innerRef,
  children,
  ...props
}: ElementComponentProps<ArticleProperties>): JSX.Element {
  return (
    <article
      {...props}
      ref={innerRef}
      className={`${className} article article-style-${articleStyle} s-f p-c-d90`}
    >
      {children}
    </article>
  );
}

export const articleElementKey = "article";

elementComponentRegistry.register(articleElementKey, Article);
