import produce from "immer";

import { Layout } from "common/elements/types";

type ElementKeyWithProperties = {
  elementKey: string;
} & { [propertyKey: string]: any };

interface IEmptyLayoutEditor {
  add(element: ElementKeyWithProperties): ILayoutEditor;
}

interface ILayoutEditor extends IEmptyLayoutEditor {
  addChildren(children: Layout): ILayoutEditor;
  addChildrenArray(children: Layout[]): ILayoutEditor;
  build(): Layout;
}

export class LayoutEditor implements ILayoutEditor {
  private layout: Layout;
  private lastElementId: string;

  constructor() {
    this.layout = {};
    this.lastElementId = "";
  }

  add(element: ElementKeyWithProperties): ILayoutEditor {
    this.lastElementId = this.generateId();
    this.layout[this.lastElementId] = {
      ...element,
      elementId: this.lastElementId,
      elementKey: element.elementKey,
      elementParentId: undefined,
      elementChildrenId: [],
      templateKey: "default",
      isTestable: element.isTestable,
    };
    return this;
  }

  addChildrenArray(children: Layout[]): ILayoutEditor {
    children.forEach((child) => {
      this.addChildren(child);
    });

    return this;
  }

  addChildren(children: Layout): ILayoutEditor {
    const templateIdToId: { [_: string]: string } = {};
    for (const templateId in children) {
      const id = this.generateId();
      templateIdToId[templateId] = id;

      const parentTemplateId =
        children[templateId].elementParentId || this.lastElementId;
      const parentId = templateIdToId[parentTemplateId]
        ? templateIdToId[parentTemplateId]
        : parentTemplateId;
      const element = {
        ...children[templateId],
        elementId: id,
        elementParentId: parentId,
        elementChildrenId: [],
      };

      this.layout[id] = element;
      this.layout[parentId].elementChildrenId!.push(id);
      /* if (parentId === this.lastElementId) {
       *   this.template[parentId].elementChildrenId!.splice(index, 0, id);
       * } else {
       *   this.template[parentId].elementChildrenId!.push(id);
       * } */
    }
    return this;
  }

  build(): Layout {
    return produce(this.layout, () => {});
  }

  private generateId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  static createEmpty(): IEmptyLayoutEditor {
    return new LayoutEditor();
  }
}
