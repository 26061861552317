import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type ContactProperties = {
  contactStyle: string;
  width?: number;
  backgroundColorProperty: string;
  backgroundImageProperty: {
    size: string;
    repeat: string;
    backgroundImageUrl: string;
    positionX: string;
    positionY: string;
  };
};

export function Contact({
  properties: {
    contactStyle,
    width,
    backgroundColorProperty,
    backgroundImageProperty,
  },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<ContactProperties>): JSX.Element {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: backgroundColorProperty,
        backgroundSize:
          backgroundImageProperty.size === "100%"
            ? "cover"
            : backgroundImageProperty.size,
        backgroundRepeat: backgroundImageProperty?.repeat,
        backgroundImage: backgroundImageProperty?.backgroundImageUrl
          ? `url(${backgroundImageProperty?.backgroundImageUrl})`
          : "unset",
        backgroundPositionX: backgroundImageProperty?.positionX,
        backgroundPositionY: backgroundImageProperty?.positionY,
      }}
    >
      <section
        {...props}
        ref={innerRef}
        className={
          className +
          "container jq-contact-form contact-style-" +
          contactStyle +
          " section-size-" +
          width
        }
      >
        {children}
      </section>
    </div>
  );
}

export const contactElementKey = "contact";

elementComponentRegistry.register(contactElementKey, Contact);
