import React from "react";

import { SocialNavItem } from "common/elements";
import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";

export type SocialNavProperties = {
  socialLinks: SocialLinkProperty[];
  style: string;
  isProduction: boolean;
  linkColor?: string;
};

export function SocialNav({
  properties: { socialLinks, style, isProduction, linkColor },
  className,
  innerRef,
  ...props
}: ElementComponentProps<SocialNavProperties>): JSX.Element {
  return (
    <div className={`social-nav style-${style}`}>
      <nav {...props} ref={innerRef} className={`${className}`}>
        {socialLinks ? (
          socialLinks.map((link: SocialLinkProperty, index: number) => (
            <SocialNavItem
              key={index}
              id={link.socialMedia.key}
              label={link.socialMedia.label}
              icon={link.socialMedia.icon}
              url={link.url}
              openNewTab={link.openNewTab}
              style={style}
              isProduction={isProduction}
              linkColor={linkColor}
            />
          ))
        ) : (
          <></>
        )}
      </nav>
    </div>
  );
}

export const navElementKey = "social-nav";

elementComponentRegistry.register(navElementKey, SocialNav);
