import { call, put, takeLatest } from "@redux-saga/core/effects";
import produce from "immer";
import { Reducer } from "redux";

import {
  startRequest,
  finishRequest,
  finishRequestWithErrors,
} from "api/apiActions";
import { findLatestPageVersionListByWebsiteSlugAsync } from "editor/api/pageVersion";
import { PageVersion } from "editor/models/types";
import { getResponseJsonAsync } from "editor/api/common";

export type State = {
  pageVersionList: { [pageId: number]: PageVersion };
};

export const EDITOR_LATEST_PAGE_VERSION_LIST_REQUESTED =
  "EDITOR_LATEST_PAGE_VERSION_LIST_REQUESTED";

type LatestPageVersionListRequestedAction = {
  type: typeof EDITOR_LATEST_PAGE_VERSION_LIST_REQUESTED;
  websiteSlug: string;
};

export const latestPageVersionListRequested = (
  websiteSlug: string
): LatestPageVersionListRequestedAction => {
  return {
    type: EDITOR_LATEST_PAGE_VERSION_LIST_REQUESTED,
    websiteSlug,
  };
};

export const EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_SUCCEEDED =
  "EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_SUCCEEDED";

type LatestPageVersionListRequestSucceededAction = {
  type: typeof EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_SUCCEEDED;
  pageVersionList: PageVersion[];
};

const latestPageVersionListRequestSucceeded = (
  pageVersionList: PageVersion[]
): LatestPageVersionListRequestSucceededAction => {
  return {
    type: EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_SUCCEEDED,
    pageVersionList,
  };
};

export const EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_FAILED =
  "EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_FAILED";

type LatestPageVersionListRequestFailedAction = {
  type: typeof EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_FAILED;
};

const latestPageVersionListRequestFailed = (): LatestPageVersionListRequestFailedAction => {
  return {
    type: EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_FAILED,
  };
};

export const EDITOR_LATEST_PAGE_VERSION_CREATED =
  "EDITOR_LATEST_PAGE_VERSION_CREATED";

type LatestPageVersionCreatedAction = {
  type: typeof EDITOR_LATEST_PAGE_VERSION_CREATED;
  pageVersion: PageVersion;
};

export const latestPageVersionCreated = (pageVersion: PageVersion): Action => {
  return {
    type: EDITOR_LATEST_PAGE_VERSION_CREATED,
    pageVersion,
  };
};

type Action =
  | LatestPageVersionListRequestedAction
  | LatestPageVersionListRequestSucceededAction
  | LatestPageVersionListRequestFailedAction
  | LatestPageVersionCreatedAction;

const initialState: State = {
  pageVersionList: [],
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case EDITOR_LATEST_PAGE_VERSION_LIST_REQUEST_SUCCEEDED:
      return handleLatestPageVersionListRequestSucceeded(state, action);

    case EDITOR_LATEST_PAGE_VERSION_CREATED:
      return handleLatestPageVersionCreated(state, action);

    default:
      return state;
  }
};

const handleLatestPageVersionListRequestSucceeded = (
  state: State,
  action: LatestPageVersionListRequestSucceededAction
): State =>
  produce(state, (draft) => {
    draft.pageVersionList = action.pageVersionList.reduce(
      (acc, pageVersion) => ({ ...acc, [pageVersion.pageId]: pageVersion }),
      {}
    );
  });

const handleLatestPageVersionCreated = (
  state: State,
  action: LatestPageVersionCreatedAction
): State =>
  produce(state, (draft) => {
    draft.pageVersionList[action.pageVersion.pageId] = action.pageVersion;
  });

function* loadLatestPageVersionList(
  action: LatestPageVersionListRequestedAction
) {
  yield put(startRequest());
  try {
    const latestPageVersionList: PageVersion[] = yield call(
      findLatestPageVersionListByWebsiteSlugAsync,
      action.websiteSlug
    );
    yield put(latestPageVersionListRequestSucceeded(latestPageVersionList));
    yield put(finishRequest());
  } catch (e) {
    yield put(latestPageVersionListRequestFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

export function* rootSaga() {
  yield takeLatest(
    EDITOR_LATEST_PAGE_VERSION_LIST_REQUESTED,
    loadLatestPageVersionList
  );
}
