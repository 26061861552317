import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "auth/S3Config";
import { S3Config } from "editor/models/types";
import { ListObjectsOutput } from "aws-sdk/clients/s3";

export const uploadFile = async (
  file: File,
  dir: string,
  filename: string,
  s3ConfigState: S3Config
) => {
  if (s3ConfigState && validateS3Config(s3ConfigState)) {
    if (s3ConfigState.accessKeyId)
      s3Config.accessKeyId = s3ConfigState.accessKeyId;

    if (s3ConfigState.secretAccessKey)
      s3Config.secretAccessKey = s3ConfigState.secretAccessKey;

    s3Config.dirName = "sites";
    s3Config.dirName = s3Config.dirName + "/" + dir;
    const s3 = new ReactS3Client(s3Config);

    try {
      const res = await s3.uploadFile(file, filename);
      return res.location;
    } catch (exception) {
      console.log(exception);
    }
  }
};

export const updateDirWithNewDomain = async (
  oldDomain: string,
  newDomain: string,
  s3ConfigState: S3Config
) => {
  if (s3ConfigState && validateS3Config(s3ConfigState)) {
    if (s3ConfigState.accessKeyId)
      s3Config.accessKeyId = s3ConfigState.accessKeyId;

    if (s3ConfigState.secretAccessKey)
      s3Config.secretAccessKey = s3ConfigState.secretAccessKey;

    s3Config.dirName = "sites";
    s3Config.dirName = s3Config.dirName + "/" + oldDomain;
    const s3 = new ReactS3Client(s3Config);
    const res = await s3.listFiles();

    uploadFiles(res.data, s3Config.dirName, s3ConfigState, newDomain);
  }
};

const uploadFiles = (
  listFileResponse: ListObjectsOutput,
  baseDirName: string,
  s3ConfigState: S3Config,
  newDomain: string
) => {
  if (listFileResponse && listFileResponse.Contents) {
    listFileResponse.Contents.forEach((element) => {
      if (element.Key && element.Key.startsWith(baseDirName)) {
        let fileRes = blobUrlToFile(
          "https://s3.amazonaws.com/img.goodweb.app/" + element.Key,
          element.Key
        );
        fileRes.then((file) => {
          if (s3ConfigState && validateS3Config(s3ConfigState)) {
            if (s3ConfigState.accessKeyId)
              s3Config.accessKeyId = s3ConfigState.accessKeyId;

            if (s3ConfigState.secretAccessKey)
              s3Config.secretAccessKey = s3ConfigState.secretAccessKey;

            s3Config.dirName = "sites/" + newDomain;
            const s3 = new ReactS3Client(s3Config);
            try {
              s3.uploadFile(file, element.Key?.replace(/^.*[\\\/]/, ""));
            } catch (exception) {
              console.log(exception);
            }
          }
        });
      }
    });
  }
};

const blobUrlToFile = (blobUrl: string, filename: string): Promise<File> =>
  new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        // please change the file.extension with something more meaningful
        // or create a utility function to parse from URL
        const file = new File([blob], filename, { type: blob.type });
        resolve(file);
      });
    });
  });

const validateS3Config = (s3Config: S3Config) => {
  if (s3Config.accessKeyId === "" || s3Config.secretAccessKey === "") {
    return false;
  }

  return true;
};
