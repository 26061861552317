import React from "react";
import { IProperty } from "../types";

export class ImageProperty implements IProperty {
  constructor(
    readonly imageStyle: string,
    readonly imageText: string,
    readonly imgUrl: string,
    readonly dimension: { width?: number; height?: number },
    readonly thumbUrl: string,
    readonly id: string
  ) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return <></>;
  }

  static parse(value: any): ImageProperty {
    return new ImageProperty(
      value.imageStyle,
      value.imageText,
      value.imageUrl,
      value.dimension,
      value.thumburl,
      value.id
    );
  }
}
