import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type ButtonProperties = {
  isButton: boolean;
  isProduction: boolean;
  buttonStyle: { style: string };
  buttonLink: {
    text: string;
    url: string;
    isExternal: boolean;
    openNewTab?: boolean;
    isAnchor?: boolean;
  };
  treatmentProperty: {
    isTreatment: boolean;
    treatments: [
      {
        isControl: boolean;
        isCurrentTreatment: boolean;
        hasTested: boolean;
        property: string;
      }
    ];
  };
  isTestable: boolean;
  testableElementStyle?: string;
  color?: {
    color: string;
    fontColor: string;
  };
};

export function Button({
  properties: {
    buttonStyle,
    buttonLink,
    treatmentProperty,
    isProduction,
    isButton,
    color,
  },
  className,
  ...props
}: ElementComponentProps<ButtonProperties>): JSX.Element {
  let linkUrl = "#" + props.id + "x"; // For Editor

  if (isProduction) {
    linkUrl = buttonLink.url;
    if (linkUrl && !linkUrl.startsWith("#") && !linkUrl.startsWith("/")) {
      if (linkUrl.includes("http")) {
        linkUrl = buttonLink.url;
      } else {
        linkUrl = "http://" + buttonLink.url;
      }
    }
  }

  let style: React.CSSProperties = {};
  if (color) {
    if (buttonStyle.style === "hobaw-btn-primary") {
      style = {
        backgroundColor: color?.color,
        borderWidth: "0px",
        color: color?.fontColor,
      };
    } else {
      style = {
        borderWidth: "2px",
        borderColor: color?.color,
        color: color?.color,
      };
    }
  }

  let classStyle = "";
  switch (buttonStyle.style) {
    case "hobaw-btn-primary":
      classStyle =
        "fp ce hobaw-btn cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov";
      break;
    case "hobaw-btn-outline-primary":
      classStyle =
        "fp ce hobaw-btn cp cp--brc brc2 brc2--hov cp-d10--bgc--hov cp-d10--bgc-rt-bw--hov cp-d10--brc--hov rs p-c-bo bgc-unset";
      break;
    default:
      classStyle = buttonStyle.style;
  }

  if (isButton) {
    return (
      <button
        {...props}
        className={`${className} ${classStyle}`}
        style={style ? style : undefined}
        type="button"
      >
        {buttonLink.text}
      </button>
    );
  }

  if (linkUrl) {
    return (
      <a
        {...props}
        className={`${className} ce hobaw-btn ${
          buttonStyle.style === "hobaw-btn-primary"
            ? `fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov`
            : `fp cp cp--brc brc2 brc2--hov cp-d10--bgc--hov cp-d10--bgc-rt-bw--hov cp-d10--brc--hov rs p-c-bo bgc-unset`
        }`}
        href={linkUrl}
        target={buttonLink.openNewTab ? "_blank" : "_self"}
        style={style ? style : undefined}
      >
        {buttonLink.text}
      </a>
    );
  } else {
    return (
      <a
        {...props}
        className={`${className} ce pf hobaw-btn ${
          buttonStyle.style === "hobaw-btn-primary"
            ? `fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov`
            : `fp cp cp--brc brc2 brc2--hov cp-d10--bgc--hov cp-d10--bgc-rt-bw--hov cp-d10--brc--hov rs p-c-bo bgc-unset`
        }`}
        target={buttonLink.openNewTab ? "_blank" : "_self"}
        href={"#" + props.id}
        style={style ? style : undefined}
      >
        {buttonLink.text}
      </a>
    );
  }
}

export const buttonElementKey = "button";

elementComponentRegistry.register(buttonElementKey, Button);
