import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Facebook, Twitter } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { looseChild1Template } from "./looseChild1";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const looseTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "loose";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 4,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 5,
    templateTitle: templateKey,
  },
  {
    elementKey: "messenger",
    position: 6,
    templateTitle: templateKey,
  },
  {
    elementKey: "whatsapp",
    position: 7,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultNavbar(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/loose/main.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        "1",
        new BackgroundColorProperty("#ffffff"),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "2",
        "2"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "/img/logo-default.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright DRSS " + new Date().getFullYear()
      );

    default:
      return looseTemplates[layoutKey];
  }
};

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page Fashion",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: looseChild1Template,
  },
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "looseChild1Template",
    details: {
      title: "About Us",
      slug: randomSlug(6),
      description: "About Us",
    },
    template: {
      template: looseChild1Template,
    },
  },
];

looseTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "Fashion",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

looseTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "Illustrations",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);

looseTemplates["navbar"] = TemplatesFactory.createDefaultNavbar(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/loose/main.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "1",
  new BackgroundColorProperty("#ffffff"),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "2",
  "2"
);

looseTemplates["cover"] = TemplatesFactory.createDefaultCover(
  "1",
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Book an Appointment", "", false, false, false),
  new RichTextProperty(
    '<h1><span style="color: rgb(255, 241, 243);">You can have anything <br></span></h1><h1><span style="color: rgb(255, 241, 243);">you want in life <br></span></h1><h1><span style="color: rgb(255, 241, 243);">if you dress for it. <br></span></span></h1><h1><span style="color: rgb(255, 241, 243);">—Edith Head</span></h1>'
  ),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://images.unsplash.com/photo-1543756779-ea44ce4f4e64?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1968&q=80",
    "no-repeat",
    "50%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(
    '<div><br></div><div><br></div><div><span style="color: rgb(255, 241, 243);">You gotta have style. <br></span></div><div><span style="color: rgb(255, 241, 243);">It helps you get down the stairs. <br></span></div><div><span style="color: rgb(255, 241, 243);">It helps you get up in the morning. <br></span></div><div><span style="color: rgb(255, 241, 243);">It’s a way of life. <br></span></div><div><span style="color: rgb(255, 241, 243);">Without it, you’re nobody. <br></span></div><div><span style="color: rgb(255, 241, 243);">I’m not talking about lots of clothes.</span></div><div><br></div>'
  ),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 90)
);

looseTemplates[
  "cards"
] = TemplatesFactory.createDefaultCards(
  new LeadStyleProperty("1"),
  new RichTextProperty("<h2>Why We Are & Who We Are</h2>"),
  new RTEAlignmentProperty("center"),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit"
  ),
  new RTEAlignmentProperty("center"),
  "2",
  [
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Sourced Locally</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Read More", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Locally sourced typically means produce and ingredients that are grown within miles of where you shop.</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1569728808392-4c637feee42b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      "2"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Curated by Eve</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("About Eve", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">The pieces I chose were based on one thing only — a gasp of delight. Isn’t that the only way to curate a life?</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1612480797665-c96d261eae09?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
      "2"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Eco-Friendly</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Learn More", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Refers to products that contribute to green living or practices that help conserve resources like water and energy.</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1631089695391-17126af7905a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
      "2"
    ),
  ]
);

looseTemplates["articles"] = TemplatesFactory.createDefaultArticles(
  new LeadStyleProperty("2"),
  new RichTextProperty("<h2>Lorem ipsum dolor sit amet.</h2>"),
  new RTEAlignmentProperty("parent"),
  new ImageStyleProperty("1"),
  new ButtonStyleProperty("hobaw-btn-outline-primary"),
  new LinkProperty("Read More", "", false, false, false),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "<div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor. Etiam sit amet fringilla lorem, vitae dapibus velit. Donec vitae enim gravida, sodales massa ut, dictum ante. Morbi risus ligula, venenatis eget porttitor eu, facilisis at sem. Phasellus et gravida ipsum, non tincidunt ante. Nunc rhoncus libero vitae feugiat condimentum. Maecenas at metus id mauris ultrices hendrerit vitae at elit. Duis laoreet, mi et convallis fermentum, purus sem volutpat nulla, id vulputate risus dui a augue.<div /><div>Donec maximus commodo maximus. In orci nisi, accumsan non tempus feugiat, imperdiet at tortor. Cras tempus suscipit quam, vitae mattis urna. Praesent non tristique turpis. Mauris in odio vulputate, pulvinar dui eu, pretium augue. Quisque viverra tempor commodo. Maecenas faucibus, leo sit amet pharetra consectetur, velit urna elementum ante, sed tincidunt tellus sem id metus. Etiam vitae diam turpis. Suspendisse eu diam in nisl interdum luctus. Sed quis convallis augue.</div>"
  ),
  new RTEAlignmentProperty("parent"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor."
  ),
  new RTEAlignmentProperty("parent"),
  "2",
  "1",
  "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  "2"
);

looseTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("1"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Book an Appointment</h2>"),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Fill in your details below and we'll be in contact with you soon."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

looseTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/loose/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright DRSS " + new Date().getFullYear()
);
