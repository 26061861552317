import React, { useState } from "react";
import { IProperty, PropertyComponent } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { propertySet, updateChallengeVariants } from "editor/states/layout";

import "./LinkProperty.scss";
import { LinkPropertyEditor } from "editor/components/impl/LinkPropertyEditor/LinkPropertyEditor";
import { getPageSlug } from "editor/selectors";
import { State } from "store";
import { getChallenge } from "editor/states/selectors";

import validator from "validator";

export class LinkProperty implements IProperty {
  constructor(
    readonly text: string,
    readonly url: string,
    readonly isAnchor: boolean,
    readonly isExternalLink: boolean,
    readonly openNewTab?: boolean,
    readonly isTreatment?: boolean
  ) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <LinkPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): LinkProperty {
    return new LinkProperty(
      value.text,
      value.url,
      value.isAnchor,
      value.isExternalLink,
      value.openNewTab,
      value.isTreatment
    );
  }
}

const LinkPropertyComponent: PropertyComponent<LinkProperty> = ({
  elementId,
  propertyKey,
  text,
  url,
  openNewTab,
  isAnchor,
  isExternalLink,
  isTreatment,
}) => {
  const dispatch = useDispatch();
  const pageSlug = useSelector(getPageSlug);
  const challenge = useSelector((state: State) =>
    getChallenge(state, pageSlug!)
  );
  const [selectedUrl, setSelectedUrl] = useState(url);
  const [urlInvalid, setUrlInvalid] = useState(true);
  const [isExternal, setIsExternal] = useState(isExternalLink);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new LinkProperty(e.target.value, url, isAnchor, isExternal, openNewTab)
      )
    );
    if (challenge) {
      if (challenge.elementId === elementId) {
        dispatch(updateChallengeVariants(pageSlug!));
      } else if (!challenge.elementId && isTreatment) {
        dispatch(updateChallengeVariants(pageSlug!));
      }
    }
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsExternal(true);
    setSelectedUrl(e.target.value);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new LinkProperty(text, e.target.value, false, true, openNewTab)
      )
    );
  };

  const handleUrlChangeSave = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsExternal(true);
    setSelectedUrl(e.target.value);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new LinkProperty(text, e.target.value, false, true, openNewTab)
      )
    );

    if (validator.isURL(e.target.value)) {
      setUrlInvalid(true);
    } else {
      setUrlInvalid(false);
    }
  };

  const handlePageSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsExternal(false);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new LinkProperty(text, "/" + String(e), false, false, openNewTab)
      )
    );
    setSelectedUrl("/" + String(e));
  };

  const handleSectionSelect = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIsExternal(false);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new LinkProperty(text, "#" + String(e), true, false, openNewTab)
      )
    );
    setSelectedUrl("#" + String(e));
  };

  const handleOpenNewTabChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new LinkProperty(
          text,
          selectedUrl,
          isAnchor,
          isExternalLink,
          e.target.checked
        )
      )
    );
  };

  return (
    <LinkPropertyEditor
      text={text}
      url={url}
      isAnchor={isAnchor}
      isExternalLink={isExternalLink}
      openNewTab={openNewTab}
      handleTextChange={handleTextChange}
      handlePageSelect={handlePageSelect}
      handleSectionSelect={handleSectionSelect}
      handleUrlChange={handleUrlChange}
      handleUrlChangeSave={handleUrlChangeSave}
      handleOpenNewTabChange={handleOpenNewTabChange}
      selectedUrl={selectedUrl}
      elementId={elementId}
      urlInvalid={urlInvalid}
    />
  );
};
