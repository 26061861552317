import { CloseRounded, SaveRounded } from "@material-ui/icons";
import { Button } from "editor/components/base/Button/Button";
import { propertySet } from "editor/states/layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRtTempText } from "../Editor/editorActions";
import style from "./RichTextHelper.module.scss";
import { RichTextProperty } from "editor/properties";

import { useTranslation } from "react-i18next";

type Props = {
  handleDisplayLinkHelper: () => void;
  tempLinkAttr:
    | {
        tempId: string;
        top: number;
        left: number;
        selectedId: string;
        rtTempText: string;
      }
    | null
    | undefined;
};

export default function LinkHelper({
  handleDisplayLinkHelper,
  tempLinkAttr,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [linkUrl, setLinkUrl] = useState("");

  useEffect(() => {
    if (tempLinkAttr) {
      const link = document.getElementById(tempLinkAttr.tempId);
      if (link) {
        const url = link.getAttribute("href");
        if (url !== undefined && url !== null && url !== "#") {
          setLinkUrl(url);
        }
      }
    }
  }, [tempLinkAttr]);

  if (!tempLinkAttr || tempLinkAttr?.tempId.startsWith("col")) return <></>;

  const handleChange = (e: any) => {
    setLinkUrl(e.target.value);
  };

  const handleSave = () => {
    var link = encodeURI(linkUrl);
    if (link.indexOf("http://") === -1 && link.indexOf("https://") === -1) {
      link = "http://" + link;
    }
    var template = document.createElement("div");
    var html = tempLinkAttr.rtTempText;
    template.innerHTML = html;
    template
      .querySelector(`#${tempLinkAttr.tempId}`)
      ?.setAttribute("href", link);
    dispatch(
      propertySet(
        tempLinkAttr.selectedId,
        "richTextProperty",
        new RichTextProperty(template.innerHTML)
      )
    );
    dispatch(setRtTempText(tempLinkAttr.selectedId, template.innerHTML));
    handleClose();
  };

  const handleClose = () => {
    var link = encodeURI(linkUrl);
    if (!linkUrl || linkUrl === "") {
      link = "#";
      var template = document.createElement("div");
      var html = tempLinkAttr.rtTempText;
      template.innerHTML = html;
      template
        .querySelector(`#${tempLinkAttr.tempId}`)
        ?.setAttribute("href", link);
      dispatch(
        propertySet(
          tempLinkAttr.selectedId,
          "richTextProperty",
          new RichTextProperty(template.innerHTML)
        )
      );
      dispatch(setRtTempText(tempLinkAttr.selectedId, template.innerHTML));
    }

    setLinkUrl("");
    handleDisplayLinkHelper();
  };

  const handleCloseOutside = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    handleClose();
  };

  return (
    <>
      <div
        className={`${style["richTextHelper-cover"]}`}
        onMouseDown={(e) => {
          handleCloseOutside(e);
        }}
      ></div>
      <div
        className={`${style["richTextHelper-dialog"]}`}
        style={{
          left: tempLinkAttr.left,
          top: tempLinkAttr.top,
        }}
      >
        <div className={style["richTextHelper-inputgroup"]}>
          <input
            id="rteHelper-input"
            type="text"
            placeholder={t("link_helper_1")}
            onChange={handleChange}
            value={linkUrl}
          />
          <Button
            icon={SaveRounded}
            variant={"primary"}
            size={"extra-small"}
            onClick={handleSave}
          />
          <Button
            icon={CloseRounded}
            variant={"outline-primary"}
            size={"extra-small"}
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}
