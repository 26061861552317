import "./../css/hobaw-main.module.scss";

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { StaticPage } from "public/StaticPage";
import { getPage } from "./states/selectors";
import { getWebsite } from "../editor/states/selectors";
import { pageRequested } from "./states/page";

type Props = {
  websiteSlug: string;
};

interface ParamTypes {
  pageSlug: string;
}

export const Page: React.FunctionComponent<Props> = ({ websiteSlug }) => {
  const { pageSlug } = useParams<ParamTypes>();
  const page = useSelector(getPage);
  const website = useSelector(getWebsite);
  const dispatch = useDispatch();

  useEffect(() => {
    if (websiteSlug && pageSlug) {
      dispatch(pageRequested(websiteSlug, pageSlug));
    }
  }, [dispatch, websiteSlug, pageSlug]);

  return <StaticPage page={page} website={website} />;
};
