import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type FooterProperties = {
  footerStyle: string;
  width: number;
  backgroundColorProperty: string;
  backgroundImageProperty: {
    size: string;
    repeat: string;
    backgroundImageUrl: string;
    positionX: string;
    positionY: string;
  };
  dimension: { width?: number; height?: number };
  borderProperty: {
    hasBorder?: boolean;
    color?: string;
    thickness?: number;
    shadow?: number;
  };
};

export function Footer({
  properties: {
    footerStyle,
    width,
    backgroundColorProperty,
    backgroundImageProperty,
    dimension,
    borderProperty,
  },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<FooterProperties>): JSX.Element {
  return (
    <>
      <div id="ad2" className="ad-container">
        <a
          rel="noopener noreferrer"
          href="https://www.trygoodweb.com?utm_source=freemium"
          target="_blank"
          id="ad2-link"
        >
          <div></div>
        </a>
      </div>
      <div
        className={`container-fluid p-c-b`}
        style={{
          backgroundColor: backgroundColorProperty,
        }}
      >
        <footer
          {...props}
          ref={innerRef}
          className={`${className} jq-footer section-size-${width} p-c-b`}
          style={{
            backgroundSize:
              backgroundImageProperty.size === "100%"
                ? "cover"
                : backgroundImageProperty.size,
            backgroundRepeat: backgroundImageProperty.repeat,
            backgroundImage: backgroundImageProperty.backgroundImageUrl
              ? `url(${backgroundImageProperty.backgroundImageUrl})`
              : `none`,
            backgroundPositionX: backgroundImageProperty.positionX,
            backgroundPositionY: backgroundImageProperty.positionY,
            backgroundColor: backgroundColorProperty
              ? backgroundColorProperty
              : "inherit",
            width: dimension && dimension.width ? dimension.width : "auto",
            height: dimension && dimension.height ? dimension.height : "auto",
            borderTop:
              borderProperty && borderProperty.hasBorder
                ? `solid ${borderProperty.thickness}px ${borderProperty.color}`
                : "none",
            boxShadow:
              borderProperty && borderProperty.hasBorder
                ? `0px 0px 10px 0px rgba(0,0,0, ${borderProperty.shadow});`
                : "none",
            position:
              borderProperty && borderProperty.hasBorder ? "relative" : "unset",
          }}
        >
          <div className={"jq-footer-guide footer-style-" + footerStyle}>
            {children}
          </div>
        </footer>
      </div>
    </>
  );
}

export const footerElementKey = "footer";

elementComponentRegistry.register(footerElementKey, Footer);
