import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type CardsProperties = {
  cardsStyle: string;
  width: number;
  backgroundColorProperty: string;
  backgroundImageProperty: {
    size: string;
    repeat: string;
    backgroundImageUrl: string;
    positionX: string;
    positionY: string;
  };
};

export function Cards({
  properties: {
    cardsStyle,
    width,
    backgroundColorProperty,
    backgroundImageProperty,
  },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<CardsProperties>): JSX.Element {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: backgroundColorProperty,
        backgroundSize:
          backgroundImageProperty.size === "100%"
            ? "cover"
            : backgroundImageProperty.size,
        backgroundRepeat: backgroundImageProperty?.repeat,
        backgroundImage: backgroundImageProperty?.backgroundImageUrl
          ? `url(${backgroundImageProperty?.backgroundImageUrl})`
          : "unset",
        backgroundPositionX: backgroundImageProperty?.positionX,
        backgroundPositionY: backgroundImageProperty?.positionY,
      }}
    >
      <section
        {...props}
        ref={innerRef}
        className={className + " container jq-cards section-size-" + width}
      >
        {children}
      </section>
    </div>
  );
}

export const cardsElementKey = "cards";

elementComponentRegistry.register(cardsElementKey, Cards);
