import React, { useState } from "react";
import { IProperty, PropertyComponent } from "../types";
import { Form } from "react-bootstrap";
import TextFieldGroup from "../../components/components/TextFieldGroup/TextFieldGroup";
import { ItemProps, Dropdown } from "editor/components/base/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { propertySet } from "editor/states/layout";

import styles from "./FormConfigurationProperty.module.scss";
import { Formik } from "formik";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";
import { trackFakeDoor } from "editor/components/impl/Editor/editorActions";
import { getPageSlug } from "editor/selectors";

import { useTranslation } from "react-i18next";

export class FormConfigurationProperty implements IProperty {
  constructor(readonly type: string, readonly value: string) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <FormConfigurationPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): FormConfigurationProperty {
    return new FormConfigurationProperty(value.type, value.value);
  }
}

const dropDownItems: ItemProps[] = [
  { key: "email", eventKey: "email", itemLabel: "Email" },
  { key: "sms", eventKey: "sms", itemLabel: "SMS" },
  { key: "fb", eventKey: "fb", itemLabel: "Facebook" },
];

const FormConfigurationPropertyComponent: PropertyComponent<FormConfigurationProperty> = ({
  elementId,
  propertyKey,
  type,
  value,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ownerEmail = useSelector(getUsername);
  const [
    isDisplayTrackerNotification,
    setIsDisplayTrackerNotification,
  ] = useState(false);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const pageSlug = useSelector(getPageSlug);

  if (value === "" && ownerEmail) {
    value = ownerEmail;
  }

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new FormConfigurationProperty(type, e.target.value)
      )
    );
  };

  const handleSectionSelect = (e: any): void => {
    if (e && e === "email") {
      dispatch(
        propertySet(
          elementId,
          propertyKey,
          new FormConfigurationProperty(String(e), value)
        )
      );
    } else {
      setIsDisplayTrackerNotification(true);
      if (websiteSlug) {
        dispatch(
          trackFakeDoor(
            websiteSlug,
            pageSlug ? pageSlug : undefined,
            "Contact Medium - " + e
          )
        );
      }
    }
  };

  let dropdownTitle = "Email";
  if (type === "sms") {
    dropdownTitle = "SMS";
  } else if (type === "fb") {
    dropdownTitle = "Facebook";
  }

  return (
    <div className="medium-padded-container">
      <div className={styles["form__group"]}>
        <Formik initialValues={{ name: "" }} onSubmit={() => {}}>
          <Form className="form">
            <Dropdown
              buttonLabel={"Message Type"}
              items={dropDownItems}
              title={dropdownTitle}
              onSelect={handleSectionSelect}
            />
            <TextFieldGroup
              name="text"
              label={t("dialog_99")}
              value={value}
              placeholder="Ex. george@gmail.com"
              onChange={handleLabelChange}
            />
            {!isDisplayTrackerNotification ? (
              <></>
            ) : (
              <div>
                <b>Thanks for your interest!</b>
                <p>
                  We are still developing this feature. We will update you once
                  it is out.
                </p>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </div>
  );
};
