import React from "react";
import { IProperty, PropertyComponent } from "./types";

export class RichTextProperty implements IProperty {
  constructor(readonly text: any) {}

  render(elementId: string, propertyKey: string) {
    return (
      <RichTextPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    return new RichTextProperty(value);
  }
}

const RichTextPropertyComponent: PropertyComponent<RichTextProperty> = () => {
  return <></>;
};
