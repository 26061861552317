import React from "react";

import styles from "./ResizeToolbar.module.scss";

type Props = {
  guides: number[];
  selectedDomElement: Element;
  elementHeight: number;
};

export const ResizeToolbarGuide: React.FunctionComponent<Props> = ({
  guides,
  selectedDomElement,
  elementHeight,
}) => {
  const createGuideElement = (
    size: number,
    closestGuide: number
  ): JSX.Element => {
    const highlight = size === closestGuide ? "highlight" : "";

    return (
      <div
        className={`${styles["resize-guide"]} ${
          styles[`resize-guide-${size}`]
        }`}
        style={{
          top: selectedDomElement.getBoundingClientRect().y,
          height: window
            .getComputedStyle(selectedDomElement)
            .getPropertyValue("height"),
        }}
        key={size}
      >
        <div
          className={`${styles["resize-guide"]} ${styles["guide-e"]} ${
            styles[`guide-e-${size}`]
          } ${styles["resizing"]} ${styles[highlight]}`}
          style={{
            height: elementHeight - 8,
          }}
        />
        <div
          className={`${styles["resize-guide"]} ${styles["guide-w"]} ${
            styles[`guide-w-${size}`]
          } ${styles["resizing"]} ${styles[highlight]}`}
          style={{
            height: elementHeight - 8,
          }}
        />
      </div>
    );
  };

  const closestGuide = getClosestGuide(
    selectedDomElement.getBoundingClientRect().width,
    guides
  );
  const guideElements: JSX.Element[] = [];

  guides.forEach((guideSize) => {
    guideElements.push(createGuideElement(guideSize, closestGuide));
  });

  return <>{guideElements}</>;
};

export const getClosestGuide = (width: number, guides: number[]): number => {
  // Replace 0 with current 100% width of window
  let tempGuides = guides.slice();
  const index = tempGuides.findIndex((x) => x === 0);
  tempGuides[index] = window.innerWidth;

  return tempGuides.reduce((a: number, b: number) => {
    const aDiff = Math.abs(a - width);
    const bDiff = Math.abs(b - width);

    if (aDiff === bDiff) {
      return a > b ? a : b;
    }
    return bDiff < aDiff ? b : a;
  });
};
