import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const foodChild1Template: ElementTemplates = {};

foodChild1Template[
  "cards"
] = TemplatesFactory.createDefaultCardsWithImage(
  "https://s3.amazonaws.com/img.goodweb.app/sites/default/food/main.png",
  new LeadStyleProperty("1"),
  new RichTextProperty("<h2><b>Our Locations</b></h2>"),
  new RTEAlignmentProperty("left"),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty("3 locations and more in the future"),
  new RTEAlignmentProperty("left"),
  "2",
  [
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>5th Avenue</h3>"),
      new RTEAlignmentProperty("left"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Reserve", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>'
      ),
      new RTEAlignmentProperty("left"),
      "https://images.unsplash.com/photo-1555992336-fb0d29498b13?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80",
      "2"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Rochester</h3>"),
      new RTEAlignmentProperty("left"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Reserve", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>'
      ),
      new RTEAlignmentProperty("left"),
      "https://images.unsplash.com/photo-1618670708336-2df80fdd0ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
      "2"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Huntington</h3>"),
      new RTEAlignmentProperty("left"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Reserve", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>'
      ),
      new RTEAlignmentProperty("left"),
      "https://images.unsplash.com/photo-1555090866-dfd8c22e9b35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      "2"
    ),
  ]
);
