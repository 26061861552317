import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import TextFieldGroup from "../components/components/TextFieldGroup";
import { IProperty, PropertyComponent } from "./types";
import { propertySet } from "editor/states/layout";
import { DimensionProperty } from "./DimensionProperty/DimensionProperty";

export class GoogleMapsEmbedUrlProperty implements IProperty {
  constructor(
    readonly original: string,
    readonly url: string,
    readonly dimension?: DimensionProperty
  ) { }

  render(elementId: string, propertyKey: string) {
    return (
      <GoogleMapsEmbedUrlPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
        url={this.url}
      />
    );
  }

  static parse(value: any): GoogleMapsEmbedUrlProperty {
    return new GoogleMapsEmbedUrlProperty(value.original, value.url);
  }
}

const GoogleMapsEmbedUrlPropertyComponent: PropertyComponent<GoogleMapsEmbedUrlProperty> = ({
  elementId,
  propertyKey,
  original,
  url,
}) => {
  const dispatch = useDispatch();

  const [originalTemp, setOriginalTemp] = useState(original);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let url = e.target.value;
    if (url) {
      const parser = new DOMParser();
      const doc: Document = parser.parseFromString(url, "text/html");
      if (doc) {
        if (doc.body && doc.body.firstChild) {
          const src = (doc.body.firstChild as HTMLIFrameElement).src;
          setOriginalTemp(url);
          dispatch(
            propertySet(
              elementId,
              propertyKey,
              new GoogleMapsEmbedUrlProperty(url, src)
            )
          );
        }
      }
    }
  };

  return (
    <div className="medium-padded-container">
      <div className="form__group">
        <Form className="form">
          <TextFieldGroup
            name="text-area"
            label="Google Maps IFrame"
            value={originalTemp}
            onChange={handleChange}
            size={"l"}
          />
        </Form>
      </div>
    </div>
  );
};
