import { ElementType, PropertyByKey } from "./types";

export class PropertyRenderer<T> {
  constructor(
    readonly properties: PropertyByKey,
    readonly element: ElementType
  ) {}

  render(propertyKey: keyof T) {
    const propertyKeyString = propertyKey.toString();
    return this.properties[propertyKeyString]
      .parse(this.element[propertyKeyString])
      .render(this.element.elementId, propertyKeyString);
  }
}
