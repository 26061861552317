import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type Heading6Properties = {
  heading6Style: { style: string };
  heading6Text: { text: string };
};

export function Heading6({
  properties: { heading6Style, heading6Text },
  className,
  ...props
}: ElementComponentProps<Heading6Properties>): JSX.Element {
  return (
    <h6
      {...props}
      className={className + "h6 heading-6-style-" + heading6Style.style}
    >
      {heading6Text.text}
    </h6>
  );
}

export const heading6ElementKey = "heading-6";

elementComponentRegistry.register(heading6ElementKey, Heading6);
