import { PowerInputOutlined } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { NavbarStyleProperty } from "editor/properties/NavbarStyleProperty/NavbarStyleProperty";
import {
  navbarElementKey,
  NavbarProperties,
} from "common/elements/sections/Navbar";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import WidthProperty from "editor/properties/WidthProperty";
import { BackgroundColorProperty, DimensionProperty } from "editor/properties";
import { Tabs, Tab } from "react-bootstrap";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import styles from "./Navbar.module.scss";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import { BorderProperty } from "editor/properties/BorderProperty/BorderProperty";
import { useTranslation } from "react-i18next";
export class NavbarElement {
  static readonly elementKey = navbarElementKey;
  static readonly label = "element_navbar_label";
  static readonly icon = PowerInputOutlined;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly allowedChildren = ["logo-container", "nav", "social-nav"];
  static readonly elementDescription = "element_navbar_description";
  static readonly elementPreviewUrl =
    "/img/add-navbar-element-preview-default.svg";
  static readonly isResizeable = true;
  static readonly resizeDirections = ["e", "w"];
  static readonly resizeGuides = [1024, 1280, 1366, 1440, 1600, 0];
  static readonly dialogPositionStatic = false;
  static readonly dialogSize = "large";
  static readonly childDragDirection = "horizontal";
  static readonly isReusable = true;
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<NavbarProperties> = {
    navbarStyle: NavbarStyleProperty,
    width: WidthProperty,
    backgroundColorProperty: BackgroundColorProperty,
    backgroundImageProperty: BackgroundImageProperty,
    dimension: DimensionProperty,
    borderProperty: BorderProperty,
  };

  static renderProperties(element: ElementType) {
    return <NavbarPropertiesComponent element={element} />;
  }

  static create(properties: {
    navbarStyle: string;
    width: number;
    backgroundColorProperty: string;
    backgroundImageProperty: BackgroundImageProperty;
    borderProperty?: BorderProperty;
    dimension?: DimensionProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const NavbarPropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof NavbarElement.properties>(
    NavbarElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="style">
        <Tab eventKey="style" title={t("dialog_1")}>
          {renderer.render("navbarStyle")}
        </Tab>
        <Tab eventKey="background-color" title={t("dialog_2")}>
          {renderer.render("backgroundColorProperty")}
        </Tab>
        <Tab eventKey="background-image" title={t("dialog_3")}>
          {renderer.render("backgroundImageProperty")}
        </Tab>
        <Tab eventKey="navbar-border" title={t("dialog_4")}>
          {renderer.render("borderProperty")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(NavbarElement);
