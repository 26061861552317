import {
  Billings,
  ReportAcquisition,
  ReportPageViews,
  Subscription,
  DailyReportWeb,
} from "editor/models/types";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import produce from "immer";
import { Reducer } from "redux";
import {
  finishRequest,
  finishRequestWithErrors,
  startRequest,
} from "api/apiActions";
import {
  cancelSubscriptionAsync,
  getPaymentsAsync,
  getReportByDateAsync,
  getSubscriptionAsync,
  getWebsiteMonthlyReportAsync,
  getWebsiteReportAsync,
  getWebsiteWeeklyReportAsync,
} from "editor/api/website";
import { getResponseJsonAsync } from "editor/api/common";
import { SubscriptionResource } from "@urux/arm-of-dorne-api";

export type State = {
  acquisitionReport: ReportAcquisition;
  pageViewReport: ReportPageViews;
  billings: Billings;
  subscription: Subscription | null;
  dailyReports: DailyReportWeb[] | null;
  weeklyReports: DailyReportWeb[] | null;
  monthlyReports: DailyReportWeb[] | null;
};

export const GET_WEBSITE_REPORT_DEFAULT = "GET_WEBSITE_REPORT_DEFAULT";

type GetWebsiteReportDefaultAction = {
  type: typeof GET_WEBSITE_REPORT_DEFAULT;
  ownerId: number;
  websiteUniqueId: string;
};

export const getWebsiteReportDefault = (
  ownerId: number,
  websiteUniqueId: string
): GetWebsiteReportDefaultAction => {
  return {
    type: GET_WEBSITE_REPORT_DEFAULT,
    ownerId,
    websiteUniqueId,
  };
};

export const GET_WEBSITE_REPORT_DEFAULT_SUCCESS =
  "GET_WEBSITE_REPORT_DEFAULT_SUCCESS";

type GetWebsiteReportDefaultSuccessAction = {
  type: typeof GET_WEBSITE_REPORT_DEFAULT_SUCCESS;
  reports: DailyReportWeb[];
};

export const getWebsiteReportSuccess = (
  reports: DailyReportWeb[]
): GetWebsiteReportDefaultSuccessAction => {
  return {
    type: GET_WEBSITE_REPORT_DEFAULT_SUCCESS,
    reports,
  };
};

export const GET_WEBSITE_REPORT_WEEKLY_DEFAULT =
  "GET_WEBSITE_REPORT_WEEKLY_DEFAULT";

type GetWebsiteWeeklyReportDefaultAction = {
  type: typeof GET_WEBSITE_REPORT_WEEKLY_DEFAULT;
  ownerId: number;
  websiteUniqueId: string;
};

export const getWebsiteWeeklyReportDefault = (
  ownerId: number,
  websiteUniqueId: string
): GetWebsiteWeeklyReportDefaultAction => {
  return {
    type: GET_WEBSITE_REPORT_WEEKLY_DEFAULT,
    ownerId,
    websiteUniqueId,
  };
};

export const GET_WEBSITE_REPORT_WEEKLY_DEFAULT_SUCCESS =
  "GET_WEBSITE_REPORT_WEEKLY_DEFAULT_SUCCESS";

type GetWebsiteWeeklyReportDefaultSuccessAction = {
  type: typeof GET_WEBSITE_REPORT_WEEKLY_DEFAULT_SUCCESS;
  reports: DailyReportWeb[];
};

export const getWebsiteWeeklyReportSuccess = (
  reports: DailyReportWeb[]
): GetWebsiteWeeklyReportDefaultSuccessAction => {
  return {
    type: GET_WEBSITE_REPORT_WEEKLY_DEFAULT_SUCCESS,
    reports,
  };
};

export const GET_WEBSITE_REPORT_MONTHLY_DEFAULT =
  "GET_WEBSITE_REPORT_MONTHLY_DEFAULT";

type GetWebsiteMonthlyReportDefaultAction = {
  type: typeof GET_WEBSITE_REPORT_MONTHLY_DEFAULT;
  ownerId: number;
  websiteUniqueId: string;
};

export const getWebsiteMonthlyReportDefault = (
  ownerId: number,
  websiteUniqueId: string
): GetWebsiteMonthlyReportDefaultAction => {
  return {
    type: GET_WEBSITE_REPORT_MONTHLY_DEFAULT,
    ownerId,
    websiteUniqueId,
  };
};

export const GET_WEBSITE_REPORT_MONTHLY_DEFAULT_SUCCESS =
  "GET_WEBSITE_REPORT_MONTHLY_DEFAULT_SUCCESS";

type GetWebsiteMonthlyReportDefaultSuccessAction = {
  type: typeof GET_WEBSITE_REPORT_MONTHLY_DEFAULT_SUCCESS;
  reports: DailyReportWeb[];
};

export const getWebsiteMonthlyReportSuccess = (
  reports: DailyReportWeb[]
): GetWebsiteMonthlyReportDefaultSuccessAction => {
  return {
    type: GET_WEBSITE_REPORT_MONTHLY_DEFAULT_SUCCESS,
    reports,
  };
};

export const GET_ACQUISITION_REPORT = "GET_ACQUISITION_REPORT";
export const ACQUISITION_REPORT = "acquisition";

type GetAquisitionReportAction = {
  type: typeof GET_ACQUISITION_REPORT;
  requestType: typeof ACQUISITION_REPORT;
  propertyId: string;
  startDate: string;
  endDate: string;
};

export const fetchAquisitionReport = (
  propertyId: string,
  startDate: string,
  endDate: string
): GetAquisitionReportAction => {
  return {
    type: GET_ACQUISITION_REPORT,
    requestType: ACQUISITION_REPORT,
    propertyId,
    startDate,
    endDate,
  };
};

export const GET_ACQUISITION_REPORT_ACTION_SUCCESS =
  "GET_ACQUISITION_REPORT_ACTION_SUCCESS";

type GetAquisitionReportSuccessAction = {
  type: typeof GET_ACQUISITION_REPORT_ACTION_SUCCESS;
  aquisitionReport: ReportAcquisition;
};

export const getAquisitionReportSuccessTrigger = (
  aquisitionReport: ReportAcquisition
): GetAquisitionReportSuccessAction => {
  return {
    type: GET_ACQUISITION_REPORT_ACTION_SUCCESS,
    aquisitionReport,
  };
};

export const GET_PAGEVIEWS_REPORT = "GET_PAGEVIEWS_REPORT";
export const PAGEVIEWS_REPORT = "pageview";

type GetPageViewsReportAction = {
  type: typeof GET_PAGEVIEWS_REPORT;
  requestType: typeof PAGEVIEWS_REPORT;
  propertyId: string;
  startDate: string;
  endDate: string;
};

export const fetchPageViewsReport = (
  propertyId: string,
  startDate: string,
  endDate: string
): GetPageViewsReportAction => {
  return {
    type: GET_PAGEVIEWS_REPORT,
    requestType: PAGEVIEWS_REPORT,
    propertyId,
    startDate,
    endDate,
  };
};

export const GET_PAGEVIEWS_REPORT_ACTION_SUCCESS =
  "GET_PAGEVIEWS_REPORT_ACTION_SUCCESS";

type GetPageViewsReportSuccessAction = {
  type: typeof GET_PAGEVIEWS_REPORT_ACTION_SUCCESS;
  pageViewsReport: ReportPageViews;
};

export const getPageViewsReportSuccessTrigger = (
  pageViewsReport: ReportPageViews
): GetPageViewsReportSuccessAction => {
  return {
    type: GET_PAGEVIEWS_REPORT_ACTION_SUCCESS,
    pageViewsReport,
  };
};

export const GET_ACQUISITION_REPORT_SUCCESS = "GET_ACQUISITION_REPORT_SUCCESS";

type GetAquisitionReportSuccessFAction = {
  type: typeof GET_ACQUISITION_REPORT_SUCCESS;
};

export const getAquisitionReportSuccess = (): GetAquisitionReportSuccessFAction => {
  return {
    type: GET_ACQUISITION_REPORT_SUCCESS,
  };
};

export const GET_PAYMENTS = "GET_PAYMENTS";

type GetPaymentsAction = {
  type: typeof GET_PAYMENTS;
  userId: number;
};

export const getSubscriptionAndPayments = (
  userId: number
): GetPaymentsAction => {
  return {
    type: GET_PAYMENTS,
    userId,
  };
};

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";

type CancelSubscriptionAction = {
  type: typeof CANCEL_SUBSCRIPTION;
  userId: number;
};

export const cancelSubscription = (
  userId: number
): CancelSubscriptionAction => {
  return {
    type: CANCEL_SUBSCRIPTION,
    userId,
  };
};

export const DISPLAY_PAYMENTS = "DISPLAY_PAYMENTS";

type DisplayPaymentsAction = {
  type: typeof DISPLAY_PAYMENTS;
  billings: Billings;
};

export const displayPayments = (billings: Billings): DisplayPaymentsAction => {
  return {
    type: DISPLAY_PAYMENTS,
    billings,
  };
};

export const SET_SUBSCRIPTION_ACTION = "SET_SUBSCRIPTION_ACTION";

type SetSubscriptionAction = {
  type: typeof SET_SUBSCRIPTION_ACTION;
  subscriptionResource: SubscriptionResource;
};

export const setSubscription = (
  subscriptionResource: SubscriptionResource
): SetSubscriptionAction => {
  return {
    type: SET_SUBSCRIPTION_ACTION,
    subscriptionResource,
  };
};

type Action =
  | GetAquisitionReportAction
  | GetAquisitionReportSuccessFAction
  | GetAquisitionReportSuccessAction
  | GetPageViewsReportAction
  | GetPageViewsReportSuccessAction
  | GetPaymentsAction
  | DisplayPaymentsAction
  | SetSubscriptionAction
  | CancelSubscriptionAction
  | GetWebsiteReportDefaultSuccessAction
  | GetWebsiteWeeklyReportDefaultSuccessAction
  | GetWebsiteMonthlyReportDefaultSuccessAction;

const initialState: State = {
  acquisitionReport: {
    isAvailable: false,
    reportDate: "",
    totalVisitors: 0,
    newVisitors: 0,
    keyValue: null,
  },
  pageViewReport: {
    isAvailable: false,
    reportDate: "",
    totalViews: 0,
    newViews: 0,
    keyValue: null,
  },
  billings: {
    billings: null,
  },
  subscription: null,
  dailyReports: null,
  weeklyReports: null,
  monthlyReports: null,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case GET_ACQUISITION_REPORT_ACTION_SUCCESS:
      return handleGetAquisitionReportSuccess(state, action);
    case GET_PAGEVIEWS_REPORT_ACTION_SUCCESS:
      return handleGetPageViewsReportSuccess(state, action);
    case DISPLAY_PAYMENTS:
      return handleDisplayPayments(state, action);
    case SET_SUBSCRIPTION_ACTION:
      return handleSetSubscription(state, action);
    case GET_WEBSITE_REPORT_DEFAULT_SUCCESS:
      return handleGetWebsiteReportDefaultSuccess(state, action);
    case GET_WEBSITE_REPORT_WEEKLY_DEFAULT_SUCCESS:
      return handleGetWebsiteWeeklyReportDefaultSuccess(state, action);
    case GET_WEBSITE_REPORT_MONTHLY_DEFAULT_SUCCESS:
      return handleGetWebsiteMonthlyReportDefaultSuccess(state, action);
    default:
      return state;
  }
};

const handleGetWebsiteMonthlyReportDefaultSuccess = (
  state: State,
  action: GetWebsiteMonthlyReportDefaultSuccessAction
): State =>
  produce(state, (draft) => {
    draft.monthlyReports = action.reports;
  });

const handleGetWebsiteWeeklyReportDefaultSuccess = (
  state: State,
  action: GetWebsiteWeeklyReportDefaultSuccessAction
): State =>
  produce(state, (draft) => {
    draft.weeklyReports = action.reports;
  });

const handleGetWebsiteReportDefaultSuccess = (
  state: State,
  action: GetWebsiteReportDefaultSuccessAction
): State =>
  produce(state, (draft) => {
    draft.dailyReports = action.reports;
  });

const handleSetSubscription = (
  state: State,
  action: SetSubscriptionAction
): State =>
  produce(state, (draft) => {
    draft.subscription = {
      holderName: action.subscriptionResource!.holderName!,
      email: action.subscriptionResource!.email!,
      last4Digits: action.subscriptionResource!.last4Digits!,
      brand: action.subscriptionResource!.brand!,
      nextBillDate: action.subscriptionResource!.nextBillDate!,
      isActive: action.subscriptionResource!.isActive!,
    };
  });

const handleDisplayPayments = (
  state: State,
  action: DisplayPaymentsAction
): State =>
  produce(state, (draft) => {
    draft.billings = action.billings;
  });

const handleGetAquisitionReportSuccess = (
  state: State,
  action: GetAquisitionReportSuccessAction
): State =>
  produce(state, (draft) => {
    draft.acquisitionReport = action.aquisitionReport;
  });

const handleGetPageViewsReportSuccess = (
  state: State,
  action: GetPageViewsReportSuccessAction
): State =>
  produce(state, (draft) => {
    draft.pageViewReport = action.pageViewsReport;
  });

function* getWebsiteReport(action: GetWebsiteReportDefaultAction) {
  yield put(startRequest());
  try {
    const response = yield call(
      getWebsiteReportAsync,
      action.ownerId,
      action.websiteUniqueId
    );

    yield put(getWebsiteReportSuccess(response));
    yield put(finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* getWebsiteWeeklyReport(action: GetWebsiteWeeklyReportDefaultAction) {
  yield put(startRequest());
  try {
    const response = yield call(
      getWebsiteWeeklyReportAsync,
      action.ownerId,
      action.websiteUniqueId
    );

    yield put(getWebsiteWeeklyReportSuccess(response));
    yield put(finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* getWebsiteMonthlyReport(
  action: GetWebsiteMonthlyReportDefaultAction
) {
  yield put(startRequest());
  try {
    const response = yield call(
      getWebsiteMonthlyReportAsync,
      action.ownerId,
      action.websiteUniqueId
    );

    yield put(getWebsiteMonthlyReportSuccess(response));
    yield put(finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* getAcquisitionReportAsync(action: GetAquisitionReportAction) {
  yield put(startRequest());
  try {
    const response = yield call(
      getReportByDateAsync,
      action.requestType,
      action.propertyId,
      action.startDate,
      action.endDate
    );
    yield put(getAquisitionReportSuccessTrigger(response));
    yield put(finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* getPageViewReportAsync(action: GetPageViewsReportAction) {
  yield put(startRequest());
  try {
    const response = yield call(
      getReportByDateAsync,
      action.requestType,
      action.propertyId,
      action.startDate,
      action.endDate
    );
    yield put(getPageViewsReportSuccessTrigger(response));
    yield put(finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* getPaymentsByUserId(action: GetPaymentsAction) {
  yield put(startRequest());
  try {
    const subscriptionResponse = yield call(
      getSubscriptionAsync,
      action.userId
    );

    if (subscriptionResponse && subscriptionResponse.isAvailable) {
      yield put(setSubscription(subscriptionResponse));

      const response: Billings = yield call(getPaymentsAsync, action.userId);
      yield put(displayPayments(response));
    }

    yield put(finishRequest());
  } catch (e) {
    yield put(finishRequestWithErrors("Getting subscription issue"));
  }
}

function* cancelSubscriptionByUserId(action: CancelSubscriptionAction) {
  yield put(startRequest());
  try {
    const subscriptionResponse = yield call(
      cancelSubscriptionAsync,
      action.userId
    );
    yield put(setSubscription(subscriptionResponse));

    yield put(finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

export function* rootSaga() {
  yield takeLatest(GET_ACQUISITION_REPORT, getAcquisitionReportAsync);
  yield takeLatest(GET_PAGEVIEWS_REPORT, getPageViewReportAsync);
  yield takeLatest(GET_PAYMENTS, getPaymentsByUserId);
  yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubscriptionByUserId);
  yield takeLatest(GET_WEBSITE_REPORT_DEFAULT, getWebsiteReport);
  yield takeLatest(GET_WEBSITE_REPORT_WEEKLY_DEFAULT, getWebsiteWeeklyReport);
  yield takeLatest(GET_WEBSITE_REPORT_MONTHLY_DEFAULT, getWebsiteMonthlyReport);
}
