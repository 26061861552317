import { PaymentRounded } from "@material-ui/icons";
import {
  getUserFirstName,
  getUserId,
  getUserLastName,
  getUsername,
  getUserWebsiteSlug,
} from "auth/selectors";
import {
  cancelSubscription,
  getSubscriptionAndPayments,
} from "editor/states/reports";
import { getBillings, getSubscription } from "editor/states/selectors";
import React, { useEffect } from "react";
import { Badge, Table } from "react-bootstrap";
import { Button } from "editor/components/base/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Admin.module.scss";
import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { Billing } from "editor/models/types";
import { useHistory } from "react-router-dom";
import { logActivity } from "auth/authActions";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { tawkWidgetToggle, setTawkAttributes } from "./Tawk";

export const PaymentDetails: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const billings = useSelector(getBillings);
  const subscription = useSelector(getSubscription);

  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const username = useSelector(getUsername);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const tldjs = require("tldjs");
  const { getDomain } = tldjs;

  useEffect(() => {
    document.title = "GoodWeb | Payment Details";
  }, []);

  Font.register({
    family: "Lexend",
    src: "/fonts/Lexend-Medium.ttf",
  });

  // Create styles
  const pdfStyles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#fff",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    bodyTopSpacer: {
      marginTop: 44,
    },
    headerSection: {
      backgroundColor: "#F5F8FF",
    },
    headerImage: {
      paddingTop: 8,
      paddingRight: 8,
      paddingBottom: 8,
      paddingLeft: 4,
      height: 42,
      width: 182,
    },
    headerText: {
      fontFamily: "Lexend",
      fontSize: 24,
    },
    subheaderText: {
      fontFamily: "Lexend",
      fontSize: 16,
      marginBottom: 8,
    },
    divider: {
      marginTop: 24,
      marginBottom: 24,
      borderBottom: "2px solid #efefef",
    },
    normalText: {
      fontFamily: "Helvetica",
      fontSize: 12,
    },
    subText: {
      fontFamily: "Helvetica",
      fontWeight: 100,
      fontSize: 10,
      width: 180,
      lineHeight: 1.2,
    },
    tableText: {
      display: "flex",
      flexDirection: "row",
      fontFamily: "Helvetica",
      fontSize: 12,
      justifyContent: "space-between",
      lineHeight: 1.5,
    },
  });

  // Create Document Component
  const PdfDocument = (bill: Billing) => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.headerSection}>
          <View style={pdfStyles.section}>
            <Image
              style={pdfStyles.headerImage}
              src={"/img/goodweb-fullmarkup.png"}
            />
            <Text style={pdfStyles.headerText}>Receipt</Text>
            <Text style={pdfStyles.divider}></Text>
            <Text style={pdfStyles.subheaderText}>To</Text>
            <Text style={pdfStyles.normalText}>{bill.billingName}</Text>
            <Text style={pdfStyles.normalText}>{bill.billingEmail}</Text>
          </View>
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.subText}>GOODWEB SpA</Text>
            <Text style={pdfStyles.subText}>
              Dr. Manuel Barros Borgoño N°160,
            </Text>
            <Text style={pdfStyles.subText}>
              Oficina 102, Comuna Providencia
            </Text>
            <Text style={pdfStyles.subText}>Santiago, Chile</Text>
            <Text style={pdfStyles.subText}>77.572.247-9</Text>
          </View>
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.bodyTopSpacer}></Text>
          <Text style={pdfStyles.subheaderText}>GoodWeb Subscription</Text>
          <Text style={pdfStyles.divider}></Text>
          <View style={pdfStyles.tableText}>
            <Text>Order Id:</Text>
            <Text> {bill.orderId}</Text>
          </View>
          <View style={pdfStyles.tableText}>
            <Text>Amount:</Text>
            <Text> {bill.amount}</Text>
          </View>
          <View style={pdfStyles.tableText}>
            <Text>Processed Date:</Text>
            <Text> {bill.date}</Text>
          </View>
          <Text style={pdfStyles.divider}></Text>
        </View>
      </Page>
    </Document>
  );

  useEffect(() => {
    if (userId && userId !== 0) {
      dispatch(getSubscriptionAndPayments(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (subscription && !subscription.isActive) {
      history.push("/admin");
    }
  }, [subscription, history]);

  useEffect(() => {
    tawkWidgetToggle(true);
  }, []);

  useEffect(() => {
    if (firstName && lastName && websiteSlug && username) {
      const publishedDomain =
        websiteSlug +
        "." +
        getDomain(document.domain) +
        (window.location.port ? ":" + window.location.port : "");
      setTawkAttributes(firstName + " " + lastName, username, publishedDomain);
    }
  }, [firstName, lastName, username, websiteSlug, getDomain]);

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <PaymentRounded />
          <h3>Payment</h3>
        </div>
        <Button
          variant="outline-primary"
          label="Cancel Subscription"
          onClick={() => {
            if (userId) {
              dispatch(cancelSubscription(userId));
              dispatch(
                logActivity("CANCEL SUBSCRIPTION", userId + "", username)
              );
            }
          }}
        />
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card"]}>
          <div className={styles["admin__card-header"]}>
            <h4>Payment Details</h4>
          </div>
          <div>
            <span className={styles["admin__content-lead"]}>Card Holder</span>
            <span className={styles["admin__content-entry"]}>
              {subscription?.holderName}
            </span>
            <span className={styles["admin__content-lead"]}>Email</span>
            <span className={`${styles["admin__content-entry"]}`}>
              {subscription?.email}
            </span>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span className={styles["admin__content-lead"]}>Brand</span>
                <span className={styles["admin__content-entry"]}>
                  {subscription?.brand}
                </span>
              </div>
              <div>
                <span className={styles["admin__content-lead"]}>
                  Last 4 Digits
                </span>
                <span className={styles["admin__content-entry"]}>
                  **** **** **** {subscription?.last4Digits}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card--big"]}>
          <div
            className={styles["admin__card-header"]}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h4>Billing History</h4>
            <span>Next bill date: {subscription?.nextBillDate}</span>
          </div>
          <div className={styles["reports_aquisition"]}>
            <Table style={{ fontSize: ".8rem" }}>
              <thead>
                <tr>
                  <td>Date</td>
                  <td style={{ textAlign: "center" }}>Amount</td>
                  <td style={{ textAlign: "center" }}>Status</td>
                  <td>Order ID</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {billings && billings.billings ? (
                  billings.billings.map((bill) => (
                    <tr key={bill.orderId}>
                      <td>{bill.date}</td>
                      <td style={{ textAlign: "center" }}>{bill.amount}</td>
                      <td style={{ textAlign: "center" }}>
                        {bill.status === "Paid" ? (
                          <Badge variant="success">{bill.status}</Badge>
                        ) : (
                          <Badge variant="warning">{bill.status}</Badge>
                        )}
                      </td>
                      <td>{bill.orderId}</td>
                      <td>
                        <PDFDownloadLink
                          document={
                            <PdfDocument
                              date={bill.date}
                              status={bill.status}
                              orderId={bill.orderId}
                              amount={bill.amount}
                              billingName={bill.billingName}
                              billingEmail={bill.billingEmail}
                            />
                          }
                          fileName={`${bill.orderId}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download"
                          }
                        </PDFDownloadLink>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No bills available</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <TawkMessengerReact
        propertyId="63b56d68c2f1ac1e202ba11d"
        widgetId="1glub6oqr"
      />
    </>
  );
};
