import React from "react";
import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export function Root({ children }: ElementComponentProps<{}>): JSX.Element {
  return <div>{children}</div>;
}

export const rootElementKey = "root";

elementComponentRegistry.register(rootElementKey, Root);
