import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Modal, Container, Row, Form, Tabs, Tab } from "react-bootstrap";
import * as yup from "yup";

import { StepProps } from "./EditorWizard";
import { TemplateKey } from "../../../templates/types";

import styles from "./EditorWizard.module.scss";
import { Button } from "editor/components/base/Button/Button";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  CloudCircleRounded,
  Facebook,
  WhatsApp,
} from "@material-ui/icons";
import { ChromePicker, ColorResult, CompactPicker } from "react-color";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  templateKey: yup.mixed<TemplateKey>().required(),
  primaryFont: yup.string(),
  secondaryFont: yup.string(),
});

type FormValues = yup.InferType<typeof schema>;

export const EditorWizardStep0b: React.FunctionComponent<StepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleUpdateColors,
  parentPrimaryFont,
  parentSecondaryFont,
  parentPrimaryColor,
  parentSecondaryColor,
  parentTernaryColor,
  parentColorType,
  website,
}) => {
  const { t } = useTranslation();
  var tinycolor = require("tinycolor2");

  const [selectedPrimaryColor, setSelectedPrimaryColor] = useState("#124E78");
  const [selectedSecondaryColor, setSelectedSecondaryColor] = useState(
    "#C47500"
  );
  const [selectedTernaryColor, setSelectedTernaryColor] = useState("#003F7A");
  const [selectedColorType, setSelectedColorType] = useState("comp");

  const [triad1, setTriad1] = useState("#124E78");
  const [triad2, setTriad2] = useState("#BB4012");
  const [triad3, setTriad3] = useState("#BB9012");

  const [mono1, setmono1] = useState("#124E78");
  const [mono2, setmono2] = useState("#256490");
  const [mono3, setmono3] = useState("#063C62");

  const [like1, setlike1] = useState("#124E78");
  const [like2, setlike2] = useState("#0C814C");
  const [like3, setlike3] = useState("#1E2182");

  const [split1, setsplit1] = useState("#124E78");
  const [split2, setsplit2] = useState("#DE381F");
  const [split3, setsplit3] = useState("#6FDE14");

  const [tetra1, settetra1] = useState("#124E78");
  const [tetra2, settetra2] = useState("#BB9012");
  const [tetra3, settetra3] = useState("#1E2182");

  const [comp1, setcomp1] = useState("#124E78");
  const [comp2, setcomp2] = useState("#C47500");
  const [comp3, setcomp3] = useState("#003F7A");

  const setPalettes = (hex: string, colorType: string) => {
    let triadColors = tinycolor(hex).triad();
    let triadMap = triadColors.map(function (t: any) {
      return t.toHexString();
    });
    setTriad1(triadMap[0]);
    setTriad2(triadMap[1]);
    setTriad3(triadMap[2]);

    let monoColors = tinycolor(hex).monochromatic();
    let monoMap = monoColors.map(function (t: any) {
      return t.toHexString();
    });
    setmono1(monoMap[0]);
    setmono2(monoMap[1]);
    setmono3(monoMap[2]);

    let splitColors = tinycolor(hex).splitcomplement();
    let splitMap = splitColors.map(function (t: any) {
      return t.toHexString();
    });
    setsplit1(splitMap[0]);
    setsplit2(splitMap[1]);
    setsplit3(splitMap[2]);

    let analogousColors = tinycolor(hex).analogous();
    let likeMap = analogousColors.map(function (t: any) {
      return t.toHexString();
    });
    setlike1(likeMap[0]);
    setlike2(likeMap[1]);
    setlike3(likeMap[2]);

    let tetradColors = tinycolor(hex).tetrad();
    let tetraMap = tetradColors.map(function (t: any) {
      return t.toHexString();
    });
    settetra1(tetraMap[0]);
    settetra2(tetraMap[1]);
    settetra3(tetraMap[2]);

    let compColor = tinycolor(hex).complement().toHexString();
    let compMap = [hex, compColor, tinycolor(compColor).greyscale().toString()];
    setcomp1(compMap[0]);
    setcomp2(compMap[1]);
    setcomp3(compMap[2]);

    let colorsMap: string[];
    if (colorType === "triad") {
      colorsMap = triadMap;
    } else if (colorType === "mono") {
      colorsMap = monoMap;
    } else if (colorType === "split") {
      colorsMap = splitMap;
    } else if (colorType === "like") {
      colorsMap = likeMap;
    } else if (colorType === "tetra") {
      colorsMap = tetraMap;
    } else {
      colorsMap = compMap;
    }

    return colorsMap;
  };

  useEffect(() => {
    if (parentPrimaryColor && parentPrimaryColor !== "") {
      setSelectedPrimaryColor(parentPrimaryColor);
    }
    if (parentSecondaryColor && parentSecondaryColor !== "") {
      setSelectedSecondaryColor(parentSecondaryColor);
    }
    if (parentTernaryColor && parentTernaryColor !== "") {
      setSelectedTernaryColor(parentTernaryColor);
    }
    if (parentColorType && parentColorType !== "") {
      setSelectedColorType(parentColorType);
    }

    if (parentColorType && parentPrimaryColor) {
      let triadColors = tinycolor(parentPrimaryColor).triad();
      let triadMap = triadColors.map(function (t: any) {
        return t.toHexString();
      });
      setTriad1(triadMap[0]);
      setTriad2(triadMap[1]);
      setTriad3(triadMap[2]);

      let monoColors = tinycolor(parentPrimaryColor).monochromatic();
      let monoMap = monoColors.map(function (t: any) {
        return t.toHexString();
      });
      setmono1(monoMap[0]);
      setmono2(monoMap[1]);
      setmono3(monoMap[2]);

      let splitColors = tinycolor(parentPrimaryColor).splitcomplement();
      let splitMap = splitColors.map(function (t: any) {
        return t.toHexString();
      });
      setsplit1(splitMap[0]);
      setsplit2(splitMap[1]);
      setsplit3(splitMap[2]);

      let analogousColors = tinycolor(parentPrimaryColor).analogous();
      let likeMap = analogousColors.map(function (t: any) {
        return t.toHexString();
      });
      setlike1(likeMap[0]);
      setlike2(likeMap[1]);
      setlike3(likeMap[2]);

      let tetradColors = tinycolor(parentPrimaryColor).tetrad();
      let tetraMap = tetradColors.map(function (t: any) {
        return t.toHexString();
      });
      settetra1(tetraMap[0]);
      settetra2(tetraMap[1]);
      settetra3(tetraMap[2]);

      let compColor = tinycolor(parentPrimaryColor).complement().toHexString();
      let compMap = [
        parentPrimaryColor,
        compColor,
        tinycolor(compColor).greyscale().toString(),
      ];
      setcomp1(compMap[0]);
      setcomp2(compMap[1]);
      setcomp3(compMap[2]);
    }
  }, [
    parentPrimaryColor,
    parentSecondaryColor,
    parentTernaryColor,
    parentColorType,
    tinycolor,
  ]);

  const onSubmit = (values: FormValues) => {
    handleSaveAndContinue(values);
  };

  const handleTypeClick = (type: string) => {
    let color1;
    let color2;
    let color3;
    if (type === "triad") {
      color1 = triad1;
      color2 = triad2;
      color3 = triad3;
    } else if (type === "mono") {
      color1 = mono1;
      color2 = mono2;
      color3 = mono3;
    } else if (type === "split") {
      color1 = split1;
      color2 = split2;
      color3 = split3;
    } else if (type === "like") {
      color1 = like1;
      color2 = like2;
      color3 = like3;
    } else if (type === "tetra") {
      color1 = tetra1;
      color2 = tetra2;
      color3 = tetra3;
    } else {
      color1 = comp1;
      color2 = comp2;
      color3 = comp3;
    }
    setSelectedPrimaryColor(color1);
    setSelectedSecondaryColor(color2);
    setSelectedTernaryColor(color3);
    setSelectedColorType(type);

    handleUpdateColors(color1, color2, color3, type);
  };

  const handleOnClick = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    let colorsMap = setPalettes(color.hex, selectedColorType);

    setSelectedPrimaryColor(colorsMap[0]);
    setSelectedSecondaryColor(colorsMap[1]);
    setSelectedTernaryColor(colorsMap[2]);
    setSelectedColorType(selectedColorType);

    setFieldValue("primaryColor", colorsMap[0]);
    setFieldValue("secondaryColor", colorsMap[1]);
    setFieldValue("ternaryColor", colorsMap[2]);
    setFieldValue("colorType", selectedColorType);

    handleUpdateColors(
      colorsMap[0],
      colorsMap[1],
      colorsMap[2],
      selectedColorType
    );
  };

  function hexToRGB(hex: string, alpha: number) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  function isHexLight(color: string) {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, setFieldValue, handleSubmit, handleChange }) => (
        <div className={`${styles["wizard__split-container"]}`}>
          <Form noValidate onSubmit={handleSubmit} style={{ width: "366px" }}>
            <Modal.Header className={`${styles["wizard__header"]}`}>
              <Modal.Title style={{ lineHeight: 1 }}>
                {t("editor_setup_wizard12")}
              </Modal.Title>
              {/* <small style={{marginTop: 12}}>
                Choose a color and we'll generate a combination that will be
                used as a palette in your website. You can change this later in
                the Editor. 
              </small>*/}
            </Modal.Header>
            <Modal.Body className={`${styles["wizard__body"]}`}>
              {/* <div className={`${styles["tab"]}`}>
                <div
                  className={`${styles["tab-with-margin"]}`}
                  onClick={handleRefreshOfSwatches}
                >
                  Refresh Colors ⟳
                </div>
              </div> */}
              <Container>
                <Form.Group as={Row}>
                  <Form.Control
                    type="hidden"
                    name="primaryColor"
                    value={values.primaryColor}
                  ></Form.Control>
                  <Form.Control
                    type="hidden"
                    name="secondaryColor"
                    value={values.secondaryColor}
                  ></Form.Control>
                  <Form.Control
                    type="hidden"
                    name="ternaryColor"
                    value={values.ternaryColor}
                  ></Form.Control>
                  <Form.Control
                    type="hidden"
                    name="colorType"
                    value={values.colorType}
                  ></Form.Control>
                  <div className="picker-container">
                    <Tabs id="color-picker" defaultActiveKey={"github"}>
                      <Tab title={t("editor_setup_wizard13")} eventKey="github">
                        <CompactPicker
                          color={selectedPrimaryColor}
                          onChange={(
                            color: ColorResult,
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleOnClick(color, event, setFieldValue);
                          }}
                        />
                      </Tab>
                      <Tab title={t("editor_setup_wizard14")} eventKey="chrome">
                        <ChromePicker
                          disableAlpha={true}
                          color={selectedPrimaryColor}
                          onChange={(
                            color: ColorResult,
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleOnClick(color, event, setFieldValue);
                          }}
                        />
                      </Tab>
                    </Tabs>
                    <div className="combinations">
                      {t("editor_setup_wizard15")}
                    </div>
                    <div className="harmony-rule-container">
                      <div
                        className={`harmony-type ${
                          selectedColorType === "triad" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("triad");
                        }}
                      >
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(triad1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(triad2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(triad3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                      <div
                        className={`harmony-type ${
                          selectedColorType === "mono" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("mono");
                        }}
                      >
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(mono1, 0.0)}` }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(mono2, 0.0)}` }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(mono3, 0.0)}` }}
                        ></div>
                      </div>
                      <div
                        className={`harmony-type ${
                          selectedColorType === "tetra" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("tetra");
                        }}
                      >
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(tetra1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(tetra2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(tetra3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="harmony-rule-container">
                      <div
                        className={`harmony-type ${
                          selectedColorType === "like" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("like");
                        }}
                      >
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(like1, 0.0)}` }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(like2, 0.0)}` }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(like3, 0.0)}` }}
                        ></div>
                      </div>
                      <div
                        className={`harmony-type ${
                          selectedColorType === "split" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("split");
                        }}
                      >
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(split1, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(split2, 0.0)}`,
                          }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{
                            backgroundColor: `${hexToRGB(split3, 0.0)}`,
                          }}
                        ></div>
                      </div>
                      <div
                        className={`harmony-type ${
                          selectedColorType === "comp" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleTypeClick("comp");
                        }}
                      >
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(comp1, 0.0)}` }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(comp2, 0.0)}` }}
                        ></div>
                        <div
                          className="harmony-swatch"
                          style={{ backgroundColor: `${hexToRGB(comp3, 0.0)}` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Container>
            </Modal.Body>
            <Modal.Footer className={styles["wizard__footer"]}>
              <Button
                label={t("next")}
                variant="outline-primary"
                size="medium"
                icon={ArrowForwardRounded}
                onClick={handleSubmit}
              />
              <Button
                icon={ArrowBackRounded}
                variant="outline-primary"
                size="circle"
                onClick={() => handleStepBack(1)}
              />
            </Modal.Footer>
          </Form>
          <div className={`${styles["wizard__preview"]}`}>
            <div className={`${styles["label"]}`}>
              {t("editor_setup_wizard4")}
            </div>
            <div
              className={`${styles["navbar"]}`}
              style={{
                fontFamily: `${parentSecondaryFont}`,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {website && website.logoCreated ? (
                  <img
                    src={`https://s3.amazonaws.com/img.goodweb.app/sites/${website.slug}/main.png`}
                    alt="Main logo"
                    style={{ maxHeight: "50px", marginRight: "12px" }}
                  />
                ) : (
                  <div className={styles["logo"]}>
                    <CloudCircleRounded
                      style={{
                        color: `${hexToRGB(selectedSecondaryColor, 0.0)}`,
                      }}
                    />{" "}
                    logo
                  </div>
                )}
                <div>{t("editor_setup_wizard5")}</div>
                <div>{t("editor_setup_wizard6")}</div>
              </div>
              <div
                style={{
                  color: `${selectedPrimaryColor}`,
                }}
              >
                <Facebook
                  style={{ color: `${hexToRGB(selectedSecondaryColor, 0.0)}` }}
                />
                <WhatsApp
                  style={{ color: `${hexToRGB(selectedSecondaryColor, 0.0)}` }}
                />
              </div>
            </div>
            <div
              className={`${styles["cover"]}`}
              style={{
                backgroundColor: `${hexToRGB(selectedPrimaryColor, 0.15)}`,
              }}
            >
              <h1
                style={{
                  fontFamily: `${parentPrimaryFont}`,
                  color: `${hexToRGB(
                    tinycolor(selectedPrimaryColor).darken(50).toString(),
                    0.0
                  )}`,
                }}
              >
                {t("editor_setup_wizard7")}
              </h1>
              <p
                style={{
                  fontFamily: `${parentSecondaryFont}`,
                }}
              >
                {t("editor_setup_wizard8")}
              </p>
              <button
                style={{
                  fontFamily: `${parentPrimaryFont}`,
                  backgroundColor: `${selectedPrimaryColor}`,
                  color: `${
                    isHexLight(selectedPrimaryColor)
                      ? selectedSecondaryColor
                      : `#ffffff`
                  }`,
                }}
              >
                {t("editor_setup_wizard9")}
              </button>
            </div>
            <div className={`${styles["body"]}`}>
              <article>
                <h3
                  style={{
                    fontFamily: `${parentPrimaryFont}`,
                    color: `${hexToRGB(
                      tinycolor(selectedPrimaryColor).darken(50).toString(),
                      0.0
                    )}`,
                  }}
                >
                  {t("editor_setup_wizard10")}
                </h3>
                <p
                  style={{
                    fontFamily: `${parentSecondaryFont}`,
                  }}
                >
                  {t("editor_setup_wizard11a")}{" "}
                  <span
                    style={{
                      color: `${hexToRGB(selectedSecondaryColor, 0.0)}`,
                      textDecoration: "underline",
                      fontWeight: 600,
                    }}
                  >
                    {t("editor_setup_wizard11b")}
                  </span>{" "}
                  {t("editor_setup_wizard11c")}
                </p>
                <p
                  style={{
                    color: `${hexToRGB(selectedTernaryColor, 0.0)}`,
                    display: "none",
                  }}
                >
                  Test
                </p>
              </article>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
