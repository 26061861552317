import { Loader } from "admin/Loader/Loader";
import { getCountry, getCountryCode } from "admin/utils-country";
import { finishRequestWithErrors } from "api/apiActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteProps, Redirect } from "react-router-dom";
import { loginWithAccessToken, updateDetails } from "./authActions";
import { getUserCountryCode, getUserId } from "./selectors";

export const OAuth2RedirectHandler: React.FunctionComponent<RouteProps> = ({
  ...props
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const stateCountryCode = useSelector(getUserCountryCode);
  const [isValid, setIsValid] = useState<boolean>();
  const [isValidated, setIsValidated] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const getUrlParameter = (name: string) => {
      // Copied code has the commented out code below but seems to work without it
      // name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      var results;
      if (props.location) {
        results = regex.exec(props.location.search);
        return results === null
          ? ""
          : decodeURIComponent(results[1].replace(/\+/g, " "));
      }
      return results;
    };

    const token = getUrlParameter("token");
    const error = getUrlParameter("error");
    if (error) {
      setIsValid(false);
      setIsValidated(false);
      setErrorMessage(error);
      dispatch(finishRequestWithErrors(error));
    } else if (!userId && token && token != null) {
      dispatch(
        loginWithAccessToken(
          token,
          (userId: number, firstName: string, lastName: string) => {
            setIsValidated(true);
            if (userId && userId !== 0) {
              setIsValid(true);
              if (stateCountryCode) {
                const countryCode = getCountryCode();
                const country = getCountry(countryCode);
                //Update Author Country
                if (countryCode && country && userId && firstName && lastName) {
                  dispatch(
                    updateDetails(
                      userId,
                      firstName,
                      lastName,
                      countryCode,
                      country
                    )
                  );
                }
              }
            } else {
              setIsValid(false);
            }
          }
        )
      );
    }
  }, [
    userId,
    stateCountryCode,
    setIsValid,
    setIsValidated,
    dispatch,
    props.location,
  ]);

  const buildRender = () => {
    // Still requesting

    if (isValidated === undefined && isValid === undefined) {
      return <Loader />;
    } else {
      if (isValid === undefined) {
        return <Loader />;
      } else if (isValid) {
        return (
          <Redirect
            to={{
              pathname: "/admin",
              state: { from: props.location },
            }}
          />
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
                error: errorMessage,
              },
            }}
          />
        );
      }
    }
  };

  return buildRender();
};
