import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import * as yup from "yup";

import { StepProps } from "./EditorWizard";
import {
  TemplateKey,
  templates,
  templateTitles,
} from "../../../templates/types";

import styles from "./EditorWizard.module.scss";
import { Button } from "editor/components/base/Button/Button";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  VisibilityRounded,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { logActivity } from "auth/authActions";
import { useTranslation } from "react-i18next";
import { getUsername } from "auth/selectors";

const containerClassName = "radio-container";
const containerClassNameSelected = containerClassName + "-selected";

const schema = yup.object({
  templateKey: yup.mixed<TemplateKey>().required(/* TODO: add a message */),
});

type FormValues = yup.InferType<typeof schema>;

export const EditorWizardStep0: React.FunctionComponent<StepProps> = ({
  values,
  handleSaveAndContinue,
  handleUpdateFonts,
}) => {
  const username = useSelector(getUsername);
  const onSubmit = (values: FormValues) => {
    handleSaveAndContinue(values);
  };
  const dispatch = useDispatch();
  const [templateKeys, setTemplateKeys] = useState<string[]>();
  const { t } = useTranslation();

  useEffect(() => {
    let tempTemplateKeys = [];
    if (templates) {
      for (const templateKey in templates) {
        tempTemplateKeys.push(templateKey);
      }
    }
    setTemplateKeys(tempTemplateKeys);
  }, []);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, handleSubmit, handleChange }) => {
        return (
          <div className={`${styles["wizard__split-container"]}`}>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header
                className={`${styles["wizard__header"]} ${styles["wizard__header2"]}`}
              >
                <Modal.Title style={{ lineHeight: 1 }}>
                  {t("start_from_scratch")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                className={`${styles["wizard__body"]} ${styles["wizard__body2"]}`}
              >
                <Container>
                  <Form.Group
                    as={Row}
                    className={`${styles["cards_container"]}`}
                  >
                    {templateKeys ? (
                      templateKeys.map((templateKey) => (
                        <Col
                          className={`${styles["card"]} ${styles[templateKey]}`}
                          key={templateKey}
                        >
                          <Form.Check
                            type="radio"
                            id={`template-${templateKey}`}
                            className={`${
                              values.templateKey === templateKey
                                ? styles[containerClassNameSelected]
                                : styles[containerClassName]
                            } ${styles["theme-selector"]}`}
                            onClick={handleChange}
                            style={{
                              backgroundImage: `url(${templateTitles[templateKey].screenshotUrl})`,
                            }}
                          >
                            <Form.Check.Input
                              type="radio"
                              name="templateKey"
                              value={templateKey}
                              checked={values.templateKey === templateKey}
                              onChange={(e: React.ChangeEvent<any>) => {
                                dispatch(
                                  logActivity(
                                    "SET TEMPLATE",
                                    "Page Wizard",
                                    username,
                                    templateKey
                                  )
                                );
                                handleChange(e);
                              }}
                              isValid
                              className={styles["input"]}
                            />
                            <Form.Check.Label
                              className={styles["form-check-label"]}
                            ></Form.Check.Label>
                          </Form.Check>
                          <Form.Check.Label
                            className={styles["form-check-label"]}
                          >
                            <span className={styles["label"]}>
                              {t(templateTitles[templateKey].title)}
                            </span>
                            <span className={styles["label-view"]}>
                              {templateKey === TemplateKey.Blank ? (
                                <>&nbsp;</>
                              ) : (
                                <a
                                  href={templateTitles[templateKey].sampleUrl}
                                  target={"_blank"}
                                  rel="noopener noreferrer"
                                >
                                  <VisibilityRounded /> {t("view_sample")}
                                </a>
                              )}
                            </span>
                          </Form.Check.Label>
                        </Col>
                      ))
                    ) : (
                      <>No Templates available</>
                    )}
                  </Form.Group>
                </Container>
              </Modal.Body>
              <Modal.Footer className={styles["wizard__footer"]}>
                <Button
                  label={
                    values.templateKey === TemplateKey.Blank
                      ? t("next")
                      : "Use Template"
                  }
                  variant="outline-primary"
                  size="medium"
                  icon={ArrowForwardRounded}
                  onClick={handleSubmit}
                  type="button"
                />
                <Button
                  label={t("back_to_admin")}
                  variant="outline-primary"
                  size="medium"
                  icon={ArrowBackRounded}
                  to="/admin"
                  type="button"
                />
              </Modal.Footer>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
