import { Layout } from "common/elements/types";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { HeadingElement } from "editor/elements/base/Heading";
import { LeadElement } from "editor/elements/base/Lead";
import { CardsContainerElement } from "editor/elements/components/CardsContainer/CardsContainer";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { CardsElement } from "editor/elements/sections/Cards/Cards";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { RichTextProperty, RTEAlignmentProperty } from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const authorChild1Template: ElementTemplates = {};

authorChild1Template["cards"] = LayoutEditor.createEmpty()
  .add(
    CardsElement.create({
      cardsStyle: "0",
      backgroundColorProperty: "#000000",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "parent",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(255, 255, 255);">The Opal Trilogy</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("center"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><div><span style="color: rgb(255, 255, 255);">Lorem ipsum dolor sit amet consectetur adipisicing elit.&nbsp;</span></div><div><span style="color: rgb(255, 255, 255);">Quibusdam, voluptatibus commodi alias deserunt.</span></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("center"),
            })
          )
          .build()
      )
      .add(
        CardsContainerElement.create({
          cardsStyle: "0",
          cardsOrientation: "1",
          cardsAlignment: "2",
        })
      )
      .addChildrenArray([
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(
            `<h3><span style="color: rgb(255, 255, 255);">A Diamond Crest</span></h3>`
          ),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty(
            '<div><span style="color: rgb(255, 255, 255);">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae veniam nihil perspiciatis quisquam.</span></div>'
          ),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1594396552580-4ef979578398?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(
            `<h3><span style="color: rgb(255, 255, 255);">An Opal Key</span></h3>`
          ),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty(
            '<div><span style="color: rgb(255, 255, 255);">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae veniam nihil perspiciatis quisquam.</span></div>'
          ),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1433979933652-5e766d98ebb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80",
          "2"
        ),
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(
            `<h3><span style="color: rgb(255, 255, 255);">A Silver Knife</span></h3>`
          ),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty(
            '<div><span style="color: rgb(255, 255, 255);">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae veniam nihil perspiciatis quisquam.</span></div>'
          ),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1594399432819-af8cb403864d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
          "2"
        ),
      ])
      .build()
  )
  .build();

authorChild1Template["cards2"] = LayoutEditor.createEmpty()
  .add(
    CardsElement.create({
      cardsStyle: "0",
      backgroundColorProperty: "#000000",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "parent",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(255, 255, 255);">New Book: Catacombs</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("center"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><div><span style="color: rgb(255, 255, 255);">Lorem ipsum dolor sit amet consectetur adipisicing elit.&nbsp;</span></div><div><span style="color: rgb(255, 255, 255);">Quibusdam, voluptatibus commodi alias deserunt.</span></div></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("center"),
            })
          )
          .build()
      )
      .add(
        CardsContainerElement.create({
          cardsStyle: "0",
          cardsOrientation: "1",
          cardsAlignment: "2",
        })
      )
      .addChildrenArray([
        TemplatesFactory.createDefaultCardNoLink(
          new RichTextProperty(
            `<h3><span style="color: rgb(255, 255, 255);">Spring 2024</span></h3>`
          ),
          new RTEAlignmentProperty("center"),
          new ImageStyleProperty("1"),
          new RichTextProperty(
            '<div><span style="color: rgb(255, 255, 255);">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae veniam nihil perspiciatis quisquam.</span></div>'
          ),
          new RTEAlignmentProperty("center"),
          "https://images.unsplash.com/photo-1519074069444-1ba4fff66d16?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          "2"
        ),
      ])
      .build()
  )
  .build();
