import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type ArticlesProperties = {
  articlesStyle: string;
  width: number;
  backgroundColorProperty: string;
  acrticlesAlignment: string;
  backgroundImageProperty: {
    size: string;
    repeat: string;
    backgroundImageUrl: string;
    positionX: string;
    positionY: string;
  };
};

export function Articles({
  properties: {
    articlesStyle,
    width,
    backgroundColorProperty,
    acrticlesAlignment,
    backgroundImageProperty,
  },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<ArticlesProperties>): JSX.Element {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: backgroundColorProperty,
        backgroundSize:
          backgroundImageProperty.size === "100%"
            ? "cover"
            : backgroundImageProperty.size,
        backgroundRepeat: backgroundImageProperty?.repeat,
        backgroundImage: backgroundImageProperty?.backgroundImageUrl
          ? `url(${backgroundImageProperty?.backgroundImageUrl})`
          : "unset",
        backgroundPositionX: backgroundImageProperty?.positionX,
        backgroundPositionY: backgroundImageProperty?.positionY,
      }}
    >
      <section
        {...props}
        ref={innerRef}
        className={
          className +
          "container jq-articles articles-style-" +
          articlesStyle +
          " section-size-" +
          width +
          " acrticles-alignment-" +
          acrticlesAlignment
        }
      >
        {children}
      </section>
    </div>
  );
}

export const articlesElementKey = "articles";

elementComponentRegistry.register(articlesElementKey, Articles);
