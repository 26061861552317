/* eslint no-sparse-arrays: 0 */
import React, { useEffect, useState } from "react";

import { Button as Toggle, Accordion } from "react-bootstrap";
import styles from "./TestsPropertiesDialog.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { pageDetailsUpdateReset } from "editor/states/pageList";
import {
  Challenge,
  propertySet,
  updateChallengeControl,
  updateChallengeStatus,
  updateChallengeAutoApply,
  ChallengesDisplay,
  Test,
} from "editor/states/layout";
import ToggleButton from "react-toggle-button";
import {
  CheckCircleRounded,
  ExpandMoreRounded,
  KeyboardArrowUpRounded,
  PictureInPictureRounded,
  QuestionAnswerRounded,
  StarRounded,
  SvgIconComponent,
} from "@material-ui/icons";
import { Button } from "editor/components/base/Button/Button";
import { logActivity } from "auth/authActions";
import "./TestPropertiesAccordion.scss";
import {
  getPageChallenges,
  updatePageWithChallengeWinner,
} from "../Editor/editorActions";
import { ButtonStyleProperty } from "editor/properties";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  websiteSlug: string;
  pageSlug: string | null;
  pageContainer: string;
  handleShowTestsPropDialog?: () => void;
  disableApply: boolean;
  isPremiumUser: boolean;
  challenge?: Challenge;
  finishedChallenges?: Challenge[];
  pageTest?: Test;
};

export default function TestPropertiesAccordion({
  pageSlug,
  pageContainer,
  disableApply,
  challenge,
  finishedChallenges,
  pageTest,
  isPremiumUser,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const username = useSelector(getUsername);
  const [pageChallenges, setPageChallenges] = useState(
    new Map<string, ChallengesDisplay>()
  );
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [message, setMessage] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    if (pageSlug && websiteSlug) {
      dispatch(getPageChallenges(pageSlug, websiteSlug));
    }
  }, [dispatch, pageSlug, websiteSlug]);

  useEffect(() => {
    let challengesToDisplayMap: Map<string, ChallengesDisplay> = new Map();
    let finishedChallengesGroupedByElementId: Map<
      string,
      Challenge[]
    > = new Map();
    if (finishedChallenges) {
      finishedChallenges.forEach((finishedChallenge) => {
        let elementId = finishedChallenge.elementId;
        if (elementId) {
          let challenges = finishedChallengesGroupedByElementId.get(elementId);
          if (challenges && challenges?.length > 0) {
            challenges.push(finishedChallenge);
          } else {
            let challenges: Challenge[] = [];
            challenges.push(finishedChallenge);
            finishedChallengesGroupedByElementId.set(elementId, challenges);
          }
        }
      });

      finishedChallengesGroupedByElementId.forEach(
        (finishedChallengesArray, elementId) => {
          finishedChallengesArray.sort((a, b) => b.challengeId - a.challengeId);
          let challengesDisplay: ChallengesDisplay = {
            elementId: elementId,
            finishedChallenges: finishedChallengesArray,
          };
          challengesToDisplayMap.set(elementId, challengesDisplay);
        }
      );
    }

    let currentChallengeNotUpdated = false;
    if (challenge && finishedChallenges) {
      if (
        finishedChallenges.find((o) => o.challengeId === challenge.challengeId)
      ) {
        currentChallengeNotUpdated = true;
      }
    }

    if (challenge && !currentChallengeNotUpdated) {
      let elementId = challenge.elementId;
      if (elementId) {
        let challengeDisplay: ChallengesDisplay;
        if (challengesToDisplayMap.get(elementId)) {
          challengeDisplay = {
            elementId: elementId,
            finishedChallenges: challengesToDisplayMap.get(elementId)
              ?.finishedChallenges,
            currentChallenge: challenge,
          };
        } else {
          challengeDisplay = {
            elementId: elementId,
            currentChallenge: challenge,
          };
        }
        challengesToDisplayMap.set(elementId, challengeDisplay);
      }
    }

    setPageChallenges(challengesToDisplayMap);
  }, [challenge, finishedChallenges]);

  const handleCardExpansion = (): void => {
    isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    dispatch(pageDetailsUpdateReset());
    dispatch(logActivity("DISPLAY TEST DETAIL", pageContainer, username));
  };

  if (!websiteSlug || !pageSlug) {
    return <></>;
  } else if (pageChallenges === undefined || pageChallenges?.size === 0) {
    return (
      <div className={styles["pages-list-empty"]}>
        {t("test_paragraph_1")} <br />
      </div>
    );
  }

  const updateChallenge = (
    challengeId: number,
    elementId: string,
    isActive: boolean
  ) => {
    dispatch(
      updateChallengeStatus(
        challengeId,
        elementId,
        isActive ? false : true,
        () => {
          dispatch(getPageChallenges(pageSlug, websiteSlug));
        }
      )
    );
    if (isActive) {
      dispatch(logActivity("DISABLE TEST", pageContainer, username));
    } else {
      dispatch(logActivity("ENABLE TEST", pageContainer, username));
    }
  };

  const updateChallengeApply = (
    challengeId: number,
    elementId: string,
    isActive: boolean
  ) => {
    dispatch(
      updateChallengeAutoApply(
        challengeId,
        elementId,
        isActive ? false : true,
        () => {
          dispatch(getPageChallenges(pageSlug, websiteSlug));
        }
      )
    );
    if (isActive) {
      dispatch(logActivity("DISABLE AUTO APPLY", pageContainer, username));
    } else {
      dispatch(logActivity("ENABLE  AUTO APPLY", pageContainer, username));
    }
  };

  const handleApplyDesign = (
    challengeId: number,
    pageSlug: string | undefined,
    property?: string | undefined,
    elementId?: string | undefined,
    propertyKey?: string | undefined,
    componentName?: string | undefined
  ) => {
    if (pageSlug && property && elementId && propertyKey && componentName) {
      dispatch(logActivity("APPLY DESIGN WINNER", pageContainer, username));
      dispatch(
        updatePageWithChallengeWinner(
          challengeId,
          pageSlug,
          property,
          elementId
        )
      );
      dispatch(
        updateChallengeControl(elementId, pageSlug, () => {
          dispatch(getPageChallenges(pageSlug, websiteSlug));
        })
      );

      document.getElementById(elementId)?.setAttribute("class", property);
      dispatch(
        propertySet(elementId, propertyKey, new ButtonStyleProperty(property))
      );

      setIsAlertVisible(true);
      setMessage(t("test_paragraph_2") + componentName);
      setTimeout(() => {
        setIsAlertVisible(false);
        setMessage(undefined);
      }, 3000);
    }
  };

  // @ts-ignore
  function parseCSSText(cssText) {
    var cssTxt = cssText.replace(/\/\*(.|\s)*?\*\//g, " ").replace(/\s+/g, " ");
    var style = {},
      [, ruleName, rule] = cssTxt.match(/ ?(.*?) ?{([^}]*)}/) || [, , cssTxt];
    // @ts-ignore
    var cssToJs = (s) =>
      // @ts-ignore
      s.replace(/\W+\w/g, (match) => match.slice(-1).toUpperCase());
    // @ts-ignore
    var properties = rule
      .split(";")
      // @ts-ignore
      .map((o) => o.split(":").map((x) => x && x.trim()));
    // @ts-ignore
    for (var [property, value] of properties) style[cssToJs(property)] = value;
    return { cssText, ruleName, style };
  }

  const DynamicComponent = (
    element: any,
    property: string | undefined,
    pageSlug: string,
    applyCurrent: boolean
  ) => {
    let reactElement: any;
    if (element) {
      const cssObj = parseCSSText(element.style.cssText);

      // eslint-disable-next-line react/no-danger-with-children
      reactElement = React.createElement(
        element.nodeName.toLowerCase(),
        {
          dangerouslySetInnerHTML: {
            __html: element.innerHTML,
          },
          className: property,
          style: applyCurrent
            ? { ...cssObj.style, position: "unset", margin: "unset" }
            : { position: "unset", margin: "unset" },
          id: (Math.random() + 1).toString(36).substring(7),
        },
        null
      );
    } else {
      reactElement = (
        <div className={styles["preview-not-available"]}>
          {t("test_paragraph_3")}
          <span>
            <Link to={`/admin/pages/${pageSlug}/edit`}>{t("Click_Here")}</Link>
          </span>
        </div>
      );
    }

    return reactElement;
  };

  const constructTestEntries = (): JSX.Element[] => {
    const testEntries: JSX.Element[] = [];
    pageChallenges.forEach(
      (challengeToDisplay: ChallengesDisplay, key: string) => {
        let componentName;
        let ComponentIcon: SvgIconComponent = QuestionAnswerRounded;
        let testingElement = document
          .getElementById(challengeToDisplay.elementId)
          ?.cloneNode(true);
        let testStatusSummary = t("test_paragraph_4");

        const currentChallenge = challengeToDisplay.currentChallenge;
        let currentChallengeVariants;
        const finishedChallenges = challengeToDisplay.finishedChallenges;
        const finishedEntries: JSX.Element[] = [];

        if (currentChallenge) {
          currentChallengeVariants = currentChallenge.variants;
          if (
            currentChallengeVariants &&
            currentChallengeVariants?.length > 0
          ) {
            componentName = currentChallengeVariants[0].elementName;
          }
          if (!isPremiumUser && !currentChallenge.isActive) {
            testStatusSummary = t("test_paragraph_5");
          } else {
            if (currentChallenge.isCollecting) {
              testStatusSummary = t("test_paragraph_6");
            } else if (currentChallenge.isTesting) {
              testStatusSummary = t("test_paragraph_7");
            }
          }
        }

        if (finishedChallenges && finishedChallenges?.length > 0) {
          if (
            finishedChallenges[0].variants &&
            finishedChallenges[0].variants.length > 0
          ) {
            componentName = finishedChallenges[0].variants[0].elementName;
          }
          let firstPass = true;
          finishedChallenges.forEach(
            (
              finishedChallenge: Challenge,
              index: number,
              array: Challenge[]
            ) => {
              if (finishedChallenge && finishedChallenge.variants) {
                let variant1 = finishedChallenge.variants[0];
                let variant2 = finishedChallenge.variants[1];
                finishedEntries.push(
                  <div className={styles["finished-container"]}>
                    {!firstPass || currentChallenge ? (
                      <div className={styles["finished-winner-icon"]}>
                        <KeyboardArrowUpRounded />
                      </div>
                    ) : (
                      <div className={styles["finished-champion-icon"]}>
                        <StarRounded />
                      </div>
                    )}
                    <div className={styles["finished-test-container"]}>
                      <div
                        className={`${styles["finished-container"]} 
                  ${
                    firstPass && !currentChallenge
                      ? styles["finished-winner-container"]
                      : ""
                  }`}
                      >
                        <div className={styles["finished-fight"]}>
                          {finishedChallenge.variants &&
                          finishedChallenge.variants.length === 2 ? (
                            <>
                              <div
                                className={`${styles["finished-variant"]} ${
                                  variant1.wasWinner
                                    ? styles["variant-winner"]
                                    : ""
                                }`}
                              >
                                <div
                                  className={styles["finished-variant-icon"]}
                                >
                                  {DynamicComponent(
                                    testingElement,
                                    variant1.property,
                                    finishedChallenge.controlSlug!,
                                    variant1.isMaster
                                      ? variant1.isMaster
                                      : false
                                  )}
                                </div>
                                <div
                                  className={styles["finished-variant-title"]}
                                >
                                  {firstPass && !currentChallenge
                                    ? variant1.wasWinner
                                      ? t("test_champion")
                                      : t("test_loser")
                                    : variant1.wasWinner
                                    ? t("test_loser")
                                    : t("test_loser")}
                                </div>
                                {finishedChallenge &&
                                finishedChallenge.variants &&
                                variant1 &&
                                variant1.elementId &&
                                variant1.property &&
                                variant1.wasWinner &&
                                firstPass &&
                                !currentChallenge &&
                                !finishedChallenge.isAutoApply &&
                                !variant1.wasApplied ? (
                                  disableApply ? (
                                    <div
                                      className={
                                        styles["preview-not-available"]
                                      }
                                    >
                                      {t("test_paragraph_8")}
                                      <span>
                                        <Link
                                          to={`/admin/pages/${pageSlug}/edit`}
                                        >
                                          {t("Click_Here")}
                                        </Link>
                                      </span>
                                    </div>
                                  ) : (
                                    <Button
                                      label={"Apply Design"}
                                      variant="none-outline"
                                      size="small"
                                      isFlat={true}
                                      onClick={() => {
                                        handleApplyDesign(
                                          finishedChallenge.challengeId,
                                          finishedChallenge.controlSlug,
                                          variant1.property,
                                          variant1.elementId,
                                          variant1.componentProperty,
                                          variant1.elementName
                                        );
                                      }}
                                    />
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className={styles["finished-stats"]}>
                                <div className={styles["finished-info"]}>
                                  <div
                                    className={styles["finished-stats-date"]}
                                  >
                                    <span
                                      className={
                                        styles["finished-stats-status-title"]
                                      }
                                    >
                                      {t("test_paragraph_9")} <br />
                                      {finishedChallenge.testActualEndDate}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles["finished-stats-vs"]}>
                                  {t("test_paragraph_10")}
                                </div>
                              </div>
                              <div
                                className={`${styles["finished-variant"]} ${
                                  variant2.wasWinner
                                    ? styles["variant-winner"]
                                    : ""
                                }`}
                              >
                                <div
                                  className={styles["finished-variant-icon"]}
                                >
                                  {DynamicComponent(
                                    testingElement,
                                    variant2.property,
                                    finishedChallenge.controlSlug!,
                                    variant2.isMaster
                                      ? variant2.isMaster
                                      : false
                                  )}
                                </div>
                                <div
                                  className={styles["finished-variant-title"]}
                                >
                                  {firstPass && !currentChallenge
                                    ? variant2.wasWinner
                                      ? t("test_champion")
                                      : t("test_loser")
                                    : variant2.wasWinner
                                    ? t("test_winner")
                                    : t("test_loser")}
                                </div>
                                {finishedChallenge &&
                                finishedChallenge.variants &&
                                variant2 &&
                                variant2.elementId &&
                                variant2.property &&
                                variant2.wasWinner &&
                                firstPass &&
                                !currentChallenge &&
                                !finishedChallenge.isAutoApply &&
                                !variant2.wasApplied ? (
                                  disableApply ? (
                                    <div
                                      className={
                                        styles["preview-not-available"]
                                      }
                                    >
                                      {t("test_paragraph_8")}
                                      <span>
                                        <Link
                                          to={`/admin/pages/${pageSlug}/edit`}
                                        >
                                          {t("Click_Here")}
                                        </Link>
                                      </span>
                                    </div>
                                  ) : (
                                    <Button
                                      label={t("test_paragraph_11")}
                                      variant="none-outline"
                                      size="small"
                                      isFlat={true}
                                      onClick={() => {
                                        handleApplyDesign(
                                          finishedChallenge.challengeId,
                                          finishedChallenge.controlSlug,
                                          variant2.property,
                                          variant2.elementId,
                                          variant2.componentProperty,
                                          variant2.elementName
                                        );
                                      }}
                                    />
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
                if (firstPass) firstPass = false;
              }
            }
          );
        }

        if (!currentChallenge && finishedChallenges) {
          testStatusSummary = t("test_paragraph_12");
        }

        switch (componentName) {
          case "Button":
            ComponentIcon = PictureInPictureRounded;
            break;
        }

        testEntries.push(
          <Accordion className={styles["test-accordion"]}>
            <Accordion.Toggle
              as={Toggle}
              variant="link"
              eventKey={key}
              onClick={handleCardExpansion}
              className={styles["toggle-button"]}
            >
              <div className={styles["test-entry-header"]}>
                <div className={styles["test-entry-component-title-holder"]}>
                  <div className={styles["test-entry-component-icon"]}>
                    <ComponentIcon />
                  </div>
                  <div className={styles["test-entry-component-name"]}>
                    {componentName}
                  </div>
                </div>
                <div className={styles["test-entry-summary"]}>
                  {testStatusSummary}
                  <div className={styles["test-entry-header-toggle"]}>
                    <ExpandMoreRounded />
                  </div>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={key}>
              <div>
                {currentChallenge && currentChallengeVariants ? (
                  <>
                    {!isPremiumUser && !currentChallenge.isActive ? (
                      <div className={styles["test-body-header-container"]}>
                        <div
                          className={`${styles["test-body-header"]} ${styles["test-body-header--warning"]}`}
                        >
                          <div className={styles["header--warning"]}>
                            <span>{t("test_paragraph_13")}</span>
                            {t("test_paragraph_14")}
                          </div>
                          <div>
                            <Link
                              to={"/admin/upgrade"}
                              onClick={() => {
                                dispatch(
                                  logActivity(
                                    "GO TO",
                                    "Reports",
                                    username,
                                    "Upgrade Page"
                                  )
                                );
                              }}
                              className={styles["admin__off-canvas-button"]}
                            >
                              {t("Click_to_Upgrade")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles["test-body-header-container"]}>
                        <div className={styles["test-body-header"]}>
                          {t("test_paragraph_15")}
                          <ToggleButton
                            value={currentChallenge.isActive}
                            onToggle={(value: any) => {
                              updateChallenge(
                                currentChallenge.challengeId,
                                key,
                                value
                              );
                            }}
                          />
                        </div>
                        <div className={styles["test-body-header"]}>
                          {t("Auto_Apply")}
                          <ToggleButton
                            value={currentChallenge.isAutoApply}
                            onToggle={(value: any) => {
                              updateChallengeApply(
                                currentChallenge.challengeId,
                                key,
                                value
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        borderTop: "solid 0.5px #ecf3ff",
                        paddingTop: "12px",
                      }}
                    ></div>
                    {isAlertVisible ? (
                      <div className={styles["alert-container"]}>
                        <div className={styles["alert-divs"]}>
                          <div className={styles["alert-icon"]}>
                            <CheckCircleRounded />
                          </div>
                          <div className={styles["alert-message"]}>
                            {message}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className={styles["current-test-container"]}>
                      <div className={styles["current-container"]}>
                        <div className={styles["current-fight"]}>
                          <div className={styles["current-variant"]}>
                            <div className={styles["current-variant-icon"]}>
                              {DynamicComponent(
                                testingElement,
                                currentChallengeVariants[0].property,
                                currentChallenge.controlSlug!,
                                currentChallengeVariants[0].isMaster
                                  ? currentChallengeVariants[0].isMaster
                                  : false
                              )}
                            </div>
                            <div className={styles["current-variant-title"]}>
                              {t("test_champion")}
                            </div>
                          </div>
                          <div className={styles["current-stats"]}>
                            <div
                              className={styles["current-stats-status"]}
                              style={{ fontWeight: "bold" }}
                            >
                              <span
                                className={styles["current-stats-status-title"]}
                              >
                                {!isPremiumUser &&
                                !currentChallenge.isActive ? (
                                  <>{t("Upgrade_to_Test")}</>
                                ) : (
                                  <>{t("Current_Test")}</>
                                )}
                              </span>
                            </div>
                            <div className={styles["current-stats-vs"]}>VS</div>
                          </div>
                          <div className={styles["current-variant"]}>
                            <div className={styles["current-variant-icon"]}>
                              {DynamicComponent(
                                testingElement,
                                currentChallengeVariants[1].property,
                                currentChallenge.controlSlug!,
                                currentChallengeVariants[1].isMaster
                                  ? currentChallengeVariants[1].isMaster
                                  : false
                              )}
                            </div>
                            <div className={styles["current-variant-title"]}>
                              {t("test_challenger")}
                            </div>
                          </div>
                        </div>
                        <div className={styles["current-info"]}>
                          <div className={styles["current-stats-status"]}>
                            <span
                              className={styles["current-stats-status-title"]}
                            >
                              {t("Current_Step")}
                            </span>
                            {testStatusSummary}
                          </div>
                          <div className={styles["current-stats-date"]}>
                            <span
                              className={styles["current-stats-status-title"]}
                            >
                              {t("End_Date_Step")}
                            </span>
                            {currentChallenge.isTesting
                              ? currentChallenge.targetTestingEndDate
                              : currentChallenge.targetCollectionEndDate}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {finishedEntries}
              </div>
            </Accordion.Collapse>
          </Accordion>
        );
      }
    );

    return testEntries;
  };

  const constructTestEntry = (): JSX.Element[] => {
    const testEntry: JSX.Element[] = [];
    if (pageTest) {
      Object.keys(pageTest).forEach((key) => {
        let challengeToDisplay = pageTest[key];
        let componentName;
        let ComponentIcon: SvgIconComponent = QuestionAnswerRounded;
        let testingElement = document.getElementById(key)?.cloneNode(true);
        let testStatusSummary = t("test_paragraph_16");

        const currentChallenge = challengeToDisplay.currentChallenge;
        let currentChallengeVariants;
        const finishedChallenges = challengeToDisplay.finishedChallenges;
        const finishedEntries: JSX.Element[] = [];

        if (currentChallenge) {
          currentChallengeVariants = currentChallenge.variants;
          if (
            currentChallengeVariants &&
            currentChallengeVariants?.length > 0
          ) {
            componentName = currentChallengeVariants[0].elementName;
          }
          if (!isPremiumUser && !currentChallenge.isActive) {
            testStatusSummary = t("Disabled");
          } else {
            if (currentChallenge.isCollecting) {
              testStatusSummary = t("test_paragraph_6");
            } else if (currentChallenge.isTesting) {
              testStatusSummary = t("test_paragraph_7");
            }
          }
        }

        if (finishedChallenges && finishedChallenges?.length > 0) {
          if (
            finishedChallenges[0].variants &&
            finishedChallenges[0].variants.length > 0
          ) {
            componentName = finishedChallenges[0].variants[0].elementName;
          }

          let firstPass = true;
          let sortedFinishedChallenges = [...finishedChallenges];
          sortedFinishedChallenges.sort(
            (a, b) => b.challengeId - a.challengeId
          );

          sortedFinishedChallenges.forEach(
            (
              finishedChallenge: Challenge,
              index: number,
              array: Challenge[]
            ) => {
              if (finishedChallenge && finishedChallenge.variants) {
                let variant1 = finishedChallenge.variants[0];
                let variant2 = finishedChallenge.variants[1];
                finishedEntries.push(
                  <div className={styles["finished-container"]}>
                    {!firstPass || currentChallenge ? (
                      <div className={styles["finished-winner-icon"]}>
                        <KeyboardArrowUpRounded />
                      </div>
                    ) : (
                      <div className={styles["finished-champion-icon"]}>
                        <StarRounded />
                      </div>
                    )}
                    <div className={styles["finished-test-container"]}>
                      <div
                        className={`${styles["finished-container"]} 
                  ${
                    firstPass && !currentChallenge
                      ? styles["finished-winner-container"]
                      : ""
                  }`}
                      >
                        <div className={styles["finished-fight"]}>
                          {finishedChallenge.variants &&
                          finishedChallenge.variants.length === 2 ? (
                            <>
                              <div
                                className={`${styles["finished-variant"]} ${
                                  variant1.wasWinner
                                    ? styles["variant-winner"]
                                    : ""
                                }`}
                              >
                                <div
                                  className={styles["finished-variant-icon"]}
                                >
                                  {DynamicComponent(
                                    testingElement,
                                    variant1.property,
                                    finishedChallenge.controlSlug!,
                                    variant1.isMaster
                                      ? variant1.isMaster
                                      : false
                                  )}
                                </div>
                                <div
                                  className={styles["finished-variant-title"]}
                                >
                                  {firstPass && !currentChallenge
                                    ? variant1.wasWinner
                                      ? t("test_champion")
                                      : t("test_loser")
                                    : variant1.wasWinner
                                    ? t("test_winner")
                                    : t("test_loser")}
                                </div>
                                {finishedChallenge &&
                                finishedChallenge.variants &&
                                variant1 &&
                                variant1.elementId &&
                                variant1.property &&
                                variant1.wasWinner &&
                                firstPass &&
                                !currentChallenge &&
                                !finishedChallenge.isAutoApply &&
                                !variant1.wasApplied ? (
                                  disableApply ? (
                                    <div
                                      className={
                                        styles["preview-not-available"]
                                      }
                                    >
                                      {t("test_paragraph_8")}
                                      <span>
                                        <Link
                                          to={`/admin/pages/${pageSlug}/edit`}
                                        >
                                          {t("Click_Here")}
                                        </Link>
                                      </span>
                                    </div>
                                  ) : (
                                    <Button
                                      label={t("test_paragraph_11")}
                                      variant="none-outline"
                                      size="small"
                                      isFlat={true}
                                      onClick={() => {
                                        handleApplyDesign(
                                          finishedChallenge.challengeId,
                                          finishedChallenge.controlSlug,
                                          variant1.property,
                                          variant1.elementId,
                                          variant1.componentProperty,
                                          variant1.elementName
                                        );
                                      }}
                                    />
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className={styles["finished-stats"]}>
                                <div className={styles["finished-info"]}>
                                  <div
                                    className={styles["finished-stats-date"]}
                                  >
                                    <span
                                      className={
                                        styles["finished-stats-status-title"]
                                      }
                                    >
                                      {t("test_paragraph_9")} <br />
                                      {finishedChallenge.testActualEndDate}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles["finished-stats-vs"]}>
                                  {t("test_paragraph_10")}
                                </div>
                              </div>
                              <div
                                className={`${styles["finished-variant"]} ${
                                  variant2.wasWinner
                                    ? styles["variant-winner"]
                                    : ""
                                }`}
                              >
                                <div
                                  className={styles["finished-variant-icon"]}
                                >
                                  {DynamicComponent(
                                    testingElement,
                                    variant2.property,
                                    finishedChallenge.controlSlug!,
                                    variant2.isMaster
                                      ? variant2.isMaster
                                      : false
                                  )}
                                </div>
                                <div
                                  className={styles["finished-variant-title"]}
                                >
                                  {firstPass && !currentChallenge
                                    ? variant2.wasWinner
                                      ? t("test_champion")
                                      : t("test_loser")
                                    : variant2.wasWinner
                                    ? t("test_winner")
                                    : t("test_loser")}
                                </div>
                                {finishedChallenge &&
                                finishedChallenge.variants &&
                                variant2 &&
                                variant2.elementId &&
                                variant2.property &&
                                variant2.wasWinner &&
                                firstPass &&
                                !currentChallenge &&
                                !finishedChallenge.isAutoApply &&
                                !variant2.wasApplied ? (
                                  disableApply ? (
                                    <div
                                      className={
                                        styles["preview-not-available"]
                                      }
                                    >
                                      {t("test_paragraph_8")}
                                      <span>
                                        <Link
                                          to={`/admin/pages/${pageSlug}/edit`}
                                        >
                                          {t("Click_Here")}
                                        </Link>
                                      </span>
                                    </div>
                                  ) : (
                                    <Button
                                      label={t("test_paragraph_11")}
                                      variant="none-outline"
                                      size="small"
                                      isFlat={true}
                                      onClick={() => {
                                        handleApplyDesign(
                                          finishedChallenge.challengeId,
                                          finishedChallenge.controlSlug,
                                          variant2.property,
                                          variant2.elementId,
                                          variant2.componentProperty,
                                          variant2.elementName
                                        );
                                      }}
                                    />
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
                if (firstPass) firstPass = false;
              }
            }
          );
        }

        if (!currentChallenge && finishedChallenges) {
          testStatusSummary = "Tests finished";
        }

        switch (componentName) {
          case "Button":
            ComponentIcon = PictureInPictureRounded;
            break;
        }

        testEntry.push(
          <Accordion className={styles["test-accordion"]}>
            <Accordion.Toggle
              as={Toggle}
              variant="link"
              eventKey={key}
              onClick={handleCardExpansion}
              className={styles["toggle-button"]}
            >
              <div className={styles["test-entry-header"]}>
                <div className={styles["test-entry-component-title-holder"]}>
                  <div className={styles["test-entry-component-icon"]}>
                    <ComponentIcon />
                  </div>
                  <div className={styles["test-entry-component-name"]}>
                    {componentName}
                  </div>
                </div>
                <div className={styles["test-entry-summary"]}>
                  {testStatusSummary}
                  <div className={styles["test-entry-header-toggle"]}>
                    <ExpandMoreRounded />
                  </div>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={key}>
              <div>
                {currentChallenge && currentChallengeVariants ? (
                  <>
                    {!isPremiumUser && !currentChallenge.isActive ? (
                      <div className={styles["test-body-header-container"]}>
                        <div
                          className={`${styles["test-body-header"]} ${styles["test-body-header--warning"]}`}
                        >
                          <div className={styles["header--warning"]}>
                            <span>{t("test_paragraph_13")}</span>
                            {t("test_paragraph_14")}
                          </div>
                          <div>
                            <Link
                              to={"/admin/upgrade"}
                              onClick={() => {
                                dispatch(
                                  logActivity(
                                    "GO TO",
                                    "Reports",
                                    username,
                                    "Upgrade Page"
                                  )
                                );
                              }}
                              className={styles["admin__off-canvas-button"]}
                            >
                              {t("Click_to_Upgrade")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles["test-body-header-container"]}>
                        <div className={styles["test-body-header"]}>
                          {t("test_paragraph_15")}
                          <ToggleButton
                            value={currentChallenge.isActive}
                            onToggle={(value: any) => {
                              updateChallenge(
                                currentChallenge.challengeId,
                                key,
                                value
                              );
                            }}
                          />
                        </div>
                        <div className={styles["test-body-header"]}>
                          {t("Auto_Apply")}
                          <ToggleButton
                            value={currentChallenge.isAutoApply}
                            onToggle={(value: any) => {
                              updateChallengeApply(
                                currentChallenge.challengeId,
                                key,
                                value
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        borderTop: "solid 0.5px #ecf3ff",
                        paddingTop: "12px",
                      }}
                    ></div>
                    {isAlertVisible ? (
                      <div className={styles["alert-container"]}>
                        <div className={styles["alert-divs"]}>
                          <div className={styles["alert-icon"]}>
                            <CheckCircleRounded />
                          </div>
                          <div className={styles["alert-message"]}>
                            {message}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className={styles["current-test-container"]}>
                      <div className={styles["current-container"]}>
                        <div className={styles["current-fight"]}>
                          <div className={styles["current-variant"]}>
                            <div className={styles["current-variant-icon"]}>
                              {DynamicComponent(
                                testingElement,
                                currentChallengeVariants[0].property,
                                currentChallenge.controlSlug!,
                                currentChallengeVariants[0].isMaster
                                  ? currentChallengeVariants[0].isMaster
                                  : false
                              )}
                            </div>
                            <div className={styles["current-variant-title"]}>
                              {t("test_champion")}
                            </div>
                          </div>
                          <div className={styles["current-stats"]}>
                            <div
                              className={styles["current-stats-status"]}
                              style={{ fontWeight: "bold" }}
                            >
                              <span
                                className={styles["current-stats-status-title"]}
                              >
                                {!isPremiumUser &&
                                !currentChallenge.isActive ? (
                                  <>{t("Upgrade_to_Test")}</>
                                ) : (
                                  <>{t("Current_Test")}</>
                                )}
                              </span>
                            </div>
                            <div className={styles["current-stats-vs"]}>VS</div>
                          </div>
                          <div className={styles["current-variant"]}>
                            <div className={styles["current-variant-icon"]}>
                              {DynamicComponent(
                                testingElement,
                                currentChallengeVariants[1].property,
                                currentChallenge.controlSlug!,
                                currentChallengeVariants[1].isMaster
                                  ? currentChallengeVariants[1].isMaster
                                  : false
                              )}
                            </div>
                            <div className={styles["current-variant-title"]}>
                              {t("test_challenger")}
                            </div>
                          </div>
                        </div>
                        <div className={styles["current-info"]}>
                          <div className={styles["current-stats-status"]}>
                            <span
                              className={styles["current-stats-status-title"]}
                            >
                              {t("Current_Step")}
                            </span>
                            {testStatusSummary}
                          </div>
                          <div className={styles["current-stats-date"]}>
                            <span
                              className={styles["current-stats-status-title"]}
                            >
                              {t("End_Date_Step")}
                            </span>
                            {currentChallenge.isTesting
                              ? currentChallenge.targetTestingEndDate
                              : currentChallenge.targetCollectionEndDate}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {finishedEntries}
              </div>
            </Accordion.Collapse>
          </Accordion>
        );
      });
    }
    return testEntry;
  };

  return (
    <>
      <div className={styles["pages-list"]}>
        <div className={styles["accordions-holder"]}>
          {pageTest ? constructTestEntry() : constructTestEntries()}
        </div>
      </div>
    </>
  );
}
