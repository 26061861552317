import {
  ArrowForwardRounded,
  CancelOutlined,
  CheckRounded,
  CreateRounded,
  OpenInNewRounded,
  PriorityHighRounded,
  PublicRounded,
  RefreshRounded,
  SaveRounded,
  SvgIconComponent,
} from "@material-ui/icons";
import { Formik, Form } from "formik";
import { FormControl, InputGroup } from "react-bootstrap";
import { Button } from "../../base/Button/Button";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPageSlug } from "./../../../selectors";
import { Dialog } from "../../components/Dialog/Dialog";
import {
  getS3config,
  getWebsite,
  getWebsitePublishState,
} from "editor/states/selectors";
import * as yup from "yup";
import styles from "./PublishWebsiteDialog.module.scss";
import ReservedSubdomains from "reserved-subdomains";
import { websiteDetailsUpdated } from "editor/states/website";
import produce from "immer";
import { editorPage } from "../Editor/Editor";
import {
  logActivity,
  resendEmailVerify,
  getUserProvider as getProvider,
} from "auth/authActions";
import { updateDirWithNewDomain } from "admin/S3Client";
import {
  getIsUserEmailVerified,
  getUsername,
  getUserProvider,
} from "auth/selectors";
import { useTranslation } from "react-i18next";

type Props = {
  showWebsitePublishDialog: boolean;
  handleShowWebsitePublishPropDialog: () => void;
  toolBarButtonRect?: DOMRect;
  handlePublish: () => void;
  cssPos?: React.CSSProperties;
};

export default function PublishWebsiteDialog({
  showWebsitePublishDialog,
  handleShowWebsitePublishPropDialog,
  toolBarButtonRect,
  handlePublish,
  cssPos,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const website = useSelector(getWebsite);
  const publishState = useSelector(getWebsitePublishState);
  const pageSlug = useSelector(getPageSlug);
  const s3ConfigState = useSelector(getS3config);
  const isUserVerified = useSelector(getIsUserEmailVerified);
  const username = useSelector(getUsername);
  const provider = useSelector(getUserProvider);
  const [isEmailResentRequested, setIsEmailResentRequested] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState(website.slug);
  const [websiteUrlError, setWebsiteUrlError] = useState<String>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Publish");
  const [isLoading, setIsLoading] = useState(false);
  const [parentActionIcon, setParentActionIcon] = useState<SvgIconComponent>(
    ArrowForwardRounded
  );
  const [
    parentActionButtonClassName,
    setParentActionButtonClassName,
  ] = useState("primary");
  const [parentActionButtonFlat, setParentActionButtonFlat] = useState(false);
  const [isEmailResent, setIsEmailResent] = useState<undefined | boolean>(
    undefined
  );
  const [isEmailVerified, setIsEmailVerified] = useState<undefined | boolean>(
    undefined
  );

  useEffect(() => {
    if (website) {
      setWebsiteUrl(website.slug);
    }
    if (provider === undefined) {
      dispatch(getProvider());
    }
    if (provider && provider !== "local") {
      if (publishState === "loading") {
        setButtonLabel(t("publish_dialog_1"));
        setIsDisabled(true);
        setParentActionIcon(RefreshRounded);
        setParentActionButtonClassName("disabled");
        setParentActionButtonFlat(true);
        setIsLoading(true);
      } else if (publishState === "publishing") {
        setButtonLabel(t("publish_dialog_2"));
        setIsDisabled(true);
        setParentActionIcon(RefreshRounded);
        setParentActionButtonClassName("disabled");
        setIsLoading(true);
        setParentActionButtonFlat(true);
      } else if (publishState === "success") {
        setButtonLabel(t("publish_dialog_3"));
        setParentActionIcon(CheckRounded);
        setParentActionButtonClassName("success");
        setIsDisabled(true);
        setIsLoading(false);
        setParentActionButtonFlat(true);
      } else if (publishState === "fail") {
        setButtonLabel(t("publish_dialog_4"));
        setParentActionButtonClassName("danger");
        setParentActionIcon(PriorityHighRounded);
        setIsDisabled(false);
        setIsLoading(false);
        setParentActionButtonFlat(false);
      } else {
        setButtonLabel(t("publish_dialog_5"));
        setParentActionButtonClassName("primary");
        setParentActionIcon(ArrowForwardRounded);
        setIsDisabled(false);
        setIsLoading(false);
        setParentActionButtonFlat(false);
      }
    } else {
      if (isUserVerified) {
        if (publishState === "loading") {
          setButtonLabel(t("publish_dialog_1"));
          setIsDisabled(true);
          setParentActionIcon(RefreshRounded);
          setParentActionButtonClassName("disabled");
          setParentActionButtonFlat(true);
          setIsLoading(true);
        } else if (publishState === "publishing") {
          setButtonLabel(t("publish_dialog_2"));
          setIsDisabled(true);
          setParentActionIcon(RefreshRounded);
          setParentActionButtonClassName("disabled");
          setIsLoading(true);
          setParentActionButtonFlat(true);
        } else if (publishState === "success") {
          setButtonLabel(t("publish_dialog_3"));
          setParentActionIcon(CheckRounded);
          setParentActionButtonClassName("success");
          setIsDisabled(true);
          setIsLoading(false);
          setParentActionButtonFlat(true);
        } else if (publishState === "fail") {
          setButtonLabel(t("publish_dialog_4"));
          setParentActionButtonClassName("danger");
          setParentActionIcon(PriorityHighRounded);
          setIsDisabled(false);
          setIsLoading(false);
          setParentActionButtonFlat(false);
        } else {
          setButtonLabel(t("publish_dialog_5"));
          setParentActionButtonClassName("primary");
          setParentActionIcon(ArrowForwardRounded);
          setIsDisabled(false);
          setIsLoading(false);
          setParentActionButtonFlat(false);
        }
      } else {
        setIsDisabled(true);
      }
    }
  }, [dispatch, website, publishState, provider, isUserVerified, t]);

  useEffect(() => {
    if (!isUserVerified !== undefined && provider) {
      if (provider === "local") {
        setIsEmailVerified(isUserVerified);
        if (!isUserVerified) {
          setParentActionButtonClassName("disabled");
        }
      } else {
        setIsEmailVerified(isUserVerified);
      }
    }
  }, [isUserVerified, provider]);

  const lastPublished = website.publishedAt
    ? t("publish_dialog_6") +
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(website.publishedAt!)
    : t("publish_dialog_7");

  const schema = yup.object({
    title: yup.string().required(t("publish_dialog_8")),
  });
  const tldjs = require("tldjs");
  const { getDomain } = tldjs;
  const publishedDomain =
    websiteUrl +
    "." +
    getDomain(document.domain) +
    (window.location.port ? ":" + window.location.port : "");

  const handleUpdate = () => {
    let isNotValid = false;

    if (!websiteUrl || !websiteUrl.replace(/\s/g, "").length) {
      setWebsiteUrlError(t("publish_dialog_9"));
      isNotValid = true;
    }
    if (ReservedSubdomains.isNotValid(websiteUrl)) {
      setWebsiteUrlError(t("publish_dialog_10"));
      isNotValid = true;
    }
    if (!isNotValid) {
      dispatch(
        websiteDetailsUpdated(
          website.slug,
          produce(website, (draft) => {
            draft.category = website.category;
            draft.title = website.title;
            draft.slug = websiteUrl;
            draft.widthGuide = website.widthGuide;
          })
        )
      );

      dispatch(logActivity("UPDATE WEBSITE DOMAIN", editorPage, username));
      updateDirWithNewDomain(website.slug, websiteUrl, s3ConfigState);
      setShowInput(false);
    }
  };

  const handleUrlUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setWebsiteUrl(event.target.value);
    setWebsiteUrlError("");
  };

  const handleEmailVerify = () => {
    if (username) {
      dispatch(resendEmailVerify(username, handleEmailResent));
      setIsEmailResentRequested(true);
    }
  };

  const handleEmailResent = (isResent: boolean) => {
    setIsEmailResent(isResent);
  };

  const PublishWebsiteDialogBody = (
    <>
      <div className={styles["publish-dialog-body"]}>
        {!isEmailVerified ? (
          <div className={styles["publish-dialog-body-notice"]}>
            <h5>{t("publish_dialog_11")}</h5>
            <div>{t("publish_dialog_12")}</div>
            <div>
              {isEmailResent ? (
                <>{t("publish_dialog_13")}</>
              ) : (
                <>
                  {t("publish_dialog_14")}{" "}
                  {isEmailResentRequested ? (
                    <span>&nbsp;{t("publish_dialog_15")}</span>
                  ) : (
                    <span onClick={handleEmailVerify}>
                      {t("publish_dialog_16")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <h5>{t("publish_dialog_17")}</h5>
            {showInput ? (
              <div>
                <Formik
                  validationSchema={schema}
                  onSubmit={handleUpdate}
                  initialValues={{
                    url: websiteUrl,
                  }}
                >
                  <Form>
                    <InputGroup className={styles["publish-dialog-input"]}>
                      <FormControl
                        placeholder="URL"
                        aria-label="URL"
                        aria-describedby="basic-addon2"
                        value={websiteUrl}
                        onChange={handleUrlUpdate}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">
                          {getDomain(document.domain) +
                            (window.location.port
                              ? ":" + window.location.port
                              : "")}
                        </InputGroup.Text>
                        <Button
                          variant="outline-primary"
                          size="circle"
                          icon={CancelOutlined}
                          style={{
                            padding: "6px",
                            border: "none",
                            marginRight: "4px",
                            marginLeft: "4px",
                            boxShadow: "none",
                          }}
                          onClick={() => {
                            setIsDisabled(false);
                            setShowInput(false);
                            setParentActionButtonClassName("primary");
                          }}
                        />
                        <Button
                          variant="primary"
                          icon={SaveRounded}
                          size="circle"
                          onClick={handleUpdate}
                          style={{ padding: "6px" }}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form>
                </Formik>
                <p className={styles["website-slug-danger"]}>
                  {websiteUrlError}
                </p>
              </div>
            ) : (
              <div className={styles["website-slug"]}>
                {website.publishedAt ? (
                  <>
                    <a
                      href={`//${publishedDomain}/${pageSlug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {publishedDomain}
                    </a>
                    <a
                      href={`//${publishedDomain}/${pageSlug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <OpenInNewRounded />
                    </a>
                  </>
                ) : (
                  <span className={styles["disabled"]}>{publishedDomain}</span>
                )}
                <CreateRounded
                  onClick={() => {
                    setIsDisabled(true);
                    setShowInput(true);
                    setParentActionButtonClassName("disabled");
                  }}
                />
              </div>
            )}
            <span role="img" aria-label="Note">
              📌
            </span>
            &nbsp;<span>{lastPublished}</span>
            <br />
            <span role="img" aria-label="Note">
              📌
            </span>
            &nbsp;<span>{t("publish_dialog_18")}</span>
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      <Dialog
        dialogBody={PublishWebsiteDialogBody}
        icon={PublicRounded}
        headerLabel={t("editor_area_publish")}
        handleShowDialog={handleShowWebsitePublishPropDialog}
        size="medium"
        isShowDialog={showWebsitePublishDialog}
        isGrabbable={false}
        elementPos={toolBarButtonRect}
        isDisabled={isDisabled}
        isShowBg
        parentActionLabel={buttonLabel}
        parentActionButtonVariant={parentActionButtonClassName}
        parentActionIcon={parentActionIcon}
        handleParentAction={handlePublish}
        isLoading={isLoading}
        isParentActionButtonFlat={parentActionButtonFlat}
        cssPosition={cssPos}
      />
    </>
  );
}
