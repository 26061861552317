import { TemplateKey } from "../../../templates/types";
import { PositionString } from "../../../types";
import { Layout } from "common/elements/types";
import { Challenge } from "editor/states/layout";
import { DeletePageSuccessAction } from "admin/pageActions";
import { PageVersion } from "editor/models/types";
import { LogoutSuccessAction } from "auth/authActions";
import {
  PurposeContact,
  PurposeLocation,
  PurposeReview,
  PurposeSocial,
  PurposeTeam,
  PurposeWorkServicesProduct,
} from "admin/Setup";

export const TRACK_FAKE_DOOR = "TRACK_FAKE_DOOR";

export type TrackFakeDoorAction = {
  type: typeof TRACK_FAKE_DOOR;
  websiteSlug: string;
  pageSlug: string | undefined;
  doorName: string;
};

export const trackFakeDoor = (
  websiteSlug: string,
  pageSlug: string | undefined,
  doorName: string
): Action => {
  return {
    type: TRACK_FAKE_DOOR,
    websiteSlug,
    pageSlug,
    doorName,
  };
};

export const EDITOR_LOAD_OTHER_PAGES = "EDITOR_LOAD_OTHER_PAGES";

export type LoadOtherPagesAction = {
  type: typeof EDITOR_LOAD_OTHER_PAGES;
  pageSlug: string;
};

export const loadOtherPages = (pageSlug: string): Action => {
  return {
    type: EDITOR_LOAD_OTHER_PAGES,
    pageSlug,
  };
};

export const EDITOR_LOAD_OTHER_PAGES_SUCCESS =
  "EDITOR_LOAD_OTHER_PAGES_SUCCESS";

export type LoadOtherPagesSuccessAction = {
  type: typeof EDITOR_LOAD_OTHER_PAGES_SUCCESS;
  otherPages: PageVersion[];
};

export const loadOtherPagesSuccess = (otherPages: PageVersion[]): Action => {
  return {
    type: EDITOR_LOAD_OTHER_PAGES_SUCCESS,
    otherPages,
  };
};

export const EDITOR_SAVE_OTHER_PAGES = "EDITOR_SAVE_OTHER_PAGES";

export type SaveOtherPagesAction = {
  type: typeof EDITOR_SAVE_OTHER_PAGES;
  sharedLayout: Layout;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
};

export const saveSiblingPages = (
  sharedLayout: Layout,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string
): Action => {
  return {
    type: EDITOR_SAVE_OTHER_PAGES,
    sharedLayout,
    primaryFont,
    secondaryFont,
    primaryColor,
    secondaryColor,
    ternaryColor,
    colorType,
    primaryRadius,
    secondaryRadius,
    primaryShadow,
  };
};

export const EDITOR_CREATE_OTHER_PAGES_UPDATE_REUSABLES =
  "EDITOR_CREATE_OTHER_PAGES_UPDATE_REUSABLES";

export type CreateOtherPagesUpdateReusables = {
  type: typeof EDITOR_CREATE_OTHER_PAGES_UPDATE_REUSABLES;
  pageId: string;
  slug: string;
  version: string;
  layout: Layout;
};

export const createOtherPagesUpdateReusables = (
  pageId: string,
  slug: string,
  version: string,
  layout: Layout
): Action => {
  return {
    type: EDITOR_CREATE_OTHER_PAGES_UPDATE_REUSABLES,
    pageId,
    slug,
    version,
    layout,
  };
};

export const EDITOR_PAGE_CREATE_DETAILS = "EDITOR_PAGE_CREATE_DETAILS";

export type PageCreateDetailsAction = {
  type: typeof EDITOR_PAGE_CREATE_DETAILS;
  websiteSlug: string;
  isPurposeContact: boolean | undefined;
  isPurposeWorkServices: boolean | undefined;
  isPurposeProduct: boolean | undefined;
  isPurposeLocation: boolean | undefined;
  isPurposeOther: boolean | undefined;
  isPurposeIncreaseSocial: boolean | undefined;
  isPurposeIntroduceTeam: boolean | undefined;
  isPurposeReview: boolean | undefined;
  contact: PurposeContact | undefined;
  services: PurposeWorkServicesProduct[] | undefined;
  products: PurposeWorkServicesProduct[] | undefined;
  location: PurposeLocation | undefined;
  social: PurposeSocial | undefined;
  team: PurposeTeam | undefined;
  reviews: PurposeReview[] | undefined;
  purposeName: string | undefined;
  purposeDescription: string | undefined;
  isDesignLogo: boolean | undefined;
  isDesignColors: boolean | undefined;
};

export const pageCreateDetails = (
  websiteSlug: string,
  isPurposeContact: boolean | undefined,
  isPurposeWorkServices: boolean | undefined,
  isPurposeProduct: boolean | undefined,
  isPurposeLocation: boolean | undefined,
  isPurposeOther: boolean | undefined,
  isPurposeIncreaseSocial: boolean | undefined,
  isPurposeIntroduceTeam: boolean | undefined,
  isPurposeReview: boolean | undefined,
  contact: PurposeContact | undefined,
  services: PurposeWorkServicesProduct[] | undefined,
  products: PurposeWorkServicesProduct[] | undefined,
  location: PurposeLocation | undefined,
  social: PurposeSocial | undefined,
  team: PurposeTeam | undefined,
  reviews: PurposeReview[] | undefined,
  purposeName: string | undefined,
  purposeDescription: string | undefined,
  isDesignLogo: boolean | undefined,
  isDesignColors: boolean | undefined
): Action => {
  return {
    type: EDITOR_PAGE_CREATE_DETAILS,
    websiteSlug,
    isPurposeContact,
    isPurposeWorkServices,
    isPurposeProduct,
    isPurposeLocation,
    isPurposeOther,
    isPurposeIncreaseSocial,
    isPurposeIntroduceTeam,
    isPurposeReview,
    contact,
    services,
    products,
    location,
    social,
    team,
    reviews,
    purposeName,
    purposeDescription,
    isDesignLogo,
    isDesignColors,
  };
};

export const EDITOR_PAGE_CREATED_FROM_TEMPLATE =
  "EDITOR_PAGE_CREATED_FROM_TEMPLATE";

export type PageCreatedFromTemplateAction = {
  type: typeof EDITOR_PAGE_CREATED_FROM_TEMPLATE;
  websiteSlug: string;
  title: string;
  description: string;
  templateKey: TemplateKey;
  isIndex: boolean;
  callback: (pageSlug: string, layout: Layout) => void;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
  isMultiPage: boolean;
  isLogoCreated: boolean;
  pageSlug: string;
};

export const pageCreatedFromTemplate = (
  websiteSlug: string,
  title: string,
  description: string,
  templateKey: TemplateKey,
  isIndex: boolean,
  callback: (pageSlug: string, layout: Layout) => void,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  isMultiPage: boolean,
  isLogoCreated: boolean,
  pageSlug: string
): Action => {
  return {
    type: EDITOR_PAGE_CREATED_FROM_TEMPLATE,
    websiteSlug,
    title,
    description,
    templateKey,
    isIndex,
    callback,
    primaryFont,
    secondaryFont,
    primaryColor,
    secondaryColor,
    ternaryColor,
    colorType,
    primaryRadius,
    secondaryRadius,
    primaryShadow,
    isMultiPage,
    isLogoCreated,
    pageSlug,
  };
};

export const EDITOR_CREATE_CHILD_PAGES_FROM_TEMPLATE =
  "EDITOR_CREATE_CHILD_PAGES_FROM_TEMPLATE";

export type CreateChildPagesFromTemplateAction = {
  type: typeof EDITOR_CREATE_CHILD_PAGES_FROM_TEMPLATE;
  websiteSlug: string;
  title: string;
  description: string;
  templateKey: TemplateKey;
  isIndex: boolean;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
  pageSlug: string;
  callback: (websiteSlug: string) => void;
  isLogoCreated: boolean;
};

export const createChildPagesFromTemplate = (
  websiteSlug: string,
  title: string,
  description: string,
  templateKey: TemplateKey,
  isIndex: boolean,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  pageSlug: string,
  callback: (websiteSlug: string) => void,
  isLogoCreated: boolean
): Action => {
  return {
    type: EDITOR_CREATE_CHILD_PAGES_FROM_TEMPLATE,
    websiteSlug,
    title,
    description,
    templateKey,
    isIndex,
    primaryFont,
    secondaryFont,
    primaryColor,
    secondaryColor,
    ternaryColor,
    colorType,
    primaryRadius,
    secondaryRadius,
    primaryShadow,
    pageSlug,
    callback,
    isLogoCreated,
  };
};

export const EDITOR_PAGE_CREATION_FROM_TEMPLATE_SUCCEEDED =
  "EDITOR_PAGE_CREATION_FROM_TEMPLATE_SUCCEEDED";

export type PageCreationFromTemplateSucceededAction = {
  type: typeof EDITOR_PAGE_CREATION_FROM_TEMPLATE_SUCCEEDED;
  id: number;
  pageSlug: string;
  pageUniqueId: string;
  version: number;
  layout: Layout;
};

export const pageCreationFromTemplateSucceeded = (
  id: number,
  pageSlug: string,
  pageUniqueId: string,
  version: number,
  layout: Layout
): Action => {
  return {
    type: EDITOR_PAGE_CREATION_FROM_TEMPLATE_SUCCEEDED,
    id,
    pageSlug,
    pageUniqueId,
    version,
    layout,
  };
};

export const EDITOR_PAGE_SAVE_SUCCEEDED = "EDITOR_PAGE_SAVE_SUCCEEDED";

export type PageSaveSucceededAction = {
  type: typeof EDITOR_PAGE_SAVE_SUCCEEDED;
  pageSlug: string;
  websiteSlug: string;
  layout: Layout;
  updatedLayout: boolean;
  title: string;
  description: string;
  isFromTemplate: boolean;
};

export const pageSaveSucceeded = (
  pageSlug: string,
  websiteSlug: string,
  layout: Layout,
  updatedLayout: boolean,
  title: string,
  description: string,
  isFromTemplate: boolean
): Action => {
  return {
    type: EDITOR_PAGE_SAVE_SUCCEEDED,
    pageSlug,
    websiteSlug,
    layout,
    updatedLayout,
    title,
    description,
    isFromTemplate,
  };
};

export const EDITOR_PAGE_CREATION_FROM_TEMPLATE_TREATMENT_SUCCEEDED =
  "EDITOR_PAGE_CREATION_FROM_TEMPLATE_TREATMENT_SUCCEEDED";

export type PageCreationFromTemplateTreatmentSucceededAction = {
  type: typeof EDITOR_PAGE_CREATION_FROM_TEMPLATE_TREATMENT_SUCCEEDED;
  id: number;
  pageSlug: string;
  version: number;
  masterSlug: string;
};

export const pageCreationFromTemplateTreatmentSucceeded = (
  id: number,
  pageSlug: string,
  version: number,
  masterSlug: string
): Action => {
  return {
    type: EDITOR_PAGE_CREATION_FROM_TEMPLATE_TREATMENT_SUCCEEDED,
    id,
    pageSlug,
    version,
    masterSlug,
  };
};

export const EDITOR_SAVE_CHALLENGE = "EDITOR_SAVE_CHALLENGE";

export type SaveChallengeAction = {
  type: typeof EDITOR_SAVE_CHALLENGE;
  controlSlug: string;
  treatmentSlug: string;
  layout: Layout;
};

export const saveChallenge = (
  controlSlug: string,
  treatmentSlug: string,
  layout: Layout
): Action => {
  return {
    type: EDITOR_SAVE_CHALLENGE,
    controlSlug,
    treatmentSlug,
    layout,
  };
};

export const EDITOR_SAVE_CHALLENGE_VARIANTS = "EDITOR_SAVE_CHALLENGE";

export type SaveChallengeVariantsAction = {
  type: typeof EDITOR_SAVE_CHALLENGE_VARIANTS;
};

export const saveChallengeVariants = (): Action => {
  return {
    type: EDITOR_SAVE_CHALLENGE_VARIANTS,
  };
};

export const EDITOR_PAGE_CREATION_FROM_TEMPLATE_FAILED =
  "EDITOR_PAGE_CREATION_FROM_TEMPLATE_FAILED";

export type PageCreationFromTemplateFailedAction = {
  type: typeof EDITOR_PAGE_CREATION_FROM_TEMPLATE_FAILED;
};

export const pageCreationFromTemplateFailed = (): Action => {
  return {
    type: EDITOR_PAGE_CREATION_FROM_TEMPLATE_FAILED,
  };
};

export const EDITOR_PAGE_LATEST_VERSION_LOADED =
  "EDITOR_PAGE_LATEST_VERSION_LOADED";

export type PageLatestVersionLoadedAction = {
  type: typeof EDITOR_PAGE_LATEST_VERSION_LOADED;
  websiteSlug: string;
  pageSlug: string;
};

export const pageLatestVersionLoaded = (
  websiteSlug: string,
  pageSlug: string
): Action => {
  return {
    type: EDITOR_PAGE_LATEST_VERSION_LOADED,
    websiteSlug,
    pageSlug,
  };
};

export const EDITOR_PAGE_LATEST_VERSION_LOAD_SUCCEEDED =
  "EDITOR_PAGE_LATEST_VERSION_LOAD_SUCCEEDED";

export type PageLatestVersionLoadSucceededAction = {
  type: typeof EDITOR_PAGE_LATEST_VERSION_LOAD_SUCCEEDED;
  pageId: number;
  pageSlug: string;
  templateKey: TemplateKey;
  title: string;
  description: string;
  isIndex: boolean;
  layout: Layout;
  version: number;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
  treatmentSlug: string;
  pageUniqueId: string;
};

export const pageLatestVersionLoadSucceeded = (
  pageId: number,
  pageSlug: string,
  templateKey: TemplateKey,
  title: string,
  description: string,
  isIndex: boolean,
  layout: Layout,
  version: number,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  treatmentSlug: string,
  pageUniqueId: string
): Action => {
  return {
    type: EDITOR_PAGE_LATEST_VERSION_LOAD_SUCCEEDED,
    pageId,
    pageSlug,
    templateKey,
    title,
    description,
    isIndex,
    layout,
    version,
    primaryFont,
    secondaryFont,
    primaryColor,
    secondaryColor,
    ternaryColor,
    colorType,
    primaryRadius,
    secondaryRadius,
    primaryShadow,
    treatmentSlug,
    pageUniqueId,
  };
};

export const EDITOR_TREATMENT_PAGE_LATEST_VERSION_LOAD_SUCCEEDED =
  "EDITOR_TREATMENT_PAGE_LATEST_VERSION_LOAD_SUCCEEDED";

export type TreatmentPageLatestVersionLoadSucceededAction = {
  type: typeof EDITOR_TREATMENT_PAGE_LATEST_VERSION_LOAD_SUCCEEDED;
  slug: string;
  layout: Layout;
};

export const treatmentPageLatestVersionLoadSucceeded = (
  slug: string,
  layout: Layout
): Action => {
  return {
    type: EDITOR_TREATMENT_PAGE_LATEST_VERSION_LOAD_SUCCEEDED,
    slug,
    layout,
  };
};

export const EDITOR_PAGE_LATEST_VERSION_LOAD_FAILED =
  "EDITOR_PAGE_LATEST_VERSION_LOAD_FAILED";

export type PageLatestVersionLoadFailedAction = {
  type: typeof EDITOR_PAGE_LATEST_VERSION_LOAD_FAILED;
};

export const pageLatestVersionLoadFailed = (): Action => {
  return {
    type: EDITOR_PAGE_LATEST_VERSION_LOAD_FAILED,
  };
};

export const EDITOR_SAVE_PAGE_REQUEST = "EDITOR_SAVE_PAGE_REQUEST";

export type SavePageRequestAction = {
  type: typeof EDITOR_SAVE_PAGE_REQUEST;
  websiteSlug: string;
  pageSlug: string;
  changedElementId: string | null;
  isDelete: boolean;
};

export const savePage = (
  websiteSlug: string,
  pageSlug: string,
  changedElementId: string | null,
  isDelete: boolean
): Action => {
  return {
    type: EDITOR_SAVE_PAGE_REQUEST,
    websiteSlug,
    pageSlug,
    changedElementId,
    isDelete,
  };
};

export const EDITOR_SAVE_PAGE_SUCCESS = "EDITOR_SAVE_PAGE_SUCCESS";

export type SavePageSuccessAction = {
  type: typeof EDITOR_SAVE_PAGE_SUCCESS;
  version: number;
};

export const savePageSuccess = (version: number): Action => {
  return {
    type: EDITOR_SAVE_PAGE_SUCCESS,
    version,
  };
};

export const EDITOR_SAVE_PAGE_FAILURE = "EDITOR_SAVE_PAGE_FAILURE";

export type SavePageFailureAction = {
  type: typeof EDITOR_SAVE_PAGE_FAILURE;
};

export const savePageFailure = (): Action => {
  return {
    type: EDITOR_SAVE_PAGE_FAILURE,
  };
};

export const EDITOR_CURRENT_PAGE_DETAILS_UPDATE_SUCCEEDED =
  "EDITOR_CURRENT_PAGE_DETAILS_UPDATE_SUCCEEDED";

export type CurrentPageDetailsUpdateSucceededAction = {
  type: typeof EDITOR_CURRENT_PAGE_DETAILS_UPDATE_SUCCEEDED;
  pageId: number;
  title: string;
  description: string;
  version: number;
  isIndex: boolean;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
};

export const currentPageDetailsUpdateSucceeded = (
  pageId: number,
  title: string,
  description: string,
  version: number,
  isIndex: boolean,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string
): Action => {
  return {
    type: EDITOR_CURRENT_PAGE_DETAILS_UPDATE_SUCCEEDED,
    pageId,
    title,
    description,
    version,
    isIndex,
    primaryFont,
    secondaryFont,
    primaryColor,
    secondaryColor,
    ternaryColor,
    colorType,
    primaryRadius,
    secondaryRadius,
    primaryShadow,
  };
};

export const EDITOR_SET_SELECTED_ELEMENT = "EDITOR_SET_SELECTED_ELEMENT";

export type SetSelectedElementAction = {
  type: typeof EDITOR_SET_SELECTED_ELEMENT;
  elementId: string | null;
  elementIndex: number | null;
};

export const setSelectedElement = (
  elementId: string | null,
  elementIndex: number | null
): Action => {
  return {
    type: EDITOR_SET_SELECTED_ELEMENT,
    elementId,
    elementIndex,
  };
};

export const EDITOR_SET_HOVERED_ELEMENT = "EDITOR_SET_HOVERED_ELEMENT";

export type SetHoveredElementAction = {
  type: typeof EDITOR_SET_HOVERED_ELEMENT;
  elementId: string | null;
  elementIndex: number | null;
};

export const setHoveredElement = (
  elementId: string | null,
  elementIndex: number | null
): Action => {
  return {
    type: EDITOR_SET_HOVERED_ELEMENT,
    elementId,
    elementIndex,
  };
};

export const EDITOR_SET_DRAGGABLE_ELEMENT = "EDITOR_SET_DRAGGABLE_ELEMENT";

export type SetDraggableElementAction = {
  type: typeof EDITOR_SET_DRAGGABLE_ELEMENT;
  elementId: string | null;
};

export const setDraggableElement = (elementId: string | null): Action => {
  return {
    type: EDITOR_SET_DRAGGABLE_ELEMENT,
    elementId,
  };
};

export const EDITOR_CANCEL_DRAGGABLE_ELEMENT =
  "EDITOR_CANCEL_DRAGGABLE_ELEMENT";

export type CancelDraggableElementAction = {
  type: typeof EDITOR_CANCEL_DRAGGABLE_ELEMENT;
};

export const cancelDraggableElement = (): Action => {
  return {
    type: EDITOR_CANCEL_DRAGGABLE_ELEMENT,
  };
};

export const EDITOR_SET_RESIZEABLE_ELEMENT = "EDITOR_SET_RESIZEABLE_ELEMENT";

export type SetResizeableElementAction = {
  type: typeof EDITOR_SET_RESIZEABLE_ELEMENT;
  elementId: string | null;
};

export const setResizeableElement = (elementId: string | null): Action => {
  return {
    type: EDITOR_SET_RESIZEABLE_ELEMENT,
    elementId,
  };
};

export const EDITOR_CANCEL_RESIZEABLE_ELEMENT =
  "EDITOR_CANCEL_RESIZEABLE_ELEMENT";

export type CancelResizeableElementAction = {
  type: typeof EDITOR_CANCEL_RESIZEABLE_ELEMENT;
};

export const cancelResizeableElement = (): Action => {
  return {
    type: EDITOR_CANCEL_RESIZEABLE_ELEMENT,
  };
};

export const EDITOR_HANDLE_RT_TEMP_TEXT_ACTION =
  "EDITOR_HANDLE_RT_TEMP_TEXT_ACTION";

export type HandleRTTempTextAction = {
  type: typeof EDITOR_HANDLE_RT_TEMP_TEXT_ACTION;
  propId: string;
  text: string | undefined;
};

export const setRtTempText = (
  propId: string,
  text: string | undefined
): Action => {
  return {
    type: EDITOR_HANDLE_RT_TEMP_TEXT_ACTION,
    propId,
    text,
  };
};

export const EDITOR_SAVE_RT_TEMP_TEXT_ACTION =
  "EDITOR_SAVE_RT_TEMP_TEXT_ACTION";

export type SaveRTTempTextAction = {
  type: typeof EDITOR_SAVE_RT_TEMP_TEXT_ACTION;
};

export const saveRtTempText = (): Action => {
  return {
    type: EDITOR_SAVE_RT_TEMP_TEXT_ACTION,
  };
};

export const EDITOR_RESET_RT_TEMP_ACTION = "EDITOR_RESET_RT_TEMP_ACTION";

export type ResetRTTempAction = {
  type: typeof EDITOR_RESET_RT_TEMP_ACTION;
};

export const resetRtTempText = (): Action => {
  return {
    type: EDITOR_RESET_RT_TEMP_ACTION,
  };
};

export const EDITOR_CANCEL_ELEMENT_EDITION = "EDITOR_CANCEL_ELEMENT_EDITION";

export type CancelElementEditionAction = {
  type: typeof EDITOR_CANCEL_ELEMENT_EDITION;
  elementId: string;
  elementRef: React.MutableRefObject<any> | null;
};

export const cancelElementEdition = (
  elementId: string,
  elementRef: React.MutableRefObject<any> | null
): Action => {
  return {
    type: EDITOR_CANCEL_ELEMENT_EDITION,
    elementId,
    elementRef,
  };
};

export const EDITOR_RESIZE = "EDITOR_RESIZE";

export type ResizeAction = {
  type: typeof EDITOR_RESIZE;
  elementId: string;
  position: PositionString;
  delta: number;
};

export const resize = (
  elementId: string,
  position: PositionString,
  delta: number
): Action => {
  return {
    type: EDITOR_RESIZE,
    elementId,
    position,
    delta,
  };
};

export const ADMIN_GET_PAGE_TEMPLATE_REQUEST =
  "ADMIN_GET_PAGE_TEMPLATE_REQUEST";

export type GetPageTemplateRequestAction = {
  type: typeof ADMIN_GET_PAGE_TEMPLATE_REQUEST;
  websiteSlug: string;
};

export const getPageTemplate = (websiteSlug: string): Action => {
  return {
    type: ADMIN_GET_PAGE_TEMPLATE_REQUEST,
    websiteSlug,
  };
};

export const ADMIN_GET_PAGE_TEMPLATE_SUCCESS =
  "ADMIN_GET_PAGE_TEMPLATE_SUCCESS";

export type GetPageTemplateSuccessAction = {
  type: typeof ADMIN_GET_PAGE_TEMPLATE_SUCCESS;
  template: TemplateKey;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
};

export const getPageTemplateSuccess = (
  template: TemplateKey,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string
): Action => {
  return {
    type: ADMIN_GET_PAGE_TEMPLATE_SUCCESS,
    template,
    primaryFont,
    secondaryFont,
    primaryColor,
    secondaryColor,
    ternaryColor,
    colorType,
    primaryRadius,
    secondaryRadius,
    primaryShadow,
  };
};

export const ADMIN_GET_PAGE_TEMPLATE_FAILURE =
  "ADMIN_GET_PAGE_TEMPLATE_FAILURE";

export type GetPageTemplateFailureAction = {
  type: typeof ADMIN_GET_PAGE_TEMPLATE_FAILURE;
};

export const getPageTemplateFailure = (): Action => {
  return {
    type: ADMIN_GET_PAGE_TEMPLATE_FAILURE,
  };
};

export const EDITOR_UPDATE_DEFAULT_FONT_ACTION =
  "EDITOR_UPDATE_DEFAULT_FONT_ACTION";

export type UpdateDefaultFontAction = {
  type: typeof EDITOR_UPDATE_DEFAULT_FONT_ACTION;
  primaryFont: string;
  secondaryFont: string;
};

export const updateDefaultFont = (
  primaryFont: string,
  secondaryFont: string
): Action => {
  return {
    type: EDITOR_UPDATE_DEFAULT_FONT_ACTION,
    primaryFont,
    secondaryFont,
  };
};

export const EDITOR_UPDATE_DEFAULT_COLOR_ACTION =
  "EDITOR_UPDATE_DEFAULT_COLOR_ACTION";

export type UpdateDefaultColorAction = {
  type: typeof EDITOR_UPDATE_DEFAULT_COLOR_ACTION;
  primaryColor: string;
  secondaryColor: string;
};

export const updateDefaultColor = (
  primaryColor: string,
  secondaryColor: string
): Action => {
  return {
    type: EDITOR_UPDATE_DEFAULT_COLOR_ACTION,
    primaryColor,
    secondaryColor,
  };
};

export const EDITOR_GET_TREATMENT_PAGE_SLUG = "EDITOR_GET_TREATMENT_PAGE_SLUG";

export type GetTreatmentPageSlugAction = {
  type: typeof EDITOR_GET_TREATMENT_PAGE_SLUG;
  pageSlug: string;
};

export const findTreatmentPageSlug = (
  pageSlug: string
): GetTreatmentPageSlugAction => {
  return {
    type: EDITOR_GET_TREATMENT_PAGE_SLUG,
    pageSlug,
  };
};

export const EDITOR_GET_TREATMENT_PAGE_SLUG_SUCCESS =
  "EDITOR_GET_TREATMENT_PAGE_SLUG_SUCCESS";

export type GetTreatmentPageSlugSuccessAction = {
  type: typeof EDITOR_GET_TREATMENT_PAGE_SLUG_SUCCESS;
  treatmentSlug: string;
};

export const findTreatmentSlugSuccess = (
  treatmentSlug: string
): GetTreatmentPageSlugSuccessAction => {
  return {
    type: EDITOR_GET_TREATMENT_PAGE_SLUG_SUCCESS,
    treatmentSlug,
  };
};

export const EDITOR_CLEAR_CHALLENGE_FROM_PAGE_DELETE =
  "EDITOR_CLEAR_CHALLENGE_FROM_PAGE_DELETE";

export type ClearChallengesFromDeletePageSuccessAction = {
  type: typeof EDITOR_CLEAR_CHALLENGE_FROM_PAGE_DELETE;
  pageSlug: string;
};

export const clearChallengesFromDeletePageSuccessAction = (
  pageSlug: string
): ClearChallengesFromDeletePageSuccessAction => {
  return {
    type: EDITOR_CLEAR_CHALLENGE_FROM_PAGE_DELETE,
    pageSlug,
  };
};

export const EDITOR_GET_PAGE_CHALLENGES = "EDITOR_GET_PAGE_CHALLENGES";

export type GetPageChallengesAction = {
  type: typeof EDITOR_GET_PAGE_CHALLENGES;
  pageSlug: string;
  websiteSlug: string;
};

export const getPageChallenges = (
  pageSlug: string,
  websiteSlug: string
): GetPageChallengesAction => {
  return {
    type: EDITOR_GET_PAGE_CHALLENGES,
    pageSlug,
    websiteSlug,
  };
};

export const EDITOR_UPDATE_PAGE_WITH_CHALLENGE_WINNER =
  "EDITOR_UPDATE_PAGE_WITH_CHALLENGE_WINNER";

export type UpdatePageWithChallengeWinnerAction = {
  type: typeof EDITOR_UPDATE_PAGE_WITH_CHALLENGE_WINNER;
  challengeId: number;
  pageSlug: string;
  property: string;
  elementId: string;
};

export const updatePageWithChallengeWinner = (
  challengeId: number,
  pageSlug: string,
  property: string,
  elementId: string
): UpdatePageWithChallengeWinnerAction => {
  return {
    type: EDITOR_UPDATE_PAGE_WITH_CHALLENGE_WINNER,
    challengeId,
    pageSlug,
    property,
    elementId,
  };
};

export const EDITOR_GET_PAGE_CHALLENGES_SUCCESS =
  "EDITOR_GET_PAGE_CHALLENGES_SUCCESS";

export type GetPageChallengesActionSuccess = {
  type: typeof EDITOR_GET_PAGE_CHALLENGES_SUCCESS;
  pageSlug: string;
  challenges: Challenge[];
};

export const getPageChallengesSuccess = (
  pageSlug: string,
  challenges: Challenge[]
): GetPageChallengesActionSuccess => {
  return {
    type: EDITOR_GET_PAGE_CHALLENGES_SUCCESS,
    pageSlug,
    challenges,
  };
};

export const EDITOR_UPDATE_CHALLENGE_VARIANTS_SUCCESS =
  "EDITOR_UPDATE_CHALLENGE_VARIANTS_SUCCESS";

export type UpdateChallengeVariantsStatusActionSuccess = {
  type: typeof EDITOR_UPDATE_CHALLENGE_VARIANTS_SUCCESS;
  pageSlug: string;
  elementId?: string;
};

export const updateChallengerVariantsSuccess = (
  pageSlug: string,
  elementId?: string
) => {
  return { type: EDITOR_UPDATE_CHALLENGE_VARIANTS_SUCCESS, pageSlug };
};

export const EDITOR_CREATE_CHALLENGE_SUCCESS =
  "EDITOR_CREATE_CHALLENGE_SUCCESS";

export type CreateChallengeSuccessAction = {
  type: typeof EDITOR_CREATE_CHALLENGE_SUCCESS;
  pageSlug: string;
  challenge: Challenge;
  layout: Layout;
};

export const createChallengeSuccess = (
  pageSlug: string,
  challenge: Challenge,
  layout: Layout
) => {
  return { type: EDITOR_CREATE_CHALLENGE_SUCCESS, pageSlug, challenge, layout };
};

export type Action =
  // This is only needed for Typescript type check to pass. There has to be an
  // action with just the type property, and nothing else.
  | { type: "_" }
  | PageCreatedFromTemplateAction
  | PageCreationFromTemplateSucceededAction
  | PageCreationFromTemplateFailedAction
  | PageLatestVersionLoadedAction
  | PageLatestVersionLoadSucceededAction
  | PageLatestVersionLoadFailedAction
  | SavePageRequestAction
  | SavePageSuccessAction
  | SavePageFailureAction
  | CurrentPageDetailsUpdateSucceededAction
  | SetSelectedElementAction
  | SetHoveredElementAction
  | SetDraggableElementAction
  | CancelDraggableElementAction
  | CancelElementEditionAction
  | ResizeAction
  | HandleRTTempTextAction
  | SaveRTTempTextAction
  | ResetRTTempAction
  | GetPageTemplateRequestAction
  | GetPageTemplateSuccessAction
  | GetPageTemplateFailureAction
  | UpdateDefaultFontAction
  | UpdateDefaultColorAction
  | TrackFakeDoorAction
  | PageCreationFromTemplateTreatmentSucceededAction
  | TreatmentPageLatestVersionLoadSucceededAction
  | SaveChallengeAction
  | PageSaveSucceededAction
  | GetTreatmentPageSlugAction
  | GetTreatmentPageSlugSuccessAction
  | GetPageChallengesAction
  | GetPageChallengesActionSuccess
  | UpdateChallengeVariantsStatusActionSuccess
  | UpdatePageWithChallengeWinnerAction
  | CreateOtherPagesUpdateReusables
  | CreateChildPagesFromTemplateAction
  | SetResizeableElementAction
  | CancelResizeableElementAction
  | DeletePageSuccessAction
  | SaveChallengeVariantsAction
  | ClearChallengesFromDeletePageSuccessAction
  | CreateChallengeSuccessAction
  | LoadOtherPagesAction
  | LoadOtherPagesSuccessAction
  | SaveOtherPagesAction
  | LogoutSuccessAction
  | PageCreateDetailsAction;
// | GenerateUrlAction;
