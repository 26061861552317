import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  createChildPagesFromTemplate,
  pageCreatedFromTemplate,
} from "../Editor/editorActions";
import { EditorWizardStep0 } from "./EditorWizardStep0";
import { EditorWizardStep1 } from "./EditorWizardStep1";
import {
  TemplateKey,
  templatePageDetails,
  templateTitles,
} from "../../../templates/types";
import { getUsername, getUserWebsiteSlug } from "../../../../auth/selectors";
import {
  getPageWithLatestDetailsList,
  getWebsite,
} from "editor/states/selectors";
import "./EditorWizard.scss";
import styles from "./EditorWizard.module.scss";
import { PageWithLatestDetails, Website } from "editor/models/types";
import {
  getColorType,
  getPrimaryColor,
  getPrimaryFont,
  getPrimaryRadius,
  getPrimaryShadow,
  getSecondaryColor,
  getSecondaryFont,
  getSecondaryRadius,
  getTemplateKey,
  getTernaryColor,
} from "editor/selectors";
import { EditorWizardStep0a } from "./EditorWizardStep0a";
import { EditorWizardStep0b } from "./EditorWizardStep0b";
import { EditorWizardStep0c } from "./EditorWizardStep0c";
import { logActivity } from "auth/authActions";
import { updateWebsiteDetails } from "admin/websiteActions";
import { Layout } from "common/elements/types";
import { fetchPages } from "admin/pageActions";
import { getPages } from "admin/selectors";
import { randomSlug } from "editor/templates/TemplatesFactory";

export type FormValues = {
  title: string;
  templateKey: TemplateKey;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
};

export type StepProps = {
  values: FormValues;
  handleSaveAndContinue: (valuesToUpdate: Partial<FormValues>) => void;
  handleJumpForward: () => void;
  handleStepBack: (stepsBack: number) => void;
  handleUpdateFonts: (
    primaryFont: string,
    secondaryFont: string,
    activeFont: string
  ) => void;
  handleUpdateColors: (
    primaryColor: string,
    secondaryColor: string,
    ternaryColor: string,
    colorType: string
  ) => void;
  handleUpdateRadiusShadow: (
    primaryRadius: string,
    secondaryRadius: string,
    primaryShadow: string
  ) => void;
  parentPrimaryFont: string;
  parentSecondaryFont: string;
  activeFont: string;
  parentPrimaryColor: string;
  parentSecondaryColor: string;
  parentTernaryColor: string;
  parentColorType: string;
  parentPrimaryRadius: string;
  parentSecondaryRadius: string;
  parentPrimaryShadow: string;
  website: Website;
};

export const EditorWizard: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const website = useSelector(getWebsite);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const stateTemplateKey = useSelector(getTemplateKey);
  const statePrimaryFont = useSelector(getPrimaryFont);
  const stateSecondaryFont = useSelector(getSecondaryFont);
  const statePrimaryColor = useSelector(getPrimaryColor);
  const stateSecondaryColor = useSelector(getSecondaryColor);
  const stateTernaryColor = useSelector(getTernaryColor);
  const stateColorType = useSelector(getColorType);
  const statePrimaryRadius = useSelector(getPrimaryRadius);
  const stateSecondaryRadius = useSelector(getSecondaryRadius);
  const statePrimaryShadow = useSelector(getPrimaryShadow);
  const username = useSelector(getUsername);
  const pages = useSelector(getPages);

  useEffect(() => {
    dispatch(logActivity("PAGE SETUP START", "Page Wizard", username));
  }, [dispatch, username]);

  const pageList: PageWithLatestDetails[] = useSelector(
    getPageWithLatestDetailsList
  );
  const [currentStep, setCurrentStep] = useState(
    stateTemplateKey !== null &&
      stateTemplateKey !== undefined &&
      pageList.length > 0
      ? 4
      : 0
  );
  const [values, setValues] = useState<FormValues>({
    title: pageList.length > 0 ? "" : "Home",
    templateKey:
      stateTemplateKey !== null &&
      stateTemplateKey !== undefined &&
      pageList.length > 0
        ? stateTemplateKey
        : TemplateKey.Blank,
    primaryFont:
      statePrimaryFont !== null && pageList.length > 0
        ? statePrimaryFont
        : website.logoCreated && website.primaryFont
        ? website.primaryFont
        : templateTitles[TemplateKey.Blank].primaryFont,
    secondaryFont:
      stateSecondaryFont !== null && pageList.length > 0
        ? stateSecondaryFont
        : website.logoCreated && website.secondaryFont
        ? website.secondaryFont
        : templateTitles[TemplateKey.Blank].secondaryFont,
    primaryColor:
      statePrimaryColor !== null && pageList.length > 0
        ? statePrimaryColor
        : website.logoCreated && website.primaryColor
        ? website.primaryColor
        : templateTitles[TemplateKey.Blank].primaryColor,
    secondaryColor:
      stateSecondaryColor !== null && pageList.length > 0
        ? stateSecondaryColor
        : website.logoCreated && website.secondaryColor
        ? website.secondaryColor
        : templateTitles[TemplateKey.Blank].secondaryColor,
    ternaryColor:
      stateTernaryColor !== null && pageList.length > 0
        ? stateTernaryColor
        : website.logoCreated && website.ternaryColor
        ? website.ternaryColor
        : templateTitles[TemplateKey.Blank].ternaryColor,
    colorType:
      stateColorType !== null && pageList.length > 0
        ? stateColorType
        : website.logoCreated && website.colorType
        ? website.colorType
        : templateTitles[TemplateKey.Blank].colorType,
    primaryRadius:
      statePrimaryRadius !== null && pageList.length > 0
        ? statePrimaryRadius
        : website.logoCreated && website.primaryRadius
        ? website.primaryRadius
        : templateTitles[TemplateKey.Blank].primaryRadius,
    secondaryRadius:
      stateSecondaryRadius !== null && pageList.length > 0
        ? stateSecondaryRadius
        : website.logoCreated && website.secondaryRadius
        ? website.secondaryRadius
        : templateTitles[TemplateKey.Blank].secondaryRadius,
    primaryShadow:
      statePrimaryShadow !== null && pageList.length > 0
        ? statePrimaryShadow
        : website.logoCreated && website.primaryShadow
        ? website.primaryShadow
        : templateTitles[TemplateKey.Blank].primaryShadow,
  });
  const steps = [
    EditorWizardStep0,
    EditorWizardStep0a,
    EditorWizardStep0b,
    EditorWizardStep0c,
    EditorWizardStep1,
  ];
  const [parentPrimaryFont, setParentPrimaryFont] = useState(
    templateTitles[TemplateKey.Blank].primaryFont
  );
  const [parentSecondaryFont, setParentSecondaryFont] = useState(
    templateTitles[TemplateKey.Blank].secondaryFont
  );
  const [parentActiveFont, setParentActiveFont] = useState(
    templateTitles[TemplateKey.Blank].primaryFont
  );
  const [parentPrimaryColor, setParentPrimaryColor] = useState(
    templateTitles[TemplateKey.Blank].primaryColor
  );
  const [parentSecondaryColor, setParentSecondaryColor] = useState(
    templateTitles[TemplateKey.Blank].secondaryColor
  );
  const [parentTernaryColor, setParentTernaryColor] = useState(
    templateTitles[TemplateKey.Blank].ternaryColor
  );
  const [parentColorType, setParentColorType] = useState(
    templateTitles[TemplateKey.Blank].colorType
  );
  const [parentPrimaryRadius, setParentPrimaryRadius] = useState(
    templateTitles[TemplateKey.Blank].primaryRadius
  );
  const [parentSecondaryRadius, setParentSecondaryRadius] = useState(
    templateTitles[TemplateKey.Blank].secondaryRadius
  );
  const [parentPrimaryShadow, setParentPrimaryShadow] = useState(
    templateTitles[TemplateKey.Blank].primaryShadow
  );

  useEffect(() => {
    if (website && website.logoCreated) {
      setParentPrimaryFont(
        website.primaryFont
          ? website.primaryFont
          : templateTitles[TemplateKey.Blank].primaryFont
      );
      setParentSecondaryFont(
        website.secondaryFont
          ? website.secondaryFont
          : templateTitles[TemplateKey.Blank].secondaryFont
      );
      setParentActiveFont(
        website.primaryFont
          ? website.primaryFont
          : templateTitles[TemplateKey.Blank].primaryFont
      );
      setParentPrimaryColor(
        website.primaryColor
          ? website.primaryColor
          : templateTitles[TemplateKey.Blank].primaryColor
      );
      setParentSecondaryColor(
        website.secondaryColor
          ? website.secondaryColor
          : templateTitles[TemplateKey.Blank].secondaryColor
      );
      setParentTernaryColor(
        website.ternaryColor
          ? website.ternaryColor
          : templateTitles[TemplateKey.Blank].ternaryColor
      );
      setParentColorType(
        website.colorType
          ? website.colorType
          : templateTitles[TemplateKey.Blank].colorType
      );
      setParentPrimaryRadius(
        website.primaryRadius
          ? website.primaryRadius
          : templateTitles[TemplateKey.Blank].primaryRadius
      );
      setParentSecondaryRadius(
        website.secondaryRadius
          ? website.secondaryRadius
          : templateTitles[TemplateKey.Blank].secondaryRadius
      );
      setParentPrimaryShadow(
        website.primaryShadow
          ? website.primaryShadow
          : templateTitles[TemplateKey.Blank].primaryShadow
      );
    }
  }, [website]);

  if (!websiteSlug) {
    return <></>;
  }

  const handleStepBack = (stepsBack: number) => {
    setCurrentStep(currentStep - stepsBack);
  };

  const handleJumpForward = () => {
    setCurrentStep(4);
  };

  let setAsIndex = true;
  pageList.some(function (page) {
    if (page.isIndex) {
      setAsIndex = false;
      return false;
    }
    return true;
  });

  const setParentFont = (
    primaryFont: string,
    secondaryFont: string,
    activeFont: string
  ) => {
    setParentPrimaryFont(primaryFont);
    setParentSecondaryFont(secondaryFont);
    setParentActiveFont(activeFont);
    dispatch(
      logActivity(
        "SET FONT",
        "Page Wizard",
        username,
        primaryFont + " / " + secondaryFont
      )
    );
  };

  const setParentColors = (
    primaryColor: string,
    secondaryColor: string,
    ternaryColor: string,
    colorType: string
  ) => {
    setParentPrimaryColor(primaryColor);
    setParentSecondaryColor(secondaryColor);
    setParentTernaryColor(ternaryColor);
    setParentColorType(colorType);

    dispatch(
      logActivity(
        "SET COLOR",
        "Page Wizard",
        username,
        primaryColor +
          " / " +
          secondaryColor +
          " / " +
          ternaryColor +
          " / " +
          colorType
      )
    );
  };

  const setParentSharpnessShadow = (
    primaryRadius: string,
    secondaryRadius: string,
    primaryShadow: string
  ) => {
    setParentPrimaryRadius(primaryRadius);
    setParentSecondaryRadius(secondaryRadius);
    setParentPrimaryShadow(primaryShadow);
    dispatch(
      logActivity(
        "SET RADIUS",
        "Page Wizard",
        username,
        primaryRadius + " / " + secondaryRadius
      )
    );
    dispatch(logActivity("SET SHADOW", "Page Wizard", username, primaryShadow));
  };

  const handleSaveAndContinue = (valuesToUpdate: Partial<FormValues>) => {
    const updatedValues = { ...values, ...valuesToUpdate };
    setValues(updatedValues);
    if (
      currentStep === steps.length - 1 ||
      updatedValues.templateKey !== TemplateKey.Blank
    ) {
      const childrenTemplates = templatePageDetails[updatedValues.templateKey];
      let pageTitle = updatedValues.title;
      let pageDescription = "Page of " + website.title;
      let masterSlug = randomSlug(6);
      if (childrenTemplates && childrenTemplates.details) {
        if (childrenTemplates.details.description) {
          pageDescription =
            childrenTemplates.details.description + " of " + website.title;
        }
        if (childrenTemplates.details.slug) {
          masterSlug = childrenTemplates.details.slug;
        }
      }

      let primaryFont = "";
      let secondaryFont = "";
      let primaryColor = "";
      let secondaryColor = "";
      let ternaryColor = "";
      let colorType = "";
      let primaryRadius = "";
      let secondaryRadius = "";
      let primaryShadow = "";
      if (setAsIndex) {
        if (updatedValues.templateKey !== TemplateKey.Blank) {
          primaryFont = templateTitles[updatedValues.templateKey].primaryFont;
          secondaryFont =
            templateTitles[updatedValues.templateKey].secondaryFont;
          primaryColor = templateTitles[updatedValues.templateKey].primaryColor;
          secondaryColor =
            templateTitles[updatedValues.templateKey].secondaryColor;
          ternaryColor = templateTitles[updatedValues.templateKey].ternaryColor;
          colorType = templateTitles[updatedValues.templateKey].colorType;
          primaryRadius =
            templateTitles[updatedValues.templateKey].primaryRadius;
          secondaryRadius =
            templateTitles[updatedValues.templateKey].secondaryRadius;
          primaryShadow =
            templateTitles[updatedValues.templateKey].primaryShadow;
        } else {
          primaryFont = updatedValues.primaryFont;
          secondaryFont = updatedValues.secondaryFont;
          primaryColor = updatedValues.primaryColor;
          secondaryColor = updatedValues.secondaryColor;
          ternaryColor = updatedValues.ternaryColor;
          colorType = updatedValues.colorType;
          primaryRadius = updatedValues.primaryRadius;
          secondaryRadius = updatedValues.secondaryRadius;
          primaryShadow = updatedValues.primaryShadow;
        }
      } else {
        primaryFont = statePrimaryFont
          ? statePrimaryFont
          : updatedValues.primaryFont;
        secondaryFont = stateSecondaryFont
          ? stateSecondaryFont
          : updatedValues.secondaryFont;
        primaryColor = statePrimaryColor
          ? statePrimaryColor
          : updatedValues.primaryColor;
        secondaryColor = stateSecondaryColor
          ? stateSecondaryColor
          : updatedValues.secondaryColor;
        ternaryColor = stateTernaryColor
          ? stateTernaryColor
          : updatedValues.ternaryColor;
        colorType = stateColorType ? stateColorType : updatedValues.colorType;
        primaryRadius = statePrimaryRadius
          ? statePrimaryRadius
          : updatedValues.primaryRadius;
        secondaryRadius = stateSecondaryRadius
          ? stateSecondaryRadius
          : updatedValues.secondaryRadius;
        primaryShadow = statePrimaryShadow
          ? statePrimaryShadow
          : updatedValues.primaryShadow;
      }
      dispatch(logActivity("PAGE SETUP END", "Page Wizard", username));
      dispatch(
        pageCreatedFromTemplate(
          websiteSlug,
          pageTitle,
          pageDescription,
          updatedValues.templateKey,
          setAsIndex,
          (pageSlug: string, layout: Layout) => {
            dispatch(
              updateWebsiteDetails(
                websiteSlug,
                primaryFont,
                secondaryFont,
                primaryColor,
                secondaryColor,
                ternaryColor,
                colorType,
                primaryRadius,
                secondaryRadius,
                primaryShadow,
                website.logoCreated ? website.logoCreated : false
              )
            );
            if (updatedValues.templateKey !== TemplateKey.Blank) {
              if (pages === undefined || pages.length === 0) {
                dispatch(
                  createChildPagesFromTemplate(
                    websiteSlug,
                    updatedValues.title,
                    "", // Set the description as an empty string for now at this stage of website creation
                    updatedValues.templateKey,
                    false,
                    primaryFont,
                    secondaryFont,
                    primaryColor,
                    secondaryColor,
                    ternaryColor,
                    colorType,
                    primaryRadius,
                    secondaryRadius,
                    primaryShadow,
                    pageSlug,
                    (websiteSlug) => {
                      dispatch(fetchPages(websiteSlug));
                      history.push(`/admin/pages/${pageSlug}/edit`);
                    },
                    website.logoCreated ? website.logoCreated : false
                  )
                );
              } else {
                dispatch(fetchPages(websiteSlug));
                history.push(`/admin/pages/${pageSlug}/edit`);
              }
            }
            if (updatedValues.templateKey === TemplateKey.Blank) {
              dispatch(fetchPages(websiteSlug));
              history.push(`/admin/pages/${pageSlug}/edit`);
            }
          },
          primaryFont,
          secondaryFont,
          primaryColor,
          secondaryColor,
          ternaryColor,
          colorType,
          primaryRadius,
          secondaryRadius,
          primaryShadow,
          updatedValues.templateKey === TemplateKey.Blank ? false : true,
          website.logoCreated ? website.logoCreated : false,
          masterSlug
        )
      );
    } else if (
      updatedValues.templateKey !== TemplateKey.Blank &&
      currentStep === 0
    ) {
      setCurrentStep(currentStep + 4);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const Step = steps[currentStep];

  return (
    <Modal
      size={currentStep === 4 ? "lg" : "xl"}
      centered
      show={true}
      className={`"editor-welcome-wizard" ${styles["editor-welcome-wizard"]}`}
    >
      <Step
        values={values}
        handleJumpForward={handleJumpForward}
        handleStepBack={handleStepBack}
        handleSaveAndContinue={handleSaveAndContinue}
        handleUpdateFonts={setParentFont}
        handleUpdateColors={setParentColors}
        handleUpdateRadiusShadow={setParentSharpnessShadow}
        parentPrimaryFont={parentPrimaryFont}
        parentSecondaryFont={parentSecondaryFont}
        activeFont={parentActiveFont}
        parentPrimaryColor={parentPrimaryColor}
        parentSecondaryColor={parentSecondaryColor}
        parentTernaryColor={parentTernaryColor}
        parentColorType={parentColorType}
        parentPrimaryRadius={parentPrimaryRadius}
        parentSecondaryRadius={parentSecondaryRadius}
        parentPrimaryShadow={parentPrimaryShadow}
        website={website}
      />
    </Modal>
  );
};
