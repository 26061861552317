import { CloseRounded, SvgIconComponent } from "@material-ui/icons";
import React, { useState, useEffect, ReactNode } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUserWebsiteSlug } from "../../../../auth/selectors";
import { fetchPages } from "../../../../admin/pageActions";
import { Shade } from "../../base/Shade/Shade";
import Moveable from "react-moveable";
import { Button } from "../../base/Button/Button";

import "./Dialog.scss";
import styles from "./Dialog.module.scss";

type Props = {
  isShowDialog: boolean;
  isGrabbable?: boolean;
  isDisabled?: boolean;
  isShowBg?: boolean;
  isDialogPositionStatic?: boolean;
  size: "medium" | "large";
  dialogBody?: ReactNode;
  headerLabel: string;
  elementPos?: DOMRect;
  cssPosition?: React.CSSProperties;
  parentActionLabel?: string;
  parentActionButtonVariant?: string;
  parentActionTo?: string;
  icon: SvgIconComponent;
  parentActionIcon?: SvgIconComponent;
  handleShowDialog: () => void;
  handleParentAction?: () => void;
  isLoading?: boolean;
  isParentActionButtonFlat?: boolean;
};

export const Dialog: React.FunctionComponent<Props> = ({
  isShowDialog: showDialog,
  isGrabbable,
  isDisabled,
  isShowBg: showBg,
  isDialogPositionStatic: dialogPositionStatic,
  size,
  dialogBody,
  headerLabel,
  elementPos,
  cssPosition,
  parentActionLabel,
  parentActionButtonVariant,
  parentActionTo,
  icon,
  parentActionIcon,
  handleShowDialog,
  handleParentAction,
  isLoading,
  isParentActionButtonFlat,
}) => {
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const dispatch = useDispatch();
  const frame = { translate: [0, 0] };
  const [isHeaderClicked, setIsHeaderClicked] = useState(false);
  const handleHeaderClick = () => {
    setIsHeaderClicked(isHeaderClicked ? false : true);
  };
  useEffect(() => {
    if (websiteSlug) {
      dispatch(fetchPages(websiteSlug));
    }
  }, [dispatch, websiteSlug]);

  if (!showDialog) {
    return <></>;
  }

  if (!websiteSlug) {
    return <></>;
  }
  var Icon = null;
  if (icon) {
    Icon = icon;
  }
  const dialogWidth = size === "medium" ? 400 : 600;
  const dialogHeight = 630;

  let grabbableClassname = isGrabbable ? "grabbable" : "";

  if (cssPosition === undefined) {
    if (dialogPositionStatic) {
      cssPosition = {
        left: window.innerWidth - dialogWidth - 24,
        top: window.innerHeight / 8,
      };
    } else if (elementPos) {
      // posTRX - position top-right x
      // posTRY - position top-right y
      const posTRX = elementPos.x + elementPos.width;
      const posTRY = elementPos.y;
      const isPosTRXOverX =
        posTRX + dialogWidth > window.innerWidth ? true : false;
      const isPosTRYOverY =
        dialogHeight + posTRY > window.innerHeight ? true : false;

      // posBRX - position bottom-right x
      // posBRY - position bottom-right y
      const posBRX = elementPos.x + elementPos.width;
      const posBRY = elementPos.y + elementPos.height - dialogHeight;
      const isPosBRXOverX =
        posBRX + dialogWidth > window.innerWidth ? true : false;
      const isPosBRYOverY =
        posBRY > window.innerHeight || posBRY < 60 ? true : false;

      // posTLX - position top-left x
      // posTLY - position top-left y
      const posTLX = elementPos.x - dialogWidth;
      const posTLY = elementPos.y;
      const isPosTLXoverX =
        posTLX > window.innerWidth || posTLX < 0 ? true : false;
      const isPosTLYoverY =
        dialogHeight + posTLY > window.innerHeight ? true : false;

      // posBLX - position bottom-left x
      // posBLY - position bottom-left y
      const posBLX = elementPos.x - dialogWidth;
      const posBLY = elementPos.bottom - dialogHeight;
      const isPosBLXOverX =
        posBLX > window.innerWidth || posBLX < 0 ? true : false;
      const isPosBLYOverY =
        posBLY < 0 || posBLY > window.innerHeight ? true : false;

      // posULX - position under-left x
      // posULY - position under-left y
      const posULX = elementPos.x;
      const posULY = elementPos.y + elementPos.height;
      const isPosULXOverX = posULX + dialogWidth > window.innerWidth;
      const isPosULYOverY = posULY + dialogHeight > window.innerHeight;

      // posURX - position under-right x
      // posURY - position under-right y
      const posURX = elementPos.x + elementPos.width - dialogWidth;
      const posURY = elementPos.y + elementPos.height;
      const isPosURXOverX =
        posURX > window.innerWidth || posURX < 0 ? true : false;
      const isPosURYOverY = posURY + dialogHeight > window.innerHeight;

      // posMRX - position mid-right x
      // posMRY - position mid-right y
      const posMRX = elementPos.right;
      const posMRY = elementPos.x + elementPos.width;
      const isPosMRXOverX =
        posMRX + dialogWidth > window.innerWidth ? true : false;
      const isPosMRYOverY = posMRY > window.innerHeight ? true : false;

      // posMLX - position mid-left x
      // posMLY - position mid-left y
      const posMLX = elementPos.left - dialogWidth;
      const posMLY = (dialogHeight - elementPos.height) / 2;
      const isPosMLXOverX =
        posMLX > window.innerWidth || posMLX < 0 ? true : false;
      const isPosMLYOverY = posMLY > window.innerHeight ? true : false;

      if (!isPosULXOverX && !isPosULYOverY) {
        cssPosition = {
          left: posULX,
          top: posULY,
          marginTop: 16,
        };
      } else if (!isPosURXOverX && !isPosURYOverY) {
        cssPosition = {
          left: posURX,
          top: posURY,
          marginTop: 16,
        };
      } else if (!isPosTRXOverX && !isPosTRYOverY) {
        cssPosition = {
          left: posTRX,
          top: posTRY,
          marginLeft: 12,
        };
      } else if (!isPosBRXOverX && !isPosBRYOverY) {
        cssPosition = {
          left: posBRX,
          top: posBRY,
          marginLeft: 12,
        };
      } else if (!isPosTLXoverX && !isPosTLYoverY) {
        cssPosition = {
          left: posTLX,
          top: posTLY,
          marginRight: 12,
        };
      } else if (!isPosBLXOverX && !isPosBLYOverY) {
        cssPosition = {
          left: posBLX,
          top: posBLY,
          marginRight: 12,
        };
      } else if (!isPosMLXOverX && !isPosMLYOverY) {
        cssPosition = {
          left: posMLX,
          top: posMLY,
          marginRight: 12,
        };
      } else if (!isPosMRXOverX && !isPosMRYOverY) {
        cssPosition = {
          left: posMRX,
          top: posMRY,
          marginLeft: 12,
        };
      } else {
        cssPosition = {
          left: window.innerWidth - dialogWidth - 24,
          top: window.innerHeight / 8,
        };
      }
    }
  }
  return (
    <>
      {showBg ? <Shade /> : ""}
      <Moveable
        className={styles["draggable-dialog"]}
        target={
          isGrabbable && isHeaderClicked
            ? document.querySelector('div[class^="Dialog_property-dialog__"]')
            : ""
        }
        draggable={true}
        throttleDrag={0}
        onDragStart={({ set }) => {
          set(frame.translate);
        }}
        onDrag={({ target, beforeTranslate }) => {
          frame.translate = beforeTranslate;
          target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
        }}
        onDragEnd={({ target, isDrag, clientX, clientY }) => {}}
      />
      <div
        className={`${styles["property-dialog"]} ${
          styles["property-dialog--" + size]
        }`}
        style={cssPosition}
      >
        <div onMouseOver={handleHeaderClick} onMouseOut={handleHeaderClick}>
          <div
            className={`${styles["property-dialog__header"]} ${grabbableClassname}`}
          >
            <div className={styles["property-dialog__details-container"]}>
              <div className={styles["header-label"]}>
                {Icon ? <Icon className={styles["icon"]} /> : <></>}
                <h4>{headerLabel}</h4>
              </div>
              <div className={styles["header-buttons-container"]}>
                {/* {isGrabbable ? (
                    <>
                    <button disabled className={styles['drag-button']}>
                        <ZoomOutMapRounded />
                    </button>
                    </>
                    ) : (
                    <></>
                    )} */}
                <Button
                  icon={CloseRounded}
                  onClick={handleShowDialog}
                  variant="outline-primary-white"
                  size="circle"
                  isFlat={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles["property-dialog__body"]}>{dialogBody}</div>
        {handleParentAction || parentActionTo ? (
          <div className={styles["property-dialog__footer"]}>
            <div className={styles["parent-action-container"]}>
              <Button
                label={parentActionLabel}
                variant={parentActionButtonVariant}
                icon={parentActionIcon}
                onClick={handleParentAction}
                isDisabled={isDisabled}
                to={parentActionTo}
                isLoading={isLoading}
                isFlat={
                  isParentActionButtonFlat ? isParentActionButtonFlat : false
                }
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
