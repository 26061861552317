import React from "react";
import { useField } from "formik";

import styles from "./DropdownGroup.module.scss";
import { Dropdown, ItemProps } from "editor/components/base/Dropdown/Dropdown";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "xs" | "s" | "m" | "l";
  items: ItemProps[];
};

// Uses Formik by default unless value OR onChange are provided. Using
// Formik means it doesn't apply the changes automatically, but rather
// the whole form has to be submitted.
export default function DropdownGroup({
  name,
  label,
  placeholder,
  value,
  onSelect,
  size,
  items,
}: Props): JSX.Element {
  return (
    <FormikDropdownGroup
      name={name}
      label={label}
      placeholder={placeholder}
      size={size}
      items={items}
      onSelect={onSelect}
    />
  );
}

function FormikDropdownGroup({
  name,
  label,
  placeholder,
  size,
  items,
  onSelect,
}: Props): JSX.Element {
  const [, meta] = useField(name);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={styles["form__group"]}>
      <label htmlFor={name}>{label}</label>
      <Dropdown items={items} title={placeholder} onSelect={onSelect} />
      {isInvalid ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
}
