import React from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";

import TextFieldGroup from "../../components/components/TextFieldGroup";
import { IProperty, PropertyComponent } from "../types";
import { SocialMedia } from "../SocialMedia";

import "./SocialLinkProperty.scss";
import { useTranslation } from "react-i18next";

export class SocialLinkProperty implements IProperty {
  constructor(
    readonly socialMedia: SocialMedia,
    readonly url: string,
    readonly openNewTab?: boolean,
    readonly isConnected?: false
  ) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <SocialLinkPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): SocialLinkProperty {
    return new SocialLinkProperty(value.text, value.url, value.openNewTab);
  }
}

const SocialLinkPropertyComponent: PropertyComponent<SocialLinkProperty> = ({
  elementId,
  propertyKey,
  socialMedia,
  url,
  openNewTab,
}) => {
  const { t } = useTranslation();
  return (
    <div className="form__group">
      <Form className="form">
        <DropdownButton
          id="dropdown-item-button"
          title="text"
          label={t("dialog_105")}
          value={socialMedia.key}
        >
          <Dropdown.Item key={socialMedia.key} as="button">
            {socialMedia.label}
          </Dropdown.Item>
        </DropdownButton>
        <TextFieldGroup name="url" label="URL" value={url} />
      </Form>
    </div>
  );
};
