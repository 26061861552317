import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Container, Row, Col, Form } from "react-bootstrap";
import * as yup from "yup";

import styles from "./Admin.module.scss";
import { ISelectProps } from "./Select";
import { useDispatch, useSelector } from "react-redux";
import { isDesktopView } from "../admin/utils-country";

import { useTranslation } from "react-i18next";
import { resendEmailVerify, verifyToken } from "auth/authActions";
import { getUsername } from "auth/selectors";

const schema = yup.object({});

export type EmailVerifyFormValues = yup.InferType<typeof schema>;

export const EmailVerifyForm: React.FunctionComponent<ISelectProps> = ({
  handleSaveAndContinue,
  values,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const username = useSelector(getUsername);
  const [isDesktop, setIsDesktop] = useState(true);
  const [stateCode, setStateCode] = useState();
  const [stateIsVerified, setStateIsVerified] = useState(false);
  const [stateIsEmailVerifiedResult, setStateIsEmailVerifiedResult] = useState<
    boolean
  >();
  const [stateIsEmailResent, setStateIsEmailResent] = useState<
    undefined | boolean
  >(undefined);
  const [
    stateEmailVerifiedErrorMessage,
    setStateEmailVerifiedErrorMessage,
  ] = useState<string | null>();
  useEffect(() => {}, []);

  const handleCodeChange = () => {
    if (stateCode && username) {
      dispatch(verifyToken(stateCode!, username, handleVerifyEmail));
    }
  };

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, []);

  const onSubmit = (values: EmailVerifyFormValues) => {
    handleSaveAndContinue(values);
  };

  const handleVerifyEmail = (isVerified: any) => {
    setStateIsEmailVerifiedResult(true);
    if (isVerified === true || isVerified === "true") {
      setStateIsVerified(isVerified);
    } else {
      setStateIsVerified(false);
      setStateEmailVerifiedErrorMessage("Invalid token. Please try again.");
    }
  };

  const handleEmailVerify = () => {
    if (username) {
      dispatch(resendEmailVerify(username, handleEmailResent));
    }
  };

  const handleEmailResent = (isResent: boolean) => {
    setStateIsEmailResent(isResent);
  };
  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <h5>{t("email_verify")}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: isDesktop ? "1rem" : "0" }}>
            <Container>
              <Form.Group as={Row}>
                <Col>
                  <div
                    className={styles["setup-text"]}
                    style={{ marginBottom: "24px" }}
                  >
                    {t("email_verify_1")} <span>xxx@gmail.com</span>{" "}
                    {t("email_verify_2")}
                  </div>
                  <div
                    className={styles["setup-label"]}
                    style={{ marginTop: "12px" }}
                  >
                    {t("email_verify_3")}
                    <span>*</span>
                  </div>
                  <Form.Control
                    type="text"
                    name="code"
                    value={stateCode}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setStateCode(e.target.value);
                    }}
                    placeholder={t("email_verify_4")}
                    size="lg"
                    style={{ maxWidth: "240px" }}
                  />
                  <div className={styles["invalid"]}>
                    {stateIsEmailVerifiedResult}
                  </div>
                  <div>
                    {stateIsEmailVerifiedResult ? (
                      <>
                        {stateIsVerified === false ||
                        stateIsVerified.toString() === "false" ? (
                          <>
                            {stateEmailVerifiedErrorMessage}
                            <br />
                            <span>
                              {stateIsEmailResent ? (
                                <span
                                  onClick={handleEmailVerify}
                                  style={{ fontSize: 14, cursor: "pointer" }}
                                >
                                  Code resent.
                                </span>
                              ) : (
                                <span
                                  onClick={handleEmailVerify}
                                  style={{ fontSize: 14, cursor: "pointer" }}
                                >
                                  Having trouble with code? Click here to resend
                                  a new one.
                                </span>
                              )}
                            </span>
                          </>
                        ) : (
                          <>Verififed. Please click Next to continue.</>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Form.Group>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {stateIsVerified ? (
              <Button
                style={{ borderRadius: 32 }}
                type="submit"
                disabled={!stateIsEmailVerifiedResult}
              >
                {t("setup_page_15")}
              </Button>
            ) : (
              <Button
                style={{ borderRadius: 32 }}
                type="button"
                disabled={!stateCode}
                onClick={() => {
                  handleCodeChange();
                }}
              >
                {t("email_verify_5")}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
