import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import * as yup from "yup";

import { IStepProps, PurposeSocial } from "./Setup";

import { useTranslation } from "react-i18next";

import styles from "./Admin.module.scss";
import {
  BorderInnerRounded,
  ChatBubble,
  Facebook,
  HdRounded,
  Instagram,
  LinkedIn,
  Telegram,
  Twitter,
  WhatsApp,
  YoutubeSearchedForRounded,
} from "@material-ui/icons";

const schema = yup.object({
  purposeName: yup.string(),
  purposeDescription: yup.string(),
});

type Props = {
  methodName: string;
};

export type SetupStep3eFormValues = yup.InferType<typeof schema>;

export const SetupStep3eForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleSkip,
}) => {
  const { t } = useTranslation();
  const [stateSelectedMethod, setStateSelectedMethod] = useState<
    string | undefined
  >();
  const [stateSocial, setStateSocial] = useState<PurposeSocial>(values.social);
  const [placeHolder, setPlaceHolder] = useState<string | undefined>();
  const [placeIcon, setPlaceIcon] = useState<any>();
  const [stateInput, setStateInput] = useState<string | undefined>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (
      values &&
      (values.isPurposeIncreaseSocial === undefined ||
        values.isPurposeIncreaseSocial === false)
    ) {
      handleSaveAndContinue(values);
    }
  }, [values, handleSaveAndContinue]);

  const onSubmit = () => {
    values.social = stateSocial;
    handleSaveAndContinue(values);
  };

  const handleInputChange = (input: string) => {
    setStateInput(input);
  };

  const handleDropDownSelect = (selectedMethod: string) => {
    setStateSelectedMethod(selectedMethod);
    switch (selectedMethod) {
      case "facebook":
        setPlaceHolder(t("setup_wizard_44"));
        setPlaceIcon(<Facebook />);
        return;
      case "twitter":
        setPlaceHolder(t("setup_wizard_45"));
        setPlaceIcon(<Twitter />);
        return;
      case "instagram":
        setPlaceHolder(t("setup_wizard_46"));
        setPlaceIcon(<Instagram />);
        return;
      case "linkedin":
        setPlaceHolder(t("setup_wizard_47"));
        setPlaceIcon(<LinkedIn />);
        return;
      case "whatsapp":
        setPlaceHolder(t("setup_wizard_48"));
        setPlaceIcon(<WhatsApp />);
        return;
      case "twitch":
        setPlaceHolder(t("setup_wizard_49"));
        setPlaceIcon(<BorderInnerRounded />);
        return;
      case "tiktok":
        setPlaceHolder(t("setup_wizard_50"));
        setPlaceIcon(<HdRounded />);
        return;
      case "youtube":
        setPlaceHolder(t("setup_wizard_51"));
        setPlaceIcon(<YoutubeSearchedForRounded />);
        return;
      case "telegram":
        setPlaceHolder(t("setup_wizard_52"));
        setPlaceIcon(<Telegram />);
        return;
      case "discord":
        setPlaceHolder(t("setup_wizard_53"));
        setPlaceIcon(<ChatBubble />);
        return;
    }
  };

  const handleDeleteSocialMethod = () => {
    if (stateSelectedMethod && stateInput) {
      let social: PurposeSocial = values.social;
      switch (stateSelectedMethod) {
        case "facebook":
          social.facebook = undefined;
          break;
        case "twitter":
          social.twitter = undefined;
          break;
        case "instagram":
          social.instagram = undefined;
          break;
        case "linkedin":
          social.linkedin = undefined;
          break;
        case "whatsapp":
          social.whatsapp = undefined;
          break;
        case "twitch":
          social.twitch = undefined;
          break;
        case "tiktok":
          social.tiktok = undefined;
          break;
        case "youtube":
          social.youtube = undefined;
          break;
        case "telegram":
          social.telegram = undefined;
          break;
        case "discord":
          social.discord = undefined;
          break;
      }
      setStateSocial(social);
      setStateSelectedMethod(undefined);
      setStateInput(undefined);
      setIsUpdate(false);
    }
  };

  const handleAddContactMethod = () => {
    if (stateSelectedMethod && stateInput) {
      let social: PurposeSocial = values.social;
      switch (stateSelectedMethod) {
        case "facebook":
          social.facebook = stateInput;
          break;
        case "twitter":
          social.twitter = stateInput;
          break;
        case "instagram":
          social.instagram = stateInput;
          break;
        case "linkedin":
          social.linkedin = stateInput;
          break;
        case "whatsapp":
          social.whatsapp = stateInput;
          break;
        case "twitch":
          social.twitch = stateInput;
          break;
        case "tiktok":
          social.tiktok = stateInput;
          break;
        case "youtube":
          social.youtube = stateInput;
          break;
        case "telegram":
          social.telegram = stateInput;
          break;
        case "discord":
          social.discord = stateInput;
          break;
      }
      setStateSocial(social);
      setStateSelectedMethod(undefined);
      setStateInput(undefined);
      setIsUpdate(false);
    }
  };

  function EditButton({ methodName }: Props): JSX.Element {
    let buttonLabel;
    let buttonIcon: any;
    let inputValue: React.SetStateAction<string | undefined>;
    switch (methodName) {
      case "facebook":
        buttonLabel = t("setup_wizard_44");
        buttonIcon = <Facebook />;
        inputValue = values.social.facebook;
        break;
      case "twitter":
        buttonLabel = t("setup_wizard_45");
        buttonIcon = <Twitter />;
        inputValue = values.social.twitter;
        break;
      case "instagram":
        buttonLabel = t("setup_wizard_46");
        buttonIcon = <Instagram />;
        inputValue = values.social.instagram;
        break;
      case "linkedin":
        buttonLabel = t("setup_wizard_47");
        buttonIcon = <LinkedIn />;
        inputValue = values.social.linkedin;
        break;
      case "whatsapp":
        buttonLabel = t("setup_wizard_48");
        buttonIcon = <WhatsApp />;
        inputValue = values.social.whatsapp;
        break;
      case "twitch":
        buttonLabel = t("setup_wizard_49");
        buttonIcon = <BorderInnerRounded />;
        inputValue = values.social.twitch;
        break;
      case "tiktok":
        buttonLabel = t("setup_wizard_50");
        buttonIcon = <HdRounded />;
        inputValue = values.social.tiktok;
        break;
      case "youtube":
        buttonLabel = t("setup_wizard_51");
        buttonIcon = <YoutubeSearchedForRounded />;
        inputValue = values.social.youtube;
        break;
      case "telegram":
        buttonLabel = t("setup_wizard_52");
        buttonIcon = <Telegram />;
        inputValue = values.social.telegram;
        break;
      case "discord":
        buttonLabel = t("setup_wizard_53");
        buttonIcon = <ChatBubble />;
        inputValue = values.social.discord;
        break;
    }

    return (
      <Button
        type="button"
        key={methodName}
        style={{
          width: "100%",
          textAlign: "left",
          borderRadius: "32px",
          color: "#007bff",
          backgroundColor: "#fff",
          border: "1px solid #007bff",
          marginBottom: "12px",
        }}
        onClick={() => {
          setStateSelectedMethod(methodName);
          setStateInput(inputValue);
          setIsUpdate(true);
          setPlaceIcon(buttonIcon);
        }}
      >
        {buttonIcon} {t("setup_wizard_156")} {buttonLabel}
      </Button>
    );
  }

  function EditButtons(): JSX.Element {
    return (
      <div>
        {stateSocial.facebook ||
        stateSocial.twitter ||
        stateSocial.instagram ||
        stateSocial.linkedin ||
        stateSocial.whatsapp ||
        stateSocial.twitch ||
        stateSocial.tiktok ||
        stateSocial.youtube ||
        stateSocial.telegram ||
        stateSocial.discord ? (
          <>
            <div className={styles["setup-list-label"]}>
              {t("setup_wizard_109")}
            </div>
          </>
        ) : (
          <></>
        )}
        {stateSocial.facebook ? <EditButton methodName="facebook" /> : <></>}
        {stateSocial.twitter ? <EditButton methodName="twitter" /> : <></>}
        {stateSocial.instagram ? <EditButton methodName="instagram" /> : <></>}
        {stateSocial.linkedin ? <EditButton methodName="linkedin" /> : <></>}
        {stateSocial.whatsapp ? <EditButton methodName="whatsapp" /> : <></>}
        {stateSocial.twitch ? <EditButton methodName="twitch" /> : <></>}
        {stateSocial.tiktok ? <EditButton methodName="tiktok" /> : <></>}
        {stateSocial.youtube ? <EditButton methodName="youtube" /> : <></>}
        {stateSocial.telegram ? <EditButton methodName="telegram" /> : <></>}
        {stateSocial.discord ? <EditButton methodName="discord" /> : <></>}
        {stateSocial.facebook ||
        stateSocial.twitter ||
        stateSocial.instagram ||
        stateSocial.linkedin ||
        stateSocial.whatsapp ||
        stateSocial.twitch ||
        stateSocial.tiktok ||
        stateSocial.youtube ||
        stateSocial.telegram ||
        stateSocial.discord ? (
          <>
            <div
              style={{
                width: "100%",
                borderTop: "1px solid rgba(0, 0, 0, 0.125)",
                marginTop: "24px",
                marginBottom: "24px",
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <Form noValidate>
      <Modal.Header>
        <Modal.Title style={{ width: "100%" }}>
          <h6>{t("setup_wizard_81")}</h6>
          <div className={styles["setup-subheader"]}>
            <div className={styles["setup-subheader-main"]}>
              {t("setup_wizard_55")}
            </div>
            <div
              className={styles["setup-subheader-skip"]}
              onClick={() => {
                handleSkip(values);
              }}
            >
              {t("setup_wizard_4")}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row}>
          <Col>
            {stateSelectedMethod ? (
              <></>
            ) : (
              <>{isUpdate === true ? <></> : <EditButtons />}</>
            )}
            {stateSelectedMethod ? (
              <div style={{ marginTop: "12px" }}>
                <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                  {placeHolder}
                </Form.Label>
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{placeIcon}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="inlineFormInputGroup"
                    placeholder={placeHolder}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleInputChange(e.target.value);
                    }}
                    value={stateInput}
                  />
                </InputGroup>
                <Button
                  type="button"
                  disabled={!stateSelectedMethod || !stateInput}
                  style={{ borderRadius: 32, width: "100%", marginTop: "36px" }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleAddContactMethod();
                  }}
                >
                  {isUpdate === true
                    ? t("setup_wizard_56")
                    : t("setup_wizard_57")}
                </Button>
                {isUpdate === true ? (
                  <Button
                    type="button"
                    disabled={!stateSelectedMethod || !stateInput}
                    style={{
                      borderRadius: 32,
                      width: "100%",
                      marginTop: "12px",
                      borderColor: "",
                      border: "2px solid #df2935",
                      backgroundColor: "white",
                      color: "#df2935",
                    }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleDeleteSocialMethod();
                    }}
                  >
                    {t("setup_wizard_59")}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <>
                <ButtonGroup className={styles["dropdown_button_group"]}>
                  <DropdownButton
                    as={ButtonGroup}
                    title={
                      stateSelectedMethod
                        ? stateSelectedMethod
                        : t("setup_wizard_58")
                    }
                    id="bg-nested-dropdown"
                    className={styles["dropdown_button"]}
                  >
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "facebook" ? true : false}
                      eventKey="facebook"
                      onSelect={() => {
                        handleDropDownSelect("facebook");
                      }}
                    >
                      Facebook
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "twitter" ? true : false}
                      eventKey="twitter"
                      onSelect={() => {
                        handleDropDownSelect("twitter");
                      }}
                    >
                      Twitter
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={
                        stateSelectedMethod === "instagram" ? true : false
                      }
                      eventKey="instagram"
                      onSelect={() => {
                        handleDropDownSelect("instagram");
                      }}
                    >
                      Instagram
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "linkedin" ? true : false}
                      eventKey="linkedin"
                      onSelect={() => {
                        handleDropDownSelect("linkedin");
                      }}
                    >
                      Linkedin
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "whatsapp" ? true : false}
                      eventKey="whatsapp"
                      onSelect={() => {
                        handleDropDownSelect("whatsapp");
                      }}
                    >
                      Whatsapp
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "twitch" ? true : false}
                      eventKey="twitch"
                      onSelect={() => {
                        handleDropDownSelect("twitch");
                      }}
                    >
                      Twitch
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "tiktok" ? true : false}
                      eventKey="tiktok"
                      onSelect={() => {
                        handleDropDownSelect("tiktok");
                      }}
                    >
                      Tiktok
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "youtube" ? true : false}
                      eventKey="youtube"
                      onSelect={() => {
                        handleDropDownSelect("youtube");
                      }}
                    >
                      Youtube
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "telegram" ? true : false}
                      eventKey="telegram"
                      onSelect={() => {
                        handleDropDownSelect("telegram");
                      }}
                    >
                      Telegram
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "discord" ? true : false}
                      eventKey="discord"
                      onSelect={() => {
                        handleDropDownSelect("discord");
                      }}
                    >
                      Discord
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
              </>
            )}
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button
          type="button"
          onClick={
            stateSelectedMethod !== undefined
              ? () => {
                  setStateSelectedMethod(undefined);
                  setStateInput(undefined);
                  setIsUpdate(false);
                }
              : () => {
                  handleStepBack();
                }
          }
          style={{
            borderRadius: 32,
            color: "#007bff",
            backgroundColor: "white",
            borderColor: "#007bff",
          }}
        >
          {stateSelectedMethod !== undefined
            ? t("cancel")
            : t("login_page_back")}
        </Button>
        {stateSelectedMethod !== undefined ? (
          <Button
            type="button"
            disabled={
              !stateSocial.facebook &&
              !stateSocial.twitter &&
              !stateSocial.instagram &&
              !stateSocial.linkedin &&
              !stateSocial.whatsapp &&
              !stateSocial.twitch &&
              !stateSocial.tiktok &&
              !stateSocial.youtube &&
              !stateSocial.telegram &&
              !stateSocial.discord
            }
            style={{ opacity: 0 }}
            onClick={onSubmit}
          >
            {t("setup_page_15")}
          </Button>
        ) : (
          <Button
            type="button"
            disabled={
              !stateSocial.facebook &&
              !stateSocial.twitter &&
              !stateSocial.instagram &&
              !stateSocial.linkedin &&
              !stateSocial.whatsapp &&
              !stateSocial.twitch &&
              !stateSocial.tiktok &&
              !stateSocial.youtube &&
              !stateSocial.telegram &&
              !stateSocial.discord
            }
            style={{ borderRadius: 32 }}
            onClick={onSubmit}
          >
            {t("setup_page_15")}
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
};
