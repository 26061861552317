import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet, updateChallengeVariants } from "editor/states/layout";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { getPageSlug } from "editor/selectors";
import { State } from "store";
import { getChallenge } from "editor/states/selectors";
import { Formik, Form } from "formik";

import styles from "./ButtonProperty.module.scss";
import { useTranslation } from "react-i18next";

export class ButtonStyleProperty implements IProperty {
  constructor(readonly style: string, readonly isTreatment?: boolean) {}

  render(elementId: string, propertyKey: string) {
    return (
      <ButtonStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    return new ButtonStyleProperty(value.style, value.isTreatment);
  }
}

const ButtonStylePropertyComponent: PropertyComponent<ButtonStyleProperty> = ({
  elementId,
  propertyKey,
  style,
  isTreatment,
}) => {
  const dispatch = useDispatch();
  const pageSlug = useSelector(getPageSlug);
  const challenge = useSelector((state: State) =>
    getChallenge(state, pageSlug!)
  );
  const { t } = useTranslation();
  const radios = [
    { name: t("dialog_73"), value: "hobaw-btn-primary" },
    { name: t("dialog_74"), value: "hobaw-btn-outline-primary" },
  ];

  const handleStyleChange = (value: string) => {
    dispatch(
      propertySet(elementId, propertyKey, new ButtonStyleProperty(value))
    );
    if (challenge) {
      if (challenge.elementId === elementId) {
        dispatch(updateChallengeVariants(pageSlug!));
      } else if (!challenge.elementId && isTreatment) {
        dispatch(updateChallengeVariants(pageSlug!));
      }
    }
  };

  return (
    <div>
      <div className={styles["button-prop-container"]}>
        <Formik initialValues={{}} onSubmit={async (values) => {}}>
          <Form className="form">
            <div className="form__group">
              <ButtonGroup className="mb-2 navbar-style-group">
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="primary"
                    name="style"
                    value={radio.value}
                    checked={style === radio.value}
                    className={`${styles[`button-toggle`]} button-radio-hidden`}
                    onClick={(e: any) => handleStyleChange(radio.value)}
                  >
                    <Button
                      className={
                        radio.name === t("dialog_73")
                          ? "hobaw-btn hobaw-btn-primary"
                          : "hobaw-btn hobaw-btn-outline-primary"
                      }
                    >
                      {" "}
                      {t("save")}{" "}
                    </Button>
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
