import { PageResource } from "@urux/arm-of-dorne-api";

export type FetchPagesRequestAction = {
  type: "ADMIN_FETCH_PAGES_REQUEST";
  websiteSlug: string;
};

export const fetchPages = (websiteSlug: string): Action => {
  return {
    type: "ADMIN_FETCH_PAGES_REQUEST",
    websiteSlug,
  };
};

export type FetchPagesSuccessAction = {
  type: "ADMIN_FETCH_PAGES_SUCCESS";
  pages: PageResource[];
};

export const fetchPagesSuccess = (pages: PageResource[]): Action => {
  return {
    type: "ADMIN_FETCH_PAGES_SUCCESS",
    pages,
  };
};

export type FetchPagesFailureAction = {
  type: "ADMIN_FETCH_PAGES_FAILURE";
};

export const fetchPagesFailure = (): Action => {
  return {
    type: "ADMIN_FETCH_PAGES_FAILURE",
  };
};

export type DeletePageRequestAction = {
  type: "ADMIN_DELETE_PAGE_REQUEST";
  websiteSlug: string;
  pageSlug: string;
};

export const deletePage = (websiteSlug: string, pageSlug: string): Action => {
  return {
    type: "ADMIN_DELETE_PAGE_REQUEST",
    websiteSlug,
    pageSlug,
  };
};

export const ADMIN_DELETE_PAGE_SUCCESS = "ADMIN_DELETE_PAGE_SUCCESS";

export type DeletePageSuccessAction = {
  type: typeof ADMIN_DELETE_PAGE_SUCCESS;
};

export const deletePageSuccess = (): Action => {
  return {
    type: ADMIN_DELETE_PAGE_SUCCESS,
  };
};

export type DeletePageFailureAction = {
  type: "ADMIN_DELETE_PAGE_FAILURE";
};

export const deletePageFailure = (): Action => {
  return {
    type: "ADMIN_DELETE_PAGE_FAILURE",
  };
};

export type Action =
  // This is only needed for Typescript type check to pass. There has to be an
  // action with just the type property, and nothing else.
  | { type: "_" }
  | FetchPagesRequestAction
  | FetchPagesSuccessAction
  | FetchPagesFailureAction
  | DeletePageRequestAction
  | DeletePageSuccessAction
  | DeletePageFailureAction;
