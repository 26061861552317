export function tawkWidgetToggle(show: boolean) {
  // Ensure the Tawk object has initalized
  // @ts-ignore
  if (window.$_Tawk && window.$_Tawk.init) {
    show ? showWidget() : hideWidget();
  } else {
    // If the Tawk object didn't initilize, use a differnt method of loading
    if (window.Tawk_API) {
      window.Tawk_API.onLoad = function () {
        show ? showWidget() : hideWidget();
      };
    }
  }
}

export function toggleWidgetToggle(show: boolean) {
  // Ensure the Tawk object has initalized
  // @ts-ignore
  if (window.$_Tawk && window.$_Tawk.init) {
    show ? maximizeWidget() : minimizeWidget();
  } else {
    // If the Tawk object didn't initilize, use a differnt method of loading
    if (window.Tawk_API) {
      window.Tawk_API.onLoad = function () {
        show ? maximizeWidget() : minimizeWidget();
      };
    }
  }
}

export function setTawkAttributes(
  name: string,
  email: string,
  website: string
) {
  if (window.Tawk_API) {
    window.Tawk_API.onLoad = function () {
      window.Tawk_API.setAttributes(
        {
          name: name,
          email: email,
          website: website,
        },
        function (error: any) {}
      );
    };
  }

  // // Ensure the Tawk object has initalized
  // // @ts-ignore
  // if (window.$_Tawk && window.$_Tawk.init && window.$_Tawk.setAttributes) {
  //     window.Tawk_API.setAttributes({
  //         'name': name,
  //         'email': email,
  //         'website': website
  //     }, function (error: any) { });
  // } else {
  //     // If the Tawk object didn't initilize, use a differnt method of loading
  //     if (window.Tawk_API) {
  //         window.Tawk_API.onLoad = function () {
  //             window.Tawk_API.setAttributes({
  //                 'name': name,
  //                 'email': email,
  //                 'website': website
  //             }, function (error: any) { });

  //         };
  //     }
  // }
}

export function showWidget() {
  window.Tawk_API.showWidget();
}

export function hideWidget() {
  window.Tawk_API.hideWidget();
}

export function minimizeWidget() {
  window.Tawk_API.minimize();
}

export function maximizeWidget() {
  window.Tawk_API.maximize();
}
