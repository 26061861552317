import React from "react";

type Props = {
  styles: {
    readonly [key: string]: string;
  };
  logoIdSelected: string | undefined;
  selectedPrimaryColor: string | undefined;
  selectedSecondaryColor: string | undefined;
  selectedTernaryColor: string | undefined;
  iconSelected: any;
  logoText: string;
  websiteTitle: string;
  setLogoIdSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsLogoSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  setUseWhiteBg: React.Dispatch<React.SetStateAction<boolean>>;
  setPrimaryRadius?: React.Dispatch<React.SetStateAction<string>>;
  setSecondaryRadius?: React.Dispatch<React.SetStateAction<string>>;
  setPrimaryShadow?: React.Dispatch<React.SetStateAction<string>>;
  setPrimaryFont?: React.Dispatch<React.SetStateAction<string>>;
  setSecondaryFont?: React.Dispatch<React.SetStateAction<string>>;
};

function getFirstLetters(str: string) {
  const firstLetters = str
    .split(" ")
    .map((word) => word[0])
    .join("");

  return firstLetters;
}

export const LogoDisplaySection: React.FunctionComponent<Props> = ({
  styles,
  logoIdSelected,
  selectedPrimaryColor,
  selectedSecondaryColor,
  selectedTernaryColor,
  iconSelected,
  logoText,
  websiteTitle,
  setLogoIdSelected,
  setIsLogoSelected,
  setUseWhiteBg,
  setPrimaryRadius,
  setSecondaryRadius,
  setPrimaryShadow,
  setPrimaryFont,
  setSecondaryFont,
}) => {
  const setEmpty = () => {};

  return (
    <div className={styles["logo-select-container"]}>
      {/* LOGO WITH ONLY FIRST LETTER */}
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4m"]} ${
          logoIdSelected === "logo-type-4m" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4m");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("8px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("4px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Roboto", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Poppins", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Pacifico",
                fontWeight: 500,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4m-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Pacifico",
                fontWeight: 500,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              id="logo-type-4m-mono"
            >
              {iconSelected}
              <div
                style={{
                  paddingTop: "8px",
                }}
              >
                {logoText}
              </div>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedSecondaryColor,
              fontFamily: "Pacifico",
              fontWeight: 500,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
            id="logo-type-4m-main"
          >
            {iconSelected}
            <div
              style={{
                paddingTop: "8px",
              }}
            >
              {logoText}
            </div>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Pacifico",
                fontWeight: 500,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4m-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Pacifico",
                fontWeight: 500,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4m-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Pacifico",
                fontWeight: 500,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4m-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Pacifico",
                fontWeight: 500,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4m-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4m-og1200x627"
              style={{
                color: selectedSecondaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedSecondaryColor,
                  fontFamily: "Pacifico",
                  fontWeight: 500,
                  fontSize: "600px",
                  width: "600px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {iconSelected}
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    borderTopStyle: "double",
                    paddingTop: "20px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-1a"]} ${
          logoIdSelected === "logo-type-1a" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-1a");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("0.0px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("0.0px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.0") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Spartan", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Montserrat", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-1a-favicon"
            >
              {getFirstLetters(logoText)}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "3rem",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-1a-mono"
            >
              {getFirstLetters(logoText)}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Lexend",
              fontWeight: 600,
              fontSize: "5rem",
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-1a-main"
          >
            {getFirstLetters(logoText)}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-1a-favicon32"
            >
              {getFirstLetters(logoText)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-1a-favicon180"
            >
              {getFirstLetters(logoText)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-1a-favicon192"
            >
              {getFirstLetters(logoText)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-1a-favicon512"
            >
              {getFirstLetters(logoText)}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-1a-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  backgroundColor: "#fff",
                  fontFamily: "Lexend",
                  fontWeight: 600,
                  fontSize: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "10px solid " + selectedSecondaryColor,
                  marginRight: "20px",
                }}
              >
                {getFirstLetters(logoText)}
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Lexend",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4d"]} ${
          logoIdSelected === "logo-type-4d" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4d");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("6px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("3px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.4") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Lora", serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Arvo", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Bungee Shade",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4d-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Bungee Shade",
                fontWeight: 600,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4d-mono"
            >
              {logoText}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Bungee Shade",
              fontWeight: 600,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-4d-main"
          >
            {logoText}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Bungee Shade",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4d-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Bungee Shade",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4d-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Bungee Shade",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4d-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Bungee Shade",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4d-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4d-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Bungee Shade",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4l"]} ${
          logoIdSelected === "logo-type-4l" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4l");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("8px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("4px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Roboto", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Poppins", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Josefin Sans",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4l-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Josefin Sans",
                fontWeight: 600,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              id="logo-type-4l-mono"
            >
              {iconSelected}
              <div
                style={{
                  paddingTop: "8px",
                }}
              >
                {logoText}
              </div>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Josefin Sans",
              fontWeight: 600,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
            id="logo-type-4l-main"
          >
            {iconSelected}
            <div
              style={{
                paddingTop: "8px",
              }}
            >
              {logoText}
            </div>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Josefin Sans",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4l-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Josefin Sans",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4l-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Josefin Sans",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4l-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Josefin Sans",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4l-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4l-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Josefin Sans",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {iconSelected}
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    borderTopStyle: "double",
                    paddingTop: "20px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-1c"]} ${
          logoIdSelected === "logo-type-1c" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-1c");
          setUseWhiteBg(true);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("8px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("5px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Raleway", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Noto Sans JP", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "1rem",
                height: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedSecondaryColor,
                transform: "skew(-8deg, -8deg)",
                borderRadius: "4px",
                fontWeight: 600,
                fontSize: "1.4rem",
                overflow: "hidden",
                border: "1px solid " + selectedSecondaryColor,
              }}
              id="logo-type-1c-favicon"
            >
              <span
                style={{
                  transform: "skew(8deg, 8deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#cdcdcd",
                width: "2rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                transform: "skew(-8deg, -8deg)",
                borderRadius: "6px",
                fontWeight: 600,
                fontSize: "3rem",
                overflow: "hidden",
                border: "2px solid #fff",
              }}
              id="logo-type-1c-mono"
            >
              <span
                style={{
                  transform: "skew(8deg, 8deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: "#fff",
              width: "4rem",
              height: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selectedSecondaryColor,
              transform: "skew(-8deg, -8deg)",
              borderRadius: "8px",
              fontWeight: 600,
              fontSize: "6.5rem",
              overflow: "hidden",
              border: "2px solid " + selectedSecondaryColor,
            }}
            id="logo-type-1c-main"
          >
            <span
              style={{
                transform: "skew(8deg, 8deg)",
              }}
            >
              {getFirstLetters(logoText).charAt(0)}
            </span>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "28px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedSecondaryColor,
                transform: "skew(-8deg, -8deg)",
                borderRadius: "6px",
                fontWeight: 600,
                fontSize: "2.4rem",
                overflow: "hidden",
                border: "2px solid " + selectedSecondaryColor,
              }}
              id="logo-type-1c-favicon32"
            >
              <span
                style={{
                  transform: "skew(8deg, 8deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "150px",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedSecondaryColor,
                transform: "skew(-8deg, -8deg)",
                borderRadius: "32px",
                fontWeight: 600,
                fontSize: "15rem",
                overflow: "hidden",
                border: "6px solid " + selectedSecondaryColor,
              }}
              id="logo-type-1c-favicon180"
            >
              <span
                style={{
                  transform: "skew(8deg, 8deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "150px",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedSecondaryColor,
                transform: "skew(-8deg, -8deg)",
                borderRadius: "20px",
                fontWeight: 600,
                fontSize: "15rem",
                overflow: "hidden",
                border: "6px solid " + selectedSecondaryColor,
              }}
              id="logo-type-1c-favicon192"
            >
              <span
                style={{
                  transform: "skew(8deg, 8deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "460px",
                height: "460px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedSecondaryColor,
                transform: "skew(-8deg, -8deg)",
                borderRadius: "20px",
                fontWeight: 600,
                fontSize: "50rem",
                overflow: "hidden",
                border: "10px solid " + selectedSecondaryColor,
              }}
              id="logo-type-1c-favicon512"
            >
              <span
                style={{
                  transform: "skew(8deg, 8deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-1c-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#fff",
                  width: "400px",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: selectedSecondaryColor,
                  transform: "skew(-8deg, -8deg)",
                  borderRadius: "32px",
                  fontWeight: 600,
                  fontSize: "41rem",
                  overflow: "hidden",
                  border: "10px solid " + selectedSecondaryColor,
                }}
              >
                <span
                  style={{
                    transform: "skew(8deg, 8deg)",
                  }}
                >
                  {getFirstLetters(logoText).charAt(0)}
                </span>
              </div>
              <div
                style={{
                  color: selectedSecondaryColor,
                  backgroundColor: "#fff",
                  fontFamily: "Lexend",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-1e"]} ${
          logoIdSelected === "logo-type-1e" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-1e");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("0.0px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("0.0px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.0") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Lora", serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Libre Baskerville", serif`)
            : setEmpty();
        }}
        style={{
          color: selectedTernaryColor,
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedTernaryColor,
                border: `1px solid ${selectedTernaryColor}`,
                width: "1rem",
                height: "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                fontFamily: "Oranienbaum",
                fontWeight: 600,
              }}
              id="logo-type-1e-favicon"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                border: `2px solid #fff`,
                width: "2rem",
                height: "2.6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "2rem",
                fontFamily: "Oranienbaum",
                fontWeight: 600,
                color: "#fff",
              }}
              id="logo-type-1e-mono"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              border: `2px solid ${selectedTernaryColor}`,
              width: "3rem",
              height: "5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "4rem",
              fontFamily: "Oranienbaum",
              fontWeight: 600,
              color: selectedTernaryColor,
            }}
            id="logo-type-1e-main"
          >
            {getFirstLetters(logoText).charAt(0)}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "1.5rem",
                width: "24px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `2px solid ${selectedTernaryColor}`,
                fontFamily: "Oranienbaum",
                color: selectedTernaryColor,
              }}
              id="logo-type-1e-favicon32"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "120px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `4px solid ${selectedTernaryColor}`,
                fontFamily: "Oranienbaum",
                color: selectedTernaryColor,
              }}
              id="logo-type-1e-favicon180"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "120px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `4px solid ${selectedTernaryColor}`,
                fontFamily: "Oranienbaum",
                color: selectedTernaryColor,
              }}
              id="logo-type-1e-favicon192"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "30rem",
                width: "370px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `5px solid ${selectedTernaryColor}`,
                fontFamily: "Oranienbaum",
                color: selectedTernaryColor,
              }}
              id="logo-type-1e-favicon512"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-1e-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "23rem",
                  width: "17rem",
                  height: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `4px solid ${selectedTernaryColor}`,
                  fontFamily: "Oranienbaum",
                  color: selectedTernaryColor,
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </div>
              <div
                style={{
                  color: selectedTernaryColor,
                  fontFamily: "Oranienbaum",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* LOGO WITH ONLY ICON */}
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-2a"]} ${
          logoIdSelected === "logo-type-2a" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-2a");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("2px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("2px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.5") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Spartan", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Jost", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-2a-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "3rem",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-2a-mono"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Lexend",
              fontWeight: 600,
              fontSize: "5rem",
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-2a-main"
          >
            {iconSelected}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-2a-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-2a-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-2a-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-2a-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-2a-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  backgroundColor: "#fff",
                  fontFamily: "Lexend",
                  fontWeight: 600,
                  fontSize: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "10px solid " + selectedSecondaryColor,
                  marginRight: "20px",
                }}
              >
                {iconSelected}
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Lexend",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-2c"]} ${
          logoIdSelected === "logo-type-2c" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-2c");
          setUseWhiteBg(true);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("6px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("3px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.4") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Spartan", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Jost", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "1rem",
                height: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,
                borderRadius: "30px 30px 30px 0px",
                fontWeight: 600,
                fontSize: "1.4rem",
                overflow: "hidden",
                border: "1px solid " + selectedPrimaryColor,
              }}
              id="logo-type-2c-favicon"
            >
              <span style={{}}>{iconSelected}</span>
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#cdcdcd",
                width: "2rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                borderRadius: "30px 30px 30px 0px",
                fontWeight: 600,
                fontSize: "3rem",
                overflow: "hidden",
                border: "2px solid #fff",
              }}
              id="logo-type-2c-mono"
            >
              <span style={{}}>{iconSelected}</span>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: "#fff",
              width: "4rem",
              height: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selectedPrimaryColor,
              borderRadius: "30px 30px 30px 0px",
              fontWeight: 600,
              fontSize: "6.5rem",
              overflow: "hidden",
              border: "2px solid " + selectedPrimaryColor,
            }}
            id="logo-type-2c-main"
          >
            <span style={{}}>{iconSelected}</span>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "28px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,
                borderRadius: "30px 30px 30px 0px",
                fontWeight: 600,
                fontSize: "2.4rem",
                overflow: "hidden",
                border: "2px solid " + selectedPrimaryColor,
              }}
              id="logo-type-2c-favicon32"
            >
              <span style={{}}>{iconSelected}</span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "150px",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,
                borderRadius: "80px 80px 80px 0px",
                fontWeight: 600,
                fontSize: "15rem",
                overflow: "hidden",
                border: "6px solid " + selectedPrimaryColor,
              }}
              id="logo-type-2c-favicon180"
            >
              <span style={{}}>{iconSelected}</span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "150px",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,
                borderRadius: "80px 80px 80px 0px",
                fontWeight: 600,
                fontSize: "15rem",
                overflow: "hidden",
                border: "6px solid " + selectedPrimaryColor,
              }}
              id="logo-type-2c-favicon192"
            >
              <span style={{}}>{iconSelected}</span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "460px",
                height: "460px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,
                borderRadius: "230px 230px 230px 0px",
                fontWeight: 600,
                fontSize: "40rem",
                overflow: "hidden",
                border: "10px solid " + selectedPrimaryColor,
              }}
              id="logo-type-2c-favicon512"
            >
              <span style={{}}>{iconSelected}</span>
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-2c-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#fff",
                  width: "400px",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: selectedPrimaryColor,
                  borderRadius: "230px 230px 230px 0px",
                  fontWeight: 600,
                  fontSize: "36rem",
                  overflow: "hidden",
                  border: "10px solid " + selectedPrimaryColor,
                }}
              >
                <span style={{}}>{iconSelected}</span>
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  backgroundColor: "#fff",
                  fontFamily: "Lexend",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* LOGO WITH ONLY FIRST LETTER AND ICON */}
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-3a"]} ${
          logoIdSelected === "logo-type-3a" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-3a");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("0.0px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("0.0px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.5") : setEmpty();
          setPrimaryFont
            ? setPrimaryFont(`"Overpass", sans-serif`)
            : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Arvo", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3a-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 600,
                fontSize: "3rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3a-mono"
            >
              {iconSelected}
              {getFirstLetters(logoText)}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: `"Jost", sans-serif`,
              fontWeight: 600,
              fontSize: "5rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-3a-main"
          >
            {iconSelected}
            {getFirstLetters(logoText)}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3a-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3a-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3a-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3a-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-3a-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  backgroundColor: "#fff",
                  fontFamily: `"Jost", sans-serif`,
                  fontWeight: 600,
                  fontSize: "8rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "10px solid " + selectedSecondaryColor,
                  marginRight: "20px",
                  paddingRight: "20px",
                }}
              >
                {iconSelected}
                {getFirstLetters(logoText)}
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: `"Jost", sans-serif`,
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4h"]} ${
          logoIdSelected === "logo-type-4h" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4h");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("6px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("3px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.4") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Raleway", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Montserrat", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Angkor",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4h-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Angkor",
                fontWeight: 600,
                fontSize: "2.5rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "90px",
                flexDirection: "column",
              }}
              id="logo-type-4h-mono"
            >
              <div
                style={{
                  color: "#fff",
                  fontFamily: "Angkor",
                  fontWeight: 600,
                  fontSize: "2.5rem",
                  minWidth: "50px",
                  minHeight: "50px",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <span>{getFirstLetters(logoText).charAt(0)}</span>
                {iconSelected}
              </div>
              <span
                style={{
                  color: "#fff",
                  fontFamily: `"Arvo", sans-serif`,
                  fontWeight: 600,
                  fontSize: "0.8rem",
                  overflowWrap: "anywhere",
                  marginTop: 0,
                  display: "flex",
                  textAlign: "justify",
                  flexDirection: "column",
                }}
              >
                {logoText}
              </span>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Angkor",
              fontWeight: 600,
              fontSize: "5rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "180px",
            }}
            id="logo-type-4h-main"
          >
            <div
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Angkor",
                fontWeight: 600,
                fontSize: "5rem",
                minWidth: "100px",
                minHeight: "100px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <span>{getFirstLetters(logoText).charAt(0)}</span>
              {iconSelected}
            </div>
            <span
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Arvo", sans-serif`,
                fontWeight: 600,
                fontSize: "1.55rem",
                overflowWrap: "anywhere",
                marginTop: 0,
                display: "flex",
                textAlign: "justify",
              }}
            >
              {logoText}
            </span>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Angkor",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4h-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Angkor",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4h-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Angkor",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4h-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Angkor",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4h-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4h-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Angkor",
                  fontWeight: 600,
                  fontSize: "15rem",
                  minWidth: "100px",
                  minHeight: "100px",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <span>{getFirstLetters(logoText).charAt(0)}</span>
                {iconSelected}
              </div>
              <span
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: `"Arvo", sans-serif`,
                  fontWeight: 600,
                  fontSize: "3.55rem",
                  overflowWrap: "anywhere",
                  marginTop: 0,
                  display: "flex",
                  textAlign: "justify",
                }}
              >
                {logoText}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-3b"]} ${
          logoIdSelected === "logo-type-3b" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-3b");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("0.0px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("0.0px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.0") : setEmpty();
          setPrimaryFont
            ? setPrimaryFont(`"Overpass", sans-serif`)
            : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Arvo", sans-serif`)
            : setEmpty();
        }}
        style={{
          color: selectedTernaryColor,
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                backgroundColor: selectedSecondaryColor,
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                fontFamily: "Abel",
                fontWeight: 600,
              }}
              id="logo-type-3b-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                border: `2px solid #fff`,
                minWidth: "2.6rem",
                minHeight: "2.6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "2rem",
                fontFamily: "Abel",
                fontWeight: 600,
                color: "#fff",
                padding: "3px",
              }}
              id="logo-type-3b-mono"
            >
              {iconSelected}
              {getFirstLetters(logoText)}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: "#fff",
              backgroundColor: selectedSecondaryColor,
              minWidth: "5rem",
              minHeight: "5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "4rem",
              fontFamily: "Abel",
              fontWeight: 600,
            }}
            id="logo-type-3b-main"
          >
            <div
              style={{
                color: selectedSecondaryColor,
              }}
            >
              {iconSelected}
            </div>
            {getFirstLetters(logoText)}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "1.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Abel",
                color: "#fff",
                backgroundColor: selectedSecondaryColor,
              }}
              id="logo-type-3b-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Abel",
                color: "#fff",
                backgroundColor: selectedSecondaryColor,
              }}
              id="logo-type-3b-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Abel",
                color: "#fff",
                backgroundColor: selectedSecondaryColor,
              }}
              id="logo-type-3b-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "30rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Abel",
                color: "#fff",
                backgroundColor: selectedSecondaryColor,
              }}
              id="logo-type-3b-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-3b-og1200x627"
              style={{
                color: selectedSecondaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "15rem",
                  minWidth: "20rem",
                  minHeight: "20rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Abel",
                  color: "#fff",
                  backgroundColor: selectedSecondaryColor,
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    color: selectedSecondaryColor,
                    marginRight: "20px",
                  }}
                >
                  {iconSelected}
                </div>
                {getFirstLetters(logoText)}
              </div>
              <div
                style={{
                  color: selectedSecondaryColor,
                  fontFamily: "Abel",
                  fontWeight: 600,
                  fontSize: "600px",
                  maxWidth: "440px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "17%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4f"]} ${
          logoIdSelected === "logo-type-4f" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4f");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("10px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("6px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("1.0") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Raleway", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Poppins", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Calistoga",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4f-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Calistoga",
                fontWeight: 600,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4f-mono"
            >
              {iconSelected}
              {logoText}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedSecondaryColor,
              fontFamily: "Calistoga",
              fontWeight: 600,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-4f-main"
          >
            {iconSelected}
            {logoText}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Calistoga",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4f-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Calistoga",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4f-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Calistoga",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4f-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Calistoga",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4f-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4f-og1200x627"
              style={{
                color: selectedSecondaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedSecondaryColor,
                  fontFamily: "Calistoga",
                  fontWeight: 600,
                  fontSize: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                  }}
                >
                  {iconSelected}
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-3c"]} ${
          logoIdSelected === "logo-type-3c" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-3c");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("6px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("3px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.4") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Raleway", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Montserrat", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Pacifico",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3c-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Pacifico",
                fontWeight: 600,
                fontSize: "2.5rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3c-mono"
            >
              {iconSelected}
              <span>{getFirstLetters(logoText)}</span>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Pacifico",
              fontWeight: 600,
              fontSize: "5rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-3c-main"
          >
            {iconSelected}
            <span>{getFirstLetters(logoText)}</span>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Pacifico",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3c-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Pacifico",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3c-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Pacifico",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3c-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Pacifico",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-3c-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-3c-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  backgroundColor: "#fff",
                  fontFamily: "Pacifico",
                  fontWeight: 600,
                  fontSize: "8rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "10px solid " + selectedSecondaryColor,
                  marginRight: "20px",
                  paddingRight: "20px",
                }}
              >
                {iconSelected}
                <span>{getFirstLetters(logoText)}</span>
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Pacifico",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "22%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FULL TEXT */}
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4a"]} ${
          logoIdSelected === "logo-type-4a" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4a");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("8px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("4px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Spartan", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Poppins", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Montserrat", sans-serif`,
                lineHeight: "0.75",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4a-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: `"Montserrat", sans-serif`,
                lineHeight: "0.75",
                fontWeight: 900,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                width: "130px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "lowercase",
                padding: "4px",
                overflowWrap: "anywhere",
              }}
              id="logo-type-4a-mono"
            >
              {logoText}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: `"Montserrat", sans-serif`,
              lineHeight: "0.75",
              fontWeight: 900,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              width: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "lowercase",
              overflowWrap: "anywhere",
            }}
            id="logo-type-4a-main"
          >
            {logoText}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Montserrat", sans-serif`,
                lineHeight: "0.75",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4a-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Montserrat", sans-serif`,
                lineHeight: "0.75",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4a-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Montserrat", sans-serif`,
                lineHeight: "0.75",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4a-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Montserrat", sans-serif`,
                lineHeight: "0.75",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4a-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4a-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  backgroundColor: "#fff",
                  fontFamily: `"Montserrat", sans-serif`,
                  lineHeight: "0.75",
                  fontWeight: 600,
                  fontSize: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "10px solid " + selectedSecondaryColor,
                  marginRight: "40px",
                }}
              >
                {iconSelected}
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: `"Montserrat", sans-serif`,
                  lineHeight: "0.75",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "18%",
                    fontFamily: `"Montserrat", sans-serif`,
                    lineHeight: "0.75",
                    marginLeft: "40px",
                    fontWeight: 900,
                    width: 460,
                    overflowWrap: "anywhere",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4b"]} ${
          logoIdSelected === "logo-type-4b" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4b");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("4px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("2px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.4") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Copse", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Libre Baskerville", serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Barlow",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4b-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Barlow",
                fontWeight: 600,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4b-mono"
            >
              {iconSelected}
              {logoText}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedSecondaryColor,
              fontFamily: "Barlow",
              fontWeight: 600,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-4b-main"
          >
            {iconSelected}
            {logoText}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Barlow",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4b-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Barlow",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4b-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Barlow",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4b-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                fontFamily: "Barlow",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4b-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4b-og1200x627"
              style={{
                color: selectedSecondaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedSecondaryColor,
                  backgroundColor: "#fff",
                  fontFamily: "Barlow",
                  fontWeight: 600,
                  fontSize: "8rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: "10px solid " + selectedSecondaryColor,
                  marginRight: "20px",
                  paddingRight: "40px",
                }}
              >
                {iconSelected}
              </div>
              <div
                style={{
                  color: selectedSecondaryColor,
                  fontFamily: "Barlow",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-1b"]} ${
          logoIdSelected === "logo-type-1b" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-1b");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("4px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("2px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.3") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Lora", serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Montserrat", sans-serif`)
            : setEmpty();
        }}
        style={{
          color: selectedTernaryColor,
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedTernaryColor,
                border: `2px solid ${selectedTernaryColor}`,
                borderRadius: "50%",
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                fontFamily: "-webkit-body",
                fontWeight: 600,
              }}
              id="logo-type-1b-favicon"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                border: `3px solid #fff`,
                borderRadius: "50%",
                width: "2.6rem",
                height: "2.6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "2rem",
                fontFamily: "-webkit-body",
                fontWeight: 600,
                color: "#fff",
              }}
              id="logo-type-1b-mono"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              border: `4px solid ${selectedTernaryColor}`,
              borderRadius: "50%",
              width: "5rem",
              height: "5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "4rem",
              fontFamily: "-webkit-body",
              fontWeight: 600,
              color: selectedTernaryColor,
            }}
            id="logo-type-1b-main"
          >
            {getFirstLetters(logoText).charAt(0)}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "1.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `3px solid ${selectedTernaryColor}`,
                borderRadius: "50%",
                fontFamily: "-webkit-body",
                color: selectedTernaryColor,
              }}
              id="logo-type-1b-favicon32"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `7px solid ${selectedTernaryColor}`,
                borderRadius: "50%",
                fontFamily: "-webkit-body",
                color: selectedTernaryColor,
              }}
              id="logo-type-1b-favicon180"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `7px solid ${selectedTernaryColor}`,
                borderRadius: "50%",
                fontFamily: "-webkit-body",
                color: selectedTernaryColor,
              }}
              id="logo-type-1b-favicon192"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "30rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `10px solid ${selectedTernaryColor}`,
                borderRadius: "50%",
                fontFamily: "-webkit-body",
                color: selectedTernaryColor,
              }}
              id="logo-type-1b-favicon512"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-1b-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "23rem",
                  width: "25rem",
                  height: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `10px solid ${selectedTernaryColor}`,
                  borderRadius: "50%",
                  fontFamily: "-webkit-body",
                  color: selectedTernaryColor,
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </div>
              <div
                style={{
                  color: selectedTernaryColor,
                  fontFamily: "-webkit-body",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4c"]} ${
          logoIdSelected === "logo-type-4c" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4c");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("5px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("2.5px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Copse", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Arvo", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Arvo", sans-serif`,
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4c-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: `"Arvo", sans-serif`,
                fontWeight: 600,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4c-mono"
            >
              {logoText}
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: `"Arvo", sans-serif`,
              fontWeight: 600,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-4c-main"
          >
            {logoText}
            {iconSelected}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Arvo", sans-serif`,
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4c-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Arvo", sans-serif`,
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4c-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Arvo", sans-serif`,
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4c-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Arvo", sans-serif`,
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4c-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4c-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: `"Arvo", sans-serif`,
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
                {iconSelected}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4e"]} ${
          logoIdSelected === "logo-type-4e" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4e");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("8px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("4px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Roboto", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Poppins", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Sancreek",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4e-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Sancreek",
                fontWeight: 600,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              id="logo-type-4e-mono"
            >
              {iconSelected}
              <div
                style={{
                  paddingTop: "8px",
                  borderTopStyle: "double",
                }}
              >
                {logoText}
              </div>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Sancreek",
              fontWeight: 600,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
            id="logo-type-4e-main"
          >
            {iconSelected}
            <div
              style={{
                paddingTop: "8px",
                borderTopStyle: "double",
              }}
            >
              {logoText}
            </div>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Sancreek",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4e-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Sancreek",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4e-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Sancreek",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4e-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Sancreek",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4e-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4e-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Sancreek",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {iconSelected}
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    borderTopStyle: "double",
                    paddingTop: "20px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-1f"]} ${
          logoIdSelected === "logo-type-1f" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-1f");
          setUseWhiteBg(true);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("8px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("5px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Raleway", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Noto Sans JP", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "1rem",
                height: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,
                fontFamily: "Angkor",
                borderRadius: "50%",
                fontWeight: 600,
                fontSize: ".6rem",
                overflow: "hidden",
                border: "1px solid " + selectedPrimaryColor,
              }}
              id="logo-type-1f-favicon"
            >
              <span
                style={{
                  transform: "rotate(-15deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#cdcdcd",
                width: "3rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                fontFamily: "Angkor",
                borderRadius: "50%",
                fontWeight: 600,
                fontSize: "1.75rem",
                overflow: "hidden",
                border: "2px solid #fff",
              }}
              id="logo-type-1f-mono"
            >
              <span
                style={{
                  transform: "rotate(-15deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: "#fff",
              width: "7rem",
              height: "7rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selectedPrimaryColor,

              fontFamily: "Angkor",
              borderRadius: "50%",
              fontWeight: 600,
              fontSize: "4rem",
              overflow: "hidden",
              border: "2px solid " + selectedPrimaryColor,
            }}
            id="logo-type-1f-main"
          >
            <span
              style={{
                transform: "rotate(-15deg)",
              }}
            >
              {getFirstLetters(logoText).charAt(0)}
            </span>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "28px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,

                fontFamily: "Angkor",
                borderRadius: "50%",
                fontWeight: 600,
                fontSize: "1rem",
                overflow: "hidden",
                border: "2px solid " + selectedPrimaryColor,
              }}
              id="logo-type-1f-favicon32"
            >
              <span
                style={{
                  transform: "rotate(-15deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "150px",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,

                fontFamily: "Angkor",
                borderRadius: "50%",
                fontWeight: 600,
                fontSize: "6rem",
                overflow: "hidden",
                border: "6px solid " + selectedPrimaryColor,
              }}
              id="logo-type-1f-favicon180"
            >
              <span
                style={{
                  transform: "rotate(-15deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "150px",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,

                fontFamily: "Angkor",
                borderRadius: "50%",
                fontWeight: 600,
                fontSize: "6rem",
                overflow: "hidden",
                border: "6px solid " + selectedPrimaryColor,
              }}
              id="logo-type-1f-favicon192"
            >
              <span
                style={{
                  transform: "rotate(-15deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                width: "460px",
                height: "460px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedPrimaryColor,

                fontFamily: "Angkor",
                borderRadius: "50%",
                fontWeight: 600,
                fontSize: "19rem",
                overflow: "hidden",
                border: "10px solid " + selectedPrimaryColor,
              }}
              id="logo-type-1f-favicon512"
            >
              <span
                style={{
                  transform: "rotate(-15deg)",
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </span>
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-1f-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#fff",
                  width: "400px",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: selectedPrimaryColor,

                  fontFamily: "Angkor",
                  borderRadius: "50%",
                  fontWeight: 600,
                  fontSize: "16rem",
                  overflow: "hidden",
                  border: "10px solid " + selectedPrimaryColor,
                }}
              >
                <span
                  style={{
                    transform: "rotate(-15deg)",
                  }}
                >
                  {getFirstLetters(logoText).charAt(0)}
                </span>
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  backgroundColor: "#fff",
                  fontFamily: "Lexend",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4g"]} ${
          logoIdSelected === "logo-type-4g" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4g");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("6px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("3px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.4") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Lora", serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Arvo", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Rozha One",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4g-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Rozha One",
                fontWeight: 600,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                letterSpacing: "-2px",
              }}
              id="logo-type-4g-mono"
            >
              {logoText}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Rozha One",
              fontWeight: 600,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              letterSpacing: "-2px",
            }}
            id="logo-type-4g-main"
          >
            {logoText}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Rozha One",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4g-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Rozha One",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4g-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Rozha One",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4g-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Rozha One",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4g-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4g-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Rozha One",
                  fontWeight: 600,
                  fontSize: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  letterSpacing: "-6px",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4i"]} ${
          logoIdSelected === "logo-type-4i" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4i");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("10px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("6px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("1.0") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Raleway", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Poppins", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 400,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4i-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 400,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4i-mono"
            >
              {iconSelected}
              <div
                style={{
                  borderRight: "1px solid #fff",
                  marginRight: "6px",
                  width: "2px",
                  height: "50%",
                }}
              ></div>
              {logoText}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: `"Jost", sans-serif`,
              fontWeight: 400,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="logo-type-4i-main"
          >
            {iconSelected}
            <div
              style={{
                borderRight: `2px solid ${selectedPrimaryColor}`,
                marginRight: "10px",
                width: "4px",
                height: "50%",
              }}
            ></div>
            {logoText}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 400,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4i-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 400,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4i-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 400,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4i-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Jost", sans-serif`,
                fontWeight: 400,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4i-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4i-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: `"Jost", sans-serif`,
                  fontWeight: 400,
                  fontSize: "7rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {iconSelected}
                <div
                  style={{
                    borderRight: `2px solid ${selectedPrimaryColor}`,
                    marginRight: "10px",
                    width: "4px",
                    height: "90px",
                  }}
                ></div>
                {logoText}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-2b"]} ${
          logoIdSelected === "logo-type-2b" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-2b");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("4px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("4px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Spartan", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Jost", sans-serif`)
            : setEmpty();
        }}
        style={{
          color: selectedTernaryColor,
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
                borderRadius: "50%",
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                fontFamily: "Lexend",
                fontWeight: 600,
              }}
              id="logo-type-2b-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                border: `2px solid #fff`,
                borderRadius: "50%",
                width: "2.6rem",
                height: "2.6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "2rem",
                fontFamily: "Lexend",
                fontWeight: 600,
                color: "#fff",
              }}
              id="logo-type-2b-mono"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: "#fff",
              backgroundColor: selectedPrimaryColor,
              borderRadius: "50%",
              width: "5rem",
              height: "5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "4rem",
              fontFamily: "Lexend",
              fontWeight: 600,
            }}
            id="logo-type-2b-main"
          >
            {iconSelected}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "1.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                fontFamily: "Lexend",
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
              }}
              id="logo-type-2b-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                fontFamily: "Lexend",
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
              }}
              id="logo-type-2b-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                fontFamily: "Lexend",
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
              }}
              id="logo-type-2b-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "30rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                fontFamily: "Lexend",
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
              }}
              id="logo-type-2b-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-2b-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "23rem",
                  width: "25rem",
                  height: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  fontFamily: "Lexend",
                  color: "#fff",
                  backgroundColor: selectedPrimaryColor,
                }}
              >
                {iconSelected}
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Lexend",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4j"]} ${
          logoIdSelected === "logo-type-4j" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4j");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("6px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("3px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.4") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Raleway", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Montserrat", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4j-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: "2.5rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "80px",
                flexDirection: "column",
              }}
              id="logo-type-4j-mono"
            >
              <div
                style={{
                  color: "#fff",
                  fontFamily: "Quicksand",
                  fontWeight: 700,
                  fontSize: "2.5rem",
                  minWidth: "50px",
                  minHeight: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <span>{getFirstLetters(logoText).charAt(0)}</span>
                {iconSelected}
              </div>
              <span
                style={{
                  color: "#fff",
                  fontFamily: "Quicksand",
                  fontWeight: 700,
                  fontSize: "0.8rem",
                  overflowWrap: "anywhere",
                  marginTop: 0,
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              >
                {logoText}
              </span>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: "#fff",
              backgroundColor: selectedPrimaryColor,
              fontFamily: "Quicksand",
              fontWeight: 700,
              fontSize: "5rem",
              minWidth: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "180px",
              textAlign: "center",
            }}
            id="logo-type-4j-main"
          >
            <div
              style={{
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: "5rem",
                minWidth: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>{getFirstLetters(logoText).charAt(0)}</span>
              {iconSelected}
            </div>
            <span
              style={{
                fontFamily: "Quicksand",
                fontSize: "1.55rem",
                overflowWrap: "anywhere",
                marginTop: 0,
                display: "flex",
                textAlign: "center",
              }}
            >
              {logoText}
            </span>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4j-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4j-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4j-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4j-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4j-og1200x627"
              style={{
                backgroundColor: selectedPrimaryColor,
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "#fff",
                  backgroundColor: selectedPrimaryColor,
                  fontFamily: "Quicksand",
                  fontWeight: 700,
                  fontSize: "15rem",
                  minWidth: "100px",
                  minHeight: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>{getFirstLetters(logoText).charAt(0)}</span>
                {iconSelected}
              </div>
              <span
                style={{
                  color: "#fff",
                  fontFamily: "Quicksand",
                  fontWeight: 700,
                  fontSize: "3.55rem",
                  overflowWrap: "anywhere",
                  marginTop: 0,
                  display: "flex",
                  textAlign: "justify",
                }}
              >
                {logoText}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-3d"]} ${
          logoIdSelected === "logo-type-3d" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-3d");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("8px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("4px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.8") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Raleway", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Jost", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Spartan", sans-serif`,
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: "1px solid " + selectedPrimaryColor,
              }}
              id="logo-type-3d-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: `"Spartan", sans-serif`,
                fontWeight: 600,
                fontSize: "2.5rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                letterSpacing: "-2px",
              }}
              id="logo-type-3d-mono"
            >
              {iconSelected}
              <span>{getFirstLetters(logoText)}</span>
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: `"Spartan", sans-serif`,
              fontWeight: 600,
              fontSize: "5rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              letterSpacing: "-2px",
            }}
            id="logo-type-3d-main"
          >
            {iconSelected}
            <span>{getFirstLetters(logoText)}</span>
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Spartan", sans-serif`,
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: "3px solid " + selectedPrimaryColor,
              }}
              id="logo-type-3d-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Spartan", sans-serif`,
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: "8px solid " + selectedPrimaryColor,
              }}
              id="logo-type-3d-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Spartan", sans-serif`,
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: "7px solid " + selectedPrimaryColor,
              }}
              id="logo-type-3d-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: `"Spartan", sans-serif`,
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: "15px solid " + selectedPrimaryColor,
              }}
              id="logo-type-3d-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-3d-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  backgroundColor: "#fff",
                  fontFamily: `"Spartan", sans-serif`,
                  fontWeight: 600,
                  fontSize: "8rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  border: "15px solid " + selectedPrimaryColor,
                  marginRight: "20px",
                }}
              >
                {iconSelected}
              </div>
              <span>{getFirstLetters(logoText)}</span>
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: `"Spartan", sans-serif`,
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "22%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-4k"]} ${
          logoIdSelected === "logo-type-4k" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-4k");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("6px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("3px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.4") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Lora", serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Arvo", sans-serif`)
            : setEmpty();
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Monofett",
                fontWeight: 600,
                fontSize: "1rem",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4k-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                color: "#fff",
                fontFamily: "Monofett",
                fontWeight: 600,
                fontSize: "2rem",
                minWidth: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                letterSpacing: "-2px",
              }}
              id="logo-type-4k-mono"
            >
              {logoText}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: selectedPrimaryColor,
              fontFamily: "Monofett",
              fontWeight: 600,
              fontSize: "3rem",
              minWidth: "100px",
              minHeight: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              letterSpacing: "-2px",
            }}
            id="logo-type-4k-main"
          >
            {logoText}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Monofett",
                fontWeight: 600,
                fontSize: "2.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4k-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Monofett",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4k-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Monofett",
                fontWeight: 600,
                fontSize: "14rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4k-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedPrimaryColor,
                fontFamily: "Monofett",
                fontWeight: 600,
                fontSize: "40rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="logo-type-4k-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-4k-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Monofett",
                  fontWeight: 600,
                  fontSize: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  letterSpacing: "-6px",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-1d"]} ${
          logoIdSelected === "logo-type-1d" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-1d");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("2px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("2px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.5") : setEmpty();
          setPrimaryFont ? setPrimaryFont(`"Roboto", sans-serif`) : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Arvo", sans-serif`)
            : setEmpty();
        }}
        style={{
          color: selectedSecondaryColor,
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: selectedSecondaryColor,
                border: `2px solid ${selectedSecondaryColor}`,
                borderRadius: "50%",
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: ".9rem",
                fontFamily: "Beth Ellen",
                fontWeight: "bold",
              }}
              id="logo-type-1d-favicon"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                border: `3px solid #fff`,
                borderRadius: "50%",
                width: "2.6rem",
                height: "2.6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.4rem",
                fontFamily: "Beth Ellen",
                fontWeight: "bold",
                color: "#fff",
              }}
              id="logo-type-1d-mono"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              border: `5px solid ${selectedSecondaryColor}`,
              borderRadius: "50%",
              width: "5rem",
              height: "5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "3rem",
              fontFamily: "Beth Ellen",
              fontWeight: "bold",
              color: selectedSecondaryColor,
            }}
            id="logo-type-1d-main"
          >
            {getFirstLetters(logoText).charAt(0)}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `3px solid ${selectedSecondaryColor}`,
                borderRadius: "50%",
                fontFamily: "Beth Ellen",
                color: selectedSecondaryColor,
              }}
              id="logo-type-1d-favicon32"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: "bold",
                fontSize: "8rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `8px solid ${selectedSecondaryColor}`,
                borderRadius: "50%",
                fontFamily: "Beth Ellen",
                color: selectedSecondaryColor,
              }}
              id="logo-type-1d-favicon180"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: "bold",
                fontSize: "8rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `8px solid ${selectedSecondaryColor}`,
                borderRadius: "50%",
                fontFamily: "Beth Ellen",
                color: selectedSecondaryColor,
              }}
              id="logo-type-1d-favicon192"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: "bold",
                fontSize: "19rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `19px solid ${selectedSecondaryColor}`,
                borderRadius: "50%",
                fontFamily: "Beth Ellen",
                color: selectedSecondaryColor,
              }}
              id="logo-type-1d-favicon512"
            >
              {getFirstLetters(logoText).charAt(0)}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-1d-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "15rem",
                  width: "25rem",
                  height: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `14px solid ${selectedSecondaryColor}`,
                  borderRadius: "50%",
                  fontFamily: "Beth Ellen",
                  color: selectedSecondaryColor,
                }}
              >
                {getFirstLetters(logoText).charAt(0)}
              </div>
              <div
                style={{
                  color: selectedSecondaryColor,
                  fontFamily: "Beth Ellen",
                  fontWeight: 600,
                  fontSize: "600px",
                  width: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "18%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["logo-container"]} ${styles["logo-type-3e"]} ${
          logoIdSelected === "logo-type-3e" ? styles["logo-type-selected"] : ""
        }`}
        onClick={() => {
          setLogoIdSelected("logo-type-3e");
          setUseWhiteBg(false);
          setIsLogoSelected ? setIsLogoSelected(true) : setEmpty();
          setPrimaryRadius ? setPrimaryRadius("0.0px") : setEmpty();
          setSecondaryRadius ? setSecondaryRadius("0.0px") : setEmpty();
          setPrimaryShadow ? setPrimaryShadow("0.0") : setEmpty();
          setPrimaryFont
            ? setPrimaryFont(`"Overpass", sans-serif`)
            : setEmpty();
          setSecondaryFont
            ? setSecondaryFont(`"Arvo", sans-serif`)
            : setEmpty();
        }}
        style={{
          color: selectedTernaryColor,
        }}
      >
        <div className={styles["variants-container"]}>
          <div className={styles["favicon-container"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                fontFamily: "Kaisei Tokumin",
                fontWeight: 600,
              }}
              id="logo-type-3e-favicon"
            >
              {iconSelected}
            </div>
            <span className={styles["small-label"]}>Favicon</span>
          </div>
          <div className={styles["mono-container"]}>
            <div
              className={styles["mono-box"]}
              style={{
                border: `2px solid #fff`,
                minWidth: "2.7rem",
                minHeight: "2.7rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.5rem",
                fontFamily: "Kaisei Tokumin",
                fontWeight: 600,
                color: "#fff",
                padding: "3px",
              }}
              id="logo-type-3e-mono"
            >
              {iconSelected}
              {logoText}
            </div>
            <span className={styles["small-label"]}>Mono White</span>
          </div>
        </div>
        <div className={styles["logo-outer-box"]}>
          <div
            className={styles["logo-box"]}
            style={{
              color: "#fff",
              backgroundColor: selectedPrimaryColor,
              minWidth: "5rem",
              minHeight: "5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "3rem",
              fontFamily: "Kaisei Tokumin",
              fontWeight: 600,
              padding: ".5rem",
            }}
            id="logo-type-3e-main"
          >
            <div
              style={{
                color: selectedPrimaryColor,
              }}
            >
              {iconSelected}
            </div>
            {logoText}
          </div>
          <span className={styles["big-label"]}>Logo</span>
          <div className={styles["hidden-logos"]}>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "1.5rem",
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Kaisei Tokumin",
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
              }}
              id="logo-type-3e-favicon32"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Kaisei Tokumin",
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
              }}
              id="logo-type-3e-favicon180"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "10rem",
                width: "180px",
                height: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Kaisei Tokumin",
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
              }}
              id="logo-type-3e-favicon192"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              style={{
                fontWeight: 600,
                fontSize: "30rem",
                width: "512px",
                height: "512px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Kaisei Tokumin",
                color: "#fff",
                backgroundColor: selectedPrimaryColor,
              }}
              id="logo-type-3e-favicon512"
            >
              {iconSelected}
            </div>
            <div
              className={styles["favicon-box"]}
              id="logo-type-3e-og1200x627"
              style={{
                color: selectedPrimaryColor,
                backgroundColor: "#fff",
                width: "1200px",
                height: "627px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "15rem",
                  minWidth: "20rem",
                  minHeight: "20rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Kaisei Tokumin",
                  color: "#fff",
                  backgroundColor: selectedPrimaryColor,
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    color: selectedPrimaryColor,
                    marginRight: "20px",
                  }}
                >
                  {iconSelected}
                </div>
                {logoText}
              </div>
              <div
                style={{
                  color: selectedPrimaryColor,
                  fontFamily: "Kaisei Tokumin",
                  fontWeight: 600,
                  fontSize: "600px",
                  maxWidth: "440px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    lineHeight: "unset",
                    fontSize: "17%",
                    marginLeft: "40px",
                  }}
                >
                  {websiteTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
