import React from "react";

type Props = {
  id: string;
  label: string;
  icon: JSX.Element;
  url: string;
  style?: string;
  openNewTab?: boolean;
  isProduction: boolean;
};

export function FooterSocialNavItem({
  id,
  label,
  icon,
  url,
  style,
  openNewTab,
  isProduction,
}: Props): JSX.Element {
  const handleDisplayIcon = (id: string, style: string) => {
    if (id === "facebook") {
      switch (style) {
        case "1":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavFB2.svg"
              alt="Facebook link"
            />
          );
        case "2":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavFB3.svg"
              alt="Facebook link"
            />
          );
        case "3":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavFB4.svg"
              alt="Facebook link"
            />
          );
      }
    } else if (id === "twitter") {
      switch (style) {
        case "1":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavTW2.svg"
              alt="Twitter link"
            />
          );
        case "2":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavTW3.svg"
              alt="Twitter link"
            />
          );
        case "3":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavTW4.svg"
              alt="Twitter link"
            />
          );
      }
    } else if (id === "linkedin") {
      switch (style) {
        case "1":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavLK2.svg"
              alt="LinkedIn link"
            />
          );
        case "2":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavLK3.svg"
              alt="LinkedIn link"
            />
          );
        case "3":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavLK4.svg"
              alt="LinkedIn link"
            />
          );
      }
    } else if (id === "instagram") {
      switch (style) {
        case "1":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavINS2.svg"
              alt="Instagram link"
            />
          );
        case "2":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavINS3.svg"
              alt="Instagram link"
            />
          );
        case "3":
          return (
            <img
              src="https://www.goodweb.app/img/WhiteSocialNavINS4.svg"
              alt="Instagram link"
            />
          );
      }
    }
  };
  let linkUrl = "#" + url; // For Editor
  if (isProduction) {
    linkUrl = url;
    if (!linkUrl.startsWith("#") && !linkUrl.startsWith("/")) {
      linkUrl = "http://" + url;
    }
  } else {
    linkUrl = "#" + url;
  }
  return (
    <a
      className={"social-nav__item style-" + style}
      href={url}
      target={openNewTab ? "_blank" : "_self"}
      style={{ pointerEvents: isProduction ? "auto" : "none" }}
    >
      {style === "4" || style === undefined ? (
        <label>{label}</label>
      ) : (
        // THIS SHOULD USE icon variable
        <div className="icon">{handleDisplayIcon(id, style)}</div>
      )}
    </a>
  );
}
