import React from "react";

import { ElementComponentProps } from "../types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import { ImageProperty } from "editor/properties/ImageProperty/ImageProperty";

export type GalleryProperties = {
  galleryImages: ImageProperty[];
  galleryStyle: string;
  width: number;
  backgroundColorProperty: string;
};

export function Gallery({
  properties: { galleryImages, galleryStyle, width, backgroundColorProperty },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<GalleryProperties>): JSX.Element {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: backgroundColorProperty,
      }}
    >
      <section
        {...props}
        ref={innerRef}
        className={
          className +
          "container jq-articles articles-style-" +
          "articlesStyle" + //style
          " section-size-" +
          width +
          " acrticles-alignment-" +
          "acrticlesAlignment" // style
        }
      >
        {galleryStyle && galleryStyle === "2" ? (
          <>
            <div className="gg-container">
              <div className="gg-box">
                {galleryImages ? (
                  galleryImages.map((galleryImage) => (
                    <img
                      src={galleryImage.imgUrl}
                      alt={galleryImage.imageText}
                      className={"rs sp"}
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            <script
              type="text/javascript"
              src="js/grid-gallery.min.js"
            ></script>
          </>
        ) : (
          <>
            <div className="gg-style-2-gallery">
              <div className="gg-style-2-panel-main">
                <img
                  src={galleryImages[0].imgUrl}
                  alt={galleryImages[0].imageText}
                  id="selected-gallery-2"
                  className={"rs sp"}
                />
              </div>
              <div className="gg-style-2-thumbs">
                {galleryImages ? (
                  galleryImages.map((galleryImage) => (
                    <img
                      src={galleryImage.imgUrl}
                      alt={galleryImage.imageText}
                      className={"rs sp"}
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            <script
              type="text/javascript"
              src="js/grid-gallery-2.min.js"
            ></script>
          </>
        )}
      </section>
    </div>
  );
}

export const galleryElementKey = "gallery";

elementComponentRegistry.register(galleryElementKey, Gallery);
