import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "editor/components/components/Dialog/Dialog";
import * as yup from "yup";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import TextFieldGroup from "editor/components/components/TextFieldGroup/TextFieldGroup";
import {
  AddRounded,
  FlashOnRounded,
  LinkOffRounded,
  LinkRounded,
} from "@material-ui/icons";
import { Button } from "editor/components/base/Button/Button";
import {
  fetchAvailablePropertyIds,
  addPropertyIdsRequest,
  fetchUsedPropertyIds,
  deletePropertyRequest,
  unlinkPropertyRequest,
  linkPropertyRequest,
  getAllUnlinkedWebsitesRequest,
} from "editor/states/website";
import styles from "./Admin.module.scss";
import { Table } from "react-bootstrap";
import { ChangeEvent } from "react";
import {
  getAvailablePropertyIds,
  getUsedPropertyIds,
  getAllUnlinkedWebsites,
} from "editor/states/selectors";
import DropdownGroup from "editor/components/components/DropdownGroup/DropdownGroup";
import { ItemProps } from "editor/components/base/Dropdown/Dropdown";

const schema = yup.object({
  propertyId: yup.string().required("Property ID is required"),
});

export const SAConsole: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const availablePropertyIds = useSelector(getAvailablePropertyIds);
  const unlinkedWebsites = useSelector(getAllUnlinkedWebsites);
  const usedPropertyIds = useSelector(getUsedPropertyIds);
  const [showAddPropertyDialog, setShowAddPropertyDialog] = useState(false);
  const [showLinkPropertyDialog, setShowLinkPropertyDialog] = useState(false);
  const [propertyIdToAdd, setPropertyIdToAdd] = useState("");
  const [propertyIdError, setPropertyIdError] = useState("");
  const [measurementIdToAdd, setMeasurementIdToAdd] = useState("");
  const [measurementIdError, setMeasurementIdError] = useState("");
  const [websiteToLink, setWebsiteToLink] = useState("");
  const [websiteTitleToLink, setWebsiteTitleToLink] = useState("");
  const [propertyToLink, setPropertyToLink] = useState("");

  useEffect(() => {
    dispatch(fetchAvailablePropertyIds());
    dispatch(fetchUsedPropertyIds());
    dispatch(getAllUnlinkedWebsitesRequest());
  }, [dispatch]);

  const handlePropertyAdd = (): void => {
    let isNotValid = false;
    if (propertyIdToAdd === "" || !propertyIdToAdd.trim()) {
      setPropertyIdError("Property ID can not be blank");
      isNotValid = true;
    }

    if (measurementIdToAdd === "" || !measurementIdToAdd.trim()) {
      setMeasurementIdError("Measurement ID can not be blank");
      isNotValid = true;
    }

    if (!isNotValid) {
      dispatch(addPropertyIdsRequest(propertyIdToAdd, measurementIdToAdd));
      handleShowAddPropertyDialog();
    }
  };

  const handleLink = (): void => {};

  const handlePropertyDelete = (propertyId: string): void => {
    if (propertyId !== undefined) {
      dispatch(deletePropertyRequest(propertyId));
    }
  };

  const handlePropertyUnlink = (propertyId: string): void => {
    if (propertyId !== undefined) {
      dispatch(unlinkPropertyRequest(propertyId));
    }
  };

  const handlePropertyLink = (): void => {
    if (propertyToLink !== undefined && websiteToLink !== undefined) {
      dispatch(linkPropertyRequest(propertyToLink, websiteToLink));
    }
  };

  const handlePropertyAddChange = (event: ChangeEvent<HTMLInputElement>) => {
    let tempPropIdsString = event.target.value;
    setPropertyIdError("");
    if (!tempPropIdsString.trim()) {
      setPropertyIdToAdd("");
    } else if (tempPropIdsString !== undefined) {
      tempPropIdsString = tempPropIdsString.replace(/\s/g, "");
      setPropertyIdToAdd(tempPropIdsString);
    }
  };

  const handleMeasurementAddChange = (event: ChangeEvent<HTMLInputElement>) => {
    let tempMeaIdsString = event.target.value;
    setMeasurementIdError("");
    if (!tempMeaIdsString.trim()) {
      setMeasurementIdToAdd("");
    } else if (tempMeaIdsString !== undefined) {
      tempMeaIdsString = tempMeaIdsString.replace(/\s/g, "");
      setMeasurementIdToAdd(tempMeaIdsString);
    }
  };

  const handleShowAddPropertyDialog = () => {
    showAddPropertyDialog
      ? setShowAddPropertyDialog(false)
      : setShowAddPropertyDialog(true);
    showAddPropertyDialog
      ? setShowAddPropertyDialog(false)
      : setShowAddPropertyDialog(true);
  };

  const handlePropertySelect = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPropertyToLink("" + e);
  };

  const handleShowLinkDialog = (
    websiteToLink: string,
    websiteTitleToLink: string
  ) => {
    handleShowLinkPropertyDialog();
    setWebsiteToLink(websiteToLink);
    setWebsiteTitleToLink(websiteTitleToLink);
  };

  const handleShowLinkPropertyDialog = () => {
    if (!showLinkPropertyDialog) {
      setPropertyToLink("");
      setWebsiteTitleToLink("");
      setWebsiteToLink("");
    }
    showLinkPropertyDialog
      ? setShowLinkPropertyDialog(false)
      : setShowLinkPropertyDialog(true);
    showLinkPropertyDialog
      ? setShowLinkPropertyDialog(false)
      : setShowLinkPropertyDialog(true);
  };

  const AddPropertyDialog = (
    <Formik
      validationSchema={schema}
      onSubmit={handlePropertyAdd}
      initialValues={{
        propertyId: "",
        measurementId: "",
      }}
    >
      <Form>
        <TextFieldGroup
          name="propertyId"
          label="Property ID"
          onChange={handlePropertyAddChange}
        />
        <Form.Text className="text-muted">E.G. 123456789</Form.Text>
        <p className="text-danger">{propertyIdError}</p>
        <TextFieldGroup
          name="measurementId"
          label="Measurement ID"
          onChange={handleMeasurementAddChange}
        />
        <Form.Text className="text-muted">E.G. G-AB1C2DEFGH</Form.Text>
        <p className="text-danger">{measurementIdError}</p>
      </Form>
    </Formik>
  );

  const dropdownPageItems = availablePropertyIds.map(
    (propertyId): ItemProps => ({
      key: propertyId.propertyId,
      eventKey: propertyId.propertyId,
      itemLabel: propertyId.propertyId,
      onClickOfItem: () => setPropertyToLink(propertyId.propertyId),
    })
  );

  const LinkWebsiteToPopertyDialog = (
    <Formik
      validationSchema={schema}
      onSubmit={handleLink}
      initialValues={{
        propertyId: "",
      }}
    >
      <Form>
        <DropdownGroup
          label={"Property ID for Website: " + websiteTitleToLink}
          items={dropdownPageItems}
          name={"propertyId"}
          placeholder={
            propertyToLink !== "" ? propertyToLink : "Select Property ID"
          }
          onSelect={handlePropertySelect}
        />
      </Form>
    </Formik>
  );

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <FlashOnRounded />
          <h3>Super Admin Console</h3>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card"]}>
          <div className={styles["admin__card-header"]}>
            <h4>Available GA IDs ({availablePropertyIds.length})</h4>
            <Button
              label="Add"
              variant="none-outline"
              size="small"
              isFlat={true}
              icon={AddRounded}
              onClick={handleShowAddPropertyDialog}
            />
          </div>
          <div>
            <Table hover>
              <thead>
                <tr>
                  <td>#</td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Property ID
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Action
                  </td>
                </tr>
              </thead>
              <tbody>
                {availablePropertyIds !== undefined ? (
                  availablePropertyIds.map((availablePropertyId) => (
                    <tr key={availablePropertyId.gaPropertyId}>
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        {availablePropertyId.gaPropertyId}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {availablePropertyId.propertyId}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          label="Delete"
                          variant="none-outline-danger"
                          size="small"
                          isFlat={true}
                          onClick={() =>
                            handlePropertyDelete(availablePropertyId.propertyId)
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className={styles["admin__content-card"]}>
          <div className={styles["admin__card-header"]}>
            <h4>Unlinked Websites ({unlinkedWebsites.length})</h4>
          </div>
          <div>
            <Table hover>
              <thead>
                <tr>
                  <td>Website</td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Action
                  </td>
                </tr>
              </thead>
              <tbody>
                {unlinkedWebsites !== undefined ? (
                  unlinkedWebsites.map((unlinkedWebsites) => (
                    <tr key={unlinkedWebsites.slug}>
                      <td>{unlinkedWebsites.title}</td>
                      <td
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          label="Link"
                          variant="none-outline"
                          size="small"
                          isFlat={true}
                          icon={LinkRounded}
                          onClick={() =>
                            handleShowLinkDialog(
                              unlinkedWebsites.slug,
                              unlinkedWebsites.title
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className={styles["admin__content-card--big"]}>
          <div className={styles["admin__card-header"]}>
            <h4>Websites & GA IDs</h4>
          </div>
          <div>
            <Table hover>
              <thead>
                <tr>
                  <td>Website</td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Property ID
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Action
                  </td>
                </tr>
              </thead>
              <tbody>
                {usedPropertyIds !== undefined ? (
                  usedPropertyIds.map((usedPropertyId) => (
                    <tr key={usedPropertyId.gaPropertyId}>
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        {usedPropertyId.websiteTitle}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        {usedPropertyId.propertyId}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          label="Unlink"
                          variant="none-outline-danger"
                          size="small"
                          isFlat={true}
                          icon={LinkOffRounded}
                          onClick={() =>
                            handlePropertyUnlink(usedPropertyId.propertyId)
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {showAddPropertyDialog ? (
        <Dialog
          dialogBody={AddPropertyDialog}
          icon={AddRounded}
          headerLabel={"Add GA Property ID"}
          handleShowDialog={handleShowAddPropertyDialog}
          isShowBg={true}
          isShowDialog={true}
          parentActionLabel={"Add GA Property"}
          parentActionButtonVariant={"primary"}
          handleParentAction={handlePropertyAdd}
          isGrabbable={false}
          size={"medium"}
          cssPosition={{
            left: (window.innerWidth - 400) / 2,
            top: window.innerHeight / 8,
          }}
        />
      ) : (
        <></>
      )}
      {showLinkPropertyDialog ? (
        <Dialog
          dialogBody={LinkWebsiteToPopertyDialog}
          icon={LinkRounded}
          headerLabel={"Link Website to GA"}
          handleShowDialog={handleShowLinkPropertyDialog}
          isShowBg={true}
          isShowDialog={true}
          parentActionLabel={"Link"}
          parentActionButtonVariant={"primary"}
          handleParentAction={handlePropertyLink}
          isGrabbable={false}
          size={"medium"}
          cssPosition={{
            left: (window.innerWidth - 400) / 2,
            top: window.innerHeight / 8,
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
