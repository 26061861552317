import { ImageRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { imageElementKey, ImageProperties } from "common/elements/base/Image";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { ImageSourceProperty } from "editor/properties/ImageSourceProperty/ImageSourceProperty";
import TextProperty from "editor/properties/TextProperty";
import { Tabs, Tab } from "react-bootstrap";
import { Formik, Form } from "formik";

import styles from "./Image.module.scss";
import "./Image.scss";
import { DimensionProperty } from "editor/properties";
import { useTranslation } from "react-i18next";

export class ImageElement {
  static readonly elementKey = imageElementKey;
  static readonly label = "element_image_label";
  static readonly icon = ImageRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementPreviewUrl =
    "/img/add-image-element-preview-default.svg";
  static readonly elementDescription = "element_image_description.";
  static readonly dialogSize = "large";
  static readonly dialogPositionStatic = false;
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<ImageProperties> = {
    imageStyle: ImageStyleProperty,
    imageText: TextProperty,
    imgUrl: ImageSourceProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <ImagePropertiesComponent element={element} />;
  }

  static create(properties: {
    imageStyle: any;
    imageText: string;
    imgUrl: string | undefined;
    dimension?: DimensionProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const ImagePropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const renderer = new PropertyRenderer<typeof ImageElement.properties>(
    ImageElement.properties,
    element
  );
  const { t } = useTranslation();
  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="links">
        <Tab eventKey="links" title={t("dialog_96")} tabClassName="single-tab">
          <Formik initialValues={{}} onSubmit={async (values) => {}}>
            <Form className="form">{renderer.render("imgUrl")}</Form>
          </Formik>
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(ImageElement);
