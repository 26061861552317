import { ViewModuleRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { FormContainerStyleProperty } from "editor/properties/FormContainerStyleProperty/FormContainerStyleProperty";
import {
  formContainerKey,
  FormContainerProperties,
} from "common/elements/components/FormContainer";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./FormContainerElement.module.scss";
import {
  DimensionProperty,
  FormConfigurationProperty,
} from "editor/properties";
import { useTranslation } from "react-i18next";

export class FormContainerElement {
  static readonly elementKey = formContainerKey;
  static readonly label = "element_formcontainer_label";
  static readonly icon = ViewModuleRounded;
  static readonly allowedChildren = ["form-group", "google-maps"];
  static readonly allowedToolbarPositions = [];
  static readonly elementDescription = "element_formcontainer_description";
  static readonly elementPreviewUrl = "./img/default-image.svg";
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<FormContainerProperties> = {
    formContainerStyle: FormContainerStyleProperty,
    formConfigProperty: FormConfigurationProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <FormContainerPropertiesComponent element={element} />;
  }

  static create(properties: {
    formContainerStyle: string;
    formConfigProperty: FormConfigurationProperty;
    dimension?: DimensionProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const FormContainerPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof FormContainerElement.properties>(
    FormContainerElement.properties,
    element
  );
  const { t } = useTranslation();
  return (
    <div className={styles["properties__container"]}>
      <div className={styles["form-group"]}>
        <Tabs id="field-config" defaultActiveKey="config">
          <Tab eventKey="config" title={t("dialog_77")}>
            {renderer.render("formConfigProperty")}
          </Tab>
          <Tab eventKey="alignment" title={t("dialog_44")}>
            {renderer.render("formContainerStyle")}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

elementDefinitionRegistry.register(FormContainerElement);
