import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type CopyrightProperties = {
  copyrightStyle: { style: string };
  copyrightText: { label: string };
};

export function Copyright({
  properties: { copyrightStyle, copyrightText },
  className,
  ...props
}: ElementComponentProps<CopyrightProperties>): JSX.Element {
  return (
    <div className="copyright-container">
      <span
        {...props}
        className={
          className + "small copyright-container-style" + copyrightStyle
        }
      >
        {copyrightText}. <a href="https://www.trygoodweb.com" target="_blank" style={{ color: "inherit" }}>Built using GoodWeb</a>
      </span>
    </div>
  );
}

export const copyrightElementKey = "copyright";

elementComponentRegistry.register(copyrightElementKey, Copyright);
