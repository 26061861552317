import { Reducer } from "redux";

import {
  Action,
  StartRequestAction,
  FinishRequestAction,
  FinishRequestWithErrorsAction,
  AuthStartRequestAction,
  AuthFinishRequestAction,
  AuthFinishRequestWithErrorsAction,
  ResetAuthErrorRequestAction,
} from "./apiActions";

export type State = {
  requestInProgress: boolean;
  authState: boolean;
  error?: string;
};

const initialState: State = {
  requestInProgress: false,
  authState: false,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case "API_START_REQUEST":
      return handleStartRequest(state, action);

    case "API_FINISH_REQUEST":
      return handleFinishRequest(state, action);

    case "API_FINISH_REQUEST_WITH_ERRORS":
      return handleFinishRequestWithErrors(state, action);

    case "API_AUTH_START_REQUEST":
      return handleAuthStartRequest(state, action);

    case "API_AUTH_FINISH_REQUEST":
      return handleAuthFinishRequest(state, action);

    case "API_AUTH_FINISH_REQUEST_WITH_ERRORS":
      return handleAuthFinishRequestWithErrors(state, action);

    case "API_AUTH_RESET_ERROR_REQUEST":
      return handleAuthResetErrorRequest(state, action);

    default:
      return state;
  }
};

const handleAuthResetErrorRequest = (
  state: State,
  _: ResetAuthErrorRequestAction
): State => {
  return {
    ...state,
    error: undefined,
  };
};

const handleStartRequest = (state: State, _: StartRequestAction): State => {
  return {
    ...state,
    requestInProgress: true,
    error: undefined,
  };
};

const handleFinishRequest = (state: State, _: FinishRequestAction): State => {
  return {
    ...state,
    requestInProgress: false,
    error: undefined,
  };
};

const handleFinishRequestWithErrors = (
  state: State,
  action: FinishRequestWithErrorsAction
): State => {
  return {
    ...state,
    requestInProgress: false,
    error: action.error,
  };
};

const handleAuthStartRequest = (
  state: State,
  _: AuthStartRequestAction
): State => {
  return {
    ...state,
    authState: true,
    error: undefined,
  };
};

const handleAuthFinishRequest = (
  state: State,
  _: AuthFinishRequestAction
): State => {
  return {
    ...state,
    authState: false,
    error: undefined,
  };
};

const handleAuthFinishRequestWithErrors = (
  state: State,
  action: AuthFinishRequestWithErrorsAction
): State => {
  return {
    ...state,
    authState: false,
    error: action.error,
  };
};
