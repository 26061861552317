import { MoreHorizOutlined } from "@material-ui/icons";
import { Formik, Form } from "formik";
import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import { LinkCollectionProperty, LinkProperty } from "editor/properties";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { NavProperties } from "common/elements/components/Nav";
import { NavStyleProperty } from "editor/properties/NavStyleProperty/NavStyleProperty";
import styles from "./Nav.module.scss";
import { LinkColorProperty } from "editor/properties/LinkColorProperty/LinkColorProperty";
import { useTranslation } from "react-i18next";
export class NavElement {
  static readonly elementKey = "nav";
  static readonly label = "element_nav_label";
  static readonly icon = MoreHorizOutlined;
  static readonly allowedToolbarPositions = [];
  static readonly elementDescription = "element_nav_description";
  static readonly dialogPositionStatic = false;
  static readonly isDeletable = true;

  static readonly properties: PropertiesOf<NavProperties> = {
    links: LinkCollectionProperty,
    style: NavStyleProperty,
    isProduction: Boolean,
    linkColor: LinkColorProperty,
  };

  static renderProperties(element: ElementType) {
    return <NavPropertiesComponent element={element} />;
  }

  static create(properties: {
    links: LinkProperty[];
    style: string;
    linkColor?: string;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const NavPropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof NavElement.properties>(
    NavElement.properties,
    element
  );
  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="links">
        <Tab eventKey="links" title={t("dialog_21")}>
          {renderer.render("links")}
        </Tab>
        <Tab eventKey="appearance" title={t("dialog_1")}>
          <Formik initialValues={{}} onSubmit={async (values) => {}}>
            <Form className="form">{renderer.render("style")}</Form>
          </Formik>
        </Tab>
        <Tab eventKey="linkColor" title={t("dialog_22")}>
          <Formik initialValues={{}} onSubmit={async (values) => {}}>
            <Form className="form">{renderer.render("linkColor")}</Form>
          </Formik>
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(NavElement);
