import React from "react";

var tinycolor = require("tinycolor2");

export type StylesProperties = {
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
};

export function Styles({
  primaryFont,
  secondaryFont,
  primaryColor,
  secondaryColor,
  ternaryColor,
  primaryRadius,
  secondaryRadius,
  primaryShadow,
}: StylesProperties): JSX.Element {
  var readableFontForPrimaryColorBG = tinycolor
    .mostReadable(primaryColor, [secondaryColor], {
      includeFallbackColors: true,
      level: "AAA",
      size: "small",
    })
    .toHexString();

  let readablePrimaryColorOnWhite = "#000";
  for (let i = 1; i <= 10; i++) {
    readablePrimaryColorOnWhite = tinycolor(primaryColor)
      .darken(i * 10)
      .toString();
    if (tinycolor.readability(readablePrimaryColorOnWhite, "#fff") > 4.5) {
      break;
    }
  }

  let readablePrimaryColorOnBlack = "#fff";
  for (let i = 1; i <= 10; i++) {
    readablePrimaryColorOnBlack = tinycolor(primaryColor)
      .lighten(i * 10)
      .toString();
    if (tinycolor.readability(readablePrimaryColorOnBlack, "#000") > 4.5) {
      break;
    }
  }

  let readableSecondaryColorOnWhite = "#000";
  for (let i = 1; i <= 10; i++) {
    readableSecondaryColorOnWhite = tinycolor(secondaryColor)
      .darken(i * 10)
      .toString();
    if (tinycolor.readability(readableSecondaryColorOnWhite, "#fff") > 4.5) {
      break;
    }
  }

  let readableSecondaryColorOnBlack = "#fff";
  for (let i = 1; i <= 10; i++) {
    readableSecondaryColorOnBlack = tinycolor(secondaryColor)
      .lighten(i * 10)
      .toString();
    if (tinycolor.readability(readableSecondaryColorOnBlack, "#000") > 4.5) {
      break;
    }
  }

  let readableTernaryColorOnWhite = "#000";
  for (let i = 1; i <= 10; i++) {
    readableTernaryColorOnWhite = tinycolor(ternaryColor)
      .darken(i * 10)
      .toString();
    if (tinycolor.readability(readableTernaryColorOnWhite, "#fff") > 4.5) {
      break;
    }
  }

  let readableTernaryColorOnBlack = "#fff";
  for (let i = 1; i <= 10; i++) {
    readableTernaryColorOnBlack = tinycolor(ternaryColor)
      .lighten(i * 10)
      .toString();
    if (tinycolor.readability(readableTernaryColorOnBlack, "#000") > 4.5) {
      break;
    }
  }

  const darkGray = tinycolor(primaryColor).desaturate(50).toString();

  let readableDarkGrayColorOnWhite = "#000";
  for (let i = 1; i <= 10; i++) {
    readableDarkGrayColorOnWhite = tinycolor(darkGray)
      .darken(i * 10)
      .toString();
    if (tinycolor.readability(readableDarkGrayColorOnWhite, "#fff") > 4.5) {
      break;
    }
  }

  const lightGray = tinycolor(primaryColor)
    .desaturate(50)
    .lighten(40)
    .toString();

  const defaultDarkGray = "#333333";

  const defaultLightGray = "#f2f2f2";

  // Class        Description                               Variable
  // fp           Primary font                              $primaryFont
  // fs           Secondary font                            $secondaryFont
  // rc           Custom radius
  // rp           Primary radius                            $primaryRadius
  // rs           Secondary radius                          $secondaryRadius
  // sp           Primary shadow                            $primaryShadow
  // cp           Primary color                             $primaryColor
  // c-rcp        Color readable on primary color           $readableFontForPrimaryColorBG
  // cp-rw        Primary color readable on white           $readablePrimaryColorOnWhite
  // cp-rb        Primary color readable on black           $readablePrimaryColorOnBlack
  // cs           Secondary color                           $secondaryColor
  // cs-rw        Secondary color readable on white         $readableSecondaryColorOnWhite
  // cs-rb        Secondary color readable on black         $readableSecondaryColorOnBlack
  // ct           Ternary color                             $ternaryColor
  // ct-rw        Ternary color readable on white           $readableTernaryColorOnWhite
  // ct-rb        Ternary color readable on black           $readableTernaryColorOnBlack
  // cp-lg        Light gray                                $lightGray
  // cp-dg        Dark gray                                 $darkGray
  // cg-df-dg     Default dark gray                         $defaultDarkGray
  // cg-df-lg     Default light gray                        $defaultLightGray
  // bgc-rt       Readable text on background color
  // pos-fx       Position fixed
  // pos-fx-br    Position fixed bottom right
  // pos-fx-bl    Position fixed bottom left

  // d<i>   Darken with i e.g. 10

  // hov    Hover
  // bgc    Background
  // brc    Border
  // brc<i> Border i e.g. 1px

  return (
    <style jsx={true}>{`
      .jaquen {
        color: ${tinycolor(primaryColor).darken(90).toString()};
      }
      h1,
      h2,
      h3 {
        font-family: ${primaryFont};
      }

      .fp {
        font-family: ${primaryFont};
      }

      .fs {
        font-family: ${secondaryFont};
      }

      .rp {
        border-radius: ${primaryRadius};
        overflow: hidden;
      }

      .rs {
        border-radius: ${secondaryRadius};
        overflow: hidden;
      }

      .rs-7 {
        border-radius: 12px;
        overflow: hidden;
      }

      .rs-0 {
        border-radius: 0px;
        overflow: hidden;
      }

      .rs-100 {
        border-radius: 25px;
        overflow: hidden;
      }

      .sp {
        box-shadow: rgba(0, 0, 0, ${primaryShadow}) 0px 0px 10px -5px;
      }

      .sp-100 {
        box-shadow: rgba(0, 0, 0, 1) 0px 0px 10px -5px;
      }

      .sp-0 {
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 10px -5px;
      }

      .brc1,
      .brc1--hov {
        border-width: 1px;
        border-style: solid;
      }

      .brc2,
      .brc2--hov {
        border-width: 2px;
        border-style: solid;
      }

      .brc3,
      .brc3--hov {
        border-width: 3px;
        border-style: solid;
      }

      .brc4,
      .brc4--hov {
        border-width: 4px;
        border-style: solid;
      }

      .brc5,
      .brc5--hov {
        border-width: 5px;
        border-style: solid;
      }

      .cg-df-dg,
      .cg-df-dg--hov:hover {
        color: ${defaultDarkGray};
      }

      .cg-df-dg-fi,
      .cg-df-dg-fi--hover {
        fill: ${defaultDarkGray};
      }

      .cg-df-lg,
      .cg-df-lg--hov:hover {
        color: ${defaultLightGray};
      }

      .cg-df-lg-fi,
      .cg-df-lg-fi--hover {
        fill: ${defaultLightGray};
      }

      .cg-df-lg-brc,
      .cg-df-lg-brc--hov:hover {
        border: 1px solid ${defaultLightGray};
      }

      .cp,
      .cp--hov:hover,
      a.cp,
      a.cp--hov:hover {
        color: ${primaryColor};
      }

      .cp--bgc,
      .cp--bgc--hov:hover {
        background-color: ${primaryColor};
      }

      .cp--brc,
      .cp--brc--hov:hover {
        border-color: ${primaryColor};
      }

      .cp-d10--brc,
      .cp-d10--brc--hov:hover {
        border-color: ${tinycolor(primaryColor).darken(10).toString()};
      }

      .cp-d10--bgc,
      .cp-d10--bgc--hov:hover {
        background-color: ${tinycolor(primaryColor).darken(10).toString()};
      }

      .cp-d10--bgc-rt-bw,
      .cp-d10--bgc-rt-bw--hov:hover,
      a.cp-d10--bgc-rt-bw,
      a.cp-d10--bgc-rt-bw--hov:hover {
        color: ${tinycolor(primaryColor).darken(10).isDark()
          ? "#fff"
          : darkGray};
      }

      .cp-fi,
      .cp-fi--hov:hover {
        fill: ${primaryColor};
      }

      .cp-d10-fi,
      .cp-d10-fi--hov:hover {
        fill: ${tinycolor(primaryColor).darken(10).toString()};
      }

      .cp-rw {
        color: ${readablePrimaryColorOnWhite};
      }

      .cp-rb {
        color: ${readablePrimaryColorOnBlack};
      }

      .cp-lg {
        color: ${lightGray};
      }

      .cp-dg {
        color: ${darkGray};
      }

      .cp-dg-d50 {
        color: ${tinycolor(darkGray).darken(50).toString()};
      }

      .cp-dg-d50-fi {
        fill: ${tinycolor(darkGray).darken(50).toString()};
      }

      .c-rcp,
      .c-rcp--hov:hover {
        color: ${readableFontForPrimaryColorBG};
      }

      .cs,
      .cs--hov:hover,
      a.cs,
      a.cs--hov:hover {
        color: ${secondaryColor};
      }

      .cs--bgc,
      .cs--bgc--hov:hover {
        background-color: ${secondaryColor};
      }

      .cs--brc,
      .cs--brc--hov:hover {
        border-color: ${secondaryColor};
      }

      .cs-d10--brc,
      .cs-d10--brc--hov:hover {
        border-color: ${tinycolor(secondaryColor).darken(10).toString()};
      }

      .cs-d10--bgc,
      .cs-d10--bgc--hov:hover {
        background-color: ${tinycolor(secondaryColor).darken(10).toString()};
      }

      .cs-d10--bgc-rt-bw,
      .cs-d10--bgc-rt-bw--hov:hover,
      a.cs-d10--bgc-rt-bw,
      a.cs-d10--bgc-rt-bw--hov:hover {
        color: ${tinycolor(secondaryColor).darken(10).isDark()
          ? "#fff"
          : darkGray};
      }

      .cs-fi,
      .cs-fi--hov:hover {
        fill: ${secondaryColor};
      }

      .cs-d10-fi,
      .cs-d10-fi--hov:hover {
        fill: ${tinycolor(secondaryColor).darken(10).toString()};
      }

      .cs-rw {
        color: ${readableSecondaryColorOnWhite};
      }

      .cs-rb {
        color: ${readablePrimaryColorOnBlack};
      }

      .ts {
        color: ${ternaryColor};
      }

      .ts-rw {
        color: ${readableTernaryColorOnWhite};
      }

      .ts-rb {
        color: ${readableSecondaryColorOnBlack};
      }

      .p-f {
        font-family: ${primaryFont};
      }
      .p-c-g {
        color: ${tinycolor(primaryColor).greyscale().toString()};
      }
      .p-c-g-d {
        color: ${tinycolor(primaryColor).greyscale().darken().toString()};
      }
      .p-c-g-l {
        color: ${tinycolor(primaryColor).greyscale().lighten().toString()};
      }
      .p-c-d80 {
        color: ${tinycolor(primaryColor).darken(80).toString()};
      }
      .s-c-d80 {
        color: ${tinycolor(secondaryColor).darken(80).toString()};
      }
      .p-c-d90 {
        color: ${tinycolor(primaryColor).darken(90).toString()};
      }
      .p-c-d100 {
        color: ${tinycolor(primaryColor).darken(100).toString()};
      }
      .p-c-hov:hover {
        color: ${primaryColor};
      }
      .s-f {
        font-family: ${secondaryFont};
      }
      .p-c {
        color: ${primaryColor} !important;
      }
      .p-c-f {
        fill: ${primaryColor};
      }
      .s-c-f {
        fill: ${secondaryColor};
      }
      .p-c-f-hov:hover {
        fill: ${primaryColor};
      }
      .s-c-f-hov:hover {
        fill: ${secondaryColor};
      }
      .s-c-hov:hover {
        color: ${secondaryColor};
      }
      .sfill-c-d80-hov:hover {
        color: ${tinycolor(secondaryColor).darken(80).toString()};
      }
      .p-c-f-g-d {
        fill: ${tinycolor(primaryColor).greyscale().darken().toString()};
      }
      .p-c-d90-f {
        fill: ${tinycolor(primaryColor).darken(90).toString()};
      }
      .p-c-d90-f-hov-brghtn20 {
      }
      .p-c-d90-f-hov-brghtn20:hover {
        fill: ${tinycolor(primaryColor).brighten(20).toString()};
      }
      .s-c {
        color: ${secondaryColor};
      }
      .p-c-b {
        background-color: ${primaryColor};
      }
      .p-c-b-hov-brghtn20 {
      }
      .p-c-b-hov-brghtn20:hover {
        background-color: ${tinycolor(primaryColor).brighten(20).toString()};
      }
      .p-c-b-des30-a5 {
        background-color: ${tinycolor(primaryColor)
          .desaturate(50)
          .setAlpha(0.5)
          .toString()};
      }
      .p-c-bo {
        border: 2px solid ${primaryColor};
      }
      .p-c-b-rev-hov-bo-brghtn20 {
      }
      .p-c-b-rev-hov-bo-brghtn20:hover {
        border: 2px solid ${tinycolor(primaryColor).brighten(20).toString()};
        background-color: ${tinycolor(primaryColor).brighten(20).toString()};
        color: ${readableFontForPrimaryColorBG} !important;
      }
      .s-c-bo {
        border: 2px solid ${secondaryColor};
      }
      .p-c-bo-d90-a5 {
        border: 1px solid
          ${tinycolor(primaryColor).darken(90).setAlpha(0.5).toString()};
      }
      .s-c-b {
        background-color: ${secondaryColor};
      }
      .s-c-f {
        fill: ${secondaryColor};
      }
      .p-r {
        border-radius: ${primaryRadius};
        overflow: hidden;
      }
      .p-s {
        border-radius: ${secondaryRadius};
        overflow: hidden;
      }
      .p-sh {
        box-shadow: rgba(0, 0, 0, ${primaryShadow}) 5px 5px 5px -5px;
      }
      .v-c-r:hover {
        color: ${readableFontForPrimaryColorBG};
      }
      .hobaw-rte a {
        color: ${primaryColor} !important;
      }
      .hobaw-rte a:hover {
        color: ${tinycolor(primaryColor).brighten(20).toString()} !important;
      }
      .bgc-unset {
        background-color: unset;
      }
      .pos-fx {
        position: fixed;
      }
      .pos-fx-br {
        bottom: 60px;
        right: 24px;
      }
      .pos-fx-bl {
        bottom: 60px;
        left: 24px;
      }
      .rc {
        border-radius: 50%;
      }
      .whatsapp-df {
        background-color: #25d366;
      }
      .whatsapp-btn i,
      .messenger-btn i {
        color: #fff;
      }
      .whatsapp-btn {
        width: 60px;
        height: 60px;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .messenger-df {
        background-color: #0080fb;
      }
      .messenger-btn {
        width: 60px;
        height: 60px;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
      }
      .messenger-btn:hover,
      .whatsapp-btn:hover {
        color: #fff;
      }
    `}</style>
  );
}
