import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type NavbarProperties = {
  navbarStyle: string;
  width: number;
  backgroundColorProperty: string;
  backgroundImageProperty: {
    size: string;
    repeat: string;
    backgroundImageUrl: string;
    positionX: string;
    positionY: string;
  };
  dimension: { width?: number; height?: number };
  borderProperty: {
    hasBorder?: boolean;
    color?: string;
    thickness?: number;
    shadow?: number;
  };
};

export function Navbar({
  properties: {
    navbarStyle,
    width,
    backgroundColorProperty,
    backgroundImageProperty,
    dimension,
    borderProperty,
  },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<NavbarProperties>): JSX.Element {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundSize:
          backgroundImageProperty.size === "100%"
            ? "cover"
            : backgroundImageProperty.size,
        backgroundRepeat: backgroundImageProperty.repeat,
        backgroundImage: backgroundImageProperty.backgroundImageUrl
          ? `url(${backgroundImageProperty.backgroundImageUrl})`
          : `none`,
        backgroundPositionX: backgroundImageProperty.positionX,
        backgroundPositionY: backgroundImageProperty.positionY,
        backgroundColor: backgroundColorProperty,
        borderBottom:
          borderProperty && borderProperty.hasBorder
            ? `solid ${borderProperty.thickness}px ${borderProperty.color}`
            : "none",
        boxShadow:
          borderProperty && borderProperty.hasBorder
            ? `0px 0px 10px 0px rgba(0,0,0, ${borderProperty.shadow})`
            : "none",
        position:
          borderProperty && borderProperty.hasBorder ? "relative" : "unset",
      }}
    >
      <div id="ad1" className="ad-container">
        <a
          rel="noopener noreferrer"
          href="https://www.trygoodweb.com?utm_source=freemium"
          target="_blank"
          id="ad1-link"
        >
          <div></div>
        </a>
      </div>
      <header
        guide={"width-guide"}
        {...props}
        ref={innerRef}
        className={`${className} container jq-navbar navbarstyle-${navbarStyle} section-size-${width}`}
        style={{
          width: dimension && dimension.width ? dimension.width : "",
          height: dimension && dimension.height ? dimension.height : "",
        }}
      >
        {children}
      </header>
    </div>
  );
}

export const navbarElementKey = "navbar";

elementComponentRegistry.register(navbarElementKey, Navbar);
