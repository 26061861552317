import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  ButtonToolbar,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import * as yup from "yup";
import { IStepProps, PurposeReview, ToUpload } from "./Setup";

import { useTranslation } from "react-i18next";

import styles from "./Admin.module.scss";
import { EditRounded, PhotoAlbumRounded } from "@material-ui/icons";

import { useDropzone } from "react-dropzone";
import { getS3config } from "editor/states/selectors";
import { useSelector } from "react-redux";
import { uploadFile } from "./S3Client";

var mime = require("mime-types");

const schema = yup.object({
  purposeName: yup.string(),
  purposeDescription: yup.string(),
});

type Props = {
  i: number;
  index: number;
  comment?: string;
  title?: string;
  reviewer?: string;
  reviewerPhoto?: ToUpload;
  rating?: number;
  tempFilename?: string;
};

export type SetupStep3gFormValues = yup.InferType<typeof schema>;

export const SetupStep3gForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleSkip,
}) => {
  const { t } = useTranslation();
  const s3ConfigState = useSelector(getS3config);
  const [stateReviews, setStateReviews] = useState<PurposeReview[]>(
    values.reviews
  );
  const [stateIndex, setStateIndex] = useState<number | undefined>();
  const [stateComment, setStateComment] = useState<string | undefined>();
  const [stateCommentError, setStateCommentError] = useState<
    string | undefined
  >();
  const [stateTitle, setStateTitle] = useState<string | undefined>();
  const [stateReviewer, setStateReviewer] = useState<string | undefined>();
  const [stateReviewerPhoto, setStateReviewerPhoto] = useState<
    ToUpload | undefined
  >();
  const [stateRating, setStateRating] = useState<number | undefined>();
  const [formDOM, setFormDOM] = useState<HTMLFormElement | null>(null);
  // const [fileType, setFileType] = useState("");
  const [tempFilename, setTempFilename] = useState<string | undefined>();
  const [isAdding, setIsAdding] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    if (
      values &&
      (values.isPurposeReview === undefined || values.isPurposeReview === false)
    ) {
      handleSaveAndContinue(values);
    }
  }, [values, handleSaveAndContinue]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imgStr = reader.result as string;

        let fileType = mime.extension(file.type);
        if (!fileType) {
          fileType = "png";
        }
        setTempFilename("reviewer-" + stateReviews?.length);
        // setFileType(fileType);
        setStateReviewerPhoto({
          filename: "reviewer-" + stateReviews?.length,
          file: file,
          dataUrl: imgStr,
        });
      };
      reader.readAsDataURL(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    minSize: 0,
    maxSize: 2097152, // 2MB max
    onDrop,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div className={styles["button-drop-zone-label-errors"]}>
        {errors.map((e) => (
          <span key={e.code}>{e.message}</span>
        ))}
      </div>
    );
  });

  const onSubmit = () => {
    setStateIndex(undefined);
    setStateComment("");
    setStateTitle(undefined);
    setStateReviewer(undefined);
    setStateReviewerPhoto(undefined);
    setStateRating(undefined);
    setTempFilename(undefined);
    handleSaveAndContinue(values);
  };

  const pushReviewsPurposes = (purposes: PurposeReview[], loc: any) => {
    if (stateIndex !== undefined) {
      purposes[stateIndex] = {
        id: stateIndex,
        comment: stateComment,
        title: stateTitle,
        reviewer: stateReviewer,
        reviewerPhoto: loc
          ? {
              file: stateReviewerPhoto?.file!,
              filename: loc,
              dataUrl: stateReviewerPhoto?.dataUrl!,
            }
          : stateReviewerPhoto,
        rating: stateRating,
        oldFilename: tempFilename,
      };
      setStateReviews(purposes);

      setStateIndex(undefined);
      setStateComment("");
      setStateTitle(undefined);
      setStateReviewer(undefined);
      setStateReviewerPhoto(undefined);
      setStateRating(undefined);
      setTempFilename(undefined);

      formDOM?.reset();
    } else {
      purposes.push({
        id: stateReviews.length,
        comment: stateComment,
        title: stateTitle,
        reviewer: stateReviewer,
        reviewerPhoto: loc
          ? {
              file: stateReviewerPhoto?.file!,
              filename: loc,
              dataUrl: stateReviewerPhoto?.dataUrl!,
            }
          : stateReviewerPhoto,
        rating: stateRating,
        oldFilename: tempFilename,
      });
      setStateReviews(purposes);

      setStateIndex(undefined);
      setStateComment("");
      setStateTitle(undefined);
      setStateReviewer(undefined);
      setStateReviewerPhoto(undefined);
      setStateRating(undefined);
      setTempFilename(undefined);

      formDOM?.reset();
    }
    setIsAdding(false);
    setShowAdd(false);
  };

  const handleAdd = () => {
    setIsAdding(true);
    if (!setStateComment) {
      setIsAdding(false);
      setShowAdd(false);
      return;
    }

    let reviews: PurposeReview[] = stateReviews;

    if (
      stateReviewerPhoto !== undefined &&
      stateReviewerPhoto.dataUrl &&
      stateReviewerPhoto.file &&
      stateReviewerPhoto.filename
    ) {
      uploadFile(
        stateReviewerPhoto.file,
        values.url,
        stateReviewerPhoto.filename,
        s3ConfigState
      ).then(function (loc) {
        pushReviewsPurposes(reviews, loc);
      });
    } else {
      pushReviewsPurposes(reviews, undefined);
    }
  };

  const handleCommentChange = (comment: string) => {
    if (!comment || comment === "") {
      setStateCommentError(t("setup_wizard_20"));
    } else {
      setStateCommentError(undefined);
      setStateComment(comment);
      values.purposeDescription = comment;
    }
  };

  const handleDelete = (index: number) => {
    let reviews: PurposeReview[] = stateReviews;
    var filteredPurposes = reviews.filter((e) => e.id !== index);

    setStateReviews(filteredPurposes);

    setStateIndex(undefined);
    setStateComment("");
    setStateTitle(undefined);
    setStateReviewer(undefined);
    setStateReviewerPhoto(undefined);
    setStateRating(undefined);
    setTempFilename(undefined);

    values.reviews = filteredPurposes;
    formDOM?.reset();
    return;
  };

  function EditButton({
    i,
    index,
    comment,
    title,
    reviewer,
    reviewerPhoto,
    rating,
    tempFilename,
  }: Props): JSX.Element {
    let shortTitle = comment;

    if (comment)
      shortTitle =
        comment?.length > 20 ? comment?.substring(0, 20) + "..." : comment;

    return (
      <Button
        type="button"
        key={i}
        style={{
          width: "100%",
          textAlign: "left",
          borderRadius: "32px",
          color: "#007bff",
          backgroundColor: "#fff",
          border: "1px solid #007bff",
          marginBottom: "12px",
        }}
        onClick={() => {
          setStateIndex(index);
          setStateReviewerPhoto(reviewerPhoto);
          setTempFilename(tempFilename);
          setStateReviewer(reviewer);
          setStateTitle(title);
          setStateRating(rating);
          setStateComment(comment);
          setShowAdd(true);
          formDOM?.reset();
        }}
      >
        <EditRounded /> {shortTitle}
      </Button>
    );
  }

  return (
    <Form noValidate ref={(form: any) => setFormDOM(form)}>
      <Modal.Header>
        <Modal.Title style={{ width: "100%" }}>
          <h6>{t("setup_wizard_81")}</h6>
          <div className={styles["setup-subheader"]}>
            <div className={styles["setup-subheader-main"]}>
              {t("setup_wizard_92")}
            </div>
            <div
              className={styles["setup-subheader-skip"]}
              onClick={() => {
                handleSkip(values);
              }}
            >
              {t("setup_wizard_4")}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row}>
          <Col>
            {values.reviews &&
            values.reviews.length > 0 &&
            stateIndex === undefined &&
            !showAdd ? (
              <>
                <div className={styles["setup-list-label"]}>
                  {t("setup_wizard_105")}
                </div>
                {values.reviews.map((review, i) => (
                  <EditButton
                    i={i}
                    index={review.id}
                    title={review.title}
                    comment={review.comment}
                    reviewer={review.reviewer}
                    reviewerPhoto={review.reviewerPhoto}
                    rating={review.rating}
                    tempFilename={review.oldFilename}
                  />
                ))}
                <div
                  style={{
                    width: "100%",
                    borderTop: "1px solid rgba(0, 0, 0, 0.125)",
                    marginTop: "24px",
                    marginBottom: "24px",
                  }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="button"
                    onClick={() => {
                      setShowAdd(true);
                    }}
                    style={{
                      borderRadius: 32,
                      color: "#007bff",
                      backgroundColor: "white",
                      borderColor: "#007bff",
                    }}
                  >
                    {t("setup_wizard_154")}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div
                  className={styles["setup-label"]}
                  style={{ marginTop: "12px" }}
                >
                  {t("setup_wizard_93")}
                  <span>*</span>
                </div>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="comment"
                  value={stateComment}
                  onChange={(e: React.ChangeEvent<any>) => {
                    handleCommentChange(e.target.value);
                  }}
                  placeholder={t("setup_wizard_83")}
                  size="lg"
                  style={{ minHeight: "160px" }}
                />
                <span className={styles["invalid"]}>{stateCommentError}</span>
                <div
                  className={styles["setup-label"]}
                  style={{ marginTop: "24px" }}
                >
                  {t("setup_wizard_94")}
                </div>
                <Form.Control
                  type="text"
                  name="name"
                  value={stateTitle}
                  onChange={(e: React.ChangeEvent<any>) => {
                    setStateTitle(e.target.value);
                  }}
                  placeholder={t("setup_wizard_82")}
                  size="lg"
                />
                <span className={styles["invalid"]}></span>
                <div
                  className={styles["setup-label"]}
                  style={{ marginTop: "24px" }}
                >
                  {t("setup_wizard_95")}
                </div>
                <Form.Control
                  type="text"
                  name="name"
                  value={stateReviewer}
                  onChange={(e: React.ChangeEvent<any>) => {
                    setStateReviewer(e.target.value);
                  }}
                  placeholder={t("setup_wizard_82")}
                  size="lg"
                />
                <span className={styles["invalid"]}></span>
                <div
                  className={`${styles["setup-label"]} ${styles["setup-label-rating"]}`}
                  style={{}}
                >
                  <div>{t("setup_wizard_104")}</div>
                  <div>
                    <ButtonToolbar aria-label="Toolbar with button groups">
                      <ButtonGroup className="mr-2" aria-label="Rating group">
                        <Button
                          className={
                            stateRating === 1
                              ? styles["setup-rating-box-selected"]
                              : styles["setup-rating-box"]
                          }
                          onClick={(e: React.ChangeEvent<any>) => {
                            if (stateRating === 1) {
                              setStateRating(undefined);
                            } else {
                              setStateRating(1);
                            }
                          }}
                        >
                          1
                        </Button>
                        <Button
                          className={
                            stateRating === 2
                              ? styles["setup-rating-box-selected"]
                              : styles["setup-rating-box"]
                          }
                          onClick={(e: React.ChangeEvent<any>) => {
                            if (stateRating === 2) {
                              setStateRating(undefined);
                            } else {
                              setStateRating(2);
                            }
                          }}
                        >
                          2
                        </Button>
                        <Button
                          className={
                            stateRating === 3
                              ? styles["setup-rating-box-selected"]
                              : styles["setup-rating-box"]
                          }
                          onClick={(e: React.ChangeEvent<any>) => {
                            if (stateRating === 3) {
                              setStateRating(undefined);
                            } else {
                              setStateRating(3);
                            }
                          }}
                        >
                          3
                        </Button>
                        <Button
                          className={
                            stateRating === 4
                              ? styles["setup-rating-box-selected"]
                              : styles["setup-rating-box"]
                          }
                          onClick={(e: React.ChangeEvent<any>) => {
                            if (stateRating === 4) {
                              setStateRating(undefined);
                            } else {
                              setStateRating(4);
                            }
                          }}
                        >
                          4
                        </Button>
                        <Button
                          className={
                            stateRating === 5
                              ? styles["setup-rating-box-selected"]
                              : styles["setup-rating-box"]
                          }
                          onClick={(e: React.ChangeEvent<any>) => {
                            if (stateRating === 5) {
                              setStateRating(undefined);
                            } else {
                              setStateRating(5);
                            }
                          }}
                        >
                          5
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>
                </div>
                <div
                  {...getRootProps()}
                  className={`${styles["button-dropzone"]}  ${styles["create-option"]}`}
                >
                  <input {...getInputProps()} />
                  <div className={styles["button-drop-zone-label-container"]}>
                    <PhotoAlbumRounded />{" "}
                    {tempFilename === undefined
                      ? t("setup_wizard_102")
                      : t("setup_wizard_103")}
                    {fileRejectionItems.length > 0 ? fileRejectionItems : <></>}
                  </div>
                  <div style={{ fontSize: "14px", fontWeight: "lighter" }}>
                    {tempFilename ? tempFilename : ""}
                  </div>
                </div>
                <Button
                  type="button"
                  disabled={!stateComment}
                  style={{ borderRadius: 32, width: "100%", marginTop: "12px" }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleAdd();
                  }}
                >
                  {isAdding ? (
                    <Spinner
                      size="sm"
                      animation="border"
                      variant="light"
                      className={styles["saving-icon"]}
                      style={{ marginRight: "4px", marginBottom: "2px" }}
                    />
                  ) : (
                    <></>
                  )}
                  {stateIndex === undefined
                    ? t("setup_wizard_99")
                    : t("setup_wizard_100")}
                </Button>
                {stateIndex !== undefined ? (
                  <Button
                    type="button"
                    disabled={stateIndex === undefined}
                    style={{
                      borderRadius: 32,
                      width: "100%",
                      marginTop: "12px",
                      borderColor: "",
                      border: "2px solid #df2935",
                      backgroundColor: "white",
                      color: "#df2935",
                    }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleDelete(stateIndex);
                    }}
                  >
                    {t("setup_wizard_101")}
                  </Button>
                ) : (
                  <></>
                )}
              </>
            )}
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button
          type="button"
          onClick={
            showAdd
              ? () => {
                  setStateIndex(undefined);
                  setStateComment("");
                  setStateTitle(undefined);
                  setStateReviewer(undefined);
                  setStateReviewerPhoto(undefined);
                  setStateRating(undefined);
                  setShowAdd(false);
                  formDOM?.reset();
                }
              : handleStepBack
          }
          style={{
            borderRadius: 32,
            color: "#007bff",
            backgroundColor: "white",
            borderColor: "#007bff",
          }}
        >
          {showAdd ? t("setup_wizard_70") : t("login_page_back")}
        </Button>
        {showAdd ? (
          <Button
            type="button"
            disabled={values.reviews.length === 0}
            style={{
              borderRadius: 32,
              opacity: 0,
            }}
            onClick={onSubmit}
          >
            {/* {isSaving ? "Loading..." : "Create"} */}
            {t("setup_page_15")}
          </Button>
        ) : (
          <Button
            type="button"
            disabled={values.reviews.length === 0}
            style={{
              borderRadius: 32,
            }}
            onClick={onSubmit}
          >
            {/* {isSaving ? "Loading..." : "Create"} */}
            {t("setup_page_15")}
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
};
