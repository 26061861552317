import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet, updateChallengeVariants } from "editor/states/layout";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import {
  getPageSlug,
  getPrimaryColor,
  getSecondaryColor,
} from "editor/selectors";
import { State } from "store";
import { getChallenge } from "editor/states/selectors";
import { Formik, Form } from "formik";

import styles from "./MessengerButtonStyleProperty.module.scss";
import { useTranslation } from "react-i18next";

export class MessengerButtonStyleProperty implements IProperty {
  constructor(readonly style: string, readonly isTreatment?: boolean) {}

  render(elementId: string, propertyKey: string) {
    return (
      <MessengerButtonStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    return new MessengerButtonStyleProperty(value.style, value.isTreatment);
  }
}

const MessengerButtonStylePropertyComponent: PropertyComponent<MessengerButtonStyleProperty> = ({
  elementId,
  propertyKey,
  style,
  isTreatment,
}) => {
  const dispatch = useDispatch();
  const pageSlug = useSelector(getPageSlug);
  const challenge = useSelector((state: State) =>
    getChallenge(state, pageSlug!)
  );
  const primaryColor = useSelector(getPrimaryColor);
  const secondaryColor = useSelector(getSecondaryColor);
  const { t } = useTranslation();
  const radios = [
    {
      name: t("dialog_8"),
      value: "hobaw-messenger-btn-default",
      sample: (
        <a
          className={`jq-messenger pos-fx pos-fx-br`}
          href="#test"
          style={{
            position: "relative",
            bottom: "unset",
            top: "unset",
            left: "unset",
            right: "unset",
            color: "#fff",
          }}
        >
          <i className="fab fa-facebook-messenger"></i>
        </a>
      ),
    },
    {
      name: t("dialog_73"),
      value: "hobaw-messenger-btn-primary",
      sample: (
        <a
          className={`jq-messenger pos-fx pos-fx-br`}
          href="#test"
          style={{
            position: "relative",
            bottom: "unset",
            top: "unset",
            left: "unset",
            right: "unset",
            color: "#fff",
            backgroundColor: primaryColor ? primaryColor : "#fff",
          }}
        >
          <i className="fab fa-facebook-messenger"></i>
        </a>
      ),
    },
    {
      name: t("dialog_74"),
      value: "hobaw-messenger-btn-secondary",
      sample: (
        <a
          className={`jq-messenger pos-fx pos-fx-br`}
          href="#test"
          style={{
            position: "relative",
            bottom: "unset",
            top: "unset",
            left: "unset",
            right: "unset",
            color: "#fff",
            backgroundColor: secondaryColor ? secondaryColor : "#fff",
          }}
        >
          <i className="fab fa-facebook-messenger"></i>
        </a>
      ),
    },
  ];

  const handleStyleChange = (value: string) => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new MessengerButtonStyleProperty(value)
      )
    );
    if (challenge) {
      if (challenge.elementId === elementId) {
        dispatch(updateChallengeVariants(pageSlug!));
      } else if (!challenge.elementId && isTreatment) {
        dispatch(updateChallengeVariants(pageSlug!));
      }
    }
  };

  return (
    <div>
      <div className={styles["button-prop-container"]}>
        <Formik initialValues={{}} onSubmit={async (values) => {}}>
          <Form className="form">
            <div className="form__group">
              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
              ></link>
              <ButtonGroup className="mb-2 navbar-style-group">
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="primary"
                    name="style"
                    value={radio.value}
                    checked={style === radio.value}
                    className={`${styles[`button-toggle`]} button-radio-hidden`}
                    style={{ height: "120px" }}
                    onClick={(e: any) => handleStyleChange(radio.value)}
                  >
                    {radio.sample}
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
