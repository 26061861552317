import {
  AuthControllerApi,
  AbMockerControllerApi,
  Configuration,
  PageControllerApi,
  WebsiteControllerApi,
  WebsiteCreateRequestCategoryEnum,
  WebsiteResourceCategoryEnum,
  AuthorControllerApi,
  PageVersionControllerApi,
  PageVersionListControllerApi,
  WebsitePublishControllerApi,
  WebsiteVersionControllerApi,
  GaPropertyControllerApi,
  ReportsControllerApi,
  S3ConfigControllerApi,
  TrackerControllerApi,
  ChallengeControllerApi,
  PaymentGatewayConfigControllerApi,
  DLocalControllerApi,
  PaypalControllerApi,
  ElementStylesControllerApi,
  SubscriptionEmailControllerApi,
} from "@urux/arm-of-dorne-api";

let configuration;
if (typeof window === "undefined") {
  require("portable-fetch");
  configuration = new Configuration({ fetchApi: fetch });
} else {
  configuration = new Configuration({ credentials: "include" });
}

export const authApi = new AuthControllerApi(configuration);
export const authorApi = new AuthorControllerApi(configuration);
export const pageApi = new PageControllerApi(configuration);
export const pageVersionApi = new PageVersionControllerApi(configuration);
export const pageVersionListApi = new PageVersionListControllerApi(
  configuration
);
export const websiteApi = new WebsiteControllerApi(configuration);
export const websitePublishApi = new WebsitePublishControllerApi(configuration);
export const websiteVersionApi = new WebsiteVersionControllerApi(configuration);
export const gaPropertyApi = new GaPropertyControllerApi(configuration);
export const reportsApi = new ReportsControllerApi(configuration);
export const s3ConfigApi = new S3ConfigControllerApi(configuration);
export const trackerApi = new TrackerControllerApi(configuration);
export const challengeApi = new ChallengeControllerApi(configuration);
export const paymentGatewayApi = new PaymentGatewayConfigControllerApi(
  configuration
);
export const dlocalSettingsApi = new DLocalControllerApi(configuration);
export const paypalSettingsApi = new PaypalControllerApi(configuration);
export const elementStylesApi = new ElementStylesControllerApi(configuration);
export const abMockerApi = new AbMockerControllerApi(configuration);
export const productTestApi = new SubscriptionEmailControllerApi(configuration);

export type TCategory = WebsiteResourceCategoryEnum;
// eslint-disable-next-line no-redeclare
export const TCategory = WebsiteResourceCategoryEnum;
export type TCreateCategory = WebsiteCreateRequestCategoryEnum;
// eslint-disable-next-line no-redeclare
export const TCreateCategory = WebsiteCreateRequestCategoryEnum;
