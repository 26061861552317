import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type Heading2Properties = {
  heading2Style: { style: string };
  heading2Text: { text: string };
};

export function Heading2({
  properties: { heading2Style, heading2Text },
  className,
  ...props
}: ElementComponentProps<Heading2Properties>): JSX.Element {
  return (
    <h2
      {...props}
      className={className + "h2 heading-2-style-" + heading2Style.style}
    >
      {heading2Text.text}
    </h2>
  );
}

export const heading2ElementKey = "heading-2";

elementComponentRegistry.register(heading2ElementKey, Heading2);
