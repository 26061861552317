import { call, put, select, takeLatest } from "@redux-saga/core/effects";
import {
  LoginUsingPOSTRequest,
  FindAuthorByIdUsingGETRequest,
  FindAllWebsitesUsingGETRequest,
  SignUpUsingPOSTRequest,
  CreateAuthorUsingPOSTRequest,
  LoginResponse,
  SignUpResponse,
  MeResponse,
  AuthorResource,
  WebsiteResource,
  UpdateCredentialsResponse,
  UpdateUsernameUsingPOSTRequest,
  UpdatePasswordUsingPOSTRequest,
  UpdateAuthorUsingPOSTRequest,
  ProviderResponse,
  LogActivityUsingPOSTRequest,
  UpdateUserPlanUsingPOSTRequest,
  UpdateUserPlanResponse,
  UpdateUserPlanRequestRoleEnum,
  GetCountrySettingUsingGETRequest,
  DLocalCountrySettingResource,
  ResendEmailUsingPOSTRequest,
  VerifyTokenUsingPOSTRequest,
  PaypalCountrySettingResource,
  PaymentRequestResource,
  ProcessPaypalPaymentRequestUsingPOSTRequest,
  LogLoginUsingPOSTRequest,
  ResetPasswordUsingPOSTRequest,
  ResetPasswordWithTokenUsingPOSTRequest,
  SubscribeFacebookUsingPOSTRequest,
  ProcessPaymentRequestUsingPOSTRequest,
  DLocalPaymentResource,
  GetPaymentSettingsUsingGETRequest,
  PaymentSettingsResponse,
} from "@urux/arm-of-dorne-api";
import {
  authApi,
  authorApi,
  websiteApi,
  paymentGatewayApi,
  dlocalSettingsApi,
  paypalSettingsApi,
  productTestApi,
} from "../api";
import * as apiActions from "../api/apiActions";
import * as authActions from "./authActions";
import * as websiteActions from "../admin/websiteActions";
import * as reportsActions from "../editor/states/reports";
import { getUserWebsiteSlug } from "../auth/selectors";
import { PlanCategoryEnum } from "admin/SelectStep0Form";

export function* loginWithAccessToken(
  action: authActions.LoginWithAccessTokenRequestAction
) {
  yield put(apiActions.startRequest());
  try {
    const loginResponse = yield call(loginAsync, "", "", action.accessToken);
    const authorResponse = yield call(findAuthorByIdAsync, loginResponse.id!);
    const websiteResourceResponse: WebsiteResource = yield call(
      fetchUserWebsiteSlugAsync,
      loginResponse.id!
    );

    const providerResponse = yield call(getUserProviderAsync);
    yield put(
      authActions.getUserProviderSuccess(
        providerResponse.provider,
        providerResponse.isUserVerified
      )
    );

    yield put(
      authActions.loginWithAccessTokenSuccess(
        loginResponse.id!,
        authorResponse.firstName!,
        authorResponse.lastName!,
        authorResponse.isSuper!,
        authorResponse.countryCode!,
        authorResponse.country!,
        websiteResourceResponse ? websiteResourceResponse.slug : undefined,
        authorResponse.username!,
        loginResponse.token!,
        websiteResourceResponse
          ? websiteResourceResponse.websiteUniqueId!
          : undefined,
        providerResponse.provider
      )
    );
    yield call(
      action.callback,
      loginResponse.id!,
      authorResponse.firstName!,
      authorResponse.lastName!
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.loginFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* login(action: authActions.LoginRequestAction) {
  yield put(apiActions.startRequest());
  try {
    const loginResponse = yield call(
      loginAsync,
      action.username,
      action.password,
      action.accessToken
    );
    const authorResponse = yield call(findAuthorByIdAsync, loginResponse.id!);
    const websiteResourceResponse: WebsiteResource = yield call(
      fetchUserWebsiteSlugAsync,
      loginResponse.id!
    );

    const providerResponse = yield call(getUserProviderAsync);

    yield put(
      authActions.getUserProviderSuccess(
        providerResponse.provider,
        providerResponse.isUserVerified
      )
    );

    yield put(
      authActions.loginSuccess(
        loginResponse.id!,
        authorResponse.firstName!,
        authorResponse.lastName!,
        authorResponse.isSuper!,
        authorResponse.countryCode!,
        authorResponse.country!,
        websiteResourceResponse ? websiteResourceResponse.slug! : undefined,
        action.username,
        loginResponse.token!,
        websiteResourceResponse
          ? websiteResourceResponse.websiteUniqueId!
          : undefined
      )
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.loginFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* logout() {
  yield put(apiActions.startRequest());
  try {
    yield put(authActions.logoutSuccess());
    yield call(logoutAsync);
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.logoutFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
  yield put(websiteActions.resetWebsiteState());
}

export function* signUp(action: authActions.SignUpRequestAction) {
  yield put(apiActions.startRequest());
  try {
    yield call(signUpAsync, action.username, action.password);
    const loginResponse = yield call(
      loginAsync,
      action.username,
      action.password,
      undefined
    );
    if (loginResponse.id && loginResponse.id !== 0) {
      const authorResponse = yield call(
        createAuthorAsync,
        action.firstName,
        action.lastName,
        action.countryCode,
        action.country
      );

      yield put(
        authActions.loginSuccess(
          loginResponse.id!,
          authorResponse.firstName!,
          authorResponse.lastName!,
          authorResponse.isSuper!,
          authorResponse.countryCode!,
          authorResponse.country!,
          undefined,
          action.username,
          undefined,
          undefined
        )
      );
      yield put(authActions.signUpSuccess());
    }
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.signUpFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* fetchCurrentUser(
  action: authActions.FetchCurrentUserRequestAction
) {
  yield put(apiActions.authStartRequest());
  yield put(apiActions.startRequest());
  try {
    const currentUserResponse = yield call(meAsync);

    const authorResponse = yield call(
      findAuthorByIdAsync,
      currentUserResponse.id!
    );
    const websiteResourceResponse: WebsiteResource = yield call(
      fetchUserWebsiteSlugAsync,
      currentUserResponse.id!
    );
    yield put(
      authActions.loginSuccess(
        currentUserResponse.id!,
        authorResponse.firstName!,
        authorResponse.lastName!,
        authorResponse.isSuper!,
        authorResponse.countryCode!,
        authorResponse.country!,
        websiteResourceResponse ? websiteResourceResponse.slug! : undefined,
        currentUserResponse.username!,
        undefined,
        websiteResourceResponse
          ? websiteResourceResponse.websiteUniqueId
          : undefined!
      )
    );
    yield put(apiActions.authFinishRequest());
    yield put(authActions.fetchCurrentUserSuccess());
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(apiActions.authFinishRequestWithErrors(e));
    yield put(authActions.fetchCurrentUserFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* getGoogleClientId() {
  yield put(apiActions.startRequest());
  try {
    const googleClientId = yield call(getGoogleClientIdAsync);
    yield put(authActions.getGoogleIdSuccess(googleClientId));
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getGoogleIdFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* getUnsplashKey() {
  yield put(apiActions.startRequest());
  try {
    const unsplashKey = yield call(getUnsplashKeyAsync);
    yield put(authActions.getUnplashKeySuccess(unsplashKey));
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* verifyEmailAndToken(action: authActions.VerifyTokenAction) {
  yield put(apiActions.startRequest());
  try {
    const isVerified: boolean = yield call(
      verifyEmailAndTokenAsync,
      action.token,
      action.username
    );
    yield call(action.callback, isVerified);
    yield put(authActions.verifyTokenSuccess(isVerified));
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(apiActions.finishRequest());
  }
}

export function* startDlocalPaymentProcess(
  action: authActions.StartDlocalPaymentProcessAction
) {
  yield put(apiActions.startRequest());
  try {
    const response = yield call(
      startDLocalPaymentProcessAsync,
      action.userId,
      action.amount,
      action.currency,
      action.countryCode,
      action.billingName,
      action.email,
      action.documentNumber,
      action.cardToken,
      action.subscriptionFrequency
    );

    yield put(
      authActions.handlePaymentProcessResult(response.status, response.message)
    );

    yield put(reportsActions.getSubscriptionAndPayments(action.userId));

    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

const startDLocalPaymentProcessAsync = async (
  userId: number,
  amount: number,
  currency: string,
  countryCode: string,
  billingName: string,
  email: string,
  documentNumber: string,
  cardToken: string,
  paymentFrequency: string
): Promise<DLocalPaymentResource> => {
  const request: ProcessPaymentRequestUsingPOSTRequest = {
    paymentRequest: {
      userId: userId,
      amount: amount,
      currency: currency,
      countryCode: countryCode,
      billingName: billingName,
      email: email,
      documentNumber: documentNumber,
      cardToken: cardToken,
      paymentFrequency: paymentFrequency,
    },
  };

  return await paymentGatewayApi.processPaymentRequestUsingPOST(request);
};

export function* resendEmailVerification(
  action: authActions.ResendEmailVerifyAction
) {
  yield put(apiActions.startRequest());
  try {
    const isResent = yield call(resendEmailVerificationAsync, action.username);
    yield call(action.callback, isResent);
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(apiActions.finishRequest());
  }
}

export function* subscribeTestFBAd1(action: authActions.SubscribeTestFBAd1) {
  yield put(apiActions.startRequest());
  try {
    yield call(subscribeTestFBAd1Async, action.email);
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* resetPasswordWithToken(
  action: authActions.PasswordResetAction
) {
  yield put(apiActions.startRequest());
  try {
    const resetDone = yield call(
      resetPasswordWithTokenAsync,
      action.username,
      action.password,
      action.token
    );
    yield call(action.callback, resetDone);
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* resetPassword(action: authActions.PasswordResetRequestAction) {
  yield put(apiActions.startRequest());
  try {
    const resetDone = yield call(resetPasswordAsync, action.username);
    if (resetDone) {
      yield call(action.callback);
    }
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* logLoginSuccess(action: authActions.LoginSuccessAction) {
  yield put(apiActions.startRequest());
  try {
    yield call(logLoginSuccessAsync, action.userId);

    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* processPaypalPaymentRequest(
  action: authActions.ProcessPaypalPaymentRequestAction
) {
  yield put(apiActions.startRequest());
  try {
    const response = yield call(
      processPaypalPaymentActionAsync,
      action.userId,
      action.amount,
      action.currency,
      action.countryCode,
      action.email,
      action.orderId,
      action.orderStatus,
      action.subscriptionId,
      action.subscriptionFrequency
    );

    if (response && response.status === "SUCCESS") {
      yield call(action.callback, true);
    } else {
      yield call(action.callback, false);
    }

    yield put(
      authActions.handlePaymentProcessResult(response.status, response.message)
    );

    yield put(reportsActions.getSubscriptionAndPayments(action.userId));

    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* getPaypalCountryReference(
  action: authActions.GetPaypalCountryReferenceAction
) {
  yield put(apiActions.startRequest());
  try {
    const response = yield call(
      getPaypalCountryReferenceAsync,
      action.countryCode
    );
    yield put(
      authActions.getPaypalCountryReferenceSuccess(
        response.country!,
        response.countryIsoLanguageCode!,
        response.countryCode!,
        response.currencyCode!,
        response.price!,
        response.annualPrice!,
        response.monthlyPlanId!,
        response.annualPlanId!
      )
    );

    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* getPaymentSettingsForCountry(
  action: authActions.GetPaymentSettingsForCountryAction
) {
  yield put(apiActions.startRequest());
  try {
    const response = yield call(
      getPaymentSettingsForCountryAsync,
      action.countryCode
    );

    yield put(
      authActions.getPaymentSettingsForCountrySuccess(
        response.isDlocal,
        response.isPaypal,
        response.preferredPaymentGateway
      )
    );

    yield call(
      action.callback,
      action.countryCode,
      response.isDlocal,
      response.isPaypal
    );

    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* getDlocalCountryReference(
  action: authActions.GetDlocalCountryReferenceAction
) {
  yield put(apiActions.startRequest());
  try {
    const response = yield call(
      getDlocalCountryReferenceAsync,
      action.countryCode
    );
    yield put(
      authActions.getDlocalCountryReferenceSuccess(
        response.country!,
        response.countryIsoLanguageCode!,
        response.countryCode!,
        response.currencyCode!,
        response.documentName!,
        response.documentFormat!,
        response.documentFormatMin!,
        response.documentFormatMax!,
        response.isRequired!,
        response.price!,
        response.annualPrice!,
        response.apiKey!
      )
    );

    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUnplashKeyFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* getUserProvider() {
  yield put(apiActions.startRequest());
  try {
    const providerResponse = yield call(getUserProviderAsync);
    yield put(
      authActions.getUserProviderSuccess(
        providerResponse.provider,
        providerResponse.isUserVerified
      )
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getUserProviderFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* fetchRedirectUri() {
  yield put(apiActions.startRequest());
  try {
    const redirectUri = yield call(getRedirectUri);
    yield put(authActions.fetchRedirectUriSuccess(redirectUri));
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getGoogleIdFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* fetchBaseUrl() {
  yield put(apiActions.startRequest());
  try {
    const baseUrl = yield call(getBaseUrl);
    yield put(authActions.fetchBaseUrlSuccess(baseUrl));
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.getGoogleIdFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* fetchUser(action: authActions.FetchUserRequestAction) {
  yield put(apiActions.startRequest());
  try {
    const authorResponse = yield call(validateUsername, action.username);
    yield put(authActions.fetchUserSuccess(authorResponse));
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.fetchUserFailure());
    yield put(apiActions.finishRequest());
  }
}

export function* updateUserPlan(action: authActions.UpdateUserPlanAction) {
  yield put(apiActions.startRequest());
  try {
    yield call(
      updateUserPlanAsync,
      action.id,
      action.plan === PlanCategoryEnum.PAID
        ? UpdateUserPlanRequestRoleEnum.PAIDADMIN
        : UpdateUserPlanRequestRoleEnum.ADMIN
    );
    yield call(action.callback);
    yield put(authActions.updateUserPlanSuccess());
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.updateCredentialsFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* updateUsername(
  action: authActions.UpdateUsernameRequestAction
) {
  yield put(apiActions.startRequest());
  try {
    const usernameResponse = yield call(
      updateUsernameAsync,
      action.id,
      action.username,
      ""
    );
    yield put(authActions.updateUsernameSuccess(usernameResponse.username));
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.updateCredentialsFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* updatePassword(
  action: authActions.UpdatePasswordRequestAction
) {
  yield put(apiActions.startRequest());
  try {
    yield call(updatePasswordAsync, action.id, "", action.password);
    yield put(authActions.updatePasswordSuccess());
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.updateCredentialsFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* updateDetails(action: authActions.UpdateDetailsRequestAction) {
  yield put(apiActions.startRequest());
  try {
    const usernameResponse = yield call(
      updateDetailsAsync,
      action.id,
      action.firstName,
      action.lastName,
      action.countryCode,
      action.country
    );
    yield put(
      authActions.updateDetailsSuccess(
        usernameResponse.username,
        usernameResponse.firstName,
        usernameResponse.lastName,
        usernameResponse.country,
        usernameResponse.countryCode
      )
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(authActions.updateCredentialsFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* logActivity(action: authActions.LogActivityRequestAction) {
  yield put(apiActions.startRequest());
  try {
    const websiteSlug = yield select(getUserWebsiteSlug);
    let sessionId = "*" + Math.random().toString(36).substr(2, 9);
    if (document.cookie) {
      let idHolder = document.cookie
        .split("; ")
        .find((row) => row.startsWith("sessionId="));
      if (idHolder) {
        sessionId = idHolder.split("=")[1];
      }
    }
    const timestamp = Date.now();
    yield call(
      logActivityAsync,
      sessionId,
      action.action,
      action.page,
      timestamp,
      websiteSlug,
      action.property
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

const logActivityAsync = async (
  sessionId: string,
  action: string,
  page: string,
  timestamp: number,
  websiteSlug?: string | null,
  property?: string | null
): Promise<any> => {
  const request: LogActivityUsingPOSTRequest = {
    request: {
      sessionId: sessionId,
      websiteSlug: websiteSlug!,
      action: action,
      page: page,
      property: property!,
      timestamp: timestamp,
    },
  };
  return await authApi.logActivityUsingPOST(request);
};

const loginAsync = async (
  username: string,
  password: string,
  accessToken: string | undefined
): Promise<LoginResponse> => {
  const request: LoginUsingPOSTRequest = {
    request: { username, password, accessToken },
  };
  return await authApi.loginUsingPOST(request);
};

const subscribeTestFBAd1Async = async (email: string) => {
  const request: SubscribeFacebookUsingPOSTRequest = {
    subscribeEmailRequest: {
      aodapiKey: "a5m0fd05n4l0cal",
      email,
    },
  };
  return await productTestApi.subscribeFacebookUsingPOST(request);
};

const resetPasswordWithTokenAsync = async (
  username: string,
  password: string,
  token: string
): Promise<boolean> => {
  const request: ResetPasswordWithTokenUsingPOSTRequest = {
    request: { username, password, token },
  };

  return await authApi.resetPasswordWithTokenUsingPOST(request);
};

const resetPasswordAsync = async (username: string): Promise<boolean> => {
  const request: ResetPasswordUsingPOSTRequest = {
    request: { username },
  };
  return await authApi.resetPasswordUsingPOST(request);
};

const logLoginSuccessAsync = async (userId: number): Promise<boolean> => {
  const request: LogLoginUsingPOSTRequest = {
    request: { userId },
  };
  return await authApi.logLoginUsingPOST(request);
};

const logoutAsync = async (): Promise<object> => {
  return await authApi.logoutUsingPOST();
};

const signUpAsync = async (
  username: string,
  password: string
): Promise<SignUpResponse> => {
  const request: SignUpUsingPOSTRequest = {
    request: { username, password },
  };
  return await authApi.signUpUsingPOST(request);
};

const updateUserPlanAsync = async (
  id: number,
  role: UpdateUserPlanRequestRoleEnum
): Promise<UpdateUserPlanResponse> => {
  const request: UpdateUserPlanUsingPOSTRequest = {
    request: {
      id,
      role,
    },
  };
  return await authApi.updateUserPlanUsingPOST(request);
};

const updateUsernameAsync = async (
  id: number,
  username: string,
  password: string
): Promise<UpdateCredentialsResponse> => {
  const request: UpdateUsernameUsingPOSTRequest = {
    request: { id, username, password },
  };

  return await authApi.updateUsernameUsingPOST(request);
};

const updatePasswordAsync = async (
  id: number,
  username: string,
  password: string
): Promise<UpdateCredentialsResponse> => {
  const request: UpdatePasswordUsingPOSTRequest = {
    request: { id, username, password },
  };

  return await authApi.updatePasswordUsingPOST(request);
};

const updateDetailsAsync = async (
  id: number,
  firstName: string,
  lastName: string,
  countryCode: string,
  country: string
): Promise<AuthorResource> => {
  const request: UpdateAuthorUsingPOSTRequest = {
    request: { id, firstName, lastName, countryCode, country },
  };

  return await authorApi.updateAuthorUsingPOST(request);
};

const meAsync = async (): Promise<MeResponse> => {
  return await authApi.meUsingGET();
};

const findAuthorByIdAsync = async (
  authorId: number
): Promise<AuthorResource> => {
  const request: FindAuthorByIdUsingGETRequest = {
    authorId,
  };
  return await authorApi.findAuthorByIdUsingGET(request);
};

const findAllWebsitesAsync = async (
  ownerId: number
): Promise<Array<WebsiteResource>> => {
  const request: FindAllWebsitesUsingGETRequest = {
    ownerId,
  };
  return await websiteApi.findAllWebsitesUsingGET(request);
};

export const findAllUnlinkedWebsitesAsync = async (): Promise<
  Array<WebsiteResource>
> => {
  return await websiteApi.getAllUnlinkedWebsitesUsingGET();
};

const fetchUserWebsiteSlugAsync = async (
  userId: number
): Promise<WebsiteResource | undefined> => {
  const response = await findAllWebsitesAsync(userId);
  return response.length > 0 ? response[0] : undefined;
};

const createAuthorAsync = async (
  firstName: string,
  lastName: string,
  countryCode?: string,
  country?: string
): Promise<AuthorResource> => {
  const request: CreateAuthorUsingPOSTRequest = {
    request: { firstName, lastName, countryCode, country },
  };
  return await authorApi.createAuthorUsingPOST(request);
};

const getResponseJsonAsync = async (response: Response): Promise<any> => {
  return await response.json();
};

const getGoogleClientIdAsync = async (): Promise<string> => {
  return await authApi.getGoogleClientIdUsingGET();
};

const getUnsplashKeyAsync = async (): Promise<string> => {
  return await authApi.getUnsplashKeyUsingGET();
};

const verifyEmailAndTokenAsync = async (
  token: string,
  username: string
): Promise<boolean> => {
  const request: VerifyTokenUsingPOSTRequest = {
    request: { token, username },
  };
  return await authApi.verifyTokenUsingPOST(request);
};

const resendEmailVerificationAsync = async (
  username: string
): Promise<boolean> => {
  const request: ResendEmailUsingPOSTRequest = {
    request: { username },
  };
  return await authApi.resendEmailUsingPOST(request);
};

const processPaypalPaymentActionAsync = async (
  userId: number,
  amount: number,
  currency: string,
  countryCode: string,
  email: string,
  orderId: string,
  orderStatus: string,
  subscriptionId: string,
  subscriptionFrequency: string
): Promise<PaymentRequestResource> => {
  const request: ProcessPaypalPaymentRequestUsingPOSTRequest = {
    paymentRequest: {
      userId: userId,
      amount: amount,
      currency: currency,
      countryCode: countryCode,
      email: email,
      orderId: orderId,
      orderStatus: orderStatus,
      subscriptionId: subscriptionId,
      paymentFrequency: subscriptionFrequency,
    },
  };

  return await paymentGatewayApi.processPaypalPaymentRequestUsingPOST(request);
};

const getPaymentSettingsForCountryAsync = async (
  countryCode: string
): Promise<PaymentSettingsResponse> => {
  const request: GetPaymentSettingsUsingGETRequest = {
    countryCode: countryCode,
  };

  return await authApi.getPaymentSettingsUsingGET(request);
};

const getDlocalCountryReferenceAsync = async (
  countryCode: string
): Promise<DLocalCountrySettingResource> => {
  const request: GetCountrySettingUsingGETRequest = {
    countryCode: countryCode,
  };

  return await dlocalSettingsApi.getCountrySettingUsingGET(request);
};

const getPaypalCountryReferenceAsync = async (
  countryCode: string
): Promise<PaypalCountrySettingResource> => {
  const request: GetCountrySettingUsingGETRequest = {
    countryCode: countryCode,
  };

  return await paypalSettingsApi.getCountrySettingUsingGET1(request);
};

const getUserProviderAsync = async (): Promise<ProviderResponse> => {
  return await authApi.getProviderUsingGET();
};

const getRedirectUri = async (): Promise<string> => {
  return await authApi.getRedirectUriUsingGET();
};

const getBaseUrl = async (): Promise<string> => {
  return await authApi.getBaseUrlUsingGET();
};

const validateUsername = async (username: string): Promise<boolean> => {
  return await authApi.findUserByUsernameUsingGET({ username: username });
};

export function* rootSaga() {
  yield takeLatest("AUTH_LOGIN_REQUEST", login);
  yield takeLatest("AUTH_LOGIN_WITH_ACCESSTOKEN_REQUEST", loginWithAccessToken);
  yield takeLatest("AUTH_LOGOUT_REQUEST", logout);
  yield takeLatest("AUTH_SIGN_UP_REQUEST", signUp);
  yield takeLatest("AUTH_FETCH_CURRENT_USER_REQUEST", fetchCurrentUser);
  yield takeLatest("AUTH_GOOGLE_ID_REQUEST", getGoogleClientId);
  yield takeLatest("AUTH_REDIRECT_URI", fetchRedirectUri);
  yield takeLatest("AUTH_BASE_URL", fetchBaseUrl);
  yield takeLatest("AUTH_FETCH_USER_REQUEST", fetchUser);
  yield takeLatest("AUTH_UPDATE_USERNAME_REQUEST", updateUsername);
  yield takeLatest("AUTH_UPDATE_PASSWORD_REQUEST", updatePassword);
  yield takeLatest("AUTH_UPDATE_DETAILS_REQUEST", updateDetails);
  yield takeLatest("AUTH_UPDATE_DETAILS_COUNTRY_REQUEST", updateDetails);
  yield takeLatest("AUTH_UNSPLASH_KEY_REQUEST", getUnsplashKey);
  yield takeLatest("AUTH_USER_PROVIDER_REQUEST", getUserProvider);
  yield takeLatest("LOG_ACTIVITY_REQUEST", logActivity);
  yield takeLatest("AUTH_UPDATE_USER_PLAN_REQUEST", updateUserPlan);
  yield takeLatest(
    "AUTH_GET_DLOCAL_COUNTRY_REFERENCE_REQUEST",
    getDlocalCountryReference
  );
  yield takeLatest(
    "AUTH_GET_PAYPAL_COUNTRY_REFERENCE_REQUEST",
    getPaypalCountryReference
  );
  yield takeLatest(
    "AUTH_PROCESS_PAYPAL_PAYMENT_REQUEST",
    processPaypalPaymentRequest
  );
  yield takeLatest("AUTH_RESEND_EMAIL_VERIFY", resendEmailVerification);
  yield takeLatest("AUTH_VERIFY_TOKEN", verifyEmailAndToken);
  yield takeLatest("AUTH_LOGIN_SUCCESS", logLoginSuccess);
  yield takeLatest("AUTH_PASSWORD_RESET_REQUEST", resetPassword);
  yield takeLatest("AUTH_RESET_PASSWORD_REQUEST", resetPasswordWithToken);
  yield takeLatest("SUBSCRIBE_TEST_FB_AD1", subscribeTestFBAd1);
  yield takeLatest(
    "AUTH_START_DLOCAL_PAYMENT_PROCESS_REQUEST",
    startDlocalPaymentProcess
  );
  yield takeLatest(
    "AUTH_GET_PAYMENT_SETTINGS_FOR_COUNTRY_REQUEST",
    getPaymentSettingsForCountry
  );
}
