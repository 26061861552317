import { MoreHorizOutlined } from "@material-ui/icons";
import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { SocialLinkCollectionProperty } from "editor/properties/SocialLinkCollectionProperty/SocialLinkCollectionProperty";
import { PropertyRenderer } from "../../PropertyRenderer";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialNavProperties } from "common/elements/components/SocialNav";
import { SocialLinkStyleProperty } from "editor/properties/SocialLinkStyleProperty/SocialLinkStyleProperty";
import styles from "./SocialNav.module.scss";
import { LinkColorProperty } from "editor/properties/LinkColorProperty/LinkColorProperty";

import { useTranslation } from "react-i18next";

export class SocialNavElement {
  static readonly elementKey = "social-nav";
  static readonly label = "element_sociallinks_label";
  static readonly icon = MoreHorizOutlined;
  static readonly allowedToolbarPositions = [];
  static readonly elementDescription = "element_sociallinks_description";
  static readonly dialogPositionStatic = false;
  static readonly isDeletable = true;

  static readonly properties: PropertiesOf<SocialNavProperties> = {
    socialLinks: SocialLinkCollectionProperty,
    style: SocialLinkStyleProperty,
    isProduction: Boolean,
    linkColor: LinkColorProperty,
  };

  static renderProperties(element: ElementType) {
    return <SocialNavPropertiesComponent element={element} />;
  }

  static create(properties: {
    socialLinks: SocialLinkProperty[];
    position: string;
    style: string;
    linkColor?: string;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const SocialNavPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof SocialNavElement.properties>(
    SocialNavElement.properties,
    element
  );
  const { t } = useTranslation();
  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="links">
        <Tab eventKey="links" title={t("dialog_21")}>
          {renderer.render("socialLinks")}
        </Tab>
        <Tab eventKey="appearance" title={t("dialog_39")}>
          {renderer.render("style")}
        </Tab>
        <Tab eventKey="linkColor" title={t("dialog_16")}>
          {renderer.render("linkColor")}
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(SocialNavElement);
