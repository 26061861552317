import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { Badge, Carousel } from "react-bootstrap";

import { Formik, Form } from "formik";

import styles from "./CardsContainerStyleProperty.module.scss";
import "./CardsContainerStyleProperty.scss";

import { useTranslation } from "react-i18next";

export class CardsContainerStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <CardsContainerStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new CardsContainerStyleProperty(value);
  }
}

const CardsContainerStylePropertyComponent: PropertyComponent<CardsContainerStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  const radios = [
    {
      value: "0",
      icon: (
        <>
          <div className={styles["card-container"]}>
            <div
              className={`${styles["card"]} ${
                style === "0" ? styles["active"] : ""
              }`}
            >
              <img
                className={styles["img"]}
                src="https://images.unsplash.com/photo-1646617747563-4f080bddf282?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt="Sample 1"
              ></img>
              <div className={styles["content-container"]}>
                <h4 className={styles["h4"]}>{t("dialog_88")}</h4>
                <p className={styles["p"]}>Lorem ipsum dolor sit amet.</p>
                <p className={styles["a"]}>{t("dialog_89")}</p>
              </div>
            </div>
          </div>
          <div className={styles["card-style-label"]}>
            <Badge variant="secondary">{t("dialog_90")}</Badge>
          </div>
        </>
      ),
    },
    {
      value: "1",
      icon: (
        <>
          <div className={styles["card-container"]}>
            <div
              className={`${styles["card-2"]} ${
                style === "1" ? styles["active"] : ""
              }`}
            >
              <img
                className={styles["img-2"]}
                src="https://images.unsplash.com/photo-1591290950068-40b9b598e6fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt="Sample 2"
              ></img>
              <div className={styles["content-container-2"]}>
                <h4 className={styles["h4-2"]}>{t("dialog_88")}</h4>
                <p className={styles["p-2"]}>Lorem ipsum dolor sit amet.</p>
                <p className={styles["a-2"]}>{t("dialog_89")}</p>
              </div>
            </div>
          </div>
          <div className={styles["card-style-label"]}>
            <Badge variant="secondary">{t("dialog_91")}</Badge>
          </div>
        </>
      ),
    },
    {
      value: "2",
      icon: (
        <>
          <div className={styles["card-container"]}>
            <div
              className={`${styles["card-3"]} ${
                style === "2" ? styles["active"] : ""
              }`}
            >
              <img
                className={styles["img-3"]}
                src="https://images.unsplash.com/photo-1581464647110-26e129ce2d02?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt="Sample 3"
              ></img>
              <div className={styles["content-container-3"]}>
                <h4 className={styles["h4-3"]}>{t("dialog_88")}</h4>
                <p className={styles["p-3"]}>Lorem ipsum dolor sit amet.</p>
                <p className={styles["a-3"]}>{t("dialog_89")}</p>
              </div>
            </div>
          </div>
          <div className={styles["card-style-label"]}>
            <Badge variant="secondary">{t("dialog_92")}</Badge>
          </div>
        </>
      ),
    },
  ];

  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <Form className="form">
        <div className="form__group">
          <div className={styles["instructions-container"]}>
            {t("dialog_41")}
          </div>
          <Carousel
            className={styles["card-carousel"]}
            interval={null}
            defaultActiveIndex={parseInt(style)}
            onSlide={(e: any) => {
              handleStyleChange(e);
            }}
          >
            {radios.map((radio, idx) => (
              <Carousel.Item
                className={styles["carousel-item"]}
                onClick={(e: any) => handleStyleChange(radio.value)}
              >
                {radio.icon}
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Form>
    </Formik>
  );
};
