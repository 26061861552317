import React from "react";
import {
  BackgroundColorProperty,
  FormConfigurationProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Twitter, Facebook } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { makeupChild1Template } from "./makeupChild1";
import { makeupChild2Template } from "./makeupChild2";
import { NavbarElement } from "editor/elements/sections/Navbar/Navbar";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { LogoContainerElement } from "editor/elements/components/LogoContainer/LogoContainer";
import { NavElement } from "editor/elements/components/Nav/Nav";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { HeadingElement } from "editor/elements/base/Heading";
import { LeadElement } from "editor/elements/base/Lead";
import { ArticleElement } from "editor/elements/components/Article";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { ImageElement } from "editor/elements/base/Image/Image";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const makeupTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "makeup";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 5,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 6,
    templateTitle: templateKey,
  },
  {
    elementKey: "messenger",
    position: 7,
    templateTitle: templateKey,
  },
  {
    elementKey: "whatsapp",
    position: 8,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return LayoutEditor.createEmpty()
        .add(
          NavbarElement.create({
            navbarStyle: "2",
            width: 100,
            backgroundColorProperty: "#194D33",
            backgroundImageProperty: new BackgroundImageProperty(
              undefined,
              "repeat",
              "center",
              "center",
              "cover",
              0,
              0,
              1
            ),
          })
        )
        .addChildren(
          LayoutEditor.createEmpty()
            .add(
              LogoContainerElement.create({
                label: "logo",
                logoText: new LogoTextProperty(defaultLogoText),
                logoStyle: new LogoStyleProperty(
                  "logo",
                  isLogoCreated && websiteSlug
                    ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
                    : "https://s3.amazonaws.com/img.goodweb.app/sites/default/makeup/main.png",
                  new Date().toISOString()
                ),
                logoDecoration: "0",
                logoImage: new LogoImageProperty(
                  undefined,
                  new Date().toISOString()
                ),
              })
            )
            .add(
              NavElement.create({
                links: TemplatesFactory.getLinkProperties(
                  childPageTemplates,
                  false,
                  mainPageDetails
                ),
                style: "2",
                linkColor: "#FFFFFF",
              })
            )
            .build()
        )
        .build();

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/makeup/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright MADEUP " + new Date().getFullYear()
      );

    default:
      return makeupTemplates[layoutKey];
  }
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "makeupChild1Template",
    details: {
      title: "My Work",
      slug: randomSlug(6),
      description: "My Work",
    },
    template: {
      template: makeupChild1Template,
    },
  },
  {
    templateName: "makeupChild2Template",
    details: {
      title: "Contact",
      slug: randomSlug(6),
      description: "Contact MADEUP",
    },
    template: {
      template: makeupChild2Template,
    },
  },
];

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page MADEUP",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: makeupChild2Template,
  },
};

makeupTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "Fashion",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

makeupTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "Makeup",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);

makeupTemplates["navbar"] = LayoutEditor.createEmpty()
  .add(
    NavbarElement.create({
      navbarStyle: "2",
      width: 100,
      backgroundColorProperty: "#194D33",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        LogoContainerElement.create({
          label: "logo",
          logoText: new LogoTextProperty(defaultLogoText),
          logoStyle: new LogoStyleProperty(
            "logo",
            "https://s3.amazonaws.com/img.goodweb.app/sites/default/makeup/main.png",
            new Date().toISOString()
          ),
          logoDecoration: "0",
          logoImage: new LogoImageProperty(undefined, new Date().toISOString()),
        })
      )
      .add(
        NavElement.create({
          links: TemplatesFactory.getLinkProperties(
            childPageTemplates,
            false,
            mainPageDetails
          ),
          style: "2",
          linkColor: "#FFFFFF",
        })
      )
      .build()
  )
  .build();

makeupTemplates["articles"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#AE9F7F",
      acrticlesAlignment: "1",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ImageElement.create({
              imageStyle: new ImageStyleProperty("1"),
              imgUrl:
                "https://images.unsplash.com/photo-1536610485688-6eccb309a182?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
              imageText: "",
            })
          )
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h1><span style="color: rgb(38, 12, 25);"><br>Feelin' <br>hot, hot, hot.</span></h1>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<div><span style="color: rgb(38, 12, 25);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt.<br><br></span></div>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .add(
        ArticleElement.create({
          articleStyle: "2",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1624668948516-cab8d7ce4414?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .build()
  )
  .build();

makeupTemplates["articles2"] = LayoutEditor.createEmpty()
  .add(
    ArticlesElement.create({
      articlesStyle: "2",
      backgroundColorProperty: "#4D6057",
      acrticlesAlignment: "2",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        ArticleElement.create({
          articleStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ArticleContentElement.create({
              articleContentStyle: "2",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: new ImageStyleProperty("1"),
                  imgUrl:
                    "https://images.unsplash.com/photo-1589523101416-3b235a915408?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1704&q=80",
                  imageText: "",
                })
              )
              .build()
          )
          .build()
      )
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "1",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(255, 255, 255);"><br></span></h2><h2><span style="color: rgb(255, 255, 255);">Make up is my passion.<br>See my work.</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .add(
            LeadElement.create({
              leadStyle: new LeadStyleProperty("1"),
              richTextProperty: new RichTextProperty(
                `<span style="color: rgb(255, 255, 255);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt.</span>`
              ),
              rteAlignment: new RTEAlignmentProperty("left"),
            })
          )
          .build()
      )
      .build()
  )
  .build();

makeupTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("1"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Book an Appointment</h2>"),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Fill in your details below and we'll be in contact with you soon."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

makeupTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/makeup/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright MADEUP " + new Date().getFullYear()
);
