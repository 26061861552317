import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  HomeRounded,
  WebRounded,
  BarChartRounded,
  BuildRounded,
  AssignmentRounded,
  ContactSupportRounded,
  FolderSpecialRounded,
  ExitToAppRounded,
  ArrowRightRounded,
  FlashOnRounded,
  AssignmentTurnedInRounded,
  PaymentRounded,
  StarRounded,
  StyleRounded,
  SystemUpdateAltRounded,
} from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getUsername,
  getUserRole,
  getUserWebsiteSlug,
} from "../../auth/selectors";
import { getPageWithLatestDetailsList } from "editor/states/selectors";
import { pageListRequested } from "editor/states/pageList";
import { latestPageVersionListRequested } from "editor/states/latestPageVersionList";
import { PageWithLatestDetails, Subscription } from "editor/models/types";

import styles from "../Admin.module.scss";
import { logActivity } from "auth/authActions";
import { editorPage } from "editor/components/impl/Editor/Editor";
import {
  accountPage,
  dashboardPage,
  docsPage,
  reportsPage,
  saConsolePage,
  settingsPage,
  supportPage,
  testsPage,
  paymentPage,
  upgradePage,
  elementStylesPage,
  abMockerPage,
} from "admin/Container";
import { Navbar, NavDropdown } from "react-bootstrap";

type Props = {
  url: string;
  currentPage: string;
  onLogoutClick: () => void;
  subscription: Subscription | null;
  isDesktop: boolean;
};

export const adminPage = "Admin";

export const SidebarNav: React.FunctionComponent<Props> = ({
  url,
  currentPage,
  onLogoutClick,
  subscription,
  isDesktop,
}) => {
  const location = useLocation();
  const isSuper = useSelector(getUserRole);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const pageList = useSelector(getPageWithLatestDetailsList);
  const username = useSelector(getUsername);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (websiteSlug) {
      dispatch(pageListRequested(websiteSlug));
      dispatch(latestPageVersionListRequested(websiteSlug));
    }
  }, [dispatch, websiteSlug]);

  if (!websiteSlug) {
    return <></>;
  }

  let indexPage: PageWithLatestDetails | undefined;
  pageList.some(function (page) {
    if (page.isIndex) {
      indexPage = page;
      return true;
    }
    return false;
  });

  let indexPageSlug = `${url}/pages/new`;
  if (indexPage) {
    indexPageSlug = `/admin/pages/${indexPage.slug}/edit`;
  }

  const renderMenu = (): JSX.Element => {
    return (
      <>
        <div className={styles["admin__nav-links-group"]}>
          <Link
            to={url}
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
            onClick={() => {
              dispatch(
                logActivity("GO TO", currentPage, username, dashboardPage)
              );
            }}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <HomeRounded />
            </div>
            <div className={styles["admin__nav-label"]}>{t("admin_home")}</div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded />
            </div>
          </Link>
          <Link
            to={indexPageSlug}
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/pages"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
            onClick={() => {
              dispatch(logActivity("GO TO", currentPage, username, editorPage));
            }}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <WebRounded />
            </div>
            <div className={styles["admin__nav-label"]}>
              {t("website_builder")}
            </div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded />
            </div>
          </Link>
          <Link
            to={url + "/settings"}
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/settings"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
            onClick={() => {
              dispatch(
                logActivity("GO TO", currentPage, username, settingsPage)
              );
            }}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <BuildRounded />
            </div>
            <div className={styles["admin__nav-label"]}>
              {t("website_settings")}
            </div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded className={styles["admin__nav-link-svg"]} />
            </div>
          </Link>
          <Link
            to={url + "/reports"}
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/reports"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
            onClick={() => {
              dispatch(
                logActivity("GO TO", currentPage, username, reportsPage)
              );
            }}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <BarChartRounded />
            </div>
            <div className={styles["admin__nav-label"]}>{t("reports")}</div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded />
            </div>
          </Link>
          <Link
            to={url + "/tests"}
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/tests"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
            onClick={() => {
              dispatch(logActivity("GO TO", currentPage, username, testsPage));
            }}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <AssignmentTurnedInRounded />
            </div>
            <div className={styles["admin__nav-label"]}>{t("tests")}</div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded />
            </div>
          </Link>
          {isSuper ? (
            <Link
              to={url + "/sa-console"}
              className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/sa-console"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
              onClick={() => {
                dispatch(
                  logActivity("GO TO", currentPage, username, saConsolePage)
                );
              }}
            >
              <div className={styles["admin__nav-link-icon"]}>
                <FlashOnRounded />
              </div>
              <div className={styles["admin__nav-label"]}>SA Console</div>
              <div className={styles["admin__nav-link-indicator"]}>
                <ArrowRightRounded />
              </div>
            </Link>
          ) : (
            <></>
          )}
          {isSuper ? (
            <Link
              to={url + "/element-styles"}
              className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/element-styles"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
              onClick={() => {
                dispatch(
                  logActivity("GO TO", currentPage, username, elementStylesPage)
                );
              }}
            >
              <div className={styles["admin__nav-link-icon"]}>
                <StyleRounded />
              </div>
              <div className={styles["admin__nav-label"]}>Element Styles</div>
              <div className={styles["admin__nav-link-indicator"]}>
                <ArrowRightRounded />
              </div>
            </Link>
          ) : (
            <></>
          )}
          {isSuper ? (
            <Link
              to={url + "/ab-mocker"}
              className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/ab-mocker"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
              onClick={() => {
                dispatch(
                  logActivity("GO TO", currentPage, username, abMockerPage)
                );
              }}
            >
              <div className={styles["admin__nav-link-icon"]}>
                <SystemUpdateAltRounded />
              </div>
              <div className={styles["admin__nav-label"]}>Mocker</div>
              <div className={styles["admin__nav-link-indicator"]}>
                <ArrowRightRounded />
              </div>
            </Link>
          ) : (
            <></>
          )}
        </div>
        <div className={styles["admin__nav-links-group"]}>
          <Link
            to={{ pathname: "https://goodweb.tawk.help/" }}
            target="_blank"
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/docs"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
            onClick={() => {
              dispatch(logActivity("GO TO", currentPage, username, docsPage));
            }}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <AssignmentRounded />
            </div>
            <div className={styles["admin__nav-label"]}>{t("Docs")}</div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded />
            </div>
          </Link>
          <Link
            to={url + "/support"}
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/support"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
            onClick={() => {
              dispatch(
                logActivity("GO TO", currentPage, username, supportPage)
              );
            }}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <ContactSupportRounded />
            </div>
            <div className={styles["admin__nav-label"]}>{t("Support")}</div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded />
            </div>
          </Link>
        </div>
        <div
          className={`${styles["admin__nav-links-group"]} ${styles["admin__nav-links-group--bottom"]}`}
        >
          <Link
            to={url + "/account"}
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/account"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
            onClick={() => {
              dispatch(
                logActivity("GO TO", currentPage, username, accountPage)
              );
            }}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <FolderSpecialRounded />
            </div>
            <div className={styles["admin__nav-label"]}>{t("Account")}</div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded />
            </div>
          </Link>
          {subscription && subscription.isActive ? (
            <Link
              to={url + "/payment"}
              className={`${styles["admin__nav-link"]} 
                      ${
                        location.pathname === "/admin/payment"
                          ? styles["admin__nav-link--active"]
                          : ""
                      } $`}
              onClick={() => {
                dispatch(
                  logActivity("GO TO", currentPage, username, paymentPage)
                );
              }}
            >
              <div className={styles["admin__nav-link-icon"]}>
                <PaymentRounded />
              </div>
              <div className={styles["admin__nav-label"]}>
                {t("upgrade_page_payment")}
              </div>
              <div className={styles["admin__nav-link-indicator"]}>
                <ArrowRightRounded />
              </div>
            </Link>
          ) : (
            <Link
              to={url + "/upgrade"}
              className={`${styles["admin__nav-link"]} 
                      ${
                        location.pathname === "/admin/upgrade"
                          ? styles["admin__nav-link--active"]
                          : ""
                      } $`}
              onClick={() => {
                dispatch(
                  logActivity("GO TO", currentPage, username, upgradePage)
                );
              }}
            >
              <div className={styles["admin__nav-link-button"]}>
                <div className={styles["admin__nav-link-icon"]}>
                  <StarRounded />
                </div>
                <div className={styles["admin__nav-label"]}>
                  {t("upgrade_page_subscription_feature")}
                </div>
              </div>
            </Link>
          )}
          <Link
            onClick={onLogoutClick}
            to={"#"}
            className={`${styles["admin__nav-link"]} 
                    ${
                      location.pathname === "/admin/logout"
                        ? styles["admin__nav-link--active"]
                        : ""
                    } $`}
          >
            <div className={styles["admin__nav-link-icon"]}>
              <ExitToAppRounded />
            </div>
            <div className={styles["admin__nav-label"]}>{t("logout")}</div>
            <div className={styles["admin__nav-link-indicator"]}>
              <ArrowRightRounded />
            </div>
          </Link>
        </div>
      </>
    );
  };

  return isDesktop ? (
    <Nav className={styles["admin__sidebar-nav"]}>{renderMenu()}</Nav>
  ) : (
    <div className={styles["admin__topbar"]}>
      <Navbar className={styles["admin__navbar"]}>
        <Navbar.Brand
          href={url}
          className={styles["admin__navbar-logo-container"]}
        >
          <img
            alt="goodweb"
            src="/img/goodweb-logo-white.svg"
            className={styles["admin__navbar-logo"]}
          />
        </Navbar.Brand>
        <NavDropdown
          title="Menu"
          id="nav-dropdown"
          className={styles["admin__navbar-dropdown"]}
        >
          {renderMenu()}
        </NavDropdown>
      </Navbar>
    </div>
  );
};
