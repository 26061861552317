import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import ReactHtmlParser from "react-html-parser";

export type RichTextProperties = {
  richTextProperty: { text: string | undefined };
  rteAlignment: { style: string | undefined };
  dimension: { width?: number; height?: number };
};

export function RichText({
  properties: { richTextProperty, rteAlignment, dimension },
  className,
  innerRef,
  ...props
}: ElementComponentProps<RichTextProperties>): JSX.Element {
  if (richTextProperty.text === undefined) return <></>;
  return (
    <div
      {...props}
      ref={innerRef}
      className={`${className} hobaw-rte rte-${rteAlignment.style}`}
      style={{
        width: dimension && dimension.width ? dimension.width : "",
        height: dimension && dimension.height ? dimension.height : "",
      }}
    >
      {ReactHtmlParser(richTextProperty.text)}
    </div>
  );
}

export const richTextElementKey = "rt";

elementComponentRegistry.register(richTextElementKey, RichText);
