import { CallToActionOutlined } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { CopyrightStyleProperty } from "editor/properties/CopyrightStyleProperty";
import {
  copyrightElementKey,
  CopyrightProperties,
} from "common/elements/base/Copyright";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { CopyrightTextProperty } from "editor/properties/CopyrightTextProperty";

export class CopyrightElement {
  static readonly elementKey = copyrightElementKey;
  static readonly label = "element_copyright_label";
  static readonly icon = CallToActionOutlined;
  static readonly allowedToolbarPositions = [];
  static readonly elementDescription = "element_copyright_description";

  static readonly properties: PropertiesOf<CopyrightProperties> = {
    copyrightStyle: CopyrightStyleProperty,
    copyrightText: CopyrightTextProperty,
  };

  static renderProperties(element: ElementType) {
    return <CopyrightPropertiesComponent element={element} />;
  }

  static create(properties: { copyrightStyle: string; copyrightText: string }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const CopyrightPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof CopyrightElement.properties>(
    CopyrightElement.properties,
    element
  );

  return (
    <>
      {renderer.render("copyrightText")}
      {renderer.render("copyrightStyle")}
    </>
  );
};

elementDefinitionRegistry.register(CopyrightElement);
