import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import ReactHtmlParser from "react-html-parser";

export type HeadlineProperties = {
  headlineStyle: { style: string };
  richTextProperty: { text: string | undefined };
  rteAlignment: { style: string | undefined };
  dimension: { width?: number; height?: number };
};

export function Headline({
  properties: { headlineStyle, richTextProperty, rteAlignment, dimension },
  className,
  ...props
}: ElementComponentProps<HeadlineProperties>): JSX.Element {
  if (richTextProperty.text === undefined) return <></>;
  return (
    <div
      {...props}
      className={`${className} headline headline-style-${headlineStyle.style} rte-${rteAlignment.style}`}
      style={{
        width: dimension && dimension.width ? dimension.width : "",
        height: dimension && dimension.height ? dimension.height : "",
      }}
    >
      {ReactHtmlParser(richTextProperty.text)}
    </div>
  );
}

export const headlineElementKey = "headline";

elementComponentRegistry.register(headlineElementKey, Headline);
