import { ContactMailOutlined } from "@material-ui/icons";
import React from "react";
import { PropertyRenderer } from "../../PropertyRenderer";

import { ElementType, ElementPropertiesComponent } from "../../types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import {
  contactElementKey,
  ContactProperties,
} from "common/elements/sections/Contact";
import { PropertiesOf } from "editor/elements/types";
import { ContactStyleProperty } from "editor/properties/ContactStyleProperty/ContactStyleProperty";
import { BackgroundColorProperty } from "editor/properties/BackgroundColorProperty/BackgroundColorProperty";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./Contact.module.scss";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { useTranslation } from "react-i18next";

export class ContactElement {
  static readonly elementKey = contactElementKey;
  static readonly label = "element_contact_label";
  static readonly icon = ContactMailOutlined;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_contact_description";
  static readonly elementPreviewUrl =
    "/img/add-contact-element-preview-default.svg";
  static readonly allowedChildren = [
    "section-header",
    "form-container",
    "paragraph",
    "lead",
    "heading",
  ];
  static readonly isResizeable = true;
  static readonly resizeDirections = ["e", "w"];
  static readonly resizeGuides = [1024, 1280, 1366, 1440, 1600, 0];
  static readonly childDragDirection = "horizontal";
  static readonly isReusable = true;
  static readonly dialogSize = "large";

  static readonly properties: PropertiesOf<ContactProperties> = {
    contactStyle: ContactStyleProperty,
    backgroundColorProperty: BackgroundColorProperty,
    backgroundImageProperty: BackgroundImageProperty,
  };

  static renderProperties(element: ElementType) {
    return <ContactPropertiesComponent element={element} />;
  }

  static create(properties: {
    contactStyle: string;
    backgroundColorProperty: string;
    backgroundImageProperty: BackgroundImageProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const ContactPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof ContactElement.properties>(
    ContactElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="alignment">
        <Tab eventKey="alignment" title={t("dialog_44")}>
          {renderer.render("contactStyle")}
        </Tab>
        <Tab eventKey="background-color" title={t("dialog_2")}>
          {renderer.render("backgroundColorProperty")}
        </Tab>
        <Tab eventKey="background" title={t("dialog_3")}>
          {renderer.render("backgroundImageProperty")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(ContactElement);
