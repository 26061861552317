import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import { ReactComponent as AlignParent } from "../../../img/AlignParent.svg";
import { ReactComponent as AlignRight } from "../../../img/AlignRight.svg";
import { ReactComponent as AlignCenter } from "../../../img/AlignCenter.svg";
import { ReactComponent as AlignLeft } from "../../../img/AlignLeft.svg";

import { Tabs, Tab } from "react-bootstrap";

import { Formik, Form } from "formik";

import styles from "./ArticleContentStyleProperty.module.scss";
import { useTranslation } from "react-i18next";

export class ArticleContentStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <ArticleContentStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new ArticleContentStyleProperty(value);
  }
}

const ArticleContentStylePropertyComponent: PropertyComponent<ArticleContentStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const radios = [
    { name: t("dialog_8"), value: "parent", icon: <AlignParent /> },
    { name: t("dialog_78"), value: "1", icon: <AlignLeft /> },
    { name: t("dialog_79"), value: "2", icon: <AlignCenter /> },
    { name: t("dialog_80"), value: "3", icon: <AlignRight /> },
  ];

  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <Form className="form">
        <div className="form__group">
          <Tabs id="nav-properties" defaultActiveKey="alignment">
            <Tab
              eventKey="alignment"
              title={t("dialog_44")}
              tabClassName="single-tab"
            >
              <div className="medium-padded-container">
                <ButtonGroup
                  className={`mb-2 navbar-style-group ${styles["button-group"]}`}
                >
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      type="radio"
                      variant="primary"
                      name="style"
                      value={radio.value}
                      checked={style === radio.value}
                      className="button-radio-hidden"
                      onClick={(e: any) => handleStyleChange(e.target.value)}
                    >
                      {radio.icon}
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Form>
    </Formik>
  );
};
