import React, { Dispatch, SetStateAction } from "react";
import { Form, Card } from "react-bootstrap";
import { Button } from "editor/components/base/Button/Button";
import * as yup from "yup";

import styles from "./Auth.module.scss";
import { Formik } from "formik";
import { ArrowBackRounded } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

let schema = yup.object({
  username: yup.string(),
  password: yup.string(),
  passwordConfirmation: yup.string(),
});

export type PasswordResetFormValues = yup.InferType<typeof schema>;

interface IProps {
  resetPasswordSubmit: (values: PasswordResetFormValues) => void;
  isPasswordResetSuccess: boolean | undefined;
  setResetToken: Dispatch<SetStateAction<string | undefined>>;
  setIsPasswordResetRequested: Dispatch<SetStateAction<boolean>>;
}

export const PasswordResetForm: React.FunctionComponent<IProps> = ({
  resetPasswordSubmit,
  isPasswordResetSuccess,
  setResetToken,
  setIsPasswordResetRequested,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  schema = yup.object({
    username: yup
      .string()
      .required(t("login_page_username_error1"))
      .email(t("login_page_username_error2")),
    password: yup
      .string()
      .required(t("login_page_username_password1"))
      .min(8, t("login_page_username_password2"))
      .matches(/[a-z]/, t("login_page_username_password3"))
      .matches(/[A-Z]/, t("login_page_username_password4")),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password")], t("login_page_username_password5"))
      .required(t("login_page_username_password6")),
  });

  return isPasswordResetSuccess === undefined ? (
    <Formik
      validationSchema={schema}
      onSubmit={resetPasswordSubmit}
      initialValues={{
        username: "",
        password: "",
        passwordConfirmation: "",
      }}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        setErrors,
      }) => (
        <Card className={styles["auth__card"]}>
          <div className={styles["auth__login"]}>
            <h3>{t("login_page_password_reset_header")}</h3>
            <div
              className={styles["auth__forms_container"]}
              style={{ marginBottom: "24px" }}
            >
              <Form.Group controlId="username">
                <Form.Label>
                  {t("login_page_password_reset_username")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder={t("login_page_password_reset_username2")}
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.username && !!errors.username}
                  autoComplete="username"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>{t("login_page_password")}</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder={t("login_page_password")}
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                  autoComplete="new-password"
                  onBlur={handleBlur}
                />
                <Form.Text className="text-muted">
                  <ul>
                    <li>{t("login_page_password1")}</li>
                    <li>{t("login_page_password2")}</li>
                    <li>{t("login_page_password3")}</li>
                  </ul>
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password-confirmation">
                <Form.Label>{t("login_page_repeat_password")}</Form.Label>
                <Form.Control
                  type="password"
                  name="passwordConfirmation"
                  placeholder={t("login_page_repeat_password")}
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  isInvalid={
                    touched.passwordConfirmation &&
                    !!errors.passwordConfirmation
                  }
                  onBlur={handleBlur}
                  autoComplete="new-password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordConfirmation}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <Button
              // label={isSubmitting ? "Requesting..." : "Request Reset"}
              label={t("login_page_repeat_password2")}
              variant="primary"
              size="large"
              style={{
                alignSelf: "stretch",
                width: "100%",
                textAlign: "center",
                justifyContent: "center",
                marginTop: 20,
                padding: 12,
              }}
              onClick={handleSubmit}
            />
          </div>
        </Card>
      )}
    </Formik>
  ) : (
    <Card className={styles["auth__card"]}>
      <div className={styles["auth__login"]}>
        <h3>{t("login_page_repeat_password")}</h3>
        <div
          style={{
            marginTop: "24px",
            marginBottom: "12px",
            backgroundColor: "#ffca7f4d",
            padding: "18px",
            borderRadius: "18px",
          }}
        >
          {isPasswordResetSuccess === true ||
          isPasswordResetSuccess.toString() === "true" ? (
            <>{t("login_page_reset_message1")}</>
          ) : (
            <>{t("login_page_reset_message2")}</>
          )}
          <div
            onClick={() => {
              setResetToken(undefined);
              setIsPasswordResetRequested(false);
              history.push("/login");
            }}
            style={{
              display: "flex",
              color: "#3185fc",
              cursor: "pointer",
              marginTop: "24px",
              marginBottom: "24px",
            }}
          >
            <ArrowBackRounded /> {t("login_page_back")}
          </div>
        </div>
      </div>
    </Card>
  );
};
