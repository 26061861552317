import { VideoLibraryRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import {
  youtubeEmbedElementKey,
  YoutubeEmbedProperties,
} from "common/elements/components/YoutubeEmbed";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { YoutubeEmbedUrlProperty } from "editor/properties/YoutubeEmbedUrlProperty";
import { DimensionProperty } from "editor/properties";
import styles from "./YoutubeEmbed.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import { useTranslation } from "react-i18next";

export class YoutubeEmbedElement {
  static readonly elementKey = youtubeEmbedElementKey;
  static readonly label = "element_youtube_label";
  static readonly icon = VideoLibraryRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly allowedChildren = [];
  static readonly elementPreviewUrl =
    "/img/add-youtubeembed-element-preview-default.svg";
  static readonly elementDescription = "element_youtube_description";
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<YoutubeEmbedProperties> = {
    youtubeUrl: YoutubeEmbedUrlProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <YoutubeEmbedPropertiesComponent element={element} />;
  }

  static create(properties: {
    youtubeUrl: string;
    dimension?: DimensionProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const YoutubeEmbedPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof YoutubeEmbedElement.properties>(
    YoutubeEmbedElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="youtube">
        <Tab eventKey="youtube" title={t("dialog_43")}>
          {renderer.render("youtubeUrl")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(YoutubeEmbedElement);
