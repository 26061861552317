import { call, put, takeLatest } from "@redux-saga/core/effects";
import produce from "immer";
import { Reducer } from "redux";
import { Page } from "public/models/types";
import {
  startRequest,
  finishRequest,
  finishRequestWithErrors,
} from "api/apiActions";
import { findPageBySlugAsync } from "public/api/page";
import { getResponseJsonAsync } from "editor/api/common";

export type State = {
  page: Page;
};

type PageRequestedAction = {
  type: "PUBLIC_PAGE_REQUESTED";
  websiteSlug: string;
  pageSlug: string;
};

export const pageRequested = (
  websiteSlug: string,
  pageSlug: string
): Action => {
  return {
    type: "PUBLIC_PAGE_REQUESTED",
    websiteSlug,
    pageSlug,
  };
};

type PageRequestSucceededAction = {
  type: "PUBLIC_PAGE_REQUEST_SUCCEEDED";
  page: Page;
};

export const pageRequestSucceeded = (page: Page): Action => {
  return {
    type: "PUBLIC_PAGE_REQUEST_SUCCEEDED",
    page,
  };
};

type PageRequestFailedAction = {
  type: "PUBLIC_PAGE_REQUEST_FAILED";
};

export const pageRequestFailed = (): Action => {
  return {
    type: "PUBLIC_PAGE_REQUEST_FAILED",
  };
};

type Action =
  | PageRequestedAction
  | PageRequestSucceededAction
  | PageRequestFailedAction;

const initialState: State = {
  page: {
    id: 0,
    slug: "",
    websiteSlug: "",
    title: "",
    description: "",
    content: {},
    isIndex: false,
    primaryFont: null,
    secondaryFont: null,
    primaryColor: null,
    secondaryColor: null,
    ternaryColor: null,
    primaryRadius: null,
    secondaryRadius: null,
    primaryShadow: null,
    isMaster: false,
    masterSlug: null,
    template: null,
    pageUniqueId: "",
  },
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case "PUBLIC_PAGE_REQUEST_SUCCEEDED":
      return handlePageRequestSucceeded(state, action);

    default:
      return state;
  }
};

const handlePageRequestSucceeded = (
  state: State,
  action: PageRequestSucceededAction
): State =>
  produce(state, (draft) => {
    draft.page = action.page;
  });

function* loadPage(action: PageRequestedAction) {
  yield put(startRequest());
  try {
    const page: Page = yield call(
      findPageBySlugAsync,
      action.websiteSlug,
      action.pageSlug
    );
    yield put(pageRequestSucceeded(page));
    yield put(finishRequest());
  } catch (e) {
    yield put(pageRequestFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

export function* rootSaga() {
  yield takeLatest("PUBLIC_PAGE_REQUESTED", loadPage);
}
