import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Container,
  Col,
  Form,
  Accordion,
  Card,
  Spinner,
} from "react-bootstrap";
import selectStyles from "./Select.module.scss";
import "./Select.scss";
import { ISelectProps } from "./Select";
import { CheckRounded } from "@material-ui/icons";
import { PlanCategoryEnum } from "./SelectStep0Form";
import PaypalSection from "editor/components/impl/PaypalSection/PaypalSection";
import { useDispatch, useSelector } from "react-redux";
import {
  processPaypalPaymentRequest,
  resetPaymentStatus,
  startDlocalPaymentProcess,
} from "auth/authActions";
import { useTranslation } from "react-i18next";
import {
  getDLocalAnnualPrice,
  getDLocalApiKey,
  getDLocalCountryCode,
  getDLocalCountryIsoLanguageCode,
  getDLocalCurrencyCode,
  getDLocalDocumentFormat,
  getDLocalDocumentFormatMax,
  getDLocalDocumentFormatMin,
  getDLocalDocumentName,
  getDLocalPaymentStateMessage,
  getDLocalPaymentStatus,
  getDLocalPrice,
  getUserCountryCode,
  getUserFirstName,
  getUserId,
  getUserLastName,
  getUsername,
  getPaypalAnnualPrice,
  getPaypalCountryIsoLanguageCode,
  getPaypalCurrencyCode,
  getPaypalPrice,
  getPaypalMonthlyPlanId,
  getPaypalAnnualPlanId,
  getPaymentOptions,
  getPreferredPaymentOption,
} from "auth/selectors";
import * as yup from "yup";
import useScript from "./ExternalHook";
import upgradeSubscriptionStyles from "./UpgradeSubscription.module.scss";
import { isDesktopView } from "./utils-country";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const dlocal: any;

const PROD_HOST = "www.goodweb.app";
const LOCAL_PROXY = "http://localhost:8010/proxy";
const PRODUCTION_DLOCAL = "https://js.dlocal.com/";

export interface ISelectFormValues {
  billingName: string;
  email: string;
  documentNumber: string;
  cardToken: string;
  category: string;
}

let schema = yup.object({
  billingName: yup.string().required("Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().required("Email is required"),
  documentNumber: yup.string().required("is required"),
  cardToken: yup.string().required("Card information is required"),
  category: yup.string().required("Choose a Subscription Plan"),
});

export type Values = yup.InferType<typeof schema>;

export const SelectStep1Form: React.FunctionComponent<ISelectProps> = ({
  handleSaveAndContinue,
  handleStepBack,
  values,
  price,
  annualPrice,
  currency,
  countryCode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const paymentOptions = useSelector(getPaymentOptions);
  const preferredPaymentOption = useSelector(getPreferredPaymentOption);
  const stateCountryCode = useSelector(getUserCountryCode);
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const username = useSelector(getUsername);
  const userId = useSelector(getUserId);

  const paypalAnnualPrice = useSelector(getPaypalAnnualPrice);
  const paypalCountryIsoLanguageCode = useSelector(
    getPaypalCountryIsoLanguageCode
  );
  const paypalCurrencyCode = useSelector(getPaypalCurrencyCode);
  const paypalPrice = useSelector(getPaypalPrice);
  const paypalMonthlyPlanId = useSelector(getPaypalMonthlyPlanId);
  const paypalAnnualPlanId = useSelector(getPaypalAnnualPlanId);
  const [paypalPriceFormatted, setPaypalPriceFormatted] = useState<
    string | undefined
  >();
  const [paypalAnnualPriceFormatted, setPaypalAnnualPriceFormatted] = useState<
    string | undefined
  >();

  const dlocalPrice = useSelector(getDLocalPrice);
  const dlocalAnnualPrice = useSelector(getDLocalAnnualPrice);
  const dlocalCurrency = useSelector(getDLocalCurrencyCode);
  const dlocalCountryCode = useSelector(getDLocalCountryCode);
  const dLocalCountryIsoLanguageCode = useSelector(
    getDLocalCountryIsoLanguageCode
  );
  const dLocalDocumentName = useSelector(getDLocalDocumentName);
  const dLocalDocumentMin = useSelector(getDLocalDocumentFormatMin);
  const dLocalDocumentMax = useSelector(getDLocalDocumentFormatMax);
  const dLocalDocumentFormat = useSelector(getDLocalDocumentFormat);
  const dLocalApiKey = useSelector(getDLocalApiKey);
  const dLocalPaymentStatus = useSelector(getDLocalPaymentStatus);
  const dLocalPaymentStateMessage = useSelector(getDLocalPaymentStateMessage);
  const [dlocalPriceFormatted, setDlocalPriceFormatted] = useState<
    string | undefined
  >();
  const [dlocalAnnualPriceFormatted, setDlocalAnnualPriceFormatted] = useState<
    string | undefined
  >();
  const [dlocalSubmitted, setDlocalSubmitted] = useState<boolean>(false);
  const [dlocalSmartFieldError, setDlocalSmartFieldError] = useState<
    string | undefined
  >(dLocalPaymentStateMessage);
  const [dlocalToken, setDlocalToken] = useState<string>();
  const [card, setCard] = useState<any>();
  const [dlocalInstance, setDlocalInstance] = useState<any>();

  const [isDesktop, setIsDesktop] = useState(true);
  const [isCancelled, setIsCancelled] = useState<boolean | undefined>(
    undefined
  );
  const [isErrorEncountered, setIsErrorEncountered] = useState<
    boolean | undefined
  >(undefined);
  const selectedSubscriptionPlan = values.category;

  schema = yup.object({
    billingName: yup.string().required("Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Not a valid email"),
    documentNumber:
      dLocalDocumentMin && dLocalDocumentMax
        ? yup
            .string()
            .required("is required")
            .min(dLocalDocumentMin, "Invalid entry")
            .max(dLocalDocumentMax, "Invalid entry")
        : yup.string().required("is required"),
    cardToken: yup.string().required("Card information is required"),
    category: yup.string().required(t("upgrade_page_subscription1")),
  });

  const accordionRef2 = useRef<HTMLElement>(null);
  const status = useScript(
    window.location.host === PROD_HOST ? PRODUCTION_DLOCAL : LOCAL_PROXY
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formValues, setFormValues] = useState<ISelectFormValues>({
    billingName: "",
    email: "",
    documentNumber: "",
    cardToken: "",
    category: "",
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, []);

  useEffect(() => {
    if (dLocalPaymentStatus && dLocalPaymentStatus === "SUCCESS") {
      handleSaveAndContinue(values);
    }
  }, [dLocalPaymentStatus, handleSaveAndContinue, values]);

  useEffect(() => {
    if (paymentOptions && preferredPaymentOption) {
      if (
        preferredPaymentOption === "dlocal" &&
        dLocalCountryIsoLanguageCode &&
        dlocalCurrency &&
        dlocalAnnualPrice &&
        dlocalPrice
      ) {
        const annualPriceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalAnnualPrice);

        const priceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalPrice);

        setDlocalPriceFormatted(priceFormatted);
        setDlocalAnnualPriceFormatted(annualPriceFormatted);
      } else {
        if (
          paypalCountryIsoLanguageCode &&
          paypalCurrencyCode &&
          paypalAnnualPrice &&
          paypalPrice
        ) {
          const annualPriceFormatted = new Intl.NumberFormat(
            paypalCountryIsoLanguageCode,
            {
              currency: paypalCurrencyCode,
              style: "currency",
            }
          ).format(paypalAnnualPrice);

          const priceFormatted = new Intl.NumberFormat(
            paypalCountryIsoLanguageCode,
            {
              currency: paypalCurrencyCode,
              style: "currency",
            }
          ).format(paypalPrice);

          setPaypalPriceFormatted(priceFormatted);
          setPaypalAnnualPriceFormatted(annualPriceFormatted);
        }
      }

      if (
        preferredPaymentOption === "dlocal" &&
        dLocalCountryIsoLanguageCode &&
        dlocalCurrency &&
        dlocalAnnualPrice &&
        dlocalPrice
      ) {
        const annualPriceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalAnnualPrice);

        const priceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalPrice);

        setDlocalPriceFormatted(priceFormatted);
        setDlocalAnnualPriceFormatted(annualPriceFormatted);
      }

      if (
        paypalCountryIsoLanguageCode &&
        paypalCurrencyCode &&
        paypalAnnualPrice &&
        paypalPrice
      ) {
        const annualPriceFormatted = new Intl.NumberFormat(
          paypalCountryIsoLanguageCode,
          {
            currency: paypalCurrencyCode,
            style: "currency",
          }
        ).format(paypalAnnualPrice);

        const priceFormatted = new Intl.NumberFormat(
          paypalCountryIsoLanguageCode,
          {
            currency: paypalCurrencyCode,
            style: "currency",
          }
        ).format(paypalPrice);

        setPaypalPriceFormatted(priceFormatted);
        setPaypalAnnualPriceFormatted(annualPriceFormatted);
      }

      if (
        dLocalCountryIsoLanguageCode &&
        dlocalCurrency &&
        dlocalAnnualPrice &&
        dlocalPrice
      ) {
        const annualPriceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalAnnualPrice);

        const priceFormatted = new Intl.NumberFormat(
          dLocalCountryIsoLanguageCode,
          {
            currency: dlocalCurrency,
            style: "currency",
          }
        ).format(dlocalPrice);

        setDlocalPriceFormatted(priceFormatted);
        setDlocalAnnualPriceFormatted(annualPriceFormatted);
      }
    }
  }, [
    paymentOptions,
    preferredPaymentOption,
    dLocalCountryIsoLanguageCode,
    dlocalCurrency,
    dlocalAnnualPrice,
    dlocalPrice,
    paypalCountryIsoLanguageCode,
    paypalCurrencyCode,
    paypalAnnualPrice,
    paypalPrice,
  ]);

  useEffect(() => {
    if (
      typeof dlocal !== "undefined" &&
      dLocalCountryIsoLanguageCode &&
      dlocalCountryCode &&
      !dlocalInstance
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const dlocalInstance = dlocal(dLocalApiKey);
      const fields = dlocalInstance.fields({
        country: dlocalCountryCode,
        locale: dLocalCountryIsoLanguageCode.substring(0, 2),
        fonts: [
          {
            cssSrc: "https://rsms.me/inter/inter-ui.css",
          },
        ],
      });
      const cardField = fields.create("card", {
        style: {
          base: {
            fontSize: "14px",
            fontFamily: "Lexend, sans-serif",
            lineHeight: "18px",
            fontSmoothing: "antialiased",
            fontWeight: "500",
            color: "#32325d",
            "::placeholder": {
              color: "#aab7c4",
            },
            iconColor: "#adbfd3",
          },
        },
      });
      cardField.mount(document.getElementById("smartfields-container"));
      setDlocalInstance(dlocalInstance);
      setCard(cardField);
    }
  }, [
    status,
    dlocalCountryCode,
    dLocalApiKey,
    dLocalCountryIsoLanguageCode,
    userId,
    dlocalInstance,
    dispatch,
  ]);

  const handleOnApprove = (data: any) => {
    let orderBody = data;
    let orderId = data.orderID;
    let orderStatus = "APPROVED";
    let subscriptionId = data.subscriptionID;
    let finalPrice =
      values.category === PlanCategoryEnum.PAID ? price : annualPrice;
    if (
      userId &&
      finalPrice &&
      currency &&
      countryCode &&
      username &&
      values.category
    ) {
      dispatch(
        processPaypalPaymentRequest(
          userId,
          finalPrice,
          currency,
          countryCode,
          username,
          orderBody,
          orderId,
          orderStatus,
          subscriptionId,
          values.category,
          handleOnApproveResponse
        )
      );
    }
  };

  const handleOnApproveResponse = (status: boolean) => {
    if (status) {
      setIsSubmitDisabled(false);
      handleSaveAndContinue(values);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleOnError = (err: any) => {
    setIsErrorEncountered(true);
  };

  const handleOnCancel = (data: any) => {
    setIsCancelled(true);
  };

  const onSubmitDlocal = (values: Values) => {
    const updatedValues = { ...values, ...values };
    const priceToBill =
      selectedSubscriptionPlan === PlanCategoryEnum.PAID
        ? dlocalPrice
        : dlocalAnnualPrice;
    setFormValues(updatedValues);

    if (
      userId &&
      username &&
      stateCountryCode &&
      priceToBill &&
      dlocalCurrency &&
      dlocalCountryCode &&
      selectedSubscriptionPlan
    ) {
      dispatch(
        startDlocalPaymentProcess(
          userId,
          priceToBill,
          dlocalCurrency,
          dlocalCountryCode,
          updatedValues.billingName,
          updatedValues.email,
          updatedValues.documentNumber,
          updatedValues.cardToken,
          selectedSubscriptionPlan
        )
      );
    }
  };

  const displayDlocal = (): JSX.Element => {
    return (
      <div>
        <div className={upgradeSubscriptionStyles["payment-option-summary"]}>
          <div>Pay in {dlocalCurrency}</div>
          <div>
            {selectedSubscriptionPlan === PlanCategoryEnum.PAID_ANNUAL
              ? dlocalAnnualPriceFormatted
              : dlocalPriceFormatted}
          </div>
        </div>
        <div className={`${selectStyles["upgrade-bill-info-form"]}`}>
          <Formik
            validationSchema={schema}
            onSubmit={onSubmitDlocal}
            initialValues={{
              billingName: `${firstName} ${lastName}`,
              lastName: lastName!,
              email: username!,
              documentNumber: "",
              cardToken: "",
              category: "",
            }}
          >
            {({
              values,
              touched,
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className={`${selectStyles["form-container"]}`}>
                  <Form.Group>
                    <div className={`${selectStyles["form-input"]}`}>
                      {t("subscription_2")}
                      <Form.Control
                        type="text"
                        name="billingName"
                        value={values.billingName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isInvalid={touched.billingName && !!errors.billingName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.billingName}
                      </Form.Control.Feedback>
                    </div>
                    <div className={`${selectStyles["form-input"]}`}>
                      {t("subscription_3")}
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </div>
                    <div className={`${selectStyles["form-input"]}`}>
                      {dLocalDocumentName}
                      <Form.Control
                        placeholder={dLocalDocumentFormat}
                        type="text"
                        name="documentNumber"
                        value={values.documentNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.documentNumber && !!errors.documentNumber
                        }
                        autoFocus
                        id="doc"
                      />
                      <Form.Control.Feedback type="invalid">
                        {dLocalDocumentName + " " + errors.documentNumber}
                      </Form.Control.Feedback>
                    </div>
                    <div
                      className={`${selectStyles["form-input"]}`}
                      onClick={() => {
                        resetPaymentStatus();
                        setDlocalSmartFieldError(undefined);
                      }}
                    >
                      {t("subscription_4")}
                      <div id="smartfields-container"></div>
                      <Form.Control
                        type="hidden"
                        name="card"
                        value={dlocalToken}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        isInvalid={touched.cardToken && !!errors.cardToken}
                      />
                      <div className={`${selectStyles["invalid"]}`}>
                        {dlocalSmartFieldError}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: "24px",
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: 32,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        type="button"
                        onClick={() => {
                          setDlocalSubmitted(true);
                          dlocalInstance
                            .createToken(card, {
                              name: values.billingName,
                            })
                            .then(function (result: any) {
                              setDlocalToken(result.token);
                              values.cardToken = result.token;
                              setDlocalSmartFieldError(undefined);
                              onSubmitDlocal(values);
                              handleSubmit();
                            })
                            .catch((result: any) => {
                              if (result.error) {
                                setDlocalSmartFieldError(result.error.message);
                                setDlocalSubmitted(false);
                              }
                            });
                        }}
                        disabled={
                          (values.billingName &&
                            values.email &&
                            values.documentNumber &&
                            errors.billingName === undefined &&
                            errors.email === undefined &&
                            errors.documentNumber === undefined) ||
                          !dlocalSubmitted
                            ? false
                            : true
                        }
                      >
                        {dlocalSubmitted ? (
                          <>
                            <Spinner
                              size="sm"
                              animation="border"
                              variant="light"
                              style={{ marginRight: "8px" }}
                            />{" "}
                            {t("subscription_5")}
                          </>
                        ) : (
                          <>{t("subscription_6")}</>
                        )}
                      </Button>
                    </div>
                    <div className={`${selectStyles["secure-note"]}`}>
                      <span role="img" aria-label="Secure Icon">
                        🔒
                      </span>
                      {t("subscription_7")}
                      <br />
                    </div>
                  </Form.Group>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  const displayPaypal = (): JSX.Element => {
    return (
      <div>
        <div className={upgradeSubscriptionStyles["payment-option-summary"]}>
          <div>Pay in {paypalCurrencyCode}</div>
          <div>
            {selectedSubscriptionPlan === PlanCategoryEnum.PAID_ANNUAL
              ? paypalAnnualPriceFormatted
              : paypalPriceFormatted}
          </div>
        </div>
        <PaypalSection
          handleOnApprove={handleOnApprove}
          handleOnCancel={handleOnCancel}
          handleOnError={handleOnError}
          annualPlanId={
            selectedSubscriptionPlan === PlanCategoryEnum.PAID_ANNUAL
              ? paypalAnnualPlanId
              : undefined
          }
          monthlyPlanId={
            selectedSubscriptionPlan === PlanCategoryEnum.PAID
              ? paypalMonthlyPlanId
              : undefined
          }
        />
        <div
          style={{
            color: "red",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          {isErrorEncountered || isCancelled ? t("upgrade_page_error") : ""}
        </div>
      </div>
    );
  };

  const displayForm = (): JSX.Element => {
    return (
      <Modal size={"lg"} centered show={true} className="urux-setup">
        <Modal.Header
          className={`${upgradeSubscriptionStyles["modal-header"]}`}
        >
          <Modal.Title>
            <h6>{t("upgrade_page_payment")}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: isDesktop ? "auto" : "4px" }}
          className={`${upgradeSubscriptionStyles["modal-body"]}`}
        >
          <Container
            className={`${upgradeSubscriptionStyles["upgrade-container"]}`}
          >
            <Col style={{ flex: "0 0 290px" }}>
              <div className={`${selectStyles["upgrade-bill-info"]}`}>
                <div className={`${selectStyles["select-dialog"]}`}>
                  <span>
                    {t("upgrade_page_subscription_feature_paragraph1")}
                  </span>
                  <span>
                    {t("upgrade_page_subscription_feature_paragraph2")}
                  </span>
                  <ul
                    className={selectStyles["list"]}
                    style={{ marginTop: "12px", width: "100%" }}
                  >
                    <li>
                      <CheckRounded /> {t("upgrade_page_subscription_feature1")}
                    </li>
                    <li>
                      <CheckRounded /> {t("upgrade_page_subscription_feature2")}
                    </li>
                    <li>
                      <CheckRounded /> {t("upgrade_page_subscription_feature3")}
                    </li>
                    <li>
                      <CheckRounded /> {t("upgrade_page_subscription_feature4")}
                    </li>
                    <li>
                      <CheckRounded /> {t("upgrade_page_subscription_feature5")}
                    </li>
                    <li>
                      <CheckRounded /> {t("upgrade_page_subscription_feature6")}
                    </li>
                    <li>
                      <CheckRounded /> {t("upgrade_page_subscription_feature7")}
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col className={`${upgradeSubscriptionStyles["col"]}`}>
              <Accordion
                defaultActiveKey="1"
                className={`${upgradeSubscriptionStyles["accordion"]}`}
              >
                <Card className={`${upgradeSubscriptionStyles["card"]}`}>
                  <Card.Header
                    className={`${upgradeSubscriptionStyles["card-header"]}`}
                  >
                    <Accordion.Toggle
                      ref={accordionRef2}
                      as={Button}
                      variant="link"
                      eventKey="1"
                      className={`${selectStyles["upgrade-selector-header"]}`}
                    >
                      {t("subscription_11")}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body
                      className={`${upgradeSubscriptionStyles["card-body"]}`}
                    >
                      {preferredPaymentOption === "dlocal" ? (
                        displayDlocal()
                      ) : (
                        <></>
                      )}
                      {preferredPaymentOption === "paypal" ? (
                        displayPaypal()
                      ) : (
                        <></>
                      )}
                      {paymentOptions && paymentOptions?.length > 1 ? (
                        <>
                          <div
                            style={{
                              color: "#5a7daf",
                              display: "flex",
                              alignItems: "center",
                              marginTop: "24px",
                            }}
                          >
                            <div
                              style={{
                                borderTop: "1px solid #E2E7EE",
                                width: "100%",
                                marginLeft: 12,
                                marginRight: 12,
                              }}
                            ></div>
                            OR
                            <div
                              style={{
                                borderTop: "1px solid #E2E7EE",
                                width: "100%",
                                marginLeft: 12,
                                marginRight: 12,
                              }}
                            ></div>
                          </div>
                          {preferredPaymentOption === "dlocal" ? (
                            displayPaypal()
                          ) : (
                            <></>
                          )}
                          {preferredPaymentOption === "paypal" ? (
                            displayDlocal()
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Container>
        </Modal.Body>
        <Modal.Footer
          style={{ justifyContent: isDesktop ? "space-between" : "center" }}
        >
          <Button
            tabIndex={-1}
            type="button"
            onClick={handleStepBack}
            style={{
              borderRadius: 32,
              color: "#007bff",
              backgroundColor: "white",
              borderColor: "#007bff",
            }}
          >
            {t("login_page_back")}
          </Button>
          <Button
            style={{ borderRadius: 32 }}
            type="button"
            onClick={() => {}}
            disabled={isSubmitDisabled}
          >
            {t("continue")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return displayForm();
};
