import { ArtTrackOutlined } from "@material-ui/icons";
import React from "react";
import { PropertyRenderer } from "../../PropertyRenderer";

import { ElementType, ElementPropertiesComponent } from "../../types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import {
  articlesElementKey,
  ArticlesProperties,
} from "common/elements/sections/Articles";
import { PropertiesOf } from "editor/elements/types";
import { ArticlesSectionStyleProperty } from "editor/properties/ArticlesSectionStyleProperty/ArticlesSectionStyleProperty";
import WidthProperty from "editor/properties/WidthProperty";
import { BackgroundColorProperty } from "editor/properties/BackgroundColorProperty/BackgroundColorProperty";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./Articles.module.scss";
import { ArticlesSectionAlignmentProperty } from "editor/properties";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { useTranslation } from "react-i18next";
export class ArticlesElement {
  static readonly elementKey = articlesElementKey;
  static readonly label = "element_articles_label";
  static readonly icon = ArtTrackOutlined;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_articles_description";
  static readonly elementPreviewUrl =
    "/img/add-article-element-preview-default.svg";
  static readonly allowedChildren = [];
  static readonly isResizeable = true;
  static readonly resizeDirections = ["e", "w"];
  static readonly resizeGuides = [1024, 1280, 1366, 1440, 1600, 0];
  static readonly childDragDirection = "vertical";
  static readonly dialogSize = "large";
  static readonly isReusable = true;

  static readonly properties: PropertiesOf<ArticlesProperties> = {
    articlesStyle: ArticlesSectionStyleProperty,
    width: WidthProperty,
    backgroundColorProperty: BackgroundColorProperty,
    acrticlesAlignment: ArticlesSectionAlignmentProperty,
    backgroundImageProperty: BackgroundImageProperty,
  };

  static renderProperties(element: ElementType) {
    return <ArticlesPropertiesComponent element={element} />;
  }

  static create(properties: {
    articlesStyle: string;
    backgroundColorProperty: string;
    acrticlesAlignment: string;
    backgroundImageProperty: BackgroundImageProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const ArticlesPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof ArticlesElement.properties>(
    ArticlesElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="alignment">
        <Tab eventKey="alignment" title={t("dialog_44")}>
          {renderer.render("acrticlesAlignment")}
        </Tab>
        <Tab eventKey="style" title={t("dialog_1")}>
          {renderer.render("articlesStyle")}
        </Tab>
        <Tab eventKey="background-color" title={t("dialog_2")}>
          {renderer.render("backgroundColorProperty")}
        </Tab>
        <Tab eventKey="background" title={t("dialog_3")}>
          {renderer.render("backgroundImageProperty")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(ArticlesElement);
