import { MoreHorizOutlined } from "@material-ui/icons";
import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { SocialLinkCollectionProperty } from "editor/properties/SocialLinkCollectionProperty/SocialLinkCollectionProperty";
import { PropertyRenderer } from "../../PropertyRenderer";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { FooterSocialNavProperties } from "common/elements/components/FooterSocialNav";
import { FooterSocialLinkStyleProperty } from "editor/properties/FooterSocialLinkStyleProperty/FooterSocialLinkStyleProperty";
import styles from "./FooterSocialNav.module.scss";
import { useTranslation } from "react-i18next";

export class FooterSocialNavElement {
  static readonly elementKey = "footer-social-nav";
  static readonly label = "element_footersocialnav_label";
  static readonly icon = MoreHorizOutlined;
  static readonly allowedToolbarPositions = [];
  static readonly elementDescription = "element_footersocialnav_description";
  static readonly dialogPositionStatic = false;
  static readonly isDeletable = true;

  static readonly properties: PropertiesOf<FooterSocialNavProperties> = {
    socialLinks: SocialLinkCollectionProperty,
    style: FooterSocialLinkStyleProperty,
    isProduction: Boolean,
  };

  static renderProperties(element: ElementType) {
    return <FooterSocialNavPropertiesComponent element={element} />;
  }

  static create(properties: {
    socialLinks: SocialLinkProperty[];
    position: string;
    style: string;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const FooterSocialNavPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<
    typeof FooterSocialNavElement.properties
  >(FooterSocialNavElement.properties, element);
  const { t } = useTranslation();
  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="links">
        <Tab eventKey="links" title={t("dialog_21")}>
          {renderer.render("socialLinks")}
        </Tab>
        <Tab eventKey="appearance" title={t("dialog_39")}>
          {renderer.render("style")}
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(FooterSocialNavElement);
