import { ViewModuleRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { SectionHeaderStyleProperty } from "editor/properties/SectionHeaderStyleProperty/SectionHeaderStyleProperty";
import {
  sectionHeaderKey,
  SectionHeaderProperties,
} from "common/elements/components/SectionHeader";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { DimensionProperty } from "editor/properties";

export class SectionHeaderElement {
  static readonly elementKey = sectionHeaderKey;
  static readonly label = "element_sectionheader_label";
  static readonly icon = ViewModuleRounded;
  static readonly allowedChildren = [
    "heading",
    "lead",
    "image",
    "paragraph",
    "button",
    "google-maps",
  ];
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_sectionheader_description";
  static readonly elementPreviewUrl = "./img/default-image.svg";
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<SectionHeaderProperties> = {
    sectionHeaderStyle: SectionHeaderStyleProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <SectionHeaderPropertiesComponent element={element} />;
  }

  static create(properties: {
    sectionHeaderStyle: string;
    dimension?: DimensionProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const SectionHeaderPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof SectionHeaderElement.properties>(
    SectionHeaderElement.properties,
    element
  );

  return <>{renderer.render("sectionHeaderStyle")}</>;
};

elementDefinitionRegistry.register(SectionHeaderElement);
