import { Reducer } from "redux";

import {
  Action,
  ShowAction,
  HideAction,
  StartAction,
  EndAction,
  SetMousePositionAction,
  RESIZE_TOOLBAR_SHOW,
  RESIZE_TOOLBAR_HIDE,
  RESIZE_TOOLBAR_START,
  RESIZE_TOOLBAR_START_RESIZE,
  RESIZE_TOOLBAR_END,
  RESIZE_TOOLBAR_SET_MOUSE_POSITION,
} from "./resizeToolbarActions";
import { PositionString } from "../../../types";

export type State = {
  elementId: string;
  state: "hidden" | "shown" | "resizing";
  position: PositionString;
  x0: number;
  y0: number;
  x: number;
  y: number;
};

const initialState: State = {
  elementId: "",
  state: "hidden",
  position: "left",
  x0: 0,
  y0: 0,
  x: 0,
  y: 0,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case RESIZE_TOOLBAR_SHOW:
      return show(state, action);

    case RESIZE_TOOLBAR_HIDE:
      return hide(state, action);

    case RESIZE_TOOLBAR_START:
      return start(state, action);

    case RESIZE_TOOLBAR_START_RESIZE:
      return startResize(state);

    case RESIZE_TOOLBAR_END:
      return end(state, action);

    case RESIZE_TOOLBAR_SET_MOUSE_POSITION:
      return setMousePosition(state, action);

    default:
      return state;
  }
};

const show = (state: State, action: ShowAction): State => {
  return {
    ...state,
    elementId: action.elementId,
    state: "shown",
  };
};

const hide = (state: State, action: HideAction): State => {
  return { ...state, state: "hidden" };
};

const start = (state: State, action: StartAction): State => {
  return {
    ...state,
    state: "resizing",
    position: action.position,
    x0: action.x,
    y0: action.y,
  };
};

const startResize = (state: State): State => {
  return {
    ...state,
    state: "resizing",
  };
};

const end = (state: State, action: EndAction): State => {
  if (state.state !== "resizing") {
    return state;
  }
  return { ...state, state: "shown" };
};

const setMousePosition = (
  state: State,
  action: SetMousePositionAction
): State => {
  return { ...state, x: action.x, y: action.y };
};
