import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ColorResult, ChromePicker } from "react-color";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";

import { Formik, Form } from "formik";

import styles from "./ColorProperty.module.scss";
import "./ColorProperty.scss";

import { getPrimaryColor } from "editor/selectors";
import { Container } from "react-bootstrap";
import ColorSwatch from "editor/components/impl/ColorSwatch/ColorSwatch";

export class ColorProperty implements IProperty {
  constructor(readonly color: string, readonly fontColor: string) { }

  render(elementId: string, propertyKey: string) {
    return (
      <ColorPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): ColorProperty {
    if (value) return new ColorProperty(value.color, value.fontColor);
    return new ColorProperty("#01010", "#f1f1f1");
  }
}

const ColorPropertyComponent: PropertyComponent<ColorProperty> = ({
  elementId,
  propertyKey,
  color,
}) => {
  var tinycolor = require("tinycolor2");
  const dispatch = useDispatch();
  const statePrimaryColor = useSelector(getPrimaryColor);
  const [selectedColor, setSelectedColor] = useState(color);

  useEffect(() => {
    if (color !== "#010101" && statePrimaryColor) {
      setSelectedColor(statePrimaryColor);
    }
  }, [color, statePrimaryColor]);

  const handleChangeComplete = (
    color: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (color) {
      let fontColor = "#fff";
      if (tinycolor.readability(fontColor, color) < 4.5) {
        fontColor = "#000";
      }
      dispatch(
        propertySet(elementId, propertyKey, new ColorProperty(color, fontColor))
      );
      setSelectedColor(color);
    }
  };

  return (
    <div className={`${styles["palette-container"]}`}>
      <Container
        className={`${styles["swatch-container"]}`}
        style={{ paddingBottom: 20, paddingTop: 20 }}
      >
        <Formik initialValues={{}} onSubmit={async (values) => { }}>
          <Form className="form">
            <div className="form__group">
              <div className="medium-padded-container">
                <div className="pickers-container">
                  <div className="picker-container">
                    <div>
                      <ColorSwatch
                        handleColorClick={handleChangeComplete}
                        isShadowed={false}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ChromePicker
                        disableAlpha={true}
                        color={selectedColor}
                        onChange={(
                          color: ColorResult,
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleChangeComplete(color.hex, event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Container>
    </div>
  );
};
