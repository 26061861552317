import {
  AddRounded,
  BackupRounded,
  DeleteForeverRounded,
  DeleteRounded,
  ArrowBackRounded,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { IProperty, PropertyComponent } from "../types";
import { Button } from "editor/components/base/Button/Button";

import styles from "./GalleryImagesProperty.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { propertySet } from "editor/states/layout";
import { ImageProperty } from "../ImageProperty/ImageProperty";

import { useDropzone } from "react-dropzone";
import { uploadFile } from "admin/S3Client";
import { getUserWebsiteSlug } from "auth/selectors";
import { getS3config } from "editor/states/selectors";
import { getS3Configuration } from "editor/states/website";
import { ProgressBar } from "react-bootstrap";
import Resizer from "react-image-file-resizer";

import "./GalleryImagesProperty.scss";
import { useTranslation } from "react-i18next";

export class GalleryImagesProperty implements IProperty {
  constructor(readonly galleryImages: ImageProperty[]) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <GalleryImagesPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): GalleryImagesProperty {
    return new GalleryImagesProperty(value);
  }
}

const GalleryImagesPropertyComponent: PropertyComponent<GalleryImagesProperty> = ({
  elementId,
  propertyKey,
  galleryImages,
}) => {
  var uuid = require("uuid");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const s3ConfigState = useSelector(getS3config);

  const [images, setImages] = useState(galleryImages);
  const [hoveredImageId, setHoveredImageId] = useState<string | null>();
  const [imageIdsToDelete, setImageIdsToDelete] = useState<string[]>([]);
  const [displayDropZone, setDisplayDropZone] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState<File[] | undefined>();

  useEffect(() => {
    dispatch(getS3Configuration());
  }, [dispatch]);

  useEffect(() => {
    if (imagesToUpload) {
      uploadImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesToUpload]);

  const onDrop = useCallback((acceptedFiles: any[]) => {
    let imagesToUploadTemp: File[] = [];
    let counter = 1;

    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("File reading was aborted");
      reader.onerror = () => console.log("File reading has failed");
      reader.onload = () => {
        // Delete to review from here to
        let img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.src = objectUrl;
        // here
        imagesToUploadTemp.push(file);
        if (counter === acceptedFiles.length) {
          setImagesToUpload(imagesToUploadTemp);
          setDisplayDropZone(false);
          setDisplayLoader(true);
        }
        counter++;
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        300,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const dataURLtoFile = (dataurl: any, filename: string): File => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const uploadImages = () => {
    if (websiteSlug && imagesToUpload) {
      let tempImagesToUpdate: ImageProperty[] = [];
      let counter = 1;
      imagesToUpload.forEach((imageToUpload) => {
        let filename = uuid.v4();
        let promise = uploadFile(
          imageToUpload,
          websiteSlug,
          filename,
          s3ConfigState
        );
        promise.then(async (location) => {
          if (location) {
            // Resize file
            let img = new Image();
            var objectUrl = URL.createObjectURL(imageToUpload);
            img.src = objectUrl;

            const thumbnail = await resizeFile(imageToUpload);

            // Upload resized file
            const thumbnailFile = dataURLtoFile(thumbnail, filename + "_t");

            let promiseThumbnail = uploadFile(
              thumbnailFile,
              websiteSlug,
              filename + "_t",
              s3ConfigState
            );

            promiseThumbnail.then((thumbnailLocation) => {
              if (thumbnailLocation) {
                tempImagesToUpdate.push(
                  new ImageProperty(
                    "",
                    imageToUpload.name,
                    location,
                    { width: img.width, height: img.height },
                    thumbnailLocation,
                    filename
                  )
                );

                if (counter === imagesToUpload.length) {
                  let combinedImages = images.concat(tempImagesToUpdate);
                  dispatch(
                    propertySet(
                      elementId,
                      propertyKey,
                      assignImageOrder(combinedImages)
                    )
                  );
                  setImages(combinedImages);
                  setDisplayLoader(false);
                  setImagesToUpload(undefined);
                }
                counter++;
              }
            });
          }
        });
      });
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 10,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    minSize: 0,
    maxSize: 2097152, // 2MB max
    onDrop,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div className={styles["drop-zone-label-errors"]}>
        {errors.map((e) => (
          <span key={e.code}>{e.message}</span>
        ))}
      </div>
    );
  });

  const handleSetImageIdsToDelete = (idToDelete?: string) => {
    if (idToDelete) {
      let tempImagesToDelete = imageIdsToDelete;
      if (!tempImagesToDelete?.includes(idToDelete)) {
        tempImagesToDelete.push(idToDelete);
        setImageIdsToDelete(tempImagesToDelete);
      } else {
        setImageIdsToDelete(imageIdsToDelete.filter((e) => e !== idToDelete));
      }
    }
  };

  const handleDeleteConfirm = () => {
    if (galleryImages && imageIdsToDelete && imageIdsToDelete.length > 0) {
      let tempImageIdsToDelete = imageIdsToDelete;
      let tempImagesToUpdate = images;

      tempImageIdsToDelete.forEach((tempImageIdToDelete) => {
        tempImagesToUpdate = tempImagesToUpdate.filter(
          (image) => image.id !== tempImageIdToDelete
        );
      });

      setImageIdsToDelete([]);
      setImages(tempImagesToUpdate);
      dispatch(
        propertySet(
          elementId,
          propertyKey,
          assignImageOrder(tempImagesToUpdate)
        )
      );
    }
  };

  const assignImageOrder = (
    galleryImages: ImageProperty[]
  ): ImageProperty[] => {
    let galleryImagesTemp: ImageProperty[] = [];
    let counter = 1;
    galleryImages.forEach((galleryImage) => {
      galleryImagesTemp.push(
        new ImageProperty(
          counter + "",
          galleryImage.imageText,
          galleryImage.imgUrl,
          {
            width: galleryImage.dimension.width,
            height: galleryImage.dimension.height,
          },
          galleryImage.thumbUrl,
          galleryImage.id
        )
      );
      counter++;
    });
    setImages(galleryImagesTemp);

    return galleryImagesTemp;
  };

  return (
    <div className="medium-padded-container">
      {displayLoader ? (
        <div className={styles["loader"]}>
          <div
            className={`bounce bounce-7`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="goodweb loader 1"
              src="/img/goodweb-bouncing-logo-1.svg"
            />
          </div>
          Uploading Images
          <span
            style={{
              color: "#7a8794",
              fontSize: "14px",
              fontStyle: "italic",
            }}
          >
            (Hold on. This usually takes up to a minute)
          </span>
          <ProgressBar
            animated
            now={60}
            style={{
              width: "300px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {displayDropZone ? (
        <>
          <div {...getRootProps()} className={styles["dropzone"]}>
            <input {...getInputProps()} />
            <div className={styles["drop-zone-label-container"]}>
              <BackupRounded />
              <p>{t("upload_logo_1")}</p>
              <small>
                {t("upload_logo_2")} <br />
                {t("upload_logo_3")}
              </small>
              {fileRejectionItems.length > 0 ? fileRejectionItems : <></>}
            </div>
          </div>
          <div className={styles["action-container"]}>
            <Button
              onClick={() => {
                setDisplayDropZone(false);
              }}
              icon={ArrowBackRounded}
              variant={"outline-danger"}
              isFlat={true}
              label={`Cancel Upload`}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {displayDropZone || displayLoader ? (
        <></>
      ) : (
        <>
          {images ? (
            <div className={styles["image-gallery-container"]}>
              {images.map((image) => (
                <div
                  key={image.id}
                  className={styles["image-container"]}
                  style={{
                    background:
                      (image.id && image.id === hoveredImageId) ||
                      (image.id && imageIdsToDelete.includes(image.id))
                        ? `linear-gradient(190deg, rgba(240, 166, 166, 0.4)30%, rgba(240, 166, 166, 0.4) 30%), url(${image.imgUrl})`
                        : `url(${image.imgUrl})`,
                  }}
                  onMouseOver={() => {
                    setHoveredImageId(image.id);
                  }}
                  onMouseOut={() => {
                    setHoveredImageId(null);
                  }}
                  onMouseDown={() => {
                    handleSetImageIdsToDelete(image.id);
                  }}
                >
                  {image.id === hoveredImageId ? (
                    image.id && imageIdsToDelete.includes(image.id) ? (
                      <div className={styles["delete-container"]}>
                        <DeleteForeverRounded />
                        Don't Delete
                      </div>
                    ) : (
                      <div className={styles["delete-container"]}>
                        <DeleteRounded />
                        Delete
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <>No Images Avaialble</>
          )}
          <div className={styles["action-container"]}>
            {imageIdsToDelete && imageIdsToDelete.length > 0 ? (
              <Button
                onClick={() => {
                  handleDeleteConfirm();
                }}
                icon={DeleteRounded}
                variant={"danger"}
                isFlat={true}
                label={`Delete ${imageIdsToDelete.length} Images`}
              />
            ) : (
              <Button
                onClick={() => {
                  setDisplayDropZone(displayDropZone ? false : true);
                }}
                icon={AddRounded}
                variant={"primary"}
                isFlat={true}
                label={`Add Images`}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
