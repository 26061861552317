import { AddRounded, CloseRounded } from "@material-ui/icons";
import React, { HTMLAttributes, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import { AddElementToolbarDropdown } from "./AddElementToolbarDropdown";
import { getAddToolbar, getPageSlug } from "../../../selectors";

import styles from "./AddElementToolbar.module.scss";
import { getUserWebsiteSlug } from "auth/selectors";
import { getWebsite } from "editor/states/selectors";
import { useTranslation } from "react-i18next";

export const AddElementToolbar: React.FunctionComponent<HTMLAttributes<
  HTMLDivElement
>> = ({ id }) => {
  const { t } = useTranslation();
  const state = useSelector(getAddToolbar);
  const website = useSelector(getWebsite);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const [isShown, setIsShown] = useState(false);
  const [positionY, setPositionY] = useState<number>();
  const pageSlug = useSelector(getPageSlug);
  const handleMouseDown = (event: React.MouseEvent) => {
    const clientRect = event.currentTarget.getBoundingClientRect();
    setPositionY(clientRect.y);
  };

  let marginTop = state.drop === "down" ? "3px" : "0px";
  let borderRadius = "20px";
  let top = state.rect.y;

  if (state.rect.y < 0) {
    marginTop = "35px";
    borderRadius = "0px 0px 20px 20px";
    top = 0;
  } else if (window.innerHeight < document.body.scrollHeight) {
    if (state.rect.y + 5 > document.body.scrollHeight) {
      marginTop = "-35px";
      borderRadius = "20px 20px 0px 0px";
    }
  }

  if (state.state === "skip") {
    return (
      <div
        key={id}
        className={`${styles["add-element-toolbar"]}
          d-flex align-items-center justify-content-center`}
        id={id}
        style={{
          left: state.rect.x,
          top: top,
          width: state.rect.width,
          height: state.rect.height,
        }}
      >
        <Dropdown
          show={isShown}
          onToggle={() => setIsShown(!isShown)}
          onMouseDown={handleMouseDown}
          drop={state.drop}
        >
          <Dropdown.Toggle
            className={`${styles["add-element-toolbar-toggle"]}`}
            style={{
              marginTop: marginTop,
              borderRadius: borderRadius,
            }}
            id={`${id}-toggle`}
          >
            {isShown ? (
              <>
                <CloseRounded />
                {state.drop === "down" || state.drop === "up" ? (
                  <div className={styles["text-container"]}>{t("close")}</div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <AddRounded />
                {state.drop === "down" || state.drop === "up" ? (
                  <div className={styles["text-container"]}>{t("add")}</div>
                ) : (
                  <></>
                )}
              </>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            as={AddElementToolbarDropdown}
            elementParentId={state.elementParentId}
            index={state.index}
            elementKeys={state.elementKeys}
            close={() => setIsShown(false)}
            height={state.rect.height}
            width={state.rect.width}
            drop={state.drop}
            positionY={positionY}
            pageSlug={pageSlug}
            websiteSlug={websiteSlug}
            website={website}
          ></Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else {
    return (
      <div
        key={id}
        className={`${styles["add-element-toolbar-hidden"]}
          d-flex align-items-center justify-content-center`}
      ></div>
    );
  }
};
