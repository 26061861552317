import { Formik, FormikErrors } from "formik";
import React, { ChangeEvent, useState } from "react";
import { Button, Modal, Col, Form, InputGroup } from "react-bootstrap";
import * as yup from "yup";

import { IStepProps } from "./Setup";

import { useTranslation } from "react-i18next";
import { generateUrl, validateUrl } from "./websiteActions";
import { useDispatch } from "react-redux";

import styles from "./Admin.module.scss";

const schema = yup.object({
  title: yup.string().required(/* TODO: Add a message */),
  url: yup.string().required(),
  description: yup.string().required(),
});

export type SetupStep2FormValues = yup.InferType<typeof schema>;

export const SetupStep2Form: React.FunctionComponent<IStepProps> = ({
  values,
  setValues,
  handleSaveAndContinue,
  handleCreateWebsiteCore,
  isSaving,
  handleStepBack,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formTitleError, setFormTitleError] = useState<string | undefined>();
  const [formDescriptionError, setFormDescriptionError] = useState<
    string | undefined
  >();
  const [formUrlError, setFormUrlError] = useState<string | undefined>();
  const [formTitleTouched, setFormTitleTouched] = useState<boolean>(false);
  const [formDescriptionTouched, setFormDescriptionTouched] = useState<boolean>(
    false
  );
  const [formUrlTouched, setFormUrlTouched] = useState<boolean>(false);
  const [formUrl, setFormUrl] = useState<string | undefined>(values.url);
  const [formUrlInfo, setFormUrlInfo] = useState<string | undefined>();
  const [isRequestForUrlSent, setIsRequestForUrlSent] = useState<boolean>(true);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [formButtonLabel, setFormLabel] = useState<string>(t("setup_page_15"));

  const onSubmit = (values: SetupStep2FormValues) => {
    handleSaveAndContinue(values);
  };

  const handleGenerateWebsite = () => {
    setIsFormSubmitted(true);
    handleCreateWebsiteCore(values, handleGenerateWebsiteResult);
  };

  const handleGenerateWebsiteResult = (isSuccess: boolean, slug: string) => {
    values.url = slug;
    setValues(values);
    setIsFormSubmitted(false);
    if (isSuccess) {
      handleSaveAndContinue(values);
    } else {
      setFormLabel(t("setup_page_15"));
    }
  };

  const handleValidateForm = () => {
    if (formTitleTouched && (!values.title || !/\S/.test(values.title))) {
      setFormTitleError(t("setup_wizard_145"));
    } else {
      setFormTitleError(undefined);
    }

    if (formUrlTouched && (!values.url || !/\S/.test(values.url))) {
      setFormUrlError(t("setup_wizard_146"));
    } else {
      setFormUrlError(undefined);
    }

    if (
      formDescriptionTouched &&
      (!values.description || !/\S/.test(values.description))
    ) {
      setFormDescriptionError(t("setup_wizard_147"));
    } else {
      setFormDescriptionError(undefined);
    }
    setIsFormValid(
      !values.title ||
        !/\S/.test(values.title) ||
        !values.url ||
        !/\S/.test(values.url) ||
        !values.description ||
        !/\S/.test(values.description)
    );
  };

  // useEffect(() => {
  //   handleValidateForm();
  // }, [values, formTitleTouched, formUrlTouched, formDescriptionTouched, handleValidateForm])

  const handleUrlUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setFormUrlTouched(true);
    setFormUrl(event.target.value);
    setFormUrlInfo(undefined);
    handleValidateForm();
  };

  const handleUrlUpdateOnBlur = (
    event: ChangeEvent<HTMLInputElement>,
    title: string,
    valuesToUpdate: SetupStep2FormValues,
    errors: FormikErrors<SetupStep2FormValues>
  ) => {
    setFormUrlTouched(true);
    if (event.target.value) {
      dispatch(
        validateUrl(
          event.target.value,
          (slug?: string, isValid?: boolean, oldUrl?: string) => {
            const updatedValues = { ...values, ...valuesToUpdate };
            let errorMessage: string | undefined = undefined;
            if (slug && isValid) {
              updatedValues.url = slug;
            } else if (!isValid && slug) {
              errorMessage =
                t("setup_wizard_143") + oldUrl + t("setup_wizard_144") + slug;
              updatedValues.url = slug;
            }
            errors.url = errorMessage;
            setFormUrlInfo(errorMessage);
            setFormUrl(updatedValues.url);
            setValues(updatedValues);
            setIsRequestForUrlSent(false);
          }
        )
      );
    } else {
      handleSiteNameBlur(title, valuesToUpdate, errors);
    }
  };

  const handleSiteNameBlur = (
    title: string,
    valuesToUpdate: SetupStep2FormValues,
    errors: FormikErrors<SetupStep2FormValues>
  ) => {
    setFormTitleTouched(true);
    setIsRequestForUrlSent(true);
    values.title = title;
    setValues(values);
    dispatch(
      generateUrl(
        title,
        (slug?: string, isValid?: boolean, oldUrl?: string) => {
          const updatedValues = { ...values, ...valuesToUpdate };
          let errorMessage: string | undefined = undefined;
          if (slug && isValid) {
            updatedValues.url = slug;
          } else if (!isValid && slug) {
            errorMessage =
              t("setup_wizard_143") + oldUrl + t("setup_wizard_144") + slug;
            updatedValues.url = slug;
          }
          errors.url = errorMessage;
          setFormUrlInfo(errorMessage);
          setFormUrl(updatedValues.url);
          setFormUrlTouched(true);
          setValues(updatedValues);
          setIsRequestForUrlSent(false);
        }
      )
    );
  };

  const handleDescriptionBlur = (
    description: string,
    valuesToUpdate: SetupStep2FormValues,
    errors: FormikErrors<SetupStep2FormValues>
  ) => {
    setFormDescriptionTouched(true);
    const updatedValues = { ...values, ...valuesToUpdate };
    updatedValues.url = values.url;
    if (description && /\S/.test(description)) {
      updatedValues.description = description;
      values.description = description;
      setValues(updatedValues);
    } else {
      errors.description = t("setup_wizard_148");
      setValues(updatedValues);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, touched, errors, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              <h6>{t("setup_wizard_80")}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Col}>
              <Col>
                <h4>{t("setup_page_17")}</h4>
                <Form.Control
                  type="text"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={() => {
                    handleSiteNameBlur(values.title, values, errors);
                  }}
                  isInvalid={touched.title && !!errors.title}
                  placeholder={t("setup_page_18")}
                  size="lg"
                />
                <div className={styles["invalid"]}>{formTitleError}</div>
              </Col>
              <Col>
                <h4>{t("setup_wizard_141")}</h4>
                <InputGroup style={{ width: "400px" }}>
                  <Form.Control
                    placeholder={t("setup_wizard_142")}
                    aria-label="URL"
                    aria-describedby="basic-addon2"
                    value={
                      isRequestForUrlSent ? t("setup_wizard_149") : formUrl
                    }
                    size="lg"
                    disabled={isRequestForUrlSent}
                    style={isRequestForUrlSent ? { color: "gray" } : {}}
                    onChange={handleUrlUpdate}
                    onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                      handleUrlUpdateOnBlur(e, values.title, values, errors);
                    }}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">
                      {
                        // getDomain(document.domain) +
                        window.location.port ? ":" + window.location.port : ""
                      }
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <div className={styles["setup_notification_feedback"]}>
                  {formUrlInfo}
                </div>
                <div className={styles["invalid"]}>{formUrlError}</div>
              </Col>
              <Col>
                <h4>{t("setup_wizard_5")}</h4>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={() => {
                    handleDescriptionBlur(values.description, values, errors);
                  }}
                  isInvalid={touched.description && !!errors.description}
                  placeholder={t("setup_wizard_6")}
                  size="lg"
                  style={{ minHeight: "200px" }}
                />
                <div className={styles["invalid"]}>{formDescriptionError}</div>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isFormValid || isFormSubmitted}
              style={{ borderRadius: 32 }}
              variant="primary"
              onClick={() => handleGenerateWebsite()}
            >
              {/* {isSaving ? "Loading..." : "Create"} */}
              {/* {isFormSubmitted ? t("setup_wizard_150") : t("setup_page_15")} */}
              {formButtonLabel}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
