import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Facebook, Twitter } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { cateringChild1Template } from "./cateringChild1";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { NavbarElement } from "editor/elements/sections/Navbar/Navbar";
import { LogoContainerElement } from "editor/elements/components/LogoContainer/LogoContainer";
import { NavElement } from "editor/elements/components/Nav/Nav";
import { SocialNavElement } from "editor/elements/components/SocialNav/SocialNav";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const cateringTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "catering";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 4,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 5,
    templateTitle: templateKey,
  },
  {
    elementKey: "messenger",
    position: 6,
    templateTitle: templateKey,
  },
  {
    elementKey: "whatsapp",
    position: 7,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return LayoutEditor.createEmpty()
        .add(
          NavbarElement.create({
            navbarStyle: "0",
            width: 100,
            backgroundColorProperty: "#fff",
            backgroundImageProperty: new BackgroundImageProperty(
              undefined,
              "repeat",
              "center",
              "center",
              "cover",
              0,
              0,
              1
            ),
          })
        )
        .addChildren(
          LayoutEditor.createEmpty()
            .add(
              NavElement.create({
                links: TemplatesFactory.getLinkProperties(
                  childPageTemplates,
                  false,
                  mainPageDetails
                ),
                style: "1",
                linkColor: "#001b54",
              })
            )
            .add(
              LogoContainerElement.create({
                label: "logo",
                logoText: new LogoTextProperty(defaultLogoText),
                logoStyle: new LogoStyleProperty(
                  "logo",
                  isLogoCreated && websiteSlug
                    ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
                    : "https://s3.amazonaws.com/img.goodweb.app/sites/default/catering/main.png",
                  new Date().toISOString()
                ),
                logoDecoration: "0",
                logoImage: new LogoImageProperty(
                  undefined,
                  new Date().toISOString()
                ),
              })
            )
            .add(
              SocialNavElement.create({
                socialLinks: [
                  new SocialLinkProperty(
                    new SocialMedia(
                      "facebook",
                      "Facebook",
                      <Facebook />,
                      "www.facebook.com/johndoe"
                    ),
                    "/",
                    true
                  ),
                  new SocialLinkProperty(
                    new SocialMedia(
                      "twitter",
                      "Twitter",
                      <Twitter />,
                      "www.twitter.com/johndoe"
                    ),
                    "/",
                    true
                  ),
                ],
                style: "2",
                position: "1",
              })
            )
            .build()
        )
        .build();

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/catering/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright Catering Co. " + new Date().getFullYear()
      );

    default:
      return cateringTemplates[layoutKey];
  }
};

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page of Catering",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: cateringChild1Template,
  },
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "cateringChild1Template",
    details: {
      title: "About Us",
      slug: randomSlug(6),
      description: "About Us",
    },
    template: {
      template: cateringChild1Template,
    },
  },
];

cateringTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "Catering",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

cateringTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "Makeup",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);

cateringTemplates["navbar"] = LayoutEditor.createEmpty()
  .add(
    NavbarElement.create({
      navbarStyle: "0",
      width: 100,
      backgroundColorProperty: "#fff",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        NavElement.create({
          links: TemplatesFactory.getLinkProperties(
            childPageTemplates,
            false,
            mainPageDetails
          ),
          style: "1",
          linkColor: "#001b54",
        })
      )
      .add(
        LogoContainerElement.create({
          label: "logo",
          logoText: new LogoTextProperty(defaultLogoText),
          logoStyle: new LogoStyleProperty(
            "logo",
            "https://s3.amazonaws.com/img.goodweb.app/sites/default/catering/main.png",
            new Date().toISOString()
          ),
          logoDecoration: "0",
          logoImage: new LogoImageProperty(undefined, new Date().toISOString()),
        })
      )
      .add(
        SocialNavElement.create({
          socialLinks: [
            new SocialLinkProperty(
              new SocialMedia(
                "facebook",
                "Facebook",
                <Facebook />,
                "www.facebook.com/johndoe"
              ),
              "/",
              true
            ),
            new SocialLinkProperty(
              new SocialMedia(
                "twitter",
                "Twitter",
                <Twitter />,
                "www.twitter.com/johndoe"
              ),
              "/",
              true
            ),
          ],
          style: "2",
          position: "1",
        })
      )
      .build()
  )
  .build();

cateringTemplates["cover"] = TemplatesFactory.createDefaultCover(
  "5",
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Book Us", "", false, false, false),
  new RichTextProperty(
    `<h1><span style="color: rgb(0, 26, 84);">A Feast in the table and<br>smiles on your faces.&nbsp;</span></h1>`
  ),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://images.unsplash.com/photo-1505935428862-770b6f24f629?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwyNTExNzJ8MHwxfHNlYXJjaHwzfHxmb29kJTIwdGFibGV8ZW58MHwwfHx8MTY1MzUxMTc1MA&ixlib=rb-1.2.1&q=80",
    "no-repeat",
    "50%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(``),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 70)
);

cateringTemplates[
  "cards"
] = TemplatesFactory.createDefaultCards(
  new LeadStyleProperty("1"),
  new RichTextProperty(
    `<h2><span style="color: rgb(0, 26, 84);">Rated #1 Catering in Santa Ana</span></h2>`
  ),
  new RTEAlignmentProperty("center"),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    `<div><span style="color: rgb(0, 26, 84);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo modi eaque architecto.</span></div>`
  ),
  new RTEAlignmentProperty("center"),
  "2",
  [
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Diverse Menu</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Read More", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f" style="color: rgb(0, 26, 84);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo modi eaque architecto.</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1628578569073-8ee77295315d?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Y2F0ZXJpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500",
      "2"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Top Chefs</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("About Eve", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f" style="color: rgb(0, 26, 84);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo modi eaque architecto.</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1583394293214-28ded15ee548?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180",
      "2"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Budget Friendly</h3>"),
      new RTEAlignmentProperty("center"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Learn More", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f" style="color: rgb(0, 26, 84);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo modi eaque architecto.</div>'
      ),
      new RTEAlignmentProperty("center"),
      "https://images.unsplash.com/photo-1625944527261-06c90f1901e3?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nzl8fGNhdGVyaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500",
      "2"
    ),
  ]
);

cateringTemplates["articles"] = TemplatesFactory.createDefaultArticles(
  new LeadStyleProperty("2"),
  new RichTextProperty(
    `<h2><span style="color: rgb(0, 26, 84);">Parties, Weddings, baptisms, graduations,&nbsp;quinceañeras.<br><br>We got you covered.</span></h2>`
  ),
  new RTEAlignmentProperty("parent"),
  new ImageStyleProperty("1"),
  new ButtonStyleProperty("hobaw-btn-outline-primary"),
  new LinkProperty("Read More", "", false, false, false),
  new BackgroundColorProperty("#FFEAE0"),
  new RichTextProperty(``),
  new RTEAlignmentProperty("parent"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor."
  ),
  new RTEAlignmentProperty("parent"),
  "2",
  "1",
  "https://images.unsplash.com/photo-1527661591475-527312dd65f5?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=830",
  "2"
);

cateringTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("1"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Book an Appointment</h2>"),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Fill in your details below and we'll be in contact with you soon."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

cateringTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/catering/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright Catering Co. " + new Date().getFullYear()
);
