import {
  compactTemplates,
  mainPageDetails as compactPageDetails,
  childPageTemplates as compactChildTemplates,
  sharedElements as compactSharedElements,
} from "./compact";
import {
  foodTemplates,
  childPageTemplates as foodChildTemplates,
  sharedElements as foodSharedElements,
} from "./food";
import {
  looseTemplates,
  childPageTemplates as looseChildTemplates,
  sharedElements as looseSharedElements,
} from "./loose";
import {
  weddingTemplates,
  childPageTemplates as weddingChildTemplates,
  sharedElements as weddingSharedElements,
} from "./wedding";
import {
  authorTemplates,
  childPageTemplates as authorChildTemplates,
  sharedElements as authorSharedElements,
} from "./author";
import {
  transportTemplates,
  childPageTemplates as transportChildTemplates,
  sharedElements as transportSharedElements,
} from "./transport";
import {
  makeupTemplates,
  childPageTemplates as makeupChildTemplates,
  sharedElements as makeupSharedElements,
} from "./makeup";
import {
  realestateTemplates,
  childPageTemplates as realestateChildTemplates,
  sharedElements as realestateSharedElements,
} from "./realestate";
import {
  resumeTemplates,
  childPageTemplates as resumeChildTemplates,
  sharedElements as resumeSharedElements,
} from "./resume";
import {
  cateringTemplates,
  childPageTemplates as cateringChildTemplates,
  sharedElements as cateringSharedElements,
} from "./catering";
import {
  vinylTemplates,
  childPageTemplates as vinylChildTemplates,
  sharedElements as vinylSharedElements,
} from "./vinyl";
import {
  lodgeTemplates,
  childPageTemplates as lodgeChildTemplates,
  sharedElements as lodgeSharedElements,
} from "./lodge";

import { Layout } from "common/elements/types";

export enum TemplateKey {
  Blank = "blank",
  Compact = "compact", // Illustration
  Loose = "loose", // Fashion
  Food = "food",
  Author = "author",
  Wedding = "wedding",
  Transport = "transport",
  Makeup = "makeup",
  Realestate = "realestate",
  Resume = "resume",
  Catering = "catering",
  Vinyl = "vinyl",
  Lodge = "lodge",
}

export type Template = { [elementTemplateKey: string]: Layout };

type Templates = { [templateKey: string]: Template };

export const templates: Templates = {
  [TemplateKey.Blank]: {},
  [TemplateKey.Compact]: compactTemplates,
  [TemplateKey.Loose]: looseTemplates,
  [TemplateKey.Food]: foodTemplates,
  [TemplateKey.Author]: authorTemplates,
  [TemplateKey.Wedding]: weddingTemplates,
  [TemplateKey.Transport]: transportTemplates,
  [TemplateKey.Makeup]: makeupTemplates,
  [TemplateKey.Realestate]: realestateTemplates,
  [TemplateKey.Resume]: resumeTemplates,
  [TemplateKey.Catering]: cateringTemplates,
  [TemplateKey.Vinyl]: vinylTemplates,
  [TemplateKey.Lodge]: lodgeTemplates,
};

export type TemplateDetails = {
  title: string;
  screenshotUrl: string;
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  secondaryColor: string;
  ternaryColor: string;
  colorType: string;
  primaryRadius: string;
  secondaryRadius: string;
  primaryShadow: string;
  sampleUrl: string;
};

type TemplateTitle = { [templateKey: string]: TemplateDetails };

export const templateTitles: TemplateTitle = {
  [TemplateKey.Blank]: {
    title: "Blank",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/blank/blank-screenshot.png",
    primaryFont: `"Roboto", sans-serif`,
    secondaryFont: `"Noto Sans JP", sans-serif`,
    primaryColor: "#124E78",
    secondaryColor: "#BB9012",
    ternaryColor: "#1E2182",
    colorType: "comp",
    primaryRadius: "5px",
    secondaryRadius: "2.5px",
    primaryShadow: "0.5",
    sampleUrl: "https://template0.goodweb.app",
  },
  [TemplateKey.Compact]: {
    title: "Illustrations",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-screenshot.png",
    primaryFont: `"Spartan", sans-serif`,
    secondaryFont: `"Noto Sans JP", sans-serif`,
    primaryColor: "#E24122",
    secondaryColor: "#23AD77",
    ternaryColor: "#FF9580",
    colorType: "comp",
    primaryRadius: "50px",
    secondaryRadius: "25px",
    primaryShadow: "0.6",
    sampleUrl: "https://template1.goodweb.app",
  },
  [TemplateKey.Loose]: {
    title: "Fashion",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/loose/loose-screenshot.png",
    primaryFont: `"Rozha One", serif;`,
    secondaryFont: `"Libre Baskerville", serif`,
    primaryColor: "#FD738A",
    secondaryColor: "#8afd73",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "0.3",
    sampleUrl: "https://template2.goodweb.app",
  },
  [TemplateKey.Food]: {
    title: "Restaurant",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/food/food-screenshot.png",
    primaryFont: `"Overpass", sans-serif`,
    secondaryFont: `"Noto Sans JP", sans-serif`,
    primaryColor: "#F0A062",
    secondaryColor: "#F0A062",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "1",
    sampleUrl: "https://template3.goodweb.app",
  },
  [TemplateKey.Author]: {
    title: "Author",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/author/author-screenshot.png",
    primaryFont: `"Libre Baskerville", serif`,
    secondaryFont: `"Noto Sans JP", sans-serif`,
    primaryColor: "#333333",
    secondaryColor: "#5d5d5d",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "1",
    sampleUrl: "https://template4.goodweb.app",
  },
  [TemplateKey.Wedding]: {
    title: "Wedding",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/wedding/wedding-screenshot.png",
    primaryFont: `"Lora", serif`,
    secondaryFont: `"Jost", sans-serif`,
    primaryColor: "#D4AF37",
    secondaryColor: "#D4AF37",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "1",
    sampleUrl: "https://template5.goodweb.app",
  },
  [TemplateKey.Transport]: {
    title: "Transport",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/transport/transport-screenshot.png",
    primaryFont: `"Overpass", sans-serif`,
    secondaryFont: `"Kumbh Sans", sans-serif`,
    primaryColor: "#3a98f1",
    secondaryColor: "#F1933A",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "50px",
    secondaryRadius: "25px",
    primaryShadow: "0.5",
    sampleUrl: "https://template6.goodweb.app",
  },
  [TemplateKey.Makeup]: {
    title: "Make up",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/makeup/makeup-screenshot.png",
    primaryFont: `"Spartan", sans-serif`,
    secondaryFont: `"Montserrat", sans-serif`,
    primaryColor: "#194D33",
    secondaryColor: "#4d1933",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "0.3",
    sampleUrl: "https://template7.goodweb.app",
  },
  [TemplateKey.Realestate]: {
    title: "Real estate",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/realestate/realestate-screenshot.png",
    primaryFont: `"Poppins", sans-serif`,
    secondaryFont: `"Montserrat", sans-serif`,
    primaryColor: "#ED0582",
    secondaryColor: "#002985",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "0.2",
    sampleUrl: "https://template8.goodweb.app",
  },
  [TemplateKey.Resume]: {
    title: "Resume",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/resume/resume-screenshot.png",
    primaryFont: `"Lora", serif`,
    secondaryFont: `"Libre Baskerville", serif`,
    primaryColor: "#FB9461",
    secondaryColor: "#002985",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "1.0",
    sampleUrl: "https://template9.goodweb.app",
  },
  [TemplateKey.Catering]: {
    title: "Catering",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/catering/catering-screenshot.png",
    primaryFont: `"Rozha One", serif;`,
    secondaryFont: `"Poppins", sans-serif`,
    primaryColor: "#FF9663",
    secondaryColor: "#002987",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "0.4",
    sampleUrl: "https://template10.goodweb.app",
  },
  [TemplateKey.Vinyl]: {
    title: "Vinyl Catalog",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/vinyl/vinyl-screenshot.png",
    primaryFont: `"Space Mono", monospace`,
    secondaryFont: `"Jost", sans-serif`,
    primaryColor: "#770000",
    secondaryColor: "#007777",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "0.4",
    sampleUrl: "https://template11.goodweb.app",
  },
  [TemplateKey.Lodge]: {
    title: "Lodge",
    screenshotUrl:
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/lodge/lodge-screenshot.png",
    primaryFont: `"Overpass", sans-serif`,
    secondaryFont: `"Noto Sans JP", sans-serif`,
    primaryColor: "#734E3C",
    secondaryColor: "#3c6173",
    ternaryColor: "#efefef",
    colorType: "comp",
    primaryRadius: "0px",
    secondaryRadius: "0px",
    primaryShadow: "0.4",
    sampleUrl: "https://template12.goodweb.app",
  },
};

export type ChildSharedElements = { [templateKey: string]: SharedElement[] };

export const sharedElements: ChildSharedElements = {
  [TemplateKey.Compact]: compactSharedElements,
  [TemplateKey.Loose]: looseSharedElements,
  [TemplateKey.Food]: foodSharedElements,
  [TemplateKey.Author]: authorSharedElements,
  [TemplateKey.Wedding]: weddingSharedElements,
  [TemplateKey.Transport]: transportSharedElements,
  [TemplateKey.Makeup]: makeupSharedElements,
  [TemplateKey.Realestate]: realestateSharedElements,
  [TemplateKey.Resume]: resumeSharedElements,
  [TemplateKey.Catering]: cateringSharedElements,
  [TemplateKey.Vinyl]: vinylSharedElements,
  [TemplateKey.Lodge]: lodgeSharedElements,
};

export type ChildTemplates = { [templateKey: string]: ChildPage[] };

export type TemplatePageDetails = { [templateKey: string]: ChildPage };

export const childTemplates: ChildTemplates = {
  [TemplateKey.Compact]: compactChildTemplates,
  [TemplateKey.Loose]: looseChildTemplates,
  [TemplateKey.Food]: foodChildTemplates,
  [TemplateKey.Author]: authorChildTemplates,
  [TemplateKey.Wedding]: weddingChildTemplates,
  [TemplateKey.Transport]: transportChildTemplates,
  [TemplateKey.Makeup]: makeupChildTemplates,
  [TemplateKey.Realestate]: realestateChildTemplates,
  [TemplateKey.Resume]: resumeChildTemplates,
  [TemplateKey.Catering]: cateringChildTemplates,
  [TemplateKey.Vinyl]: vinylChildTemplates,
  [TemplateKey.Lodge]: lodgeChildTemplates,
};

export const templatePageDetails: TemplatePageDetails = {
  [TemplateKey.Compact]: compactPageDetails,
  // [TemplateKey.Loose]: looseChildTemplates,
  // [TemplateKey.Food]: foodChildTemplates,
  // [TemplateKey.Author]: authorChildTemplates,
  // [TemplateKey.Wedding]: weddingChildTemplates,
  // [TemplateKey.Transport]: transportChildTemplates,
  // [TemplateKey.Makeup]: makeupChildTemplates,
  // [TemplateKey.Realestate]: realestateChildTemplates,
  // [TemplateKey.Resume]: resumeChildTemplates,
  // [TemplateKey.Catering]: cateringChildTemplates,
  // [TemplateKey.Vinyl]: vinylChildTemplates,
  // [TemplateKey.Lodge]: lodgeChildTemplates,
};

export type ChildPageDetails = {
  title: string;
  slug: string;
  description: string;
};

export type ChildPageTemplate = {
  template: Template;
};

export type ChildPage = {
  templateName: string;
  details: ChildPageDetails;
  template: ChildPageTemplate;
};

export type SharedElement = {
  elementKey: string;
  position: number;
  templateTitle: string;
  elementId?: string;
};
