import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type Heading4Properties = {
  heading4Style: { style: string };
  heading4Text: { text: string };
};

export function Heading4({
  properties: { heading4Style, heading4Text },
  className,
  ...props
}: ElementComponentProps<Heading4Properties>): JSX.Element {
  return (
    <h4
      {...props}
      className={className + "h4 heading-4-style-" + heading4Style.style}
    >
      {heading4Text.text}
    </h4>
  );
}

export const heading4ElementKey = "heading-4";

elementComponentRegistry.register(heading4ElementKey, Heading4);
