import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type ArticleHeaderProperties = {
  articleHeaderStyle: string;
};

export function ArticleHeader({
  properties: { articleHeaderStyle },
  className,
  innerRef,
  children,
  ...props
}: ElementComponentProps<ArticleHeaderProperties>): JSX.Element {
  return (
    <div
      {...props}
      ref={innerRef}
      className={`${className} article-header article-header-style-${articleHeaderStyle} p-s p-sh`}
    >
      {children}
    </div>
  );
}

export const articleHeaderElementKey = "article-header";

elementComponentRegistry.register(articleHeaderElementKey, ArticleHeader);
