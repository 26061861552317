import React, { useState, useEffect } from "react";
import Moveable from "react-moveable";
import { useSelector, useDispatch } from "react-redux";

import * as resizeToolbarActions from "./resizeToolbarActions";
import * as websiteActions from "../../../states/website";
import { getSelectedElementId, getResizeToolbar } from "../../../selectors";
import { getUsername, getUserWebsiteSlug } from "../../../../auth/selectors";
import { getElementConfiguration } from "../../../elements/registry";
import { getWebsite } from "editor/states/selectors";
import { ResizeToolbarGuide, getClosestGuide } from "./ResizeToolbarGuide";
import {
  ResizeToolbarHorizontalGuide,
  getWindowDimensions,
} from "./ResizeToolbarHorizontalGuide";
import { propertySet } from "editor/states/layout";
import { getCompositeLayout } from "editor/states/selectors";
import produce from "immer";
import { logActivity } from "auth/authActions";
import { editorPage } from "../Editor/Editor";
import { DimensionProperty } from "editor/properties";

type Props = {
  selectedRef: React.MutableRefObject<any>;
};

export default function ResizeToolbar({ selectedRef }: Props): JSX.Element {
  const state = useSelector(getResizeToolbar);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const dispatch = useDispatch();
  const selectedElementId = useSelector(getSelectedElementId);
  const layout = useSelector(getCompositeLayout);
  const username = useSelector(getUsername);
  const [isResizing, setIsResizing] = useState(false);
  const [isResizingHorizontal, setIsResizingHorizontal] = useState(false);
  const [isResizingVertical, setIsResizingVertical] = useState(false);

  useEffect((): void => {
    if (!selectedRef.current || !selectedElementId) {
      return;
    }
    dispatch(resizeToolbarActions.show(selectedElementId));
  }, [dispatch, selectedRef, selectedElementId]);

  if (!selectedRef.current || !selectedElementId) {
    return <></>;
  }

  const handleHorizontalGuides = (): void => {
    setIsResizingHorizontal(true);
    setIsResizingVertical(false);
  };

  const handleVerticalGuides = (): void => {
    setIsResizingVertical(true);
    setIsResizingHorizontal(false);
  };

  const handleResizeStart = (): void => {
    setIsResizing(true);
    dispatch(resizeToolbarActions.startResize());
  };

  const handleResizeEnd = (
    selectedDomElement: HTMLElement | SVGElement,
    guides: number[],
    elementId: string,
    elementKey: string
  ): void => {
    setIsResizing(false);
    const elementWidth = parseInt(
      window.getComputedStyle(selectedDomElement).getPropertyValue("width"),
      10
    );
    const closestGuide = getClosestGuide(elementWidth, guides);
    const closestWidth = closestGuide === window.innerWidth ? 0 : closestGuide;

    const horizontalGuides = [4, 5, 6, 7];
    const windowDimensions = getWindowDimensions();
    const guideDiff = windowDimensions.height / 10;
    const guidesMeasurements: number[] = [];
    horizontalGuides.forEach((guide) => {
      guidesMeasurements.push(
        selectedDomElement.getBoundingClientRect().top + guide * guideDiff
      );
    });
    const updatedElemHeight = parseInt(
      window.getComputedStyle(selectedDomElement).getPropertyValue("height"),
      10
    );

    let heightCoveragePercent =
      (100 * updatedElemHeight) / windowDimensions.height;
    let vh = Math.round(heightCoveragePercent / 10) * 10;

    const coverElemGuide = document.getElementById(
      "internal-cover-holder-" + elementId
    );
    if (elementKey === "cover" && coverElemGuide) {
      if (coverElemGuide.offsetHeight > updatedElemHeight) {
        heightCoveragePercent =
          (100 * coverElemGuide.offsetHeight) / windowDimensions.height;
        vh = Math.round(heightCoveragePercent / 10) * 10;
      }
    }

    selectedDomElement.style.width = "";
    selectedDomElement.style.height = "";

    dispatch(
      propertySet(
        selectedElementId,
        "dimension",
        new DimensionProperty(closestWidth, vh)
      )
    );

    if (
      selectedDomElement.getAttribute("guide") === "width-guide" &&
      websiteSlug !== undefined
    ) {
      dispatch(
        websiteActions.websiteDetailsUpdated(
          websiteSlug,
          produce(website, (draft) => {
            draft.category = website.category;
            draft.title = website.title;
            draft.slug = website.slug;
            draft.widthGuide = closestWidth;
          })
        )
      );
    }

    dispatch(resizeToolbarActions.end());
  };

  const uruxElement = layout[selectedElementId];
  const elementConfiguration = getElementConfiguration(uruxElement.elementKey);

  if (!elementConfiguration || !elementConfiguration.isResizeable) {
    return <></>;
  }

  const elementHeight = parseInt(
    window.getComputedStyle(selectedRef.current).getPropertyValue("height"),
    10
  );

  let moveableNorthButtons = Array.from(
    document.getElementsByClassName("moveable-n")
  );
  let moveableSouthButtons = Array.from(
    document.getElementsByClassName("moveable-s")
  );
  moveableNorthButtons.forEach((moveableNorthButton) => {
    moveableNorthButton.addEventListener("mousedown", handleHorizontalGuides);
  });
  moveableSouthButtons.forEach((moveableSouthButton) => {
    moveableSouthButton.addEventListener("mousedown", handleHorizontalGuides);
  });

  let moveableEastButtons = Array.from(
    document.getElementsByClassName("moveable-e")
  );
  let moveableWestButtons = Array.from(
    document.getElementsByClassName("moveable-w")
  );
  moveableEastButtons.forEach((moveableEastButton) => {
    moveableEastButton.addEventListener("mousedown", handleVerticalGuides);
  });
  moveableWestButtons.forEach((moveableWestButton) => {
    moveableWestButton.addEventListener("mousedown", handleVerticalGuides);
  });

  if (state.state === "shown" || state.state === "resizing") {
    return (
      <>
        <Moveable
          resizable
          target={selectedRef.current}
          renderDirections={elementConfiguration.resizeDirections}
          keepRatio={false}
          throttleResize={0}
          edge={false}
          zoom={0}
          origin={false}
          padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
          snappable={false}
          snapThreshold={0}
          isDisplaySnapDigit={false}
          snapGap={false}
          clipSnapThreshold={0}
          snapElement={false}
          snapVertical={false}
          snapHorizontal={false}
          snapCenter={false}
          snapDigit={0}
          onResizeStart={handleResizeStart}
          onResizeEnd={({ target }): void => {
            handleResizeEnd(
              target,
              elementConfiguration.resizeGuides,
              uruxElement.elementId,
              uruxElement.elementKey
            );
            dispatch(
              logActivity(
                "RESIZE ELEMENT",
                editorPage,
                username,
                uruxElement.elementKey
              )
            );
          }}
          onResize={({ target, clientX, clientY, width, height }): void => {
            target.style.width = `${width}px`;
            target.style.height = `${height}px`;
            dispatch(resizeToolbarActions.setMousePosition(clientX, clientY));
            // Commenting this out to maintain the resize to be centered
            // target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
          }}
        />
        {isResizing && isResizingVertical ? (
          <ResizeToolbarGuide
            guides={elementConfiguration.resizeGuides}
            selectedDomElement={selectedRef.current}
            elementHeight={elementHeight}
          />
        ) : (
          <></>
        )}
        {isResizing && isResizingHorizontal ? (
          <ResizeToolbarHorizontalGuide
            selectedDomElement={selectedRef.current}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
  return <></>;
}
