import {
  WebsiteCreateRequestCategoryEnum,
  WebsiteResourceCategoryEnum,
} from "@urux/arm-of-dorne-api";

export type TCategory = WebsiteResourceCategoryEnum;
export const TCategory = WebsiteResourceCategoryEnum;

export type TCreateCategory = WebsiteCreateRequestCategoryEnum;
export const TCreateCategory = WebsiteCreateRequestCategoryEnum;
