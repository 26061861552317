import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { EditorEmptyHelper } from "../EditorEmptyHelper/EditorEmptyHelper";
import { mapElementToComponent } from "../../../elements";
import {
  getDraggableElementId,
  getResizeableElementId,
  getTemplateKey,
} from "../../../selectors";
import { pageLatestVersionLoaded } from "./editorActions";
import { fetchWebsite } from "./../../../../admin/websiteActions";
import { getUserWebsiteSlug } from "../../../../auth/selectors";
import { TemplateKey } from "../../../templates/types";
import { getWebsite } from "../../../../editor/states/selectors";
import { websiteLatestVersionRequested } from "editor/states/website";
import { Layout } from "common/elements/types";
import { DragWindow } from "../DragWindow/DragWindow";
import { ResizeWindow } from "../DragWindow/ResizeWindow";

type Props = {
  selectedRef: React.MutableRefObject<any>;
  layout: Layout;
};

interface ParamTypes {
  pageSlug: string;
}

export const EditorContent: React.FunctionComponent<Props> = ({
  selectedRef,
  layout,
}) => {
  const { pageSlug } = useParams<ParamTypes>();
  const templateKey = useSelector(getTemplateKey);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const draggableElementId = useSelector(getDraggableElementId);
  const resizeableElementId = useSelector(getResizeableElementId);
  const [resizeElementWidth, setResizeElementWidth] = useState(0);
  const [resizeElementHeight, setResizeElementHeight] = useState(0);
  const [stateDraggableId, setStateDraggableId] = useState<string | null>();
  const [stateResizeableId, setStateResizeableId] = useState<string | null>();
  const layoutElementsSize = layout["root"].elementChildrenId!.length;
  const dispatch = useDispatch();
  const website = useSelector(getWebsite);
  let rootRef: React.MutableRefObject<any> = React.useRef();

  useEffect(() => {
    if (websiteSlug) {
      dispatch(pageLatestVersionLoaded(websiteSlug, pageSlug));
      dispatch(fetchWebsite(websiteSlug));
      dispatch(websiteLatestVersionRequested(websiteSlug));
    }
  }, [dispatch, websiteSlug, pageSlug]);

  useEffect(() => {
    setStateDraggableId(draggableElementId);
    setStateResizeableId(resizeableElementId);
    if (resizeableElementId) {
      const element = document.getElementById(resizeableElementId);
      if (element) {
        setResizeElementHeight(element.getBoundingClientRect().height);
        setResizeElementWidth(element.getBoundingClientRect().width);
      }
    }
  }, [draggableElementId, resizeableElementId]);

  return (
    <div
      className={`jaquen jq-rt--${website.widthGuide} hobaw-website-root--${
        website.widthGuide
      } ${stateDraggableId ? "drag-mode" : ""}`}
      ref={rootRef}
    >
      {layoutElementsSize === 0 ? (
        <EditorEmptyHelper />
      ) : stateDraggableId || stateResizeableId ? (
        <>
          {stateDraggableId ? (
            <DragWindow
              selectedRef={selectedRef}
              draggableId={stateDraggableId}
              // widthGuide={website.widthGuide}
            />
          ) : stateResizeableId ? (
            <ResizeWindow
              selectedRef={selectedRef}
              resizeId={stateResizeableId}
              width={resizeElementWidth}
              height={resizeElementHeight}
            />
          ) : (
            mapElementToComponent("root", undefined, 0, layout, selectedRef)
          )}
        </>
      ) : (
        mapElementToComponent("root", undefined, 0, layout, selectedRef)
      )}
      {templateKey !== TemplateKey.Blank ? (
        <link href={`/css/hobaw-${templateKey}.module.css`} rel="stylesheet" />
      ) : (
        <></>
      )}
    </div>
  );
};
