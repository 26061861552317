import React from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import TextFieldGroup from "../components/components/TextFieldGroup";
import { IProperty, PropertyComponent } from "./types";
import { propertySet } from "editor/states/layout";

import { useTranslation } from "react-i18next";

export default class TextProperty implements IProperty {
  constructor(readonly text: string) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <TextPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
        text={this.text}
      />
    );
  }

  static parse(value: any): TextProperty {
    return new TextProperty(value);
  }
}

const TextPropertyComponent: PropertyComponent<TextProperty> = ({
  elementId,
  propertyKey,
  text,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(
      propertySet(elementId, propertyKey, new TextProperty(e.target.value))
    );
  };

  return (
    <div className="form__group">
      <Form className="form">
        <TextFieldGroup
          name="text"
          label={t("dialog_105")}
          value={text}
          onChange={handleChange}
        />
      </Form>
    </div>
  );
};
