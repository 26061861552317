import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import { ReactComponent as CardsRight } from "../../../img/CardsRight.svg";
import { ReactComponent as CardsCenter } from "../../../img/CardsCenter.svg";
import { ReactComponent as CardsLeft } from "../../../img/CardsLeft.svg";

import styles from "./FormContainerStyleProperty.module.scss";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

export class FormContainerStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <FormContainerStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new FormContainerStyleProperty(value);
  }
}

const FormContainerStylePropertyComponent: PropertyComponent<FormContainerStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const radios = [
    { name: t("dialog_78"), value: "1", icon: <CardsLeft /> },
    { name: t("dialog_79"), value: "2", icon: <CardsCenter /> },
    { name: t("dialog_80"), value: "3", icon: <CardsRight /> },
  ];

  const handleStyleChange = (value: string) => {
    dispatch(propertySet(elementId, propertyKey, value));
  };

  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <Form className="form">
        <div className="form__group">
          <div className="medium-padded-container">
            <ButtonGroup
              className={`mb-2 navbar-style-group ${styles["button-group"]}`}
            >
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  type="radio"
                  variant="primary"
                  name="style"
                  value={radio.value}
                  checked={style === radio.value}
                  className="button-radio-hidden"
                  onClick={(e: any) => handleStyleChange(e.target.value)}
                >
                  {radio.icon}
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
