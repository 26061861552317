import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Line } from "@ant-design/plots";
import { OffcanvasProvider, Trigger, Offcanvas } from "react-simple-offcanvas";

import {
  BarChartRounded,
  ComputerRounded,
  PhoneIphoneRounded,
  TouchAppRounded,
  VisibilityRounded,
} from "@material-ui/icons";
import styles from "./Admin.module.scss";
import {
  getDailyReports,
  getMonthlyReports,
  getSubscription,
  getWebsite,
  getWeeklyReports,
} from "editor/states/selectors";
import {
  getWebsiteReportDefault,
  getWebsiteWeeklyReportDefault,
  getWebsiteMonthlyReportDefault,
} from "editor/states/reports";
import { Table } from "react-bootstrap";
import {
  getUserFirstName,
  getUserId,
  getUserLastName,
  getUsername,
  getUserWebsiteSlug,
  getWebsiteUniqueId,
} from "auth/selectors";
import { reportsPage } from "./Container";
import { logActivity } from "auth/authActions";
import { useTranslation } from "react-i18next";
import { Button } from "editor/components/base/Button/Button";
import { DailyReportWeb, ReportLocation } from "editor/models/types";
import { Link } from "react-router-dom";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { setTawkAttributes, tawkWidgetToggle } from "./Tawk";

import { isDesktopView } from "../admin/utils-country";

type DataType = { time: string; value: number; category: string };

type Props = {
  label: string;
};

export const Reports: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const ownerId = useSelector(getUserId);
  const websiteUniqueId = useSelector(getWebsiteUniqueId);
  const website = useSelector(getWebsite);
  const dailyReports = useSelector(getDailyReports);
  const weeklyReports = useSelector(getWeeklyReports);
  const monthlyReports = useSelector(getMonthlyReports);
  const subscription = useSelector(getSubscription);
  const [view, setView] = useState<string>();
  const [stateDailyReport, setStateDailyReport] = useState(dailyReports);
  const [data, setData] = useState<DataType[]>([]);
  const [headerLabel, setHeaderLabel] = useState<string>();
  const [chartLabel, setChartLabel] = useState<string>();
  const [simpleLabel, setSimpleLabel] = useState<string>();
  const [columnLabel, setColumnLabel] = useState<string>();
  const [locations, setLocations] = useState<ReportLocation[]>();
  const { t } = useTranslation();
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const username = useSelector(getUsername);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const tldjs = require("tldjs");
  const { getDomain } = tldjs;

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, []);

  useEffect(() => {
    tawkWidgetToggle(false);
  }, []);

  useEffect(() => {
    document.title = "GoodWeb | Reports";
  }, []);

  useEffect(() => {
    tawkWidgetToggle(true);
  }, []);

  useEffect(() => {
    if (firstName && lastName && websiteSlug && username) {
      const publishedDomain =
        websiteSlug +
        "." +
        getDomain(document.domain) +
        (window.location.port ? ":" + window.location.port : "");
      setTawkAttributes(firstName + " " + lastName, username, publishedDomain);
    }
  }, [firstName, lastName, username, websiteSlug, getDomain]);

  useEffect(() => {
    if (websiteUniqueId && ownerId) {
      dispatch(getWebsiteReportDefault(ownerId, websiteUniqueId));
      if (subscription && subscription.isActive) {
        dispatch(getWebsiteWeeklyReportDefault(ownerId, websiteUniqueId));
        dispatch(getWebsiteMonthlyReportDefault(ownerId, websiteUniqueId));
      }
    }
  }, [dispatch, websiteUniqueId, ownerId, website, subscription]);

  useEffect(() => {
    if (dailyReports) {
      setStateDailyReport(dailyReports);
      setHeaderLabel("Yesterday's");
      setChartLabel("Last 7 Days");
      setSimpleLabel("day");
      setColumnLabel("Date");
      let viewsData: DataType[] = [];
      let engagementData: DataType[] = [];
      let missingDays = 7 - dailyReports.length;
      for (let x = 0; x <= missingDays; x++) {
        viewsData[x] = {
          time: "d" + x,
          value: 0,
          category: "Views",
        };
        engagementData[x] = {
          time: "d" + x,
          value: 0,
          category: "Engagement",
        };
      }
      let i = missingDays;
      dailyReports.forEach((dailyReport) => {
        viewsData[i] = {
          time: dailyReport.logDate,
          value: dailyReport.dailyTotalVisitorsCount,
          category: "Views",
        };
        engagementData[i] = {
          time: dailyReport.logDate,
          value: dailyReport.dailyTotalEngagedCount,
          category: "Engagement",
        };
        i++;
      });
      setData(viewsData.concat(engagementData));
      if (
        dailyReports[dailyReports.length - 1] &&
        dailyReports[dailyReports.length - 1].locations
      ) {
        handleLocationDisplayData(
          dailyReports[dailyReports.length - 1].locations
        );
      }
    }
  }, [dailyReports]);

  useEffect(() => {
    dispatch(logActivity("VIEW", reportsPage, username));
  }, [dispatch, username]);

  const config = {
    data,
    xField: "time",
    yField: "value",
    seriesField: "category",
    smooth: true,
    lineWidth: 2,
    strokeOpacity: 0.7,
    point: {
      size: 5,
      shape: "circle",
    },
    color: ["#3185fc", "#3bb273"],
  };

  const handleLocationDisplayData = (rawLocations: ReportLocation[]) => {
    let orderedLocations = [...rawLocations];
    orderedLocations.sort(
      (locA, locB) => locB.visitorsCount - locA.visitorsCount
    );

    if (orderedLocations.length > 4) {
      let othersVisitorCount = 0;
      let othersEngagementCount = 0;
      for (var i = 4; i <= orderedLocations.length; i++) {
        if (orderedLocations[i] && orderedLocations[i].visitorsCount) {
          othersVisitorCount =
            othersVisitorCount + orderedLocations[i].visitorsCount;
          othersEngagementCount =
            othersEngagementCount + orderedLocations[i].engagedCount;
        }
        orderedLocations.splice(i, 1);
      }
      orderedLocations.push({
        country: "etc.",
        city: "Others",
        visitorsCount: othersVisitorCount,
        engagedCount: othersEngagementCount,
      });
    }

    setLocations(orderedLocations);
  };

  const handleView = (view: string) => {
    setView(view);

    let reportToDisplay: DailyReportWeb[] | null = [];
    let totalPlots = 0;
    let prefix = "";

    if (view === "daily" && dailyReports) {
      totalPlots = 7;
      reportToDisplay = dailyReports;
      prefix = "-d";
      setStateDailyReport(dailyReports);
      setHeaderLabel(t("reports_header_label_1"));
      setChartLabel(t("reports_chart_label_1"));
      setSimpleLabel(t("reports_simple_label_1"));
      setColumnLabel(t("reports_column_label_1"));
      handleLocationDisplayData(
        dailyReports[dailyReports.length - 1].locations
      );
    } else if (view === "weekly" && weeklyReports) {
      totalPlots = 12;
      reportToDisplay = weeklyReports;
      prefix = "-w";
      setStateDailyReport(weeklyReports);
      setHeaderLabel(t("reports_header_label_2"));
      setChartLabel(t("reports_chart_label_2"));
      setSimpleLabel(t("reports_simple_label_2"));
      setColumnLabel(t("reports_column_label_2"));
      handleLocationDisplayData(
        weeklyReports[weeklyReports.length - 1].locations
      );
    } else if (view === "monthly" && monthlyReports) {
      totalPlots = 12;
      reportToDisplay = monthlyReports;
      prefix = "-m";
      setStateDailyReport(monthlyReports);
      setHeaderLabel(t("reports_header_label_3"));
      setChartLabel(t("reports_chart_label_3"));
      setSimpleLabel(t("reports_simple_label_3"));
      setColumnLabel(t("reports_column_label_3"));
      handleLocationDisplayData(
        monthlyReports[monthlyReports.length - 1].locations
      );
    }

    if (reportToDisplay) {
      let viewsData: DataType[] = [];
      let engagementData: DataType[] = [];
      let missingDays = totalPlots - reportToDisplay.length;
      let y = 0;
      for (let x = missingDays; x >= 0; x--) {
        viewsData[y] = {
          time: prefix + x,
          value: 0,
          category: "Views",
        };
        engagementData[y] = {
          time: prefix + x,
          value: 0,
          category: "Engagement",
        };
        y++;
      }
      let i = missingDays;
      reportToDisplay.forEach((dailyReport) => {
        viewsData[i] = {
          time: dailyReport.logDate,
          value: dailyReport.dailyTotalVisitorsCount,
          category: "Views",
        };
        engagementData[i] = {
          time: dailyReport.logDate,
          value: dailyReport.dailyTotalEngagedCount,
          category: "Engagement",
        };
        i++;
      });
      setData(viewsData.concat(engagementData));
    }
  };

  function OffCanvasDisplay({ label }: Props): JSX.Element {
    return (
      <OffcanvasProvider>
        <Trigger
          styles={{
            boxShadow: "0px 2px 2px rgb(0 0 0 / 20%)",
            backgroundColor: "transparent",
            borderColor: "#3185fc",
            color: "#3185fc",
            borderRadius: "32px",
          }}
        >
          {label}
        </Trigger>
        <Offcanvas
          title=""
          position="bottom"
          backdrop={false}
          children={
            <div className={styles["admin__off-canvas"]}>
              <div className={styles["admin__off-canvas-main"]}>
                <h5>
                  {t("reports_paragraph_1")} {label} {t("reports_paragraph_2")}
                </h5>
                <h6>{t("reports_paragraph_3")}</h6>
                <Link
                  to={"/admin/upgrade"}
                  onClick={() => {
                    dispatch(
                      logActivity("GO TO", "Reports", username, "Upgrade Page")
                    );
                  }}
                  className={styles["admin__off-canvas-button"]}
                >
                  {t("reports_click_to_upgrade")}
                </Link>
              </div>
            </div>
          }
          styles={{
            marginLeft: 300,
            height: "82%",
            background:
              "linear-gradient(360deg, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%) rgba(255,255,255,.5)",
            borderTop: "2px solid #EEE",
          }}
        />
      </OffcanvasProvider>
    );
  }

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <BarChartRounded />
          <h3>{t("reports")}</h3>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        {website.publishedAt &&
        stateDailyReport &&
        stateDailyReport.length > 0 ? (
          <div className={styles["admin__content-container-column"]}>
            <div className={styles["admin__content-subheader-container"]}>
              <h6>
                {headerLabel} {t("reports_views_label")}
              </h6>
              {isDesktop ? (
                <div className={styles["admin__content-header-buttons"]}>
                  <Button
                    label={t("reports_button_label_daily")}
                    onClick={() => {
                      handleView("daily");
                    }}
                    variant={
                      !view || view === "daily" ? "primary" : "outline-primary"
                    }
                  />
                  {subscription && subscription.isActive ? (
                    <>
                      <Button
                        label={t("reports_button_label_weekly")}
                        onClick={() => {
                          handleView("weekly");
                        }}
                        variant={
                          view === "weekly" ? "primary" : "outline-primary"
                        }
                      />
                      <Button
                        label={t("reports_button_label_monthly")}
                        onClick={() => {
                          handleView("monthly");
                        }}
                        variant={
                          view === "monthly" ? "primary" : "outline-primary"
                        }
                      />
                    </>
                  ) : (
                    <>
                      <OffCanvasDisplay
                        label={t("reports_button_label_weekly")}
                      />
                      <OffCanvasDisplay
                        label={t("reports_button_label_monthly")}
                      />
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className={
                styles[
                  isDesktop
                    ? "admin__content-container-row"
                    : "admin__content-container-column"
                ]
              }
            >
              <div
                className={styles["admin__content-card--small"]}
                style={{ width: isDesktop ? "auto" : "fit-content" }}
              >
                <div className={styles["admin__card-header"]}>
                  <h5>{t("reports_header_visitors")}</h5>
                </div>
                <div className={styles["reports_aquisition"]}>
                  <div
                    className={styles["reports_aquisition-header"]}
                    style={{
                      width: isDesktop ? "auto" : "100%",
                      marginBottom: isDesktop ? "0" : "12px",
                    }}
                  >
                    <div className={styles["reports_aquisition-indicator"]}>
                      <div className={styles["visitor-indicator"]}>
                        <div className={styles["visitor-indicator-icon"]}>
                          <VisibilityRounded />
                        </div>
                        {
                          stateDailyReport[stateDailyReport.length - 1]
                            .dailyTotalVisitorsCount
                        }
                      </div>
                      <div className={styles["visitor-info"]}>
                        <span>{t("reports_views_label")}</span>
                        {
                          stateDailyReport[stateDailyReport.length - 1]
                            .dailyTotalVisitorsCount
                        }{" "}
                        {t("reports_views_p_1")} <br /> <br />
                        {stateDailyReport[stateDailyReport.length - 1]
                          .dailyTotalVisitorsChange >= 0 ? (
                          <span className={styles["visitor-info-green"]}>
                            {stateDailyReport[
                              stateDailyReport.length - 1
                            ].dailyTotalVisitorsChange.toString() === "Infinity"
                              ? ""
                              : stateDailyReport[stateDailyReport.length - 1]
                                  .dailyTotalVisitorsChange + "% "}
                            {t("reports_views_p_2")} {simpleLabel}{" "}
                            {t("reports_views_p_3")}
                          </span>
                        ) : (
                          <span className={styles["visitor-info-red"]}>
                            {
                              stateDailyReport[stateDailyReport.length - 1]
                                .dailyTotalVisitorsChange
                            }
                            {t("reports_views_p_4")} {simpleLabel}{" "}
                            {t("reports_views_p_5")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles["reports_aquisition-header"]}
                    style={{
                      width: isDesktop ? "auto" : "100%",
                      marginBottom: isDesktop ? "0" : "12px",
                    }}
                  >
                    <div className={styles["reports_aquisition-indicator"]}>
                      <div className={styles["visitor-indicator"]}>
                        <div className={styles["visitor-indicator-icon"]}>
                          <TouchAppRounded />
                        </div>
                        {
                          stateDailyReport[stateDailyReport.length - 1]
                            .dailyTotalEngagedCount
                        }
                      </div>
                      <div className={styles["visitor-info"]}>
                        <span>{t("reports_engagements_label")}</span>
                        {
                          stateDailyReport[stateDailyReport.length - 1]
                            .dailyTotalEngagedCount
                        }{" "}
                        {t("reports_engagements_p_1")} <br />
                        <br />
                        {stateDailyReport[stateDailyReport.length - 1]
                          .dailyTotalEngagedChanged >= 0 ? (
                          <span className={styles["visitor-info-green"]}>
                            {stateDailyReport[
                              stateDailyReport.length - 1
                            ].dailyTotalEngagedChanged.toString() === "Infinity"
                              ? ""
                              : stateDailyReport[stateDailyReport.length - 1]
                                  .dailyTotalEngagedChanged + "% "}
                            {t("reports_engagements_p_2")} {simpleLabel}{" "}
                            {t("reports_engagements_p_3")}
                          </span>
                        ) : (
                          <span className={styles["visitor-info-red"]}>
                            {
                              stateDailyReport[stateDailyReport.length - 1]
                                .dailyTotalEngagedChanged
                            }
                            {t("reports_engagements_p_4")} {simpleLabel}{" "}
                            {t("reports_engagements_p_5")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles["admin__content-card--small"]}
                style={{ width: isDesktop ? "auto" : "fit-content" }}
              >
                <div className={styles["admin__card-header"]}>
                  <h5>{t("reports_devices_label")}</h5>
                </div>
                <div className={styles["reports_aquisition"]}>
                  <div
                    className={styles["reports_aquisition-header"]}
                    style={{
                      width: isDesktop ? "auto" : "100%",
                      marginBottom: isDesktop ? "0" : "12px",
                    }}
                  >
                    <div className={styles["reports_aquisition-indicator"]}>
                      <div className={styles["visitor-indicator"]}>
                        <div className={styles["visitor-indicator-icon"]}>
                          <ComputerRounded />
                        </div>
                        {
                          stateDailyReport[stateDailyReport.length - 1]
                            .dailyTotalDesktopVisitorsCount
                        }
                      </div>
                      <div className={styles["visitor-info"]}>
                        <span>{t("reports_devices_desktop_label")}</span>
                        {
                          stateDailyReport[stateDailyReport.length - 1]
                            .dailyTotalDesktopVisitorsCount
                        }{" "}
                        {t("reports_devices_p_1")}
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles["reports_aquisition-header"]}
                    style={{
                      width: isDesktop ? "auto" : "100%",
                      marginBottom: isDesktop ? "0" : "12px",
                    }}
                  >
                    <div className={styles["reports_aquisition-indicator"]}>
                      <div className={styles["visitor-indicator"]}>
                        <div className={styles["visitor-indicator-icon"]}>
                          <PhoneIphoneRounded />
                        </div>
                        {
                          stateDailyReport[stateDailyReport.length - 1]
                            .dailyTotalMobileVisitorsCount
                        }
                      </div>
                      <div className={styles["visitor-info"]}>
                        <span>{t("reports_devices_mobile_label")}</span>
                        {
                          stateDailyReport[stateDailyReport.length - 1]
                            .dailyTotalMobileVisitorsCount
                        }{" "}
                        {t("reports_devices_p_2")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles["admin__content-card--medium"]}
                style={{
                  marginRight: "unset",
                  width: isDesktop ? "auto" : "fit-content",
                }}
              >
                <div className={styles["admin__card-header"]}>
                  <h5>{t("reports_location_label")}</h5>
                </div>
                <div className={styles["reports_aquisition"]}>
                  <div className={styles["reports_aquisition-table"]}>
                    <Table hover>
                      <thead style={{ fontSize: "16px", fontWeight: "bold" }}>
                        <tr>
                          <td>{t("reports_location_table_header_1")}</td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {t("reports_location_table_header_2")}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {t("reports_location_table_header_3")}
                          </td>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "15px" }}>
                        {locations ? (
                          <>
                            {locations.map((location) => (
                              <tr key={location.country + location.city}>
                                <td>
                                  {location.city}, {location.country}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {location.visitorsCount}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {location.engagedCount}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            {data ? (
              isDesktop ? (
                <div
                  className={styles["admin__content-card--big"]}
                  style={{ marginTop: "16px" }}
                >
                  <div className={styles["admin__card-header"]}>
                    <h5>
                      {chartLabel} {t("reports_header_visitors")}
                    </h5>
                  </div>
                  <div className={styles["admin__line-chart-container"]}>
                    <div className={styles["admin__line-chart-graph"]}>
                      {data ? <Line {...config} /> : <></>}
                    </div>
                    {stateDailyReport ? (
                      <Table hover>
                        <thead>
                          <tr>
                            <td>{columnLabel}</td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {t("reports_views_label")}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {t("reports_main_table_header_1")}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {t("reports_main_table_header_2")}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {t("reports_main_table_header_3")}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {t("reports_main_table_header_4")}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {t("reports_main_table_header_5")}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {stateDailyReport
                            .slice()
                            .reverse()
                            .map((report) => (
                              <tr key={report.logDate}>
                                <td>{report.logDate}</td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {report.dailyTotalVisitorsCount}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {report.dailyTotalVisitorsChange.toString() ===
                                  "Infinity"
                                    ? "-"
                                    : report.dailyTotalVisitorsChange + " %"}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {report.dailyTotalEngagedCount}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {report.dailyTotalEngagedChanged.toString() ===
                                  "Infinity"
                                    ? "-"
                                    : report.dailyTotalEngagedChanged + " %"}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {report.dailyTotalDesktopVisitorsCount}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {report.dailyTotalMobileVisitorsCount}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <span style={{ width: "100%" }}>
                  {t("reports_main_table_notice")}
                </span>
              )
            ) : (
              <></>
            )}
          </div>
        ) : (
          <>
            <div className={styles["report-notification"]}>
              <div
                className="text-muted"
                style={{ marginTop: "4px", fontSize: ".9rem" }}
              >
                {t("reports_not_available")}
              </div>
            </div>
          </>
        )}
      </div>
      <TawkMessengerReact
        propertyId="63b56d68c2f1ac1e202ba11d"
        widgetId="1glub6oqr"
      />
    </>
  );
};
