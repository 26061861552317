import React from "react";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";

export class SocialMedia {
  constructor(
    readonly key: string,
    readonly label: string,
    readonly icon: JSX.Element,
    readonly urlPlaceholder: string
  ) {}
}

export const SocialMediaList = [
  new SocialMedia(
    "facebook",
    "Facebook",
    <Facebook />,
    "www.facebook.com/gotrygoodweb"
  ),
  new SocialMedia(
    "instagram",
    "Instagram",
    <Instagram />,
    "www.instagram.com/goodweb"
  ),
  new SocialMedia(
    "twitter",
    "Twitter",
    <Twitter />,
    "www.twitter.com/TryGoodWeb"
  ),
  new SocialMedia(
    "linkedin",
    "LinkedIn",
    <LinkedIn />,
    "www.linkedin.com/company/trygoodweb"
  ),
];
