import React from "react";

import { SvgIconComponent } from "@material-ui/icons";
import { Link } from "react-router-dom";

import styles from "./Button.module.scss";
import { Spinner } from "react-bootstrap";

type Props = {
  isDisabled?: boolean;
  isFlat?: boolean;
  label?: string;
  variant?: string;
  style?: React.CSSProperties; //Provided for custom styling
  size?: string;
  to?: string;
  withinDomain?: boolean;
  newWindow?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  icon?: SvgIconComponent;
  onClick?: () => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  isLoading?: boolean;
};

export const Button: React.FunctionComponent<Props> = ({
  isDisabled,
  isFlat,
  label,
  variant,
  style,
  size,
  to,
  withinDomain = true,
  newWindow,
  type,
  icon,
  onClick,
  onKeyUp,
  isLoading,
}) => {
  var Icon = null;
  if (icon) {
    Icon = icon;
  }

  if (variant === undefined) {
    variant = "button--default";
  } else {
    variant = "button--" + variant;
  }

  if (size === undefined) {
    size = "button--default";
  } else {
    size = "button--" + size;
  }

  const shadow = isFlat ? "button--flat" : "button--shadow";

  return (
    <>
      {to === undefined ? (
        <button
          className={`${styles[size]} ${styles[variant]} ${styles[shadow]}`}
          disabled={isDisabled}
          onClick={onClick}
          onKeyUp={onKeyUp}
          style={style}
          type={type}
        >
          <span>
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : Icon ? (
              <Icon />
            ) : (
              <></>
            )}
            {label ? (
              <label onClick={onClick} onKeyUp={onKeyUp}>
                {label}
              </label>
            ) : (
              ""
            )}
          </span>
        </button>
      ) : withinDomain ? (
        <Link
          className={`${styles["button"]} ${styles[size]} ${styles[variant]} ${styles[shadow]}`}
          style={style}
          to={to}
          onClick={onClick}
          onKeyUp={onKeyUp}
        >
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : Icon ? (
            <Icon />
          ) : (
            <></>
          )}
          {label ? <label>{label}</label> : ""}
        </Link>
      ) : (
        <a
          className={`${styles["button"]} ${styles[size]} ${styles[variant]} ${styles[shadow]}`}
          href={"http://" + to}
          onClick={onClick}
          onKeyUp={onKeyUp}
          target={newWindow ? "_blank" : "_self"}
          style={style}
        >
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : Icon ? (
            <Icon />
          ) : (
            <></>
          )}
          {label ? <label>{label}</label> : ""}
        </a>
      )}
    </>
  );
};
