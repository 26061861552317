import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type FormContainerProperties = {
  formContainerStyle: string;
  formConfigProperty: {
    type: string;
    value: string;
  };
  dimension: { width?: number; height?: number };
};

export function FormContainer({
  properties: { formContainerStyle, formConfigProperty, dimension },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<FormContainerProperties>): JSX.Element {
  return (
    <div
      {...props}
      ref={innerRef}
      className={
        className +
        "content-container form-container form-container-style-" +
        formContainerStyle
      }
      style={{
        width: dimension && dimension.width ? dimension.width : "",
        height: dimension && dimension.height ? dimension.height : "",
      }}
    >
      <form className="section">
        <input type="hidden" id="sec201d" value={formConfigProperty.value} />
        {children}
      </form>
    </div>
  );
}

export const formContainerKey = "form-container";

elementComponentRegistry.register(formContainerKey, FormContainer);
