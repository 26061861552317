import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type SmallTextProperties = {
  smallTextStyle: { style: string };
  smallTextText: { text: string };
};

export function SmallText({
  properties: { smallTextStyle, smallTextText },
  className,
  ...props
}: ElementComponentProps<SmallTextProperties>): JSX.Element {
  return (
    <small
      {...props}
      className={
        className +
        "form-text text-muted small-text-style" +
        smallTextStyle.style
      }
    >
      {smallTextText.text}
    </small>
  );
}

export const smallTextElementKey = "small-text";

elementComponentRegistry.register(smallTextElementKey, SmallText);
