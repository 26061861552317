import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type LinkProperties = {
  link: { text: string; url: string; openNewTab?: boolean; icon?: string };
};

export function Link({
  properties: { link },
  className,
  ...props
}: ElementComponentProps<LinkProperties>): JSX.Element {
  return (
    <a {...props} className={className} href={link.url}>
      {link.text}
    </a>
  );
}

export const linkElementKey = "link";

elementComponentRegistry.register(linkElementKey, Link);
