import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "./types";
import { propertySet } from "editor/states/layout";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import { ReactComponent as CardsRight } from "../../img/CardsRight.svg";
import { ReactComponent as CardsCenter } from "../../img/CardsCenter.svg";
import { ReactComponent as CardsLeft } from "../../img/CardsLeft.svg";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

export class ImageStyleProperty implements IProperty {
  constructor(readonly imageStyle: any) {}

  render(elementId: string, propertyKey: string) {
    return (
      <ImageStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    return new ImageStyleProperty(value);
  }
}

const ImageStylePropertyComponent: PropertyComponent<ImageStyleProperty> = ({
  elementId,
  propertyKey,
  imageStyle,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const radios = [
    { name: t("dialog_78"), value: "1", icon: <CardsLeft /> },
    { name: t("dialog_79"), value: "2", icon: <CardsCenter /> },
    { name: t("dialog_80"), value: "3", icon: <CardsRight /> },
  ];

  const handleStyleChange = (value: string) => {
    dispatch(
      propertySet(elementId, propertyKey, new ImageStyleProperty(value))
    );
  };

  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <Form className="form">
        <div className="form__group">
          <label htmlFor="style">{t("dialog_84")}</label>
          <ButtonGroup className="mb-2 navbar-style-group">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                type="radio"
                variant="primary"
                name="style"
                value={radio.value}
                checked={imageStyle.imageStyle === radio.value}
                className="button-radio-hidden"
                onClick={(e: any) => handleStyleChange(e.target.value)}
              >
                {radio.icon}
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
      </Form>
    </Formik>
  );
};
