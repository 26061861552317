import React from "react";

import { FooterSocialNavItem } from "common/elements";
import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";

export type FooterSocialNavProperties = {
  socialLinks: SocialLinkProperty[];
  style: string;
  isProduction: boolean;
};

export function FooterSocialNav({
  properties: { socialLinks, style, isProduction },
  className,
  innerRef,
  ...props
}: ElementComponentProps<FooterSocialNavProperties>): JSX.Element {
  return (
    <nav
      {...props}
      ref={innerRef}
      className={`${className} social-nav style-${style}`}
    >
      {socialLinks ? (
        socialLinks.map((link: SocialLinkProperty, index: number) => (
          <FooterSocialNavItem
            key={index}
            id={link.socialMedia.key}
            label={link.socialMedia.label}
            icon={link.socialMedia.icon}
            url={link.url}
            openNewTab={link.openNewTab}
            style={style}
            isProduction={isProduction}
          />
        ))
      ) : (
        <></>
      )}
    </nav>
  );
}

export const navElementKey = "footer-social-nav";

elementComponentRegistry.register(navElementKey, FooterSocialNav);
