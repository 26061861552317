import { PlanCategoryEnum } from "admin/SelectStep0Form";
import { SegmentClient } from "index";

export type GetUserProviderAction = {
  type: "AUTH_USER_PROVIDER_REQUEST";
};

export const getUserProvider = (): Action => {
  return {
    type: "AUTH_USER_PROVIDER_REQUEST",
  };
};

export type GetUserProviderSuccessAction = {
  type: "AUTH_USER_PROVIDER_SUCCESS";
  provider: string;
  isUserVerified: boolean;
};

export const getUserProviderSuccess = (
  provider: string,
  isUserVerified: boolean
): Action => {
  return {
    type: "AUTH_USER_PROVIDER_SUCCESS",
    provider,
    isUserVerified,
  };
};

export type GetUserProviderFailureAction = {
  type: "AUTH_USER_PROVIDER_FAILURE";
};

export const getUserProviderFailure = (): Action => {
  return {
    type: "AUTH_USER_PROVIDER_FAILURE",
  };
};

export type GetUnplashKeyAction = {
  type: "AUTH_UNSPLASH_KEY_REQUEST";
};

export const getUnplashKey = (): Action => {
  return {
    type: "AUTH_UNSPLASH_KEY_REQUEST",
  };
};

export type GetUnplashKeySuccessAction = {
  type: "AUTH_UNSPLASH_KEY_SUCCESS";
  unplashKey: string;
};

export const getUnplashKeySuccess = (unplashKey: string): Action => {
  return {
    type: "AUTH_UNSPLASH_KEY_SUCCESS",
    unplashKey,
  };
};

export type GetUnplashKeyFailureAction = {
  type: "AUTH_UNSPLASH_KEY_FAILURE";
};

export const getUnplashKeyFailure = (): Action => {
  return {
    type: "AUTH_UNSPLASH_KEY_FAILURE",
  };
};

export type GetGoogleIdAction = {
  type: "AUTH_GOOGLE_ID_REQUEST";
};

export const getGoogleId = (): Action => {
  return {
    type: "AUTH_GOOGLE_ID_REQUEST",
  };
};

export type GetGoogleIdSuccessAction = {
  type: "AUTH_GOOGLE_ID_SUCCESS";
  googleId: string;
};

export const getGoogleIdSuccess = (googleId: string): Action => {
  return {
    type: "AUTH_GOOGLE_ID_SUCCESS",
    googleId,
  };
};

export type GetGoogleIdFailureAction = {
  type: "AUTH_GOOGLE_ID_FAILURE";
};

export const getGoogleIdFailure = (): Action => {
  return {
    type: "AUTH_GOOGLE_ID_FAILURE",
  };
};

export type FetchRedirectUriAction = {
  type: "AUTH_REDIRECT_URI";
};

export const fetchRedirectUri = (): Action => {
  return {
    type: "AUTH_REDIRECT_URI",
  };
};

export type FetchRedirectUriSuccessAction = {
  type: "AUTH_REDIRECT_URI_SUCCESS";
  redirectUri: string;
};

export const fetchRedirectUriSuccess = (redirectUri: string): Action => {
  return {
    type: "AUTH_REDIRECT_URI_SUCCESS",
    redirectUri,
  };
};

export type FetchRedirectUriFailureAction = {
  type: "AUTH_REDIRECT_URI_FAILURE";
};

export const fetchRedirectUriFailure = (): Action => {
  return {
    type: "AUTH_REDIRECT_URI_FAILURE",
  };
};

export type FetchBaseUrlAction = {
  type: "AUTH_BASE_URL";
};

export const fetchBaseUrl = (): Action => {
  return {
    type: "AUTH_BASE_URL",
  };
};

export type FetchBaseUrlSuccessAction = {
  type: "AUTH_BASE_URL_SUCCESS";
  baseUrl: string;
};

export const fetchBaseUrlSuccess = (baseUrl: string): Action => {
  return {
    type: "AUTH_BASE_URL_SUCCESS",
    baseUrl,
  };
};

export type FetchBaseUrlFailureAction = {
  type: "AUTH_BASE_URL_FAILURE";
};

export const fetchBaseUrlFailure = (): Action => {
  return {
    type: "AUTH_BASE_URL_FAILURE",
  };
};

export type LoginRequestAction = {
  type: "AUTH_LOGIN_REQUEST";
  username: string;
  password: string;
  accessToken: string | undefined;
};

export const login = (
  username: string,
  password: string,
  accessToken: string | undefined
): Action => {
  return {
    type: "AUTH_LOGIN_REQUEST",
    username,
    password,
    accessToken,
  };
};

export type PasswordResetRequestAction = {
  type: "AUTH_PASSWORD_RESET_REQUEST";
  username: string;
  callback: () => void;
};

export const resetPassword = (
  username: string,
  callback: () => void
): Action => {
  return {
    type: "AUTH_PASSWORD_RESET_REQUEST",
    username,
    callback,
  };
};

export type PasswordResetAction = {
  type: "AUTH_RESET_PASSWORD_REQUEST";
  username: string;
  password: string;
  token: string;
  callback: (isPasswordResetSuccess: boolean) => void;
};

export const resetPasswordWithToken = (
  username: string,
  password: string,
  token: string,
  callback: (isPasswordResetSuccess: boolean) => void
): Action => {
  return {
    type: "AUTH_RESET_PASSWORD_REQUEST",
    username,
    password,
    token,
    callback,
  };
};

export type LoginWithAccessTokenRequestAction = {
  type: "AUTH_LOGIN_WITH_ACCESSTOKEN_REQUEST";
  accessToken: string | undefined;
  callback: (userId: number, firstName: string, lastName: string) => void;
};

export const loginWithAccessToken = (
  accessToken: string | undefined,
  callback: (userId: number, firstName: string, lastName: string) => void
): Action => {
  return {
    type: "AUTH_LOGIN_WITH_ACCESSTOKEN_REQUEST",
    accessToken,
    callback,
  };
};

export type ResendEmailVerifyAction = {
  type: "AUTH_RESEND_EMAIL_VERIFY";
  username: string;
  callback: (isSent: boolean) => void;
};

export const resendEmailVerify = (
  username: string,
  callback: (isSent: boolean) => void
): Action => {
  return {
    type: "AUTH_RESEND_EMAIL_VERIFY",
    username,
    callback,
  };
};

export type VerifyTokenAction = {
  type: "AUTH_VERIFY_TOKEN";
  token: string;
  username: string;
  callback: (isVerified: boolean) => void;
};

export const verifyToken = (
  token: string,
  username: string,
  callback: (isVerified: boolean) => void
): Action => {
  return {
    type: "AUTH_VERIFY_TOKEN",
    token,
    username,
    callback,
  };
};

export type VerifyTokenSuccessAction = {
  type: "AUTH_VERIFY_TOKEN_SUCCESS";
  isVerified: any;
};

export const verifyTokenSuccess = (isVerified: any): Action => {
  return {
    type: "AUTH_VERIFY_TOKEN_SUCCESS",
    isVerified,
  };
};

export type LoginWithAccessTokenSuccessAction = {
  type: "AUTH_LOGIN_WITH_ACCESSTOKEN_SUCCESS";
  userId: number;
  firstName: string;
  lastName: string;
  isSuper: boolean;
  countryCode?: string;
  country?: string;
  websiteSlug?: string;
  username?: string;
  token?: string;
  websiteUniqueId?: string;
  provider?: string;
};

export const loginWithAccessTokenSuccess = (
  userId: number,
  firstName: string,
  lastName: string,
  isSuper: boolean,
  countryCode?: string,
  country?: string,
  websiteSlug?: string,
  username?: string,
  token?: string,
  websiteUniqueId?: string,
  provider?: string
): Action => {
  return {
    type: "AUTH_LOGIN_WITH_ACCESSTOKEN_SUCCESS",
    userId,
    firstName,
    lastName,
    isSuper,
    countryCode,
    country,
    websiteSlug,
    username,
    token,
    websiteUniqueId,
    provider,
  };
};

export type LoginSuccessAction = {
  type: "AUTH_LOGIN_SUCCESS";
  userId: number;
  firstName: string;
  lastName: string;
  isSuper: boolean;
  countryCode?: string;
  country?: string;
  websiteSlug?: string;
  username?: string;
  token?: string;
  websiteUniqueId?: string;
};

export const loginSuccess = (
  userId: number,
  firstName: string,
  lastName: string,
  isSuper: boolean,
  countryCode?: string,
  country?: string,
  websiteSlug?: string,
  username?: string,
  token?: string,
  websiteUniqueId?: string
): Action => {
  return {
    type: "AUTH_LOGIN_SUCCESS",
    userId,
    firstName,
    lastName,
    isSuper,
    countryCode,
    country,
    websiteSlug,
    username,
    token,
    websiteUniqueId,
  };
};

export type LoginFailureAction = {
  type: "AUTH_LOGIN_FAILURE";
};

export const loginFailure = (): Action => {
  return {
    type: "AUTH_LOGIN_FAILURE",
  };
};

export type LogoutRequestAction = {
  type: "AUTH_LOGOUT_REQUEST";
};

export const logout = (): Action => {
  return {
    type: "AUTH_LOGOUT_REQUEST",
  };
};

export type LogoutSuccessAction = {
  type: "AUTH_LOGOUT_SUCCESS";
};

export const logoutSuccess = (): Action => {
  return {
    type: "AUTH_LOGOUT_SUCCESS",
  };
};

export type LogoutFailureAction = {
  type: "AUTH_LOGOUT_FAILURE";
};

export const logoutFailure = (): Action => {
  return {
    type: "AUTH_LOGOUT_FAILURE",
  };
};

export type SignUpRequestAction = {
  type: "AUTH_SIGN_UP_REQUEST";
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  countryCode?: string;
  country?: string;
};

export const signUp = (
  username: string,
  password: string,
  firstName: string,
  lastName: string,
  countryCode?: string,
  country?: string
): Action => {
  return {
    type: "AUTH_SIGN_UP_REQUEST",
    username,
    password,
    firstName,
    lastName,
    countryCode,
    country,
  };
};

export type UpdateUserPlanAction = {
  type: "AUTH_UPDATE_USER_PLAN_REQUEST";
  id: number;
  plan: string;
  callback: () => void;
};

export const updateUserPlan = (
  id: number,
  plan: string,
  callback: () => void
): Action => {
  return {
    type: "AUTH_UPDATE_USER_PLAN_REQUEST",
    id,
    plan,
    callback,
  };
};

export type GetDlocalConfigAction = {
  type: "AUTH_GET_DLOCAL_CONFIG_REQUEST";
  userName: string;
  countryCode: string;
};

export const getDlocalConfig = (
  userName: string,
  countryCode: string
): Action => {
  return {
    type: "AUTH_GET_DLOCAL_CONFIG_REQUEST",
    userName,
    countryCode,
  };
};

export type ProcessPaypalPaymentRequestAction = {
  type: "AUTH_PROCESS_PAYPAL_PAYMENT_REQUEST";
  userId: number;
  amount: number;
  currency: string;
  countryCode: string;
  email: string;
  orderBody: string;
  orderId: string;
  orderStatus: string;
  subscriptionId: string;
  subscriptionFrequency: PlanCategoryEnum;
  callback: (status: boolean) => void;
};

export const processPaypalPaymentRequest = (
  userId: number,
  amount: number,
  currency: string,
  countryCode: string,
  email: string,
  orderBody: string,
  orderId: string,
  orderStatus: string,
  subscriptionId: string,
  subscriptionFrequency: PlanCategoryEnum,
  callback: (status: boolean) => void
): Action => {
  return {
    type: "AUTH_PROCESS_PAYPAL_PAYMENT_REQUEST",
    userId,
    amount,
    currency,
    countryCode,
    email,
    orderBody,
    orderId,
    orderStatus,
    subscriptionId,
    subscriptionFrequency,
    callback,
  };
};

export type StartDlocalPaymentProcessAction = {
  type: "AUTH_START_DLOCAL_PAYMENT_PROCESS_REQUEST";
  userId: number;
  amount: number;
  currency: string;
  countryCode: string;
  billingName: string;
  email: string;
  documentNumber: string;
  cardToken: string;
  subscriptionFrequency: PlanCategoryEnum;
};

export const startDlocalPaymentProcess = (
  userId: number,
  amount: number,
  currency: string,
  countryCode: string,
  billingName: string,
  email: string,
  documentNumber: string,
  cardToken: string,
  subscriptionFrequency: PlanCategoryEnum
): Action => {
  return {
    type: "AUTH_START_DLOCAL_PAYMENT_PROCESS_REQUEST",
    userId,
    amount,
    currency,
    countryCode,
    billingName,
    email,
    documentNumber,
    cardToken,
    subscriptionFrequency,
  };
};

export type HandlePaymentProcessResultAction = {
  type: "AUTH_HANDLE_PAYMENT_PROCESS_RESULT";
  status: string;
  message: string;
};

export const handlePaymentProcessResult = (
  status: string,
  message: string
): Action => {
  return {
    type: "AUTH_HANDLE_PAYMENT_PROCESS_RESULT",
    status,
    message,
  };
};

export type ResetPaymentStatusAction = {
  type: "AUTH_RESET_PAYMENT_STATUS_ACTION";
};

export const resetPaymentStatus = (): Action => {
  return {
    type: "AUTH_RESET_PAYMENT_STATUS_ACTION",
  };
};

export type GetPaymentSettingsForCountryAction = {
  type: "AUTH_GET_PAYMENT_SETTINGS_FOR_COUNTRY_REQUEST";
  countryCode: string;
  callback: (countryCode: string, isDlocal: boolean, isPaypal: boolean) => void;
};

export const getPaymentSettingsForCountry = (
  countryCode: string,
  callback: (countryCode: string, isDlocal: boolean, isPaypal: boolean) => void
): Action => {
  return {
    type: "AUTH_GET_PAYMENT_SETTINGS_FOR_COUNTRY_REQUEST",
    countryCode,
    callback,
  };
};

export type GetPaymentSettingsForCountrySuccessAction = {
  type: "AUTH_GET_PAYMENT_SETTINGS_FOR_COUNTRY_SUCCESS";
  isDlocal: boolean;
  isPaypal: boolean;
  preferredPaymentGateway: string;
};

export const getPaymentSettingsForCountrySuccess = (
  isDlocal: boolean,
  isPaypal: boolean,
  preferredPaymentGateway: string
): Action => {
  return {
    type: "AUTH_GET_PAYMENT_SETTINGS_FOR_COUNTRY_SUCCESS",
    isDlocal,
    isPaypal,
    preferredPaymentGateway,
  };
};

export type GetDlocalCountryReferenceAction = {
  type: "AUTH_GET_DLOCAL_COUNTRY_REFERENCE_REQUEST";
  countryCode: string;
};

export const getDlocalCountryReference = (countryCode: string): Action => {
  return {
    type: "AUTH_GET_DLOCAL_COUNTRY_REFERENCE_REQUEST",
    countryCode,
  };
};

export type GetPaypalCountryReferenceAction = {
  type: "AUTH_GET_PAYPAL_COUNTRY_REFERENCE_REQUEST";
  countryCode: string;
};

export const getPaypalCountryReference = (countryCode: string): Action => {
  return {
    type: "AUTH_GET_PAYPAL_COUNTRY_REFERENCE_REQUEST",
    countryCode,
  };
};

export type GetPaypalCountryReferenceSuccessAction = {
  type: "AUTH_GET_PAYPAL_COUNTRY_REFERENCE_SUCCESS";
  country: string;
  countryIsoLanguageCode: string;
  countryCode: string;
  currencyCode: string;
  price: number;
  annualPrice: number;
  monthlyPlanId: string;
  annualPlanId: string;
};

export const getPaypalCountryReferenceSuccess = (
  country: string,
  countryIsoLanguageCode: string,
  countryCode: string,
  currencyCode: string,
  price: number,
  annualPrice: number,
  monthlyPlanId: string,
  annualPlanId: string
): Action => {
  return {
    type: "AUTH_GET_PAYPAL_COUNTRY_REFERENCE_SUCCESS",
    country,
    countryIsoLanguageCode,
    countryCode,
    currencyCode,
    price,
    annualPrice,
    monthlyPlanId,
    annualPlanId,
  };
};

export type GetDlocalCountryReferenceSuccessAction = {
  type: "AUTH_GET_DLOCAL_COUNTRY_REFERENCE_SUCCESS";
  country: string;
  countryIsoLanguageCode: string;
  countryCode: string;
  currencyCode: string;
  documentName: string;
  documentFormat: string;
  documentFormatMin: number;
  documentFormatMax: number;
  isRequired: boolean;
  price: number;
  annualPrice: number;
  apiKey: string;
};

export const getDlocalCountryReferenceSuccess = (
  country: string,
  countryIsoLanguageCode: string,
  countryCode: string,
  currencyCode: string,
  documentName: string,
  documentFormat: string,
  documentFormatMin: number,
  documentFormatMax: number,
  isRequired: boolean,
  price: number,
  annualPrice: number,
  apiKey: string
): Action => {
  return {
    type: "AUTH_GET_DLOCAL_COUNTRY_REFERENCE_SUCCESS",
    country,
    countryIsoLanguageCode,
    countryCode,
    currencyCode,
    documentName,
    documentFormat,
    documentFormatMin,
    documentFormatMax,
    isRequired,
    price,
    annualPrice,
    apiKey,
  };
};

export type UpdateUserPlanSuccessAction = {
  type: "AUTH_UPDATE_USER_PLAN_REQUEST_SUCCESS";
};

export const updateUserPlanSuccess = (): Action => {
  return {
    type: "AUTH_UPDATE_USER_PLAN_REQUEST_SUCCESS",
  };
};
//updateUserPlanSuccess

export type UpdateUsernameRequestAction = {
  type: "AUTH_UPDATE_USERNAME_REQUEST";
  id: number;
  username: string;
};

export const updateUsername = (id: number, username: string): Action => {
  return {
    type: "AUTH_UPDATE_USERNAME_REQUEST",
    id,
    username,
  };
};

export type UpdateCredentialsFailureAction = {
  type: "AUTH_UPDATE_DETAILS_FAILURE";
};

export const updateCredentialsFailure = (): Action => {
  return {
    type: "AUTH_UPDATE_DETAILS_FAILURE",
  };
};

export type UpdateUsernameSuccessAction = {
  type: "AUTH_UPDATE_USERNAME_SUCCESS";
  username: string;
};

export const updateUsernameSuccess = (username: string): Action => {
  return {
    type: "AUTH_UPDATE_USERNAME_SUCCESS",
    username,
  };
};

export type UpdatePasswordSuccessAction = {
  type: "AUTH_UPDATE_PASSWORD_SUCCESS";
};

export const updatePasswordStateAction = (): Action => {
  return {
    type: "AUTH_RESET_PASSWORD_STATE_ACTION",
  };
};

export type UpdatePasswordStateAction = {
  type: "AUTH_RESET_PASSWORD_STATE_ACTION";
};

export const updateUsernameStateAction = (): Action => {
  return {
    type: "AUTH_RESET_USERNAME_STATE_ACTION",
  };
};

export type UpdateUsernameStateAction = {
  type: "AUTH_RESET_USERNAME_STATE_ACTION";
};

export const updatePasswordSuccess = (): Action => {
  return {
    type: "AUTH_UPDATE_PASSWORD_SUCCESS",
  };
};

export type UpdatePasswordRequestAction = {
  type: "AUTH_UPDATE_PASSWORD_REQUEST";
  id: number;
  password: string;
};

export const updatePassword = (id: number, password: string): Action => {
  return {
    type: "AUTH_UPDATE_PASSWORD_REQUEST",
    id,
    password,
  };
};

export type UpdateDetailsCountryRequestAction = {
  type: "AUTH_UPDATE_DETAILS_COUNTRY_REQUEST";
  id: number;
  countryCode: string;
  country: string;
  callback: (isUpdated: boolean) => void;
};

export const updateDetailsCountry = (
  id: number,
  countryCode: string,
  country: string,
  callback: (isUpdated: boolean) => void
): Action => {
  return {
    type: "AUTH_UPDATE_DETAILS_COUNTRY_REQUEST",
    id,
    countryCode,
    country,
    callback,
  };
};

export type UpdateDetailsRequestAction = {
  type: "AUTH_UPDATE_DETAILS_REQUEST";
  id: number;
  firstName: string;
  lastName: string;
  countryCode: string;
  country: string;
};

export const updateDetails = (
  id: number,
  firstName: string,
  lastName: string,
  countryCode: string,
  country: string
): Action => {
  return {
    type: "AUTH_UPDATE_DETAILS_REQUEST",
    id,
    firstName,
    lastName,
    countryCode,
    country,
  };
};

export type UpdateDetailsSuccessAction = {
  type: "AUTH_UPDATE_DETAILS_SUCCESS";
  username: string;
  firstName: string;
  lastName: string;
  country: string;
  countryCode: string;
};

export const updateDetailsSuccess = (
  username: string,
  firstName: string,
  lastName: string,
  country: string,
  countryCode: string
): Action => {
  return {
    type: "AUTH_UPDATE_DETAILS_SUCCESS",
    username,
    firstName,
    lastName,
    country,
    countryCode,
  };
};

export type SignUpSuccessAction = {
  type: "AUTH_SIGN_UP_SUCCESS";
};

export const signUpSuccess = (): Action => {
  return {
    type: "AUTH_SIGN_UP_SUCCESS",
  };
};

export type SignUpFailureAction = {
  type: "AUTH_SIGN_UP_FAILURE";
};

export const signUpFailure = (): Action => {
  return {
    type: "AUTH_SIGN_UP_FAILURE",
  };
};

export type FetchCurrentUserRequestAction = {
  type: "AUTH_FETCH_CURRENT_USER_REQUEST";
};

export const fetchCurrentUser = (): Action => {
  return {
    type: "AUTH_FETCH_CURRENT_USER_REQUEST",
  };
};

export type FetchCurrentUserSuccessAction = {
  type: "AUTH_FETCH_CURRENT_USER_SUCCESS";
};

export const fetchCurrentUserSuccess = (): Action => {
  return {
    type: "AUTH_FETCH_CURRENT_USER_SUCCESS",
  };
};

export type FetchCurrentUserFailureAction = {
  type: "AUTH_FETCH_CURRENT_USER_FAILURE";
};

export const fetchCurrentUserFailure = (): Action => {
  return {
    type: "AUTH_FETCH_CURRENT_USER_FAILURE",
  };
};

export type FetchUserRequestAction = {
  type: "AUTH_FETCH_USER_REQUEST";
  username: string;
};

export const fetchUser = (username: string): Action => {
  return {
    type: "AUTH_FETCH_USER_REQUEST",
    username: username,
  };
};

export type FetchUserSuccessAction = {
  type: "AUTH_FETCH_USER_SUCCESS";
  isValid: boolean;
};

export const fetchUserSuccess = (isValid: boolean): Action => {
  return {
    type: "AUTH_FETCH_USER_SUCCESS",
    isValid: isValid,
  };
};

export type FetchUserFailureAction = {
  type: "AUTH_FETCH_USER_FAILURE";
};

export const fetchUserFailure = (): Action => {
  return {
    type: "AUTH_FETCH_USER_FAILURE",
  };
};

export type SubscribeTestFBAd1 = {
  type: "SUBSCRIBE_TEST_FB_AD1";
  email: string;
};

export const subscribeTestFBAd1 = (email: string): Action => {
  return {
    type: "SUBSCRIBE_TEST_FB_AD1",
    email: email,
  };
};

export type LogActivityRequestAction = {
  type: "LOG_ACTIVITY_REQUEST";
  timestamp: number;
  action: string;
  page: string;
  username: string;
  property?: string | null;
};

export const logActivity = (
  action: string,
  page: string,
  username?: string,
  property?: string | null
): Action => {
  SegmentClient.track({
    userId: username ? username : "anon",
    event: action,
    properties: {
      property: property,
    },
  });
  return {
    type: "LOG_ACTIVITY_REQUEST",
    timestamp: Date.now(),
    username: username ? username : "anon",
    action: action,
    page: page,
    property: property,
  };
};

export type Action =
  // This is only needed for Typescript type check to pass. There has to be an
  // action with just the type property, and nothing else.
  | { type: "_"; username?: string; websiteSlug?: string }
  | LoginRequestAction
  | LoginWithAccessTokenRequestAction
  | LoginSuccessAction
  | LoginWithAccessTokenSuccessAction
  | LoginFailureAction
  | LogoutRequestAction
  | LogoutSuccessAction
  | LogoutFailureAction
  | SignUpRequestAction
  | SignUpSuccessAction
  | SignUpFailureAction
  | FetchCurrentUserRequestAction
  | FetchCurrentUserSuccessAction
  | FetchCurrentUserFailureAction
  | GetGoogleIdAction
  | GetGoogleIdSuccessAction
  | GetGoogleIdFailureAction
  | FetchRedirectUriAction
  | FetchRedirectUriSuccessAction
  | FetchRedirectUriFailureAction
  | FetchBaseUrlAction
  | FetchBaseUrlSuccessAction
  | FetchBaseUrlFailureAction
  | FetchUserRequestAction
  | FetchUserSuccessAction
  | FetchUserFailureAction
  | UpdateUsernameRequestAction
  | UpdatePasswordRequestAction
  | UpdateDetailsRequestAction
  | UpdateDetailsCountryRequestAction
  | UpdateUsernameSuccessAction
  | UpdateCredentialsFailureAction
  | UpdatePasswordSuccessAction
  | UpdatePasswordStateAction
  | UpdateDetailsSuccessAction
  | UpdateUsernameStateAction
  | GetUnplashKeyAction
  | GetUnplashKeySuccessAction
  | GetUnplashKeyFailureAction
  | GetUserProviderAction
  | GetUserProviderSuccessAction
  | GetUserProviderFailureAction
  | LogActivityRequestAction
  | UpdateUserPlanAction
  | UpdateUserPlanSuccessAction
  | GetDlocalConfigAction
  | GetDlocalCountryReferenceAction
  | GetDlocalCountryReferenceSuccessAction
  | StartDlocalPaymentProcessAction
  | HandlePaymentProcessResultAction
  | ResetPaymentStatusAction
  | ResendEmailVerifyAction
  | VerifyTokenAction
  | VerifyTokenSuccessAction
  | GetPaypalCountryReferenceAction
  | GetPaypalCountryReferenceSuccessAction
  | ProcessPaypalPaymentRequestAction
  | PasswordResetRequestAction
  | PasswordResetAction
  | SubscribeTestFBAd1
  | GetPaymentSettingsForCountryAction
  | GetPaymentSettingsForCountrySuccessAction;
