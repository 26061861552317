import { Layout } from "common/elements/types";

export const renameElementId = (
  layout: Layout,
  elementId: string,
  renameToElementId: string
) => {
  const element = layout[elementId];
  element.elementId = renameToElementId;
  layout[renameToElementId] = element;
  delete layout[elementId];

  if (element.elementParentId) {
    renameElementIdParentReference(
      layout,
      element.elementParentId,
      elementId,
      renameToElementId
    );
  }

  for (const childElementId of element.elementChildrenId || []) {
    layout[childElementId].elementParentId = renameToElementId;
  }
};

export const renameElementIdParentReference = (
  layout: Layout,
  parentElementId: string,
  elementId: string,
  renameToElementId: string
): void =>
  spliceElementParentReference(
    layout,
    parentElementId,
    elementId,
    renameToElementId
  );

export const removeElementParentReference = (
  layout: Layout,
  parentElementId: string,
  elementId: string
): Layout => {
  spliceElementParentReference(layout, parentElementId, elementId);
  return layout;
};

const spliceElementParentReference = (
  layout: Layout,
  parentElementId: string,
  elementId: string,
  renameToElementId?: string
): void => {
  const parent = layout[parentElementId];
  if (parent?.elementChildrenId) {
    const index = parent.elementChildrenId.indexOf(elementId);
    if (index > -1) {
      if (renameToElementId) {
        parent.elementChildrenId.splice(index, 1, renameToElementId);
      } else {
        parent.elementChildrenId.splice(index, 1);
      }
    }
  }
};
