import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import * as yup from "yup";

import { IStepProps, PurposeContact } from "./Setup";

import { useTranslation } from "react-i18next";

import styles from "./Admin.module.scss";
import {
  ChatBubble,
  ChatRounded,
  Instagram,
  PhoneAndroidRounded,
  Telegram,
  WhatsApp,
} from "@material-ui/icons";

const schema = yup.object({
  purposeName: yup.string(),
  purposeDescription: yup.string(),
});

type Props = {
  methodName: string;
};

export type SetupStep3dFormValues = yup.InferType<typeof schema>;

export const SetupStep3dForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleSkip,
}) => {
  const { t } = useTranslation();
  const [stateSelectedMethod, setStateSelectedMethod] = useState<
    string | undefined
  >();
  const [stateContact, setStateContact] = useState<PurposeContact>(
    values.contact
  );
  const [placeHolder, setPlaceHolder] = useState<string | undefined>();
  const [placeIcon, setPlaceIcon] = useState<any>();
  const [stateInput, setStateInput] = useState<string | undefined>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  // const [isContactAdded, setIsContactAdded] = useState<boolean>(false);
  const [buttonTitle, setButtonContactTitle] = useState<string>(
    stateContact.discord ||
      stateContact.instagram ||
      stateContact.messenger ||
      stateContact.mobile1 ||
      stateContact.mobile2 ||
      stateContact.mobile3 ||
      stateContact.telegram ||
      stateContact.whatsapp
      ? t("setup_wizard_155")
      : t("setup_wizard_33")
  );

  useEffect(() => {
    if (
      values &&
      (values.isPurposeContact === undefined ||
        values.isPurposeContact === false)
    ) {
      handleSaveAndContinue(values);
    }
  }, [values, handleSaveAndContinue]);

  const onSubmit = () => {
    values.contact = stateContact;
    handleSaveAndContinue(values);
  };

  const handleInputChange = (input: string) => {
    setStateInput(input);
  };

  const handleDropDownSelect = (selectedMethod: string) => {
    setStateSelectedMethod(selectedMethod);
    switch (selectedMethod) {
      case "mobile":
        setPlaceHolder(t("setup_wizard_37"));
        setPlaceIcon(<PhoneAndroidRounded />);
        return;
      case "whatsapp":
        setPlaceHolder(t("setup_wizard_38"));
        setPlaceIcon(<WhatsApp />);
        return;
      case "messenger":
        setPlaceHolder(t("setup_wizard_39"));
        setPlaceIcon(<ChatRounded />);
        return;
      case "instagram":
        setPlaceHolder(t("setup_wizard_40"));
        setPlaceIcon(<Instagram />);
        return;
      case "telegram":
        setPlaceHolder(t("setup_wizard_41"));
        setPlaceIcon(<Telegram />);
        return;
      case "discord":
        setPlaceHolder(t("setup_wizard_42"));
        setPlaceIcon(<ChatBubble />);
        return;
    }
  };

  const handleDeleteContactMethod = () => {
    if (stateSelectedMethod && stateInput) {
      let contact: PurposeContact = values.contact;
      switch (stateSelectedMethod) {
        case "mobile":
          if (contact.mobile1 === undefined) {
            contact.mobile1 = undefined;
          } else if (contact.mobile2 === undefined) {
            contact.mobile2 = undefined;
          } else if (contact.mobile3 === undefined) {
            contact.mobile3 = undefined;
          }
          break;
        case "mobile1":
          contact.mobile1 = undefined;
          break;
        case "mobile2":
          contact.mobile2 = undefined;
          break;
        case "mobile3":
          contact.mobile3 = undefined;
          break;
        case "whatsapp":
          contact.whatsapp = undefined;
          break;
        case "messenger":
          contact.messenger = undefined;
          break;
        case "instagram":
          contact.instagram = undefined;
          break;
        case "telegram":
          contact.telegram = undefined;
          break;
        case "discord":
          contact.discord = undefined;
          break;
      }
      setStateContact(contact);
      setStateSelectedMethod(undefined);
      setStateInput(undefined);
      setIsUpdate(false);

      if (
        contact.discord ||
        contact.instagram ||
        contact.messenger ||
        contact.mobile1 ||
        contact.mobile2 ||
        contact.mobile3 ||
        contact.telegram ||
        contact.whatsapp
      ) {
        // setIsContactAdded(true)
        setButtonContactTitle(t("setup_wizard_155"));
      } else {
        // setIsContactAdded(false)
        setButtonContactTitle(t("setup_wizard_33"));
      }
    }
  };

  const handleAddContactMethod = () => {
    if (stateSelectedMethod && stateInput) {
      let contact: PurposeContact = values.contact;
      switch (stateSelectedMethod) {
        case "mobile":
          if (contact.mobile1 === undefined) {
            contact.mobile1 = stateInput;
          } else if (contact.mobile2 === undefined) {
            contact.mobile2 = stateInput;
          } else if (contact.mobile3 === undefined) {
            contact.mobile3 = stateInput;
          }
          break;
        case "mobile1":
          contact.mobile1 = stateInput;
          break;
        case "mobile2":
          contact.mobile2 = stateInput;
          break;
        case "mobile3":
          contact.mobile3 = stateInput;
          break;
        case "whatsapp":
          contact.whatsapp = stateInput;
          break;
        case "messenger":
          contact.messenger = stateInput;
          break;
        case "instagram":
          contact.instagram = stateInput;
          break;
        case "telegram":
          contact.telegram = stateInput;
          break;
        case "discord":
          contact.discord = stateInput;
          break;
      }
      setStateContact(contact);
      setStateSelectedMethod(undefined);
      setStateInput(undefined);
      setIsUpdate(false);
      // setIsContactAdded(true)
      if (
        contact.discord ||
        contact.instagram ||
        contact.messenger ||
        contact.mobile1 ||
        contact.mobile2 ||
        contact.mobile3 ||
        contact.telegram ||
        contact.whatsapp
      ) {
        // setIsContactAdded(true)
        setButtonContactTitle(t("setup_wizard_155"));
      } else {
        // setIsContactAdded(false)
        setButtonContactTitle(t("setup_wizard_33"));
      }
    }
  };

  function EditButton({ methodName }: Props): JSX.Element {
    let buttonLabel;
    let buttonIcon: any;
    let inputValue: React.SetStateAction<string | undefined>;
    switch (methodName) {
      case "mobile1":
        buttonLabel = t("setup_wizard_43");
        buttonIcon = <PhoneAndroidRounded />;
        inputValue = values.contact.mobile1;
        break;
      case "mobile2":
        buttonLabel = t("setup_wizard_43");
        buttonIcon = <PhoneAndroidRounded />;
        inputValue = values.contact.mobile2;
        break;
      case "mobile3":
        buttonLabel = t("setup_wizard_43");
        buttonIcon = <PhoneAndroidRounded />;
        inputValue = values.contact.mobile3;
        break;
      case "whatsapp":
        buttonLabel = "Whatsapp";
        buttonIcon = <WhatsApp />;
        inputValue = values.contact.whatsapp;
        break;
      case "messenger":
        buttonLabel = "Messenger";
        buttonIcon = <ChatRounded />;
        inputValue = values.contact.messenger;
        break;
      case "instagram":
        buttonLabel = "Instagram";
        buttonIcon = <Instagram />;
        inputValue = values.contact.instagram;
        break;
      case "telegram":
        buttonLabel = "Telegram";
        buttonIcon = <Telegram />;
        inputValue = values.contact.telegram;
        break;
      case "discord":
        buttonLabel = "Discord";
        buttonIcon = <ChatBubble />;
        inputValue = values.contact.discord;
        break;
    }

    return (
      <Button
        type="button"
        key={methodName}
        style={{
          width: "100%",
          textAlign: "left",
          borderRadius: "32px",
          color: "#007bff",
          backgroundColor: "#fff",
          border: "1px solid #007bff",
          marginBottom: "12px",
        }}
        onClick={() => {
          setStateSelectedMethod(methodName);
          setStateInput(inputValue);
          setIsUpdate(true);
          setPlaceIcon(buttonIcon);
        }}
      >
        {buttonIcon} {t("setup_wizard_156")} {buttonLabel}
      </Button>
    );
  }

  function EditButtons(): JSX.Element {
    return (
      <div>
        <div>
          {stateContact.mobile1 ||
          stateContact.mobile2 ||
          stateContact.mobile3 ||
          stateContact.whatsapp ||
          stateContact.messenger ||
          stateContact.instagram ||
          stateContact.telegram ||
          stateContact.discord ? (
            <>
              <div className={styles["setup-list-label"]}>
                {t("setup_wizard_106")}
              </div>
            </>
          ) : (
            <></>
          )}
          {stateContact.mobile1 ? <EditButton methodName="mobile1" /> : <></>}
          {stateContact.mobile2 ? <EditButton methodName="mobile2" /> : <></>}
          {stateContact.mobile3 ? <EditButton methodName="mobile3" /> : <></>}
          {stateContact.whatsapp ? <EditButton methodName="whatsapp" /> : <></>}
          {stateContact.messenger ? (
            <EditButton methodName="messenger" />
          ) : (
            <></>
          )}
          {stateContact.instagram ? (
            <EditButton methodName="instagram" />
          ) : (
            <></>
          )}
          {stateContact.telegram ? <EditButton methodName="telegram" /> : <></>}
          {stateContact.discord ? <EditButton methodName="discord" /> : <></>}
        </div>
      </div>
    );
  }
  return (
    <Form noValidate>
      <Modal.Header>
        <Modal.Title style={{ width: "100%" }}>
          <h6>{t("setup_wizard_81")}</h6>
          <div className={styles["setup-subheader"]}>
            <div className={styles["setup-subheader-main"]}>
              {t("setup_wizard_54")}
            </div>
            <div
              className={styles["setup-subheader-skip"]}
              onClick={() => {
                handleSkip(values);
              }}
            >
              {t("setup_wizard_4")}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row}>
          <Col>
            {stateSelectedMethod !== undefined ? (
              <></>
            ) : (
              <>
                <EditButtons />
                {stateContact.discord ||
                stateContact.instagram ||
                stateContact.messenger ||
                stateContact.mobile1 ||
                stateContact.mobile2 ||
                stateContact.mobile3 ||
                stateContact.telegram ||
                stateContact.whatsapp ? (
                  <div
                    style={{
                      width: "100%",
                      borderTop: "1px solid rgba(0, 0, 0, 0.125)",
                      marginTop: "28px",
                      marginBottom: "28px",
                    }}
                  />
                ) : (
                  <></>
                )}
                <ButtonGroup className={styles["dropdown_button_group"]}>
                  <DropdownButton
                    as={ButtonGroup}
                    title={buttonTitle}
                    id="bg-nested-dropdown"
                    className={styles["dropdown_button"]}
                  >
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "mobile" ? true : false}
                      eventKey="mobile"
                      onSelect={() => {
                        handleDropDownSelect("mobile");
                      }}
                    >
                      Mobile
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "whatsapp" ? true : false}
                      eventKey="whatsapp"
                      onSelect={() => {
                        handleDropDownSelect("whatsapp");
                      }}
                    >
                      Whatsapp
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={
                        stateSelectedMethod === "messenger" ? true : false
                      }
                      eventKey="messenger"
                      onSelect={() => {
                        handleDropDownSelect("messenger");
                      }}
                    >
                      Messenger
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={
                        stateSelectedMethod === "instagram" ? true : false
                      }
                      eventKey="instagram"
                      onSelect={() => {
                        handleDropDownSelect("instagram");
                      }}
                    >
                      Instagram
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "telegram" ? true : false}
                      eventKey="telegram"
                      onSelect={() => {
                        handleDropDownSelect("telegram");
                      }}
                    >
                      Telegram
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown_button_item"]}
                      active={stateSelectedMethod === "discord" ? true : false}
                      eventKey="discord"
                      onSelect={() => {
                        handleDropDownSelect("discord");
                      }}
                    >
                      Discord
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
              </>
            )}
            {stateSelectedMethod ? (
              <div style={{ marginTop: "12px" }}>
                <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                  {placeHolder}
                </Form.Label>
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>{placeIcon}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="inlineFormInputGroup"
                    placeholder={placeHolder}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleInputChange(e.target.value);
                    }}
                    value={stateInput}
                  />
                </InputGroup>
                <Button
                  type="button"
                  disabled={!stateSelectedMethod || !stateInput}
                  style={{ borderRadius: 32, width: "100%", marginTop: "36px" }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleAddContactMethod();
                  }}
                >
                  {isUpdate ? t("setup_wizard_34") : t("setup_wizard_35")}
                </Button>
                {isUpdate ? (
                  <Button
                    type="button"
                    disabled={!stateSelectedMethod || !stateInput}
                    style={{
                      borderRadius: 32,
                      width: "100%",
                      marginTop: "12px",
                      borderColor: "",
                      border: "2px solid #df2935",
                      backgroundColor: "white",
                      color: "#df2935",
                    }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleDeleteContactMethod();
                    }}
                  >
                    {t("setup_wizard_36")}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button
          type="button"
          onClick={
            stateSelectedMethod !== undefined
              ? () => {
                  setStateSelectedMethod(undefined);
                  setStateInput(undefined);
                  setIsUpdate(false);
                }
              : () => {
                  handleStepBack();
                }
          }
          style={{
            borderRadius: 32,
            color: "#007bff",
            backgroundColor: "white",
            borderColor: "#007bff",
          }}
        >
          {stateSelectedMethod !== undefined
            ? t("cancel")
            : t("login_page_back")}
        </Button>
        {stateSelectedMethod !== undefined ? (
          <Button
            type="button"
            disabled={
              !stateContact.mobile1 &&
              !stateContact.mobile2 &&
              !stateContact.mobile3 &&
              !stateContact.whatsapp &&
              !stateContact.messenger &&
              !stateContact.instagram &&
              !stateContact.telegram &&
              !stateContact.discord
            }
            style={{ opacity: 0 }}
            onClick={onSubmit}
          >
            {t("setup_page_15")}
          </Button>
        ) : (
          <Button
            type="button"
            disabled={
              !stateContact.mobile1 &&
              !stateContact.mobile2 &&
              !stateContact.mobile3 &&
              !stateContact.whatsapp &&
              !stateContact.messenger &&
              !stateContact.instagram &&
              !stateContact.telegram &&
              !stateContact.discord
            }
            style={{ borderRadius: 32 }}
            onClick={onSubmit}
          >
            {t("setup_page_15")}
          </Button>
        )}
      </Modal.Footer>
    </Form>
  );
};
