import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  FieldGroupConfigProperty,
  FormConfigurationProperty,
  GoogleMapsEmbedUrlProperty,
  HeadlineStyleProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
  YoutubeEmbedUrlProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { ImageSourceProperty } from "editor/properties/ImageSourceProperty/ImageSourceProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Facebook, Twitter } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { ImageProperty } from "editor/properties/ImageProperty/ImageProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const defaultTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

let slug: undefined | string;

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultNavbar(
        [
          new LinkProperty("Home", "/", false, false, false),
          new LinkProperty("Contact", "/", false, false, false),
        ],
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "/img/logo-default.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        "0",
        new BackgroundColorProperty("#ffffff"),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "2",
        "1"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        [
          new LinkProperty("Google", "https://google.com", false, true, false),
          new LinkProperty("Yahoo", "https://yahoo.com", false, true, false),
        ],
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "/img/logo-default.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright YourCompany " + new Date().getFullYear()
      );

    default:
      return defaultTemplates[layoutKey];
  }
};

defaultTemplates["gallery"] = TemplatesFactory.createDefaultGallery(
  [
    new ImageProperty(
      "1",
      "Landscape 1",
      "https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
      {},
      "https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
      "1"
    ),
    new ImageProperty(
      "2",
      "Landscape 2",
      "https://images.unsplash.com/photo-1470813740244-df37b8c1edcb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80",
      {},
      "https://images.unsplash.com/photo-1470813740244-df37b8c1edcb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80",
      "2"
    ),
    new ImageProperty(
      "3",
      "Landscape 3",
      "https://images.unsplash.com/photo-1544077960-604201fe74bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80",
      {},
      "https://images.unsplash.com/photo-1544077960-604201fe74bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80",
      "3"
    ),
    new ImageProperty(
      "4",
      "Landscape 4",
      "https://images.unsplash.com/photo-1502512977010-b821981050d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2338&q=80",
      {},
      "https://images.unsplash.com/photo-1502512977010-b821981050d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2338&q=80",
      "4"
    ),
    new ImageProperty(
      "5",
      "Landscape 5",
      "https://images.unsplash.com/photo-1508164005423-5d2e49b2a749?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
      {},
      "https://images.unsplash.com/photo-1508164005423-5d2e49b2a749?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
      "5"
    ),
    new ImageProperty(
      "6",
      "Landscape 6",
      "https://images.unsplash.com/photo-1554291499-563a504e0734?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2338&q=80",
      {},
      "https://images.unsplash.com/photo-1554291499-563a504e0734?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2338&q=80",
      "6"
    ),
  ],
  "1"
);

defaultTemplates[
  "alternate-logo-container"
] = TemplatesFactory.createDefaultAlternateLogoContainer(
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "/img/logo-default-t2.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString())
);

defaultTemplates["footer-nav"] = TemplatesFactory.createDefaultFooterNav(
  [
    new LinkProperty("Home", "/", false, false, false),
    new LinkProperty("Contact", "/", false, false, false),
  ],
  "1"
);

defaultTemplates["nav"] = TemplatesFactory.createDefaultNav(
  [
    new LinkProperty("Home", "/", false, false, false),
    new LinkProperty("Contact", "/", false, false, false),
  ],
  "1"
);

defaultTemplates[
  "logo-container"
] = TemplatesFactory.createDefaultLogoContainer(
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "/img/logo-default-t2.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString())
);

defaultTemplates["social-nav"] = TemplatesFactory.createSocialNavbar(
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "1"
);

defaultTemplates["navbar"] = TemplatesFactory.createDefaultNavbar(
  [
    new LinkProperty("Home", "/", false, false, false),
    new LinkProperty("Contact", "/", false, false, false),
  ],
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    slug
      ? `https://s3.amazonaws.com/img.goodweb.app/sites/${slug}/main.png`
      : "/img/logo-default.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "0",
  new BackgroundColorProperty("#ffffff"),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "1",
  "2"
);

defaultTemplates["button"] = TemplatesFactory.createDefaultButton(
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Call to Action", "", false, false, false),
  new TreatmentProperty(false),
  false,
  false
);

defaultTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty("", undefined),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);

defaultTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

defaultTemplates["headline"] = TemplatesFactory.createDefaultHeadline(
  new HeadlineStyleProperty("1"),
  new RichTextProperty("Rich Text Sample"),
  new RTEAlignmentProperty("parent")
);

defaultTemplates["rt"] = TemplatesFactory.createDefaultRichText(
  new RichTextProperty("<strong>Rich Text Sample</strong>"),
  new RTEAlignmentProperty("parent")
);

defaultTemplates["heading"] = TemplatesFactory.createDefaultHeading(
  new RichTextProperty("<h1>Rich Text Sample</h1>"),
  new RTEAlignmentProperty("parent")
);

defaultTemplates["paragraph"] = TemplatesFactory.createDefaultParagraph(
  new RichTextProperty(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
  ),
  new RTEAlignmentProperty("parent")
);

defaultTemplates["image"] = TemplatesFactory.createDefaultImage(
  new ImageStyleProperty("1"),
  new ImageSourceProperty("https://picsum.photos/1500/1000")
);

defaultTemplates["youtube"] = TemplatesFactory.createDefaultYoutube(
  new YoutubeEmbedUrlProperty("zlRl8sJU_4I")
);

defaultTemplates["google-maps"] = TemplatesFactory.createDefaultGoogleMaps(
  new GoogleMapsEmbedUrlProperty(
    `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d208.09073861732702!2d-70.64216484440823!3d-33.4375333972365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c598f7667e4b%3A0xe4e2ee1d9b2f8b7d!2sPanader%C3%ADa%20y%20Caf%C3%A9%20Gabilondo!5e0!3m2!1sen!2sch!4v1656418540030!5m2!1sen!2sch" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d408.03288794071074!2d-70.64199679057643!3d-33.437561954883726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c598f7667e4b%3A0xe4e2ee1d9b2f8b7d!2sPanader%C3%ADa%20y%20Caf%C3%A9%20Gabilondo!5e0!3m2!1sen!2sch!4v1656342879868!5m2!1sen!2sch"
  )
);

defaultTemplates["lead"] = TemplatesFactory.createDefaultLead(
  new LeadStyleProperty("1"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RTEAlignmentProperty("parent")
);

defaultTemplates["blockquote"] = TemplatesFactory.createDefaultBlockQuote(
  new RichTextProperty(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  ),
  new RTEAlignmentProperty("parent")
);

defaultTemplates["cover"] = TemplatesFactory.createDefaultCover(
  "5",
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Call to Action", "", false, false, false),
  new RichTextProperty("<h1>Lorem ipsum dolor sit amet.</h1>"),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://picsum.photos/2500/1000",
    "no-repeat",
    "50%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 50)
);

defaultTemplates[
  "cards"
] = TemplatesFactory.createDefaultCards(
  new LeadStyleProperty("1"),
  new RichTextProperty("<h2>Lorem ipsum dolor sit amet.</h2>"),
  new RTEAlignmentProperty("parent"),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  [
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Lorem Ipsum</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Read More", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://picsum.photos/900/700",
      "parent"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Dolor Sit Amet</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Read More", "", false, false, false),
      new RichTextProperty(
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor."
      ),
      new RTEAlignmentProperty("parent"),
      "https://picsum.photos/901/700",
      "parent"
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Consectetur Adip</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Read More", "", false, false, false),
      new RichTextProperty(
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor."
      ),
      new RTEAlignmentProperty("parent"),
      "https://picsum.photos/900/701",
      "parent"
    ),
  ]
);

defaultTemplates["card"] = TemplatesFactory.createDefaultCard(
  new RichTextProperty("<h3>Lorem ipsum</h3>"),
  new RTEAlignmentProperty("parent"),
  new ImageStyleProperty("1"),
  new ButtonStyleProperty("hobaw-btn-outline-primary"),
  new LinkProperty("Call to Action", "", false, false, false),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt."
  ),
  new RTEAlignmentProperty("parent"),
  "https://picsum.photos/600/400",
  "parent"
);

defaultTemplates["articles"] = TemplatesFactory.createDefaultArticles(
  new LeadStyleProperty("1"),
  new RichTextProperty("<h2>Lorem ipsum dolor sit amet.</h2>"),
  new RTEAlignmentProperty("parent"),
  new ImageStyleProperty("1"),
  new ButtonStyleProperty("hobaw-btn-outline-primary"),
  new LinkProperty("Call to Action", "", false, false, false),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor. Etiam sit amet fringilla lorem, vitae dapibus velit. Donec vitae enim gravida, sodales massa ut, dictum ante. Morbi risus ligula, venenatis eget porttitor eu, facilisis at sem. Phasellus et gravida ipsum, non tincidunt ante. Nunc rhoncus libero vitae feugiat condimentum. Maecenas at metus id mauris ultrices hendrerit vitae at elit. Duis laoreet, mi et convallis fermentum, purus sem volutpat nulla, id vulputate risus dui a augue."
  ),
  new RTEAlignmentProperty("parent"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus commodi alias deserunt."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "2",
  "https://picsum.photos/1200/900",
  "2"
);

defaultTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("1"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Lorem ipsum dolor sit amet.</h2>"),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

defaultTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  [
    new LinkProperty("Google", "https://google.com", false, true, false),
    new LinkProperty("Yahoo", "https://yahoo.com", false, true, false),
  ],
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "/img/logo-default.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright YourCompany " + new Date().getFullYear()
);

defaultTemplates[
  "form-group"
] = TemplatesFactory.createDefaultFormContainerContent(
  new FieldGroupConfigProperty(
    "Email",
    "input",
    "Example: george@gmail.com",
    false
  )
);
