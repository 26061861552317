import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type ImageProperties = {
  imageStyle: string;
  imageText: string;
  imgUrl: string;
  dimension: { width?: number; height?: number };
};

export function Image({
  properties: { imageStyle, imageText, dimension, imgUrl },
  className,
  ...props
}: ElementComponentProps<ImageProperties>): JSX.Element {
  return (
    <div className={`ce image-container`}>
      <picture {...props} className={imageStyle}>
        <img
          src={
            imgUrl === undefined || imgUrl === ""
              ? "https://www.goodweb.app/img/default-image.svg"
              : imgUrl
          }
          alt={imageText}
          className={className + " rs"}
          style={{
            width: dimension && dimension.width ? dimension.width : "",
            height: dimension && dimension.height ? dimension.height : "",
          }}
        />
      </picture>
    </div>
  );
}

export const imageElementKey = "image";

elementComponentRegistry.register(imageElementKey, Image);
