import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type CardProperties = {
  cardStyle: string;
  dimension: { width?: number; height?: number };
};

export function Card({
  properties: { cardStyle, dimension },
  className,
  innerRef,
  children,
  ...props
}: ElementComponentProps<CardProperties>): JSX.Element {
  return (
    <article
      {...props}
      ref={innerRef}
      className={`${className} card-content card-content-style-${cardStyle} fs rp sp cg-df-lg-brc cg-df-dg`}
      style={{
        width: dimension && dimension.width ? dimension.width : "",
        height: dimension && dimension.height ? dimension.height : "",
      }}
    >
      {children}
    </article>
  );
}

export const cardElementKey = "card";

elementComponentRegistry.register(cardElementKey, Card);
