import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { IStepProps, PurposeWorkServicesProduct, ToUpload } from "./Setup";

import { useTranslation } from "react-i18next";

import styles from "./Admin.module.scss";
import { EditRounded, PhotoAlbumRounded } from "@material-ui/icons";

import { useDropzone } from "react-dropzone";
import { urltoFile } from "./image-utils";
import { uploadFile } from "./S3Client";
import { useSelector } from "react-redux";
import { getS3config } from "editor/states/selectors";

var mime = require("mime-types");

const schema = yup.object({
  purposeName: yup.string(),
  purposeDescription: yup.string(),
});

type Props = {
  i: number;
  index: number;
  serviceTitle?: string;
  serviceDescription?: string;
  servicePhotoUrl?: ToUpload;
  tempFilename?: string;
};

export type SetupStep3bFormValues = yup.InferType<typeof schema>;

export const SetupStep3bForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  isSaving,
  handleStepBack,
  handleSkip,
}) => {
  const { t } = useTranslation();
  const s3ConfigState = useSelector(getS3config);
  const [stateWorkProduct, setStateWorkProduct] = useState<
    PurposeWorkServicesProduct[]
  >(values.products);
  const [stateName, setStateName] = useState<string | undefined>();
  const [stateDescription, setStateDescription] = useState<
    string | undefined
  >();
  const [stateNameError, setStateNameError] = useState<string | undefined>();
  const [stateDescriptionError, setStateDescriptionError] = useState<
    string | undefined
  >();
  const [stateIndex, setStateIndex] = useState<number | undefined>();
  const [formDOM, setFormDOM] = useState<HTMLFormElement | null>(null);
  // const [fileType, setFileType] = useState("");
  const [tempFilename, setTempFilename] = useState<string | undefined>();
  const [statePhotoToUpload, setStatePhotoToUpload] = useState<
    ToUpload | undefined
  >();
  const [isAdding, setIsAdding] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    if (
      values &&
      (values.isPurposeProduct === undefined ||
        values.isPurposeProduct === false)
    ) {
      handleSaveAndContinue(values);
    }
  }, [values, handleSaveAndContinue]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imgStr = reader.result as string;

        let fileType = mime.extension(file.type);
        if (!fileType) {
          fileType = "png";
        }
        setTempFilename("product-" + stateWorkProduct?.length);
        // setFileType(fileType);
        setStatePhotoToUpload({
          filename: "product-" + stateWorkProduct?.length,
          file: file,
          dataUrl: imgStr,
        });
      };
      reader.readAsDataURL(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    minSize: 0,
    maxSize: 2097152, // 2MB max
    onDrop,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div className={styles["button-drop-zone-label-errors"]}>
        {errors.map((e) => (
          <span key={e.code}>{e.message}</span>
        ))}
      </div>
    );
  });

  const onSubmit = () => {
    values.purposeDescription = undefined;
    values.purposeName = undefined;
    setStateName("");
    setStateDescription("");
    setStateIndex(undefined);
    handleSaveAndContinue(values);
  };

  const pushProductPurposes = (
    purposes: PurposeWorkServicesProduct[],
    loc: any
  ) => {
    if (stateIndex !== undefined) {
      purposes[stateIndex] = {
        id: stateIndex,
        title: stateName,
        description: stateDescription,
        photoUrl: loc
          ? {
              file: statePhotoToUpload?.file!,
              filename: loc,
              dataUrl: statePhotoToUpload?.dataUrl!,
            }
          : statePhotoToUpload,
        oldFilename: tempFilename,
      };
      setStateWorkProduct(purposes);

      values.purposeDescription = undefined;
      values.purposeName = undefined;
      setStatePhotoToUpload(undefined);
      setTempFilename(undefined);
      setStateName("");
      setStateDescription("");
      setStateIndex(undefined);

      formDOM?.reset();
    } else {
      purposes.push({
        id: stateWorkProduct.length,
        title: stateName,
        description: stateDescription,
        photoUrl: loc
          ? {
              file: statePhotoToUpload?.file!,
              filename: loc,
              dataUrl: statePhotoToUpload?.dataUrl!,
            }
          : statePhotoToUpload,
        oldFilename: tempFilename,
      });
      setStateWorkProduct(purposes);

      values.purposeDescription = undefined;
      values.purposeName = undefined;
      setStatePhotoToUpload(undefined);
      setTempFilename(undefined);
      setStateName("");
      setStateDescription("");
      setStateIndex(undefined);

      formDOM?.reset();
    }
    setIsAdding(false);
    setShowAdd(false);
  };

  const handleAdd = () => {
    setIsAdding(true);
    if (!stateName) {
      setIsAdding(false);
      setShowAdd(false);
      return;
    }
    if (!stateDescription) {
      setIsAdding(false);
      setShowAdd(false);
      return;
    }

    let purposes: PurposeWorkServicesProduct[] = stateWorkProduct;
    if (
      statePhotoToUpload !== undefined &&
      statePhotoToUpload.dataUrl &&
      statePhotoToUpload.file &&
      statePhotoToUpload.filename
    ) {
      const reader = new FileReader();
      reader.onabort = () => console.log("File reading was aborted");
      reader.onerror = () => console.log("File reading has failed");
      reader.onload = () => {
        const imgStr = reader.result as string;
        let fileType = mime.extension(statePhotoToUpload.file.type);
        if (!fileType) {
          fileType = "png";
        }
        if (imgStr)
          urltoFile(
            imgStr,
            statePhotoToUpload.filename,
            fileType,
            statePhotoToUpload.file.type
          ).then(function (file) {
            uploadFile(
              file,
              values.url,
              statePhotoToUpload.filename,
              s3ConfigState
            ).then(function (loc) {
              pushProductPurposes(purposes, loc);
            });
          });
      };

      reader.readAsDataURL(statePhotoToUpload.file);
    } else {
      pushProductPurposes(purposes, undefined);
    }
  };

  const handleDelete = (index: number) => {
    let purposes: PurposeWorkServicesProduct[] = stateWorkProduct;
    var filteredPurposes = purposes.filter((e) => e.id !== index);

    setStateWorkProduct(filteredPurposes);
    setStateDescription("");
    setStateName("");
    setStateIndex(undefined);
    setStatePhotoToUpload(undefined);
    setTempFilename(undefined);
    values.products = filteredPurposes;
    values.purposeName = undefined;
    values.purposeDescription = undefined;
    formDOM?.reset();
    return;
  };

  const handleNameChange = (name: string) => {
    if (!name || name === "") {
      setStateNameError(t("setup_wizard_19"));
    } else {
      setStateNameError(undefined);
      setStateName(name);
      values.purposeName = name;
    }
  };

  const handleDescriptionChange = (description: string) => {
    if (!description || description === "") {
      setStateDescriptionError(t("setup_wizard_20"));
    } else {
      setStateDescriptionError(undefined);
      setStateDescription(description);
      values.purposeDescription = description;
    }
  };

  function EditButton({
    i,
    index,
    serviceTitle,
    serviceDescription,
    servicePhotoUrl,
    tempFilename,
  }: Props): JSX.Element {
    let shortTitle = serviceTitle;

    if (serviceTitle)
      shortTitle =
        serviceTitle?.length > 20
          ? serviceTitle?.substring(0, 20) + "..."
          : serviceTitle;

    return (
      <Button
        type="button"
        key={i}
        style={{
          width: "100%",
          textAlign: "left",
          borderRadius: "32px",
          color: "#007bff",
          backgroundColor: "#fff",
          border: "1px solid #007bff",
          marginBottom: "12px",
        }}
        onClick={() => {
          setStateDescription(serviceDescription);
          setStateName(serviceTitle);
          setStateIndex(index);
          setStatePhotoToUpload(servicePhotoUrl);
          setTempFilename(tempFilename);
          values.purposeName = serviceTitle;
          values.purposeDescription = serviceDescription;
        }}
      >
        <EditRounded /> {shortTitle}
      </Button>
    );
  }

  return (
    <Form noValidate ref={(form: any) => setFormDOM(form)}>
      <Modal.Header>
        <Modal.Title style={{ width: "100%" }}>
          <h6>{t("setup_wizard_81")}</h6>
          <div className={styles["setup-subheader"]}>
            <div className={styles["setup-subheader-main"]}>
              {t("setup_wizard_22")}
            </div>
            <div
              className={styles["setup-subheader-skip"]}
              onClick={() => {
                handleSkip(values);
              }}
            >
              {t("setup_wizard_4")}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row}>
          <Col>
            {values.products &&
            values.products.length > 0 &&
            stateIndex === undefined &&
            !showAdd ? (
              <>
                <div className={styles["setup-list-label"]}>
                  {t("setup_wizard_107")}
                </div>
                {values.products.map((service, i) => (
                  <EditButton
                    i={i}
                    index={service.id}
                    serviceTitle={service.title}
                    serviceDescription={service.description}
                    servicePhotoUrl={service.photoUrl}
                    tempFilename={service.oldFilename}
                  />
                ))}
                <div
                  style={{
                    width: "100%",
                    borderTop: "1px solid rgba(0, 0, 0, 0.125)",
                    marginTop: "24px",
                    marginBottom: "6px",
                  }}
                />
              </>
            ) : (
              <></>
            )}
            {values.products &&
            values.products.length > 0 &&
            stateIndex === undefined &&
            !showAdd ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="button"
                  onClick={() => {
                    setShowAdd(true);
                  }}
                  style={{
                    borderRadius: 32,
                    color: "#007bff",
                    backgroundColor: "white",
                    borderColor: "#007bff",
                    marginTop: "28px",
                  }}
                >
                  {t("setup_wizard_151")}
                </Button>
              </div>
            ) : (
              <>
                <div className={styles["setup-label"]}>
                  {t("setup_wizard_84")}
                  <span>*</span>
                </div>
                <Form.Control
                  type="text"
                  name="name"
                  value={stateName}
                  onChange={(e: React.ChangeEvent<any>) => {
                    handleNameChange(e.target.value);
                  }}
                  placeholder={t("setup_wizard_86")}
                  size="lg"
                />
                <span className={styles["invalid"]}>{stateNameError}</span>
                <div
                  className={styles["setup-label"]}
                  style={{ marginTop: "24px" }}
                >
                  {t("setup_wizard_85")}
                  <span>*</span>
                </div>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="description"
                  value={stateDescription}
                  onChange={(e: React.ChangeEvent<any>) => {
                    handleDescriptionChange(e.target.value);
                  }}
                  placeholder={t("setup_wizard_87")}
                  size="lg"
                  style={{ minHeight: "160px" }}
                />
                <span className={styles["invalid"]}>
                  {stateDescriptionError}
                </span>
                <div
                  {...getRootProps()}
                  className={`${styles["button-dropzone"]}  ${styles["create-option"]}`}
                  style={{ marginTop: "24px" }}
                >
                  <input {...getInputProps()} />
                  <div className={styles["button-drop-zone-label-container"]}>
                    <PhotoAlbumRounded />{" "}
                    {tempFilename === undefined
                      ? t("setup_wizard_78")
                      : t("setup_wizard_79")}
                    {fileRejectionItems.length > 0 ? fileRejectionItems : <></>}
                  </div>
                  <div style={{ fontSize: "14px", fontWeight: "lighter" }}>
                    {tempFilename ? tempFilename : ""}
                  </div>
                </div>
                <Button
                  type="button"
                  disabled={!stateDescription || !stateName}
                  style={{ borderRadius: 32, width: "100%", marginTop: "36px" }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleAdd();
                  }}
                >
                  {isAdding ? (
                    <Spinner
                      size="sm"
                      animation="border"
                      variant="light"
                      className={styles["saving-icon"]}
                      style={{ marginRight: "4px", marginBottom: "2px" }}
                    />
                  ) : (
                    <></>
                  )}
                  {stateIndex === undefined
                    ? t("setup_wizard_23")
                    : t("setup_wizard_24")}
                </Button>
              </>
            )}
            {stateIndex !== undefined ? (
              <Button
                type="button"
                disabled={stateIndex === undefined}
                style={{
                  borderRadius: 32,
                  width: "100%",
                  marginTop: "12px",
                  borderColor: "",
                  border: "2px solid #df2935",
                  backgroundColor: "white",
                  color: "#df2935",
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  handleDelete(stateIndex);
                }}
              >
                {t("setup_wizard_74")}
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: showAdd ? "start" : "space-between" }}
      >
        {!showAdd ? (
          <>
            <Button
              type="button"
              onClick={
                stateIndex !== undefined
                  ? () => {
                      setStateDescription("");
                      setStateName("");
                      setStateIndex(undefined);
                      setTempFilename(undefined);
                      setStatePhotoToUpload(undefined);
                      values.purposeName = undefined;
                      values.purposeDescription = undefined;
                      formDOM?.reset();
                    }
                  : handleStepBack
              }
              style={{
                borderRadius: 32,
                color: "#007bff",
                backgroundColor: "white",
                borderColor: "#007bff",
              }}
            >
              {stateIndex !== undefined
                ? t("setup_wizard_70")
                : t("login_page_back")}
            </Button>
            {stateIndex !== undefined ? (
              <></>
            ) : (
              <Button
                type="button"
                disabled={values.products.length === 0}
                style={{
                  borderRadius: 32,
                }}
                onClick={onSubmit}
              >
                {t("setup_page_15")}
              </Button>
            )}
          </>
        ) : (
          <div style={{ justifyContent: "start" }}>
            <Button
              type="button"
              onClick={() => {
                setShowAdd(false);
              }}
              style={{
                borderRadius: 32,
                color: "#007bff",
                backgroundColor: "white",
                borderColor: "#007bff",
              }}
            >
              {stateIndex !== undefined
                ? t("setup_wizard_70")
                : t("login_page_back")}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Form>
  );
};
