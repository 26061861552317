import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getRequestIsInProgress } from "../api/selectors";
import { PlanCategoryEnum, SelectStep0Form } from "./SelectStep0Form";
import { SelectStep1Form } from "./SelectStep1Form";
import { Loader } from "./Loader/Loader";
import { getSlug } from "./selectors";
import { useEffect } from "react";
import {
  logActivity,
  startDlocalPaymentProcess,
  updateDetails,
} from "auth/authActions";
import {
  getDLocalPaymentStatus,
  getUserCountryCode,
  getUserFirstName,
  getUserId,
  getUserLastName,
  getUsername,
  getUserWebsiteSlug,
  getPaypalAnnualPlanId,
  getPaypalAnnualPrice,
  getPaypalCountryCode,
  getPaypalCountryIsoLanguageCode,
  getPaypalCurrencyCode,
  getPaypalMonthlyPlanId,
  getPaypalPrice,
} from "auth/selectors";
import { getCountry, getCountryCode } from "../admin/utils-country";
import { getWebsite } from "editor/states/selectors";
import { EmailVerifyForm } from "./EmailVerifyForm";

export interface ISelectFormValues {
  title: string;
  category: PlanCategoryEnum;
  billingName: string;
  email: string;
  documentNumber: string;
  cardToken: string;
}

export interface ISelectProps {
  values: ISelectFormValues;
  handleSaveAndContinue: (valuesToUpdate: Partial<ISelectFormValues>) => void;
  isSaving: boolean;
  handleStepBack: () => void;
  price?: number;
  annualPrice?: number;
  currency?: string;
  countryCode?: string;
  isoLangCode?: string;
  monthlyPlanId?: string;
  annualPlanId?: string;
}

export const Select: React.FunctionComponent = () => {
  const [values, setValues] = useState<ISelectFormValues>({
    title: "",
    category: PlanCategoryEnum.NONE,
    billingName: "",
    email: "",
    documentNumber: "",
    cardToken: "",
  });
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const isRequestInProgress = useSelector(getRequestIsInProgress);
  const userWebsiteSlug = useSelector(getSlug);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const userId = useSelector(getUserId);
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const userName = useSelector(getUsername);
  const stateCountryCode = useSelector(getUserCountryCode);
  const [countryCode, setCountryCode] = useState(stateCountryCode);
  const paypalAnnualPrice = useSelector(getPaypalAnnualPrice);
  const paypalCountryCode = useSelector(getPaypalCountryCode);
  const paypalCountryIsoLanguageCode = useSelector(
    getPaypalCountryIsoLanguageCode
  );
  const paypalCurrencyCode = useSelector(getPaypalCurrencyCode);
  const paypalPrice = useSelector(getPaypalPrice);
  const paypalMonthlyPlanId = useSelector(getPaypalMonthlyPlanId);
  const paypalAnnualPlanId = useSelector(getPaypalAnnualPlanId);
  const dLocalPaymentStatus = useSelector(getDLocalPaymentStatus);

  const dispatch = useDispatch();
  const steps = [EmailVerifyForm, SelectStep0Form, SelectStep1Form];

  useEffect(() => {
    dispatch(logActivity("WEBSITE SELECT START", "Select Wizard", userName));
  }, [dispatch, userName]);

  useEffect(() => {
    if (dLocalPaymentStatus && dLocalPaymentStatus === "SUCCESS") {
      history.push("/admin/setup");
    }
  }, [dLocalPaymentStatus, history]);

  useEffect(() => {
    if (userWebsiteSlug || websiteSlug) {
      history.push("/admin/setup");
    } else {
      history.push("/admin/select");
    }
  }, [userWebsiteSlug, websiteSlug, website, history]);

  useEffect(() => {
    const countryCode = getCountryCode();
    const country = getCountry(countryCode);

    setCountryCode(countryCode);

    //Update Author Country
    if (countryCode && country && userId && firstName && lastName) {
      dispatch(
        updateDetails(userId, firstName, lastName, countryCode, country)
      );
    }
  }, [userId, firstName, lastName, dispatch]);

  const handleSaveAndContinue = (
    valuesToUpdate: Partial<ISelectFormValues>
  ) => {
    const updatedValues = { ...values, ...valuesToUpdate };
    setValues(updatedValues);

    if (currentStep === steps.length - 1) {
      const price =
        values.category === PlanCategoryEnum.PAID_ANNUAL
          ? paypalAnnualPrice
          : paypalPrice;
      if (
        userId &&
        userName &&
        countryCode &&
        price &&
        paypalCurrencyCode &&
        paypalCountryCode
      ) {
        dispatch(
          startDlocalPaymentProcess(
            userId,
            price,
            paypalCurrencyCode,
            paypalCountryCode,
            updatedValues.billingName,
            updatedValues.email,
            updatedValues.documentNumber,
            updatedValues.cardToken,
            values.category
          )
        );
      }
    } else {
      if (updatedValues.category === "FREE") {
        history.push("/admin/setup");
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handleStepBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const Step = steps[currentStep];

  return (
    <>
      {userWebsiteSlug ? (
        <Loader />
      ) : (
        <Modal
          size={currentStep === 1 ? "xl" : "lg"}
          centered
          show={true}
          className="urux-setup"
        >
          <Step
            values={values}
            handleSaveAndContinue={handleSaveAndContinue}
            isSaving={isRequestInProgress}
            handleStepBack={handleStepBack}
            price={paypalPrice}
            annualPrice={paypalAnnualPrice}
            currency={paypalCurrencyCode}
            countryCode={paypalCountryCode}
            isoLangCode={paypalCountryIsoLanguageCode}
            annualPlanId={paypalAnnualPlanId}
            monthlyPlanId={paypalMonthlyPlanId}
          />
        </Modal>
      )}
    </>
  );
};
