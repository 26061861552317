import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const authorChild2Template: ElementTemplates = {};

authorChild2Template["articles"] = TemplatesFactory.createDefaultArticles(
  new LeadStyleProperty("1"),
  new RichTextProperty("<h2>About James Jones</h2>"),
  new RTEAlignmentProperty("parent"),
  new ImageStyleProperty("1"),
  new ButtonStyleProperty("hobaw-btn-outline-primary"),
  new LinkProperty("Contct J.J.", "", false, false, false),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor. Etiam sit amet fringilla lorem, vitae dapibus velit. Donec vitae enim gravida, sodales massa ut, dictum ante. Morbi risus ligula, venenatis eget porttitor eu, facilisis at sem.&nbsp;<div><br></div><div>Phasellus et gravida ipsum, non tincidunt ante. Nunc rhoncus libero vitae feugiat condimentum. Maecenas at metus id mauris ultrices hendrerit vitae at elit. Duis laoreet, mi et convallis fermentum, purus sem volutpat nulla, id vulputate risus dui a augue.</div>`
  ),
  new RTEAlignmentProperty("parent"),
  new RichTextProperty("Writer, Producer, and Prolific Twitterati"),
  new RTEAlignmentProperty("parent"),
  "1",
  "2",
  "https://images.unsplash.com/photo-1587428599384-f67a2092769a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
  "2"
);
