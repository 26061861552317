import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import styles from "./Loader.module.scss";
import "./Loader.scss";

export const Loader: React.FunctionComponent = () => {
  const history = useHistory();

  useEffect(
    () => {
      // "Refresh" the page if not unmounted after 2 seconds
      // eslint-disable-next-line react-hooks/exhaustive-deps
      let timer1 = setTimeout(() => {
        if (history && history.push) {
          history.push("/admin");
        } else {
          window.location.replace(
            window.location.protocol + "//" + window.location.host + "/admin"
          );
        }
      }, 2000);
      return () => {
        // Cancel plan to refresh page since unmounting before 2
        clearTimeout(timer1);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles["auth__loader__stage"]}>
      <div className={styles["auth__loader__logo-container"]}>
        <div className={`bounce bounce-7`}>
          <img alt="goodweb loader 1" src="/img/goodweb-bouncing-logo-1.svg" />
        </div>
      </div>
      <img
        className={styles["auth__loader__static"]}
        alt="goodweb loader 2"
        src="/img/goodweb-bouncing-logo-2.svg"
      />
    </div>
  );
};
