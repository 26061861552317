import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";

import {
  reducer as adminReducer,
  TAdminState as AdminState,
  saga as adminSaga,
} from "admin/store";
import { reducer as apiReducer, State as ApiState } from "api/store";
import {
  reducer as authReducer,
  State as AuthState,
  saga as authSaga,
} from "auth/store";
import {
  reducer as editorReducer,
  State as EditorState,
  saga as editorSaga,
} from "editor/store";
import {
  reducer as publicReducer,
  State as PublicState,
  saga as publicSaga,
} from "public/store";

const reducer = combineReducers({
  admin: adminReducer,
  api: apiReducer,
  auth: authReducer,
  editor: editorReducer,
  public: publicReducer,
});

export type State = {
  admin: AdminState;
  api: ApiState;
  auth: AuthState;
  editor: EditorState;
  public: PublicState;
};

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) ||
  compose;
const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunkMiddleware, sagaMiddleware))
);

sagaMiddleware.run(authSaga);
sagaMiddleware.run(adminSaga);
sagaMiddleware.run(editorSaga);
sagaMiddleware.run(publicSaga);
