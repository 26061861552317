import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import * as yup from "yup";

import { IStepProps, PurposeLocation } from "./Setup";

import { useTranslation } from "react-i18next";

import styles from "./Admin.module.scss";

const schema = yup.object({
  purposeName: yup.string(),
  purposeDescription: yup.string(),
});

export type SetupStep3cFormValues = yup.InferType<typeof schema>;

export const SetupStep3cForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleSkip,
}) => {
  const { t } = useTranslation();
  const [stateAddress1, setStateAddress1] = useState<string | undefined>(
    values.location?.address1
  );
  const [stateAddress1Error, setStateAddress1Error] = useState<
    string | undefined
  >();
  const [stateAddress2, setStateAddress2] = useState<string | undefined>(
    values.location?.address2
  );
  const [stateAddress2Error, setStateAddress2Error] = useState<
    string | undefined
  >();
  const [stateCity, setStateCity] = useState<string | undefined>(
    values.location?.city
  );
  const [stateCityError, setStateCityError] = useState<string | undefined>();
  const [stateCountry, setStateCountry] = useState<string | undefined>(
    values.location?.country
  );
  const [stateCountryError, setStateCountryError] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (
      values &&
      (values.isPurposeLocation === undefined ||
        values.isPurposeLocation === false)
    ) {
      handleSaveAndContinue(values);
    }
  }, [values, handleSaveAndContinue]);

  const onSubmit = () => {
    let location: PurposeLocation = {
      address1: stateAddress1,
      address2: stateAddress2,
      city: stateCity,
      country: stateCountry,
    };
    values.location = location;
    handleSaveAndContinue(values);
  };

  const handleAddress1Change = (address1: string) => {
    setStateAddress1(address1);
    if (!address1 || address1 === "") {
      setStateAddress1Error(t("setup_wizard_25"));
    } else {
      setStateAddress1Error(undefined);
      setStateAddress2Error(undefined);
    }
  };

  const handleAddress2Change = (address2: string) => {
    setStateAddress2(address2);
  };

  const handleCityChange = (city: string) => {
    setStateCity(city);
    if (!city || city === "") {
      setStateCityError(t("setup_wizard_26"));
    } else {
      setStateCityError(undefined);
    }
  };

  const handleCountryChange = (country: string) => {
    setStateCountry(country);
    if (!country || country === "") {
      setStateCountryError(t("setup_wizard_27"));
    } else {
      setStateCountryError(undefined);
    }
  };

  return (
    <Form noValidate>
      <Modal.Header>
        <Modal.Title style={{ width: "100%" }}>
          <h6>{t("setup_wizard_81")}</h6>
          <div className={styles["setup-subheader"]}>
            <div className={styles["setup-subheader-main"]}>
              {t("setup_wizard_28")}
            </div>
            <div
              className={styles["setup-subheader-skip"]}
              onClick={() => {
                handleSkip(values);
              }}
            >
              {t("setup_wizard_4")}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row}>
          <Col>
            <div className={styles["setup-label"]}>
              {t("setup_wizard_29")}
              <span>*</span>
            </div>
            <Form.Control
              type="text"
              name="address1"
              value={stateAddress1}
              onChange={(e: React.ChangeEvent<any>) => {
                handleAddress1Change(e.target.value);
              }}
              placeholder={t("setup_wizard_29")}
              size="lg"
            />
            <div className={styles["invalid"]} style={{ marginBottom: "12px" }}>
              {stateAddress1Error}
            </div>
            <div
              className={styles["setup-label"]}
              style={{ marginTop: "24px" }}
            >
              {t("setup_wizard_30")}
            </div>
            <Form.Control
              type="text"
              name="address2"
              value={stateAddress2}
              onChange={(e: React.ChangeEvent<any>) => {
                handleAddress2Change(e.target.value);
              }}
              placeholder={t("setup_wizard_30")}
              size="lg"
            />
            <div className={styles["invalid"]} style={{ marginBottom: "12px" }}>
              {stateAddress2Error}
            </div>
            <div
              className={styles["setup-label"]}
              style={{ marginTop: "24px" }}
            >
              {t("setup_wizard_31")}
              <span>*</span>
            </div>
            <Form.Control
              type="text"
              name="city"
              value={stateCity}
              onChange={(e: React.ChangeEvent<any>) => {
                handleCityChange(e.target.value);
              }}
              placeholder={t("setup_wizard_31")}
              size="lg"
            />
            <div className={styles["invalid"]} style={{ marginBottom: "12px" }}>
              {stateCityError}
            </div>
            <div
              className={styles["setup-label"]}
              style={{ marginTop: "24px" }}
            >
              {t("setup_wizard_32")}
              <span>*</span>
            </div>
            <Form.Control
              type="text"
              name="country"
              value={stateCountry}
              onChange={(e: React.ChangeEvent<any>) => {
                handleCountryChange(e.target.value);
              }}
              placeholder={t("setup_wizard_32")}
              size="lg"
            />
            <div className={styles["invalid"]} style={{ marginBottom: "12px" }}>
              {stateCountryError}
            </div>
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        {/* TODO: For now this is the final step. When it's not the case
                anymore, we need to change this. */}
        <Button
          type="button"
          onClick={handleStepBack}
          style={{
            borderRadius: 32,
            color: "#007bff",
            backgroundColor: "white",
            borderColor: "#007bff",
          }}
        >
          {t("login_page_back")}
        </Button>
        <Button
          type="button"
          disabled={!stateAddress1 || !stateCity || !stateCountry}
          style={{ borderRadius: 32 }}
          onClick={onSubmit}
        >
          {/* {isSaving ? "Loading..." : "Create"} */}
          {t("setup_page_15")}
        </Button>
      </Modal.Footer>
    </Form>
  );
};
