import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type InputProperties = {
  inputStyle: string;
  inputType: string;
};

export function Input({
  properties: { inputType, inputStyle },
  className,
  ...props
}: ElementComponentProps<InputProperties>): JSX.Element {
  return (
    <input
      {...props}
      children={undefined}
      type={inputType}
      className={className + "form-control input-style-" + inputStyle}
    />
  );
}

export const inputElementKey = "input";

elementComponentRegistry.register(inputElementKey, Input);
