import React from "react";
import { useSelector } from "react-redux";
import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";

import { getUserId } from "./selectors";

export const PrivateRoute: React.FunctionComponent<RouteProps> = ({
  ...props
}) => {
  const userId = useSelector(getUserId);
  const location = useLocation();

  const accessToken = localStorage.getItem("accessToken");

  // If userId == undefined
  // Check if Authorization Bearer exists
  if (!userId && !accessToken) {
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
  }

  return !userId ? (
    <Redirect to={{ pathname: "/login", state: { from: location } }} />
  ) : (
    <Route {...props} />
  );
};
