import { ElementDefinition } from "editor/elements/types";

class ElementDefinitionRegistry {
  private readonly _registry: {
    [elementKey: string]: ElementDefinition;
  } = {};

  register(definition: ElementDefinition): void {
    this._registry[definition.elementKey] = definition;
  }

  get(elementKey: string): ElementDefinition {
    return this._registry[elementKey];
  }
}

export const elementDefinitionRegistry = new ElementDefinitionRegistry();
