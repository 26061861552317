import { LocalOfferRounded } from "@material-ui/icons";
import React from "react";

import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import { LogoTextProperty } from "editor/properties/LogoTextProperty/LogoTextProperty";
import { AlternateLogoStyleProperty } from "editor/properties/AlternateLogoStyleProperty/AlternateLogoStyleProperty";
import { LogoDecorationProperty } from "editor/properties/LogoDecorationProperty";
import { AlternateLogoImageProperty } from "editor/properties/AlternateLogoImageProperty/AlternateLogoImageProperty";
import {
  AlternateLogoContainerProperties,
  alternatelogoContainerElementKey,
} from "common/elements/components/AlternateLogoContainer";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./AlternateLogoContainer.module.scss";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { Formik, Form } from "formik";
import { LogoStyleProperty } from "editor/properties";

import { useTranslation } from "react-i18next";

export class AlternateLogoContainerElement {
  static readonly elementKey = alternatelogoContainerElementKey;
  static readonly label = "element_alternatelogocontainer_label";
  static readonly icon = LocalOfferRounded;
  static readonly allowedToolbarPositions = ["left", "right"];
  static readonly elementDescription =
    "element_alternatelogocontainer_description";
  static readonly dialogPositionStatic = false;
  static readonly isDeletable = true;

  static readonly properties: PropertiesOf<AlternateLogoContainerProperties> = {
    logoText: LogoTextProperty,
    logoStyle: AlternateLogoStyleProperty,
    logoDecoration: LogoDecorationProperty,
    logoImage: AlternateLogoImageProperty,
  };

  static renderProperties(element: ElementType) {
    return <AlternateLogoContainerPropertiesComponent element={element} />;
  }

  static create(properties: {
    label: string;
    logoText: LogoTextProperty;
    logoStyle: LogoStyleProperty;
    logoDecoration: string;
    logoImage: AlternateLogoImageProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const AlternateLogoContainerPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<
    typeof AlternateLogoContainerElement.properties
  >(AlternateLogoContainerElement.properties, element);

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey={"upload"}>
        <Tab eventKey="upload" title={t("upload_logo")}>
          <Formik initialValues={{}} onSubmit={async (values) => {}}>
            <Form className="form">{renderer.render("logoImage")}</Form>
          </Formik>
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(AlternateLogoContainerElement);
