import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  BarChartRounded,
  CreateRounded,
  HomeRounded,
  RemoveRedEyeRounded,
  VisibilityRounded,
} from "@material-ui/icons";

import {
  getUserFirstName,
  getUserId,
  getUserLastName,
  getUsername,
  getUserWebsiteSlug,
  getWebsiteUniqueId,
} from "../auth/selectors";

import styles from "./Admin.module.scss";
import { Button } from "editor/components/base/Button/Button";
import { PageWithLatestDetails } from "editor/models/types";
import {
  getDailyReports,
  getPageWithLatestDetailsList,
  getWebsite,
} from "editor/states/selectors";
import { fetchWebsite } from "./websiteActions";
import { getWebsiteReportDefault } from "editor/states/reports";
import { getSlug } from "./selectors";
import { fetchCurrentUser, logActivity } from "auth/authActions";
import { dashboardPage, reportsPage } from "./Container";
import { editorPage } from "editor/components/impl/Editor/Editor";
import { Select } from "./Select";
import { useTranslation } from "react-i18next";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { setTawkAttributes, tawkWidgetToggle } from "./Tawk";

import { isDesktopView } from "../admin/utils-country";

type Props = {
  url: string;
};

export const Dashboard: React.FunctionComponent<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const websiteUniqueId = useSelector(getWebsiteUniqueId);
  const website = useSelector(getWebsite);
  const pageList = useSelector(getPageWithLatestDetailsList);
  const dailyReports = useSelector(getDailyReports);
  const userId = useSelector(getUserId);
  const userWebsiteSlugFromCreation = useSelector(getSlug);
  const { t } = useTranslation();
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const username = useSelector(getUsername);
  const tldjs = require("tldjs");
  const { getDomain } = tldjs;

  useEffect(() => {
    document.title = "GoodWeb | Admin Console";
  }, []);

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, []);

  useEffect(() => {
    tawkWidgetToggle(true);
  }, []);

  useEffect(() => {
    if (firstName && lastName && websiteSlug && username) {
      const publishedDomain =
        websiteSlug +
        "." +
        getDomain(document.domain) +
        (window.location.port ? ":" + window.location.port : "");
      setTawkAttributes(firstName + " " + lastName, username, publishedDomain);
    }
  }, [firstName, lastName, username, websiteSlug, getDomain]);

  useEffect(() => {
    if (websiteSlug) {
      if (website.slug === "") {
        dispatch(fetchWebsite(websiteSlug));
      }
      if (userId) {
        dispatch(getWebsiteReportDefault(userId, websiteSlug));
      }
    } else {
      dispatch(fetchCurrentUser());
    }
    if (userId === undefined) {
      history.push("/login");
    }
  }, [
    dispatch,
    history,
    websiteSlug,
    website,
    userId,
    userWebsiteSlugFromCreation,
    websiteUniqueId,
  ]);

  useEffect(() => {
    dispatch(logActivity("VIEW", dashboardPage, username));
  }, [dispatch, username]);

  if (!websiteSlug && !userWebsiteSlugFromCreation) {
    return <Select />;
  }

  const lastPublished = website.publishedAt
    ? "Last published " +
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(website.publishedAt!)
    : t("your_website_has_not_yet_been_published");

  let indexPage: PageWithLatestDetails | undefined;
  pageList.some(function (page) {
    if (page.isIndex) {
      indexPage = page;
      return true;
    }
    return false;
  });

  let indexPageSlug = `${url}/pages/new`;
  if (indexPage) {
    indexPageSlug = `/admin/pages/${indexPage.slug}/edit`;
  }

  const publishedDomain =
    website.slug +
    "." +
    getDomain(document.domain) +
    (window.location.port ? ":" + window.location.port : "");

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <HomeRounded />
          <h3>{t("admin_home")}</h3>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card"]}>
          <div className={styles["admin__card-header"]}>
            <h4>{t("your_website")}</h4>
          </div>
          <div>
            {website.logoCreated ? (
              <div
                className={styles["home-preview"]}
                style={{
                  backgroundImage: `url('https://s3.amazonaws.com/img.goodweb.app/sites/${website.slug}/og1200x627.png')`,
                  backgroundSize: "120%",
                }}
              ></div>
            ) : (
              <div className={styles["home-preview"]}>
                <div className="text-muted">{t("preview_not_available")}</div>
              </div>
            )}
            <div
              className="text-muted"
              style={{ marginTop: "4px", fontSize: ".9rem" }}
            >
              {lastPublished}
            </div>
            <div
              className={
                website.publishedAt
                  ? styles["home-preview-actions"]
                  : styles["home-preview-actions--single"]
              }
            >
              {website.publishedAt ? (
                <Button
                  label="View"
                  variant="none-outline"
                  size="small"
                  isFlat={true}
                  to={publishedDomain}
                  withinDomain={false}
                  newWindow={true}
                  icon={RemoveRedEyeRounded}
                />
              ) : (
                <></>
              )}
              {isDesktop ? (
                <Button
                  label={t("edit_in_website_builder")}
                  variant="none-outline"
                  size="small"
                  isFlat={true}
                  icon={CreateRounded}
                  to={indexPageSlug}
                  onClick={() => {
                    dispatch(
                      logActivity("GO TO", dashboardPage, editorPage, username)
                    );
                  }}
                />
              ) : (
                <div className="text-muted">
                  Editor isn't available on mobile
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div
            className={styles["admin__content-card"]}
            style={{ maxWidth: isDesktop ? "270px" : "100%" }}
          >
            <div className={styles["admin__card-header"]}>
              <h4>{t("quick_report")}</h4>
            </div>
            <div>
              <div
                className={styles["home-report-section"]}
                style={{ marginBottom: 0 }}
              >
                {website.publishedAt &&
                dailyReports &&
                dailyReports.length > 0 ? (
                  <>
                    <div
                      className={styles["home-report"]}
                      style={{ marginTop: 10 }}
                    >
                      <div className={styles["reports_aquisition-indicator"]}>
                        <div className={styles["visitor-indicator"]}>
                          <div className={styles["visitor-indicator-icon"]}>
                            <VisibilityRounded />
                          </div>
                          {
                            dailyReports[dailyReports.length - 1]
                              .dailyTotalVisitorsCount
                          }
                        </div>
                        <div className={styles["visitor-info"]}>
                          <span>Views</span>
                          {
                            dailyReports[dailyReports.length - 1]
                              .dailyTotalVisitorsCount
                          }{" "}
                          Views from all your pages. <br /> <br />
                          {dailyReports[dailyReports.length - 1]
                            .dailyTotalVisitorsChange >= 0 ? (
                            <span className={styles["visitor-info-green"]}>
                              {
                                dailyReports[dailyReports.length - 1]
                                  .dailyTotalVisitorsChange
                              }
                              % Better compared to the day before
                            </span>
                          ) : (
                            <span className={styles["visitor-info-red"]}>
                              {
                                dailyReports[dailyReports.length - 1]
                                  .dailyTotalVisitorsChange
                              }
                              % Worse compared to the day before
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles["home-report-notification"]}>
                    <div
                      className="text-muted"
                      style={{ marginTop: "4px", fontSize: ".9rem" }}
                    >
                      {!dailyReports || dailyReports.length <= 0 ? (
                        <>{t("reports_not_available")}</>
                      ) : (
                        <>{t("reports_arent_available")}</>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles["home-preview-actions"]}
                style={{ marginTop: 16 }}
              >
                <Button
                  label={t("view_reports_page")}
                  variant="none-outline"
                  size="small"
                  isFlat={true}
                  icon={BarChartRounded}
                  to={url + "/reports"}
                  style={{ margin: "auto" }}
                  onClick={() => {
                    dispatch(
                      logActivity("GO TO", dashboardPage, reportsPage, username)
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div
          className={`${styles["admin__content-card"]} ${styles["admin__content-card--warning"]}`}
        >
          <div>
            <div className={styles["home-support"]}>
              <div className={styles["admin__card-header--small"]}>
                <h5>
                  <span
                    role="img"
                    aria-label="Help Notice"
                    style={{ marginRight: "6px" }}
                  >
                    🙋{" "}
                  </span>{" "}
                  {t("need_help")}
                </h5>
              </div>
              <div
                className="text-muted"
                style={{ marginTop: "4px", fontSize: ".9rem" }}
              >
                {t("reach_out")}{" "}
                <Link to={url + "/support"}>{t("support_site")}</Link>{" "}
                {t("remember")}&nbsp;
                <a
                  href="https://goodweb.tawk.help/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("docs_site")}
                </a>{" "}
                {t("how_to_faq")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TawkMessengerReact
        propertyId="63b56d68c2f1ac1e202ba11d"
        widgetId="1glub6oqr"
      />
    </>
  );
};
