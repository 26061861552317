import { EditRounded, NoteAddRounded } from "@material-ui/icons";
import { logActivity } from "auth/authActions";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";
import {
  getHoveredElementId,
  getHoveredElementIndex,
  getPageSlug,
} from "editor/selectors";
import { templateInserted } from "editor/states/layout";
import { getCompositeLayout, getWebsite } from "editor/states/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editorPage } from "../Editor/Editor";
import { setSelectedElement } from "../Editor/editorActions";
import * as addToolbarActions from "../AddElementToolbar/addToolbarActions";
import * as addToolbarGuidesActions from "../AddElementToolbar/addToolbarGuidesActions";
import styles from "./CardsPropDialog.module.scss";
import { useTranslation } from "react-i18next";

export const CardsPropDialog: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dialogWidth = 110;
  const dialogHeight = 220;

  const dispatch = useDispatch();

  const hoveredElementId = useSelector(getHoveredElementId);
  const hoveredElementIndex = useSelector(getHoveredElementIndex);
  const layout = useSelector(getCompositeLayout);
  const pageSlug = useSelector(getPageSlug);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const username = useSelector(getUsername);

  const [cardsSectionTop, setCardsSectionTop] = useState(0);
  const [isCardsSection, setIsCardsSection] = useState(false);
  const [cardsContainerElementId, setCardsContainerElementId] = useState<
    string
  >();

  useEffect(() => {
    const elem = layout["root"];
    let cardsIds: string[] = [];
    if (elem && elem.elementChildrenId && elem.elementChildrenId.length > 0) {
      elem.elementChildrenId.forEach((childId) => {
        const elemChild = layout[childId];
        if (elemChild && elemChild.elementKey === "cards") {
          cardsIds.push(elemChild.elementId);
          if (elemChild.elementChildrenId) {
            cardsIds = loopThroughChildren(
              cardsIds,
              elemChild.elementChildrenId
            );
          }
        }
      });
    }

    if (hoveredElementId && cardsIds.includes(hoveredElementId)) {
      setIsCardsSection(true);
    } else {
      setIsCardsSection(false);
      setCardsSectionTop(0);
    }

    function loopThroughChildren(
      cardsChildrenContainer: string[],
      idsToLoop: string[]
    ) {
      idsToLoop.forEach((id) => {
        cardsChildrenContainer.push(id);
        const elem = layout[id];
        if (elem.elementKey === "cards-container") {
          setCardsContainerElementId(elem.elementId);
          const domElem = document.getElementById(elem.elementId);
          if (domElem) {
            const clientRect = domElem.getBoundingClientRect();
            setCardsSectionTop(clientRect.top + window.pageYOffset);
          }
        }
        if (elem && elem.elementChildrenId) {
          cardsChildrenContainer = loopThroughChildren(
            cardsChildrenContainer,
            elem.elementChildrenId
          );
        }
      });
      return cardsChildrenContainer;
    }
  }, [hoveredElementId, layout]);

  if (!isCardsSection) {
    return <></>;
  }

  const handleAddClick = () => {
    if (cardsContainerElementId) {
      const element = layout[cardsContainerElementId];
      dispatch(logActivity("QUICK ADD ELEMENT", editorPage, username, "card"));
      dispatch(
        templateInserted(
          "card",
          cardsContainerElementId,
          element.elementChildrenId ? element.elementChildrenId.length : 0,
          website.logoCreated ? website.logoCreated : false,
          websiteSlug,
          pageSlug
        )
      );
    }
  };

  const handleOpenCardsProperty = (event: React.MouseEvent) => {
    if (cardsContainerElementId) {
      dispatch(
        setSelectedElement(cardsContainerElementId, hoveredElementIndex)
      );
      dispatch(
        logActivity(
          "SELECT ELEMENT / OPEN DIALOG",
          editorPage,
          username,
          "cards-container"
        )
      );
      dispatch(addToolbarActions.disable());
      dispatch(addToolbarGuidesActions.disable());
      event.stopPropagation();
    }
  };

  return (
    <div
      className={styles["cards-prop-dialog"]}
      style={{
        top: cardsSectionTop,
        height: dialogHeight,
        width: dialogWidth,
      }}
    >
      <div className={styles["cards-prop-button"]} onClick={handleAddClick}>
        <NoteAddRounded />
        {t("cards_dialog_1")}
      </div>
      <div
        className={styles["cards-prop-button"]}
        onClick={handleOpenCardsProperty}
      >
        <EditRounded />
        {t("cards_dialog_2")}
      </div>
    </div>
  );
};
