import React, { useEffect } from "react";
import { ContactSupportRounded } from "@material-ui/icons";
import styles from "./Admin.module.scss";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import {
  tawkWidgetToggle,
  toggleWidgetToggle,
  setTawkAttributes,
} from "./Tawk";
import { useSelector } from "react-redux";
import {
  getUserFirstName,
  getUserLastName,
  getUsername,
  getUserWebsiteSlug,
} from "auth/selectors";
import { useTranslation } from "react-i18next";

export const Support: React.FunctionComponent = () => {
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const username = useSelector(getUsername);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const tldjs = require("tldjs");
  const { getDomain } = tldjs;
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "GoodWeb | Support";
  }, []);

  useEffect(() => {
    tawkWidgetToggle(true);
    toggleWidgetToggle(true);
  }, []);

  useEffect(() => {
    if (firstName && lastName && websiteSlug && username) {
      const publishedDomain =
        websiteSlug +
        "." +
        getDomain(document.domain) +
        (window.location.port ? ":" + window.location.port : "");
      setTawkAttributes(firstName + " " + lastName, username, publishedDomain);
    }
  }, [firstName, lastName, username, websiteSlug, getDomain]);

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <ContactSupportRounded />
          <h3>{t("Support")}</h3>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card--medium"]}>
          <div className={styles["admin__card-header"]}>
            <h4>{t("Docs")}</h4>
          </div>
          <div>
            {t("docs_paragraph_1")}{" "}
            <a
              href="https://goodweb.tawk.help/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("docs_paragraph_link")}
            </a>{" "}
            {t("docs_paragraph_2")}
          </div>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card--medium"]}>
          <div className={styles["admin__card-header"]}>
            <h4>{t("Support")}</h4>
          </div>
          <div>{t("support_paragraph")}</div>
        </div>
      </div>
      <img
        alt="Arrow to support"
        src="/img/arrow-yellow-support.svg"
        style={{
          height: "24%",
          position: "absolute",
          right: "380px",
        }}
      />
      <TawkMessengerReact
        propertyId="63b56d68c2f1ac1e202ba11d"
        widgetId="1glub6oqr"
      />
    </>
  );
};
