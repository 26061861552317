import { Reducer } from "redux";

import { PageResource } from "@urux/arm-of-dorne-api";
import { Page } from "./models";
import { Action, FetchPagesSuccessAction } from "./pageActions";

export type State = {
  pages: Page[];
};

const initialState: State = {
  pages: [],
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case "ADMIN_FETCH_PAGES_SUCCESS":
      return handleFetchPagesSuccess(state, action);

    default:
      return state;
  }
};

const handleFetchPagesSuccess = (
  state: State,
  action: FetchPagesSuccessAction
): State => {
  return {
    ...state,
    pages: action.pages.map(mapPageResourceToPage),
  };
};

export const mapPageResourceToPage = (resource: PageResource): Page => {
  return {
    slug: resource.slug!,
    title: resource.title!,
    description: resource.description!,
    isIndex: resource.isIndex!,
  };
};
