import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import { DimensionProperty } from "editor/properties";

export type CoverProperties = {
  coverStyle: string;
  backgroundImageProperty: {
    size: string;
    repeat: string;
    backgroundImageUrl: string;
    positionX: string;
    positionY: string;
  };
  backgroundColorProperty: string;
  dimension?: DimensionProperty;
  backgroundFilter?: {
    color?: string | null;
    opacity?: number | null;
    color2?: string | null;
    opacity2?: number | null;
    isUp?: boolean | null;
  };
};

export function Cover({
  properties: {
    coverStyle,
    backgroundImageProperty,
    backgroundColorProperty,
    dimension,
    backgroundFilter,
  },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<CoverProperties>): JSX.Element {
  let backgroundImage;
  if (backgroundFilter && backgroundFilter.color) {
    if (backgroundFilter.color2) {
      backgroundImage = `linear-gradient(${
        backgroundFilter.isUp ? "" : "to right,"
      } ${backgroundFilter.color}, ${backgroundFilter.color2}), url(${
        backgroundImageProperty.backgroundImageUrl
      })`;
    } else {
      backgroundImage = `linear-gradient(${backgroundFilter.color}, ${backgroundFilter.color}), url(${backgroundImageProperty.backgroundImageUrl})`;
    }
  }

  return (
    <div className="container-fluid">
      <section
        {...props}
        ref={innerRef}
        className={
          className +
          "jq-cover coverstyle-" +
          coverStyle +
          " cover-height-" +
          dimension?.height
        }
        style={{
          backgroundSize: "cover",
          backgroundRepeat: backgroundImageProperty.repeat,
          backgroundImage: backgroundImage
            ? backgroundImage
            : `url(${backgroundImageProperty.backgroundImageUrl})`,
          backgroundPositionX: backgroundImageProperty.positionX,
          backgroundPositionY: backgroundImageProperty.positionY,
          backgroundColor: backgroundColorProperty,
        }}
      >
        <div
          id={"internal-cover-holder-" + props.id}
          className="jq-cover-guide pf cg-df-dg"
        >
          <div className={"section-header"}>{children}</div>
        </div>
      </section>
    </div>
  );
}

export const coverElementKey = "cover";

elementComponentRegistry.register(coverElementKey, Cover);
