import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  getUserFirstName,
  getUserLastName,
  getUsername,
  getUserWebsiteSlug,
} from "../auth/selectors";
import {
  getPageWithLatestDetailsList,
  getWebsite,
} from "editor/states/selectors";

import {
  CreateRounded,
  BuildRounded,
  OpenInBrowserRounded,
  OpenInNewRounded,
  AddRounded,
} from "@material-ui/icons";
import { Button } from "editor/components/base/Button/Button";
import { latestPageVersionListRequested } from "editor/states/latestPageVersionList";
import { pageListRequested } from "editor/states/pageList";

import styles from "./Admin.module.scss";
import PageListAccordion from "editor/components/impl/PageListPropertiesDialog/PageListAccordion";
import { PageWithLatestDetails } from "editor/models/types";
import { fetchWebsite } from "./websiteActions";
import { WebsiteSettingsDialog } from "./WebsiteSettingsDialog/WebsiteSettingsDialog";
import { logActivity } from "auth/authActions";
import { settingsPage } from "./Container";
import { editorPage } from "editor/components/impl/Editor/Editor";
import { useTranslation } from "react-i18next";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { setTawkAttributes, tawkWidgetToggle } from "./Tawk";
import { isDesktopView } from "../admin/utils-country";

export const Settings: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const website = useSelector(getWebsite);
  const pageList = useSelector(getPageWithLatestDetailsList);
  const dispatch = useDispatch();
  const [showWebsiteDetailsDialog, setShowWebsiteDetailsDialog] = useState(
    false
  );

  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const username = useSelector(getUsername);
  const tldjs = require("tldjs");
  const { getDomain } = tldjs;

  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, []);

  useEffect(() => {
    document.title = "GoodWeb | Settings";
  }, []);

  useEffect(() => {
    tawkWidgetToggle(true);
  }, []);

  useEffect(() => {
    if (firstName && lastName && websiteSlug && username) {
      const publishedDomain =
        websiteSlug +
        "." +
        getDomain(document.domain) +
        (window.location.port ? ":" + window.location.port : "");
      setTawkAttributes(firstName + " " + lastName, username, publishedDomain);
    }
  }, [firstName, lastName, username, websiteSlug, getDomain]);

  const handleShowWebsiteDetailsDialog = () => {
    showWebsiteDetailsDialog
      ? setShowWebsiteDetailsDialog(false)
      : setShowWebsiteDetailsDialog(true);
    if (showWebsiteDetailsDialog) {
      dispatch(
        logActivity("CLOSE DIALOG", settingsPage, username, "Website Dialog")
      );
    } else {
      dispatch(
        logActivity("OPEN DIALOG", settingsPage, username, "Website Dialog")
      );
    }
  };

  useEffect(() => {
    if (websiteSlug) {
      dispatch(fetchWebsite(websiteSlug));
      dispatch(pageListRequested(websiteSlug));
      dispatch(latestPageVersionListRequested(websiteSlug));
    }
  }, [dispatch, websiteSlug]);

  useEffect(() => {
    dispatch(logActivity("VIEW", settingsPage, username));
  }, [dispatch, username]);

  if (!websiteSlug) {
    return <></>;
  }

  let indexPage: PageWithLatestDetails | undefined;
  pageList.some(function (page) {
    if (page.isIndex) {
      indexPage = page;
      return true;
    }
    return false;
  });

  let indexPageSlug = `/admin/pages/new`;
  if (indexPage) {
    indexPageSlug = `/admin/pages/${indexPage.slug}/edit`;
  }

  const lastPublished = website.publishedAt
    ? "Last published " +
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(website.publishedAt!)
    : t("website_not_published");

  const publishedDomain =
    website.slug +
    "." +
    getDomain(document.domain) +
    (window.location.port ? ":" + window.location.port : "");

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <BuildRounded />
          <h3>{t("website_settings")}</h3>
        </div>
        {isDesktop ? (
          <Button
            to={indexPageSlug}
            variant="primary"
            label={t("go_to_builder")}
            icon={OpenInBrowserRounded}
            onClick={() => {
              dispatch(
                logActivity("GO TO", settingsPage, username, editorPage)
              );
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card"]}>
          <div className={styles["admin__card-header"]}>
            <h4>{t("details")}</h4>
            <Button
              label={t("edit")}
              variant="none-outline"
              size="small"
              isFlat={true}
              icon={CreateRounded}
              onClick={handleShowWebsiteDetailsDialog}
            />
          </div>
          <div>
            <span className={styles["admin__content-lead"]}>
              {t("website_title")}
            </span>
            <span className={styles["admin__content-entry"]}>
              {website.title}
            </span>
            <span className={styles["admin__content-lead"]}>{t("type")}</span>
            <span
              className={`${styles["admin__content-entry"]} ${styles["admin__content-entry--capitalize"]}`}
            >
              {website.category}
            </span>
            <span className={styles["admin__content-lead"]}>{t("url")}</span>
            <span className={styles["admin__content-entry"]}>
              <a
                href={`//${publishedDomain}`}
                className={
                  website.publishedAt ? `` : `${styles["admin__link-disabled"]}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {publishedDomain} <OpenInNewRounded />
              </a>
            </span>
            <span className={styles["admin__content-small"]}>
              {lastPublished}
            </span>
          </div>
        </div>
        <div className={styles["admin__content-card--big"]}>
          <div className={styles["admin__card-header"]}>
            <h4>{t("pages")}</h4>
            {isDesktop ? (
              <Button
                to={"/admin/pages/new"}
                label={t("create_new_page")}
                variant="none-outline"
                size="small"
                isFlat={true}
                icon={AddRounded}
                onClick={() => {
                  dispatch(
                    logActivity("CREATE NEW PAGE", settingsPage, username)
                  );
                }}
              />
            ) : (
              <></>
            )}
          </div>
          <PageListAccordion pageList={pageList} pageContainer={settingsPage} />
        </div>
      </div>
      <WebsiteSettingsDialog
        handleShowWebsiteDetailsDialog={handleShowWebsiteDetailsDialog}
        showDialog={showWebsiteDetailsDialog}
        website={website}
      />
      <TawkMessengerReact
        propertyId="63b56d68c2f1ac1e202ba11d"
        widgetId="1glub6oqr"
      />
    </>
  );
};
