import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";

import {
  logout,
  getUserProvider as getProvider,
  logActivity,
  getDlocalCountryReference,
  resendEmailVerify,
  verifyToken,
  getPaypalCountryReference,
  getPaymentSettingsForCountry,
} from "../auth/authActions";
import {
  getIsUserEmailVerified,
  getUserCountryCode,
  getUserId,
  getUsername,
  getUserProvider,
  getUserRole,
  getUserWebsiteSlug,
} from "../auth/selectors";
import { Dashboard } from "./Dashboard";
import { Settings } from "./Settings";
import { getWebsiteTitle } from "./selectors";
import { Setup } from "./Setup";
import { fetchWebsite } from "./websiteActions";

import styles from "./Admin.module.scss";
import { Sidebar } from "./Sidebar/Sidebar";
import { SAConsole } from "./SAConsole";
import { Reports } from "./Reports";
import { AccountDetails } from "./AccountDetails";
import { PaymentDetails } from "./PaymentDetails";

import { useIdleTimer } from "react-idle-timer";
import { getPageTemplate } from "editor/components/impl/Editor/editorActions";
import { Tests } from "./Tests";
import { Select } from "./Select";
import { getSubscription } from "editor/states/selectors";
import { getSubscriptionAndPayments } from "editor/states/reports";
import { ElementStyles } from "./ElementStyles";
import { ABMocker } from "./ABMocker";
import { Support } from "./Support";
import { Toast } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { UpgradeSubscription } from "./UpgradeSubscription";

export const dashboardPage = "Dashboard";
export const selectPage = "Select Tier";
export const setupPage = "Setup";
export const settingsPage = "Settings";
export const saConsolePage = "SA Console";
export const elementStylesPage = "Element Styles";
export const abMockerPage = "Mocker";
export const reportsPage = "Reports";
export const testsPage = "Tests";
export const accountPage = "Accounts";
export const paymentPage = "Payment";
export const docsPage = "Documentation";
export const supportPage = "Support";
export const upgradePage = "Become a Premium Member";
export const upgradePageMarker = "Upgrade Page";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const Container: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const title = useSelector(getWebsiteTitle);
  const userId = useSelector(getUserId);
  const username = useSelector(getUsername);
  const isSuper = useSelector(getUserRole);
  const provider = useSelector(getUserProvider);
  const countryCode = useSelector(getUserCountryCode);
  const subscription = useSelector(getSubscription);
  const location = useLocation();
  const isUserVerified = useSelector(getIsUserEmailVerified);
  const [isVerifying, setIsVerifying] = useState<boolean>();
  const [isEmailVerifiedResult, setIsEmailVerifiedResult] = useState<boolean>();
  const [isVerificationRequested, setIsVerificationRequested] = useState<
    boolean
  >();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailResentRequested, setIsEmailResentRequested] = useState(false);
  const [isEmailResent, setIsEmailResent] = useState<undefined | boolean>(
    undefined
  );
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    if (isSuper && userId && userId !== 0) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "traffic_type",
        traffic_type: "internal",
      });
    }
  }, [isSuper, userId]);

  useEffect(() => {
    if (!title && websiteSlug) {
      dispatch(fetchWebsite(websiteSlug));
    }
    if (provider === undefined) {
      dispatch(getProvider());
    }
    if (websiteSlug) {
      dispatch(getPageTemplate(websiteSlug));
    }
    if (userId === undefined) {
      history.push("/login");
    }

    const params = new URLSearchParams(search);
    const verifyParam = params.get("verify");
    if (userId && params && verifyParam && username) {
      dispatch(verifyToken(verifyParam, username, handleVerifyEmail));
      setIsVerifying(true);
      setIsVerificationRequested(true);
    } else {
      setIsVerifying(false);
      if (userId && provider && isUserVerified !== undefined) {
        if (provider === "local") {
          setIsEmailVerified(isUserVerified);
        } else {
          setIsEmailVerified(isUserVerified);
        }
      }
    }
  }, [
    dispatch,
    title,
    websiteSlug,
    provider,
    userId,
    history,
    isUserVerified,
    username,
    search,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const verifyParam = params.get("verify");
    if (isUserVerified && verifyParam) {
      setShow(true);
      setIsEmailVerified(isUserVerified);
    }
  }, [isUserVerified, search]);

  useEffect(() => {
    if (userId && userId !== 0) {
      dispatch(getSubscriptionAndPayments(userId));
    }
  }, [userId, dispatch]);

  const handleOnIdle = (event: any) => {
    window.location.reload(); // After 1 hour of idle time force reload to trigger check if auth is still valid
  };

  useIdleTimer({
    timeout: 1000 * 3600, // 1 hour of idle time
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const getPaymentReferences = (
    countryCode: string,
    isDlocal: boolean,
    isPaypal: boolean
  ) => {
    if (isDlocal) {
      dispatch(getDlocalCountryReference(countryCode));
    }
    if (isPaypal) {
      dispatch(getPaypalCountryReference(countryCode));
    }
  };

  const getPaymentSettings = (countryCode: string) => {
    dispatch(getPaymentSettingsForCountry(countryCode, getPaymentReferences));
  };

  let currentPage = dashboardPage;
  if (location) {
    if (location.pathname === "/admin") {
      currentPage = dashboardPage;
    } else if (location.pathname === "/admin/select") {
      currentPage = selectPage;
      if (countryCode) {
        getPaymentSettings(countryCode);
      }
    } else if (location.pathname === "/admin/setup") {
      currentPage = setupPage;
    } else if (location.pathname === "/admin/settings") {
      currentPage = settingsPage;
    } else if (location.pathname === "/admin/sa-console") {
      currentPage = saConsolePage;
    } else if (location.pathname === "/admin/element-styles") {
      currentPage = elementStylesPage;
    } else if (location.pathname === "/admin/ab-mocker") {
      currentPage = abMockerPage;
    } else if (location.pathname === "/admin/reports") {
      currentPage = reportsPage;
    } else if (location.pathname === "/admin/tests") {
      currentPage = testsPage;
    } else if (location.pathname === "/admin/account") {
      currentPage = accountPage;
    } else if (location.pathname === "/admin/payment") {
      currentPage = paymentPage;
    } else if (location.pathname === "/admin/support") {
      currentPage = supportPage;
    } else if (location.pathname === "/admin/upgrade") {
      currentPage = upgradePage;
      if (countryCode) {
        getPaymentSettings(countryCode);
      }
    }
  }

  const handleEmailResent = (isResent: boolean) => {
    setIsEmailResent(isResent);
  };

  const handleEmailVerify = () => {
    if (username) {
      dispatch(resendEmailVerify(username, handleEmailResent));
      setIsEmailResentRequested(true);
    }
  };

  const handleVerifyEmail = (isVerified: any) => {
    setIsVerifying(false);
    if (isVerified && isVerified === "true") {
      setIsEmailVerifiedResult(true);
    } else {
      setIsEmailVerifiedResult(false);
    }
  };

  const onLogoutClick = () => {
    localStorage.removeItem("wauid");
    localStorage.removeItem("accessToken");
    dispatch(logActivity("LOGOUT", currentPage, username));
    dispatch(logout());
  };

  return (
    <div className={styles["admin__main-container"]}>
      <Sidebar
        url={url}
        onLogoutClick={onLogoutClick}
        currentPage={currentPage}
        subscription={subscription}
      />
      <div className={styles["admin__content"]}>
        <Switch>
          <Route exact strict path={`${path}`}>
            <Dashboard url={url} />
          </Route>
          <Route exact strict path={`${path}/select`}>
            <Select />
          </Route>
          <Route exact strict path={`${path}/setup`}>
            <Setup />
          </Route>
          <Route exact strict path={`${path}/settings`}>
            <Settings />
          </Route>
          <Route exact strict path={`${path}/sa-console`}>
            {isSuper ? <SAConsole /> : <Dashboard url={url} />}
          </Route>
          <Route exact strict path={`${path}/element-styles`}>
            {isSuper ? <ElementStyles /> : <Dashboard url={url} />}
          </Route>
          <Route exact strict path={`${path}/ab-mocker`}>
            {isSuper ? <ABMocker /> : <Dashboard url={url} />}
          </Route>
          <Route exact strict path={`${path}/reports`}>
            <Reports />
          </Route>
          <Route exact strict path={`${path}/tests`}>
            <Tests />
          </Route>
          <Route exact strict path={`${path}/account`}>
            <AccountDetails />
          </Route>
          <Route exact strict path={`${path}/payment`}>
            <PaymentDetails />
          </Route>
          <Route exact strict path={`${path}/upgrade`}>
            <UpgradeSubscription />
          </Route>
          <Route exact strict path={`${path}/support`}>
            <Support />
          </Route>
        </Switch>
      </div>
      {provider === "local" ? (
        isEmailVerified ? (
          <Toast
            style={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
            className={styles["admin__email-toast"]}
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Header className={styles["admin__email-toast__header"]}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">{t("email_verified")}</strong>
            </Toast.Header>
            <Toast.Body className={styles["admin__email-toast__body"]}>
              <>
                <div>
                  {t("email_paragraph_1")} {isEmailVerifiedResult}
                </div>
              </>
            </Toast.Body>
          </Toast>
        ) : isVerifying === false ? (
          <Toast
            style={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
            className={styles["admin__email-toast"]}
            delay={3000}
            autohide={isEmailVerified ? true : false}
          >
            <Toast.Header className={styles["admin__email-toast__header"]}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">{t("email_verify")}</strong>
            </Toast.Header>
            <Toast.Body className={styles["admin__email-toast__body"]}>
              {isEmailVerifiedResult && isEmailVerifiedResult === true ? (
                <div>
                  {t("email_paragraph_2")} {isEmailVerifiedResult}
                </div>
              ) : isVerificationRequested ? (
                <>
                  {t("email_paragraph_3")}
                  <div>
                    {isEmailResentRequested ? (
                      <span>
                        {isEmailResent ? (
                          <>{t("email_paragraph_4")}</>
                        ) : (
                          <>&nbsp;{t("email_paragraph_5")}</>
                        )}
                      </span>
                    ) : (
                      <span onClick={handleEmailVerify}>
                        &nbsp;{t("email_paragraph_6")}
                      </span>
                    )}
                  </div>
                </>
              ) : isEmailResentRequested && isEmailResent !== undefined ? (
                isEmailResent ? (
                  <>{t("email_paragraph_4")}</>
                ) : (
                  <>{t("email_paragraph_7")}</>
                )
              ) : (
                <>
                  {t("email_paragraph_8")}
                  <div>
                    {t("email_paragraph_9")}
                    {isEmailResentRequested ? (
                      <span>&nbsp;{t("email_paragraph_5")}</span>
                    ) : (
                      <span onClick={handleEmailVerify}>
                        &nbsp;{t("email_paragraph_10")}
                      </span>
                    )}
                  </div>
                </>
              )}
            </Toast.Body>
          </Toast>
        ) : (
          <Toast
            style={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
            className={styles["admin__email-toast"]}
          >
            <Toast.Header className={styles["admin__email-toast__header"]}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">{t("email_paragraph_11")}</strong>
            </Toast.Header>
            <Toast.Body className={styles["admin__email-toast__body"]}>
              <>
                <div>{t("email_paragraph_12")}</div>
              </>
            </Toast.Body>
          </Toast>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
