import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Toggle from "react-toggle";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";

import styles from "./BorderProperty.module.scss";
import "./BorderProperty.scss";

import {
  getPrimaryColor,
  getPrimaryShadow,
  getSecondaryColor,
  getTernaryColor,
} from "editor/selectors";

import "../../components/impl/Tools/react-toggle.scss";
import { Range } from "react-range";
import { useTranslation } from "react-i18next";

export class BorderProperty implements IProperty {
  constructor(
    readonly hasBorder: boolean,
    readonly color: string,
    readonly thickness: number,
    readonly shadow: number
  ) {}

  render(elementId: string, propertyKey: string) {
    return (
      <BorderPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): BorderProperty {
    if (value)
      return new BorderProperty(
        value.hasBorder ? value.hasBorder : false,
        value.color,
        value.thickness,
        value.shadow
      );

    return new BorderProperty(false, "#fff", 0, 0);
  }
}

const BorderPropertyComponent: PropertyComponent<BorderProperty> = ({
  elementId,
  propertyKey,
  hasBorder,
  color,
  thickness,
  shadow,
}) => {
  const { t } = useTranslation();
  var tinycolor = require("tinycolor2");
  const dispatch = useDispatch();
  const statePrimaryColor = useSelector(getPrimaryColor);
  const stateSecondaryColor = useSelector(getSecondaryColor);
  const stateTernaryColor = useSelector(getTernaryColor);
  const statePrimaryShadow = useSelector(getPrimaryShadow);
  const [stateBorder, setStateHasBorder] = useState(hasBorder);
  const [stateColor, setStateColor] = useState(color);
  const [stateThickness, setStateThickness] = useState(thickness);
  const [stateShadow, setStateShadow] = useState(shadow);
  const [thicknessRangeValue, setThicknessRangeValue] = useState([1]);
  const [shadowRangeValue, setShadowRangeValue] = useState([0.5]);
  const [primaryColorsRange, setPrimaryColorsRange] = useState<string[]>([
    "#fff",
  ]);
  const [secondaryColorsRange, setSecondaryColorsRange] = useState<string[]>([
    "#fff",
  ]);
  const [ternaryColorsRange, setTernaryColorsRange] = useState<string[]>([
    "#fff",
  ]);
  const [splitComplementsRange, setSplitComplementsRange] = useState<string[]>([
    "#fff",
  ]);
  const defaultColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];

  useEffect(() => {
    if (!color && stateSecondaryColor) {
      setStateColor(stateSecondaryColor);
    }
    if (!thickness) {
      setStateThickness(1);
      setThicknessRangeValue([1]);
    } else {
      setStateThickness(thickness);
      setThicknessRangeValue([thickness]);
    }
    if (!shadow) {
      setStateShadow(0.5);
      setShadowRangeValue([0.5]);
    } else {
      setStateShadow(shadow);
      setShadowRangeValue([shadow]);
    }
  }, [color, thickness, shadow, stateSecondaryColor]);

  useEffect(() => {
    if (statePrimaryShadow && statePrimaryShadow !== "") {
      setShadowRangeValue([Number(statePrimaryShadow)]);
    }
    if (statePrimaryColor) {
      setPrimaryColorsRange([
        tinycolor(statePrimaryColor).darken(25).toString(),
        tinycolor(statePrimaryColor).darken(10).toString(),
        statePrimaryColor,
        tinycolor(statePrimaryColor).lighten(10).toString(),
        tinycolor(statePrimaryColor).lighten(25).toString(),
      ]);
      let splitColors = tinycolor(statePrimaryColor).splitcomplement();
      let splitMap = splitColors.map(function (t: any) {
        return t.toHexString();
      });
      splitMap.shift();
      let tetradColors = tinycolor(statePrimaryColor).tetrad();
      let tetradMap = tetradColors.map(function (t: any) {
        return t.toHexString();
      });
      tetradMap.shift();
      setSplitComplementsRange([
        ...splitMap,
        ...tetradMap,
        tinycolor(statePrimaryColor).greyscale().toString(),
        "#fff",
        "#000",
      ]);
    }

    if (stateSecondaryColor) {
      setSecondaryColorsRange([
        tinycolor(stateSecondaryColor).darken(25).toString(),
        tinycolor(stateSecondaryColor).darken(10).toString(),
        stateSecondaryColor,
        tinycolor(stateSecondaryColor).lighten(10).toString(),
        tinycolor(stateSecondaryColor).lighten(25).toString(),
      ]);
    }

    if (stateTernaryColor) {
      setTernaryColorsRange([
        tinycolor(stateTernaryColor).darken(25).toString(),
        tinycolor(stateTernaryColor).darken(10).toString(),
        stateTernaryColor,
        tinycolor(stateTernaryColor).lighten(10).toString(),
        tinycolor(stateTernaryColor).lighten(25).toString(),
      ]);
    }
  }, [
    statePrimaryColor,
    stateSecondaryColor,
    stateTernaryColor,
    statePrimaryShadow,
    tinycolor,
  ]);

  const handleHasBorderSwitch = () => {
    const currBorder = hasBorder ? false : true;
    setStateHasBorder(currBorder);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BorderProperty(currBorder, stateColor, stateThickness, stateShadow)
      )
    );
  };

  const handleColorChange = (selectedColor: string) => {
    setStateColor(selectedColor);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BorderProperty(
          stateBorder,
          selectedColor,
          stateThickness,
          stateShadow
        )
      )
    );
  };

  const handleThickness = (values: number[]) => {
    setStateThickness(values[0]);
    setThicknessRangeValue(values);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BorderProperty(stateBorder, stateColor, values[0], stateShadow)
      )
    );
  };

  const handleShadow = (values: number[]) => {
    setStateShadow(values[0]);
    setShadowRangeValue(values);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BorderProperty(stateBorder, stateColor, stateThickness, values[0])
      )
    );
  };

  return (
    <div className="medium-padded-container">
      <div className={`${styles["main-controller-container"]}`}>
        <div className={`${styles["controls-container"]}`}>
          <div className={`${styles["toggle-container"]}`}>
            {t("dialog_15")}
            <Toggle
              id="cheese-status"
              defaultChecked={hasBorder}
              onClick={handleHasBorderSwitch}
            />
          </div>
          {hasBorder ? (
            <>
              <div className={`${styles["control-container"]}`}>
                {t("dialog_16")}
                <div className={`picker ${styles["colors-container"]}`}>
                  <div>
                    <span className="helper">{t("dialog_7")}</span>
                    <div className={`${styles["swatches"]}`}>
                      {[
                        ...primaryColorsRange,
                        ...secondaryColorsRange,
                        ...ternaryColorsRange,
                        ...splitComplementsRange,
                      ].map((selectedColor) =>
                        selectedColor ? (
                          <div
                            className="color-pick"
                            key={
                              selectedColor +
                              Math.random().toString(36).slice(-6)
                            }
                            style={{
                              backgroundColor: selectedColor,
                              outline:
                                stateColor === selectedColor
                                  ? "solid 0.5px #2176ff"
                                  : "unset",
                            }}
                            onMouseDown={() => {
                              handleColorChange(selectedColor);
                            }}
                          ></div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <span className="helper">{t("dialog_8")}</span>
                    <div className={`${styles["swatches"]}`}>
                      {[...defaultColors].map((selectedColor) =>
                        selectedColor ? (
                          <div
                            className="color-pick"
                            key={
                              selectedColor +
                              Math.random().toString(36).slice(-6)
                            }
                            style={{
                              backgroundColor: selectedColor,
                              outline:
                                stateColor === selectedColor
                                  ? "solid 0.5px #2176ff"
                                  : "unset",
                            }}
                            onMouseDown={() => {
                              handleColorChange(selectedColor);
                            }}
                          ></div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles["range-container"]}`}>
                {t("dialog_18")}
                <Range
                  step={0.5}
                  min={0}
                  max={5}
                  values={thicknessRangeValue}
                  onChange={(values) => handleThickness(values)}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      className={`${styles["range-tracker"]}`}
                      style={{
                        ...props.style,
                        height: "4px",
                        width: "100%",
                        backgroundColor: "#cfcfcf",
                        borderRadius: "4px",
                        boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      className={`${styles["range-thumb"]}`}
                      style={{
                        ...props.style,
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%",
                        backgroundColor: "#319560",
                      }}
                    />
                  )}
                />
              </div>
              <div className={`${styles["range-container"]}`}>
                {t("dialog_19")}
                <Range
                  step={0.1}
                  min={0}
                  max={1}
                  values={shadowRangeValue}
                  onChange={(values) => handleShadow(values)}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      className={`${styles["range-tracker"]}`}
                      style={{
                        ...props.style,
                        height: "4px",
                        width: "100%",
                        backgroundColor: "#cfcfcf",
                        borderRadius: "4px",
                        boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      className={`${styles["range-thumb"]}`}
                      style={{
                        ...props.style,
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%",
                        backgroundColor: "#319560",
                      }}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
