import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Twitter, Facebook } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { compactChild1Template } from "./compactChild1";
import { compactChild2Template } from "./compactChild2";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const compactTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "compact";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 4,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 5,
    templateTitle: templateKey,
  },
  {
    elementKey: "messenger",
    position: 6,
    templateTitle: templateKey,
  },
  {
    elementKey: "whatsapp",
    position: 7,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultNavbar(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/main.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe/"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        "0",
        new BackgroundColorProperty("#ffffff"),
        new BackgroundImageProperty(
          "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-navbar-bg.png",
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "1",
        "1"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright DSGNS " + new Date().getFullYear()
      );

    default:
      return compactTemplates[layoutKey];
  }
};

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: compactChild1Template,
  },
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "compactChild1Template",
    details: {
      title: "Designs",
      slug: randomSlug(6),
      description: "Design catalog",
    },
    template: {
      template: compactChild1Template,
    },
  },
  {
    templateName: "compactChild2Template",
    details: {
      title: "Contact Us",
      slug: randomSlug(6),
      description: "Contact page",
    },
    template: {
      template: compactChild2Template,
    },
  },
];

compactTemplates["navbar"] = TemplatesFactory.createDefaultNavbar(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/main.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe/"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  "0",
  new BackgroundColorProperty("#ffffff"),
  new BackgroundImageProperty(
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-navbar-bg.png",
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "1",
  "1"
);

compactTemplates["cover"] = TemplatesFactory.createDefaultCover(
  "7",
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Collaborate With Us", "", false, false, false),
  new RichTextProperty("<h1>Your Search <br />Ends Here</h1>"),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-banner-bg.png",
    "no-repeat",
    "0%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(
    "With 20 years of collaborative designs, <br /> we've got you covered."
  ),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 80)
);

compactTemplates[
  "cards"
] = TemplatesFactory.createDefaultCards(
  new LeadStyleProperty("0"),
  new RichTextProperty("<h2>We've Got You Covered</h2>"),
  new RTEAlignmentProperty("parent"),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Illustrations, animations, story boards, etc. You name it, we got it."
  ),
  new RTEAlignmentProperty("parent"),
  "2",
  [
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Illustrations</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Contact", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-card-image-3.png",
      "parent",
      true
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Stories</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Chat", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-card-image-1.png",
      "parent",
      true
    ),
    TemplatesFactory.createDefaultCard(
      new RichTextProperty("<h3>Animations</h3>"),
      new RTEAlignmentProperty("parent"),
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Message", "", false, false, false),
      new RichTextProperty(
        '<div class="s-f">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>'
      ),
      new RTEAlignmentProperty("parent"),
      "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-card-image-2.png",
      "parent",
      true
    ),
  ]
);

compactTemplates["articles"] = TemplatesFactory.createDefaultArticles(
  new LeadStyleProperty("1"),
  new RichTextProperty(
    "<h2>Making the World a Better Place through Our Drawings</h2>"
  ),
  new RTEAlignmentProperty("left"),
  new ImageStyleProperty("1"),
  new ButtonStyleProperty("hobaw-btn-outline-primary"),
  new LinkProperty("Read More", "", false, false, false),
  new BackgroundColorProperty("#E8FFEB"),
  new RichTextProperty(
    "<div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor.</div>"
  ),
  new RTEAlignmentProperty("left"),
  new RichTextProperty(
    "<div>Our designs have been used by Fortune 500 companies, non-profit organizations, government campaigns, and even in helping combat climate change.</div>"
  ),
  new RTEAlignmentProperty("left"),
  "2",
  "1",
  "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-articles-img.png",
  "2"
);

compactTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("2"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty("<h2>Reach Out!</h2>"),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    "Fill in your details and we'll be in contact with you soon or you can call us at +1 800 DSGNS"
  ),
  new RTEAlignmentProperty("parent"),
  "2",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

compactTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright DSGNS " + new Date().getFullYear()
);

compactTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "Illustrations",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

compactTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "Illustrations",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);
