import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { TemplatesFactory } from "./TemplatesFactory";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { Layout } from "common/elements/types";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const compactChild2Template: ElementTemplates = {};

compactChild2Template["cover"] = TemplatesFactory.createDefaultCover(
  "7",
  new ButtonStyleProperty("hobaw-btn-primary"),
  new LinkProperty("Collaborate With Us", "", false, false, false),
  new RichTextProperty("<h1>Say a Little, <br />and Say it well</h1>"),
  new RTEAlignmentProperty("parent"),
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/compact/compact-banner3-bg.png",
    "no-repeat",
    "0%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty(
    "Use the form below or contact us directly through our 1 800 number"
  ),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 70)
);
