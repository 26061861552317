import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type TextAreaProperties = {
  textAreaStyle: string;
};

export function TextArea({
  properties: { textAreaStyle },
  className,
  innerRef,
  ...props
}: ElementComponentProps<TextAreaProperties>): JSX.Element {
  return (
    <textarea
      {...props}
      ref={innerRef}
      className={className + "form-control text-area-style-" + textAreaStyle}
    ></textarea>
  );
}

export const textAreaElementKey = "text-area";

elementComponentRegistry.register(textAreaElementKey, TextArea);
