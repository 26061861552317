import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type CardsContainerProperties = {
  cardsStyle: string;
  cardsOrientation: string;
  cardsAlignment: string;
};

export function CardsContainer({
  properties: { cardsStyle, cardsOrientation, cardsAlignment },
  className,
  children,
  innerRef,
  ...props
}: ElementComponentProps<CardsContainerProperties>): JSX.Element {
  return (
    <div
      {...props}
      ref={innerRef}
      className={`${className} cards-container cards-style-${cardsStyle} cards-orientation-${cardsOrientation} cards-alignment-${cardsAlignment}`}
    >
      {children}
    </div>
  );
}

export const cardsContainerKey = "cards-container";

elementComponentRegistry.register(cardsContainerKey, CardsContainer);
