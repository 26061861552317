import { SystemUpdateAltRounded } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import styles from "./Admin.module.scss";
import { Dropdown, Table } from "react-bootstrap";
import { Button } from "editor/components/base/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getChallengesCollecting,
  endCollectionPhase,
  getChallengesTesting,
  endTestingPhase,
  mockReports,
  mockWeeklyReport,
} from "editor/states/website";
import { getAllChallengesCollecting } from "editor/states/selectors";
import { ABMockResource } from "editor/models/types";
import {
  getUsername,
  getUserWebsiteSlug,
  getWebsiteUniqueId,
} from "auth/selectors";

export const ABMocker: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const collection = "collection";
  const edgeControl = "edgeControl";
  const edgeChallenger = "edgeChallenger";
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const websiteUniqueId = useSelector(getWebsiteUniqueId);
  const username = useSelector(getUsername);
  const [abAction, setAbAction] = useState("");
  const [monthsToMock, setMonthsToMock] = useState<number>();
  const [challengeId, setChallengeId] = useState("");
  const [challengesToDisplay, setChallengesToDisplay] = useState<
    ABMockResource[] | null
  >();
  const challengesCollecting = useSelector(getAllChallengesCollecting);
  const [mockedReport, setMockedReport] = useState<boolean>(false);

  useEffect(() => {
    setChallengesToDisplay(undefined);
    setChallengesToDisplay(challengesCollecting);
  }, [dispatch, abAction, challengesCollecting]);

  const handleSelectABSection = (e: any) => {
    setAbAction(e);
    setChallengeId("");
    if (e === collection) {
      dispatch(getChallengesCollecting());
    } else if (e === edgeControl || e === edgeChallenger) {
      dispatch(getChallengesTesting());
    }
  };

  const handleSelectMonthsToMock = (e: any) => {
    if (e === "1") {
      setMonthsToMock(1);
    } else if (e === "2") {
      setMonthsToMock(2);
    } else {
      setMonthsToMock(3);
    }
  };

  const handleSelectChallenge = (e: any) => {
    setChallengeId(e);
  };

  const handleEndCollectionPhase = () => {
    if (abAction === collection && challengeId && !mockedReport) {
      dispatch(endCollectionPhase(+challengeId, abAction));
      setMockedReport(true);
    } else if (
      (abAction === edgeControl || abAction === edgeChallenger) &&
      challengeId &&
      !mockedReport
    ) {
      dispatch(endTestingPhase(+challengeId, abAction));
      setMockedReport(true);
    }
  };

  const handleMockRerport = () => {
    if (monthsToMock && websiteUniqueId && !mockedReport) {
      dispatch(mockReports(websiteUniqueId, monthsToMock));
      setMockedReport(true);
    }
  };

  const handleMockWeeklyReport = () => {
    if (username) {
      dispatch(mockWeeklyReport(username));
    }
  };

  return (
    <>
      <div className={styles["admin__content-header-container"]}>
        <div className={styles["admin__content-header"]}>
          <SystemUpdateAltRounded />
          <h3>Mocker</h3>
        </div>
      </div>
      <div className={styles["admin__content-container"]}>
        <div className={styles["admin__content-card--big"]}>
          <h5>Mock AB Test</h5>
          <Table>
            <thead>
              <tr>
                <td>Choose Action</td>
                <td>
                  <Dropdown onSelect={handleSelectABSection}>
                    <Dropdown.Toggle id="dropdown-basic">
                      {abAction ? abAction : "Select AB Action"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey={collection}>
                        collection
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={edgeControl}>
                        edgeControl
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={edgeChallenger}>
                        edgeChallenger
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <td>Select Challenge</td>
                <td>
                  <Dropdown onSelect={handleSelectChallenge}>
                    <Dropdown.Toggle id="dropdown-basic">
                      {challengeId ? challengeId : "Select Challenge"}
                    </Dropdown.Toggle>
                    {abAction && challengesToDisplay ? (
                      <Dropdown.Menu>
                        {challengesToDisplay.map((challenges) => (
                          <Dropdown.Item eventKey={challenges.challengeId + ""}>
                            {challenges.challengeId} - {challenges.websiteSlug}{" "}
                            - {challenges.controlSlug} - {challenges.elementId}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="#">
                          No Challenges Available
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      label="Run"
                      onClick={() => {
                        handleEndCollectionPhase();
                      }}
                      isDisabled={abAction && challengeId ? false : true}
                    />
                  </div>
                </td>
              </tr>
            </thead>
          </Table>
        </div>
        <div className={styles["admin__content-card--big"]}>
          <h5>Mock Reports</h5>
          <Table>
            <thead>
              <tr>
                <td>Months to Generate</td>
                <td>
                  <Dropdown onSelect={handleSelectMonthsToMock}>
                    <Dropdown.Toggle id="dropdown-basic2">
                      {monthsToMock ? monthsToMock : "Select Months to Mock"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey={"1"}>1</Dropdown.Item>
                      <Dropdown.Item eventKey={"2"}>2</Dropdown.Item>
                      <Dropdown.Item eventKey={"3"}>3</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      label="Run"
                      onClick={handleMockRerport}
                      isDisabled={websiteSlug ? false : true}
                    />
                  </div>
                </td>
              </tr>
            </thead>
          </Table>
        </div>
        <div className={styles["admin__content-card--big"]}>
          <h5>Mock Weekly Report</h5>
          <Table>
            <thead>
              <tr>
                <td colSpan={2}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      label="Run"
                      onClick={handleMockWeeklyReport}
                      isDisabled={websiteSlug ? false : true}
                    />
                  </div>
                </td>
              </tr>
            </thead>
          </Table>
        </div>
      </div>
    </>
  );
};
