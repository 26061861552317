import {
  UpdateWebsiteUsingPUTRequest,
  WebsiteResource,
  FindWebsitePublishStateUsingGETRequest,
  WebsitePublishStateResource,
  FindLatestWebsiteVersionUsingGETRequest,
  CreateWebsiteVersionUsingPOSTRequest,
  WebsiteVersionResource,
  ElementMetadata,
  WebsiteUpdateRequestCategoryEnum,
  GAPropertyResource,
  AddGAPropertiesUsingPOSTRequest,
  DeletePropertyUsingDELETERequest,
  UnlinkPropertyUsingGETRequest,
  LinkPropertyUsingGETRequest,
  GetReportByDateUsingGETRequest,
  GAAquisitionReportResource,
  GetBillingUsingGETRequest,
  BillingsResource,
  GetSubscriptionUsingGETRequest,
  SubscriptionResource,
  CancelSubscriptionUsingGETRequest,
  ElementStyleResource,
  ABMockerResource,
  CancelChallengeCollectionUsingPOSTRequest,
  EdgeChallengeUsingPOSTRequest,
  GetReportsUsingGETRequest,
  GetWeeklyReportsUsingGETRequest,
  GetMonthlyReportsUsingGETRequest,
  GenerateReportsUsingPOSTRequest,
  MockWeeklyReportUsingGETRequest,
} from "@urux/arm-of-dorne-api";
import {
  reportsApi,
  gaPropertyApi,
  TCategory,
  websiteApi,
  websiteVersionApi,
  s3ConfigApi,
  paymentGatewayApi,
  elementStylesApi,
  abMockerApi,
} from "api";
import {
  ABMockResource,
  Billing,
  Billings,
  ElementStyle,
  GAProperty,
  ReportAcquisition,
  ReportKeyValue,
  ReportPageViews,
  S3Config,
  Website,
  WebsiteVersion,
} from "editor/models/types";
import { StartPublishWebsiteUsingPUTRequest } from "@urux/arm-of-dorne-api";
import { websitePublishApi } from "api";
import { WebsitePublishState } from "editor/models/types";
import { Layout } from "common/elements/types";
import { SharedElementConfigurationById } from "editor/elements/types";

export const publishWebsiteAsync = async (
  websiteSlug: string
): Promise<any> => {
  const request: StartPublishWebsiteUsingPUTRequest = {
    websiteSlug,
  };

  await websitePublishApi.startPublishWebsiteUsingPUT(request);
};

export const findWebsitePublishStateAsync = async (
  websiteSlug: string
): Promise<WebsitePublishState> => {
  const request: FindWebsitePublishStateUsingGETRequest = {
    websiteSlug,
  };

  return mapToWebsitePublishState(
    await websitePublishApi.findWebsitePublishStateUsingGET(request)
  );
};

const mapToWebsitePublishState = (
  resource: WebsitePublishStateResource
): WebsitePublishState => {
  return {
    inProgress: resource.inProgress!,
    lastPublishedAt: resource.lastPublishedAt!,
  };
};

export const createWebsiteVersionAsync = async (
  websiteSlug: string,
  sharedContent: Layout,
  sharedContentMetadata: SharedElementConfigurationById
): Promise<WebsiteVersion> => {
  const request: CreateWebsiteVersionUsingPOSTRequest = {
    websiteSlug,
    request: {
      sharedContent: JSON.stringify(sharedContent),
      sharedContentMetadata: mapToElementMetadataList(sharedContentMetadata),
    },
  };

  return mapToWebsiteVersion(
    await websiteVersionApi.createWebsiteVersionUsingPOST(request)
  );
};

const mapToElementMetadataList = (
  metadata: SharedElementConfigurationById
): ElementMetadata[] => {
  return Object.keys(metadata).map((elementId) => ({
    elementId,
    elementKey: metadata[elementId].elementKey,
    label: metadata[elementId].label,
  }));
};

export const findLatestWebsiteVersionAsync = async (
  websiteSlug: string
): Promise<WebsiteVersion> => {
  const request: FindLatestWebsiteVersionUsingGETRequest = {
    websiteSlug,
  };

  return mapToWebsiteVersion(
    await websiteVersionApi.findLatestWebsiteVersionUsingGET(request)
  );
};

export const getAvailablePropertyIdsAsync = async (): Promise<GAProperty[]> => {
  let gaPropertyResources = await gaPropertyApi.getAvailableGAPropertiesUsingGET();
  let gaProperties: GAProperty[] = [];
  gaPropertyResources.forEach((gaPropertyResource) =>
    gaProperties.push(mapToGAProperty(gaPropertyResource))
  );
  return gaProperties;
};

export const getUsedPropertyIdsAsync = async (): Promise<GAProperty[]> => {
  let gaPropertyResources = await gaPropertyApi.getUsedGAPropertiesUsingGET();
  let gaProperties: GAProperty[] = [];
  gaPropertyResources.forEach((gaPropertyResource) =>
    gaProperties.push(mapToGAProperty(gaPropertyResource))
  );
  return gaProperties;
};

export const getS3ConfigAsync = async (): Promise<S3Config> => {
  let s3ConfigResource = await s3ConfigApi.getS3ConfigUsingGET();
  let s3Config: S3Config = {
    accessKeyId: s3ConfigResource.accessKeyId!,
    secretAccessKey: s3ConfigResource.secretAccessKey!,
    bucketName: s3ConfigResource.bucketName!,
    dirName: s3ConfigResource.dirName!,
    region: s3ConfigResource.region!,
    s3Url: s3ConfigResource.url!,
  };

  return s3Config;
};

export const addPropertyIdsAsync = async (
  propertyId: string,
  measurementId: string
): Promise<GAProperty[]> => {
  const request: AddGAPropertiesUsingPOSTRequest = {
    request: {
      propertyId,
      measurementId,
    },
  };
  let gaPropertyResources = await gaPropertyApi.addGAPropertiesUsingPOST(
    request
  );
  let gaProperties: GAProperty[] = [];
  gaPropertyResources.forEach((gaPropertyResource) =>
    gaProperties.push(mapToGAProperty(gaPropertyResource))
  );
  return gaProperties;
};

export const getAllElementStylesAsync = async (): Promise<ElementStyle[]> => {
  let elementStyleResources = await elementStylesApi.getAllElementStylesUsingGET();
  let elementStyles: ElementStyle[] = [];
  elementStyleResources.forEach((elementStyleResource) =>
    elementStyles.push(mapToElementStyle(elementStyleResource))
  );

  return elementStyles;
};

export const getWebsitesToFinishCollectionAsync = async (): Promise<
  ABMockResource[]
> => {
  let response = await abMockerApi.getWebsitesReadyForCollectionUsingGET();
  let abMockerResources: ABMockResource[] = [];
  response.forEach((resp) =>
    abMockerResources.push(mapToABMockerResource(resp))
  );
  return abMockerResources;
};

export const getWebsitesToFinishTestingAsync = async (): Promise<
  ABMockResource[]
> => {
  let response = await abMockerApi.getWebsitesReadyForTestingUsingGET();
  let abMockerResources: ABMockResource[] = [];
  response.forEach((resp) =>
    abMockerResources.push(mapToABMockerResource(resp))
  );
  return abMockerResources;
};

export const endChallengeCollectionPhaseAsync = async (
  challengeId: number,
  action: string
): Promise<ABMockResource[]> => {
  const request: CancelChallengeCollectionUsingPOSTRequest = {
    challengeId,
    action,
  };

  let response = await abMockerApi.cancelChallengeCollectionUsingPOST(request);
  let abMockerResources: ABMockResource[] = [];
  response.forEach((resp) =>
    abMockerResources.push(mapToABMockerResource(resp))
  );
  return abMockerResources;
};

export const mockReportsBySlugAndMonthAsync = async (
  websiteUniqueId: string,
  months: number
): Promise<any> => {
  const request: GenerateReportsUsingPOSTRequest = {
    websiteUniqueId,
    months,
  };

  let response = await abMockerApi.generateReportsUsingPOST(request);
  return response;
};

export const mockWeeklyReportByActionAsync = async (
  username: string
): Promise<any> => {
  const request: MockWeeklyReportUsingGETRequest = {
    username,
  };

  let response = await abMockerApi.mockWeeklyReportUsingGET(request);
  return response;
};

export const endChallengeTestingPhaseAsync = async (
  challengeId: number,
  action: string
): Promise<ABMockResource[]> => {
  const request: EdgeChallengeUsingPOSTRequest = {
    challengeId,
    action,
  };

  let response = await abMockerApi.edgeChallengeUsingPOST(request);
  let abMockerResources: ABMockResource[] = [];
  response.forEach((resp) =>
    abMockerResources.push(mapToABMockerResource(resp))
  );
  return abMockerResources;
};

export const deletePropertyAsync = async (propertyId: string): Promise<any> => {
  const request: DeletePropertyUsingDELETERequest = {
    propertyId,
  };
  await gaPropertyApi.deletePropertyUsingDELETE(request);
};

export const unlinkPropertyAsync = async (propertyId: string): Promise<any> => {
  const request: UnlinkPropertyUsingGETRequest = {
    propertyId,
  };
  await gaPropertyApi.unlinkPropertyUsingGET(request);
};

export const linkPropertyAsync = async (
  propertyId: string,
  websiteSlug: string
): Promise<any> => {
  const request: LinkPropertyUsingGETRequest = {
    propertyId,
    websiteSlug,
  };
  await gaPropertyApi.linkPropertyUsingGET(request);
};

export const getWebsiteReportAsync = async (
  ownerId: number,
  websiteUniqueId: string
): Promise<any> => {
  const request: GetReportsUsingGETRequest = {
    ownerId,
    websiteUniqueId,
  };
  return await reportsApi.getReportsUsingGET(request);
};

export const getWebsiteWeeklyReportAsync = async (
  ownerId: number,
  websiteUniqueId: string
): Promise<any> => {
  const request: GetWeeklyReportsUsingGETRequest = {
    ownerId,
    websiteUniqueId,
  };
  return await reportsApi.getWeeklyReportsUsingGET(request);
};

export const getWebsiteMonthlyReportAsync = async (
  ownerId: number,
  websiteUniqueId: string
): Promise<any> => {
  const request: GetMonthlyReportsUsingGETRequest = {
    ownerId,
    websiteUniqueId,
  };
  return await reportsApi.getMonthlyReportsUsingGET(request);
};

export const getReportByDateAsync = async (
  reportType: string,
  propertyId: string,
  startDate: string,
  endDate: string
): Promise<any> => {
  const request: GetReportByDateUsingGETRequest = {
    reportType: reportType,
    propertyId: propertyId,
    startDate: startDate,
    endDate: endDate,
  };
  if (reportType === "acquisition") {
    return mapToReportAquisition(
      await reportsApi.getReportByDateUsingGET(request)
    );
  } else if (reportType === "pageview") {
    return mapToReportPageView(
      await reportsApi.getReportByDateUsingGET(request)
    );
  }
};

export const getSubscriptionAsync = async (
  userId: number
): Promise<SubscriptionResource> => {
  const request: GetSubscriptionUsingGETRequest = {
    userId: userId,
  };
  return await paymentGatewayApi.getSubscriptionUsingGET(request);
};

export const cancelSubscriptionAsync = async (
  userId: number
): Promise<SubscriptionResource> => {
  const request: CancelSubscriptionUsingGETRequest = {
    userId: userId,
  };
  return await paymentGatewayApi.cancelSubscriptionUsingGET(request);
};

export const getPaymentsAsync = async (userId: number): Promise<Billings> => {
  const request: GetBillingUsingGETRequest = {
    userId: userId,
  };
  return mapBillings(await paymentGatewayApi.getBillingUsingGET(request));
};

const mapBillings = (billingsResources: BillingsResource[]): Billings => {
  let values: Billing[] = [];
  billingsResources.forEach((billingResource) => {
    values.push({
      date: billingResource.date!,
      status: billingResource.status!,
      orderId: billingResource.orderId!,
      amount: billingResource.amount!,
      billingEmail: billingResource.billingEmail!,
      billingName: billingResource.billingName!,
    });
  });

  let value: Billings = {
    billings: values,
  };

  return value;
};

const mapToReportAquisition = (
  resource: GAAquisitionReportResource
): ReportAcquisition => {
  const resourceValues: {
    [key: string]: string;
  } = resource.values!;
  let values: ReportKeyValue[] = [];
  for (const [key, value] of Object.entries(resourceValues)) {
    values.push({
      name: key,
      value: value,
    });
  }
  return {
    isAvailable: resource.available!,
    reportDate: resource.reportDate!,
    totalVisitors: resource.totalVisitors!,
    newVisitors: resource.newVisitors!,
    keyValue: values,
  };
};

const mapToReportPageView = (
  resource: GAAquisitionReportResource
): ReportPageViews => {
  const resourceValues: {
    [key: string]: string;
  } = resource.values!;
  let values: ReportKeyValue[] = [];
  for (const [key, value] of Object.entries(resourceValues)) {
    values.push({
      name: key,
      value: value,
    });
  }
  return {
    isAvailable: resource.available!,
    reportDate: resource.reportDate!,
    totalViews: resource.totalVisitors!,
    newViews: resource.newVisitors!,
    keyValue: values,
  };
};

const mapToGAProperty = (resource: GAPropertyResource): GAProperty => {
  return {
    websiteId: resource.websiteId!,
    websiteSlug: resource.websiteSlug!,
    websiteTitle: resource.websiteTitle!,
    propertyId: resource.propertyId!,
    gaPropertyId: resource.gaPropertyId!,
    isLinked: resource.isLinked!,
  };
};

const mapToElementStyle = (resource: ElementStyleResource): ElementStyle => {
  return {
    elementStyleId: resource.elementStyleId!,
    elementKey: resource.elementKey!,
    elementName: resource.elementName!,
    property: resource.property!,
    style: resource.style!,
    description: resource.description!,
  };
};

const mapToABMockerResource = (resource: ABMockerResource): ABMockResource => {
  return {
    challengeId: resource.challengeId!,
    websiteSlug: resource.websiteSlug!,
    controlSlug: resource.controlSlug!,
    elementId: resource.elementId!,
  };
};

const mapToWebsiteVersion = (
  resource: WebsiteVersionResource
): WebsiteVersion => {
  return {
    websiteId: resource.websiteId!,
    version: resource.version!,
    sharedContent: JSON.parse(resource.sharedContent!),
    sharedContentMetadata: mapToElementConfigurationById(
      resource.sharedContentMetadata!
    ),
    created: resource.created!,
  };
};

const mapToElementConfigurationById = (
  elementMetadata: ElementMetadata[]
): SharedElementConfigurationById => {
  return elementMetadata.reduce((acc, metadata) => {
    acc[metadata.elementId!] = {
      elementKey: metadata.elementKey!,
      label: metadata.label!,
    };
    return acc;
  }, {} as SharedElementConfigurationById);
};

export const updateWebsiteAsync = async (
  websiteSlug: string,
  website: Website
): Promise<any> => {
  let category: WebsiteUpdateRequestCategoryEnum;
  if (website.category === TCategory.BUSINESS)
    category = WebsiteUpdateRequestCategoryEnum.BUSINESS;
  else if (website.category === TCategory.PERSONAL)
    category = WebsiteUpdateRequestCategoryEnum.PERSONAL;
  else category = WebsiteUpdateRequestCategoryEnum.NONE;

  const request: UpdateWebsiteUsingPUTRequest = {
    websiteSlug,
    request: {
      slug: website.slug,
      category: category,
      title: website.title,
      widthGuide: website.widthGuide,
    },
  };
  return mapToWebsite(await websiteApi.updateWebsiteUsingPUT(request), website);
};

const mapToWebsite = (resource: WebsiteResource, website: Website): Website => {
  return {
    slug: resource.slug!,
    widthGuide: resource.widthGuide!,
    publishedAt: resource.publishedAt!,
    title: resource.title!,
    category: resource.category!,
    gaProperty: resource.gaProperty!,
    gaMeasurementId: resource.gaMeasurementId!,
    primaryFont: website.primaryFont!,
    secondaryFont: website.secondaryFont!,
    primaryColor: website.primaryColor!,
    secondaryColor: website.secondaryColor!,
    ternaryColor: website.ternaryColor!,
    colorType: website.colorType!,
    primaryRadius: website.primaryRadius!,
    secondaryRadius: website.secondaryRadius!,
    primaryShadow: website.primaryShadow!,
    logoCreated: website.logoCreated!,
    websiteUniqueId: resource.websiteUniqueId!,
  };
};
