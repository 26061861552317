import { LayoutEditor } from "../components/impl/LayoutEditor/LayoutEditor";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  FieldGroupConfigProperty,
  FormConfigurationProperty,
  GoogleMapsEmbedUrlProperty,
  LinkProperty,
  LogoStyleProperty,
  RichTextProperty,
  RTEAlignmentProperty,
  YoutubeEmbedUrlProperty,
} from "../properties";
import { NavElement } from "../elements/components/Nav/Nav";
import { FooterNavElement } from "../elements/components/FooterNav/FooterNav";
import { LogoContainerElement } from "../elements/components/LogoContainer/LogoContainer";
import { AlternateLogoContainerElement } from "../elements/components/AlternateLogoContainer/AlternateLogoContainer";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { NavbarElement } from "../elements/sections/Navbar/Navbar";
import { CoverElement } from "editor/elements/sections/Cover/Cover";
import { ButtonElement } from "editor/elements/components/Button/Button";
import { CardsElement } from "editor/elements/sections/Cards/Cards";
import { CardsContainerElement } from "editor/elements/components/CardsContainer/CardsContainer";
import { ArticlesElement } from "editor/elements/sections/Articles/Articles";
import { ArticleContentElement } from "editor/elements/components/ArticleContent";
import { ArticleElement } from "editor/elements/components/Article";
import { ContactElement } from "editor/elements/sections/Contact/Contact";
import { FormContainerElement } from "editor/elements/components/FormContainerElement/FormContainer";
import { FrormGroupElement as FormGroupElement } from "editor/elements/components/FormGroup/FormGroup";
import { FooterElement } from "editor/elements/sections/Footer/Footer";
import { CopyrightElement } from "editor/elements/base/Copyright";
import { HeadlineElement } from "editor/elements/base/Headline";
import { HeadlineStyleProperty } from "editor/properties/HeadlineStyleProperty";
import { LeadElement } from "editor/elements/base/Lead";
import { BlockQuoteElement } from "editor/elements/base/BlockQuote";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { CardElement } from "editor/elements/components/Card/Card";
import { ImageElement } from "editor/elements/base/Image/Image";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { ImageSourceProperty } from "editor/properties/ImageSourceProperty/ImageSourceProperty";
import { ParagraphElement } from "editor/elements/base/Paragraph";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialNavElement } from "editor/elements/components/SocialNav/SocialNav";
import { FooterSocialNavElement } from "editor/elements/components/FooterSocialNav/FooterSocialNav";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { RichTextElement } from "editor/elements/base/RichText";
import { HeadingElement } from "editor/elements/base/Heading";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { ChildPage } from "./types";
import { GalleryElement } from "editor/elements/sections/Gallery/Gallery";
import { ImageProperty } from "editor/properties/ImageProperty/ImageProperty";
import { YoutubeEmbedElement } from "editor/elements/components/YoutubeEmbed/YoutubeEmbed";
import { GoogleMapsEmbedElement } from "editor/elements/components/GoogleMapsEmbed/GoogleMapsEmbed";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { WhatsappElement } from "editor/elements/components/Whatsapp/Whatsapp";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";
import { MessengerElement } from "editor/elements/components/Messenger/Messenger";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";

export function randomSlug(len: number) {
  var text = "";

  var charset = "abcdefghijklmnopqrstuvwxyz" + new Date().getTime();

  for (var i = 0; i < len; i++)
    text += charset.charAt(Math.floor(Math.random() * charset.length));

  return text;
}

export class TemplatesFactory {
  static getLinkProperties(
    childPageTemplates: ChildPage[],
    skipHome: boolean,
    mainPageDetails: ChildPage
  ) {
    let linkProperties: LinkProperty[] = [];
    if (!skipHome) {
      if (mainPageDetails) {
        linkProperties.push(
          new LinkProperty(
            mainPageDetails.details.title,
            "/" + mainPageDetails.details.slug,
            false,
            false,
            false
          )
        );
      } else {
        linkProperties.push(
          new LinkProperty("Home", "/" + randomSlug(6), false, false, false)
        );
      }
    }
    for (const childTemplate of childPageTemplates) {
      linkProperties.push(
        new LinkProperty(
          childTemplate.details.title,
          "/" + childTemplate.details.slug,
          false,
          false,
          false
        )
      );
    }

    return linkProperties;
  }

  static getMaxPageWidth(): number {
    let closest = Math.max(
      ...[1024, 1280, 1366, 1440, 1600, 0].filter(
        (num) => num <= window.innerWidth
      )
    );

    return closest;
  }

  static createDefaultGallery(
    galleryImages: ImageProperty[],
    galleryStyle: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        GalleryElement.create({
          galleryImages: galleryImages,
          galleryStyle: galleryStyle,
          width: 1024,
          backgroundColorProperty: "",
        })
      )
      .build();
  }

  static createDefaultArticles(
    leadStyle: LeadStyleProperty,
    rteHeadlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    imageStyle: ImageStyleProperty,
    buttonStyle: ButtonStyleProperty,
    linkProperty: LinkProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextParagraphProperty: RichTextProperty,
    rteParagraphAlignment: RTEAlignmentProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    articleStyle: string,
    sectionHeaderStyle: string,
    imgUrl: string,
    articleAlignment: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        ArticlesElement.create({
          articlesStyle: articleStyle,
          backgroundColorProperty: backgroundColorProperty.color,
          acrticlesAlignment: articleAlignment,
          backgroundImageProperty: new BackgroundImageProperty(
            undefined,
            "repeat",
            "center",
            "center",
            "cover",
            0,
            0,
            1
          ),
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            SectionHeaderElement.create({
              sectionHeaderStyle: sectionHeaderStyle,
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
              )
              .add(
                LeadElement.create({
                  leadStyle: leadStyle,
                  richTextProperty: richTextLeadProperty,
                  rteAlignment: rteLeadAlignment,
                })
              )
              .build()
          )
          .add(
            ArticleElement.create({
              articleStyle: "1",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ArticleContentElement.create({
                  articleContentStyle: "2",
                })
              )
              .addChildren(
                LayoutEditor.createEmpty()
                  .add(
                    ImageElement.create({
                      imageStyle: imageStyle,
                      imgUrl: imgUrl,
                      imageText: "",
                    })
                  )
                  .add(
                    ParagraphElement.create({
                      richTextProperty: richTextParagraphProperty,
                      rteAlignment: rteParagraphAlignment,
                    })
                  )
                  .add(
                    ButtonElement.create({
                      buttonStyle: buttonStyle,
                      buttonLink: linkProperty,
                      treatmentProperty: new TreatmentProperty(false),
                      isProduction: false,
                      isButton: false,
                      isTestable: false,
                    })
                  )
                  .build()
              )
              .build()
          )
          .build()
      )
      .build();
  }

  static createDefaultCover(
    coverStyle: string,
    buttonStyle: ButtonStyleProperty,
    linkProperty: LinkProperty,
    headlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    leadStyle: LeadStyleProperty,
    backgroundImageProperty: BackgroundImageProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    dimensionProperty: DimensionProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        CoverElement.create({
          coverStyle: coverStyle,
          backgroundImageProperty: backgroundImageProperty,
          dimension: dimensionProperty,
          backgroundColorProperty: backgroundColorProperty,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: headlineText,
              rteAlignment: rteHeadlineAlignment,
            })
          )
          .add(
            LeadElement.create({
              leadStyle: leadStyle,
              richTextProperty: richTextLeadProperty,
              rteAlignment: rteLeadAlignment,
            })
          )
          .add(
            ButtonElement.create({
              buttonStyle: buttonStyle,
              buttonLink: linkProperty,
              treatmentProperty: new TreatmentProperty(false),
              isProduction: false,
              isButton: false,
              isTestable: false,
            })
          )
          .build()
      )
      .build();
  }

  static createDefaultCoverNoText(
    coverStyle: string,
    leadStyle: LeadStyleProperty,
    backgroundImageProperty: BackgroundImageProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    dimensionProperty: DimensionProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        CoverElement.create({
          coverStyle: coverStyle,
          backgroundImageProperty: backgroundImageProperty,
          backgroundColorProperty: backgroundColorProperty,
          dimension: dimensionProperty,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            LeadElement.create({
              leadStyle: leadStyle,
              richTextProperty: richTextLeadProperty,
              rteAlignment: rteLeadAlignment,
            })
          )
          .build()
      )
      .build();
  }

  static createDefaultCoverWithLogo(
    imgUrl: string,
    coverStyle: string,
    headlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    leadStyle: LeadStyleProperty,
    backgroundImageProperty: BackgroundImageProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    dimensionProperty: DimensionProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        CoverElement.create({
          coverStyle: coverStyle,
          backgroundImageProperty: backgroundImageProperty,
          backgroundColorProperty: backgroundColorProperty,
          dimension: dimensionProperty,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ImageElement.create({
              imageStyle: "1",
              imgUrl: imgUrl,
              imageText: "",
            })
          )
          .add(
            HeadingElement.create({
              richTextProperty: headlineText,
              rteAlignment: rteHeadlineAlignment,
            })
          )
          .add(
            LeadElement.create({
              leadStyle: leadStyle,
              richTextProperty: richTextLeadProperty,
              rteAlignment: rteLeadAlignment,
            })
          )
          .build()
      )
      .build();
  }

  static createDefaultCard = (
    rteHeadlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    imageStyle: ImageStyleProperty,
    buttonStyle: ButtonStyleProperty,
    linkProperty: LinkProperty,
    richTextParagraphProperty: RichTextProperty,
    rteParagraphAlignment: RTEAlignmentProperty,
    imgUrl: string,
    cardStyle: string,
    isHeaderOnTop?: boolean
  ): Layout => {
    return LayoutEditor.createEmpty()
      .add(
        CardElement.create({
          cardStyle: cardStyle,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            isHeaderOnTop
              ? HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
              : ImageElement.create({
                  imageStyle: imageStyle,
                  imageText: "",
                  imgUrl: imgUrl,
                })
          )
          .add(
            isHeaderOnTop
              ? ImageElement.create({
                  imageStyle: imageStyle,
                  imageText: "",
                  imgUrl: imgUrl,
                })
              : HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
          )
          .add(
            ParagraphElement.create({
              richTextProperty: richTextParagraphProperty,
              rteAlignment: rteParagraphAlignment,
            })
          )
          .add(
            ButtonElement.create({
              buttonStyle: buttonStyle,
              buttonLink: linkProperty,
              treatmentProperty: new TreatmentProperty(false),
              isProduction: false,
              isButton: false,
              isTestable: false,
            })
          )
          .build()
      )
      .build();
  };

  static createDefaultCardNoTitle = (
    imageStyle: ImageStyleProperty,
    buttonStyle: ButtonStyleProperty,
    linkProperty: LinkProperty,
    richTextParagraphProperty: RichTextProperty,
    rteParagraphAlignment: RTEAlignmentProperty,
    imgUrl: string,
    cardStyle: string
  ): Layout => {
    return LayoutEditor.createEmpty()
      .add(
        CardElement.create({
          cardStyle: cardStyle,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ImageElement.create({
              imageStyle: imageStyle,
              imageText: "",
              imgUrl: imgUrl,
            })
          )
          .add(
            ParagraphElement.create({
              richTextProperty: richTextParagraphProperty,
              rteAlignment: rteParagraphAlignment,
            })
          )
          .add(
            ButtonElement.create({
              buttonStyle: buttonStyle,
              buttonLink: linkProperty,
              treatmentProperty: new TreatmentProperty(false),
              isProduction: false,
              isButton: false,
              isTestable: false,
            })
          )
          .build()
      )
      .build();
  };

  static createDefaultCardNoLink = (
    rteHeadlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    imageStyle: ImageStyleProperty,
    richTextParagraphProperty: RichTextProperty,
    rteParagraphAlignment: RTEAlignmentProperty,
    imgUrl: string,
    cardStyle: string,
    isHeaderOnTop?: boolean
  ): Layout => {
    return LayoutEditor.createEmpty()
      .add(
        CardElement.create({
          cardStyle: cardStyle,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            isHeaderOnTop
              ? HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
              : ImageElement.create({
                  imageStyle: imageStyle,
                  imageText: "",
                  imgUrl: imgUrl,
                })
          )
          .add(
            isHeaderOnTop
              ? ImageElement.create({
                  imageStyle: imageStyle,
                  imageText: "",
                  imgUrl: imgUrl,
                })
              : HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
          )
          .add(
            ParagraphElement.create({
              richTextProperty: richTextParagraphProperty,
              rteAlignment: rteParagraphAlignment,
            })
          )
          .build()
      )
      .build();
  };

  static createDefaultCardNoTitleNoLink = (
    imageStyle: ImageStyleProperty,
    richTextParagraphProperty: RichTextProperty,
    rteParagraphAlignment: RTEAlignmentProperty,
    imgUrl: string,
    cardStyle: string
  ): Layout => {
    return LayoutEditor.createEmpty()
      .add(
        CardElement.create({
          cardStyle: cardStyle,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            ImageElement.create({
              imageStyle: imageStyle,
              imageText: "",
              imgUrl: imgUrl,
            })
          )
          .add(
            ParagraphElement.create({
              richTextProperty: richTextParagraphProperty,
              rteAlignment: rteParagraphAlignment,
            })
          )
          .build()
      )
      .build();
  };

  static createDefaultArticlesNoTitle(
    leadStyle: LeadStyleProperty,
    imageStyle: ImageStyleProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    articleStyle: string,
    sectionHeaderStyle: string,
    imgUrl: string,
    articleAlignment: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        ArticlesElement.create({
          articlesStyle: articleStyle,
          backgroundColorProperty: backgroundColorProperty.color,
          acrticlesAlignment: articleAlignment,
          backgroundImageProperty: new BackgroundImageProperty(
            undefined,
            "repeat",
            "center",
            "center",
            "cover",
            0,
            0,
            1
          ),
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            SectionHeaderElement.create({
              sectionHeaderStyle: sectionHeaderStyle,
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                LeadElement.create({
                  leadStyle: leadStyle,
                  richTextProperty: richTextLeadProperty,
                  rteAlignment: rteLeadAlignment,
                })
              )
              .build()
          )
          .add(
            ArticleElement.create({
              articleStyle: "1",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ArticleContentElement.create({
                  articleContentStyle: "2",
                })
              )
              .addChildren(
                LayoutEditor.createEmpty()
                  .add(
                    ImageElement.create({
                      imageStyle: imageStyle,
                      imgUrl: imgUrl,
                      imageText: "",
                    })
                  )
                  .build()
              )
              .build()
          )
          .build()
      )
      .build();
  }

  static createDefaultArticlesNoTitleImageFirst(
    leadStyle: LeadStyleProperty,
    imageStyle: ImageStyleProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    articleStyle: string,
    sectionHeaderStyle: string,
    imgUrl: string,
    articleAlignment: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        ArticlesElement.create({
          articlesStyle: articleStyle,
          backgroundColorProperty: backgroundColorProperty.color,
          acrticlesAlignment: articleAlignment,
          backgroundImageProperty: new BackgroundImageProperty(
            undefined,
            "repeat",
            "center",
            "center",
            "cover",
            0,
            0,
            1
          ),
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            SectionHeaderElement.create({
              sectionHeaderStyle: sectionHeaderStyle,
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ArticleContentElement.create({
                  articleContentStyle: "2",
                })
              )
              .addChildren(
                LayoutEditor.createEmpty()
                  .add(
                    ImageElement.create({
                      imageStyle: imageStyle,
                      imgUrl: imgUrl,
                      imageText: "",
                    })
                  )
                  .build()
              )
              .build()
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                LeadElement.create({
                  leadStyle: leadStyle,
                  richTextProperty: richTextLeadProperty,
                  rteAlignment: rteLeadAlignment,
                })
              )
              .build()
          )
          .add(
            ArticleElement.create({
              articleStyle: "1",
            })
          )
          .build()
      )
      .build();
  }

  static createSimpleCard = (
    rteHeadlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    imageStyle: ImageStyleProperty,
    imgUrl: string,
    cardStyle: string,
    isHeaderOnTop?: boolean
  ): Layout => {
    return LayoutEditor.createEmpty()
      .add(
        CardElement.create({
          cardStyle: cardStyle,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            isHeaderOnTop
              ? HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
              : ImageElement.create({
                  imageStyle: imageStyle,
                  imageText: "",
                  imgUrl: imgUrl,
                })
          )
          .add(
            isHeaderOnTop
              ? ImageElement.create({
                  imageStyle: imageStyle,
                  imageText: "",
                  imgUrl: imgUrl,
                })
              : HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
          )
          .build()
      )
      .build();
  };

  static createDefaultCards(
    leadStyle: LeadStyleProperty,
    rteHeadlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    cardsStyle: string,
    cardChildren: Layout[]
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        CardsElement.create({
          cardsStyle: cardsStyle,
          backgroundColorProperty: backgroundColorProperty.color,
          backgroundImageProperty: new BackgroundImageProperty(
            undefined,
            "repeat",
            "center",
            "center",
            "cover",
            0,
            0,
            1
          ),
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            SectionHeaderElement.create({
              sectionHeaderStyle: "parent",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
              )
              .add(
                LeadElement.create({
                  leadStyle: leadStyle,
                  richTextProperty: richTextLeadProperty,
                  rteAlignment: rteLeadAlignment,
                })
              )
              .build()
          )
          .add(
            CardsContainerElement.create({
              cardsStyle: cardsStyle,
              cardsOrientation: "1",
              cardsAlignment: "2",
            })
          )
          .addChildrenArray(cardChildren)
          .build()
      )
      .build();
  }

  static createDefaultCardsWithImage(
    imgUrl: string,
    leadStyle: LeadStyleProperty,
    rteHeadlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    cardsStyle: string,
    cardChildren: Layout[]
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        CardsElement.create({
          cardsStyle: cardsStyle,
          backgroundColorProperty: backgroundColorProperty.color,
          backgroundImageProperty: new BackgroundImageProperty(
            undefined,
            "repeat",
            "center",
            "center",
            "cover",
            0,
            0,
            1
          ),
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            SectionHeaderElement.create({
              sectionHeaderStyle: "parent",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                ImageElement.create({
                  imageStyle: "1",
                  imgUrl: imgUrl,
                  imageText: "",
                })
              )
              .add(
                HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
              )
              .add(
                LeadElement.create({
                  leadStyle: leadStyle,
                  richTextProperty: richTextLeadProperty,
                  rteAlignment: rteLeadAlignment,
                })
              )
              .build()
          )
          .add(
            CardsContainerElement.create({
              cardsStyle: cardsStyle,
              cardsOrientation: "1",
              cardsAlignment: "2",
            })
          )
          .addChildrenArray(cardChildren)
          .build()
      )
      .build();
  }

  static createDefaultLogoContainer(
    logoText: LogoTextProperty,
    logoStyle: LogoStyleProperty,
    logoImage: LogoImageProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        LogoContainerElement.create({
          label: "logo",
          logoText: logoText,
          logoStyle: logoStyle,
          logoDecoration: "0",
          logoImage: logoImage,
        })
      )
      .build();
  }

  static createDefaultNav(links: LinkProperty[], navStyle: string): Layout {
    return LayoutEditor.createEmpty()
      .add(
        NavElement.create({
          links,
          style: navStyle,
        })
      )
      .build();
  }

  static createDefaultNavbar(
    links: LinkProperty[],
    logoText: LogoTextProperty,
    logoStyle: LogoStyleProperty,
    logoImage: LogoImageProperty,
    socialLinks: SocialLinkProperty[],
    socialNavStyle: string,
    backgroundColorProperty: BackgroundColorProperty,
    backgroundImageProperty: BackgroundImageProperty,
    width: number,
    navbarStyle: string,
    navStyle: string,
    navLinkColor?: string
  ): Layout {
    let elem0: any = NavElement.create({
      links,
      style: navStyle,
      linkColor: navLinkColor ? "#FFFFFF" : "",
    });
    let elem1: any = LogoContainerElement.create({
      label: "logo",
      logoText: logoText,
      logoStyle: logoStyle,
      logoDecoration: "0",
      logoImage: logoImage,
    });
    let elem2: any = SocialNavElement.create({
      socialLinks,
      style: socialNavStyle,
      position: "1",
    });

    if (parseInt(navbarStyle) === 0) {
      elem0 = NavElement.create({
        links,
        style: navStyle,
        linkColor: navLinkColor ? "#FFFFFF" : "",
      });
      elem1 = LogoContainerElement.create({
        label: "logo",
        logoText: logoText,
        logoStyle: logoStyle,
        logoDecoration: "0",
        logoImage: logoImage,
      });
      elem2 = SocialNavElement.create({
        socialLinks,
        style: socialNavStyle,
        position: "1",
      });
    } else if (
      parseInt(navbarStyle) === 1 ||
      parseInt(navbarStyle) === 2 ||
      parseInt(navbarStyle) === 3
    ) {
      elem0 = LogoContainerElement.create({
        label: "logo",
        logoText: logoText,
        logoStyle: logoStyle,
        logoDecoration: "0",
        logoImage: logoImage,
      });
      elem1 = NavElement.create({
        links,
        style: navStyle,
        linkColor: navLinkColor ? "#FFFFFF" : "",
      });
      elem2 = SocialNavElement.create({
        socialLinks,
        style: socialNavStyle,
        position: "1",
      });
    } else if (parseInt(navbarStyle) === 4) {
      elem0 = SocialNavElement.create({
        socialLinks,
        style: socialNavStyle,
        position: "1",
      });
      elem1 = NavElement.create({
        links,
        style: navStyle,
        linkColor: navLinkColor ? "#FFFFFF" : "",
      });
      elem2 = LogoContainerElement.create({
        label: "logo",
        logoText: logoText,
        logoStyle: logoStyle,
        logoDecoration: "0",
        logoImage: logoImage,
      });
    }

    return LayoutEditor.createEmpty()
      .add(
        NavbarElement.create({
          navbarStyle: navbarStyle,
          width: width,
          backgroundColorProperty: backgroundColorProperty.color,
          backgroundImageProperty: backgroundImageProperty,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty().add(elem0).add(elem1).add(elem2).build()
      )
      .build();
  }

  static createSocialNavbar(
    socialLinks: SocialLinkProperty[],
    socialNavStyle: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        SocialNavElement.create({
          socialLinks,
          style: socialNavStyle,
          position: "1",
        })
      )
      .build();
  }

  static createDefaultWeddingNavbar(
    linksLeft: LinkProperty[],
    linksRight: LinkProperty[],
    logoText: LogoTextProperty,
    logoStyle: LogoStyleProperty,
    logoImage: LogoImageProperty,
    backgroundColorProperty: BackgroundColorProperty,
    backgroundImageProperty: BackgroundImageProperty,
    width: number,
    navbarStyle: string,
    navStyle: string
  ): Layout {
    let elem0: any = NavElement.create({
      links: linksLeft,
      style: navStyle,
    });
    let elem1: any = LogoContainerElement.create({
      label: "logo",
      logoText: logoText,
      logoStyle: logoStyle,
      logoDecoration: "0",
      logoImage: logoImage,
    });
    let elem2: any = NavElement.create({
      links: linksRight,
      style: navStyle,
    });
    return TemplatesFactory.createNavbarWithLogoCenter(
      elem0,
      elem1,
      elem2,
      backgroundColorProperty,
      backgroundImageProperty,
      width,
      navbarStyle
    );
  }

  static createDefaultWeddingCards(
    leadStyle: LeadStyleProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    cardsStyle: string,
    cardChildren: Layout[]
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        CardsElement.create({
          cardsStyle: cardsStyle,
          backgroundColorProperty: backgroundColorProperty.color,
          backgroundImageProperty: new BackgroundImageProperty(
            undefined,
            "repeat",
            "center",
            "center",
            "cover",
            0,
            0,
            1
          ),
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            SectionHeaderElement.create({
              sectionHeaderStyle: "parent",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                LeadElement.create({
                  leadStyle: leadStyle,
                  richTextProperty: richTextLeadProperty,
                  rteAlignment: rteLeadAlignment,
                })
              )
              .build()
          )
          .add(
            CardsContainerElement.create({
              cardsStyle: cardsStyle,
              cardsOrientation: "2",
              cardsAlignment: "2",
            })
          )
          .addChildrenArray(cardChildren)
          .build()
      )
      .build();
  }

  static createFooterSocialNavbar(socialLinks: SocialLinkProperty[]): Layout {
    return LayoutEditor.createEmpty()
      .add(
        FooterSocialNavElement.create({
          socialLinks,
          style: "1",
          position: "1",
        })
      )
      .build();
  }

  static createDefaultContact(
    leadStyle: LeadStyleProperty,
    leadText: TextProperty,
    rteHeadlineText: RichTextProperty,
    rteHeadlineAlignment: RTEAlignmentProperty,
    formConfigProperty: FormConfigurationProperty,
    backgroundColorProperty: BackgroundColorProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty,
    contactStyle: string,
    testableElementStyle?: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        ContactElement.create({
          contactStyle: contactStyle,
          backgroundColorProperty: backgroundColorProperty.color,
          backgroundImageProperty: new BackgroundImageProperty(
            undefined,
            "repeat",
            "center",
            "center",
            "cover",
            0,
            0,
            1
          ),
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            SectionHeaderElement.create({
              sectionHeaderStyle: "parent",
            })
          )
          .addChildren(
            LayoutEditor.createEmpty()
              .add(
                HeadingElement.create({
                  richTextProperty: rteHeadlineText,
                  rteAlignment: rteHeadlineAlignment,
                })
              )
              .add(
                LeadElement.create({
                  leadStyle: leadStyle,
                  richTextProperty: richTextLeadProperty,
                  rteAlignment: rteLeadAlignment,
                })
              )
              .build()
          )
          .add(
            FormContainerElement.create({
              formContainerStyle: "1",
              formConfigProperty: formConfigProperty,
            })
          )
          .addChildren(
            this.createDefaultFormContainerContent(
              new FieldGroupConfigProperty(
                "Email",
                "input",
                "Example: george@gmail.com",
                false
              )
            )
          )
          .addChildren(
            this.createDefaultFormContainerContent(
              new FieldGroupConfigProperty("Message", "textarea", "", false)
            )
          )
          .addChildren(
            this.createDefaultButton(
              new ButtonStyleProperty("hobaw-btn-primary", true),
              new LinkProperty("Submit", "", false, false, false, true),
              new TreatmentProperty(true, [
                {
                  isControl: true,
                  isCurrentTreatment: false,
                  hasTested: false,
                  property: "hobaw-btn-primary",
                  propertyDesc: "Solid style",
                },
                {
                  isControl: false,
                  isCurrentTreatment: true,
                  hasTested: false,
                  property: "hobaw-btn-outline-primary",
                  propertyDesc: "Outline style",
                },
              ]),
              true,
              true,
              testableElementStyle
            )
          )
          .build()
      )
      .build();
  }

  static createDefaultFormContainerContent(
    fieldGroupConfig: FieldGroupConfigProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        FormGroupElement.create({
          formGroupStyle: "1",
          fieldGroupConfig: fieldGroupConfig,
        })
      )
      .build();
  }

  static createDefaultAlternateLogoContainer(
    logoText: LogoTextProperty,
    logoStyle: LogoStyleProperty,
    logoImage: LogoImageProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        AlternateLogoContainerElement.create({
          label: "logo",
          logoText: logoText,
          logoStyle: logoStyle,
          logoDecoration: "0",
          logoImage: logoImage,
        })
      )
      .build();
  }

  static createDefaultFooterNav(
    links: LinkProperty[],
    navStyle: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        FooterNavElement.create({
          links,
          style: "1",
        })
      )
      .build();
  }

  static createDefaultFooter(
    links: LinkProperty[],
    logoText: LogoTextProperty,
    logoStyle: LogoStyleProperty,
    logoImage: LogoImageProperty,
    socialLinks: SocialLinkProperty[],
    backgroundColorProperty: BackgroundColorProperty,
    backgroundImageProperty: BackgroundImageProperty,
    copyrightText: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        FooterElement.create({
          footerStyle: "1",
          backgroundColorProperty: backgroundColorProperty.color,
          backgroundImageProperty: backgroundImageProperty,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            AlternateLogoContainerElement.create({
              label: "logo",
              logoText: logoText,
              logoStyle: logoStyle,
              logoDecoration: "0",
              logoImage: logoImage,
            })
          )
          .add(
            FooterNavElement.create({
              links,
              style: "1",
            })
          )
          .addChildren(this.createFooterSocialNavbar(socialLinks))
          .add(
            CopyrightElement.create({
              copyrightStyle: "1",
              copyrightText: copyrightText,
            })
          )
          .build()
      )
      .build();
  }

  static createDefaultButton(
    buttonStyle: ButtonStyleProperty,
    linkProperty: LinkProperty,
    treatmentProperty: TreatmentProperty,
    isButton: Boolean,
    isTestable: Boolean,
    testableElementStyle?: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        ButtonElement.create({
          buttonStyle: buttonStyle,
          buttonLink: linkProperty,
          treatmentProperty: treatmentProperty,
          isProduction: false,
          isButton: isButton,
          isTestable: isTestable,
          testableElementStyle: testableElementStyle,
        })
      )
      .build();
  }

  static createDefaultWhatsapp(
    websiteTitle: string,
    whatsappButtonStyle: WhatsappButtonStyleProperty,
    whatsappNumber: PhoneNumberProperty,
    treatmentProperty: TreatmentProperty,
    isButton: Boolean,
    isTestable: Boolean,
    testableElementStyle?: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        WhatsappElement.create({
          websiteTitle: websiteTitle,
          whatsappNumber: whatsappNumber,
          whatsappButtonStyle: whatsappButtonStyle,
          treatmentProperty: treatmentProperty,
          isProduction: false,
          isButton: isButton,
          isTestable: isTestable,
          testableElementStyle: testableElementStyle,
        })
      )
      .build();
  }

  static createDefaultMessenger(
    websiteTitle: string,
    messengerButtonStyle: MessengerButtonStyleProperty,
    messengerUsername: MessengerButtonUsernameProperty,
    treatmentProperty: TreatmentProperty,
    isButton: Boolean,
    isTestable: Boolean,
    testableElementStyle?: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        MessengerElement.create({
          websiteTitle: websiteTitle,
          messengerUsername: messengerUsername,
          messengerButtonStyle: messengerButtonStyle,
          treatmentProperty: treatmentProperty,
          isProduction: false,
          isButton: isButton,
          isTestable: isTestable,
          testableElementStyle: testableElementStyle,
        })
      )
      .build();
  }

  static createDefaultHeadline(
    headlineStyle: HeadlineStyleProperty,
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        HeadlineElement.create({
          headlineStyle: headlineStyle,
          richTextProperty: richTextProperty,
          rteAlignment: rteAlignment,
        })
      )
      .build();
  }

  static createDefaultLead(
    leadStyle: LeadStyleProperty,
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        LeadElement.create({
          leadStyle: leadStyle,
          richTextProperty: richTextLeadProperty,
          rteAlignment: rteLeadAlignment,
        })
      )
      .build();
  }

  static createDefaultBlockQuote(
    richTextLeadProperty: RichTextProperty,
    rteLeadAlignment: RTEAlignmentProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        BlockQuoteElement.create({
          richTextProperty: richTextLeadProperty,
          rteAlignment: rteLeadAlignment,
        })
      )
      .build();
  }

  static createDefaultRichText(
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        RichTextElement.create({
          richTextProperty: richTextProperty,
          rteAlignment: rteAlignment,
        })
      )
      .build();
  }

  static createDefaultHeading(
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        HeadingElement.create({
          richTextProperty: richTextProperty,
          rteAlignment: rteAlignment,
        })
      )
      .build();
  }

  static createDefaultParagraph(
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        ParagraphElement.create({
          richTextProperty: richTextProperty,
          rteAlignment: rteAlignment,
        })
      )
      .build();
  }

  static createDefaultImage(
    imageStyleProperty: ImageStyleProperty,
    imageSourceProperty: ImageSourceProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        ImageElement.create({
          imageStyle: imageStyleProperty.imageStyle,
          imageText: "Image",
          imgUrl: imageSourceProperty.imageUrl,
        })
      )
      .build();
  }

  static createDefaultYoutube(youtubeUrl: YoutubeEmbedUrlProperty): Layout {
    return LayoutEditor.createEmpty()
      .add(
        YoutubeEmbedElement.create({
          youtubeUrl: youtubeUrl.url,
        })
      )
      .build();
  }

  static createDefaultGoogleMaps(
    googleMaps: GoogleMapsEmbedUrlProperty
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        GoogleMapsEmbedElement.create({
          googleMapsUrl: googleMaps,
        })
      )
      .build();
  }

  static createNavbarWithLogoCenter(
    elem0: any,
    elem1: any,
    elem2: any,
    backgroundColorProperty: BackgroundColorProperty,
    backgroundImageProperty: BackgroundImageProperty,
    width: number,
    navbarStyle: string
  ): Layout {
    return LayoutEditor.createEmpty()
      .add(
        NavbarElement.create({
          navbarStyle: navbarStyle,
          width: width,
          backgroundColorProperty: backgroundColorProperty.color,
          backgroundImageProperty: backgroundImageProperty,
        })
      )
      .addChildren(
        LayoutEditor.createEmpty().add(elem0).add(elem1).add(elem2).build()
      )
      .build();
  }
}
