import { ViewModuleRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import {
  articleContentElementKey,
  ArticleContentProperties,
} from "common/elements/components/ArticleContent";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { ArticleContentStyleProperty } from "editor/properties/ArticleContentStyleProperty/ArticleContentStyleProperty";

export class ArticleContentElement {
  static readonly elementKey = articleContentElementKey;
  static readonly label = "element_content_label";
  static readonly icon = ViewModuleRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly allowedChildren = [
    "heading",
    "image",
    "button",
    "paragraph",
    "google-maps",
    "youtube",
  ];
  static readonly elementPreviewUrl =
    "/img/add-article-element-preview-default.svg";
  static readonly elementDescription = "element_content_description";

  static readonly properties: PropertiesOf<ArticleContentProperties> = {
    articleContentStyle: ArticleContentStyleProperty,
  };

  static renderProperties(element: ElementType) {
    return <ArticleContentPropertiesComponent element={element} />;
  }

  static create(properties: { articleContentStyle: string }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const ArticleContentPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<
    typeof ArticleContentElement.properties
  >(ArticleContentElement.properties, element);

  return <>{renderer.render("articleContentStyle")}</>;
};

elementDefinitionRegistry.register(ArticleContentElement);
