import { NotesRounded } from "@material-ui/icons";
import { ParagraphProperties } from "common/elements/base/Paragraph";
import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import React from "react";
import { elementDefinitionRegistry } from "../ElementDefinitionRegistry";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import {
  DimensionProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "editor/properties";

export class ParagraphElement {
  static readonly elementKey = "paragraph";
  static readonly label = "element_paragraph_label";
  static readonly icon = NotesRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_paragraph_description";
  static readonly elementPreviewUrl =
    "/img/add-paragraph-element-preview-default.svg";
  static readonly dialogPositionStatic = false;
  static readonly isDialogDisplay = false;
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<ParagraphProperties> = {
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <ParagraphPropertiesComponent element={element} />;
  }

  static create(properties: {
    richTextProperty: RichTextProperty;
    rteAlignment: RTEAlignmentProperty;
    dimension?: DimensionProperty;
  }) {
    return {
      ...properties,
      elementKey: this.elementKey,
    };
  }
}

const ParagraphPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof ParagraphElement.properties>(
    ParagraphElement.properties,
    element
  );
  return <>{renderer.render("richTextProperty")}</>;
};

elementDefinitionRegistry.register(ParagraphElement);
