import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";
import ReactHtmlParser from "react-html-parser";

export type ParagraphProperties = {
  richTextProperty: { text: string | undefined };
  rteAlignment: { style: string | undefined };
  dimension: { width?: number; height?: number };
};

export function Paragraph({
  properties: { richTextProperty, rteAlignment, dimension },
  className,
  innerRef,
  ...props
}: ElementComponentProps<ParagraphProperties>): JSX.Element {
  if (richTextProperty.text === undefined) return <></>;
  return (
    <div
      {...props}
      ref={innerRef}
      className={`ce ${className} hobaw-rte paragraph rte-${rteAlignment.style} cg-df-dg`}
      style={{
        width: dimension && dimension.width ? dimension.width : "",
        height: dimension && dimension.height ? dimension.height : "",
      }}
    >
      {ReactHtmlParser(richTextProperty.text)}
    </div>
  );
}

export const paragraphElementKey = "paragraph";

elementComponentRegistry.register(paragraphElementKey, Paragraph);
