import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { TCreateCategory } from "./api";
import { getRequestIsInProgress } from "../api/selectors";
import { SetupStep0Form } from "./SetupStep0Form";
import { SetupStep1Form } from "./SetupStep1Form";
import { SetupStep2Form } from "./SetupStep2Form";
import { SetupStep2bForm } from "./SetupStep2bForm";
import {
  createWebsiteCore,
  getWebsiteDetails,
  updateWebsiteDetailsOnCreate,
  updateWebsitePurposes,
} from "./websiteActions";
import { fetchCurrentUser, logActivity } from "auth/authActions";
import { Loader } from "./Loader/Loader";
import { getSlug } from "./selectors";
import { useEffect } from "react";
import { uploadFile } from "admin/S3Client";
import { getS3config, getSubscription } from "editor/states/selectors";
import { getS3Configuration } from "editor/states/website";
import {
  TemplatesFactory,
  randomSlug,
} from "editor/templates/TemplatesFactory";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";
import { ForkType, SetupStepFork } from "./SetupStepFork";
import { SetupStep3aForm } from "./SetupStep3aForm";
import { SetupStep3bForm } from "./SetupStep3bForm";
import { SetupStep3cForm } from "./SetupStep3cForm";
import { SetupStep3dForm } from "./SetupStep3dForm";
import { SetupStep3eForm } from "./SetupStep3eForm";
import { SetupStep3fForm } from "./SetupStep3fForm";
import { SetupStep3gForm } from "./SetupStep3gForm";
import { SetupStep4aForm } from "./SetupStep4aForm";
import { SetupStep4bForm } from "./SetupStep4bForm";
import { SetupStep4cForm } from "./SetupStep4cForm";
import { SetupStep4dForm } from "./SetupStep4dForm";
import { TemplateKey, templateTitles } from "editor/templates/types";
import {
  createChildPagesFromTemplate,
  pageCreatedFromTemplate,
} from "editor/components/impl/Editor/editorActions";
import { Layout } from "common/elements/types";
import { fetchPages } from "./pageActions";

export interface ToUpload {
  filename: string;
  file: File;
  dataUrl: string;
}

export interface PurposeWorkServicesProduct {
  id: number;
  title: string | undefined;
  description: string | undefined;
  photoUrl: ToUpload | undefined;
  oldFilename: string | undefined;
}

export interface PurposeReview {
  id: number;
  title: string | undefined;
  comment: string | undefined;
  reviewer: string | undefined;
  reviewerPhoto: ToUpload | undefined;
  rating: number | undefined;
  oldFilename: string | undefined;
}

export interface PurposeTeam {
  description: string | undefined;
  member: PurposeTeamMember[];
}

export interface PurposeTeamMember {
  id: number;
  name: string | undefined;
  position: string | undefined;
  desription: string | undefined;
  photoUrl: ToUpload | undefined;
  oldFilename: string | undefined;
}

export interface PurposeLocation {
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  country: string | undefined;
}

export interface PurposeContact {
  mobile1: string | undefined;
  mobile2: string | undefined;
  mobile3: string | undefined;
  messenger: string | undefined;
  instagram: string | undefined;
  whatsapp: string | undefined;
  telegram: string | undefined;
  discord: string | undefined;
}

export interface PurposeSocial {
  facebook: string | undefined;
  twitter: string | undefined;
  instagram: string | undefined;
  linkedin: string | undefined;
  whatsapp: string | undefined;
  twitch: string | undefined;
  tiktok: string | undefined;
  youtube: string | undefined;
  telegram: string | undefined;
  discord: string | undefined;
}

export interface ISetupFormValues {
  title: string;
  url: string;
  category: TCreateCategory;
  siteCreationType: ForkType;
  description: string;
  isPurposeContact: boolean | undefined;
  isPurposeWorkServices: boolean | undefined;
  isPurposeProduct: boolean | undefined;
  isPurposeLocation: boolean | undefined;
  isPurposeOther: boolean | undefined;
  isPurposeIncreaseSocial: boolean | undefined;
  isPurposeIntroduceTeam: boolean | undefined;
  isPurposeReview: boolean | undefined;
  contact: PurposeContact;
  services: PurposeWorkServicesProduct[];
  products: PurposeWorkServicesProduct[];
  location: PurposeLocation | undefined;
  social: PurposeSocial;
  team: PurposeTeam;
  reviews: PurposeReview[];
  purposeName: string | undefined;
  purposeDescription: string | undefined;
  isDesignLogo: boolean | undefined;
  isDesignColors: boolean | undefined;
  isDesignTemplates: boolean | undefined;
  colorPrimary: string | undefined;
  colorSecondary: string | undefined;
  colorTernary: string | undefined;
  colorType: string | undefined;
  templateKey: TemplateKey | undefined;
  fontPrimary: string | undefined;
  fontSecondary: string | undefined;
  radiusPrimary: string | undefined;
  radiusSecondary: string | undefined;
  shadowPrimary: string | undefined;
}

export interface IStepProps {
  values: ISetupFormValues;
  handleSaveAndContinue: (valuesToUpdate: Partial<ISetupFormValues>) => void;
  isSaving: boolean;
  handleStepBack: () => void;
  setIsCreateLogo: any;
  setIsSelectTemplateLogo: any;
  setImagesToUpload: any;
  imagesToUpload: ToUpload[];
  setPrimaryColor: any;
  setSecondaryColor: any;
  setTernaryColor: any;
  setColorType: any;
  setPrimaryRadius: any;
  setSecondaryRadius: any;
  setPrimaryShadow: any;
  setPrimaryFont: any;
  setSecondaryFont: any;
  isPaid: boolean;
  setValues: (value: React.SetStateAction<ISetupFormValues>) => void;
  handleCreateWebsiteCore: (
    valuesToUpdate: Partial<ISetupFormValues>,
    callback: (isSuccess: boolean, slug: string) => void
  ) => void;
  handleSkip: (valuesToUpdate: Partial<ISetupFormValues>) => void;
}

export const Setup: React.FunctionComponent = () => {
  const [values, setValues] = useState<ISetupFormValues>({
    title: "",
    url: "",
    category: TCreateCategory.NONE,
    siteCreationType: undefined,
    description: "",
    isPurposeContact: undefined,
    isPurposeWorkServices: undefined,
    isPurposeProduct: undefined,
    isPurposeLocation: undefined,
    isPurposeOther: undefined,
    isPurposeIncreaseSocial: undefined,
    isPurposeIntroduceTeam: undefined,
    isPurposeReview: undefined,
    contact: {
      mobile1: undefined,
      mobile2: undefined,
      mobile3: undefined,
      messenger: undefined,
      instagram: undefined,
      whatsapp: undefined,
      telegram: undefined,
      discord: undefined,
    },
    social: {
      facebook: undefined,
      twitter: undefined,
      instagram: undefined,
      linkedin: undefined,
      whatsapp: undefined,
      twitch: undefined,
      tiktok: undefined,
      youtube: undefined,
      telegram: undefined,
      discord: undefined,
    },
    services: [],
    products: [],
    team: {
      description: undefined,
      member: [],
    },
    location: undefined,
    reviews: [],
    purposeName: undefined,
    purposeDescription: undefined,
    isDesignLogo: undefined,
    isDesignColors: undefined,
    isDesignTemplates: undefined,
    colorPrimary: undefined,
    colorSecondary: undefined,
    colorTernary: undefined,
    colorType: undefined,
    templateKey: undefined,
    fontPrimary: undefined,
    fontSecondary: undefined,
    radiusPrimary: undefined,
    radiusSecondary: undefined,
    shadowPrimary: undefined,
  });

  const history = useHistory();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [currentStep, setCurrentStep] = useState(0);
  const subscription = useSelector(getSubscription);
  const s3ConfigState = useSelector(getS3config);
  const isRequestInProgress = useSelector(getRequestIsInProgress);
  const userWebsiteSlug = useSelector(getSlug);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const dispatch = useDispatch();
  const steps = [
    SetupStep0Form, // Welcome - Step 0
    SetupStepFork, // Fork - Step 1 (Skipped)
    SetupStep1Form, // Purpose - Step 2 (Skipped)
    SetupStep2Form, // Name, URL, Description - Step 3
    SetupStep2bForm, // Purpose Selection - Step 4
    SetupStep3aForm, // Work / Services
    SetupStep3bForm, // Product
    SetupStep3cForm, // Location
    SetupStep3dForm, // Contact
    SetupStep3eForm, // Social
    SetupStep3fForm, // Team
    SetupStep3gForm, // Reviews
    SetupStep4aForm, // Design Selection
    SetupStep4bForm, // Logo
    SetupStep4cForm, // Colors
    SetupStep4dForm, // Templates
  ];

  const [isFinalStepDone, setIsFinalStepDone] = useState(false);
  const [isCreateLogo, setIsCreateLogo] = useState(false);
  const [isSelectTemplateLogo, setIsSelectTemplateLogo] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState<ToUpload[]>([]);
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [ternaryColor, setTernaryColor] = useState("");
  const [colorType, setColorType] = useState("");
  const [primaryRadius, setPrimaryRadius] = useState("5px");
  const [secondaryRadius, setSecondaryRadius] = useState("2.5px");
  const [primaryShadow, setPrimaryShadow] = useState("0.5");
  const [primaryFont, setPrimaryFont] = useState("");
  const [secondaryFont, setSecondaryFont] = useState("");
  const [isPremiumUser, setIsPremiumUser] = useState<boolean>(false);

  const username = useSelector(getUsername);

  useEffect(() => {
    if (
      s3ConfigState === undefined ||
      (s3ConfigState.s3Url !== null && !s3ConfigState.s3Url.trim().length)
    ) {
      dispatch(getS3Configuration());
    }

    if (
      values.url === undefined ||
      (values.url !== null && !values.url.trim().length)
    ) {
      dispatch(
        getWebsiteDetails((websiteResource) => {
          values.url = websiteResource.slug!;
          values.description = websiteResource.description!;
          values.title = websiteResource.title!;
          values.isPurposeContact = websiteResource.isPurposeContact!;
          values.isPurposeIncreaseSocial = websiteResource.isPurposeIncreaseSocial!;
          values.isPurposeIntroduceTeam = websiteResource.isPurposeIntroduceTeam!;
          values.isPurposeLocation = websiteResource.isPurposeLocation!;
          values.isPurposeProduct = websiteResource.isPurposeProduct!;
          values.isPurposeReview = websiteResource.isPurposeReview!;
          values.isPurposeWorkServices = websiteResource.isPurposeWorkServices!;

          if (websiteResource.purpose) {
            if (
              websiteResource.isPurposeContact &&
              websiteResource.purpose.contact
            ) {
              values.contact = {
                mobile1: websiteResource.purpose.contact.mobile1!,
                mobile2: websiteResource.purpose.contact.mobile2!,
                mobile3: websiteResource.purpose.contact.mobile3!,
                instagram: websiteResource.purpose.contact.instagram!,
                whatsapp: websiteResource.purpose.contact.whatsapp!,
                discord: websiteResource.purpose.contact.discord!,
                messenger: websiteResource.purpose.contact.messenger!,
                telegram: websiteResource.purpose.contact.telegram!,
              };
            }

            if (
              websiteResource.isPurposeIncreaseSocial &&
              websiteResource.purpose.social
            ) {
              values.social = {
                facebook: websiteResource.purpose.social.facebook,
                twitter: websiteResource.purpose.social.twitter,
                instagram: websiteResource.purpose.social.instagram,
                linkedin: websiteResource.purpose.social.linkedin,
                whatsapp: websiteResource.purpose.social.whatsapp,
                twitch: websiteResource.purpose.social.twitch,
                tiktok: websiteResource.purpose.social.tiktok,
                youtube: websiteResource.purpose.social.youtube,
                telegram: websiteResource.purpose.social.telegram,
                discord: websiteResource.purpose.social.discord,
              };
            }

            if (
              websiteResource.isPurposeLocation &&
              websiteResource.purpose.location
            ) {
              values.location = {
                address1: websiteResource.purpose.location.address1,
                address2: websiteResource.purpose.location.address2,
                city: websiteResource.purpose.location.city,
                country: websiteResource.purpose.location.country,
              };
            }

            if (
              websiteResource.isPurposeIntroduceTeam &&
              websiteResource.purpose.team
            ) {
              let teamMembers: PurposeTeamMember[] = [];
              let restTeamMembers = websiteResource.purpose.team.teamMembers;
              if (restTeamMembers) {
                restTeamMembers.forEach(function (restTeamMember, index) {
                  teamMembers.push({
                    id: index,
                    name: restTeamMember.name,
                    position: restTeamMember.position,
                    desription: restTeamMember.desription,
                    photoUrl: undefined,
                    oldFilename: undefined,
                  });
                });
              }

              values.team = {
                description: websiteResource.purpose.team.description,
                member: teamMembers,
              };
            }

            if (
              websiteResource.isPurposeProduct &&
              websiteResource.purpose.products
            ) {
              let purposeProducts: PurposeWorkServicesProduct[] = [];
              let restProducts = websiteResource.purpose.products;
              if (restProducts) {
                restProducts.forEach(function (restProduct, index) {
                  purposeProducts.push({
                    id: index,
                    title: restProduct.title,
                    description: restProduct.description,
                    photoUrl: undefined,
                    oldFilename: undefined,
                  });
                });
              }
              values.products = purposeProducts;
            }

            if (
              websiteResource.isPurposeReview &&
              websiteResource.purpose.reviews
            ) {
              let purposeReviews: PurposeReview[] = [];
              let restPurposeReviews = websiteResource.purpose.reviews;
              if (restPurposeReviews) {
                restPurposeReviews.forEach(function (restReview, index) {
                  purposeReviews.push({
                    id: index,
                    title: restReview.title,
                    comment: restReview.comment,
                    reviewer: restReview.reviewer,
                    reviewerPhoto: undefined,
                    rating: restReview.rating,
                    oldFilename: undefined,
                  });
                });
              }
            }

            if (
              websiteResource.isPurposeWorkServices &&
              websiteResource.purpose.workOrService
            ) {
              let purposeServices: PurposeWorkServicesProduct[] = [];
              let restServices = websiteResource.purpose.workOrService;
              if (restServices) {
                restServices.forEach(function (restService, index) {
                  purposeServices.push({
                    id: index,
                    title: restService.title,
                    description: restService.description,
                    photoUrl: undefined,
                    oldFilename: undefined,
                  });
                });
              }
              values.services = purposeServices;
            }
          }

          let stepToAdd = currentStep;
          if (
            websiteResource.slug &&
            websiteResource.description &&
            websiteResource.title
          ) {
            stepToAdd = stepToAdd + 4;
          }
          setCurrentStep(stepToAdd);
        })
      );
    }
  }, [
    dispatch,
    s3ConfigState,
    values.url,
    currentStep,
    values.contact,
    values.description,
    values.isPurposeContact,
    values.isPurposeIncreaseSocial,
    values.isPurposeIntroduceTeam,
    values.isPurposeLocation,
    values.isPurposeProduct,
    values.isPurposeReview,
    values.isPurposeWorkServices,
    values.location,
    values.products,
    values.services,
    values.social,
    values.team,
    values.title,
  ]);

  useEffect(() => {
    if (subscription && subscription.isActive) {
      setIsPremiumUser(true);
    }
  }, [subscription]);

  useEffect(() => {
    if (websiteSlug && websiteSlug !== undefined) {
      if (s3ConfigState && imagesToUpload.length > 0) {
        imagesToUpload.forEach((imageToUpload) => {
          uploadFile(
            imageToUpload.file,
            websiteSlug,
            imageToUpload.filename,
            s3ConfigState
          ).then(function () {
            if (values.isDesignTemplates === false) {
              history.push("/admin");
            }
          });
        });
      }
      // else {
      //   if (values.isDesignTemplates === false) {
      //     history.push("/admin");
      //   }
      // }
    }
  }, [
    dispatch,
    history,
    websiteSlug,
    s3ConfigState,
    imagesToUpload,
    currentStep,
    values.isDesignTemplates,
  ]);

  useEffect(() => {
    dispatch(logActivity("WEBSITE SETUP START", "Setup Wizard", username));
  }, [dispatch, username]);

  const setBaseStyles = (updatedValues: Partial<ISetupFormValues>) => {
    let fontPrimary = updatedValues.fontPrimary
      ? updatedValues.fontPrimary
      : templateTitles[TemplateKey.Blank].primaryFont;
    let fontSecondary = updatedValues.fontSecondary
      ? updatedValues.fontSecondary
      : templateTitles[TemplateKey.Blank].secondaryFont;
    let colorPrimary = updatedValues.colorPrimary
      ? updatedValues.colorPrimary
      : templateTitles[TemplateKey.Blank].primaryColor;
    let colorSecondary = updatedValues.colorSecondary
      ? updatedValues.colorSecondary
      : templateTitles[TemplateKey.Blank].secondaryColor;
    let colorTernary = updatedValues.colorTernary
      ? updatedValues.colorTernary
      : templateTitles[TemplateKey.Blank].ternaryColor;
    let colorType = updatedValues.colorType
      ? updatedValues.colorType
      : templateTitles[TemplateKey.Blank].colorType;
    let radiusPrimary = updatedValues.radiusPrimary
      ? updatedValues.radiusPrimary
      : templateTitles[TemplateKey.Blank].primaryRadius;
    let radiusSecondary = updatedValues.radiusSecondary
      ? updatedValues.radiusSecondary
      : templateTitles[TemplateKey.Blank].secondaryRadius;
    let shadowPrimary = updatedValues.shadowPrimary
      ? updatedValues.shadowPrimary
      : templateTitles[TemplateKey.Blank].primaryShadow;

    setPrimaryColor(colorPrimary);
    setSecondaryColor(colorSecondary);
    setTernaryColor(colorTernary);
    setColorType(colorType);
    setPrimaryRadius(radiusPrimary);
    setSecondaryRadius(radiusSecondary);
    setPrimaryShadow(shadowPrimary);
    setPrimaryFont(fontPrimary);
    setSecondaryFont(fontSecondary);
  };

  const handleCreateWebsiteCore = (
    valuesToUpdate: Partial<ISetupFormValues>,
    callback: (isSuccess: boolean, slug: string) => void
  ) => {
    const updatedValues = { ...values, ...valuesToUpdate };
    setBaseStyles(updatedValues);
    setValues(updatedValues);
    dispatch(
      createWebsiteCore(
        updatedValues.title!,
        updatedValues.category!,
        primaryFont,
        secondaryFont,
        primaryColor,
        secondaryColor,
        ternaryColor,
        colorType,
        primaryRadius,
        secondaryRadius,
        primaryShadow,
        isCreateLogo,
        TemplatesFactory.getMaxPageWidth(),
        timeZone,
        updatedValues.description!,
        updatedValues.url!,
        callback
      )
    );
  };

  const handleSkip = (valuesToUpdate: Partial<ISetupFormValues>) => {
    dispatch(
      logActivity(
        "WEBSITE SETUP Skipped",
        "Setup Wizard",
        username,
        valuesToUpdate.category
      )
    );
    setIsFinalStepDone(true);
    setupSite(valuesToUpdate);
    dispatch(fetchCurrentUser());
  };

  const handleSaveAndContinue = (valuesToUpdate: Partial<ISetupFormValues>) => {
    const updatedValues = { ...values, ...valuesToUpdate };
    setBaseStyles(updatedValues);
    setValues(updatedValues);

    if (currentStep === steps.length - 1) {
      dispatch(
        logActivity(
          "WEBSITE SETUP END",
          "Setup Wizard",
          username,
          valuesToUpdate.category
        )
      );
      setIsFinalStepDone(true);
      setupSite(valuesToUpdate);
      dispatch(fetchCurrentUser());
    } else {
      if (updatedValues.url !== undefined) {
        if (
          (currentStep === 5 && updatedValues.isPurposeWorkServices) ||
          (currentStep === 6 && updatedValues.isPurposeProduct) ||
          (currentStep === 7 && updatedValues.isPurposeLocation) ||
          (currentStep === 8 && updatedValues.isPurposeContact) ||
          (currentStep === 9 && updatedValues.isPurposeIncreaseSocial) ||
          (currentStep === 10 && updatedValues.isPurposeIntroduceTeam) ||
          (currentStep === 11 && updatedValues.isPurposeReview)
        ) {
          dispatch(updateWebsitePurposes(updatedValues));
        }
      }
      setCurrentStep(currentStep + 1);
    }
  };

  const setupSite = (updatedValues: Partial<ISetupFormValues>) => {
    let masterSlug = randomSlug(6);

    // Call createWebsite
    dispatch(
      updateWebsiteDetailsOnCreate(
        updatedValues.url,
        TemplatesFactory.getMaxPageWidth(),
        primaryFont,
        secondaryFont,
        primaryColor,
        secondaryColor,
        ternaryColor,
        colorType,
        primaryRadius,
        secondaryRadius,
        primaryShadow,
        isCreateLogo,
        (websiteSlug: string) => {
          if (
            updatedValues.isDesignTemplates &&
            updatedValues.templateKey !== undefined
          ) {
            // if (websiteSlug && websiteSlug !== undefined && s3ConfigState) {
            //   if (updatedValues.isPurposeWorkServices === true && updatedValues.services) {
            //     updatedValues.services.map((service) => {
            //       purposesPromises.push(
            //         new Promise(() => {
            //           if (service && service.photoUrl && service.photoUrl.file && service.photoUrl.filename) {
            //             uploadFile(
            //               service.photoUrl.file,
            //               websiteSlug,
            //               service.photoUrl.filename,
            //               s3ConfigState
            //             ).then((location) => {

            //             });
            //             return;
            //           }
            //           return;
            //         })
            //       );
            //     })
            //   }
            // }

            // dispatch(pageCreateDetails(
            //   websiteSlug,
            //   updatedValues.isPurposeContact,
            //   updatedValues.isPurposeWorkServices,
            //   updatedValues.isPurposeProduct,
            //   updatedValues.isPurposeLocation,
            //   updatedValues.isPurposeOther,
            //   updatedValues.isPurposeIncreaseSocial,
            //   updatedValues.isPurposeIntroduceTeam,
            //   updatedValues.isPurposeReview,
            //   updatedValues.contact,
            //   updatedValues.services,
            //   updatedValues.products,
            //   updatedValues.location,
            //   updatedValues.social,
            //   updatedValues.team,
            //   updatedValues.reviews,
            //   updatedValues.purposeName,
            //   updatedValues.purposeDescription,
            //   updatedValues.isDesignLogo,
            //   updatedValues.isDesignColors,
            // ));

            dispatch(
              pageCreatedFromTemplate(
                websiteSlug,
                "Home", // Todo use translate
                "Home | " + updatedValues.description,
                updatedValues.templateKey!,
                true,
                (pageSlug: string, layout: Layout) => {
                  if (
                    updatedValues.templateKey &&
                    updatedValues.templateKey !== TemplateKey.Blank
                  ) {
                    //Todo include templates that has no children
                    dispatch(
                      createChildPagesFromTemplate(
                        websiteSlug,
                        updatedValues.title!,
                        "", // Set the description as an empty string for now at this stage of website creation
                        updatedValues.templateKey,
                        false,
                        primaryFont,
                        secondaryFont,
                        primaryColor,
                        secondaryColor,
                        ternaryColor,
                        colorType,
                        primaryRadius,
                        secondaryRadius,
                        primaryShadow,
                        pageSlug,
                        (websiteSlug) => {
                          dispatch(fetchPages(websiteSlug));
                          history.push(`/admin/pages/${pageSlug}/edit`);
                        },
                        isCreateLogo
                      )
                    );
                  } else {
                    dispatch(fetchPages(websiteSlug));
                    history.push(`/admin/pages/${pageSlug}/edit`);
                  }
                },
                primaryFont,
                secondaryFont,
                primaryColor,
                secondaryColor,
                ternaryColor,
                colorType,
                primaryRadius,
                secondaryRadius,
                primaryShadow,
                updatedValues.templateKey === TemplateKey.Blank ? false : true, //Todo include templates that has no children
                isCreateLogo,
                masterSlug
              )
            );
          }
        }
      )
    );

    // Call pageCreatedFromTemplate

    // Call updateWebsiteDetails

    // Call publish

    // Display modal with choice A. Go to Admin, B. Go to Editor, C. View Website
  };

  const handleStepBack = () => {
    let Step = steps[currentStep];
    let deductSteps = 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep4cForm" &&
      values &&
      (values.isDesignColors === undefined || values.isDesignColors === false)
    )
      deductSteps = deductSteps + 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep4bForm" &&
      values &&
      (values.isDesignLogo === undefined || values.isDesignLogo === false)
    )
      deductSteps = deductSteps + 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep3gForm" &&
      values &&
      (values.isPurposeReview === undefined || values.isPurposeReview === false)
    )
      deductSteps = deductSteps + 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep3fForm" &&
      values &&
      (values.isPurposeIntroduceTeam === undefined ||
        values.isPurposeIntroduceTeam === false)
    )
      deductSteps = deductSteps + 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep3eForm" &&
      values &&
      (values.isPurposeIncreaseSocial === undefined ||
        values.isPurposeIncreaseSocial === false)
    )
      deductSteps = deductSteps + 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep3dForm" &&
      values &&
      (values.isPurposeContact === undefined ||
        values.isPurposeContact === false)
    )
      deductSteps = deductSteps + 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep3cForm" &&
      values &&
      (values.isPurposeLocation === undefined ||
        values.isPurposeLocation === false)
    )
      deductSteps = deductSteps + 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep3bForm" &&
      values &&
      (values.isPurposeProduct === undefined ||
        values.isPurposeProduct === false)
    )
      deductSteps = deductSteps + 1;

    Step = steps[currentStep - deductSteps];
    if (
      Step.name === "SetupStep3aForm" &&
      values &&
      (values.isPurposeWorkServices === undefined ||
        values.isPurposeWorkServices === false)
    )
      deductSteps = deductSteps + 1;

    setCurrentStep(currentStep - deductSteps);
  };

  const Step = steps[currentStep];

  return (
    <>
      {isRequestInProgress || isFinalStepDone || userWebsiteSlug ? (
        <Loader />
      ) : (
        <Modal
          size={isCreateLogo || isSelectTemplateLogo ? "xl" : "lg"}
          centered
          show={true}
          className="urux-setup"
        >
          <Step
            values={values}
            handleSaveAndContinue={handleSaveAndContinue}
            isSaving={isRequestInProgress}
            handleStepBack={handleStepBack}
            setIsCreateLogo={setIsCreateLogo}
            setIsSelectTemplateLogo={setIsSelectTemplateLogo}
            setImagesToUpload={setImagesToUpload}
            imagesToUpload={imagesToUpload}
            setPrimaryColor={setPrimaryColor}
            setSecondaryColor={setSecondaryColor}
            setTernaryColor={setTernaryColor}
            setColorType={setColorType}
            setPrimaryRadius={setPrimaryRadius}
            setSecondaryRadius={setSecondaryRadius}
            setPrimaryShadow={setPrimaryShadow}
            setPrimaryFont={setPrimaryFont}
            setSecondaryFont={setSecondaryFont}
            isPaid={isPremiumUser}
            setValues={setValues}
            handleCreateWebsiteCore={handleCreateWebsiteCore}
            handleSkip={handleSkip}
          />
        </Modal>
      )}
    </>
  );
};
