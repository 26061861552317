import React from "react";

import { NavItem } from "common/elements";
import { Link, ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type NavProperties = {
  links: Link[];
  boldness?: number;
  spacing?: number;
  size?: number;
  style: string;
  isProduction: boolean;
  linkColor?: string;
};

export function Nav({
  properties: {
    links,
    boldness,
    spacing,
    size,
    style,
    isProduction,
    linkColor,
  },
  className,
  innerRef,
  children,
  ...props
}: ElementComponentProps<NavProperties>): JSX.Element {
  return (
    <div className="navs-container">
      <label htmlFor="toggle" className="menu p-c-b p-r p-sh">
        Menu
      </label>
      <input type="checkbox" id="toggle" />
      <nav
        {...props}
        ref={innerRef}
        className={`${className} nav style-${style}`}
      >
        {links ? (
          links.map((link: Link, index: number) => (
            <NavItem
              key={index}
              text={link.text}
              url={link.url}
              boldness={boldness}
              spacing={spacing}
              size={size}
              openNewTab={link.openNewTab}
              style={style}
              isLastItem={links.length - 1 === index}
              isProduction={isProduction}
              linkColor={linkColor}
            />
          ))
        ) : (
          <></>
        )}
      </nav>
    </div>
  );
}

export const navElementKey = "nav";

elementComponentRegistry.register(navElementKey, Nav);
