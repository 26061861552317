import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type GoogleMapsEmbedProperties = {
  googleMapsUrl: { original: string; url: string };
  dimension: { width?: number; height?: number };
};
export function GoogleMapsEmbed({
  properties: { googleMapsUrl, dimension },
  className,
  ...props
}: ElementComponentProps<GoogleMapsEmbedProperties>): JSX.Element {
  return (
    <div
      {...props}
      style={{
        width: dimension && dimension.width ? dimension.width : "400px",
        height: dimension && dimension.height ? dimension.height : "300px",
      }}
      className={className + " rs sp"}
    >
      <iframe
        title="Google Maps"
        src={googleMapsUrl.url}
        width={dimension && dimension.width ? dimension.width : "400"}
        height={dimension && dimension.width ? dimension.width : "300"}
        style={{ border: "none" }}
        data-allowfullscreen=""
        data-loading="lazy"
        data-referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export const googleMapsEmbedElementKey = "google-maps";

elementComponentRegistry.register(googleMapsEmbedElementKey, GoogleMapsEmbed);
