import { SmartphoneRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import {
  cardElementKey,
  CardProperties,
} from "common/elements/components/Card";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { CardStyleProperty } from "editor/properties/CardStyleProperty";
import styles from "./Card.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import { DimensionProperty } from "editor/properties";
import { useTranslation } from "react-i18next";

export class CardElement {
  static readonly elementKey = cardElementKey;
  static readonly label = "element_card_label";
  static readonly icon = SmartphoneRounded;
  static readonly allowedToolbarPositions = [];
  static readonly allowedChildren = ["heading", "paragraph", "button", "image"];
  static readonly elementPreviewUrl =
    "/img/add-card-element-preview-default.svg";
  static readonly elementDescription = "element_card_description";
  static readonly dialogPositionStatic = false;
  static readonly isDeletable = true;
  static readonly isResizeableSmall = true;

  static readonly properties: PropertiesOf<CardProperties> = {
    cardStyle: CardStyleProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <CardPropertiesComponent element={element} />;
  }

  static create(properties: {
    cardStyle: string;
    dimension?: DimensionProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const CardPropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const renderer = new PropertyRenderer<typeof CardElement.properties>(
    CardElement.properties,
    element
  );
  const { t } = useTranslation();
  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="alignment">
        <Tab
          eventKey="alignment"
          title={t("dialog_44")}
          tabClassName="single-tab"
        >
          {renderer.render("cardStyle")}
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(CardElement);
