import { FormatShapesRounded } from "@material-ui/icons";
import { RichTextProperties } from "common/elements/base/RichText";
import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import React from "react";
import { elementDefinitionRegistry } from "../ElementDefinitionRegistry";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { RichTextProperty } from "editor/properties/RichTextProperty";
import { DimensionProperty, RTEAlignmentProperty } from "editor/properties";

export class RichTextElement {
  static readonly elementKey = "rt";
  static readonly label = "element_richtext_label";
  static readonly icon = FormatShapesRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_richtext_description";
  static readonly elementPreviewUrl =
    "/img/add-paragraph-element-preview-default.svg";
  static readonly dialogPositionStatic = false;
  static readonly isDialogDisplay = false;

  static readonly properties: PropertiesOf<RichTextProperties> = {
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty,
    dimension: DimensionProperty,
  };

  static renderProperties(element: ElementType) {
    return <RichTextPropertiesComponent element={element} />;
  }

  static create(properties: {
    richTextProperty: RichTextProperty;
    rteAlignment: RTEAlignmentProperty;
    dimension?: DimensionProperty;
  }) {
    return {
      ...properties,
      elementKey: this.elementKey,
    };
  }
}

const RichTextPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof RichTextElement.properties>(
    RichTextElement.properties,
    element
  );
  return <>{renderer.render("richTextProperty")}</>;
};

elementDefinitionRegistry.register(RichTextElement);
