import { Reducer } from "redux";

import {
  Action,
  FetchBaseUrlSuccessAction,
  FetchRedirectUriSuccessAction,
  FetchUserSuccessAction,
  GetGoogleIdSuccessAction,
  LoginSuccessAction,
  LogoutSuccessAction,
  UpdateUsernameSuccessAction,
  UpdatePasswordStateAction,
  UpdatePasswordSuccessAction,
  UpdateDetailsSuccessAction,
  UpdateUsernameStateAction,
  GetUnplashKeySuccessAction,
  GetUserProviderSuccessAction,
  GetDlocalCountryReferenceSuccessAction,
  HandlePaymentProcessResultAction,
  ResetPaymentStatusAction,
  LoginWithAccessTokenSuccessAction,
  VerifyTokenSuccessAction,
  GetPaypalCountryReferenceSuccessAction,
  GetPaymentSettingsForCountrySuccessAction,
} from "./authActions";

export type State = {
  userId?: number;
  username?: string;
  firstName?: string;
  lastName?: string;
  websiteSlug?: string;
  websiteUniqueId?: string;
  googleClientId?: string;
  unsplashKey?: string;
  redirectUri?: string;
  baseUrl?: string;
  isUsernameValid?: boolean;
  isSuper?: boolean;
  isPasswordUpdated: boolean;
  isUsernameUpdated: boolean;
  provider?: string;
  primaryFont?: string;
  secondaryFont?: string;
  primaryColor?: string;
  secondaryColor?: string;
  ternaryColor?: string;
  colorType?: string;
  primaryRadius?: string;
  secondaryRadius?: string;
  primaryShadow?: string;
  logoCreated: boolean;
  countryCode?: string;
  country?: string;
  dLocalcountry?: string;
  dLocalcountryIsoLanguageCode?: string;
  dLocalcountryCode?: string;
  dLocalcurrencyCode?: string;
  dLocaldocumentName?: string;
  dLocaldocumentFormat?: string;
  dLocaldocumentFormatMin?: number;
  dLocaldocumentFormatMax?: number;
  dLocalisRequired?: boolean;
  dLocalprice?: number;
  dLocalAnnualPrice?: number;
  dLocalApiKey?: string;
  dLocalPaymentStatus?: string;
  dLocalPaymentStateMessage?: string;
  isUserEmailVerified?: boolean;
  paypalCountry?: string;
  paypalCountryIsoLanguageCode?: string;
  paypalCountryCode?: string;
  paypalCurrencyCode?: string;
  paypalPrice?: number;
  paypalAnnualPrice?: number;
  paypalMonthlyPlanId?: string;
  paypalAnnualPlanId?: string;
  availablePaymentMethod?: string[];
  preferredPaymentMethod?: string;
};

const initialState: State = {
  isPasswordUpdated: false,
  isUsernameUpdated: false,
  logoCreated: false,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case "AUTH_LOGIN_WITH_ACCESSTOKEN_SUCCESS":
      return handleLoginWithAccessTokeSuccess(state, action);

    case "AUTH_LOGIN_SUCCESS":
      return handleLoginSuccess(state, action);

    case "AUTH_LOGOUT_SUCCESS":
      return handleLogoutSuccess(state, action);

    case "AUTH_GOOGLE_ID_SUCCESS":
      return handleGetGoogleClientId(state, action);

    case "AUTH_UNSPLASH_KEY_SUCCESS":
      return handleGetUnsplashKey(state, action);

    case "AUTH_USER_PROVIDER_SUCCESS":
      return handleGetUserProvider(state, action);

    case "AUTH_BASE_URL_SUCCESS":
      return handleFetchBaseUrlSuccess(state, action);

    case "AUTH_REDIRECT_URI_SUCCESS":
      return handleFetchRedirectUriSuccess(state, action);

    case "AUTH_FETCH_USER_SUCCESS":
      return handleValidateUsername(state, action);

    case "AUTH_UPDATE_USERNAME_SUCCESS":
      return handleUsernameUpdateSuccess(state, action);

    case "AUTH_UPDATE_PASSWORD_SUCCESS":
      return handlePasswordUpdateSuccess(state, action);

    case "AUTH_RESET_PASSWORD_STATE_ACTION":
      return handlePasswordUpdateState(state, action);

    case "AUTH_RESET_USERNAME_STATE_ACTION":
      return handleUsernameUpdateState(state, action);

    case "AUTH_UPDATE_DETAILS_SUCCESS":
      return handleDetailsUpdateSuccess(state, action);

    case "AUTH_GET_DLOCAL_COUNTRY_REFERENCE_SUCCESS":
      return handleGetDLocalCountryReferenceSuccess(state, action);

    case "AUTH_GET_PAYPAL_COUNTRY_REFERENCE_SUCCESS":
      return handleGetPaypalCountryReferenceSuccess(state, action);

    case "AUTH_HANDLE_PAYMENT_PROCESS_RESULT":
      return handlePaymentProcessResult(state, action);

    case "AUTH_RESET_PAYMENT_STATUS_ACTION":
      return handleResetPaymentStatus(state, action);

    case "AUTH_VERIFY_TOKEN_SUCCESS":
      return handleVerifyTokenSuccess(state, action);

    case "AUTH_GET_PAYMENT_SETTINGS_FOR_COUNTRY_SUCCESS":
      return handleGetPaymentSettingsForCountry(state, action);

    default:
      return state;
  }
};

const handleGetPaymentSettingsForCountry = (
  state: State,
  action: GetPaymentSettingsForCountrySuccessAction
): State => {
  let availablePaymentMethods: string[] = [];
  if (action.isDlocal) {
    availablePaymentMethods.push("dlocal");
  }

  if (action.isPaypal) {
    availablePaymentMethods.push("paypal");
  }

  return {
    ...state,
    availablePaymentMethod: availablePaymentMethods,
    preferredPaymentMethod: action.preferredPaymentGateway,
  };
};

const handleResetPaymentStatus = (
  state: State,
  action: ResetPaymentStatusAction
): State => {
  return {
    ...state,
    dLocalPaymentStatus: undefined,
    dLocalPaymentStateMessage: undefined,
  };
};

const handlePaymentProcessResult = (
  state: State,
  action: HandlePaymentProcessResultAction
): State => {
  return {
    ...state,
    dLocalPaymentStatus: action.status,
    dLocalPaymentStateMessage: action.message,
  };
};

const handleGetPaypalCountryReferenceSuccess = (
  state: State,
  action: GetPaypalCountryReferenceSuccessAction
): State => {
  return {
    ...state,
    paypalCountry: action.country,
    paypalCountryIsoLanguageCode: action.countryIsoLanguageCode,
    paypalCountryCode: action.countryCode,
    paypalCurrencyCode: action.currencyCode,
    paypalPrice: action.price,
    paypalAnnualPrice: action.annualPrice,
    paypalMonthlyPlanId: action.monthlyPlanId,
    paypalAnnualPlanId: action.annualPlanId,
  };
};

const handleGetDLocalCountryReferenceSuccess = (
  state: State,
  action: GetDlocalCountryReferenceSuccessAction
): State => {
  return {
    ...state,
    dLocalcountry: action.country,
    dLocalcountryIsoLanguageCode: action.countryIsoLanguageCode,
    dLocalcountryCode: action.countryCode,
    dLocalcurrencyCode: action.currencyCode,
    dLocaldocumentName: action.documentName,
    dLocaldocumentFormat: action.documentFormat,
    dLocaldocumentFormatMin: action.documentFormatMin,
    dLocaldocumentFormatMax: action.documentFormatMax,
    dLocalisRequired: action.isRequired,
    dLocalprice: action.price,
    dLocalAnnualPrice: action.annualPrice,
    dLocalApiKey: action.apiKey,
  };
};

const handleDetailsUpdateSuccess = (
  state: State,
  action: UpdateDetailsSuccessAction
): State => {
  return {
    ...state,
    username: action.username,
    firstName: action.firstName,
    lastName: action.lastName,
    country: action.country,
    countryCode: action.countryCode,
  };
};

const handleUsernameUpdateState = (
  state: State,
  action: UpdateUsernameStateAction
): State => {
  return {
    ...state,
    isUsernameUpdated: false,
  };
};

const handlePasswordUpdateState = (
  state: State,
  action: UpdatePasswordStateAction
): State => {
  return {
    ...state,
    isPasswordUpdated: false,
  };
};

const handlePasswordUpdateSuccess = (
  state: State,
  action: UpdatePasswordSuccessAction
): State => {
  return {
    ...state,
    isPasswordUpdated: true,
  };
};

const handleUsernameUpdateSuccess = (
  state: State,
  action: UpdateUsernameSuccessAction
): State => {
  return {
    ...state,
    username: action.username,
    isUsernameUpdated: true,
  };
};

const handleFetchRedirectUriSuccess = (
  state: State,
  action: FetchRedirectUriSuccessAction
): State => {
  return {
    ...state,
    redirectUri: action.redirectUri,
  };
};

const handleFetchBaseUrlSuccess = (
  state: State,
  action: FetchBaseUrlSuccessAction
): State => {
  return {
    ...state,
    baseUrl: action.baseUrl,
  };
};

const handleGetGoogleClientId = (
  state: State,
  action: GetGoogleIdSuccessAction
): State => {
  return {
    ...state,
    googleClientId: action.googleId,
  };
};

const handleGetUnsplashKey = (
  state: State,
  action: GetUnplashKeySuccessAction
): State => {
  return {
    ...state,
    unsplashKey: action.unplashKey,
  };
};

const handleGetUserProvider = (
  state: State,
  action: GetUserProviderSuccessAction
): State => {
  return {
    ...state,
    provider: action.provider,
    isUserEmailVerified: action.isUserVerified,
  };
};

const handleVerifyTokenSuccess = (
  state: State,
  action: VerifyTokenSuccessAction
): State => {
  return {
    ...state,
    isUserEmailVerified:
      action.isVerified && action.isVerified === "true" ? true : false,
  };
};

const handleLoginWithAccessTokeSuccess = (
  state: State,
  action: LoginWithAccessTokenSuccessAction
): State => {
  if (action.token !== undefined) {
    localStorage.setItem("accessToken", action.token);
  }
  if (action.userId) {
    // var oneday = new Date();
    // oneday.setHours(oneday.getHours() + 24);
    // localStorage.setItem("wauid", oneday + "");
    var oneMonth = new Date();
    oneMonth.setHours(oneMonth.getHours() + 730);
    localStorage.setItem("wauid", oneMonth + "");
  }

  return {
    ...state,
    userId: action.userId,
    firstName: action.firstName,
    lastName: action.lastName,
    isSuper: action.isSuper,
    websiteSlug: action.websiteSlug,
    websiteUniqueId: action.websiteUniqueId,
    countryCode: action.countryCode,
    country: action.country,
    provider: action.provider,
    isUserEmailVerified: true,
  };
};

const handleLoginSuccess = (
  state: State,
  action: LoginSuccessAction
): State => {
  if (action.token !== undefined) {
    localStorage.setItem("accessToken", action.token);
  }
  if (action.userId) {
    var oneday = new Date();
    oneday.setHours(oneday.getHours() + 24);
    localStorage.setItem("wauid", oneday + "");
  }

  return {
    ...state,
    username: action.username,
    userId: action.userId,
    firstName: action.firstName,
    lastName: action.lastName,
    isSuper: action.isSuper,
    websiteSlug: action.websiteSlug,
    websiteUniqueId: action.websiteUniqueId,
    countryCode: action.countryCode,
    country: action.country,
  };
};

const handleLogoutSuccess = (state: State, _: LogoutSuccessAction): State => {
  return {
    ...state,
    userId: undefined,
    username: undefined,
    firstName: undefined,
    lastName: undefined,
    websiteSlug: undefined,
    websiteUniqueId: undefined,
    googleClientId: undefined,
    unsplashKey: undefined,
    redirectUri: undefined,
    baseUrl: undefined,
    isUsernameValid: undefined,
    isSuper: undefined,
    isPasswordUpdated: false,
    isUsernameUpdated: false,
    provider: undefined,
    primaryFont: undefined,
    secondaryFont: undefined,
    primaryColor: undefined,
    secondaryColor: undefined,
    ternaryColor: undefined,
    colorType: undefined,
    primaryRadius: undefined,
    secondaryRadius: undefined,
    primaryShadow: undefined,
    logoCreated: false,
    countryCode: undefined,
    country: undefined,
    dLocalcountry: undefined,
    dLocalcountryIsoLanguageCode: undefined,
    dLocalcountryCode: undefined,
    dLocalcurrencyCode: undefined,
    dLocaldocumentName: undefined,
    dLocaldocumentFormat: undefined,
    dLocaldocumentFormatMin: undefined,
    dLocaldocumentFormatMax: undefined,
    dLocalisRequired: undefined,
    dLocalprice: undefined,
    dLocalAnnualPrice: undefined,
    dLocalApiKey: undefined,
    dLocalPaymentStatus: undefined,
    dLocalPaymentStateMessage: undefined,
    isUserEmailVerified: undefined,
  };
};

const handleValidateUsername = (
  state: State,
  _: FetchUserSuccessAction
): State => {
  return {
    ...state,
    isUsernameValid: _.isValid,
  };
};
