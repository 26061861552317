import { ViewModuleRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { CardsContainerStyleProperty } from "editor/properties/CardsContainerStyleProperty/CardsContainerStyleProperty";
import { CardsContainerOrientationProperty } from "editor/properties/CardsContainerOrientationProperty/CardsContainerOrientationProperty";
import {
  cardsContainerKey,
  CardsContainerProperties,
} from "common/elements/components/CardsContainer";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { Tabs, Tab } from "react-bootstrap";

import styles from "./CardsContainer.module.scss";
import { CardsAlignmentProperty } from "editor/properties/CardsAlignmentProperty/CardsAlignmentProperty";
import { useTranslation } from "react-i18next";

export class CardsContainerElement {
  static readonly elementKey = cardsContainerKey;
  static readonly label = "Cards";
  static readonly icon = ViewModuleRounded;
  static readonly allowedToolbarPositions = [];
  static readonly elementDescription = "element_cardscontainer_description";
  static readonly elementPreviewUrl = "./img/default-image.svg";
  static readonly allowedChildren = ["card"];
  static readonly isResizeable = false;
  static readonly dialogSize = "large";
  static readonly childDragDirection = "horizontal";

  static readonly properties: PropertiesOf<CardsContainerProperties> = {
    cardsStyle: CardsContainerStyleProperty,
    cardsOrientation: CardsContainerOrientationProperty,
    cardsAlignment: CardsAlignmentProperty,
  };

  static renderProperties(element: ElementType) {
    return <CardsContainerPropertiesComponent element={element} />;
  }

  static create(properties: {
    cardsStyle: string;
    cardsOrientation: string;
    cardsAlignment: string;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const CardsContainerPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<
    typeof CardsContainerElement.properties
  >(CardsContainerElement.properties, element);
  const { t } = useTranslation();
  return (
    <div className={styles["properties__container"]}>
      <Tabs id="cards-properties" defaultActiveKey="style">
        <Tab eventKey="style" title={t("dialog_75")}>
          {renderer.render("cardsStyle")}
        </Tab>
        <Tab eventKey="alignment" title={t("dialog_44")}>
          {renderer.render("cardsAlignment")}
        </Tab>
        <Tab eventKey="orientation" title={t("dialog_76")}>
          {renderer.render("cardsOrientation")}
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(CardsContainerElement);
