import { ViewModuleRounded } from "@material-ui/icons";
import React from "react";

import { ElementType, ElementPropertiesComponent } from "editor/elements/types";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { CardsStyleProperty } from "editor/properties/CardsStyleProperty";
import {
  cardsElementKey,
  CardsProperties,
} from "common/elements/sections/Cards";
import { PropertiesOf } from "editor/elements/types";
import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import WidthProperty from "editor/properties/WidthProperty";
import { BackgroundColorProperty } from "editor/properties";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./Cards.module.scss";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { useTranslation } from "react-i18next";

export class CardsElement {
  static readonly elementKey = cardsElementKey;
  static readonly label = "element_cards_label";
  static readonly icon = ViewModuleRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly allowedChildren = ["heading", "lead", "cards-container"];
  static readonly elementDescription = "element_cards_description";
  static readonly elementPreviewUrl =
    "/img/add-cards-element-preview-default.svg";
  static readonly isResizeable = true;
  static readonly resizeDirections = [];
  static readonly resizeGuides = [1024, 1280, 1366, 1440, 1600, 0];
  static readonly childDragDirection = "vertical";
  static readonly isReusable = true;
  static readonly dialogSize = "large";

  static readonly properties: PropertiesOf<CardsProperties> = {
    cardsStyle: CardsStyleProperty,
    width: WidthProperty,
    backgroundColorProperty: BackgroundColorProperty,
    backgroundImageProperty: BackgroundImageProperty,
  };

  static renderProperties(element: ElementType) {
    return <CardsPropertiesComponent element={element} />;
  }

  static create(properties: {
    cardsStyle: string;
    backgroundColorProperty: string;
    backgroundImageProperty: BackgroundImageProperty;
  }) {
    return { ...properties, elementKey: this.elementKey };
  }
}

const CardsPropertiesComponent: ElementPropertiesComponent = ({ element }) => {
  const { t } = useTranslation();
  const renderer = new PropertyRenderer<typeof CardsElement.properties>(
    CardsElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="nav-properties" defaultActiveKey="style">
        <Tab eventKey="style" title={t("dialog_44")}>
          {renderer.render("cardsStyle")}
        </Tab>
        <Tab eventKey="background-color" title={t("dialog_2")}>
          {renderer.render("backgroundColorProperty")}
        </Tab>
        <Tab eventKey="background" title={t("dialog_3")}>
          {renderer.render("backgroundImageProperty")}
        </Tab>
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(CardsElement);
