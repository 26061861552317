import {
  ButtonStyleProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import { Layout } from "common/elements/types";
import { LayoutEditor } from "editor/components/impl/LayoutEditor/LayoutEditor";
import { SectionHeaderElement } from "editor/elements/components/SectionHeader/SectionHeader";
import { HeadingElement } from "editor/elements/base/Heading";
import { CardsElement } from "editor/elements/sections/Cards/Cards";
import { CardsContainerElement } from "editor/elements/components/CardsContainer/CardsContainer";
import { TemplatesFactory } from "./TemplatesFactory";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const realestateChild1Template: ElementTemplates = {};

realestateChild1Template["cards"] = LayoutEditor.createEmpty()
  .add(
    CardsElement.create({
      cardsStyle: "0",
      backgroundColorProperty: "#FFFFFF",
      backgroundImageProperty: new BackgroundImageProperty(
        undefined,
        "repeat",
        "center",
        "center",
        "cover",
        0,
        0,
        1
      ),
    })
  )
  .addChildren(
    LayoutEditor.createEmpty()
      .add(
        SectionHeaderElement.create({
          sectionHeaderStyle: "parent",
        })
      )
      .addChildren(
        LayoutEditor.createEmpty()
          .add(
            HeadingElement.create({
              richTextProperty: new RichTextProperty(
                `<h2><span style="color: rgb(0, 41, 133);">Properties</span></h2>`
              ),
              rteAlignment: new RTEAlignmentProperty("center"),
            })
          )
          .build()
      )
      .add(
        CardsContainerElement.create({
          cardsStyle: "1",
          cardsOrientation: "1",
          cardsAlignment: "2",
        })
      )
      .addChildrenArray([
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Greens</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1515263487990-61b07816b324?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBhcnRtZW50JTIwYnVpbGRpbmd8ZW58MHx8MHxibHVlfA%3D%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Once Centro</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1572798177370-303020c3e6e2?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YXBhcnRtZW50JTIwYnVpbGRpbmd8ZW58MHx8MHxibHVlfA%3D%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Alveo</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1528290526716-9c58aa233fb7?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8YXBhcnRtZW50JTIwYnVpbGRpbmd8ZW58MHx8MHxibHVlfA%3D%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>El Patio</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1567320743368-9db24e12ebf0?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHVyYmFufGVufDB8fDB8Ymx1ZXw%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Parkford Suites</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1511452885600-a3d2c9148a31?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fGFyY2hpdGVjdHVyZXxlbnwwfHwwfGJsdWV8&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
        TemplatesFactory.createDefaultCard(
          new RichTextProperty("<h3>Viento</h3>"),
          new RTEAlignmentProperty("parent"),
          new ImageStyleProperty("1"),
          new ButtonStyleProperty("hobaw-btn-outline-primary"),
          new LinkProperty("View", "", false, false, false),
          new RichTextProperty(
            '<div class="s-f">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>'
          ),
          new RTEAlignmentProperty("parent"),
          "https://images.unsplash.com/photo-1536557869186-5a2f30e1ba9c?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTM1fHxhcmNoaXRlY3R1cmV8ZW58MHx8MHxibHVlfA%3D%3D&auto=format&fit=crop&w=500",
          "parent",
          true
        ),
      ])
      .build()
  )
  .build();
