import { FormatQuoteRounded } from "@material-ui/icons";
import {
  blockquoteElementKey,
  BlockQuoteProperties,
} from "common/elements/base/BlockQuote";
import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import React from "react";
import { elementDefinitionRegistry } from "../ElementDefinitionRegistry";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import { RichTextProperty, RTEAlignmentProperty } from "editor/properties";

export class BlockQuoteElement {
  static readonly elementKey = blockquoteElementKey;
  static readonly label = "element_blockquote_label";
  static readonly icon = FormatQuoteRounded;
  static readonly allowedToolbarPositions = ["top", "bottom"];
  static readonly elementDescription = "element_blockquote_description";
  static readonly elementPreviewUrl =
    "/img/add-quote-element-preview-default.svg";
  static readonly dialogPositionStatic = false;
  static readonly isDialogDisplay = false;

  static readonly properties: PropertiesOf<BlockQuoteProperties> = {
    richTextProperty: RichTextProperty,
    rteAlignment: RTEAlignmentProperty,
  };

  static renderProperties(element: ElementType) {
    return <BlockQuotePropertiesComponent element={element} />;
  }

  static create(properties: {
    richTextProperty: RichTextProperty;
    rteAlignment: RTEAlignmentProperty;
  }) {
    return {
      ...properties,
      elementKey: this.elementKey,
    };
  }
}

const BlockQuotePropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const renderer = new PropertyRenderer<typeof BlockQuoteElement.properties>(
    BlockQuoteElement.properties,
    element
  );
  return <>{renderer.render("richTextProperty")}</>;
};

elementDefinitionRegistry.register(BlockQuoteElement);
