import React from "react";
import { IProperty, PropertyComponent } from "../types";
import { Form, FormCheck } from "react-bootstrap";
import TextFieldGroup from "../../components/components/TextFieldGroup/TextFieldGroup";
import { ItemProps, Dropdown } from "editor/components/base/Dropdown/Dropdown";
import { useDispatch } from "react-redux";
import { propertySet } from "editor/states/layout";

import styles from "./FieldGroupConfigProperty.module.scss";
import { Formik } from "formik";

import { useTranslation } from "react-i18next";

export class FieldGroupConfigProperty implements IProperty {
  constructor(
    readonly label: string,
    readonly type: string,
    readonly placeholder?: string,
    readonly isRequired?: boolean
  ) {}

  render(elementId: string, propertyKey: string): JSX.Element {
    return (
      <FieldGroupConfigPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): FieldGroupConfigProperty {
    return new FieldGroupConfigProperty(
      value.label,
      value.type,
      value.placeholder,
      value.isRequired
    );
  }
}

const dropDownItems: ItemProps[] = [
  { key: "input", eventKey: "input", itemLabel: "Text field" },
  { key: "textarea", eventKey: "textarea", itemLabel: "Text Area" },
];

const FieldGroupConfigPropertyComponent: PropertyComponent<FieldGroupConfigProperty> = ({
  elementId,
  propertyKey,
  label,
  type,
  placeholder,
  isRequired,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new FieldGroupConfigProperty(
          e.target.value,
          type,
          placeholder,
          isRequired
        )
      )
    );
  };

  const handleSectionSelect = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new FieldGroupConfigProperty(label, String(e), placeholder, isRequired)
      )
    );
  };

  const handlePlaceholderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new FieldGroupConfigProperty(label, type, e.target.value, isRequired)
      )
    );
  };

  const handleRequireChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new FieldGroupConfigProperty(label, type, placeholder, e.target.checked)
      )
    );
  };

  return (
    <div className="medium-padded-container">
      <div className={styles["form__group"]}>
        <Formik initialValues={{ name: "" }} onSubmit={() => {}}>
          <Form className="form">
            <Dropdown
              buttonLabel={"Field Type"}
              items={dropDownItems}
              title={type === "input" ? "Text Field" : "Text Area"}
              onSelect={handleSectionSelect}
            />
            <TextFieldGroup
              name="text"
              label={t("dialog_100")}
              value={label}
              placeholder=""
              onChange={handleLabelChange}
            />
            <TextFieldGroup
              name="text"
              label={t("dialog_101")}
              value={placeholder}
              onChange={handlePlaceholderChange}
            />
            <FormCheck>
              <FormCheck.Label>
                <FormCheck.Input
                  type="checkbox"
                  checked={isRequired}
                  onChange={handleRequireChange}
                />
                {t("dialog_102")}
              </FormCheck.Label>
            </FormCheck>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
