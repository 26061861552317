import React, { useState } from "react";
import { Container, Col, Row, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button as GoodButton } from "editor/components/base/Button/Button";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import styles from "./Tests.module.scss";
import { useDispatch } from "react-redux";
import { logActivity, subscribeTestFBAd1 } from "auth/authActions";
import Footer from "auth/Footer";

export const TestFacebookAd1: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string | null>("");
  const [error, setError] = useState<string | null>(null);
  const [enable, setEnable] = useState(false);
  const [sent, setSent] = useState(false);

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleValidation = (e: any) => {
    if (!isValidEmail(e.target.value)) {
      setError("Email is invalid");
      setEmail(null);
      setEnable(false);
    } else {
      setEmail(e.target.value);
      setEnable(true);
      setError(null);
    }
  };

  const handleSaveAndContinue = () => {
    if (enable && email) {
      setSent(true);
      dispatch(subscribeTestFBAd1(email));
    }
  };

  return (
    <Container className={styles["auth__container-signup"]}>
      <div className={styles["auth__navbar-container"]}>
        <Navbar className={styles["auth__navbar"]}>
          <Navbar.Brand href="#">
            <img
              alt="goodweb logo"
              src="/img/goodweb-logo-symbol-white.svg"
              width={100}
            />
          </Navbar.Brand>
          <GoodButton
            label={t("login_page_lead2")}
            isFlat={true}
            to="/sign-up"
            variant="white-outline"
            onClick={() => {
              dispatch(
                logActivity("GO TO", "FB AD1 Test Page", undefined, "Sign-up")
              );
            }}
          />
        </Navbar>
      </div>
      <Row className={styles["auth__content-row"]}>
        <Col className={styles["auth__content-col"]}>
          <div className={styles["fbtest_card"]}>
            <img alt="Disconnected" src="/img/disconnect.png" />
            <h2>Uh-oh.</h2>
            <span className={styles["fbtest_lead"]}>
              Unfortunately our Facebook Page Website Builder is currently down.
            </span>
            <span className={styles["fbtest_normal"]}>
              If you want know when it's up and running again please provide
              your email below.
            </span>
            {sent ? (
              <></>
            ) : (
              <InputGroup
                className="mb-3 text-white"
                style={{
                  boxShadow: "rgb(0 0 0 / 35%) -7px 10px 4px -11px",
                  marginTop: 12,
                }}
              >
                <Form.Control
                  type="email"
                  placeholder={"Your Email"}
                  aria-label={"Your Email"}
                  // style={{ padding: 24, border: "2px solid #D8D8D8", borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
                  // className="p-3 border-white border-2 rounded-tl-[4px] rounded-bl-[4px] text-black  min-w-[200px] lg:min-w-[260px]"
                  onBlur={handleValidation}
                  aria-describedby="basic-addon2"
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  style={{
                    padding: "unset",
                    paddingRight: 8,
                    paddingLeft: 8,
                    color: "white",
                    border: "1px solid #3185FC",
                    backgroundColor: "#3185FC",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                  // className="pl-2 border-white border-2 p-3 rounded-tr-[4px] rounded-br-[4px]"
                  onClick={handleSaveAndContinue}
                >
                  Submit
                </Button>
              </InputGroup>
            )}
            <span style={{ color: "#FFCA7F", marginTop: "-8px" }}>
              {sent ? "Email received." : ""} {error}
            </span>
          </div>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};
