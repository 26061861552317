import { State } from "../store";
import { Page } from "./models";

export const getWebsiteTitle = (state: State): string | undefined =>
  state.admin.website.title;

export const getPages = (state: State): Page[] => state.admin.page.pages;

export const getSlug = (state: State): string | undefined =>
  state.admin.website.slug;
