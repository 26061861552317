import React, { useEffect, useState } from "react";

import { EditorArea } from "../EditorArea/EditorArea";

import { useIdleTimer } from "react-idle-timer";
import { Link, useHistory } from "react-router-dom";
import { getUserId, getUsername } from "auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import { logActivity } from "auth/authActions";
import { tawkWidgetToggle } from "../../../../admin/Tawk";
import styles from "../../../../admin/Admin.module.scss";

import { isDesktopView } from "../../../../admin/utils-country";

export const editorPage = "Editor";

export const Editor: React.FunctionComponent = () => {
  const history = useHistory();
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const username = useSelector(getUsername);
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    if (isDesktopView) {
      setIsDesktop(isDesktopView());
    }
  }, []);

  useEffect(() => {
    if (userId === undefined) {
      history.push("/login");
    }
  }, [history, userId]);

  useEffect(() => {
    dispatch(logActivity("VIEW", editorPage, username));
  }, [dispatch, username]);

  useEffect(() => {
    tawkWidgetToggle(false);
  }, []);

  const handleOnIdle = (event: any) => {
    window.location.reload(); // After 1 hour of idle time force reload to trigger check if auth is still valid
  };

  useIdleTimer({
    timeout: 1000 * 3600, // 1 hour of idle time
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return isDesktop ? (
    <EditorArea />
  ) : (
    <div className={styles["admin__gw-editor-disabled"]}>
      <span>The Website Builder is only available on Desktop right now.</span>
      <img
        alt="GoodWeb"
        src="/img/goodweb-fullmarkup-default.svg"
        className={styles["admin__gw-editor-logo"]}
      />
      <Link to={"/admin"} className={styles["admin__sidebar-logo-container"]}>
        Back to Admin
      </Link>
    </div>
  );
};
