import { fork } from "@redux-saga/core/effects";
import { combineReducers } from "redux";

import {
  reducer as addToolbarReducer,
  State as AddToolbarState,
} from "editor/components/impl/AddElementToolbar/addToolbarReducer";
import {
  reducer as addToolbarGuidesReducer,
  State as AddToolbarGuidesState,
} from "editor/components/impl/AddElementToolbar/addToolbarGuidesReducer";
import {
  reducer as editorReducer,
  State as EditorState,
} from "editor/components/impl/Editor/editorReducer";
import {
  reducer as resizeToolbarReducer,
  State as ResizeToolbarState,
} from "editor/components/impl/ResizeToolbar/resizeToolbarReducer";
import {
  reducer as websiteReducer,
  State as WebsiteState,
  rootSaga as websiteSaga,
} from "editor/states/website";
import {
  reducer as pageListReducer,
  State as PageListState,
  rootSaga as pageListSaga,
} from "editor/states/pageList";
import {
  reducer as latestPageVersionListReducer,
  State as LatestPageVersionListState,
  rootSaga as latestPageVersionListSaga,
} from "editor/states/latestPageVersionList";
import {
  reducer as photosReducer,
  State as PhotosState,
  rootSaga as photosSaga,
} from "editor/states/photo";
import {
  reducer as layoutReducer,
  State as LayoutState,
  rootSaga as layoutSaga,
} from "editor/states/layout";
import {
  reducer as reportsReducer,
  State as ReportsState,
  rootSaga as reportsSaga,
} from "editor/states/reports";

import { rootSaga as commonSaga } from "editor/sagas";

export const reducer = combineReducers({
  editor: editorReducer,
  addToolbar: addToolbarReducer,
  addToolbarGuides: addToolbarGuidesReducer,
  resizeToolbar: resizeToolbarReducer,
  website: websiteReducer,
  pageList: pageListReducer,
  latestPageVersionList: latestPageVersionListReducer,
  photos: photosReducer,
  layout: layoutReducer,
  reports: reportsReducer,
});

export type State = {
  editor: EditorState;
  addToolbar: AddToolbarState;
  addToolbarGuides: AddToolbarGuidesState;
  resizeToolbar: ResizeToolbarState;
  website: WebsiteState;
  pageList: PageListState;
  latestPageVersionList: LatestPageVersionListState;
  photos: PhotosState;
  layout: LayoutState;
  reports: ReportsState;
};

export function* saga() {
  yield fork(commonSaga);
  yield fork(websiteSaga);
  yield fork(pageListSaga);
  yield fork(latestPageVersionListSaga);
  yield fork(photosSaga);
  yield fork(layoutSaga);
  yield fork(reportsSaga);
}
