import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import {
  login,
  fetchCurrentUser,
  logActivity,
  resetPasswordWithToken,
} from "./authActions";
import { LoginForm, LoginFormValues } from "./LoginForm";
import { getUserId, getUserWebsiteSlug } from "./selectors";
import {
  getRequestIsInProgress,
  getRequestError,
  getAuthRequestState,
} from "../api/selectors";

import styles from "./Auth.module.scss";
import "./Auth.scss";
import Footer from "./Footer";
import { Loader } from "admin/Loader/Loader";
import { tawkWidgetToggle } from "../admin/Tawk";
import { resetAuthErrorRequest } from "api/apiActions";
import {
  PasswordResetForm,
  PasswordResetFormValues,
} from "./PasswordResetForm";
import { useTranslation } from "react-i18next";

export const loginPage = "Login";

export const Login: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const userId = useSelector(getUserId);
  const userWebsiteSlug = useSelector(getUserWebsiteSlug);
  const isRequestInProgress = useSelector(getRequestIsInProgress);
  const isAuthRequestInProgress = useSelector(getAuthRequestState);
  const error = useSelector(getRequestError);
  const dispatch = useDispatch();
  const location = useLocation();
  const [stateError, setStateError] = useState<string | undefined>();
  const [isFirstPass, setIsFirstPass] = useState(true);
  const [isPasswordResetRequested, setIsPasswordResetRequested] = useState<
    boolean
  >(false);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState<
    boolean | undefined
  >(undefined);
  const [resetToken, setResetToken] = useState<string | undefined>(undefined);
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const verifyParam = params.get("code");
    if (params && verifyParam) {
      setIsPasswordResetRequested(true);
      setResetToken(verifyParam);
    }
  }, [dispatch, search]);

  useEffect(() => {
    tawkWidgetToggle(false);
  }, []);

  useEffect(() => {
    if (isFirstPass) {
      setIsFirstPass(false);
    }
    if (error) {
      setStateError(error);
    }
  }, [error, isFirstPass]);

  useEffect(() => {
    const wauidDate = localStorage.getItem("wauid");
    if (wauidDate) {
      if (new Date(wauidDate) > new Date()) {
        dispatch(fetchCurrentUser());
      } else {
        localStorage.removeItem("wauid");
      }
    } else {
      localStorage.removeItem("wauid");
    }

    if (
      !document.cookie
        .split(";")
        .some((item) => item.trim().startsWith("sessionId="))
    ) {
      const sessionId = "_" + Math.random().toString(36).substr(2, 9);
      document.cookie = "sessionId=" + sessionId + "; max-age=" + 60 * 30 + ";";
    } else {
      const sessionId = document
        .cookie!.split("; ")
        .find((row) => row.startsWith("sessionId="))!
        .split("=")[1];
      document.cookie = "sessionId=" + sessionId + "; max-age=" + 60 * 30 + ";";
    }
    setIsFirstPass(false);
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(logActivity("VIEW", loginPage));
  }, [dispatch]);

  // If token is null user is not logged in
  if (localStorage.getItem("accessToken")) {
    if (userId === undefined) {
      localStorage.removeItem("accessToken");
    }
  }

  const resetPasswordSubmit = (values: PasswordResetFormValues) => {
    if (values.username && values.password && resetToken) {
      dispatch(
        resetPasswordWithToken(
          values.username,
          values.password,
          resetToken,
          handlePasswordResetResponse
        )
      );
      dispatch(logActivity("RESET-PASSWORD", loginPage));
    }
  };

  const handlePasswordResetResponse = (isPasswordResetSuccess: boolean) => {
    setIsPasswordResetSuccess(isPasswordResetSuccess);
  };

  const onSubmit = (values: LoginFormValues) => {
    setStateError(undefined);
    dispatch(resetAuthErrorRequest());
    dispatch(login(values.username, values.password, undefined));
    dispatch(logActivity("LOGIN", loginPage, values.username));
  };

  const { from } = (location.state as any) || { from: undefined };
  const LoginPage = (
    <Container fluid className={styles["auth__container"]}>
      <Row className={styles["auth__content-row"]}>
        <Col className={styles["auth__content"]} xs={7}>
          <div className={styles["auth__content-container"]}>
            <img
              className={styles["logo"]}
              alt="goodweb logo"
              src="/img/goodweb-fullmarkup-default.svg"
            />
            <div>
              <span>{t("login_page_title")}</span>
            </div>
            <div className={styles["auth__subcontent"]}>
              {t("login_page_subtitle")}
            </div>
          </div>
        </Col>
        <Col className={styles["auth__action_col"]} xs={5}>
          <img
            className={styles["logo"]}
            alt="goodweb logo"
            src="/img/goodweb-fullmarkup-default.svg"
          />
          {isPasswordResetRequested ? (
            <PasswordResetForm
              resetPasswordSubmit={resetPasswordSubmit}
              isPasswordResetSuccess={isPasswordResetSuccess}
              setResetToken={setResetToken}
              setIsPasswordResetRequested={setIsPasswordResetRequested}
            />
          ) : (
            <LoginForm
              onSubmit={onSubmit}
              isSubmitting={isRequestInProgress}
              error={stateError ? stateError : error}
            />
          )}
          <Footer />
        </Col>
      </Row>
    </Container>
  );

  // if (isAuthRequestInProgress || isFirstPass) {
  if (isAuthRequestInProgress && isFirstPass) {
    return <Loader />;
  } else if (error) {
    return LoginPage;
  }
  if (userId && from) {
    if (from && from.pathname && from.pathname.indexOf("oauth2") === -1) {
      return <Redirect to={from} />;
    } else {
      return <Redirect to={`/admin`} />;
    }
  } else if (userId && !userWebsiteSlug) {
    return <Redirect to="/admin/setup" />;
  } else if (userId && userWebsiteSlug) {
    return <Redirect to={`/admin`} />;
  } else {
    return LoginPage;
  }
};
