import React from "react";
import {
  BackgroundColorProperty,
  ButtonStyleProperty,
  DimensionProperty,
  FormConfigurationProperty,
  LinkProperty,
  RichTextProperty,
  RTEAlignmentProperty,
} from "../properties";
import { randomSlug, TemplatesFactory } from "./TemplatesFactory";
import { LogoTextProperty } from "../properties/LogoTextProperty/LogoTextProperty";
import { LogoStyleProperty } from "../properties/LogoStyleProperty/LogoStyleProperty";
import { LogoImageProperty } from "../properties/LogoImageProperty/LogoImageProperty";
import { LeadStyleProperty } from "editor/properties/LeadStyleProperty";
import { ImageStyleProperty } from "editor/properties/ImageStyleProperty";
import TextProperty from "editor/properties/TextProperty";
import { Layout } from "common/elements/types";
import { SocialLinkProperty } from "editor/properties/SocialLinkProperty/SocialLinkProperty";
import { SocialMedia } from "editor/properties/SocialMedia";
import { Twitter, Facebook } from "@material-ui/icons";
import { BackgroundImageProperty } from "editor/properties/BackgroundImageProperty/BackgroundImageProperty";
import { ChildPage, SharedElement } from "./types";
import { weddingChild1Template } from "./weddingChild1";
import { weddingChild2Template } from "./weddingChild2";
import { weddingChild3Template } from "./weddingChild3";
import { weddingChild4Template } from "./weddingChild4";
import { MessengerButtonStyleProperty } from "editor/properties/MessengerButtonStyleProperty/MessengerButtonStyleProperty";
import MessengerButtonUsernameProperty from "editor/properties/MessengerButtonStyleProperty/MessengerButtonUsernameProperty";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";

type ElementTemplates = { [elementTemplateKey: string]: Layout };

export const weddingTemplates: ElementTemplates = {};

const defaultLogoText = "logo";

export const templateKey = "wedding";

export const sharedElements: SharedElement[] = [
  {
    elementKey: "navbar",
    position: 0,
    templateTitle: templateKey,
  },
  {
    elementKey: "contact",
    position: 5,
    templateTitle: templateKey,
  },
  {
    elementKey: "footer",
    position: 6,
    templateTitle: templateKey,
  },
  {
    elementKey: "messenger",
    position: 7,
    templateTitle: templateKey,
  },
  {
    elementKey: "whatsapp",
    position: 8,
    templateTitle: templateKey,
  },
];

export const returnTemplate = (
  layoutKey: string,
  isLogoCreated: boolean,
  websiteSlug?: string
) => {
  switch (layoutKey) {
    case "navbar":
      return TemplatesFactory.createDefaultWeddingNavbar(
        TemplatesFactory.getLinkProperties(
          childPageTemplatesLeft,
          true,
          mainPageDetails
        ),
        TemplatesFactory.getLinkProperties(
          childPageTemplatesRight,
          true,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/main.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/wedding/main.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        new BackgroundColorProperty("#ffffff"),
        new BackgroundImageProperty(
          "",
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        100,
        "0",
        "1"
      );

    case "footer":
      return TemplatesFactory.createDefaultFooter(
        TemplatesFactory.getLinkProperties(
          childPageTemplates,
          false,
          mainPageDetails
        ),
        new LogoTextProperty(defaultLogoText),
        new LogoStyleProperty(
          "logo",
          isLogoCreated && websiteSlug
            ? `https://s3.amazonaws.com/img.goodweb.app/sites/${websiteSlug}/mono.png`
            : "https://s3.amazonaws.com/img.goodweb.app/sites/default/wedding/mono.png",
          new Date().toISOString()
        ),
        new LogoImageProperty(undefined, new Date().toISOString()),
        [
          new SocialLinkProperty(
            new SocialMedia(
              "facebook",
              "Facebook",
              <Facebook />,
              "www.facebook.com/johndoe"
            ),
            "/",
            true
          ),
          new SocialLinkProperty(
            new SocialMedia(
              "twitter",
              "Twitter",
              <Twitter />,
              "www.twitter.com/johndoe"
            ),
            "/",
            true
          ),
        ],
        new BackgroundColorProperty(""),
        new BackgroundImageProperty(
          undefined,
          "repeat",
          "center",
          "center",
          "cover",
          0,
          0,
          1
        ),
        "© Copyright Bows & Boquets " + new Date().getFullYear()
      );

    default:
      return weddingTemplates[layoutKey];
  }
};

export const childPageTemplates: ChildPage[] = [
  {
    templateName: "weddingChild1Template",
    details: {
      title: "About",
      slug: randomSlug(6),
      description: "About Us",
    },
    template: {
      template: weddingChild1Template,
    },
  },
  {
    templateName: "weddingChild2Template",
    details: {
      title: "Services",
      slug: randomSlug(6),
      description: "Our Wedding Services",
    },
    template: {
      template: weddingChild2Template,
    },
  },
  {
    templateName: "weddingChild3Template",
    details: {
      title: "Stories",
      slug: randomSlug(6),
      description: "The Stories of Clients",
    },
    template: {
      template: weddingChild3Template,
    },
  },
  {
    templateName: "weddingChild4Template",
    details: {
      title: "Contact",
      slug: randomSlug(6),
      description: "Contact Us",
    },
    template: {
      template: weddingChild4Template,
    },
  },
];

export const mainPageDetails: ChildPage = {
  templateName: "",
  details: {
    title: "Home page Wedding",
    slug: randomSlug(6),
    description: "A Page",
  },
  template: {
    template: weddingChild1Template,
  },
};

export const childPageTemplatesLeft: ChildPage[] = [
  {
    templateName: "weddingChild1Template",
    details: {
      title: "About",
      slug: randomSlug(6),
      description: "About Us",
    },
    template: {
      template: weddingChild1Template,
    },
  },
  {
    templateName: "weddingChild2Template",
    details: {
      title: "Services",
      slug: randomSlug(6),
      description: "Our Wedding Services",
    },
    template: {
      template: weddingChild2Template,
    },
  },
];

export const childPageTemplatesRight: ChildPage[] = [
  {
    templateName: "weddingChild3Template",
    details: {
      title: "Stories",
      slug: randomSlug(6),
      description: "The Stories of Clients",
    },
    template: {
      template: weddingChild3Template,
    },
  },
  {
    templateName: "weddingChild4Template",
    details: {
      title: "Contact",
      slug: randomSlug(6),
      description: "Contact Us",
    },
    template: {
      template: weddingChild4Template,
    },
  },
];

weddingTemplates["messenger"] = TemplatesFactory.createDefaultMessenger(
  "Wedding",
  new MessengerButtonStyleProperty("hobaw-messenger-btn-default"),
  new MessengerButtonUsernameProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-messenger-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-messenger-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br messenger-btn messenger-df"
);

weddingTemplates["whatsapp"] = TemplatesFactory.createDefaultWhatsapp(
  "Makeup",
  new WhatsappButtonStyleProperty("hobaw-whatsapp-btn-default"),
  new PhoneNumberProperty(""),
  new TreatmentProperty(true, [
    {
      isControl: true,
      isCurrentTreatment: false,
      hasTested: false,
      property: "hobaw-whatsapp-btn-default",
      propertyDesc: "Default Style",
    },
    {
      isControl: false,
      isCurrentTreatment: true,
      hasTested: false,
      property: "hobaw-whatsapp-btn-primary",
      propertyDesc: "Primary Color",
    },
  ]),
  true,
  true,
  "pos-fx pos-fx-br whatsapp-btn whatsapp-df"
);

weddingTemplates["navbar"] = TemplatesFactory.createDefaultWeddingNavbar(
  TemplatesFactory.getLinkProperties(
    childPageTemplatesLeft,
    true,
    mainPageDetails
  ),
  TemplatesFactory.getLinkProperties(
    childPageTemplatesRight,
    true,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/wedding/main.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  new BackgroundColorProperty("#ffffff"),
  new BackgroundImageProperty(
    "",
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  100,
  "0",
  "1"
);

weddingTemplates["articles"] = TemplatesFactory.createDefaultArticles(
  new LeadStyleProperty("1"),
  new RichTextProperty(
    '<h2><span style="color: #D4AF37;">TREASURED &amp; <br>PRICELESS MEMORIES<br>FOR YOUR DAY</span></h2>'
  ),
  new RTEAlignmentProperty("center"),
  new ImageStyleProperty("1"),
  new ButtonStyleProperty("hobaw-btn-outline-primary"),
  new LinkProperty("TALK TO US", "", false, false, false),
  new BackgroundColorProperty("#FFFFFF"),
  new RichTextProperty("<div></div>"),
  new RTEAlignmentProperty("center"),
  new RichTextProperty(
    '<span style="#9e9e9e">OUR SERVICE ARE RATED #1 IN WEDDING.COM</span>'
  ),
  new RTEAlignmentProperty("center"),
  "1",
  "1",
  "https://images.unsplash.com/photo-1597427681221-d4beae4f802d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
  "2"
);

weddingTemplates[
  "cards"
] = TemplatesFactory.createDefaultWeddingCards(
  new LeadStyleProperty("1"),
  new BackgroundColorProperty("#F4EACC"),
  new RichTextProperty(
    '<span style="color: #735e19">Best Catering, Best Photography, Best Day</span>'
  ),
  new RTEAlignmentProperty("center"),
  "0",
  [
    TemplatesFactory.createDefaultCardNoTitle(
      new ImageStyleProperty("1"),
      new ButtonStyleProperty("hobaw-btn-outline-primary"),
      new LinkProperty("Our Events", "", false, false, false),
      new RichTextProperty(
        '<span style="color: rgb(115, 94, 25);">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et massa vitae nisi imperdiet tempor.</span>'
      ),
      new RTEAlignmentProperty("left"),
      "https://images.unsplash.com/photo-1540248647338-e24852117013?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1142&q=80",
      "0"
    ),
  ]
);

weddingTemplates["articles2"] = TemplatesFactory.createDefaultArticlesNoTitle(
  new LeadStyleProperty("1"),
  new ImageStyleProperty("1"),
  new BackgroundColorProperty("#FFFFFF"),
  new RichTextProperty(
    '<div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25);"><br></span></div><div><span style="color: rgb(115, 94, 25); font-style: italic;">"Love seems the swiftest but it is the slowest of all growths. No man or woman really knows what perfect love is until they have been married a quarter of a century."</span><br></div></div>'
  ),
  new RTEAlignmentProperty("center"),
  "2",
  "1",
  "https://images.unsplash.com/photo-1648729415002-ae77bbb9d90d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1208&q=80",
  "2"
);

weddingTemplates["cover"] = TemplatesFactory.createDefaultCoverNoText(
  "1",
  new LeadStyleProperty("1"),
  new BackgroundImageProperty(
    "https://images.unsplash.com/photo-1600270237614-d20aef1c8b14?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
    "no-repeat",
    "50%",
    "50%",
    "100%",
    0,
    0,
    1
  ),
  new BackgroundColorProperty("#f4f4f4"),
  new RichTextProperty("<div></div>"),
  new RTEAlignmentProperty("parent"),
  new DimensionProperty(TemplatesFactory.getMaxPageWidth(), 60)
);

weddingTemplates["contact"] = TemplatesFactory.createDefaultContact(
  new LeadStyleProperty("2"),
  new TextProperty(
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, voluptatibus."
  ),
  new RichTextProperty(""),
  new RTEAlignmentProperty("parent"),
  new FormConfigurationProperty("email", ""),
  new BackgroundColorProperty("#ffffff"),
  new RichTextProperty(
    '<div><span style="color: rgb(115, 94, 25);">Let\'s chat about your perfect wedding.</span></div>'
  ),
  new RTEAlignmentProperty("parent"),
  "1",
  "element  ce hobaw-btn fp cp--bgc cp--brc brc2 brc2--hov cp-d10--bgc--hov sp rs c-rcp cp-d10--brc--hov cp-d10--bgc-rt-bw--hov"
);

weddingTemplates["footer"] = TemplatesFactory.createDefaultFooter(
  TemplatesFactory.getLinkProperties(
    childPageTemplates,
    false,
    mainPageDetails
  ),
  new LogoTextProperty(defaultLogoText),
  new LogoStyleProperty(
    "logo",
    "https://s3.amazonaws.com/img.goodweb.app/sites/default/wedding/mono.png",
    new Date().toISOString()
  ),
  new LogoImageProperty(undefined, new Date().toISOString()),
  [
    new SocialLinkProperty(
      new SocialMedia(
        "facebook",
        "Facebook",
        <Facebook />,
        "www.facebook.com/johndoe"
      ),
      "/",
      true
    ),
    new SocialLinkProperty(
      new SocialMedia(
        "twitter",
        "Twitter",
        <Twitter />,
        "www.twitter.com/johndoe"
      ),
      "/",
      true
    ),
  ],
  new BackgroundColorProperty(""),
  new BackgroundImageProperty(
    undefined,
    "repeat",
    "center",
    "center",
    "cover",
    0,
    0,
    1
  ),
  "© Copyright Bows & Boquets " + new Date().getFullYear()
);
