import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { Formik, Form } from "formik";
import "./CoverStyleProperty.scss";
import { useTranslation } from "react-i18next";

export class CoverStyleProperty implements IProperty {
  constructor(readonly style: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <CoverStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: string) {
    return new CoverStyleProperty(value);
  }
}

const CoverStylePropertyComponent: PropertyComponent<CoverStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let backgroundImageUrl = "";
  let backgroundSize = "";
  let backgroundRepeat = "";
  let backgroundPositionX = "";
  let backgroundPositionY = "";
  const domContainer = document.getElementById(elementId);
  if (domContainer) {
    backgroundImageUrl = domContainer.style.backgroundImage;
    backgroundSize = domContainer.style.backgroundSize;
    backgroundRepeat = domContainer.style.backgroundRepeat;
    backgroundPositionX = domContainer.style.backgroundPositionX;
    backgroundPositionY = domContainer.style.backgroundPositionY;
  }

  let draggable = (
    <Draggable key={"d1"} draggableId={"d1"} index={0}>
      {(provided, snapshot) => (
        <div
          className="draggable"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {t("dialog_67")}
        </div>
      )}
    </Draggable>
  );

  const handleStyleChange = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId === "droppable4") {
      dispatch(propertySet(elementId, propertyKey, "4"));
    } else if (result.destination.droppableId === "droppable2") {
      dispatch(propertySet(elementId, propertyKey, "2"));
    } else if (result.destination.droppableId === "droppable5") {
      dispatch(propertySet(elementId, propertyKey, "5"));
    } else if (result.destination.droppableId === "droppable3") {
      dispatch(propertySet(elementId, propertyKey, "3"));
    } else if (result.destination.droppableId === "droppable1") {
      dispatch(propertySet(elementId, propertyKey, "1"));
    } else if (result.destination.droppableId === "droppable6") {
      dispatch(propertySet(elementId, propertyKey, "6"));
    } else if (result.destination.droppableId === "droppable7") {
      dispatch(propertySet(elementId, propertyKey, "7"));
    } else if (result.destination.droppableId === "droppable8") {
      dispatch(propertySet(elementId, propertyKey, "8"));
    } else if (result.destination.droppableId === "droppable9") {
      dispatch(propertySet(elementId, propertyKey, "9"));
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <Form className="form">
        <div className="form__group">
          <div className="medium-padded-container">
            <div className={"instructions-container"}>{t("dialog_66")}</div>
            <div
              style={{
                backgroundColor: "unset",
                backgroundImage: backgroundImageUrl,
                backgroundSize: backgroundSize,
                backgroundRepeat: backgroundRepeat,
                backgroundPositionX: backgroundPositionX,
                backgroundPositionY: backgroundPositionY,
                borderRadius: 8,
              }}
            >
              <div className="grid">
                <DragDropContext
                  onDragEnd={(result: DropResult) => {
                    handleStyleChange(result);
                  }}
                >
                  <Droppable droppableId="droppable4">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc4"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "4" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable6">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc6"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "6" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable1">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc1"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "1" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable7">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc7"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "7" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable5">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc5"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "5" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable8">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc8"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "8" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable3">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc3"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "3" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable9">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc9"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "9" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>

                  <Droppable droppableId="droppable2">
                    {(provided, snapshot) => (
                      <div
                        className="pos loc2"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="cont">
                          {style === "2" ? draggable : <></>}
                        </div>
                        <div style={{ display: "none" }}>
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
