import React, { useCallback, useEffect, useState } from "react";
import { IProperty, PropertyComponent } from "../types";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import styles from "./CoverBackgroundProperty.module.scss";
import { ReactComponent as UnsplashLogo } from "../../../img/UnsplashWordmarkLogo.svg";
import { ReactComponent as RepeatXY } from "../../../img/RepeatXY.svg";
import { ReactComponent as RepeatX } from "../../../img/RepeatX.svg";
import { ReactComponent as RepeatY } from "../../../img/RepeatY.svg";
import { ReactComponent as RepeatNone } from "../../../img/RepeatNone.svg";
import { ReactComponent as Cover } from "../../../img/Cover.svg";
import { ReactComponent as Contain } from "../../../img/Contain.svg";
import { ReactComponent as None } from "../../../img/BgStretchNone.svg";
import { ReactComponent as Pos } from "../../../img/BgImagePosIndicator.svg";
import TextFieldGroup from "editor/components/components/TextFieldGroup/TextFieldGroup";
import {
  ArrowBackRounded,
  RefreshRounded,
  SearchRounded,
} from "@material-ui/icons";
import { Button } from "editor/components/base/Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { propertySet } from "editor/states/layout";
import { getPhotos, getPhotosStatus } from "../../../editor/states/selectors";
import {
  resetPhotos,
  getPhotos as getPhotosFromApi,
} from "../../../editor/states/photo";
import { Carousel } from "react-bootstrap";
import { getUnsplashKey } from "auth/selectors";
import { getUnplashKey } from "auth/authActions";
import { useTranslation } from "react-i18next";

export class CoverBackgroundImageProperty implements IProperty {
  constructor(
    readonly backgroundImageUrl: string,
    readonly repeat: string, // "repeat" | "repeat-x" | "repeat-y" | "no-repeat"
    readonly positionX: string,
    readonly positionY: string,
    readonly size: string
  ) {}

  render(elementId: string, propertyKey: string) {
    return (
      <CoverBackgroundImagePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): CoverBackgroundImageProperty {
    return new CoverBackgroundImageProperty(
      value.backgroundImageUrl,
      value.repeat,
      value.positionX,
      value.positionY,
      value.size
    );
  }
}

const CoverBackgroundImagePropertyComponent: PropertyComponent<CoverBackgroundImageProperty> = ({
  elementId,
  propertyKey,
  backgroundImageUrl,
  repeat,
  positionX,
  positionY,
  size,
}) => {
  const { t } = useTranslation();
  const [imgStrUrl, setImgStrUrl] = useState(backgroundImageUrl);
  const [queryString, setQueryString] = useState("");
  const photos = useSelector(getPhotos);
  const photoQueryState = useSelector(getPhotosStatus);
  const unsplashKey = useSelector(getUnsplashKey);
  const dispatch = useDispatch();

  useEffect(() => {
    if (unsplashKey === undefined) {
      dispatch(getUnplashKey());
    }
  }, [dispatch, unsplashKey]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("File reading was aborted");
        reader.onerror = () => console.log("File reading has failed");
        reader.onload = () => {
          setImgStrUrl(reader.result as string);
          dispatch(
            propertySet(
              elementId,
              propertyKey,
              new CoverBackgroundImageProperty(
                reader.result as string,
                repeat,
                positionX,
                positionY,
                size
              )
            )
          );
        };
        reader.readAsDataURL(file);
        let img = new Image();
        img.src = imgStrUrl;
      });
    },
    [
      imgStrUrl,
      dispatch,
      elementId,
      positionX,
      positionY,
      propertyKey,
      repeat,
      size,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    onDrop,
  });

  const handleStretchChange = (value: string): void => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new CoverBackgroundImageProperty(
          backgroundImageUrl,
          repeat,
          positionX,
          positionY,
          value
        )
      )
    );
  };

  const handleRepeatChange = (value: string) => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new CoverBackgroundImageProperty(
          backgroundImageUrl,
          value,
          positionX,
          positionY,
          size
        )
      )
    );
  };

  const handlePositionChange = (valueX: string, valueY: string) => {
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new CoverBackgroundImageProperty(
          backgroundImageUrl,
          repeat,
          valueX,
          valueY,
          size
        )
      )
    );
  };

  const handleReselectClick = () => {
    setImgStrUrl("");
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new CoverBackgroundImageProperty("", repeat, positionX, positionY, size)
      )
    );
  };

  const handleReselectFromUnsplash = () => {
    dispatch(resetPhotos());
    setQueryString("");
  };

  const handleGetPhotosFromUnsplash = () => {
    const key = unsplashKey === undefined ? "replace-me" : unsplashKey;
    dispatch(getPhotosFromApi(queryString, key));
  };

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryString(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && queryString !== "") {
      handleGetPhotosFromUnsplash();
    }
  };

  const handleSelectPhotoFromUnsplash = (url: string) => {
    setImgStrUrl(url);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new CoverBackgroundImageProperty(
          url,
          repeat,
          positionX,
          positionY,
          size
        )
      )
    );
  };

  let buttonLabel = "Search";
  let buttonIcon = SearchRounded;
  let isDisabled = false;
  if (photoQueryState === "in-progress") {
    buttonLabel = "Searching";
    buttonIcon = RefreshRounded;
    isDisabled = true;
  }

  return (
    <div className="medium-padded-container">
      {imgStrUrl && imgStrUrl !== "" ? (
        <div className={styles["background-preview-container"]}>
          <div
            className={styles["background"]}
            style={{
              backgroundImage: `url(${imgStrUrl})`,
              backgroundSize: size === "100%" ? "cover" : size,
              backgroundRepeat: repeat,
              backgroundPositionX: positionX,
              backgroundPositionY: positionY,
            }}
          >
            <Button
              label={t("dialog_95")}
              isFlat={true}
              variant="danger"
              size="medium"
              onClick={handleReselectClick}
              icon={ArrowBackRounded}
            />
          </div>
          <div className={styles["actions"]}>
            <div className={styles["top-actions"]}>
              <div className={styles["action-container"]}>
                <span>Stretch</span>
                <div>
                  <ul>
                    <li
                      className={size === "cover" ? styles["selected"] : ""}
                      onClick={(e: any) => handleStretchChange("cover")}
                    >
                      <Cover />
                    </li>
                    <li
                      className={size === "contain" ? styles["selected"] : ""}
                      onClick={(e: any) => handleStretchChange("contain")}
                    >
                      <Contain />
                    </li>
                    <li
                      className={size === "unset" ? styles["selected"] : ""}
                      onClick={(e: any) => handleStretchChange("unset")}
                    >
                      <None />
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles["action-container"]}>
                <span>Repeat</span>
                <div>
                  <ul>
                    <li
                      className={repeat === "repeat" ? styles["selected"] : ""}
                      onClick={(e: any) => handleRepeatChange("repeat")}
                    >
                      <RepeatXY />
                    </li>
                    <li
                      className={
                        repeat === "repeat-x" ? styles["selected"] : ""
                      }
                      onClick={(e: any) => handleRepeatChange("repeat-x")}
                    >
                      <RepeatX />
                    </li>
                    <li
                      className={
                        repeat === "repeat-y" ? styles["selected"] : ""
                      }
                      onClick={(e: any) => handleRepeatChange("repeat-y")}
                    >
                      <RepeatY />
                    </li>
                    <li
                      className={
                        repeat === "no-repeat" ? styles["selected"] : ""
                      }
                      onClick={(e: any) => handleRepeatChange("no-repeat")}
                    >
                      <RepeatNone />
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles["action-container"]}>
                <span>Position</span>
                <div>
                  <ul className={styles["position"]}>
                    <li
                      className={
                        positionX === "left" && positionY === "top"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) => handlePositionChange("left", "top")}
                    >
                      <Pos />
                    </li>
                    <li
                      className={
                        positionX === "center" && positionY === "top"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) =>
                        handlePositionChange("center", "top")
                      }
                    >
                      <Pos />
                    </li>
                    <li
                      className={
                        positionX === "right" && positionY === "top"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) => handlePositionChange("right", "top")}
                    >
                      <Pos />
                    </li>
                  </ul>
                  <ul className={styles["position"]}>
                    <li
                      className={
                        positionX === "left" && positionY === "center"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) =>
                        handlePositionChange("left", "center")
                      }
                    >
                      <Pos />
                    </li>
                    <li
                      className={
                        positionX === "center" && positionY === "center"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) =>
                        handlePositionChange("center", "center")
                      }
                    >
                      <Pos />
                    </li>
                    <li
                      className={
                        positionX === "right" && positionY === "center"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) =>
                        handlePositionChange("right", "center")
                      }
                    >
                      <Pos />
                    </li>
                  </ul>
                  <ul className={styles["position"]}>
                    <li
                      className={
                        positionX === "left" && positionY === "bottom"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) =>
                        handlePositionChange("left", "bottom")
                      }
                    >
                      <Pos />
                    </li>
                    <li
                      className={
                        positionX === "center" && positionY === "bottom"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) =>
                        handlePositionChange("center", "bottom")
                      }
                    >
                      <Pos />
                    </li>
                    <li
                      className={
                        positionX === "right" && positionY === "bottom"
                          ? styles["selected"]
                          : ""
                      }
                      onClick={(e: any) =>
                        handlePositionChange("right", "bottom")
                      }
                    >
                      <Pos />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : photos && photos.length > 0 && photoQueryState === "success" ? (
        <div>
          <Carousel interval={null} defaultActiveIndex={0}>
            {photos.map((photo) => (
              <Carousel.Item key={photo.id}>
                <div
                  className={styles["background-preview-container"]}
                  onClick={() =>
                    handleSelectPhotoFromUnsplash(photo.urls.large)
                  }
                >
                  <div
                    className={styles["background"]}
                    style={{
                      backgroundImage: `url(${photo.urls.small})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPositionX: "center",
                      backgroundPositionY: "center",
                    }}
                  ></div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className={styles["photos-slide-actions"]}>
            Click on image above to use
            <br />
            or
            <br />
            <span onClick={handleReselectFromUnsplash}>
              <ArrowBackRounded /> Search new image
            </span>
          </div>
        </div>
      ) : (
        <Formik initialValues={{}} onSubmit={async (values) => {}}>
          <div className={styles["choice-container"]}>
            <div {...getRootProps()} className={styles["dropzone"]}>
              <input {...getInputProps()} />
              <div className={styles["drop-zone-label-container"]}>
                <p>{t("upload_logo_1")}</p>
                <small>
                  {t("upload_logo_2")} <br />
                  {t("upload_logo_3")}
                </small>
              </div>
            </div>
            <div className={styles["unsplash-container"]}>
              <span className={styles["info-text"]}>{t("upload_logo_4")}</span>
              <UnsplashLogo />
              <div className={styles["action-container"]}>
                <TextFieldGroup
                  name="searchkey"
                  label={t("dialog_55")}
                  value={queryString}
                  size="m"
                  placeholder={t("dialog_10")}
                  onChange={handleQueryChange}
                  onKeyPress={handleKeyPress}
                />
                <Button
                  label={buttonLabel}
                  icon={buttonIcon}
                  variant="outline-primary"
                  size="medium"
                  onClick={() => handleGetPhotosFromUnsplash()}
                  onKeyUp={(e: React.KeyboardEvent) => {
                    if (e.keyCode) {
                      e.preventDefault();
                      handleGetPhotosFromUnsplash();
                    }
                  }}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </Formik>
      )}
    </div>
  );
};
// photoQueryState === "in-progress" ? (<></>) : (<></>)
