import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import * as yup from "yup";

import { TCreateCategory } from "./api";
import { IStepProps } from "./Setup";

import styles from "./Admin.module.scss";

import { useTranslation } from "react-i18next";

const schema = yup.object({
  category: yup.mixed<TCreateCategory>().required(/* TODO: Add a message */),
});

export type SetupStep4aFormValues = yup.InferType<typeof schema>;

export const SetupStep4aForm: React.FunctionComponent<IStepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
  handleSkip,
}) => {
  const { t } = useTranslation();

  const [stateDesignLogo, setStateDesignLogo] = useState<boolean | undefined>(
    values.isDesignLogo
  );
  const [stateDesignColors, setStateDesignColors] = useState<
    boolean | undefined
  >(values.isDesignColors);
  const [stateDesignTemplate, setStateDesignTemplate] = useState<
    boolean | undefined
  >(values.isDesignTemplates);

  const onSubmit = (values: SetupStep4aFormValues) => {
    handleSaveAndContinue(values);
  };

  const handleStateDesignLogo = () => {
    values.isDesignLogo = stateDesignLogo ? false : true;
    setStateDesignLogo(stateDesignLogo ? false : true);
  };

  const handleStateDesignTemplate = () => {
    values.isDesignTemplates = stateDesignTemplate ? false : true;
    setStateDesignTemplate(stateDesignTemplate ? false : true);
  };

  const handleStateDesignColors = () => {
    values.isDesignColors = stateDesignColors ? false : true;
    setStateDesignColors(stateDesignColors ? false : true);
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title style={{ width: "100%" }}>
              <h6>{t("setup_wizard_7")}</h6>
              <div className={styles["setup-subheader"]}>
                <div className={styles["setup-subheader-main"]}>
                  {t("setup_wizard_135")}
                </div>
                <div
                  className={styles["setup-subheader-skip"]}
                  onClick={() => {
                    handleSkip(values);
                  }}
                >
                  {t("setup_wizard_4")}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                className={`${styles["setup-purpose__container"]} ${styles["setup-design__container"]}`}
              >
                <div
                  className={`${styles["setup-design-logo"]} ${
                    styles["setup-purpose__card"]
                  } ${
                    stateDesignLogo === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStateDesignLogo();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    {t("setup_wizard_136")}
                  </div>
                </div>
                <div
                  className={`${styles["setup-design-colors"]} ${
                    styles["setup-purpose__card"]
                  } ${
                    stateDesignColors === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStateDesignColors();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    {t("setup_wizard_137")}
                  </div>
                </div>
                <div
                  className={`${styles["setup-design-templates"]} ${
                    styles["setup-purpose__card"]
                  } ${
                    stateDesignTemplate === true
                      ? styles["setup-purpose__card-selected"]
                      : ``
                  }`}
                  onClick={() => {
                    handleStateDesignTemplate();
                  }}
                >
                  <div className={styles["setup-purpose__card-header"]}>
                    {t("setup_wizard_138")}
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Button
              type="button"
              onClick={handleStepBack}
              style={{
                borderRadius: 32,
                color: "#007bff",
                backgroundColor: "white",
                borderColor: "#007bff",
              }}
            >
              {t("login_page_back")}
            </Button>
            <Button
              style={{ borderRadius: 32 }}
              type="submit"
              disabled={
                values.isDesignLogo !== true &&
                values.isDesignColors !== true &&
                values.isDesignTemplates !== true
              }
            >
              {t("setup_page_15")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
