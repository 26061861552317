import { call, put, select, takeLatest, delay } from "@redux-saga/core/effects";

import {
  CreatePageUsingPOSTRequest,
  PageResource,
  FindPageBySlugUsingGETRequest,
  CreatePageVersionUsingPOSTRequest,
  PageVersionResource,
  FindLatestPageVersionUsingGETRequest,
  GetPageTemplateUsingGETRequest,
  TrackFakeDoorUsingPOSTRequest,
  FindTreatmentSlugPageBySlugUsingGETRequest,
  CreateChallengeUsingPOSTRequest,
  RestVariant,
  FindChallengesByPageSlugUsingGETRequest,
  ChallengeResource,
  UpdateChallengeStatusUsingPOSTRequest,
  UpdateChallengeVariantsUsingPOSTRequest,
  GetPagesAndChallengesSlugUsingGETRequest,
  UpdateChallengeControlUsingPOSTRequest,
  FindOtherPagesUsingGETRequest,
  UpdateChallengeAutoApplyUsingPOSTRequest,
} from "@urux/arm-of-dorne-api";
import * as addToolbarActions from "./components/impl/AddElementToolbar/addToolbarActions";
import { challengeApi, pageApi, pageVersionApi, trackerApi } from "../api";
import * as apiActions from "../api/apiActions";
import * as editorActions from "./components/impl/Editor/editorActions";
import { State as ResizeToolbarState } from "./components/impl/ResizeToolbar/resizeToolbarReducer";
import * as resizeToolbarActions from "./components/impl/ResizeToolbar/resizeToolbarActions";
import {
  getResizeToolbar,
  getTitle,
  getDescription,
  getPageSlug,
  getPageIndexState,
  getPageVersion,
  getPrimaryFont,
  getSecondaryFont,
  getPrimaryColor,
  getSecondaryColor,
  getPrimaryRadius,
  getSecondaryRadius,
  getPrimaryShadow,
  getTreatmentPageSlug,
  getTernaryColor,
  getColorType,
  getTreatmentPageSlugs,
} from "./selectors";
import { getUserWebsiteSlug } from "../auth/selectors";
import { ChildPage, childTemplates, TemplateKey } from "./templates/types";
import { Layout } from "common/elements/types";
import {
  getAllChildrenLayout,
  getChallenge,
  getLayout,
  getLoadedLayout,
  getLoadedVersion,
  getPageSiblings,
  getPageSiblingsSlugs,
  getSharedElementsLayout,
  getTreatmentLayout,
} from "./states/selectors";
import {
  EDITOR_CANCEL_ELEMENT_EDITION,
  EDITOR_PAGE_CREATED_FROM_TEMPLATE,
  EDITOR_CREATE_OTHER_PAGES_UPDATE_REUSABLES,
  EDITOR_CREATE_CHILD_PAGES_FROM_TEMPLATE,
  EDITOR_PAGE_LATEST_VERSION_LOADED,
  EDITOR_SAVE_PAGE_REQUEST,
  TRACK_FAKE_DOOR,
  CreateOtherPagesUpdateReusables,
} from "./components/impl/Editor/editorActions";
import {
  Challenge,
  EDITOR_ELEMENT_REMOVED,
  EDITOR_ELEMENT_SHARED,
  EDITOR_ELEMENT_UNSHARED,
  EDITOR_PROPERTY_SET,
  EDITOR_SHARED_ELEMENT_INSERTED,
  EDITOR_TEMPLATE_INSERTED,
  getPagesChallengesSuccessAction,
  UpdateChallengeStatusAction,
  UpdateChallengeVariantsStatusAction,
  TemplateInsertedAction,
  SharedElementInsertedAction,
  ElementSharedAction,
  ElementUnsharedAction,
  ElementRemovedAction,
  PropertySetAction,
  EDITOR_UPDATE_CHALLENGE_STATUS,
  EDITOR_UPDATE_CHALLENGE_AUTO_APPLY,
  UpdateChallengeAutoApplyAction,
  ADMIN_ALL_CHALLENGES,
  GetAllChallengesAction,
  getAllChallengesSuccessAction,
} from "./states/layout";
import { RESIZE_TOOLBAR_SET_MOUSE_POSITION } from "./components/impl/ResizeToolbar/resizeToolbarActions";
import { State } from "store";
import { PageVersion } from "./models/types";

export function* getPageChallenges(
  action: editorActions.GetPageChallengesAction
) {
  yield put(apiActions.startRequest());
  try {
    const response = yield call(
      getPageChallengesAsync,
      action.pageSlug,
      action.websiteSlug
    );
    yield put(
      editorActions.getPageChallengesSuccess(action.pageSlug, response)
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* loadOtherPages(action: editorActions.LoadOtherPagesAction) {
  yield put(apiActions.startRequest());
  try {
    const websiteSlug = yield select(getUserWebsiteSlug);
    const response = yield call(
      loadOtherPagesSync,
      websiteSlug,
      action.pageSlug
    );

    yield put(editorActions.loadOtherPagesSuccess(response));

    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* createChallenge(action: editorActions.SaveChallengeAction) {
  yield put(apiActions.startRequest());
  try {
    const challenge: Challenge = yield select((state: State) =>
      getChallenge(state, action.controlSlug!)
    );
    const websiteSlug = yield select(getUserWebsiteSlug);
    const response = yield call(
      createChallengeSync,
      websiteSlug,
      action.controlSlug,
      action.treatmentSlug,
      challenge
    );

    yield put(
      editorActions.createChallengeSuccess(
        action.controlSlug,
        response,
        action.layout
      )
    );

    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* updateChallengeVariants(
  action: UpdateChallengeVariantsStatusAction
) {
  yield put(apiActions.startRequest());
  try {
    const websiteSlug = yield select(getUserWebsiteSlug);
    const challenge: Challenge = yield select((state: State) =>
      getChallenge(state, action.pageSlug)
    );
    if (!challenge || (!challenge.isTreatmentInserted && challenge.variants)) {
      // Variants didn't change. If Variants is undefined, it was deleted, will continue
      return;
    }

    yield call(
      updateChallengeVariantsSync,
      websiteSlug,
      action.pageSlug,
      challenge
    );
    yield put(
      editorActions.updateChallengerVariantsSuccess(
        action.pageSlug,
        challenge.variants ? challenge.variants[0].elementId : undefined
      )
    );
    yield put(editorActions.getPageChallenges(action.pageSlug, websiteSlug));
    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* getPagesChallenges(action: GetAllChallengesAction) {
  yield put(apiActions.startRequest());
  try {
    const websiteSlug = yield select(getUserWebsiteSlug);
    const response = yield call(getPagesChallengesAsync, websiteSlug);
    yield put(getAllChallengesSuccessAction(response));
    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* updatePageWithChallengeWinner(
  action: editorActions.UpdatePageWithChallengeWinnerAction
) {
  yield put(apiActions.startRequest());
  try {
    const websiteSlug = yield select(getUserWebsiteSlug);
    const response = yield call(
      updateChallengeControlSync,
      action.challengeId,
      websiteSlug,
      action.pageSlug,
      action.elementId
    );
    yield put(getPagesChallengesSuccessAction(action.pageSlug, response));
    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

// export function* updateChallengeControl(action: UpdateChallengeControlAction) {
//   yield put(apiActions.startRequest());
//   try {
//     const websiteSlug = yield select(getUserWebsiteSlug);
//     yield call(
//       updateChallengeControlSync,
//       websiteSlug,
//       action.pageSlug,
//       action.elementId
//     );
//     yield call(action.callback);
//     yield put(apiActions.finishRequest());
//   } catch (e) {
//     const json = yield call(getResponseJsonAsync, e);
//     yield put(apiActions.finishRequestWithErrors(json.message));
//   }
// }

export function* updateChallengeStatus(action: UpdateChallengeStatusAction) {
  yield put(apiActions.startRequest());
  try {
    const websiteSlug = yield select(getUserWebsiteSlug);
    const pageSlug = yield select(getPageSlug);
    yield call(
      updateChallengeStatusSync,
      action.challengeId,
      action.elementId,
      action.isActive,
      websiteSlug,
      pageSlug
    );
    yield call(action.callback);
    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* updateChallengeAutoApply(
  action: UpdateChallengeAutoApplyAction
) {
  yield put(apiActions.startRequest());
  try {
    const websiteSlug = yield select(getUserWebsiteSlug);
    const pageSlug = yield select(getPageSlug);
    yield call(
      updateChallengeAutoApplySync,
      action.challengeId,
      action.elementId,
      action.isAutoApply,
      websiteSlug,
      pageSlug
    );
    yield call(action.callback);
    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* trackFakeDoor(action: editorActions.TrackFakeDoorAction) {
  yield put(apiActions.startRequest());
  try {
    yield call(
      trackFakeDoorAsync,
      action.websiteSlug,
      action.pageSlug,
      action.doorName
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* createOtherPages(
  action: editorActions.CreateChildPagesFromTemplateAction
) {
  const layouts = yield select(getAllChildrenLayout);
  const childrenTemplates = childTemplates[action.templateKey];
  for (const childTemplate of childrenTemplates) {
    const layout: Layout = layouts[childTemplate.templateName];
    yield call(createChildPage, layout, childTemplate, action);
  }
  yield call(action.callback, action.websiteSlug);
}

export function* createChildPage(
  layout: Layout,
  childPageDetail: ChildPage,
  action: editorActions.CreateChildPagesFromTemplateAction
) {
  const content = JSON.stringify(layout);
  yield put(apiActions.startRequest());
  try {
    yield call(
      createPageAsync,
      action.websiteSlug,
      action.templateKey,
      childPageDetail.details.title,
      childPageDetail.details.description,
      action.isIndex,
      content,
      action.primaryFont,
      action.secondaryFont,
      action.primaryColor,
      action.secondaryColor,
      action.ternaryColor,
      action.colorType,
      action.primaryRadius,
      action.secondaryRadius,
      action.primaryShadow,
      true,
      childPageDetail.details.slug
    );

    // yield put(
    //   editorActions.treatmentPageCreatedFromTemplate(
    //     action.websiteSlug,
    //     childPageDetail.details.title,
    //     childPageDetail.details.description,
    //     action.templateKey,
    //     action.isIndex,
    //     action.primaryFont,
    //     action.secondaryFont,
    //     action.primaryColor,
    //     action.secondaryColor,
    //     action.ternaryColor,
    //     action.colorType,
    //     action.primaryRadius,
    //     action.secondaryRadius,
    //     action.primaryShadow,
    //     childPageDetail.details.slug,
    //     layout
    //   )
    // );

    // yield put(
    //   editorActions.getPageChallenges(response.slug, action.websiteSlug)
    // );

    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(editorActions.pageCreationFromTemplateFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* createPageDetails(
  action: editorActions.PageCreateDetailsAction
) {
  yield put(apiActions.startRequest());
}

export function* createPage(
  action: editorActions.PageCreatedFromTemplateAction
) {
  const layout: Layout = yield select(getLayout);
  yield put(apiActions.startRequest());

  try {
    const content = JSON.stringify(layout);
    const response = yield call(
      createPageAsync,
      action.websiteSlug,
      action.templateKey,
      action.title,
      action.description,
      action.isIndex,
      content,
      action.primaryFont,
      action.secondaryFont,
      action.primaryColor,
      action.secondaryColor,
      action.ternaryColor,
      action.colorType,
      action.primaryRadius,
      action.secondaryRadius,
      action.primaryShadow,
      true,
      action.pageSlug,
      undefined
    );
    yield put(
      editorActions.pageCreationFromTemplateSucceeded(
        response.pageId,
        response.slug,
        response.pageUniqueId,
        response.version,
        layout
      )
    );

    yield put(
      editorActions.pageSaveSucceeded(
        response.slug,
        action.websiteSlug,
        layout,
        true,
        action.title!,
        action.description!,
        true
      )
    );

    // yield put(
    //   editorActions.getPageChallenges(response.slug, action.websiteSlug)
    // );

    if (action.isMultiPage) {
      yield put(
        editorActions.createOtherPagesUpdateReusables(
          response.pageId,
          response.slug,
          response.version,
          layout
        )
      );
    }

    yield put(apiActions.finishRequest());
    yield call(action.callback, response.slug, layout);
  } catch (e) {
    yield put(editorActions.pageCreationFromTemplateFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* findTreatmentPageSlug(
  action: editorActions.GetTreatmentPageSlugAction
) {
  yield put(apiActions.startRequest());
  const websiteSlug = yield select(getUserWebsiteSlug);
  try {
    const treatmentSlug: string = yield call(
      getTreatmentPageSlugAsync,
      websiteSlug,
      action.pageSlug
    );
    yield put(editorActions.findTreatmentSlugSuccess(treatmentSlug));
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(editorActions.pageLatestVersionLoadFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* loadPageLatestVersion(
  action: editorActions.PageLatestVersionLoadedAction
) {
  yield put(apiActions.startRequest());
  try {
    const page: PageResource = yield call(
      loadPageAsync,
      action.websiteSlug,
      action.pageSlug
    );

    const latestVersion: PageVersionResource = yield call(
      loadPageLatestVersionAsync,
      action.websiteSlug,
      action.pageSlug
    );

    const content = JSON.parse(latestVersion.content!);

    yield put(
      editorActions.pageLatestVersionLoadSucceeded(
        page.pageId!,
        page.slug!,
        page.template! as TemplateKey,
        latestVersion.title!,
        latestVersion.description!,
        latestVersion.isIndex!,
        content,
        latestVersion.version!,
        latestVersion.primaryFont!,
        latestVersion.secondaryFont!,
        latestVersion.primaryColor!,
        latestVersion.secondaryColor!,
        latestVersion.ternaryColor!,
        latestVersion.colorType!,
        latestVersion.primaryRadius!,
        latestVersion.secondaryRadius!,
        latestVersion.primaryShadow!,
        page.treatmentSlug!,
        latestVersion.pageUniqueId!
      )
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(editorActions.pageLatestVersionLoadFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* saveTreatmentPage(
  action: editorActions.PageSaveSucceededAction
) {
  let treatmentSlug: string | null = yield select((state: State) =>
    getTreatmentPageSlugs(state, action.pageSlug)
  );
  if (!treatmentSlug) {
    treatmentSlug = yield select((state: State) => getTreatmentPageSlug(state));
  }
  if (!treatmentSlug) return;
  const title = action.title;
  const description = action.description;
  let layout: Layout;
  if (action.isFromTemplate) {
    layout = action.layout;
  } else {
    layout = yield select((state: State) => getTreatmentLayout(state));
  }

  yield put(apiActions.startRequest());

  const primaryFont: string = yield select(getPrimaryFont);
  const secondaryFont: string = yield select(getSecondaryFont);
  const primaryColor: string = yield select(getPrimaryColor);
  const secondaryColor: string = yield select(getSecondaryColor);
  const ternaryColor: string = yield select(getTernaryColor);
  const colorType: string = yield select(getColorType);
  const primaryRadius: string = yield select(getPrimaryRadius);
  const secondaryRadius: string = yield select(getSecondaryRadius);
  const primaryShadow: string = yield select(getPrimaryShadow);

  try {
    const content = JSON.stringify(layout);
    yield call(
      savePageAsync,
      action.websiteSlug,
      treatmentSlug,
      title!,
      description!,
      false,
      content,
      primaryFont,
      secondaryFont,
      primaryColor,
      secondaryColor,
      ternaryColor,
      colorType,
      primaryRadius,
      secondaryRadius,
      primaryShadow,
      false,
      action.pageSlug,
      undefined,
      undefined
    );
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(editorActions.savePageFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

export function* saveSiblingPages(action: editorActions.SaveOtherPagesAction) {
  const otherPagesToSave: { [slug: string]: PageVersion } = yield select(
    getPageSiblings
  );
  const websiteSlug: string = yield select(getUserWebsiteSlug);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of Object.entries(otherPagesToSave)) {
    try {
      yield call(
        savePageAsync,
        websiteSlug,
        otherPagesToSave[key].slug,
        otherPagesToSave[key].title,
        otherPagesToSave[key].description!,
        otherPagesToSave[key].isIndex,
        otherPagesToSave[key].content,
        action.primaryFont,
        action.secondaryFont,
        action.primaryColor,
        action.secondaryColor,
        action.ternaryColor,
        action.colorType,
        action.primaryRadius,
        action.secondaryRadius,
        action.primaryShadow,
        otherPagesToSave[key].isMaster,
        undefined,
        undefined
      );
      yield put(apiActions.finishRequest());
    } catch (e) {
      yield put(editorActions.savePageFailure());
      console.error(e);
    }
  }
}

export function* savePage(action: editorActions.SavePageRequestAction) {
  const title: string | null = yield select(getTitle);
  let description: string | null = yield select(getDescription);
  description = description === null ? "" : description;
  const isIndex: boolean | null = yield select(getPageIndexState);

  const layout: Layout = yield select(getLayout);
  const sharedLayout: Layout = yield select(getSharedElementsLayout);

  yield put(apiActions.startRequest());

  const primaryFont: string = yield select(getPrimaryFont);
  const secondaryFont: string = yield select(getSecondaryFont);
  const primaryColor: string = yield select(getPrimaryColor);
  const secondaryColor: string = yield select(getSecondaryColor);
  const ternaryColor: string = yield select(getTernaryColor);
  const colorType: string = yield select(getColorType);
  const primaryRadius: string = yield select(getPrimaryRadius);
  const secondaryRadius: string = yield select(getSecondaryRadius);
  const primaryShadow: string = yield select(getPrimaryShadow);

  try {
    // Check first if loaded version of layout is the same as to be saved version
    const loadedVersion: number | null = yield select(getLoadedVersion);
    const savedVersion: number = yield select(getPageVersion);

    let changedElementStyle;
    if (
      action.changedElementId &&
      document.getElementById(action.changedElementId)
    ) {
      const changedElement = document.getElementById(action.changedElementId);
      if (changedElement) {
        changedElementStyle = changedElement.className;
      }
    }

    if (
      loadedVersion !== 0 &&
      loadedVersion !== null &&
      loadedVersion !== savedVersion
    ) {
      const loadedContent = JSON.stringify(yield select(getLoadedLayout));
      const response = yield call(
        savePageAsync,
        action.websiteSlug,
        action.pageSlug,
        title!,
        description!,
        isIndex!,
        loadedContent,
        primaryFont,
        secondaryFont,
        primaryColor,
        secondaryColor,
        ternaryColor,
        colorType,
        primaryRadius,
        secondaryRadius,
        primaryShadow,
        true,
        undefined,
        action.changedElementId,
        changedElementStyle,
        action.isDelete
      );
      yield put(editorActions.savePageSuccess(response.version));
    }
    const content = JSON.stringify(layout);
    const response = yield call(
      savePageAsync,
      action.websiteSlug,
      action.pageSlug,
      title!,
      description!,
      isIndex!,
      content,
      primaryFont,
      secondaryFont,
      primaryColor,
      secondaryColor,
      ternaryColor,
      colorType,
      primaryRadius,
      secondaryRadius,
      primaryShadow,
      true,
      undefined,
      action.changedElementId,
      changedElementStyle,
      action.isDelete
    );

    yield put(editorActions.savePageSuccess(response.version));
    yield put(
      editorActions.pageSaveSucceeded(
        action.pageSlug,
        action.websiteSlug,
        layout,
        true,
        title!,
        description!,
        false
      )
    );

    let siblingPages: string[] | null = yield select(getPageSiblingsSlugs);
    if (siblingPages && siblingPages.length > 0) {
      yield put(
        editorActions.saveSiblingPages(
          sharedLayout,
          primaryFont,
          secondaryFont,
          primaryColor,
          secondaryColor,
          ternaryColor,
          colorType,
          primaryRadius,
          secondaryRadius,
          primaryShadow
        )
      );
    }

    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(editorActions.savePageFailure());
    console.error(e);
  }
}

export function* autoSavePage(
  action:
    | CreateOtherPagesUpdateReusables
    | TemplateInsertedAction
    | SharedElementInsertedAction
    | ElementSharedAction
    | ElementUnsharedAction
    | ElementRemovedAction
    | PropertySetAction
) {
  const autoSaveWhenInactiveForMilliseconds = 1000;
  yield delay(autoSaveWhenInactiveForMilliseconds);
  const websiteSlug = yield select(getUserWebsiteSlug);
  const pageSlug = yield select(getPageSlug);
  if (
    action.type === "EDITOR_PROPERTY_SET" ||
    action.type === "EDITOR_ELEMENT_REMOVED"
  ) {
    if (action.type === "EDITOR_ELEMENT_REMOVED") {
      yield put(
        editorActions.savePage(websiteSlug, pageSlug, action.elementId, true)
      );
    } else {
      yield put(
        editorActions.savePage(websiteSlug, pageSlug, action.elementId, false)
      );
    }
  } else {
    yield put(editorActions.savePage(websiteSlug, pageSlug, null, false));
  }
}

export function* resizeOnResizeToolbarDrag() {
  const state: ResizeToolbarState = yield select(getResizeToolbar);
  if (state.state !== "resizing") {
    return;
  }

  const dx = state.x - state.x0;
  const threshold = 90;
  if (dx > threshold) {
    yield put(editorActions.resize(state.elementId, state.position, 1));
    yield put(resizeToolbarActions.start(state.position, state.x, state.y));
  } else if (dx < -threshold) {
    yield put(editorActions.resize(state.elementId, state.position, -1));
    yield put(resizeToolbarActions.start(state.position, state.x, state.y));
  }
}

export function* updateControlsOnSaveOrCancelElementEdition() {
  yield put(addToolbarActions.enable());
  yield put(resizeToolbarActions.hide());
}

export function* updateControlsOnInsertElement() {
  yield put(addToolbarActions.hide());
}

export function* getPageTemplate(
  action: editorActions.GetPageTemplateRequestAction
) {
  yield put(apiActions.startRequest());
  try {
    const response = yield call(getPageTemplateAsync, action.websiteSlug);
    if (response) {
      yield put(
        editorActions.getPageTemplateSuccess(
          response.templateKey,
          response.primaryFont,
          response.secondaryFont,
          response.primaryColor,
          response.secondaryColor,
          response.ternaryColor,
          response.colorType,
          response.primaryRadius,
          response.secondaryRadius,
          response.primaryShadow
        )
      );
    } else {
      yield put(editorActions.getPageTemplateFailure());
    }
    yield put(apiActions.finishRequest());
  } catch (e) {
    yield put(editorActions.getPageTemplateFailure());
    const json = yield call(getResponseJsonAsync, e);
    yield put(apiActions.finishRequestWithErrors(json.message));
  }
}

const getPageTemplateAsync = async (websiteSlug: string): Promise<object> => {
  const request: GetPageTemplateUsingGETRequest = {
    websiteSlug,
  };
  return await pageApi.getPageTemplateUsingGET(request);
};

const createPageAsync = async (
  websiteSlug: string,
  template: string,
  title: string,
  description: string,
  isIndex: boolean,
  content: string,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  isMaster: boolean,
  pageSlug: string,
  masterSlug?: string
): Promise<PageResource> => {
  const request: CreatePageUsingPOSTRequest = {
    websiteSlug,
    request: {
      template,
      title,
      content,
      description,
      isIndex,
      primaryFont,
      secondaryFont,
      primaryColor,
      secondaryColor,
      ternaryColor,
      colorType,
      primaryRadius,
      secondaryRadius,
      primaryShadow,
      isMaster,
      pageSlug,
      masterSlug,
    },
  };
  return await pageApi.createPageUsingPOST(request);
};

const loadPageAsync = async (
  websiteSlug: string,
  pageSlug: string
): Promise<PageResource> => {
  const request: FindPageBySlugUsingGETRequest = {
    websiteSlug,
    pageSlug,
  };
  return await pageApi.findPageBySlugUsingGET(request);
};

const getTreatmentPageSlugAsync = async (
  websiteSlug: string,
  pageSlug: string
): Promise<string> => {
  const request: FindTreatmentSlugPageBySlugUsingGETRequest = {
    websiteSlug,
    pageSlug,
  };
  return await pageApi.findTreatmentSlugPageBySlugUsingGET(request);
};

const loadPageLatestVersionAsync = async (
  websiteSlug: string,
  pageSlug: string
): Promise<PageVersionResource> => {
  const request: FindLatestPageVersionUsingGETRequest = {
    websiteSlug,
    pageSlug,
  };

  return await pageVersionApi.findLatestPageVersionUsingGET(request);
};

const savePageAsync = async (
  websiteSlug: string,
  pageSlug: string,
  title: string,
  description: string,
  isIndex: boolean,
  content: string,
  primaryFont: string,
  secondaryFont: string,
  primaryColor: string,
  secondaryColor: string,
  ternaryColor: string,
  colorType: string,
  primaryRadius: string,
  secondaryRadius: string,
  primaryShadow: string,
  isMaster: boolean,
  masterSlug?: string,
  elementId?: string | null,
  elementStyle?: string | null,
  isDelete?: boolean
): Promise<PageVersionResource> => {
  const changedElementId = elementId === null ? undefined : elementId;
  const changedElementStyle = elementStyle === null ? undefined : elementStyle;
  const request: CreatePageVersionUsingPOSTRequest = {
    websiteSlug,
    pageSlug,
    request: {
      title,
      content,
      description,
      isIndex,
      primaryFont,
      secondaryFont,
      primaryColor,
      secondaryColor,
      ternaryColor,
      colorType,
      primaryRadius,
      secondaryRadius,
      primaryShadow,
      isMaster,
      masterSlug,
      changedElementId,
      changedElementStyle,
      isDelete,
    },
  };
  return await pageVersionApi.createPageVersionUsingPOST(request);
};

const getResponseJsonAsync = async (response: Response): Promise<any> => {
  return await response.json();
};

const getPageChallengesAsync = async (
  pageSlug: string,
  websiteSlug: string
): Promise<Array<ChallengeResource>> => {
  const request: FindChallengesByPageSlugUsingGETRequest = {
    pageSlug,
    websiteSlug,
  };
  return await challengeApi.findChallengesByPageSlugUsingGET(request);
};

const updateChallengeStatusSync = async (
  challengeId: number,
  elementId: string,
  isActive: boolean,
  websiteSlug: string,
  pageSlug: string
): Promise<any> => {
  if (!websiteSlug) return;
  const request: UpdateChallengeStatusUsingPOSTRequest = {
    challengeId,
    elementId,
    pageSlug,
    websiteSlug,
    isActive,
  };
  return await challengeApi.updateChallengeStatusUsingPOST(request);
};

const updateChallengeAutoApplySync = async (
  challengeId: number,
  elementId: string,
  isAutoApply: boolean,
  websiteSlug: string,
  pageSlug: string
): Promise<any> => {
  if (!websiteSlug) return;
  const request: UpdateChallengeAutoApplyUsingPOSTRequest = {
    challengeId,
    elementId,
    pageSlug,
    websiteSlug,
    isAutoApply,
  };
  return await challengeApi.updateChallengeAutoApplyUsingPOST(request);
};

const updateChallengeControlSync = async (
  challengeId: number,
  websiteSlug: string,
  pageSlug: string,
  elementId: string
): Promise<any> => {
  const request: UpdateChallengeControlUsingPOSTRequest = {
    challengeId,
    elementId,
    pageSlug,
    websiteSlug,
  };
  return await challengeApi.updateChallengeControlUsingPOST(request);
};

const loadOtherPagesSync = async (
  websiteSlug: string,
  pageSlug: string
): Promise<PageVersionResource[]> => {
  const request: FindOtherPagesUsingGETRequest = {
    websiteSlug,
    pageSlug,
  };
  return await pageVersionApi.findOtherPagesUsingGET(request);
};

const createChallengeSync = async (
  websiteSlug?: string,
  controlSlug?: string,
  treatmentSlug?: string,
  challenge?: Challenge
): Promise<ChallengeResource | null> => {
  if (!websiteSlug || !controlSlug) return null;
  let restVariants: RestVariant[] = [];
  if (challenge && challenge.variants && challenge.variants?.length > 0) {
    challenge.variants.forEach((variant) => {
      restVariants.push({
        elementId: variant.elementId,
        elementName: variant.elementName,
        isChampion: variant.isChampion,
        isControl: variant.isControl,
        isCurrent: variant.isCurrent,
        isTested: variant.isTested,
        property: variant.property,
        propertyDesc: variant.propertyDesc,
      });
    });
  }
  const elementId =
    challenge && challenge.elementId ? challenge.elementId : undefined;
  const request: CreateChallengeUsingPOSTRequest = {
    websiteSlug: websiteSlug,
    request: {
      controlSlug,
      elementId,
      treatmentSlug,
      restVariants,
    },
  };
  return await challengeApi.createChallengeUsingPOST(request);
};

const getPagesChallengesAsync = async (websiteSlug?: string): Promise<any> => {
  if (!websiteSlug) return;
  const request: GetPagesAndChallengesSlugUsingGETRequest = {
    websiteSlug: websiteSlug,
  };
  return await challengeApi.getPagesAndChallengesSlugUsingGET(request);
};

const updateChallengeVariantsSync = async (
  websiteSlug?: string,
  controlSlug?: string,
  challenge?: Challenge
): Promise<any> => {
  if (!websiteSlug || !controlSlug) return;
  let restVariants: RestVariant[] = [];
  if (challenge && challenge.variants && challenge.variants?.length > 0) {
    challenge.variants.forEach((variant) => {
      restVariants.push({
        elementId: variant.elementId,
        elementName: variant.elementName,
        isChampion: variant.isChampion,
        isControl: variant.isControl,
        isCurrent: variant.isCurrent,
        isTested: variant.isTested,
        property: variant.property,
        propertyDesc: variant.propertyDesc,
      });
    });
  }

  const request: UpdateChallengeVariantsUsingPOSTRequest = {
    websiteSlug: websiteSlug,
    request: {
      controlSlug,
      websiteSlug,
      restVariants,
    },
  };
  return await challengeApi.updateChallengeVariantsUsingPOST(request);
};

const trackFakeDoorAsync = async (
  websiteSlug: string,
  pageSlug: string | undefined,
  doorName: string
): Promise<any> => {
  const request: TrackFakeDoorUsingPOSTRequest = {
    request: {
      websiteSlug,
      pageSlug,
      doorName,
    },
  };
  return await trackerApi.trackFakeDoorUsingPOST(request);
};

// export function* generateUrl(
//   action: editorActions.GenerateUrlAction
// ) {
//   yield put(apiActions.startRequest());
//   try {
//     yield put(apiActions.finishRequest());
//   } catch (e) {
//     yield put(editorActions.getPageTemplateFailure());
//     const json = yield call(getResponseJsonAsync, e);
//     yield put(apiActions.finishRequestWithErrors(json.message));
//   }
// }

export function* rootSaga() {
  // yield takeLatest(editorActions.SETUP_GENERATE_URL, generateUrl);
  yield takeLatest(EDITOR_PAGE_CREATED_FROM_TEMPLATE, createPage);
  yield takeLatest(EDITOR_CREATE_CHILD_PAGES_FROM_TEMPLATE, createOtherPages);
  yield takeLatest(EDITOR_PAGE_LATEST_VERSION_LOADED, loadPageLatestVersion);
  yield takeLatest(EDITOR_SAVE_PAGE_REQUEST, savePage);
  yield takeLatest(EDITOR_CREATE_OTHER_PAGES_UPDATE_REUSABLES, autoSavePage);
  yield takeLatest(EDITOR_TEMPLATE_INSERTED, autoSavePage);
  yield takeLatest(EDITOR_SHARED_ELEMENT_INSERTED, autoSavePage);
  yield takeLatest(EDITOR_ELEMENT_SHARED, autoSavePage);
  yield takeLatest(EDITOR_ELEMENT_UNSHARED, autoSavePage);
  yield takeLatest(EDITOR_ELEMENT_REMOVED, autoSavePage);
  yield takeLatest(EDITOR_PROPERTY_SET, autoSavePage);
  yield takeLatest(
    RESIZE_TOOLBAR_SET_MOUSE_POSITION,
    resizeOnResizeToolbarDrag
  );
  yield takeLatest(
    EDITOR_CANCEL_ELEMENT_EDITION,
    updateControlsOnSaveOrCancelElementEdition
  );
  yield takeLatest(EDITOR_TEMPLATE_INSERTED, updateControlsOnInsertElement);
  yield takeLatest(
    EDITOR_SHARED_ELEMENT_INSERTED,
    updateControlsOnInsertElement
  );
  yield takeLatest(
    editorActions.ADMIN_GET_PAGE_TEMPLATE_REQUEST,
    getPageTemplate
  );
  yield takeLatest(TRACK_FAKE_DOOR, trackFakeDoor);
  yield takeLatest(
    editorActions.EDITOR_SAVE_CHALLENGE_VARIANTS,
    updateChallengeVariants
  );
  yield takeLatest(editorActions.EDITOR_SAVE_CHALLENGE, createChallenge);
  yield takeLatest(
    editorActions.EDITOR_GET_TREATMENT_PAGE_SLUG,
    findTreatmentPageSlug
  );
  yield takeLatest(editorActions.EDITOR_LOAD_OTHER_PAGES, loadOtherPages);
  yield takeLatest(editorActions.EDITOR_SAVE_OTHER_PAGES, saveSiblingPages);
  yield takeLatest(editorActions.EDITOR_GET_PAGE_CHALLENGES, getPageChallenges);
  yield takeLatest(EDITOR_UPDATE_CHALLENGE_STATUS, updateChallengeStatus);
  yield takeLatest(
    EDITOR_UPDATE_CHALLENGE_AUTO_APPLY,
    updateChallengeAutoApply
  );
  yield takeLatest(
    editorActions.EDITOR_UPDATE_PAGE_WITH_CHALLENGE_WINNER,
    updatePageWithChallengeWinner
  );
  yield takeLatest(ADMIN_ALL_CHALLENGES, getPagesChallenges);
  yield takeLatest(editorActions.EDITOR_PAGE_CREATE_DETAILS, createPageDetails);
}
