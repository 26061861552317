import { Rect, Scroll } from "../../../types";

export type EnableAction = {
  type: "ADD_TOOLBAR_GUIDES_ENABLE";
};

export const enable = (): Action => {
  return {
    type: "ADD_TOOLBAR_GUIDES_ENABLE",
  };
};

export type DisableAction = {
  type: "ADD_TOOLBAR_GUIDES_DISABLE";
};

export const disable = (): Action => {
  return {
    type: "ADD_TOOLBAR_GUIDES_DISABLE",
  };
};

export type HideAction = {
  type: "ADD_TOOLBAR_GUIDES_HIDE";
};

export const hide = (): Action => {
  return {
    type: "ADD_TOOLBAR_GUIDES_HIDE",
  };
};

export type SetHoveredElementWithMousePositionAction = {
  type: "ADD_TOOLBAR_GUIDES_SET_HOVERED_ELEMENT_WITH_MOUSE_POSITION";
  elementId: string;
  elementKey: string;
  elementParentId: string;
  elementParentKey: string;
  index: number;
  elementRect: Rect;
  scroll: Scroll;
  x: number;
  y: number;
  stopEventPropagation: () => void;
};

export const setHoveredElementWithMousePosition = (
  elementId: string,
  elementKey: string,
  elementParentId: string,
  elementParentKey: string,
  index: number,
  elementRect: Rect,
  scroll: Scroll,
  x: number,
  y: number,
  stopEventPropagation: () => void
): Action => {
  return {
    type: "ADD_TOOLBAR_GUIDES_SET_HOVERED_ELEMENT_WITH_MOUSE_POSITION",
    elementId,
    elementKey,
    elementParentId,
    elementParentKey,
    index,
    elementRect,
    scroll,
    x,
    y,
    stopEventPropagation,
  };
};

export type SetHoveredComponentWithMousePositionAction = {
  type: "ADD_TOOLBAR_GUIDES_SET_HOVERED_COMPONENT_WITH_MOUSE_POSITION";
  elementParentId: string;
  elementParentKey: string;
  index: number;
  elementRect: Rect;
  scroll: Scroll;
  x: number;
  y: number;
  stopEventPropagation: () => void;
};

export const setHoveredComponentWithMousePosition = (
  elementParentId: string,
  elementParentKey: string,
  index: number,
  elementRect: Rect,
  scroll: Scroll,
  x: number,
  y: number,
  stopEventPropagation: () => void
): Action => {
  return {
    type: "ADD_TOOLBAR_GUIDES_SET_HOVERED_COMPONENT_WITH_MOUSE_POSITION",
    elementParentId,
    elementParentKey,
    index,
    elementRect,
    scroll,
    x,
    y,
    stopEventPropagation,
  };
};

export type Action =
  | { type: "_" }
  | EnableAction
  | DisableAction
  | HideAction
  | SetHoveredElementWithMousePositionAction
  | SetHoveredComponentWithMousePositionAction;
