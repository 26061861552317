import { PositionString } from "../../../types";

export const RESIZE_TOOLBAR_SHOW = "RESIZE_TOOLBAR_SHOW";

export type ShowAction = {
  type: typeof RESIZE_TOOLBAR_SHOW;
  elementId: string;
};

export const show = (elementId: string): Action => {
  return {
    type: RESIZE_TOOLBAR_SHOW,
    elementId,
  };
};

export const RESIZE_TOOLBAR_HIDE = "RESIZE_TOOLBAR_HIDE";

export type HideAction = { type: typeof RESIZE_TOOLBAR_HIDE };

export const hide = (): Action => {
  return {
    type: RESIZE_TOOLBAR_HIDE,
  };
};

export const RESIZE_TOOLBAR_START = "RESIZE_TOOLBAR_START";

export type StartAction = {
  type: typeof RESIZE_TOOLBAR_START;
  position: PositionString;
  x: number;
  y: number;
};

export const start = (
  position: PositionString,
  x: number,
  y: number
): Action => {
  return {
    type: RESIZE_TOOLBAR_START,
    position,
    x,
    y,
  };
};

export const RESIZE_TOOLBAR_START_RESIZE = "RESIZE_TOOLBAR_START_RESIZE";

export type StartResizeAction = {
  type: typeof RESIZE_TOOLBAR_START_RESIZE;
};

export const startResize = (): Action => {
  return {
    type: RESIZE_TOOLBAR_START_RESIZE,
  };
};

export const RESIZE_TOOLBAR_END = "RESIZE_TOOLBAR_END";

export type EndAction = { type: typeof RESIZE_TOOLBAR_END };

export const end = (): Action => {
  return {
    type: RESIZE_TOOLBAR_END,
  };
};

export const RESIZE_TOOLBAR_SET_MOUSE_POSITION =
  "RESIZE_TOOLBAR_SET_MOUSE_POSITION";

export type SetMousePositionAction = {
  type: typeof RESIZE_TOOLBAR_SET_MOUSE_POSITION;
  x: number;
  y: number;
};

export const setMousePosition = (x: number, y: number): Action => {
  return {
    type: RESIZE_TOOLBAR_SET_MOUSE_POSITION,
    x,
    y,
  };
};

export type Action =
  | { type: "_" }
  | ShowAction
  | HideAction
  | StartAction
  | EndAction
  | SetMousePositionAction
  | StartResizeAction;
