import React, { useState } from "react";

import {
  ChevronRightRounded,
  ExpandMoreRounded,
  HomeRounded,
} from "@material-ui/icons";
import { Button as Toggle, Accordion, Card } from "react-bootstrap";
import { Button } from "editor/components/base/Button/Button";
import { PageWithLatestDetails } from "editor/models/types";
import { getUsername, getUserWebsiteSlug } from "../../../../auth/selectors";
import styles from "./PageListPropertiesDialog.module.scss";
import PagePropertiesForm from "./PagePropertiesForm";
import { useSelector, useDispatch } from "react-redux";
import { pageDetailsUpdateReset } from "editor/states/pageList";
import { logActivity } from "auth/authActions";
import { editorPage } from "../Editor/Editor";
import { useTranslation } from "react-i18next";

type Props = {
  pageList: PageWithLatestDetails[];
  pageContainer: string;
  handleShowPagesPropDialog?: () => void;
};

export default function PageListPropertiesDialog({
  pageList,
  pageContainer,
  handleShowPagesPropDialog,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const username = useSelector(getUsername);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleCardExpansion = (): void => {
    isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    dispatch(pageDetailsUpdateReset());
    dispatch(logActivity("DISPLAY PAGE DETAIL", pageContainer, username));
  };

  const handleOpenPage = (page: string) => {
    if (handleShowPagesPropDialog) {
      dispatch(
        logActivity("GO TO", pageContainer, username, editorPage + " / " + page)
      );
      handleShowPagesPropDialog();
    }
  };

  if (!websiteSlug) {
    return <></>;
  }

  let indexPage: PageWithLatestDetails;
  pageList.some(function (page) {
    if (page.isIndex) {
      indexPage = page;
      return true;
    }
    return false;
  });

  if (!pageList || pageList.length === 0) {
    return (
      <></>
      // <div className={styles["pages-list-empty"]}>
      //   No pages available. <br />
      //   <Link to="/admin/pages/new">Add new Page</Link>
      // </div>
    );
  }

  return (
    <>
      <div className={styles["pages-list"]}>
        <Accordion>
          {(pageList || []).map((page) =>
            page.isMaster ? (
              <Card key={page.slug} className={styles["card"]}>
                <Card.Header className={styles["card-header"]}>
                  <Accordion.Toggle
                    as={Toggle}
                    variant="link"
                    eventKey={page.title}
                    onClick={handleCardExpansion}
                    className={styles["toggle-button"]}
                  >
                    <div className={styles["label-cont"]}>
                      <HomeRounded
                        style={{
                          opacity: `${page.isIndex ? `100` : `0`}`,
                        }}
                      />
                      <label className={styles["toggle-label"]}>
                        {" "}
                        {page.title}
                      </label>
                    </div>
                    <div className={styles["actions-container"]}>
                      <Button
                        to={`#`}
                        label={t("details")}
                        variant="none-outline"
                        size="small"
                        isFlat={true}
                        icon={ExpandMoreRounded}
                        onClick={() => {
                          dispatch(
                            logActivity(
                              "DISPLAY PAGE DETAIL",
                              pageContainer,
                              username
                            )
                          );
                        }}
                      />
                      <Button
                        to={`/admin/pages/${page.slug}/edit`}
                        label={t("Open")}
                        variant="none-outline"
                        size="small"
                        isFlat={true}
                        onClick={() => {
                          handleOpenPage(page.slug);
                        }}
                        icon={ChevronRightRounded}
                      />
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={page.title}>
                  <Card.Body>
                    <PagePropertiesForm
                      page={page}
                      indexPage={indexPage}
                      websiteSlug={websiteSlug}
                      pageContainer={pageContainer}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ) : (
              <></>
            )
          )}
        </Accordion>
      </div>
    </>
  );
}
