import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IProperty, PropertyComponent } from "../types";
import { propertySet } from "editor/states/layout";
import TextFieldGroup from "../../components/components/TextFieldGroup/TextFieldGroup";

import styles from "./LogoTextProperty.module.scss";
import { getWebsite } from "editor/states/selectors";
import { useTranslation } from "react-i18next";

export class LogoTextProperty implements IProperty {
  constructor(readonly label: string) {}

  render(elementId: string, propertyKey: string) {
    return (
      <LogoTextPropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
        label={this.label}
      />
    );
  }

  static parse(value: any) {
    return new LogoTextProperty(value.label);
  }
}

const LogoTextPropertyComponent: PropertyComponent<LogoTextProperty> = ({
  elementId,
  propertyKey,
  label,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const website = useSelector(getWebsite);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      propertySet(elementId, propertyKey, new LogoTextProperty(e.target.value))
    );
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value ? e.target.value : website.title;
    dispatch(propertySet(elementId, propertyKey, new LogoTextProperty(val)));
  };

  return (
    <div className={styles["container"]} id="logoText-dialog-container">
      <div className={styles["textField-container"]}>
        <TextFieldGroup
          name="label"
          label={t("create_logo_1")}
          value={label === "logo" ? website.title : label}
          onChange={handleChange}
          size={"m"}
          id={"logo-text"}
          placeholder={t("create_logo_2")}
          onFocusOut={handleBlur}
        />
      </div>
    </div>
  );
};
