import React from "react";

type Props = {
  text: string;
  url: string;
  boldness?: number;
  spacing?: number;
  size?: number;
  style?: string;
  openNewTab?: boolean;
  isLastItem?: boolean;
  isProduction: boolean;
  linkColor?: string;
};

export function NavItem({
  text,
  url,
  boldness,
  spacing,
  size,
  openNewTab,
  style,
  isLastItem,
  isProduction,
  linkColor,
}: Props): JSX.Element {
  let linkUrl = "#" + url; // For Editor
  if (isProduction) {
    linkUrl = url;
    if (!linkUrl.startsWith("#") && !linkUrl.startsWith("/")) {
      linkUrl = "http://" + url;
    }
  } else {
    linkUrl = "#" + url;
  }

  return (
    <a
      className={`nav__item fp cg-df-dg cp--hov ${
        isLastItem && style === "2"
          ? `fp cp--bgc cp-d10--bgc--hov sp rs c-rcp cp-d10--bgc-rt-bw--hov`
          : ``
      }`}
      href={linkUrl}
      target={openNewTab ? "_blank" : "_self"}
      style={{
        pointerEvents: isProduction ? "auto" : "none",
        color: isLastItem ? "" : linkColor ? linkColor : "",
      }}
    >
      {text && text !== "" ? text : "Link"}
    </a>
  );
}
// p-f p-c p-c-bo p-c-b-rev-hov-bo-brghtn20
// cp cp--brc cp--brc--hov
