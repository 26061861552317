import React from "react";
import { useDispatch } from "react-redux";

import { IProperty, PropertyComponent } from "./types";
import { propertySet } from "editor/states/layout";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";

import { Formik, Form } from "formik";

export class LeadStyleProperty implements IProperty {
  constructor(readonly style: any) {}

  render(elementId: string, propertyKey: string) {
    return (
      <LeadStylePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    return new LeadStyleProperty(value);
  }
}

const LeadStylePropertyComponent: PropertyComponent<LeadStyleProperty> = ({
  elementId,
  propertyKey,
  style,
}) => {
  const dispatch = useDispatch();

  const radios = [
    { name: "Primary", value: "1" },
    { name: "Secondary", value: "2" },
  ];

  const handleStyleChange = (value: any) => {
    dispatch(propertySet(elementId, propertyKey, new LeadStyleProperty(value)));
  };

  return (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      <Form className="form">
        <div className="form__group">
          <label htmlFor="style">Lead Style</label>
          <ButtonGroup className="mb-2 navbar-style-group">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                type="radio"
                variant="primary"
                name="style"
                value={radio.value}
                checked={style.style === radio.value}
                className="button-radio-hidden"
                onClick={(e: any) => handleStyleChange(radio.value)}
              >
                <Button
                  variant={
                    radio.name === "Primary" ? "primary" : "outline-primary"
                  }
                >
                  {" "}
                  Save{" "}
                </Button>
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
      </Form>
    </Formik>
  );
};
