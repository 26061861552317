export function urltoFile(
  url: string,
  filename: string,
  fileType: string,
  mimeType: string
) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename + "." + fileType, { type: mimeType });
    });
}
