import { State as AddToolbarState } from "./components/impl/AddElementToolbar/addToolbarReducer";
import { State as ResizeToolbarState } from "./components/impl/ResizeToolbar/resizeToolbarReducer";
import { State as AddToolbarGuidesState } from "./components/impl/AddElementToolbar/addToolbarGuidesReducer";
import { State } from "../store";
import { TemplateKey } from "./templates/types";

export const getPageId = (state: State): number | undefined =>
  state.editor.editor.id;

export const getTemplateKey = (state: State): TemplateKey | undefined =>
  state.editor.editor.templateKey;

export const getPageSlug = (state: State): string | null =>
  state.editor.editor.pageSlug;

export const getPageUniqueId = (state: State): string | null =>
  state.editor.editor.pageUniqueId;

export const getTreatmentPageSlug = (state: State): string | null =>
  state.editor.editor.treatmentPageSlug;

export const getTreatmentPageSlugs = (
  state: State,
  masterSlug: string
): string => state.editor.editor.treatmentPageSlugs[masterSlug];

export const getPageVersion = (state: State): number | null =>
  state.editor.editor.version;

export const getTitle = (state: State): string | null =>
  state.editor.editor.title;

export const getSelectedElementId = (state: State): string | null =>
  state.editor.editor.selectedElementId;

export const getSelectedElementIndex = (state: State): number | null =>
  state.editor.editor.selectedElementIndex;

export const getDraggableElementId = (state: State): string | null =>
  state.editor.editor.draggableElementId;

export const getResizeableElementId = (state: State): string | null =>
  state.editor.editor.resizeableElementId;

export const getHoveredElementId = (state: State): string | null =>
  state.editor.editor.hoveredElementId;

export const getHoveredElementIndex = (state: State): number | null =>
  state.editor.editor.hoveredElementIndex;

export const getAddToolbar = (state: State): AddToolbarState =>
  state.editor.addToolbar;

export const getAddToolbarGuides = (state: State): AddToolbarGuidesState =>
  state.editor.addToolbarGuides;

export const getResizeToolbar = (state: State): ResizeToolbarState =>
  state.editor.resizeToolbar;

export const getDescription = (state: State): string | null =>
  state.editor.editor.description;

export const getPageIndexState = (state: State): boolean | null =>
  state.editor.editor.isIndex;

export const getRtTempText = (state: State): string | undefined =>
  state.editor.editor.rtTempText;

export const getRtTempPropId = (state: State): string | undefined =>
  state.editor.editor.rtTempPropId;

export const getRtTempTextStatus = (state: State): boolean =>
  state.editor.editor.isRtTempTextSaved;

export const getPrimaryFont = (state: State): string | null =>
  state.editor.editor.primaryFont;

export const getSecondaryFont = (state: State): string | null =>
  state.editor.editor.secondaryFont;

export const getPrimaryColor = (state: State): string | null =>
  state.editor.editor.primaryColor;

export const getSecondaryColor = (state: State): string | null =>
  state.editor.editor.secondaryColor;

export const getTernaryColor = (state: State): string | null =>
  state.editor.editor.ternaryColor;

export const getColorType = (state: State): string | null =>
  state.editor.editor.colorType;

export const getPrimaryRadius = (state: State): string | null =>
  state.editor.editor.primaryRadius;

export const getSecondaryRadius = (state: State): string | null =>
  state.editor.editor.secondaryRadius;

export const getPrimaryShadow = (state: State): string | null =>
  state.editor.editor.primaryShadow;
