import { Formik } from "formik";
import React from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { getRequestError } from "../../../../api/selectors";
import { StepProps } from "./EditorWizard";

import styles from "./EditorWizard.module.scss";
import { Button } from "editor/components/base/Button/Button";
import { ArrowBackRounded, ArrowForwardRounded } from "@material-ui/icons";
import { getTemplateKey } from "editor/selectors";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  title: yup.string().required(/* TODO: Add a message */),
});

type FormValues = yup.InferType<typeof schema>;

export const EditorWizardStep1: React.FunctionComponent<StepProps> = ({
  values,
  handleSaveAndContinue,
  handleStepBack,
}) => {
  const { t } = useTranslation();
  const error = useSelector(getRequestError);
  const templateKey = useSelector(getTemplateKey);

  const onSubmit = (values: FormValues) => {
    handleSaveAndContinue(values);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault();
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={values}
    >
      {({ values, touched, errors, handleSubmit, handleChange }) => (
        <Form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>
          <Modal.Header className={`${styles["wizard__header"]}`}>
            <Modal.Title>{t("editor_setup_wizard23")}</Modal.Title>
            <small>{t("editor_setup_wizard24")}</small>
          </Modal.Header>
          <Modal.Body className={`${styles["wizard__body"]}`}>
            <Container>
              <Form.Group as={Row}>
                <Col>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    isInvalid={touched.title && !!errors.title}
                    autoFocus
                    className={styles["wizard-input"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                  <p className="text-danger">{error}</p>
                </Col>
              </Form.Group>
            </Container>
          </Modal.Body>
          <Modal.Footer className={styles["wizard__footer"]}>
            <Button
              label={t("editor_setup_wizard25")}
              variant="outline-primary"
              size="medium"
              icon={ArrowForwardRounded}
              // onClick={handleSubmit}
              type="submit"
            />
            {templateKey ? (
              <></>
            ) : values.templateKey === "blank" ? (
              <Button
                icon={ArrowBackRounded}
                variant="outline-primary"
                size="circle"
                onClick={() => handleStepBack(1)}
              />
            ) : (
              <Button
                icon={ArrowBackRounded}
                variant="outline-primary"
                size="circle"
                onClick={() => handleStepBack(4)}
              />
            )}
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
