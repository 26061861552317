import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRedirectUri, fetchBaseUrl, logActivity } from "./authActions";
import { getRedirectUri, getBaseUrl, getUsername } from "./selectors";

import styles from "./Auth.module.scss";

type Props = {
  label: string;
  page: string;
};

export default function FacebookLoginButton({
  label,
  page,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const redirectUri = useSelector(getRedirectUri);
  const baseUrl = useSelector(getBaseUrl);
  const username = useSelector(getUsername);

  useEffect(() => {
    dispatch(fetchRedirectUri());
    dispatch(fetchBaseUrl());
  }, [dispatch]);

  if (redirectUri === undefined) {
    return <></>;
  }

  return (
    <a
      className={styles["auth__social-button"]}
      href={baseUrl + `/oauth2/authorize/facebook?redirect_uri=` + redirectUri}
      onClick={() => {
        dispatch(logActivity("LOGIN", page, username, "facebook"));
      }}
    >
      <img alt={label} src="/img/facebook-logo.svg" />
      {label}
    </a>
  );
}
