import React from "react";

import { ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type LogoContainerProperties = {
  logoText: { label: string };
  logoStyle: { style: string; url: string; lastUpdateDate: string };
  logoDecoration: string;
  logoImage: { src: string; lastUpdateDate: string };
  dimension: { width?: number; height?: number };
};

export function LogoContainer({
  properties: { logoText, logoStyle, logoDecoration, logoImage, dimension },
  className,
  innerRef,
  ...props
}: ElementComponentProps<LogoContainerProperties>): JSX.Element {
  return (
    <div className={"logo-container"}>
      <a {...props} ref={innerRef} className={className + " link-block"}>
        {logoImage && logoImage.src !== undefined ? (
          <img
            className="logo-container__logo"
            alt=""
            src={`${logoImage.src}?tm=${logoImage.lastUpdateDate}`}
            style={{
              width: dimension && dimension.width ? dimension.width : "",
              height: dimension && dimension.height ? dimension.height : "",
            }}
          />
        ) : (
          <img
            className="logo-container__logo"
            alt=""
            src={`${logoStyle.url}?tm=${logoStyle.lastUpdateDate}`}
            style={{
              width: dimension && dimension.width ? dimension.width : "",
              height: dimension && dimension.height ? dimension.height : "",
            }}
          />
        )}
      </a>
    </div>
  );
}

export const logoContainerElementKey = "logo-container";

elementComponentRegistry.register(logoContainerElementKey, LogoContainer);
