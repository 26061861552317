/* eslint no-sparse-arrays: 0 */
import { Challenge, updateChallengeStatus } from "editor/states/layout";
import { WhatsApp } from "@material-ui/icons";
import {
  ElementType,
  ElementPropertiesComponent,
  PropertiesOf,
} from "editor/elements/types";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { elementDefinitionRegistry } from "../../ElementDefinitionRegistry";
import { PropertyRenderer } from "editor/elements/PropertyRenderer";
import styles from "./Whatsapp.module.scss";
import editorStyles from "editor/components/impl/EditorArea/EditorArea.module.scss";
import { TreatmentProperty } from "editor/properties/TreatmentProperty/TreatmentProperty";
import { ReuseTab } from "editor/components/components/Dialog/ReuseTab";
// import { ColorProperty } from "editor/properties/ColorProperty/ColorProperty";
import { useDispatch, useSelector } from "react-redux";
import { getUsername, getUserWebsiteSlug } from "auth/selectors";
import { getPageSlug } from "editor/selectors";
import { getPageChallenges } from "editor/components/impl/Editor/editorActions";
import { getChallenge, getFinishedChallenges } from "editor/states/selectors";
import { State } from "store";
import { Button } from "editor/components/base/Button/Button";
import { logActivity } from "auth/authActions";
import { Link } from "react-router-dom";
import {
  whatsappElementKey,
  WhatsappProperties,
} from "common/elements/base/Whatsapp";
import { WhatsappButtonStyleProperty } from "editor/properties/WhatsappButtonStyleProperty/WhatsappButtonStyleProperty";
import { PhoneNumberProperty } from "editor/properties/PhoneNumberProperty/PhoneNumberProperty";
import { useTranslation } from "react-i18next";

export class WhatsappElement {
  static readonly elementKey = whatsappElementKey;
  static readonly label = "element_whatsapp_label";
  static readonly icon = WhatsApp;
  static readonly allowedToolbarPositions = [];
  static readonly elementDescription = "element_whatsapp_description";
  static readonly elementPreviewUrl =
    "/img/add-button-element-preview-default.svg";
  static readonly isReusable = true;
  static readonly dialogPositionStatic = false;
  static readonly isTestable = true;
  static readonly cssPosition = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    height: "fit-content",
  };

  static readonly properties: PropertiesOf<WhatsappProperties> = {
    websiteTitle: String,
    whatsappNumber: PhoneNumberProperty,
    whatsappButtonStyle: WhatsappButtonStyleProperty,
    treatmentProperty: TreatmentProperty,
    isProduction: Boolean,
    isButton: Boolean,
    // color: ColorProperty,
    isTestable: Boolean,
    testableElementStyle: String,
  };

  static renderProperties(element: ElementType) {
    return <WhatsappButtonPropertiesComponent element={element} />;
  }

  static create(properties: {
    websiteTitle: string;
    whatsappNumber: PhoneNumberProperty;
    whatsappButtonStyle: WhatsappButtonStyleProperty;
    treatmentProperty: TreatmentProperty;
    isProduction: Boolean;
    isButton: Boolean;
    isTestable: Boolean;
    testableElementStyle?: string;
    // color?: ColorProperty;
  }) {
    return {
      ...properties,
      elementKey: this.elementKey,
    };
  }
}

const WhatsappButtonPropertiesComponent: ElementPropertiesComponent = ({
  element,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const pageSlug = useSelector(getPageSlug);
  const currentChallenge = useSelector((state: State) =>
    getChallenge(state, pageSlug!)
  );
  const finishedChallenges = useSelector((state: State) =>
    getFinishedChallenges(state, pageSlug!)
  );
  const username = useSelector(getUsername);
  const [isOverriden, setIsOverriden] = useState(false);
  const [testingElement, setTestingElement] = useState<any>();
  const [challenge, setChallenge] = useState<any>();

  useEffect(() => {
    if (pageSlug && websiteSlug) {
      dispatch(getPageChallenges(pageSlug, websiteSlug));
    }
  }, [dispatch, pageSlug, websiteSlug]);

  useEffect(() => {
    let finishedChallenge: Challenge | undefined;
    let finishedChallengesGroupedByElementId: Map<
      string,
      Challenge[]
    > = new Map();
    setChallenge(undefined);
    if (finishedChallenges) {
      finishedChallenges.forEach((finishedChallenge) => {
        let elementId = finishedChallenge.elementId;
        if (elementId) {
          let challenges = finishedChallengesGroupedByElementId.get(elementId);
          if (challenges && challenges?.length > 0) {
            challenges.push(finishedChallenge);
          } else {
            let challenges: Challenge[] = [];
            challenges.push(finishedChallenge);
            finishedChallengesGroupedByElementId.set(elementId, challenges);
          }
        }
      });

      finishedChallengesGroupedByElementId.forEach(
        (finishedChallengesArray, elementId) => {
          finishedChallengesArray.sort((a, b) => b.challengeId - a.challengeId);
          const highestId = Math.max(
            ...finishedChallengesArray.map((o) => o.challengeId)
          );
          finishedChallengesArray.forEach((element) => {
            if (
              elementId === element.elementId &&
              highestId === element.challengeId
            ) {
              finishedChallenge = element;
            }
          });
        }
      );
    }

    if (
      currentChallenge &&
      currentChallenge?.elementId === element.elementId &&
      currentChallenge?.isActive
    ) {
      setIsOverriden(true);
      setTestingElement(
        document.getElementById(element.elementId)?.cloneNode(true)
      );
      setChallenge(currentChallenge);
    } else if (
      finishedChallenge &&
      finishedChallenge.elementId === element.elementId
    ) {
      setIsOverriden(true);
      setTestingElement(
        document.getElementById(element.elementId)?.cloneNode(true)
      );
      setChallenge(finishedChallenge);
    } else {
      setIsOverriden(false);
    }
  }, [currentChallenge, finishedChallenges, element]);

  // @ts-ignore
  function parseCSSText(cssText) {
    var cssTxt = cssText.replace(/\/\*(.|\s)*?\*\//g, " ").replace(/\s+/g, " ");
    var style = {},
      [, ruleName, rule] = cssTxt.match(/ ?(.*?) ?{([^}]*)}/) || [, , cssTxt];
    // @ts-ignore
    var cssToJs = (s) =>
      // @ts-ignore
      s.replace(/\W+\w/g, (match) => match.slice(-1).toUpperCase());
    // @ts-ignore
    var properties = rule
      .split(";")
      // @ts-ignore
      .map((o) => o.split(":").map((x) => x && x.trim()));
    // @ts-ignore
    for (var [property, value] of properties) style[cssToJs(property)] = value;
    return { cssText, ruleName, style };
  }

  const DynamicComponent = (
    element: any,
    property: string | undefined,
    pageSlug: string,
    applyCurrent: boolean
  ) => {
    let reactElement;
    if (element) {
      const cssObj = parseCSSText(element.style.cssText);

      // eslint-disable-next-line react/no-danger-with-children
      reactElement = React.createElement(
        element.nodeName.toLowerCase(),
        {
          dangerouslySetInnerHTML: {
            __html: element.innerHTML,
          },
          className: property,
          style: applyCurrent
            ? { ...cssObj.style, position: "unset", margin: "unset" }
            : { position: "unset", margin: "unset" },
          id: (Math.random() + 1).toString(36).substring(7),
        },
        null
      );
    }
    return reactElement;
  };

  const updateChallenge = (
    pageSlug?: string,
    challengeId?: number,
    elementId?: string,
    isActive?: boolean
  ) => {
    if (websiteSlug && pageSlug && challengeId && elementId) {
      dispatch(
        updateChallengeStatus(
          challengeId,
          elementId,
          isActive ? false : true,
          () => {
            dispatch(getPageChallenges(pageSlug, websiteSlug));
          }
        )
      );

      if (isActive) {
        dispatch(logActivity("DISABLE TEST", pageSlug, username));
      } else {
        dispatch(logActivity("ENABLE TEST", pageSlug, username));
      }
    }
  };

  const renderer = new PropertyRenderer<typeof WhatsappElement.properties>(
    WhatsappElement.properties,
    element
  );

  return (
    <div className={styles["properties__container"]}>
      <Tabs id="button-properties" defaultActiveKey="number">
        <Tab eventKey="number" title="Number">
          {renderer.render("whatsappNumber")}
        </Tab>
        {isOverriden ? (
          <Tab eventKey="tests" title="Smart Tests">
            <div className="medium-padded-container">
              <div className={editorStyles["override-property-container"]}>
                <div className={editorStyles["override-property-note"]}>
                  This element is currently being tested to automatically
                  improve your design.
                  <br />
                  These are the designs being tested:
                </div>
                <div className={editorStyles["override-property-variants"]}>
                  <div className={editorStyles["override-property-variant"]}>
                    {challenge?.variants && challenge?.variants[0] ? (
                      <>
                        {DynamicComponent(
                          testingElement,
                          challenge?.variants[0].property,
                          challenge?.controlSlug,
                          challenge?.variants[0].isMaster
                        )}
                        {challenge?.variants[0].isCurrent
                          ? "Champion"
                          : "Challenger"}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={editorStyles["override-property-vs"]}>VS</div>
                  <div className={editorStyles["override-property-variant"]}>
                    {challenge?.variants && challenge?.variants[1] ? (
                      <>
                        {DynamicComponent(
                          testingElement,
                          challenge?.variants[1].property,
                          challenge?.controlSlug,
                          challenge?.variants[1].isMaster
                        )}
                        {challenge?.variants[1].isCurrent
                          ? "Champion"
                          : "Challenger"}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className={editorStyles["override-property-small-note"]}>
                  To see the history of the tests and styles,{" "}
                  <Link to={`/admin/tests`}>&nbsp;click here.</Link>
                </div>
                <div className={editorStyles["override-property-actions"]}>
                  <Button
                    variant="none-outline"
                    label="Disable Test"
                    onClick={() =>
                      updateChallenge(
                        challenge?.controlSlug,
                        challenge?.challengeId,
                        challenge?.elementId,
                        challenge?.isActive
                      )
                    }
                    style={{ padding: "12px", boxShadow: "none" }}
                    key={challenge?.controlSlug}
                  />
                </div>
              </div>
            </div>
          </Tab>
        ) : (
          <Tab eventKey="style" title={t("dialog_1")}>
            <Formik initialValues={{}} onSubmit={async (values) => {}}>
              <Form className="form">
                {renderer.render("whatsappButtonStyle")}
              </Form>
            </Formik>
          </Tab>
        )}
        {isOverriden ? (
          <></>
        ) : (
          // <Tab eventKey="color" title="Color">
          //     {renderer.render("color")}
          // </Tab>
          <></>
        )}
        <Tab eventKey="reuse" title={t("dialog_5")}>
          <ReuseTab elementId={element.elementId} />
        </Tab>
      </Tabs>
    </div>
  );
};

elementDefinitionRegistry.register(WhatsappElement);
