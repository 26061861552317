import { Formik, FormikErrors } from "formik";
import React, { useState } from "react";
import { Form, Card } from "react-bootstrap";
import { Button } from "editor/components/base/Button/Button";
import { Link } from "react-router-dom";
import * as yup from "yup";

import styles from "./Auth.module.scss";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";
import { signupPage } from "./SignUp";
import { useDispatch } from "react-redux";
import { logActivity } from "./authActions";
import { loginPage } from "./Login";

import { useTranslation } from "react-i18next";

export type SignUpFormValues = yup.InferType<typeof schema>;

let schema = yup.object({
  username: yup.string(),
  password: yup.string(),
  passwordConfirmation: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
});

interface IProps {
  onSubmit: (values: SignUpFormValues) => void;
  validateUsername: (username: string) => void;
  isSubmitting: boolean;
  isUsernameValid?: string;
  username?: number;
  websiteSlug?: string;
}

export const SignUpForm: React.FunctionComponent<IProps> = ({
  onSubmit,
  validateUsername,
  isSubmitting,
  isUsernameValid,
  username,
  websiteSlug,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  schema = yup.object({
    username: yup
      .string()
      .required(t("login_page_username_error1"))
      .email(t("login_page_username_error2"))
      .test("is-valid", t("login_page_username_error3"), function (
        this: yup.TestContext,
        value?: any
      ) {
        if (isUsernameValid === "true") {
          return false;
        } else {
          return true;
        }
      }),
    password: yup
      .string()
      .required(t("login_page_username_password1"))
      .min(8, t("login_page_username_password2"))
      .matches(/[a-z]/, t("login_page_username_password3"))
      .matches(/[A-Z]/, t("login_page_username_password4")),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password")], t("login_page_username_password5"))
      .required(t("login_page_username_password6")),
    firstName: yup.string().required(t("signup_page_validation1")),
    lastName: yup.string().required(t("signup_page_validation2")),
  });

  const validateFormForButton = (
    isSubmitting: boolean,
    errors: FormikErrors<{
      username: string;
      password: string;
      passwordConfirmation: string;
      firstName: string;
      lastName: string;
    }>,
    values: {
      username: string;
      password: string;
      passwordConfirmation: string;
      firstName: string;
      lastName: string;
    }
  ): boolean => {
    let isValid = true;

    if (isSubmitting || isSubmitClicked) {
      isValid = false;
    } else if (username) {
      isValid = false;
    } else if (
      errors.firstName ||
      errors.lastName ||
      errors.username ||
      errors.password ||
      errors.passwordConfirmation
    ) {
      isValid = false;
    } else if (
      !values.firstName ||
      !values.lastName ||
      !values.username ||
      !values.password ||
      !values.passwordConfirmation
    ) {
      isValid = false;
    }

    return isValid;
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        username: "",
        password: "",
        passwordConfirmation: "",
        firstName: "",
        lastName: "",
      }}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        setErrors,
      }) => (
        <Card className={styles["auth__card-big"]}>
          <h2>{t("login_page_lead2")}</h2>
          <span>
            {t("signup_page_lead")}{" "}
            <Link
              to="/login"
              className="mx-auto"
              onClick={() => {
                dispatch(
                  logActivity("GO TO", signupPage, undefined, loginPage)
                );
              }}
            >
              {t("login_page_header")}
            </Link>
          </span>
          <div
            className={styles["auth__socmed-buttons"]}
            style={{ paddingTop: "24px" }}
          >
            <GoogleLoginButton
              label={t("signup_page_google")}
              page={signupPage}
            />
            <FacebookLoginButton
              label={t("signup_page_facebook")}
              page={signupPage}
            />
          </div>
          <div
            className={styles["auth__divider-or"]}
            style={{ marginTop: "24px", marginBottom: "4px" }}
          >
            {t("or")}
          </div>
          <Form onSubmit={handleSubmit}>
            <div
              className={styles["auth__forms_container"]}
              style={{ paddingTop: "0px" }}
            >
              <Form.Group controlId="first-name">
                <h6>{t("signup_page_details")}</h6>
                <Form.Label>{t("first_name")}</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder={t("first_name")}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.firstName && !!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="last-name">
                <Form.Label>{t("last_name")}</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder={t("last_name")}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.lastName && !!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <hr />
              <h6>{t("signup_page_account")}</h6>
              <Form.Group controlId="username">
                <Form.Label>
                  {t("login_page_password_reset_username")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder={t("login_page_password_reset_username2")}
                  value={values.username}
                  onChange={handleChange}
                  onBlur={
                    handleBlur
                    // () => {
                    // setErrors({});
                    // handleValidateEmail(values.username);
                    // schema.validate(values.username)
                    // }
                  }
                  isInvalid={touched.username && !!errors.username}
                  autoComplete="username"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>{t("password")}</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder={t("password")}
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                  autoComplete="new-password"
                  onBlur={handleBlur}
                />
                <Form.Text className="text-muted">
                  <ul>
                    <li>{t("password_rule_1")}</li>
                    <li>{t("password_rule_2")}</li>
                    <li>{t("password_rule_3")}</li>
                  </ul>
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password-confirmation">
                <Form.Label>{t("repeat_password")}</Form.Label>
                <Form.Control
                  type="password"
                  name="passwordConfirmation"
                  placeholder={t("repeat_password")}
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  isInvalid={
                    touched.passwordConfirmation &&
                    !!errors.passwordConfirmation
                  }
                  onBlur={handleBlur}
                  autoComplete="new-password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordConfirmation}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                label={
                  isSubmitting || isSubmitClicked
                    ? t("loading")
                    : t("login_page_lead2")
                }
                variant={
                  validateFormForButton(isSubmitting, errors, values)
                    ? "primary"
                    : "disabled"
                }
                size="large"
                isDisabled={
                  validateFormForButton(isSubmitting, errors, values)
                    ? false
                    : true
                }
                style={{
                  alignSelf: "stretch",
                  width: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: 20,
                  padding: 12,
                }}
                type="submit"
                onClick={() => {
                  setIsSubmitClicked(true);
                  handleSubmit();
                }}
              />
            </div>
          </Form>
        </Card>
      )}
    </Formik>
  );
};
