import { elementDefinitionRegistry } from "editor/elements/ElementDefinitionRegistry";
import { PropertiesOf } from "editor/elements/types";
import { rootElementKey } from "common/elements/sections/Root";

export class RootElement {
  static readonly elementKey = rootElementKey;
  static readonly label = "Root";
  static readonly allowedChildren = [
    "navbar",
    "cover",
    "cards",
    "articles",
    "gallery",
    "contact",
    "footer",
    "whatsapp",
    "messenger",
  ];
  static readonly childDragDirection = "vertical";

  static readonly properties: PropertiesOf<{}> = {};
}

elementDefinitionRegistry.register(RootElement);
