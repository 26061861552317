import React from "react";
import { Dropdown as BootstrapDropdown, DropdownButton } from "react-bootstrap";

import "./Dropdown.scss";

type Props = {
  value?: string;
  title?: string;
  buttonLabel?: string;
  items: ItemProps[];
  onSelect?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Dropdown: React.FunctionComponent<Props> = ({
  value,
  title,
  buttonLabel,
  items,
  onSelect,
}) => {
  return (
    <DropdownButton
      id="dropdown-item-button"
      title={title}
      label={buttonLabel}
      value={value}
      onSelect={onSelect}
    >
      {(items || []).map((item) => (
        <BootstrapDropdown.Item
          key={item.key}
          eventKey={item.eventKey}
          active={item.isActive}
          onClick={item.onClickOfItem}
          // as="button"
        >
          {item.itemIcon}
          {item.itemLabel}
        </BootstrapDropdown.Item>
      ))}
    </DropdownButton>
  );
};

export type ItemProps = {
  key: number | string;
  eventKey?: string;
  itemLabel: string;
  itemIcon?: JSX.Element;
  isActive?: boolean;
  onClickOfItem?: () => void;
};
