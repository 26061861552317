import React from "react";

import { NavItem } from "common/elements";
import { Link, ElementComponentProps } from "common/elements/types";
import { elementComponentRegistry } from "common/elements/ElementComponentRegistry";

export type FooterNavProperties = {
  links: Link[];
  boldness?: number;
  spacing?: number;
  size?: number;
  style: string;
  isProduction: boolean;
};

export function FooterNav({
  properties: { links, boldness, spacing, size, style, isProduction },
  className,
  innerRef,
  children,
  ...props
}: ElementComponentProps<FooterNavProperties>): JSX.Element {
  return (
    <div className="navs-container">
      <nav
        {...props}
        ref={innerRef}
        className={`${className} nav style-${style}`}
      >
        {links ? (
          links.map((link: Link, index: number) => (
            <NavItem
              key={index}
              text={link.text}
              url={link.url}
              boldness={boldness}
              spacing={spacing}
              size={size}
              openNewTab={link.openNewTab}
              style={style}
              isProduction={isProduction}
            />
          ))
        ) : (
          <></>
        )}
      </nav>
      {children !== undefined ? <>{children}</> : <></>}
    </div>
  );
}

export const navElementKey = "footer-nav";

elementComponentRegistry.register(navElementKey, FooterNav);
