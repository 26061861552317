import React, { useCallback, useEffect, useRef, useState } from "react";
import { IProperty, PropertyComponent } from "../types";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import styles from "./BackgroundProperty.module.scss";
import { ReactComponent as UnsplashLogo } from "../../../img/UnsplashWordmarkLogo.svg";
import TextFieldGroup from "editor/components/components/TextFieldGroup/TextFieldGroup";
import { Photo } from "editor/models/types";
import {
  ArrowBackRounded,
  OpenWithRounded,
  RefreshRounded,
  SaveRounded,
  SearchRounded,
  ZoomInRounded,
  ZoomOutRounded,
} from "@material-ui/icons";
import { Button } from "editor/components/base/Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { propertySet } from "editor/states/layout";
import {
  getPhotos,
  getPhotosStatus,
  getS3config,
  getWebsite,
} from "../../../editor/states/selectors";
import {
  resetPhotos,
  getPhotos as getPhotosFromApi,
} from "../../../editor/states/photo";
import { Carousel } from "react-bootstrap";
import { getUnsplashKey, getUserWebsiteSlug } from "auth/selectors";
import { getUnplashKey } from "auth/authActions";
import { uploadFile } from "admin/S3Client";
import { useTranslation } from "react-i18next";

import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
  BoundsType,
  ReactZoomPanPinchState,
} from "react-zoom-pan-pinch";
import { urltoFile } from "../../../admin/image-utils";
import "./BackgroundProperty.scss";

var mime = require("mime-types");

export class BackgroundImageProperty implements IProperty {
  constructor(
    readonly backgroundImageUrl: string | undefined,
    readonly repeat: string, // "repeat" | "repeat-x" | "repeat-y" | "no-repeat"
    readonly positionX: string,
    readonly positionY: string,
    readonly size: string,
    readonly positionXPixels: number,
    readonly positionYPixels: number,
    readonly scalePixels: number
  ) {}

  render(elementId: string, propertyKey: string) {
    return (
      <BackgroundImagePropertyComponent
        {...this}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any): BackgroundImageProperty {
    return new BackgroundImageProperty(
      value.backgroundImageUrl,
      value.repeat,
      value.positionX,
      value.positionY,
      value.size,
      value.positionXPixels,
      value.positionYPixels,
      value.scalePixels
    );
  }
}

const BackgroundImagePropertyComponent: PropertyComponent<BackgroundImageProperty> = ({
  elementId,
  propertyKey,
  backgroundImageUrl,
  repeat,
  positionX,
  positionY,
  size,
  positionXPixels,
  positionYPixels,
  scalePixels,
}) => {
  const { t } = useTranslation();
  const [imgStrUrl, setImgStrUrl] = useState(backgroundImageUrl);
  const [queryString, setQueryString] = useState("");
  const s3ConfigState = useSelector(getS3config);
  const photos = useSelector(getPhotos);
  const photoQueryState = useSelector(getPhotosStatus);
  const unsplashKey = useSelector(getUnsplashKey);
  const website = useSelector(getWebsite);
  const websiteSlug = useSelector(getUserWebsiteSlug);
  const [bgHeight, setBgHeight] = useState(200);
  const [isZoomClicked, setIsZoomClicked] = useState(false);
  const [photosToDisplay, setPhotosToDisplay] = useState<Photo[]>();
  const [photoInView, setPhotoInView] = useState<number>(0);
  const dispatch = useDispatch();

  const transformRef = useRef<ReactZoomPanPinchRef>(null);

  useEffect(() => {
    if (unsplashKey === undefined) {
      dispatch(getUnplashKey());
    }
    if (imgStrUrl) {
      const domContainer = document.getElementById(elementId);
      if (domContainer) {
        let containerHeight = 200;

        let elemClasses = domContainer.className.split(" ");
        if (elemClasses) {
          if (elemClasses.includes("cover-height-40")) {
            containerHeight = 0.4 * 768 * 0.385;
          } else if (elemClasses.includes("cover-height-50")) {
            containerHeight = 0.5 * 768 * 0.385;
          } else if (elemClasses.includes("cover-height-60")) {
            containerHeight = 0.6 * 768 * 0.385;
          } else if (elemClasses.includes("cover-height-70")) {
            containerHeight = 0.7 * 768 * 0.385;
          } else if (elemClasses.includes("cover-height-80")) {
            containerHeight = 0.8 * 768 * 0.385;
          } else if (elemClasses.includes("cover-height-90")) {
            containerHeight = 0.9 * 768 * 0.385;
          }

          setBgHeight(containerHeight);
        }
      }
    }

    if (transformRef && transformRef.current) {
      transformRef.current?.setTransform(
        positionXPixels,
        positionYPixels,
        scalePixels
      );
    }
  }, [
    dispatch,
    unsplashKey,
    imgStrUrl,
    elementId,
    positionXPixels,
    positionYPixels,
    scalePixels,
  ]);

  useEffect(() => {
    let photosToDisplayTemp: Photo[] = [];
    if (photos) {
      photos.forEach((photo) => {
        if (photo) {
          photosToDisplayTemp?.push(photo);
        }
      });
    }
    setPhotosToDisplay(photosToDisplayTemp);
  }, [photos]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("File reading was aborted");
        reader.onerror = () => console.log("File reading has failed");
        reader.onload = () => {
          const imgStr = reader.result as string;
          let filename = "BG";
          if (website.title)
            filename =
              filename +
              "-" +
              website.title.replace(/\s+/g, "-") +
              "-" +
              Math.random().toString(36).slice(-6);

          let fileType = mime.extension(file.type);
          if (!fileType) {
            fileType = "png";
          }

          if (imgStr)
            urltoFile(imgStr, filename, fileType, file.type).then(function (
              file
            ) {
              if (websiteSlug !== undefined) {
                uploadFile(file, websiteSlug, filename, s3ConfigState).then(
                  function (loc) {
                    setImgStrUrl(loc);
                    if (loc !== undefined) {
                      var imageToPreview = new Image();
                      imageToPreview.src = imgStr;
                      imageToPreview.onload = function () {
                        let posXPixels = imageToPreview.width / 2;
                        let posYPixels = imageToPreview.height / 2;
                        let reducedPercent = (posXPixels - 512) / posXPixels;
                        let reducedHeight =
                          posYPixels - posYPixels * reducedPercent;
                        let calculatedPosY = (bgHeight - reducedHeight) / 2;
                        dispatch(
                          propertySet(
                            elementId,
                            propertyKey,
                            new BackgroundImageProperty(
                              loc,
                              "no-repeat",
                              "50%",
                              "50%",
                              "100%",
                              0,
                              calculatedPosY,
                              1
                            )
                          )
                        );
                      };
                    }
                  }
                );
              }
            });
        };

        reader.readAsDataURL(file);
        let img = new Image();
        if (imgStrUrl) img.src = imgStrUrl;
      });
    },
    [
      imgStrUrl,
      website.title,
      dispatch,
      elementId,
      propertyKey,
      s3ConfigState,
      websiteSlug,
      bgHeight,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: ["image/png", "image/jpeg", "image/jpg", "image/gif"],
    onDrop,
  });

  const handleReselectClick = () => {
    setImgStrUrl("");
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BackgroundImageProperty(
          "",
          repeat,
          positionX,
          positionY,
          size,
          positionXPixels,
          positionYPixels,
          scalePixels
        )
      )
    );
  };

  const handleReselectFromUnsplash = () => {
    dispatch(resetPhotos());
    setQueryString("");
  };

  const handleGetPhotosFromUnsplash = () => {
    const key = unsplashKey === undefined ? "replace-me" : unsplashKey;
    dispatch(getPhotosFromApi(queryString, key));
  };

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryString(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && queryString !== "") {
      handleGetPhotosFromUnsplash();
    }
  };

  const handleSelectPhotoFromUnsplash = (
    url: string,
    size: string,
    repeat: string,
    posX: string,
    posY: string,
    posXPixels: number,
    posYPixels: number
  ) => {
    let reducedPercent = (posXPixels - 512) / posXPixels;
    let reducedHeight = posYPixels - posYPixels * reducedPercent;
    let calculatedPosY = (bgHeight - reducedHeight) / 2;

    setImgStrUrl(url);
    dispatch(
      propertySet(
        elementId,
        propertyKey,
        new BackgroundImageProperty(
          url,
          repeat,
          posX,
          posY,
          size,
          0,
          calculatedPosY,
          1
        )
      )
    );
  };

  let buttonLabel = "Search";
  let buttonIcon = SearchRounded;
  let isDisabled = false;
  if (photoQueryState === "in-progress") {
    buttonLabel = "Searching";
    buttonIcon = RefreshRounded;
    isDisabled = true;
  }

  const handlePanAndZoom = (
    state?: ReactZoomPanPinchState,
    bounds?: BoundsType | null
  ) => {
    let movedTopPercent = 50;
    let movedLeftPercent = 50;
    let resultTop = 0;
    let resultLeft = 0;

    if (state) {
      if (bounds) {
        resultTop =
          (bounds?.minPositionY - state.positionY) / bounds?.minPositionY;
        movedTopPercent = 100 - resultTop * 100;
        if (movedTopPercent < 0) {
          movedTopPercent = 0;
        } else if (movedTopPercent > 100) {
          movedTopPercent = 100;
        }

        resultLeft =
          (bounds?.minPositionX - state.positionX) / bounds?.minPositionX;
        movedLeftPercent = 100 - resultLeft * 100;
        if (movedLeftPercent < 0) {
          movedLeftPercent = 0;
        } else if (movedLeftPercent > 100) {
          movedLeftPercent = 100;
        }
      }

      dispatch(
        propertySet(
          elementId,
          propertyKey,
          new BackgroundImageProperty(
            backgroundImageUrl,
            repeat,
            movedLeftPercent + "%",
            movedTopPercent + "%",
            state.scale * 100 + "%",
            state.positionX,
            state.positionY,
            state.scale
          )
        )
      );
    }
  };

  const generateCarouselItem = (photo: Photo) => {
    return (
      <Carousel.Item key={photo.id}>
        <div
          className={styles["background-preview-container"]}
          onClick={() =>
            handleSelectPhotoFromUnsplash(
              photo.urls.large,
              "100%",
              "no-repeat",
              "50%",
              "50%",
              photo.width / 2,
              photo.height / 2
            )
          }
        >
          <div
            className={styles["background"]}
            style={{
              backgroundImage: `url(${photo.urls.small})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              backgroundPositionY: "center",
            }}
          ></div>
        </div>
      </Carousel.Item>
    );
  };

  return (
    <div className="medium-padded-container">
      {imgStrUrl && imgStrUrl !== "" ? (
        <>
          <div className={styles["instructions-container"]}>
            {t("dialog_56")}
          </div>
          <div className={styles["pan-container"]}>
            <OpenWithRounded className={styles["pan-container-button-open"]} />
            <TransformWrapper
              ref={transformRef}
              initialPositionX={positionXPixels}
              initialPositionY={positionYPixels}
              initialScale={scalePixels}
              onPanningStop={(ref: ReactZoomPanPinchRef) => {
                handlePanAndZoom(ref.state, ref.instance.bounds);
              }}
              onZoomStop={(ref: ReactZoomPanPinchRef) => {
                handlePanAndZoom(ref.state, ref.instance.bounds);
              }}
              onZoom={(ref: ReactZoomPanPinchRef) => {
                handlePanAndZoom(ref.state, ref.instance.bounds);
              }}
              zoomAnimation={{ size: 1 }}
            >
              {({ zoomIn, zoomOut, resetTransform }: any) => (
                <React.Fragment>
                  <div
                    onMouseOver={() => {
                      setIsZoomClicked(false);
                    }}
                  >
                    {isZoomClicked ? (
                      <div
                        style={{
                          width: "512px",
                          position: "absolute",
                          zIndex: 1,
                          height: bgHeight,
                          backgroundColor: "rgba(137, 139, 139, 0.4)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {t("dialog_57")}
                      </div>
                    ) : (
                      <></>
                    )}
                    <TransformComponent wrapperStyle={{ height: bgHeight }}>
                      <img
                        width="100%"
                        id="mini-pan"
                        src={imgStrUrl}
                        alt="test 2"
                      />
                    </TransformComponent>
                  </div>
                  <div className={styles["tools-container"]}>
                    <Button
                      icon={ArrowBackRounded}
                      label={t("dialog_58")}
                      isFlat={true}
                      variant="danger"
                      size="medium"
                      onClick={handleReselectClick}
                    />
                    <div>
                      <button
                        className={styles["tools"]}
                        onClick={() => {
                          zoomIn();
                          setIsZoomClicked(true);
                        }}
                      >
                        <ZoomInRounded />
                      </button>
                      <button
                        className={styles["tools"]}
                        onClick={() => {
                          zoomOut();
                          setIsZoomClicked(true);
                        }}
                      >
                        <ZoomOutRounded />
                      </button>
                      <button
                        className={styles["tools"]}
                        onClick={() => {
                          resetTransform();
                          // handlePanAndZoom(transformRef.current?.state, transformRef.current?.instance.bounds);
                        }}
                      >
                        <RefreshRounded />
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </>
      ) : photosToDisplay &&
        photosToDisplay.length > 0 &&
        photoQueryState === "success" ? (
        <div>
          <Carousel
            interval={null}
            defaultActiveIndex={0}
            onSlide={(activeIndex: number) => {
              setPhotoInView(activeIndex);
            }}
          >
            {photosToDisplay.map((photo) => generateCarouselItem(photo))}
          </Carousel>
          <div className={styles["photos-slide-actions"]}>
            <span onClick={handleReselectFromUnsplash}>
              <ArrowBackRounded /> {t("dialog_59")}
            </span>
            <Button
              label={t("dialog_14")}
              icon={SaveRounded}
              variant="primary"
              size="medium"
              onClick={() => {
                if (photosToDisplay[photoInView]) {
                  handleSelectPhotoFromUnsplash(
                    photosToDisplay[photoInView].urls.large,
                    "100%",
                    "no-repeat",
                    "50%",
                    "50%",
                    photosToDisplay[photoInView].width / 2,
                    photosToDisplay[photoInView].height / 2
                  );
                }
              }}
            />
          </div>
        </div>
      ) : (
        <Formik initialValues={{}} onSubmit={async (values) => {}}>
          <div className={styles["choice-container"]}>
            <div {...getRootProps()} className={styles["dropzone"]}>
              <input {...getInputProps()} />
              <div className={styles["drop-zone-label-container"]}>
                <p>{t("upload_logo_1")}</p>
                <small>
                  {t("upload_logo_2")} <br />
                  {t("upload_logo_3")}
                </small>
              </div>
            </div>
            <div className={styles["unsplash-container"]}>
              <span className={styles["info-text"]}>{t("upload_logo_4")}</span>
              <UnsplashLogo />
              <div className={styles["action-container"]}>
                <TextFieldGroup
                  name="searchkey"
                  label={t("dialog_55")}
                  value={queryString}
                  size="m"
                  placeholder={t("dialog_10")}
                  onChange={handleQueryChange}
                  onKeyPress={handleKeyPress}
                />
                <Button
                  label={buttonLabel}
                  icon={buttonIcon}
                  variant="outline-primary"
                  size="medium"
                  onClick={() => handleGetPhotosFromUnsplash()}
                  onKeyUp={(e: React.KeyboardEvent) => {
                    if (e.keyCode) {
                      e.preventDefault();
                      handleGetPhotosFromUnsplash();
                    }
                  }}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </Formik>
      )}
    </div>
  );
};
