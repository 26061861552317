import {
  AddRounded,
  ArrowBackRounded,
  DeleteForeverRounded,
  EditRounded,
  SwapVertRounded,
} from "@material-ui/icons";
import React, { useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";

import { SocialLinkProperty } from "../SocialLinkProperty/SocialLinkProperty";
import { SocialMedia, SocialMediaList } from "../SocialMedia";
import { IProperty, PropertyComponent } from "../types";
import { Formik, Form } from "formik";
import TextFieldGroup from "../../components/components/TextFieldGroup";

import { useDispatch } from "react-redux";
import produce from "immer";
import { propertySet } from "editor/states/layout";
import { ItemProps, Dropdown } from "editor/components/base/Dropdown/Dropdown";

import styles from "./SocialLinkCollectionProperty.module.scss";
import "./SocialLinkCollectionProperty.scss";
import { Button } from "editor/components/base/Button/Button";
import { useTranslation } from "react-i18next";

export class SocialLinkCollectionProperty implements IProperty {
  constructor(readonly socialLinks: SocialLinkProperty[]) {}

  render(elementId: string, propertyKey: string) {
    return (
      <SocialLinkCollectionPropertyComponent
        {...this}
        key={elementId}
        elementId={elementId}
        propertyKey={propertyKey}
      />
    );
  }

  static parse(value: any) {
    const socialLinks: SocialLinkProperty[] = value.map(
      (v: SocialLinkProperty) => {
        var socialLinkProperty = new SocialLinkProperty(
          v.socialMedia,
          v.url,
          v.openNewTab,
          v.isConnected
        );
        return socialLinkProperty;
      }
    );
    return new SocialLinkCollectionProperty(socialLinks);
  }
}

interface ItemType {
  id: number;
  socialLink: SocialLinkProperty;
}

const SocialLinkCollectionPropertyComponent: PropertyComponent<SocialLinkCollectionProperty> = ({
  elementId,
  propertyKey,
  socialLinks,
}) => {
  const dispatch = useDispatch();
  const [isLinkDetailsDisplayed, setIsLinkDetailsDisplayed] = useState(false);
  const [socialLinkProperty, setSocialLinkProperty] = useState<
    SocialLinkProperty
  >();
  const [socialMedia, setSocialMedia] = useState<SocialMedia>();
  const [linksUnsorted, setLinksUnsorted] = useState(socialLinks);
  const [linksSorted, setLinksSorted] = useState<ItemType[]>(
    socialLinks.map((socialLink, index) => ({
      id: index,
      socialLink: socialLink,
    }))
  );
  const [selectedLinkIndex, setSelectedLinkIndex] = useState<number>();
  const linksText = socialLinks.map((link) => {
    return link.socialMedia.key;
  });

  const socialMediaList = SocialMediaList;

  const handleLinkAddClick = (socialMedia: SocialMedia) => {
    setIsLinkDetailsDisplayed(isLinkDetailsDisplayed ? false : true);
    setSelectedLinkIndex(undefined);
    setSocialMedia(socialMedia);
  };

  const handleLinkUpdateClick = (
    index: number,
    socialLinkProperty: SocialLinkProperty
  ) => {
    setSocialLinkProperty(socialLinkProperty);
    setSelectedLinkIndex(index);
    setIsLinkDetailsDisplayed(isLinkDetailsDisplayed ? false : true);
  };

  const handleAddLink = (socialLinkProperty: SocialLinkProperty) => {
    const nextLinks = produce(linksUnsorted, (draft) => {
      draft.push(socialLinkProperty);
    });
    dispatch(propertySet(elementId, propertyKey, nextLinks));
    setIsLinkDetailsDisplayed(false);
    linksSorted.push({
      id: linksSorted.slice(-1)[0].id + 1,
      socialLink: socialLinkProperty,
    });
    setLinksSorted(linksSorted);
    setLinksUnsorted(nextLinks);
  };

  const handleLinkUrlChange = (index: number, url: string) => {
    const nextLinks = produce(linksUnsorted, (draft) => {
      draft[index].url = url;
    });
    dispatch(propertySet(elementId, propertyKey, nextLinks));
  };

  const handleRemove = (index: number) => {
    const nextLinks = produce(linksUnsorted, (draft) => {
      draft.splice(index, 1);
    });
    dispatch(propertySet(elementId, propertyKey, nextLinks));
    setIsLinkDetailsDisplayed(false);
    linksSorted.splice(index, 1);
    setLinksSorted(linksSorted);
  };

  const handleSort = () => {
    const nextLinks = linksSorted.map(
      (linkSort: ItemType) => linkSort.socialLink
    );
    dispatch(propertySet(elementId, propertyKey, nextLinks));
    setLinksUnsorted(nextLinks);
  };

  let filteredSocialMediaList: SocialMedia[] = [];
  socialMediaList.forEach((item) => {
    if (!linksText.includes(item.key)) {
      filteredSocialMediaList.push(item);
    }
  });

  const dropdownPageItems = filteredSocialMediaList.map(
    (item: SocialMedia): ItemProps => ({
      key: item.key,
      eventKey: item.urlPlaceholder,
      itemLabel: item.label,
      itemIcon: item.icon,
      isActive: !linksText.includes(item.key) ? false : true,
      onClickOfItem: () => handleLinkAddClick(item),
    })
  );

  const { t } = useTranslation();

  return (
    <div className="medium-padded-container">
      <div className="link-collection-display-container">
        {!isLinkDetailsDisplayed ? (
          <div className="link-collection">
            <div className="link-collection-label">
              <SwapVertRounded /> {t("dialog_23")}
            </div>
            <ListGroup>
              <ReactSortable
                list={linksSorted}
                setList={setLinksSorted}
                onEnd={handleSort}
              >
                {linksSorted.map((item, index) => (
                  <ListGroup.Item
                    key={index}
                    onClick={() =>
                      handleLinkUpdateClick(index, item.socialLink)
                    }
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="actions left grabbable">
                        <SwapVertRounded />
                      </div>
                      <h5>{item.socialLink.socialMedia.label}</h5>
                    </div>
                    <div className="actions right">
                      <EditRounded />
                    </div>
                  </ListGroup.Item>
                ))}
              </ReactSortable>
            </ListGroup>
            {dropdownPageItems.length === 0 ? (
              <div className={styles["notice"]}>{t("dialog_52")}</div>
            ) : (
              <Dropdown items={dropdownPageItems} title={t("dialog_40")} />
            )}
          </div>
        ) : (
          <div className="link-collection-display-container">
            <SocialLinkDetailsDisplay
              index={selectedLinkIndex}
              socialMedia={socialMedia}
              socialLinkProperty={socialLinkProperty}
              setIsLinkDetailsDipslayed={setIsLinkDetailsDisplayed}
              addLink={handleAddLink}
              updateLink={handleLinkUrlChange}
              removeLink={handleRemove}
            />
          </div>
        )}
      </div>
    </div>
  );
};

type SocialLinkCollectionItemProps = {
  index?: number;
  socialMedia?: SocialMedia;
  socialLinkProperty?: SocialLinkProperty;
  setIsLinkDetailsDipslayed: React.Dispatch<React.SetStateAction<boolean>>;
  addLink: (socialLinkProp: SocialLinkProperty) => void;
  updateLink: (index: number, url: string) => void;
  removeLink: (index: number) => void;
};

const SocialLinkDetailsDisplay: React.FunctionComponent<SocialLinkCollectionItemProps> = ({
  index,
  socialMedia,
  socialLinkProperty,
  setIsLinkDetailsDipslayed,
  addLink,
  updateLink,
  removeLink,
}) => {
  const { t } = useTranslation();
  const [unsavedUrl, setUnsavedUrl] = useState<string>(() =>
    socialLinkProperty === undefined || socialLinkProperty.url === undefined
      ? ""
      : socialLinkProperty.url
  );
  const [unsavedSocialLinkProp, setUnsavedSocialLinkProp] = useState<
    SocialLinkProperty
  >();

  const handleUrlChange = (
    url: string,
    socialMedia?: SocialMedia,
    index?: number
  ) => {
    setUnsavedUrl(url);
    if (index !== undefined) {
      updateLink(index, url);
    } else if (socialMedia !== undefined) {
      setUnsavedSocialLinkProp(
        new SocialLinkProperty(socialMedia, url, true, false)
      );
    }
  };

  return (
    <>
      {index !== undefined ? (
        <>
          <Card.Body>
            <div className="internal-header">
              <div className="actions">
                {/* {socialLinkProperty?.socialMedia.icon} */}
              </div>
              <h4>
                {t("dialog_50")} {socialLinkProperty?.socialMedia.label}{" "}
                {t("details")}
              </h4>
            </div>
            <Formik initialValues={{}} onSubmit={async (values) => {}}>
              <Form className="form">
                <TextFieldGroup
                  name="url"
                  label={socialLinkProperty?.socialMedia.label + " URL"}
                  placeholder={
                    t("dialog_51") + " " + socialMedia?.urlPlaceholder
                  }
                  onChange={(e) =>
                    handleUrlChange(e.target.value, socialMedia, index)
                  }
                  value={unsavedUrl}
                />
                <div className="actions">
                  <Button
                    variant="outline-danger"
                    size="circle"
                    icon={DeleteForeverRounded}
                    onClick={() => removeLink(index)}
                  />
                  <span
                    onClick={() => setIsLinkDetailsDipslayed(false)}
                    className={styles["back-link"]}
                  >
                    <ArrowBackRounded /> {t("dialog_35")}
                  </span>
                </div>
              </Form>
            </Formik>
          </Card.Body>
        </>
      ) : (
        <>
          <Card.Body>
            <div className="internal-header">
              <div className="actions">{socialMedia?.icon}</div>
              <h4>
                {t("add")} {socialMedia?.label} {t("details")}
              </h4>
            </div>
            <Formik initialValues={{}} onSubmit={async (values) => {}}>
              <Form className="form">
                <TextFieldGroup
                  name="url"
                  label={socialMedia?.label + " URL"}
                  placeholder={"Ex. " + socialMedia?.urlPlaceholder}
                  onChange={(e) => handleUrlChange(e.target.value, socialMedia)}
                />
                <div className="actions">
                  <span style={{ width: "15%" }}></span>
                  <span
                    style={{ paddingRight: "16px" }}
                    onClick={() => setIsLinkDetailsDipslayed(false)}
                  >
                    <ArrowBackRounded /> {t("cancel")}
                  </span>
                  {unsavedSocialLinkProp !== undefined && unsavedUrl !== "" ? (
                    <>
                      <Button
                        variant="primary"
                        label={t("dialog_49")}
                        icon={AddRounded}
                        onClick={() => addLink(unsavedSocialLinkProp)}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        isDisabled={true}
                        label={t("dialog_49")}
                        icon={AddRounded}
                      />
                    </>
                  )}
                </div>
              </Form>
            </Formik>
          </Card.Body>
        </>
      )}
    </>
  );
};
