import { call, delay, put, select, takeLatest } from "@redux-saga/core/effects";
import produce from "immer";
import { Reducer } from "redux";

import {
  ABMockResource,
  ElementStyle,
  GAProperty,
  S3Config,
  Website,
  WebsitePublishState,
  WebsiteVersion,
} from "editor/models/types";
import {
  startRequest,
  finishRequestWithErrors,
  finishRequest,
} from "api/apiActions";
import {
  addPropertyIdsAsync,
  createWebsiteVersionAsync,
  findLatestWebsiteVersionAsync,
  findWebsitePublishStateAsync,
  getAvailablePropertyIdsAsync,
  deletePropertyAsync,
  getUsedPropertyIdsAsync,
  publishWebsiteAsync,
  updateWebsiteAsync,
  unlinkPropertyAsync,
  linkPropertyAsync,
  getS3ConfigAsync,
  getAllElementStylesAsync,
  getWebsitesToFinishCollectionAsync,
  endChallengeCollectionPhaseAsync,
  getWebsitesToFinishTestingAsync,
  endChallengeTestingPhaseAsync,
  mockReportsBySlugAndMonthAsync as mockReportsByWebsiteUniqueIdAndMonthAsync,
  mockWeeklyReportByActionAsync,
} from "editor/api/website";
import { getResponseJsonAsync } from "editor/api/common";
import {
  EDITOR_CREATE_OTHER_PAGES_UPDATE_REUSABLES,
  EDITOR_SAVE_PAGE_SUCCESS,
  SavePageSuccessAction,
} from "editor/components/impl/Editor/editorActions";
import {
  getSharedElementsConfiguration,
  getSharedElementsLayout,
  getWebsite,
} from "./selectors";
import { Layout } from "common/elements/types";
import {
  EDITOR_ELEMENT_SHARED,
  EDITOR_ELEMENT_UNSHARED,
  EDITOR_PROPERTY_SET,
} from "./layout";
import { SharedElementConfigurationById } from "editor/elements/types";
import { TCategory } from "api";
import { findAllUnlinkedWebsitesAsync } from "auth/sagas";

export type State = {
  website: Website;
  publishState: "loading" | "unpublished" | "publishing" | "success" | "fail";
  websiteUpdated: "unchanged" | "success" | "fail" | "changed";
  availablePropertyIds: GAProperty[];
  usedPropertyIds: GAProperty[];
  unlinkedWebsites: Website[];
  s3Config: S3Config;
  elementStyles: ElementStyle[] | null;
  challengesCollecting: ABMockResource[] | null;
};

export const EDITOR_WEBSITE_PUBLISHED = "EDITOR_WEBSITE_PUBLISHED";

type WebsitePublishedAction = {
  type: typeof EDITOR_WEBSITE_PUBLISHED;
  websiteSlug: string;
};

export const websitePublished = (
  websiteSlug: string
): WebsitePublishedAction => {
  return {
    type: EDITOR_WEBSITE_PUBLISHED,
    websiteSlug,
  };
};

export const EDITOR_WEBSITE_PUBLISH_SUCCEEDED =
  "EDITOR_WEBSITE_PUBLISH_SUCCEEDED";

type WebsitePublishSucceededAction = {
  type: typeof EDITOR_WEBSITE_PUBLISH_SUCCEEDED;
};

export const websitePublishSucceeded = (): WebsitePublishSucceededAction => {
  return {
    type: EDITOR_WEBSITE_PUBLISH_SUCCEEDED,
  };
};

export const EDITOR_WEBSITE_PUBLISH_FAILED = "EDITOR_WEBSITE_PUBLISH_FAILED";

type WebsitePublishFailedAction = {
  type: typeof EDITOR_WEBSITE_PUBLISH_FAILED;
};

export const websitePublishFailed = (): WebsitePublishFailedAction => {
  return {
    type: EDITOR_WEBSITE_PUBLISH_FAILED,
  };
};

export const EDITOR_WEBSITE_PUBLISH_STATE_REQUESTED =
  "EDITOR_WEBSITE_PUBLISH_STATE_REQUESTED";

type WebsitePublishStateRequestedAction = {
  type: typeof EDITOR_WEBSITE_PUBLISH_STATE_REQUESTED;
  websiteSlug: string;
};

export const websitePublishStateRequested = (
  websiteSlug: string
): WebsitePublishStateRequestedAction => {
  return {
    type: EDITOR_WEBSITE_PUBLISH_STATE_REQUESTED,
    websiteSlug,
  };
};

export const EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_SUCCEEDED =
  "EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_SUCCEEDED";

type WebsitePublishStateRequestSucceededAction = {
  type: typeof EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_SUCCEEDED;
  websitePublishState: WebsitePublishState;
};

export const websitePublishStateRequestSucceeded = (
  websitePublishState: WebsitePublishState
): WebsitePublishStateRequestSucceededAction => {
  return {
    type: EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_SUCCEEDED,
    websitePublishState,
  };
};

export const EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_FAILED =
  "EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_FAILED";

type WebsitePublishStateRequestFailedAction = {
  type: typeof EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_FAILED;
};

export const websitePublishStateRequestFailed = (): WebsitePublishStateRequestFailedAction => {
  return {
    type: EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_FAILED,
  };
};

export const EDITOR_WEBSITE_WIDTH_GUIDE_UPDATED =
  "EDITOR_WEBSITE_WIDTH_GUIDE_UPDATED";

type WebsiteWidthGuideUpdatedAction = {
  type: typeof EDITOR_WEBSITE_WIDTH_GUIDE_UPDATED;
  widthGuide: number;
};

export const websiteWidthGuideUpdated = (
  widthGuide: number
): WebsiteWidthGuideUpdatedAction => {
  return {
    type: EDITOR_WEBSITE_WIDTH_GUIDE_UPDATED,
    widthGuide,
  };
};

export const EDITOR_WEBSITE_DETAILS_UPDATED = "EDITOR_WEBSITE_DETAILS_UPDATED";

type WebsiteDetailsUpdatedAction = {
  type: typeof EDITOR_WEBSITE_DETAILS_UPDATED;
  websiteSlug: string;
  website: Website;
};

export const websiteDetailsUpdated = (
  websiteSlug: string,
  website: Website
): WebsiteDetailsUpdatedAction => {
  return {
    type: EDITOR_WEBSITE_DETAILS_UPDATED,
    websiteSlug,
    website,
  };
};

export const EDITOR_WEBSITE_UPDATE_MODIFIED = "EDITOR_WEBSITE_UPDATE_MODIFIED";

type WebsiteUpdateModifiedAction = {
  type: typeof EDITOR_WEBSITE_UPDATE_MODIFIED;
  website: Website;
};

export const websiteUpdateModified = (website: Website): Action => {
  return {
    type: EDITOR_WEBSITE_UPDATE_MODIFIED,
    website,
  };
};

export const EDITOR_WEBSITE_UPDATE_SUCCEEDED =
  "EDITOR_WEBSITE_UPDATE_SUCCEEDED";

type WebsiteUpdateSucceededAction = {
  type: typeof EDITOR_WEBSITE_UPDATE_SUCCEEDED;
  website: Website;
};

export const websiteUpdateSucceeded = (website: Website): Action => {
  return {
    type: EDITOR_WEBSITE_UPDATE_SUCCEEDED,
    website,
  };
};

export const EDITOR_WEBSITE_UPDATE_FAILED = "EDITOR_WEBSITE_UPDATE_FAILED";

type WebsiteUpdateFailedAction = {
  type: typeof EDITOR_WEBSITE_UPDATE_FAILED;
};

export const websiteUpdateFailed = (): Action => {
  return {
    type: EDITOR_WEBSITE_UPDATE_FAILED,
  };
};

export const FETCH_WEBSITE_SUCCESS = "FETCH_WEBSITE_SUCCESS";

type WebsiteFetchSuccessAction = {
  type: typeof FETCH_WEBSITE_SUCCESS;
  website: Website;
};

export const websiteFetchSuccess = (website: Website): Action => {
  return {
    type: FETCH_WEBSITE_SUCCESS,
    website,
  };
};

export const EDITOR_WEBSITE_VERSION_CREATED = "EDITOR_WEBSITE_VERSION_CREATED";

type WebsiteVersionCreatedAction = {
  type: typeof EDITOR_WEBSITE_VERSION_CREATED;
};

export const websiteVersionCreated = (): Action => {
  return {
    type: EDITOR_WEBSITE_VERSION_CREATED,
  };
};

export const EDITOR_WEBSITE_VERSION_CREATION_SUCCEEDED =
  "EDITOR_WEBSITE_VERSION_CREATION_SUCCEEDED";

type WebsiteVersionCreationSucceededAction = {
  type: typeof EDITOR_WEBSITE_VERSION_CREATION_SUCCEEDED;
  websiteVersion: WebsiteVersion;
};

export const websiteVersionCreationSucceeded = (
  websiteVersion: WebsiteVersion
): Action => {
  return {
    type: EDITOR_WEBSITE_VERSION_CREATION_SUCCEEDED,
    websiteVersion,
  };
};

export const EDITOR_WEBSITE_VERSION_CREATION_FAILED =
  "EDITOR_WEBSITE_VERSION_CREATION_FAILED";

type WebsiteVersionCreationFailedAction = {
  type: typeof EDITOR_WEBSITE_VERSION_CREATION_FAILED;
};

export const websiteVersionCreationFailed = (): Action => {
  return {
    type: EDITOR_WEBSITE_VERSION_CREATION_FAILED,
  };
};

export const EDITOR_WEBSITE_LATEST_VERSION_REQUESTED =
  "EDITOR_WEBSITE_LATEST_VERSION_REQUESTED";

type WebsiteLatestVersionRequestedAction = {
  type: typeof EDITOR_WEBSITE_LATEST_VERSION_REQUESTED;
  websiteSlug: string;
};

export const websiteLatestVersionRequested = (websiteSlug: string): Action => {
  return {
    type: EDITOR_WEBSITE_LATEST_VERSION_REQUESTED,
    websiteSlug,
  };
};

export const EDITOR_WEBSITE_LATEST_VERSION_REQUEST_SUCCEEDED =
  "EDITOR_WEBSITE_LATEST_VERSION_REQUEST_SUCCEEDED";

export type WebsiteLatestVersionRequestSucceededAction = {
  type: typeof EDITOR_WEBSITE_LATEST_VERSION_REQUEST_SUCCEEDED;
  sharedContent: Layout;
  sharedContentMetadata: SharedElementConfigurationById;
};

const websiteLatestVersionRequestSucceeded = (
  sharedContent: Layout,
  sharedContentMetadata: SharedElementConfigurationById
): Action => {
  return {
    type: EDITOR_WEBSITE_LATEST_VERSION_REQUEST_SUCCEEDED,
    sharedContent,
    sharedContentMetadata,
  };
};

export const EDITOR_WEBSITE_LATEST_VERSION_REQUEST_FAILED =
  "EDITOR_WEBSITE_LATEST_VERSION_REQUEST_FAILED";

type WebsiteLatestVersionRequestFailedAction = {
  type: typeof EDITOR_WEBSITE_LATEST_VERSION_REQUEST_FAILED;
};

const websiteLatestVersionRequestFailed = (): Action => {
  return {
    type: EDITOR_WEBSITE_LATEST_VERSION_REQUEST_FAILED,
  };
};

export const EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_REQUESTED =
  "EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_REQUESTED";

type GetAvailablePropertyIdsRequestedAction = {
  type: typeof EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_REQUESTED;
};

export const fetchAvailablePropertyIds = (): Action => {
  return {
    type: EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_REQUESTED,
  };
};

export const EDITOR_GET_WEBSITE_TO_FINISH_COLLECTION =
  "EDITOR_GET_WEBSITE_TO_FINISH_COLLECTION";

type GetWebsiteToFinishCollection = {
  type: typeof EDITOR_GET_WEBSITE_TO_FINISH_COLLECTION;
};

export const getWebsiteToFinishCollection = (): Action => {
  return {
    type: EDITOR_GET_WEBSITE_TO_FINISH_COLLECTION,
  };
};

export const EDITOR_WEBSITE_S3_CONFIG_REQUESTED =
  "EDITOR_WEBSITE_S3_CONFIG_REQUESTED";

type GetS3ConfigRequestAction = {
  type: typeof EDITOR_WEBSITE_S3_CONFIG_REQUESTED;
};

export const getS3Configuration = (): Action => {
  return {
    type: EDITOR_WEBSITE_S3_CONFIG_REQUESTED,
  };
};

export const EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_SUCCEEDED =
  "EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_SUCCEEDED";

export type GetAvailablePropertyIdsSucceededAction = {
  type: typeof EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_SUCCEEDED;
  availablePropertyIds: GAProperty[];
};

const fetchAvailablePropertyIdsSuccess = (
  availablePropertyIds: GAProperty[]
): Action => {
  return {
    type: EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_SUCCEEDED,
    availablePropertyIds,
  };
};

export const EDITOR_WEBSITE_ADD_PROPERTY_IDS_REQUESTED =
  "EDITOR_WEBSITE_ADD_PROPERTY_IDS_REQUESTED";

type AddPropertyIdsRequestedAction = {
  type: typeof EDITOR_WEBSITE_ADD_PROPERTY_IDS_REQUESTED;
  propertyId: string;
  measurementId: string;
};

export const addPropertyIdsRequest = (
  propertyId: string,
  measurementId: string
): Action => {
  return {
    type: EDITOR_WEBSITE_ADD_PROPERTY_IDS_REQUESTED,
    propertyId,
    measurementId,
  };
};

export const EDITOR_WEBSITE_DELETE_PROPERTY_REQUESTED =
  "EDITOR_WEBSITE_DELETE_PROPERTY_REQUESTED";

type DeletePropertyRequestedAction = {
  type: typeof EDITOR_WEBSITE_DELETE_PROPERTY_REQUESTED;
  propertyId: string;
};

export const deletePropertyRequest = (propertyId: string): Action => {
  return {
    type: EDITOR_WEBSITE_DELETE_PROPERTY_REQUESTED,
    propertyId,
  };
};

export const EDITOR_WEBSITE_UNLINK_PROPERTY_REQUESTED =
  "EDITOR_WEBSITE_UNLINK_PROPERTY_REQUESTED";

type UnlinkPropertyRequestedAction = {
  type: typeof EDITOR_WEBSITE_UNLINK_PROPERTY_REQUESTED;
  propertyId: string;
};

export const unlinkPropertyRequest = (propertyId: string): Action => {
  return {
    type: EDITOR_WEBSITE_UNLINK_PROPERTY_REQUESTED,
    propertyId,
  };
};

export const EDITOR_WEBSITE_LINK_PROPERTY_REQUESTED =
  "EDITOR_WEBSITE_LINK_PROPERTY_REQUESTED";

type LinkPropertyRequestedAction = {
  type: typeof EDITOR_WEBSITE_LINK_PROPERTY_REQUESTED;
  propertyId: string;
  websiteSlug: string;
};

export const linkPropertyRequest = (
  propertyId: string,
  websiteSlug: string
): Action => {
  return {
    type: EDITOR_WEBSITE_LINK_PROPERTY_REQUESTED,
    propertyId,
    websiteSlug,
  };
};

export const EDITOR_WEBSITE_USED_PROPERTY_IDS_REQUESTED =
  "EDITOR_WEBSITE_USED_PROPERTY_IDS_REQUESTED";

type GetUsedPropertyIdsRequestedAction = {
  type: typeof EDITOR_WEBSITE_USED_PROPERTY_IDS_REQUESTED;
};

export const fetchUsedPropertyIds = (): Action => {
  return {
    type: EDITOR_WEBSITE_USED_PROPERTY_IDS_REQUESTED,
  };
};

export const EDITOR_WEBSITE_USED_PROPERTY_IDS_SUCCEEDED =
  "EDITOR_WEBSITE_USED_PROPERTY_IDS_SUCCEEDED";

export type GetUsedPropertyIdsSucceededAction = {
  type: typeof EDITOR_WEBSITE_USED_PROPERTY_IDS_SUCCEEDED;
  usedPropertyIds: GAProperty[];
};

const fetchUsedPropertyIdsSuccess = (usedPropertyIds: GAProperty[]): Action => {
  return {
    type: EDITOR_WEBSITE_USED_PROPERTY_IDS_SUCCEEDED,
    usedPropertyIds,
  };
};

export const EDITOR_WEBSITE_S3_CONFIG_SUCCEEDED =
  "EDITOR_WEBSITE_S3_CONFIG_SUCCEEDED";

export type GetS3ConfigSuccessAction = {
  type: typeof EDITOR_WEBSITE_S3_CONFIG_SUCCEEDED;
  s3Config: S3Config;
};

const getS3ConfigSuccess = (s3Config: S3Config): Action => {
  return {
    type: EDITOR_WEBSITE_S3_CONFIG_SUCCEEDED,
    s3Config,
  };
};

export const EDITOR_WEBSITE_UNLINKED_WEBSITES_REQUESTED =
  "EDITOR_WEBSITE_UNLINKED_WEBSITES_REQUESTED";

type GetAllUnlinkedWebsitesRequestedAction = {
  type: typeof EDITOR_WEBSITE_UNLINKED_WEBSITES_REQUESTED;
};

export const getAllUnlinkedWebsitesRequest = (): Action => {
  return {
    type: EDITOR_WEBSITE_UNLINKED_WEBSITES_REQUESTED,
  };
};

export const EDITOR_WEBSITE_UNLINKED_WEBSITES_SUCCESS =
  "EDITOR_WEBSITE_UNLINKED_WEBSITES_SUCCESS";

type GetAllUnlinkedWebsitesSucceededAction = {
  type: typeof EDITOR_WEBSITE_UNLINKED_WEBSITES_SUCCESS;
  unlinkedWebsites: Website[];
};

export const getAllUnlinkedWebsitesSuccess = (
  unlinkedWebsites: Website[]
): Action => {
  return {
    type: EDITOR_WEBSITE_UNLINKED_WEBSITES_SUCCESS,
    unlinkedWebsites,
  };
};

export const EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES =
  "EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES";

type GetAllElementStylesAction = {
  type: typeof EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES;
};

export const getAllElementStyles = (): Action => {
  return {
    type: EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES,
  };
};

export const EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES_SUCCESS =
  "EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES_SUCCESS";

type GetAllElementStylesSuccessAction = {
  type: typeof EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES_SUCCESS;
  elementStyles: ElementStyle[];
};

export const getAllElementStylesSuccess = (
  elementStyles: ElementStyle[]
): Action => {
  return {
    type: EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES_SUCCESS,
    elementStyles,
  };
};

export const EDITOR_GET_CHALLENGES_COLLECTING =
  "EDITOR_GET_CHALLENGES_COLLECTING";

type GetChallengesCollectingAction = {
  type: typeof EDITOR_GET_CHALLENGES_COLLECTING;
};

export const getChallengesCollecting = (): Action => {
  return {
    type: EDITOR_GET_CHALLENGES_COLLECTING,
  };
};

export const EDITOR_GET_CHALLENGES_COLLECTING_SUCCESS =
  "EDITOR_GET_CHALLENGES_COLLECTING_SUCCESS";

type GetChallengesCollectingSuccessAction = {
  type: typeof EDITOR_GET_CHALLENGES_COLLECTING_SUCCESS;
  abMockResources: ABMockResource[];
};

export const getChallengesCollectingSuccess = (
  abMockResources: ABMockResource[]
): Action => {
  return {
    type: EDITOR_GET_CHALLENGES_COLLECTING_SUCCESS,
    abMockResources,
  };
};

export const EDITOR_GET_CHALLENGES_TESTING_SUCCESS =
  "EDITOR_GET_CHALLENGES_TESTING_SUCCESS";

type GetChallengesTestingSuccessAction = {
  type: typeof EDITOR_GET_CHALLENGES_TESTING_SUCCESS;
  abMockResources: ABMockResource[];
};

export const getChallengesTestingSuccess = (
  abMockResources: ABMockResource[]
): Action => {
  return {
    type: EDITOR_GET_CHALLENGES_TESTING_SUCCESS,
    abMockResources,
  };
};

export const EDITOR_END_COLLECTION_PHASE = "EDITOR_END_COLLECTION_PHASE";

type EndCollectionPhaseAction = {
  type: typeof EDITOR_END_COLLECTION_PHASE;
  challengeId: number;
  action: string;
};

export const endCollectionPhase = (
  challengeId: number,
  action: string
): Action => {
  return {
    type: EDITOR_END_COLLECTION_PHASE,
    challengeId,
    action,
  };
};

export const EDITOR_MOCK_WEEKLY_REPORT = "EDITOR_MOCK_WEEKLY_REPORT";

type MockWeeklyReportAction = {
  type: typeof EDITOR_MOCK_WEEKLY_REPORT;
  username: string;
};

export const mockWeeklyReport = (username: string): Action => {
  return {
    type: EDITOR_MOCK_WEEKLY_REPORT,
    username,
  };
};

export const EDITOR_MOCK_REPORTS = "EDITOR_MOCK_REPORTS";

type MockReportsAction = {
  type: typeof EDITOR_MOCK_REPORTS;
  websiteUniqueId: string;
  months: number;
};

export const mockReports = (
  websiteUniqueId: string,
  months: number
): Action => {
  return {
    type: EDITOR_MOCK_REPORTS,
    websiteUniqueId,
    months,
  };
};

export const EDITOR_GET_CHALLENGES_TESTING = "EDITOR_GET_CHALLENGES_TESTING";

type GetChallengesTestingAction = {
  type: typeof EDITOR_GET_CHALLENGES_TESTING;
};

export const getChallengesTesting = (): Action => {
  return {
    type: EDITOR_GET_CHALLENGES_TESTING,
  };
};

export const EDITOR_END_TESTING_PHASE = "EDITOR_END_TESTING_PHASE";

type EndTestingPhaseAction = {
  type: typeof EDITOR_END_TESTING_PHASE;
  challengeId: number;
  action: string;
};

export const endTestingPhase = (
  challengeId: number,
  action: string
): Action => {
  return {
    type: EDITOR_END_TESTING_PHASE,
    challengeId,
    action,
  };
};

type Action =
  | WebsitePublishedAction
  | WebsitePublishSucceededAction
  | WebsitePublishFailedAction
  | WebsitePublishStateRequestedAction
  | WebsitePublishStateRequestSucceededAction
  | WebsitePublishStateRequestFailedAction
  | WebsiteDetailsUpdatedAction
  | WebsiteWidthGuideUpdatedAction
  | WebsiteUpdateSucceededAction
  | WebsiteUpdateFailedAction
  | WebsiteFetchSuccessAction
  | WebsiteVersionCreatedAction
  | WebsiteVersionCreationSucceededAction
  | WebsiteVersionCreationFailedAction
  | WebsiteLatestVersionRequestedAction
  | WebsiteLatestVersionRequestSucceededAction
  | WebsiteLatestVersionRequestFailedAction
  | WebsiteUpdateModifiedAction
  | GetAvailablePropertyIdsRequestedAction
  | GetAvailablePropertyIdsSucceededAction
  | AddPropertyIdsRequestedAction
  | GetUsedPropertyIdsRequestedAction
  | GetUsedPropertyIdsSucceededAction
  | DeletePropertyRequestedAction
  | UnlinkPropertyRequestedAction
  | GetAllUnlinkedWebsitesRequestedAction
  | GetAllUnlinkedWebsitesSucceededAction
  | LinkPropertyRequestedAction
  | GetS3ConfigRequestAction
  | GetS3ConfigSuccessAction
  | GetAllElementStylesAction
  | GetAllElementStylesSuccessAction
  | GetWebsiteToFinishCollection
  | GetChallengesCollectingAction
  | GetChallengesCollectingSuccessAction
  | EndCollectionPhaseAction
  | GetChallengesTestingAction
  | GetChallengesTestingSuccessAction
  | EndTestingPhaseAction
  | MockReportsAction
  | MockWeeklyReportAction;

const initialState: State = {
  website: {
    slug: "",
    widthGuide: 1280,
    publishedAt: null,
    title: "",
    category: TCategory.NONE,
    gaProperty: null,
    gaMeasurementId: null,
    websiteUniqueId: "",
  },
  publishState: "loading",
  websiteUpdated: "unchanged",
  availablePropertyIds: [],
  usedPropertyIds: [],
  unlinkedWebsites: [],
  s3Config: {
    accessKeyId: "",
    secretAccessKey: "",
    bucketName: "",
    dirName: "",
    region: "",
    s3Url: "",
  },
  elementStyles: null,
  challengesCollecting: null,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action | SavePageSuccessAction
): State => {
  switch (action.type) {
    case EDITOR_WEBSITE_PUBLISHED:
      return handleWebsitePublished(state, action);

    case EDITOR_WEBSITE_PUBLISH_FAILED:
      return handleWebsitePublishFailed(state, action);

    case EDITOR_SAVE_PAGE_SUCCESS:
      return handleWebsiteResetPublishState(state, action);

    case EDITOR_WEBSITE_PUBLISH_STATE_REQUEST_SUCCEEDED:
      return handleWebsitePublishStateRequestSucceeded(state, action);

    case EDITOR_WEBSITE_DETAILS_UPDATED:
      return handleWebsiteDetailsUpdated(state, action);

    case EDITOR_WEBSITE_WIDTH_GUIDE_UPDATED:
      return handleWebsiteWidthGuideUpdated(state, action);

    case EDITOR_WEBSITE_UPDATE_SUCCEEDED:
      return handleUpdateWebsiteSuccess(state, action);

    case EDITOR_WEBSITE_UPDATE_FAILED:
      return handleUpdateWebsiteFailed(state, action);

    case FETCH_WEBSITE_SUCCESS:
      return handleFetchWebsiteSuccess(state, action);

    case EDITOR_WEBSITE_UPDATE_MODIFIED:
      return handleWebsiteUpdateModified(state, action);

    case EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_SUCCEEDED:
      return handleFetchAvailablePropertyIdsSuccess(state, action);

    case EDITOR_WEBSITE_USED_PROPERTY_IDS_SUCCEEDED:
      return handleFetchUsedPropertyIdsSuccess(state, action);

    case EDITOR_WEBSITE_UNLINKED_WEBSITES_SUCCESS:
      return handleGetUnlinkedWebsitesSuccess(state, action);

    case EDITOR_WEBSITE_S3_CONFIG_SUCCEEDED:
      return handleGetS3ConfigSuccess(state, action);

    case EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES_SUCCESS:
      return handleGetAllElementStylesSuccess(state, action);

    case EDITOR_GET_CHALLENGES_COLLECTING_SUCCESS:
      return handleGetChallangesCollectingSuccess(state, action);

    case EDITOR_GET_CHALLENGES_TESTING_SUCCESS:
      return handleGetChallangesTestingSuccess(state, action);

    default:
      return state;
  }
};

const handleGetChallangesTestingSuccess = (
  state: State,
  action: GetChallengesTestingSuccessAction
): State =>
  produce(state, (draft) => {
    draft.challengesCollecting = action.abMockResources;
  });

const handleGetChallangesCollectingSuccess = (
  state: State,
  action: GetChallengesCollectingSuccessAction
): State =>
  produce(state, (draft) => {
    draft.challengesCollecting = action.abMockResources;
  });

const handleGetS3ConfigSuccess = (
  state: State,
  action: GetS3ConfigSuccessAction
): State =>
  produce(state, (draft) => {
    draft.s3Config = action.s3Config;
  });

const handleGetAllElementStylesSuccess = (
  state: State,
  action: GetAllElementStylesSuccessAction
): State =>
  produce(state, (draft) => {
    draft.elementStyles = action.elementStyles;
  });

const handleGetUnlinkedWebsitesSuccess = (
  state: State,
  action: GetAllUnlinkedWebsitesSucceededAction
): State =>
  produce(state, (draft) => {
    draft.unlinkedWebsites = action.unlinkedWebsites;
  });

const handleFetchUsedPropertyIdsSuccess = (
  state: State,
  action: GetUsedPropertyIdsSucceededAction
): State =>
  produce(state, (draft) => {
    draft.usedPropertyIds = action.usedPropertyIds;
  });

const handleFetchAvailablePropertyIdsSuccess = (
  state: State,
  action: GetAvailablePropertyIdsSucceededAction
): State =>
  produce(state, (draft) => {
    draft.availablePropertyIds = action.availablePropertyIds;
  });

const handleFetchWebsiteSuccess = (
  state: State,
  action: WebsiteFetchSuccessAction
): State =>
  produce(state, (draft) => {
    draft.website = action.website;
  });

const handleUpdateWebsiteSuccess = (
  state: State,
  action: WebsiteUpdateSucceededAction
): State =>
  produce(state, (draft) => {
    draft.websiteUpdated = "success";
    draft.website = action.website;
    draft.publishState = "unpublished";
  });

const handleUpdateWebsiteFailed = (
  state: State,
  _: WebsiteUpdateFailedAction
): State =>
  produce(state, (draft) => {
    draft.websiteUpdated = "fail";
  });

const handleWebsitePublished = (
  state: State,
  _: WebsitePublishedAction
): State =>
  produce(state, (draft) => {
    draft.publishState = "publishing";
  });

const handleWebsitePublishFailed = (
  state: State,
  _: WebsitePublishFailedAction
): State =>
  produce(state, (draft) => {
    draft.publishState = "fail";
  });

const handleWebsiteResetPublishState = (
  state: State,
  _: SavePageSuccessAction
): State =>
  produce(state, (draft) => {
    draft.publishState = "unpublished";
  });

const handleWebsitePublishStateRequestSucceeded = (
  state: State,
  action: WebsitePublishStateRequestSucceededAction
): State =>
  produce(state, (draft) => {
    draft.website.publishedAt = action.websitePublishState.lastPublishedAt;
    if (action.websitePublishState.inProgress) {
      draft.publishState = "publishing";
    } else {
      draft.publishState =
        draft.publishState === "publishing" ? "success" : "unpublished";
    }
  });

const handleWebsiteWidthGuideUpdated = (
  state: State,
  action: WebsiteWidthGuideUpdatedAction
): State =>
  produce(state, (draft) => {
    draft.website.widthGuide = action.widthGuide;
  });

const handleWebsiteUpdateModified = (
  state: State,
  _: WebsiteUpdateModifiedAction
): State =>
  produce(state, (draft) => {
    draft.websiteUpdated = "changed";
  });

const handleWebsiteDetailsUpdated = (
  state: State,
  action: WebsiteDetailsUpdatedAction
): State =>
  produce(state, (draft) => {
    draft.website = action.website;
  });

function* publishWebsite(action: WebsitePublishedAction) {
  yield put(startRequest());
  try {
    yield call(publishWebsiteAsync, action.websiteSlug);
    yield put(websitePublishSucceeded());
    yield put(finishRequest());
    yield put(websitePublishStateRequested(action.websiteSlug));
  } catch (e) {
    yield put(websitePublishFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* loadWebsitePublishState(action: WebsitePublishStateRequestedAction) {
  yield put(startRequest());
  try {
    const websitePublishState: WebsitePublishState = yield call(
      findWebsitePublishStateAsync,
      action.websiteSlug
    );
    yield put(websitePublishStateRequestSucceeded(websitePublishState));
    yield put(finishRequest());
    if (websitePublishState.inProgress) {
      const pollAfterMilliseconds = 10000;
      yield delay(pollAfterMilliseconds);
      yield put(websitePublishStateRequested(action.websiteSlug));
    }
  } catch (e) {
    yield put(websitePublishStateRequestFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

function* updateWebsite(action: WebsiteDetailsUpdatedAction) {
  yield put(startRequest());
  try {
    const website: Website = yield select(getWebsite);
    const updatedWebsite: Website = yield call(
      updateWebsiteAsync,
      action.websiteSlug,
      website
    );
    yield put(websiteUpdateSucceeded(updatedWebsite));
    yield put(finishRequest());
  } catch (e) {
    yield put(websiteUpdateFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

export function* createWebsiteVersion() {
  const website: Website = yield select(getWebsite);
  const sharedContent: Layout = yield select(getSharedElementsLayout);
  const sharedContentMetadata: SharedElementConfigurationById = yield select(
    getSharedElementsConfiguration
  );

  yield put(startRequest());
  try {
    const websiteVersion: WebsiteVersion = yield call(
      createWebsiteVersionAsync,
      website.slug,
      sharedContent,
      sharedContentMetadata
    );
    yield put(websiteVersionCreationSucceeded(websiteVersion));
    yield put(finishRequest());
  } catch (e) {
    yield put(websiteVersionCreationFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

export function* createWebsiteVersionIfChanged() {
  const createWhenInactiveForMilliseconds = 1000;
  yield delay(createWhenInactiveForMilliseconds);
  yield put(websiteVersionCreated());
}

function* loadWebsiteLatestVersion(
  action: WebsiteLatestVersionRequestedAction
) {
  yield put(startRequest());
  try {
    const websiteLatestVersion: WebsiteVersion = yield call(
      findLatestWebsiteVersionAsync,
      action.websiteSlug
    );
    yield put(
      websiteLatestVersionRequestSucceeded(
        websiteLatestVersion.sharedContent,
        websiteLatestVersion.sharedContentMetadata
      )
    );
    yield put(finishRequest());
  } catch (e) {
    yield put(websiteLatestVersionRequestFailed());
    const json = yield call(getResponseJsonAsync, e);
    yield put(finishRequestWithErrors(json.message));
  }
}

export function* getUsedPropertyIds(
  action: GetAvailablePropertyIdsRequestedAction
) {
  yield put(startRequest());
  try {
    const response = yield call(getUsedPropertyIdsAsync);
    yield put(fetchUsedPropertyIdsSuccess(response));
    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* getAvailablePropertyIds(
  action: GetAvailablePropertyIdsRequestedAction
) {
  yield put(startRequest());
  try {
    const response = yield call(getAvailablePropertyIdsAsync);
    yield put(fetchAvailablePropertyIdsSuccess(response));
    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* getS3Config(action: GetS3ConfigRequestAction) {
  yield put(startRequest());
  try {
    const response = yield call(getS3ConfigAsync);
    yield put(getS3ConfigSuccess(response));
    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* getAllElementStylesFromApi(action: GetAllElementStylesAction) {
  yield put(startRequest());
  try {
    const response = yield call(getAllElementStylesAsync);
    yield put(getAllElementStylesSuccess(response));
    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* getWebsitesToFinishCollection(
  action: GetChallengesCollectingAction
) {
  yield put(startRequest());
  try {
    const response = yield call(getWebsitesToFinishCollectionAsync);
    yield put(getChallengesCollectingSuccess(response));
    yield put(finishRequest());
  } catch {
    yield put(finishRequest());
  }
}

export function* getWebsitesToFinishTesting(
  action: GetChallengesTestingAction
) {
  yield put(startRequest());
  try {
    const response = yield call(getWebsitesToFinishTestingAsync);
    yield put(getChallengesTestingSuccess(response));
    yield put(finishRequest());
  } catch {
    yield put(finishRequest());
  }
}

export function* endChallengeCollectionPhase(action: EndCollectionPhaseAction) {
  yield put(startRequest());
  try {
    yield call(
      endChallengeCollectionPhaseAsync,
      action.challengeId,
      action.action
    );
    yield put(finishRequest());
  } catch {
    yield put(finishRequest());
  }
}

export function* mockWeeklyReportByAction(action: MockWeeklyReportAction) {
  yield put(startRequest());
  try {
    yield call(mockWeeklyReportByActionAsync, action.username);
    yield put(finishRequest());
  } catch {
    yield put(finishRequest());
  }
}

export function* mockReportsByWebsiteUniqueIdAndMonth(
  action: MockReportsAction
) {
  yield put(startRequest());
  try {
    yield call(
      mockReportsByWebsiteUniqueIdAndMonthAsync,
      action.websiteUniqueId,
      action.months
    );
    yield put(finishRequest());
  } catch {
    yield put(finishRequest());
  }
}

export function* endChallengeTestingPhase(action: EndTestingPhaseAction) {
  yield put(startRequest());
  try {
    yield call(
      endChallengeTestingPhaseAsync,
      action.challengeId,
      action.action
    );
    yield put(finishRequest());
  } catch {
    yield put(finishRequest());
  }
}

export function* getAllUnlinkedWebsites(
  action: GetAllUnlinkedWebsitesRequestedAction
) {
  yield put(startRequest());
  try {
    const response = yield call(findAllUnlinkedWebsitesAsync);
    yield put(getAllUnlinkedWebsitesSuccess(response));
    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* addPropertyIds(action: AddPropertyIdsRequestedAction) {
  yield put(startRequest());
  try {
    yield call(addPropertyIdsAsync, action.propertyId, action.measurementId);
    const response = yield call(getAvailablePropertyIdsAsync);
    yield put(fetchAvailablePropertyIdsSuccess(response));
    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* deletePropertyId(action: DeletePropertyRequestedAction) {
  yield put(startRequest());
  try {
    yield call(deletePropertyAsync, action.propertyId);
    const response = yield call(getAvailablePropertyIdsAsync);
    yield put(fetchAvailablePropertyIdsSuccess(response));
    const responseWeb = yield call(getUsedPropertyIdsAsync);
    yield put(fetchUsedPropertyIdsSuccess(responseWeb));
    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* unlinkPropertyId(action: UnlinkPropertyRequestedAction) {
  yield put(startRequest());
  try {
    yield call(unlinkPropertyAsync, action.propertyId);
    const response = yield call(getAvailablePropertyIdsAsync);
    yield put(fetchAvailablePropertyIdsSuccess(response));
    const responseWeb = yield call(getUsedPropertyIdsAsync);
    yield put(fetchUsedPropertyIdsSuccess(responseWeb));
    const responseUnlinked = yield call(findAllUnlinkedWebsitesAsync);
    yield put(getAllUnlinkedWebsitesSuccess(responseUnlinked));
    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* linkPropertyId(action: LinkPropertyRequestedAction) {
  yield put(startRequest());
  try {
    yield call(linkPropertyAsync, action.propertyId, action.websiteSlug);
    const response = yield call(getAvailablePropertyIdsAsync);
    yield put(fetchAvailablePropertyIdsSuccess(response));
    const responseUsed = yield call(getUsedPropertyIdsAsync);
    yield put(fetchUsedPropertyIdsSuccess(responseUsed));
    const responseWeb = yield call(findAllUnlinkedWebsitesAsync);
    yield put(getAllUnlinkedWebsitesSuccess(responseWeb));

    yield put(finishRequest());
  } catch {
    yield put(websiteLatestVersionRequestFailed());
  }
}

export function* rootSaga() {
  yield takeLatest(EDITOR_GET_CHALLENGES_TESTING, getWebsitesToFinishTesting);
  yield takeLatest(
    EDITOR_GET_CHALLENGES_COLLECTING,
    getWebsitesToFinishCollection
  );
  yield takeLatest(EDITOR_WEBSITE_PUBLISHED, publishWebsite);
  yield takeLatest(
    EDITOR_WEBSITE_PUBLISH_STATE_REQUESTED,
    loadWebsitePublishState
  );
  yield takeLatest(EDITOR_WEBSITE_WIDTH_GUIDE_UPDATED, updateWebsite);
  yield takeLatest(EDITOR_WEBSITE_DETAILS_UPDATED, updateWebsite);
  yield takeLatest(EDITOR_WEBSITE_VERSION_CREATED, createWebsiteVersion);
  yield takeLatest(EDITOR_ELEMENT_SHARED, createWebsiteVersionIfChanged);
  yield takeLatest(EDITOR_ELEMENT_UNSHARED, createWebsiteVersionIfChanged);
  yield takeLatest(
    EDITOR_CREATE_OTHER_PAGES_UPDATE_REUSABLES,
    createWebsiteVersion
  );
  yield takeLatest(EDITOR_PROPERTY_SET, createWebsiteVersionIfChanged);
  yield takeLatest(
    EDITOR_WEBSITE_LATEST_VERSION_REQUESTED,
    loadWebsiteLatestVersion
  );
  yield takeLatest(
    EDITOR_WEBSITE_AVAILABLE_PROPERTY_IDS_REQUESTED,
    getAvailablePropertyIds
  );
  yield takeLatest(
    EDITOR_WEBSITE_USED_PROPERTY_IDS_REQUESTED,
    getUsedPropertyIds
  );
  yield takeLatest(EDITOR_WEBSITE_ADD_PROPERTY_IDS_REQUESTED, addPropertyIds);
  yield takeLatest(EDITOR_WEBSITE_DELETE_PROPERTY_REQUESTED, deletePropertyId);
  yield takeLatest(EDITOR_WEBSITE_UNLINK_PROPERTY_REQUESTED, unlinkPropertyId);
  yield takeLatest(
    EDITOR_WEBSITE_UNLINKED_WEBSITES_REQUESTED,
    getAllUnlinkedWebsites
  );
  yield takeLatest(EDITOR_WEBSITE_LINK_PROPERTY_REQUESTED, linkPropertyId);
  yield takeLatest(EDITOR_WEBSITE_S3_CONFIG_REQUESTED, getS3Config);
  yield takeLatest(
    EDITOR_WEBSITE_GET_ALL_ELEMENT_STYLES,
    getAllElementStylesFromApi
  );
  yield takeLatest(EDITOR_END_COLLECTION_PHASE, endChallengeCollectionPhase);
  yield takeLatest(EDITOR_END_TESTING_PHASE, endChallengeTestingPhase);
  yield takeLatest(EDITOR_MOCK_REPORTS, mockReportsByWebsiteUniqueIdAndMonth);
  yield takeLatest(EDITOR_MOCK_WEEKLY_REPORT, mockWeeklyReportByAction);
}
